import { IntegrationFormState, IntegrationModel, IntegrationLogModel } from '@/types';
import BaseService from '@/services/api/BaseService';
import { AxiosRequestConfig } from 'axios';

class IntegrationService extends BaseService<IntegrationModel | IntegrationFormState> {
    getLogs<ResponseT = IntegrationLogModel[]>(id: number, config?: AxiosRequestConfig) {
        return this.http.get<ResponseT>(`${this.route}/${id}/logs`, config);
    }
}

export default new IntegrationService('integrations');
