import { FullAudit, ConnectFlowModel, LevelModel, UserModel } from '@/types';

export interface NumberModel extends FullAudit {
    id: number;
    level: LevelModel;
    connectFlow: ConnectFlowModel;
    connectFlowId: number;
    number: string;
    city: string;
    state: string;
    type: string;
    mediaLabel: string | null;
    isSmartCallerId: boolean;
    agents?: UserModel[];
    usersNumber: {
        levelId: number;
        userId: number;
        numberId: number;
        type: string;
    }[];
}
