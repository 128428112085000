import { RouteRecordRaw } from 'vue-router';
import { validateIdAsPositiveIntegerGuard } from '@/router/routeGuards';
import UserLabelPage from '@/views/pages/user-label/UserLabelPage.vue';
import UserLabelDetailPage from '@/views/pages/user-label/UserLabelDetailPage.vue';
import UserLabelFormPage from '@/views/pages/user-label/UserLabelFormPage.vue';
import { FormAction } from '@/types';

const userLabelRouter: RouteRecordRaw = {
    name: 'UserLabel',
    path: 'user-label',
    children: [
        {
            name: 'ListUserLabel',
            path: '',
            component: UserLabelPage,
        },
        {
            name: 'ViewUserLabelById',
            path: ':id',
            component: UserLabelDetailPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            meta: { isLevelLocked: true },
        },
        {
            name: 'EditUserLabelById',
            path: ':id/edit',
            component: UserLabelFormPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            props: { action: FormAction.EDIT },
            meta: { isLevelLocked: true },
        },
        {
            name: 'CreateUserLabel',
            path: 'create',
            component: UserLabelFormPage,
            props: { action: FormAction.CREATE },
            meta: { isLevelLocked: true },
        },
    ],
};

export default userLabelRouter;
