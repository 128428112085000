export interface CallServiceGetInfoParams {
    startDate: string;
    endDate: string;
    level: number;
    type: string | null;
    status: string | null;
    userLabel: string | null;
    media: string | null;
    numberClient: string | null;
    page?: number;
    per_page?: number;
    order_by?: string;
    is_active?: boolean;
    search?: string;
}
