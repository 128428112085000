import { createApp } from 'vue';
import App from '@/App.vue';

// Vue Router & Store
import router from '@/router';
import store from '@/store';
import { pinia } from '@/stores';

// i18n (Internationalization)
import { i18n } from '@/plugins/i18n';

// Global Properties & Components
import globalProperties from '@/plugins/globalProperties';
import globalComponents from '@/plugins/globalComponents';

// Third-party Plugins
import Toast, { PluginOptions } from 'vue-toastification';
import VueTippy from 'vue-tippy';
import VueGtag from 'vue-gtag';

// Styles
import '@/assets/main.pcss';
import 'vue-toastification/dist/index.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';

// Definitions
const app = createApp(App);

// Core Vue Plugins
app.use(i18n);
app.use(store);
app.use(pinia);
app.use(router);
app.use(globalProperties);
app.use(globalComponents);

// Plugin Configurations
const toastOptions: PluginOptions = { toastClassName: 'themed' };
app.use(Toast, toastOptions);

app.use(VueTippy, {
    defaultProps: {
        theme: 'light-border',
    },
});

// Google Analytics (Production Only)
if (process.env.NODE_ENV === 'production') {
    app.use(
        VueGtag,
        {
            config: { id: process.env.VUE_APP_GA_MEASUREMENT_ID },
        },
        router,
    );
}

// Mount the App
app.mount('#app');
