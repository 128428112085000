import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, watch, onMounted } from 'vue';
import { useRoute, RouterLink } from 'vue-router';

import { useUiStore } from '@/stores';
import { ConnectFlowSurveyService, VaultService } from '@/services';
import { toastServiceError } from '@/services/core/notification';
import { ConnectFlowSurveyModel, Permission } from '@/types';
import { getAudioFormat } from '@/utils/audio';


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowSurveyDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const uiStore = useUiStore();

const survey: Ref<ConnectFlowSurveyModel | undefined> = ref(undefined);
const audioFile: Ref<string | undefined> = ref();
const questions: Ref<
    {
        label: string;
        rangeAnswer: string;
        audioFilePath?: string | null;
        audioFile?: string | null;
        textToAudio?: string | null;
    }[]
> = ref([]);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getAudioData = async (audioFilePath: string | null | undefined) => {
    let audioData: string | undefined;

    if (audioFilePath) {
        try {
            const { data } = await VaultService.getAudioAsset(audioFilePath);
            const blob = new Blob([data], {
                type: getAudioFormat(audioFilePath),
            });

            audioData = URL.createObjectURL(blob);
        } catch (err) {
            console.error(err);
        }
    }

    return audioData;
};

const fetchConnectFlowSurvey = async (id: number) => {
    await setLoading(true);
    try {
        const response = await ConnectFlowSurveyService.get<ConnectFlowSurveyModel>(id);
        survey.value = response.data;
        audioFile.value = await getAudioData(survey.value?.audioFilePath);
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

watch([survey], async () => {
    questions.value = [];

    if (survey.value?.questions?.length) {
        for (let i = 0; i < survey.value.questions.length; i++) {
            const question = survey.value.questions[i];
            questions.value.push({
                label: question.label,
                rangeAnswer: question.rangeAnswer,
                audioFilePath: question.audioFilePath,
                audioFile: await getAudioData(question.audioFilePath),
                textToAudio: question.textToAudio,
            });
        }
    }
});

onMounted(() => {
    const surveyId = Number(route.params.id);
    if (!isNaN(surveyId)) fetchConnectFlowSurvey(surveyId);
});

const __returned__ = { route, uiStore, survey, audioFile, questions, setLoading, getAudioData, fetchConnectFlowSurvey, get RouterLink() { return RouterLink }, get Permission() { return Permission }, get getAudioFormat() { return getAudioFormat } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})