import { App, defineAsyncComponent } from 'vue';

// Automatically register all Vue components in the `components` folder
export default {
    install(app: App) {
        const components = require.context('@/components', true, /\.vue$/);

        components.keys().forEach((fileName) => {
            const componentConfig = components(fileName);
            const componentName = fileName
                .split('/')
                .pop()
                ?.replace(/\.\w+$/, ''); // Extract component name

            if (componentName) {
                app.component(
                    componentName,
                    defineAsyncComponent(() => Promise.resolve(componentConfig.default)),
                );
            }
        });
    },
};
