import { createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col rounded bg-gray-50 shadow ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50" }
const _hoisted_2 = { class: "flex items-center justify-between bg-gray-50 p-3" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "text-lg font-bold text-gray-800" }
const _hoisted_5 = { class: "flex w-2/4 items-center" }
const _hoisted_6 = { class: "flex-1 overflow-y-auto p-6" }
const _hoisted_7 = { class: "space-y-4" }
const _hoisted_8 = { class: "flex items-center" }
const _hoisted_9 = { class: "mr-1 font-semibold text-gray-700" }
const _hoisted_10 = { class: "text-gray-400" }
const _hoisted_11 = { class: "text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiListSearch = _resolveComponent("UiListSearch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" Cabeçalho Fixo "),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["MenuIcon"], {
          class: "mr-3 h-6 w-6 flex-shrink-0 text-[#6dbcab] group-hover:text-[#6dbcab]",
          "aria-hidden": "true"
        }),
        _createElementVNode("h3", _hoisted_4, _toDisplayString($setup.t('meeting.view.transcription.title')), 1 /* TEXT */)
      ]),
      _createElementVNode("form", _hoisted_5, [
        _createVNode(_component_UiListSearch, {
          class: "w-full rounded bg-gray-50",
          modelValue: $setup.searchQuery,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchQuery) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ])
    ]),
    _createCommentVNode(" Área de Mensagens Rolável "),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("ul", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredMessages, (message) => {
          return (_openBlock(), _createElementBlock("li", {
            key: message.id,
            class: "flex flex-col"
          }, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("p", _hoisted_9, _toDisplayString(message.speakerName), 1 /* TEXT */),
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "mr-1" }, "-", -1 /* HOISTED */)),
              _createElementVNode("p", _hoisted_10, _toDisplayString(message.formattedTimestamp), 1 /* TEXT */)
            ]),
            _createElementVNode("p", _hoisted_11, _toDisplayString(message.transcript), 1 /* TEXT */)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ])
  ]))
}