import { defineComponent as _defineComponent } from 'vue'
import { computed, PropType } from 'vue';

import {
    CalendarIcon,
    ClockIcon,
    UserGroupIcon,
    PlayIcon,
    VideoCameraIcon,
    UserIcon,
} from '@heroicons/vue/solid';
import { MeetingModel, Permission, MeetingPlatformTypeNameMap } from '@/types';
import { formatDateAsString } from '@/utils/datetime';


export default /*@__PURE__*/_defineComponent({
  __name: 'MeetingListItem',
  props: {
    modelValue: {
        type: Object as PropType<MeetingModel>,
        required: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const participantsNames = computed(() =>
    props.modelValue.participants.map((p) => p.name).join(', '),
);

const formattedCreatedAt = computed(() => {
    return props.modelValue.createdAt
        ? formatDateAsString(props.modelValue.createdAt, 'DD/MM/YYYY HH:mm:ss')
        : '-';
});

const __returned__ = { props, participantsNames, formattedCreatedAt, get CalendarIcon() { return CalendarIcon }, get ClockIcon() { return ClockIcon }, get UserGroupIcon() { return UserGroupIcon }, get PlayIcon() { return PlayIcon }, get VideoCameraIcon() { return VideoCameraIcon }, get UserIcon() { return UserIcon }, get Permission() { return Permission }, get MeetingPlatformTypeNameMap() { return MeetingPlatformTypeNameMap } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})