<script lang="ts" setup>
import { PropType } from 'vue';
import router from '@/router';

import {
    InformationCircleIcon,
    LocationMarkerIcon,
    TagIcon,
    UsersIcon,
} from '@heroicons/vue/solid';
import ConnectFlowIcon from '@/components/svg/ConnectFlowIcon';
import { PencilAltIcon } from '@heroicons/vue/outline';
import { NumberModel, Permission } from '@/types';
import { applyPhoneMask } from '@/utils/number';

const { modelValue } = defineProps({
    modelValue: {
        type: Object as PropType<NumberModel>,
        required: true,
    },
});

const handleEditClick = () => {
    router.push({
        name: 'EditNumberById',
        params: {
            id: modelValue.id,
        },
    });
};
</script>

<template>
    <UiPanel class="space-y-2 hover:bg-white">
        <div class="flex items-center justify-between">
            <div class="flex-1">
                <component
                    :is="$can(Permission.VIEW_NUMBER) ? 'RouterLink' : 'span'"
                    :to="{
                        name: 'ViewNumberById',
                        params: {
                            id: modelValue.id,
                        },
                    }"
                    class="text-md text-primary truncate font-medium"
                >
                    {{ applyPhoneMask(modelValue.number) }}
                </component>
            </div>
            <div v-if="$can(Permission.EDIT_NUMBER)">
                <UiActionButton
                    v-tippy="$t('core.actions.Edit')"
                    variant="primary"
                    @click="handleEditClick"
                >
                    <span class="sr-only"> {{ $t('core.actions.Edit') }} </span>
                    <PencilAltIcon class="h-6 w-6" aria-hidden="true" />
                </UiActionButton>
            </div>
        </div>
        <div class="space-y-2 sm:flex">
            <div class="space-y-2 sm:flex sm:flex-1 sm:space-x-4">
                <p class="flex items-center text-sm text-gray-500">
                    <LocationMarkerIcon
                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                    />
                    {{ modelValue.city }}/{{ modelValue.state }}
                </p>
                <p class="flex items-center text-sm text-gray-500">
                    <InformationCircleIcon
                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                    />
                    {{ $t(`number.types.${modelValue.type}`) }}
                </p>
                <p class="flex items-center text-sm text-gray-500">
                    <ConnectFlowIcon
                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                    />
                    <component
                        :is="
                            $can(Permission.VIEW_CONNECT_FLOW) && modelValue.connectFlow?.id
                                ? 'RouterLink'
                                : 'span'
                        "
                        :to="{
                            name: 'EditFlowById',
                            params: {
                                id: modelValue.connectFlow?.id,
                            },
                        }"
                        :class="[
                            $can(Permission.VIEW_CONNECT_FLOW) &&
                                modelValue.connectFlow?.id &&
                                'text-primary font-medium',
                        ]"
                    >
                        {{ modelValue.connectFlow?.name || $t('number.list.noConnectFlow') }}
                    </component>
                </p>
                <p class="flex items-center text-sm text-gray-500">
                    <TagIcon
                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                    />
                    {{ modelValue.mediaLabel || $t('number.list.noMediaLabel') }}
                </p>
                <p class="flex items-center text-sm text-gray-500">
                    <UsersIcon
                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                    />
                    {{
                        $t('number.list.agentCount', modelValue.usersNumber?.length || 0, {
                            named: { n: modelValue.usersNumber?.length },
                        })
                    }}
                </p>
            </div>
            <UiAuditInfo :model-value="modelValue" />
        </div>
    </UiPanel>
</template>
