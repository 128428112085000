<script lang="ts" setup>
import { PlusIcon } from '@heroicons/vue/solid';
import { PropType } from 'vue';
import { ConnectFlowModelingActions, VueFlowNodeModel } from '@/types';
import store from '@/store';

const props = defineProps({
    node: {
        type: Object as PropType<VueFlowNodeModel>,
        required: true,
    },
});

function openComponentSelector() {
    store.dispatch('flow/' + ConnectFlowModelingActions.SET_MENU, {
        show: true,
        action: 'update',
        idTriggerBy: props.node.id,
    });
}
</script>

<template>
    <button
        class="rounded-full border border-red-400 bg-gray-50 p-1 shadow hover:border-blue-400 hover:bg-blue-50"
        @click="openComponentSelector"
    >
        <PlusIcon class="h-5 w-5" />
    </button>
</template>
