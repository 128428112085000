import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, watch } from 'vue';
import { useVuelidateWithLogging } from '@/composables/useVuelidateWithLogging';
import { TrashIcon } from '@heroicons/vue/solid';

import { isValidPhone, required, maxLength } from '@/utils/validators';
import { ContactPhoneFormState } from '@/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactPhoneForm',
  props: {
    modelValue: {
        type: Object as () => ContactPhoneFormState,
        required: true,
    },
    index: {
        type: Number,
        required: true,
    },
},
  emits: ['update:modelValue', 'remove'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const formState = reactive<ContactPhoneFormState>(props.modelValue);

const rules = computed(() => ({
    number: { isValidPhone, required, maxLength: maxLength(50) },
}));

const { v$, $validateWithLogging } = useVuelidateWithLogging(rules, formState);

__expose({
    validate: () => $validateWithLogging(),
});

watch(
    formState,
    () => {
        emit('update:modelValue', formState);
    },
    {
        deep: true,
    },
);

const __returned__ = { props, emit, formState, rules, v$, $validateWithLogging, get TrashIcon() { return TrashIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})