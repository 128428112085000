<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave } from 'vue-router';

const { t } = useI18n();

const props = defineProps({
    dirty: {
        type: Boolean,
        default: false,
    },
});

onBeforeRouteLeave(() => {
    if (props.dirty) {
        const answer = window.confirm(t('core.messages.confirmRouteLeave'));
        if (!answer) return false;
    } else return true;
});
</script>
<template>
    <div></div>
</template>
