import { defineComponent as _defineComponent } from 'vue'
import { onMounted, ref, Ref, computed, WritableComputedRef, PropType } from 'vue';
import {
    ConnectFlowModelingActions,
    ConnectFlowModelingGetters,
    ConnectFlowModelingMutations,
    VueFlowNodeModel,
    MultiSelectOption,
} from '@/types';
import store from '@/store';
import { ReplyIcon, PlusIcon } from '@heroicons/vue/outline';

import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { useI18n } from 'vue-i18n';
import { v4 as uuidv4 } from 'uuid';
import TransferToExternalNumberField from '@/views/pages/connect-flow/components/flow/nodes/FormElements/TransferToExternalNumberField.vue';
import { requiredIf } from '@/utils/validators';

interface IExternalNumber {
    id: string;
    phone: string;
}

interface IExternalFormState {
    numbers: IExternalNumber[];
    redirectType: undefined | string;
    whisper: null | string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TransferToExternal',
  props: {
    node: {
        type: Object as PropType<VueFlowNodeModel>,
        required: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const props = __props;

const showForm = ref(false);

const nodeWritable: WritableComputedRef<VueFlowNodeModel> = computed({
    get: () => store.getters['flow/' + ConnectFlowModelingGetters.ELEMENT_BY_ID](props.node.id),
    set: (value: VueFlowNodeModel) => {
        store.commit('flow/' + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
            idx: null,
            value,
        });
    },
});

const formState: Ref<IExternalFormState> = ref({
    numbers: [],
    redirectType: undefined,
    whisper: null,
});

const rules = computed<ValidationArgs<IExternalFormState>>(() => {
    const r: ValidationArgs<IExternalFormState> = {
        numbers: {},
        redirectType: {
            required: requiredIf(() => {
                return formState.value.numbers.length > 1;
            }),
        },
        whisper: {},
    };
    return r;
});
const v$: Ref<Validation<ValidationArgs<IExternalFormState>>> = useVuelidate(rules, formState);

const CallingRedirectType = {
    SEQUENTIAL: 'SEQUENTIAL',
    SIMULTANEOUS: 'SIMULTANEOUS',
};

const optionsType: Ref<MultiSelectOption[]> = ref([
    {
        label: t('connectFlow.node.transferToExternal.types.sequential'),
        value: CallingRedirectType.SEQUENTIAL,
    },
    {
        label: t('connectFlow.node.transferToExternal.types.simultaneous'),
        value: CallingRedirectType.SIMULTANEOUS,
    },
]);

const nodeText = computed(() => {
    let text = t('connectFlow.node.transferToExternal.empty');
    if (nodeWritable.value.data.callTo !== undefined) {
        text = t('connectFlow.node.transferToExternal.callTo', {
            number: nodeWritable.value.data.callTo.join('; '),
        });
    }
    return text;
});

const addNumber = () => {
    formState.value.numbers.push({
        id: uuidv4(),
        phone: '',
    });
};
const removeNumber = (index: number) => {
    formState.value.numbers.splice(index, 1);
};

const isFormCorrect = ref(true);

function removeNode() {
    store.dispatch('flow/' + ConnectFlowModelingActions.DELETE_NODE, {
        nodeId: nodeWritable.value.id,
        parentId: nodeWritable.value.parentId,
    });
}

async function onSubmit() {
    isFormCorrect.value = await v$.value.$validate();
    if (isFormCorrect.value && formState.value.numbers.length) {
        nodeWritable.value.data.callTo = formState.value.numbers.map((item) => item.phone);
        nodeWritable.value.data.redirectType = formState.value.redirectType ?? undefined;
        nodeWritable.value.data.whisper = formState.value.whisper ?? undefined;

        if (formState.value.numbers.length === 1) {
            nodeWritable.value.data.redirectType = undefined;
        }

        showForm.value = false;
    }
    nodeWritable.value.data.hasErrors = !isFormCorrect.value;
    v$.value.$reset();
}

onMounted(() => {
    if (nodeWritable.value.data.callTo !== undefined) {
        formState.value.numbers = nodeWritable.value.data.callTo.map((item) => {
            return { id: uuidv4(), phone: item };
        });
    }
    formState.value.redirectType = nodeWritable.value.data.redirectType;
    if (nodeWritable.value.data.whisper !== undefined) {
        formState.value.whisper = nodeWritable.value.data.whisper;
    }

    if (formState.value.numbers.length === 0) {
        formState.value.numbers.push({ id: uuidv4(), phone: '' });
    }

    v$.value.$validate().then((result) => {
        isFormCorrect.value = result;
        nodeWritable.value.data.hasErrors = !isFormCorrect.value;
    });
});

const __returned__ = { t, props, showForm, nodeWritable, formState, rules, v$, CallingRedirectType, optionsType, nodeText, addNumber, removeNumber, isFormCorrect, removeNode, onSubmit, get ReplyIcon() { return ReplyIcon }, get PlusIcon() { return PlusIcon }, TransferToExternalNumberField }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})