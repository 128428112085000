<script lang="ts" setup>
import store from '@/store';
import { NavigationActions } from '@/types';
import { MenuIcon } from '@heroicons/vue/outline';

function toogleMenu() {
    store.dispatch(NavigationActions.TOGGLE_SIDE_NAVIGATION, true);
}
</script>

<template>
    <div class="flex w-full items-center">
        <button
            type="button"
            class="focus:ring-primary mr-2 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset md:hidden"
            @click="toogleMenu()"
        >
            <span class="sr-only">Open sidebar</span>
            <MenuIcon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="flex w-screen items-center justify-between">
            <slot></slot>
        </div>
    </div>
</template>
