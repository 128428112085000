import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, ComputedRef, onMounted } from 'vue';
import moment from 'moment';
import { useI18n } from 'vue-i18n';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import { ContactCenterPerDayReportService } from '@/services';

import {
    BarChartConfig,
    CallsStatsPerDay,
    CallType,
    ContactCenterPerDayReportActions,
    ContactCenterPerDayReportFilter,
    ContactCenterPerDayReportGetters,
    ContactCenterPerDayReportServiceGetInfoParams,
    ContactCenterPerDayReportServiceGetInfoResult,
    DataItem,
    FilterItem,
    LevelModel,
} from '@/types';

import { ContactCenterPerDayReportFilters } from '@/views/pages/reports/contact-center-per-day/components/';

// Constants
const chartColorBlue = '#BFDBFE';
const chartColorCyan = '#A5F3FC';

// State

export default /*@__PURE__*/_defineComponent({
  __name: 'ContactCenterPerDayReportPage',
  setup(__props, { expose: __expose }) {
  __expose();

const reportInfo: Ref<ContactCenterPerDayReportServiceGetInfoResult | null> = ref(null);

// Computed
const headerStats = computed<DataItem<string>[]>(() => {
    const stats = reportInfo.value?.stats;
    if (!stats) return [];

    return [
        { name: 'totalCalls', value: stats.total.toString() },
        { name: 'inboundCalls', value: stats.type[CallType.INBOUND].toString() },
        { name: 'outboundCalls', value: stats.type[CallType.OUTBOUND].toString() },
        { name: 'internalCalls', value: stats.type[CallType.INTERNAL].toString() },
        { name: 'uniqueNumbers', value: stats.uniqueNumbers.toString() },
        {
            name: 'averageServiceTime',
            value: moment.utc((stats.averageServiceTime || 0) * 1000).format('HH:mm:ss'),
        },
    ];
});

const charts = computed<BarChartConfig[]>(() => [
    getChart(
        t('report.contactCenterPerDay.serviceTimePerDayColumnChart.title'),
        'averageServiceTime',
        chartColorBlue,
        yAxisLabelFormatterAsTime,
    ),
    getChart(
        t('report.contactCenterPerDay.durationPerDayColumnChart.title'),
        'averageDuration',
        chartColorCyan,
        yAxisLabelFormatterAsTime,
    ),
]);

// Composables
const uiStore = useUiStore();
const filterStore = useFilterStore();
const { t } = useI18n();

const levelFilter = computed<LevelModel | null>(() => filterStore.level);
const dateRange = computed<Array<Date>>({
    get: () => filterStore.dateRange,
    set: (value) => filterStore.setDateRange(value),
});
const filter = computed<ContactCenterPerDayReportFilter>(
    () => store.getters[ContactCenterPerDayReportGetters.FILTER],
);
const activeFilters: ComputedRef<FilterItem[]> = computed(
    () => store.getters[ContactCenterPerDayReportGetters.ACTIVE_FILTERS],
);

// Watchers
watch([levelFilter, dateRange, activeFilters], () => {
    fetchReportData();
});

// Methods
const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchReportData = async () => {
    const params: ContactCenterPerDayReportServiceGetInfoParams = {
        startDate: moment.utc(dateRange.value[0]).format('YYYY-MM-DD'),
        endDate: moment.utc(dateRange.value[1]).format('YYYY-MM-DD'),
        type: filter.value.type?.value || null,
        status: filter.value.status?.value || null,
        user: filter.value.user?.id || null,
        number: filter.value.number?.number || null,
        serviceTimeRange: filter.value.serviceTimeRange?.value || null,
        userLabel: filter.value.userLabel?.id || null,
    };

    try {
        setLoading(true);
        const { data } = await ContactCenterPerDayReportService.getInfo({ params });
        reportInfo.value = data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveFilter = (filter: FilterItem) => {
    store.dispatch(ContactCenterPerDayReportActions.CLEAR_FILTER, filter.field);
};

const getChart = (
    title: string,
    prop: keyof CallsStatsPerDay,
    color = chartColorBlue,
    yAxisLabelFormatter = (value: number | string) => String(value),
): BarChartConfig => {
    const stats = [...(reportInfo.value?.callsStatsPerDay || [])].reverse();

    const category = stats.map((s) => s.date);
    const value = stats.map((s) => s[prop] || 0);

    return {
        title,
        category,
        series: [
            {
                name: title,
                value,
                color,
            },
        ],
        yAxisLabelFormatter,
    };
};

const yAxisLabelFormatterAsTime = (value: number | string) =>
    moment.utc((Number(value) || 0) * 1000).format('HH:mm:ss');

const xAxisLabelFormatterAsDate = (value: number | string) => moment.utc(value).format('DD/MM');

// Lifecycle
onMounted(() => {
    fetchReportData();
});

const __returned__ = { chartColorBlue, chartColorCyan, reportInfo, headerStats, charts, uiStore, filterStore, t, levelFilter, dateRange, filter, activeFilters, setLoading, fetchReportData, handleRemoveFilter, getChart, yAxisLabelFormatterAsTime, xAxisLabelFormatterAsDate, get ContactCenterPerDayReportFilters() { return ContactCenterPerDayReportFilters } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})