<script lang="ts" setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { TagIcon } from '@heroicons/vue/solid';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import WordService from '@/services/api/WordService';
import { toast, toastServiceError } from '@/services/core/notification';

import {
    FilterItem,
    Permission,
    SortMenuItem,
    WordActions,
    WordFilter,
    WordGetters,
    WordModel,
    WordServiceGetAllParams,
} from '@/types';
import { WordListItem, WordListSort, WordListFilters } from '@/views/pages/word/components';

const route = useRoute();
const router = useRouter();

const filterStore = useFilterStore();
const uiStore = useUiStore();

const words = ref<WordModel[]>([]);
const count = ref(0);
const perPage = 10;

const search = computed({
    get: () => store.getters[WordGetters.SEARCH],
    set: (value: string) => store.dispatch(WordActions.SET_SEARCH, value),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[WordGetters.SORT],
    set: (value: SortMenuItem[]) => store.dispatch(WordActions.SET_SORT, value),
});

const levelFilter = computed(() => filterStore.level);

const currentPage = computed({
    get: () => store.getters[WordGetters.PAGE],
    set: (value: number) => store.dispatch(WordActions.SET_PAGE, value),
});

const filter: WordFilter = store.getters[WordGetters.FILTER];

const activeFilters = computed<FilterItem[]>(() => store.getters[WordGetters.ACTIVE_FILTERS]);

const orderBy = computed(() => sortMenuSelected.value.map((s) => s.direction + s.key).join(','));

watch([currentPage], () => fetchWords());

watch([levelFilter, search, filter, orderBy], () => {
    if (currentPage.value === 1) fetchWords();
    else currentPage.value = 1;
});

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getParams = (): WordServiceGetAllParams => ({
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    call_participant:
        filter.callParticipant !== null ? (filter.callParticipant.value as string) : undefined,
    is_active: filter.isActive !== null ? (filter.isActive.value as boolean) : undefined,
    search: search.value,
});

const fetchWords = async () => {
    setLoading(true);
    try {
        const { data, headers } = await WordService.getAll<WordModel[]>({
            params: getParams(),
        });
        words.value = data;
        count.value = parseInt(headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const setIsActive = async (item: WordModel, isActive: boolean) => {
    setLoading(true);
    try {
        const { data } = await (
            isActive
                ? (id: number) => WordService.activate(id)
                : (id: number) => WordService.inactivate(id)
        )(item.id);
        toast.success(data.message);
        await fetchWords();
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveSortClick = (item: SortMenuItem) => {
    const index = sortMenuSelected.value.indexOf(item);
    if (index > -1) sortMenuSelected.value.splice(index, 1);
};

const handleRemoveFilterClick = (item: FilterItem) =>
    store.dispatch(WordActions.CLEAR_FILTER, item);

const handleActivateClick = (item: WordModel) => setIsActive(item, true);

const handleInactivateClick = (item: WordModel) => setIsActive(item, false);

onMounted(() => {
    if (route.query.clearStateOnSetup) {
        search.value = '';
        sortMenuSelected.value = [];
        currentPage.value = 1;
        router.replace({ query: {} });
    }

    fetchWords();
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="flex items-center text-xl font-semibold text-gray-800">
                    <TagIcon class="mr-1 h-6 w-6 text-gray-500" aria-hidden="true" />
                    {{ $t('core.entity.Words') }}
                </h1>
            </template>
            <template #actions>
                <router-link v-if="$can(Permission.CREATE_WORD)" :to="{ name: 'CreateWord' }">
                    <UiButton variant="primary" text-variant="white">
                        {{ $t('core.actions.Add') }}
                    </UiButton>
                </router-link>
            </template>
        </UiPageHeader>

        <div class="space-y-2">
            <UiPanel class="flex px-4 py-2" no-padding>
                <UiListSearch
                    v-model="search"
                    :placeholder="$t('word.filters.search')"
                    class="flex-1"
                />
                <div class="flex items-center space-x-4">
                    <WordListSort v-model="sortMenuSelected" />
                    <WordListFilters />
                </div>
            </UiPanel>

            <UiPanel v-if="sortMenuSelected.length || activeFilters.length" class="space-x-2">
                <UiSortMenuBadge
                    v-for="selected in sortMenuSelected"
                    :key="selected.key"
                    :item="selected"
                    @remove="handleRemoveSortClick(selected)"
                />
                <UiBadge
                    v-for="item in activeFilters"
                    :key="`activeFilter${item.field}`"
                    removable
                    @remove="handleRemoveFilterClick(item)"
                >
                    {{ $t(`word.filters.${item.field}.label`) }}: {{ item.label }}
                </UiBadge>
            </UiPanel>

            <div v-if="words.length" class="space-y-2">
                <WordListItem
                    v-for="word in words"
                    :key="word.id"
                    :model-value="word"
                    @activate="handleActivateClick"
                    @inactivate="handleInactivateClick"
                />
            </div>
            <UiPanel v-else class="flex items-center justify-center">
                {{ $t('core.messages.noRecordsFound') }}
            </UiPanel>

            <UiPagination
                v-if="words.length"
                v-model="currentPage"
                :count="count"
                :perPage="perPage"
            />
        </div>
    </div>
</template>
