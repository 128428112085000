import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, ComputedRef, onMounted } from 'vue';
import moment from 'moment';
import orderBy from 'lodash.orderby';
import { useI18n } from 'vue-i18n';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import { ContactCenterPerAgentReportService } from '@/services';

import {
    CallType,
    ChartDataItem,
    ContactCenterReportActions,
    ContactCenterReportFilter,
    ContactCenterReportGetters,
    ContactCenterReportServiceGetInfoParams,
    ContactCenterReportServiceGetInfoResult,
    DataItem,
    FilterItem,
    LevelModel,
    UserAgentCallsStats,
} from '@/types';

import { ContactCenterPerAgentReportFilters } from '@/views/pages/reports/contact-center-per-agent/components/';

// Constants and props
interface IContactCenterReportChart {
    title: string;
    category: Array<string>;
    series: Array<ChartDataItem<number[]>>;
    xAxisLabelFormatter: (value: number) => string;
}

// State

export default /*@__PURE__*/_defineComponent({
  __name: 'ContactCenterPerAgentReportPage',
  setup(__props, { expose: __expose }) {
  __expose();

const reportInfo: Ref<ContactCenterReportServiceGetInfoResult | null> = ref(null);

// Computed
const headerStats = computed<DataItem<string>[]>(() => {
    const stats = reportInfo.value?.stats;
    if (!stats) return [];

    return [
        { name: 'totalCalls', value: stats.total.toString() },
        { name: 'inboundCalls', value: stats.type[CallType.INBOUND].toString() },
        { name: 'outboundCalls', value: stats.type[CallType.OUTBOUND].toString() },
        { name: 'internalCalls', value: stats.type[CallType.INTERNAL].toString() },
        { name: 'uniqueNumbers', value: stats.uniqueNumbers.toString() },
        {
            name: 'averageServiceTime',
            value: moment.utc((stats.averageServiceTime || 0) * 1000).format('HH:mm:ss'),
        },
    ];
});

const xAxisLabelFormatterAsTime = (value: number) => moment.utc(value * 1000).format('HH:mm:ss');

const charts = computed<IContactCenterReportChart[]>(() => {
    return [
        getChart(
            t('report.contactCenterPerAgent.serviceTimePerUserBarChart.title'),
            'averageServiceTime',
            '#BFDBFE',
            xAxisLabelFormatterAsTime,
        ),
        getChart(
            t('report.contactCenterPerAgent.durationPerUserBarChart.title'),
            'averageDuration',
            '#A5F3FC',
            xAxisLabelFormatterAsTime,
        ),
    ];
});

// Composables
const filterStore = useFilterStore();
const uiStore = useUiStore();
const { t } = useI18n();

const levelFilter = computed<LevelModel | null>(() => filterStore.level);
const dateRange = computed<Array<Date>>({
    get: () => filterStore.dateRange,
    set: (value) => filterStore.setDateRange(value),
});
const filter = computed<ContactCenterReportFilter>(
    () => store.getters[ContactCenterReportGetters.FILTER],
);
const activeFilters: ComputedRef<FilterItem[]> = computed(
    () => store.getters[ContactCenterReportGetters.ACTIVE_FILTERS],
);

// Watchers
watch([levelFilter, dateRange, activeFilters], () => {
    fetchReportData();
});

// Methods
const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchReportData = async () => {
    const params: ContactCenterReportServiceGetInfoParams = {
        startDate: moment.utc(dateRange.value[0]).format('YYYY-MM-DD'),
        endDate: moment.utc(dateRange.value[1]).format('YYYY-MM-DD'),
        type: filter.value.type?.value || null,
        status: filter.value.status?.value || null,
        user: filter.value.user?.id || null,
        number: filter.value.number?.number || null,
        serviceTimeRange: filter.value.serviceTimeRange?.value || null,
        userLabel: filter.value.userLabel?.id || null,
    };

    try {
        setLoading(true);
        const { data } = await ContactCenterPerAgentReportService.getInfo({ params });
        reportInfo.value = data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveFilter = (filter: FilterItem) => {
    store.dispatch(ContactCenterReportActions.CLEAR_FILTER, filter.field);
};

const getChart = (
    title: string,
    prop: keyof UserAgentCallsStats,
    color: string,
    xAxisLabelFormatter: (value: number) => string,
): IContactCenterReportChart => {
    const topUsers = orderBy(reportInfo.value?.userAgentCallsStats, prop, 'desc').slice(0, 10);
    const category: string[] = [];
    const serie: ChartDataItem<number[]> = { name: title, value: [], color };

    for (let i = topUsers.length - 1; i >= 0; i--) {
        const user = topUsers[i];
        category.push(user.name);
        serie.value.push(user[prop] as number);
    }

    return {
        title,
        category,
        series: [serie],
        xAxisLabelFormatter,
    };
};

// Lifecycle
onMounted(() => {
    fetchReportData();
});

const __returned__ = { reportInfo, headerStats, xAxisLabelFormatterAsTime, charts, filterStore, uiStore, t, levelFilter, dateRange, filter, activeFilters, setLoading, fetchReportData, handleRemoveFilter, getChart, get ContactCenterPerAgentReportFilters() { return ContactCenterPerAgentReportFilters } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})