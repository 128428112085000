import {
    ConnectFlowModel,
    ConnectFlowAudioModel,
    VueFlowConnectionModel,
    VueFlowNodeModel,
    QueueModel,
    ConnectFlowSurveyModel,
    ScheduleModel,
    UserExtensionModel,
} from '@/types';

export interface ConnectFlowModelingMenu {
    show: boolean;
    action: string;
    idTriggerBy: string | null;
}
export interface ConnectFlowModelingState {
    connectFlow: ConnectFlowModel;
    elements: (VueFlowNodeModel | VueFlowConnectionModel)[];
    menu: ConnectFlowModelingMenu;
    scheduleOptions: ScheduleModel[];
    queueOptions: QueueModel[];
    surveyOptions: ConnectFlowSurveyModel[];
    audioOptions: ConnectFlowAudioModel[];
    extensionsOptions: UserExtensionModel[];
}

export enum ConnectFlowModelingMutations {
    SET_MENU = 'setMenu',
    SET_MENU_TRIGGER_BY = 'setMenuTriggerBY',
    SET_CONNECT_FLOW = 'setConnectFlow',
    PUSH_NEW_ELEMENT = 'setNewElement',
    SET_ELEMENTS = 'setElements',
    SET_SCHEDULE = 'setSchedule',
    SET_QUEUE = 'setQueue',
    SET_SURVEY = 'setSurvey',
    SET_AUDIO = 'setAudio',
    SET_EXTENSIONS = 'setExtension',
    UPDATE_ELEMENT = 'updateElement',
    DELETE_ELEMENTS = 'deleteElements',
}

export enum ConnectFlowModelingActions {
    SET_MENU = 'setMenuShow',
    SET_ELEMENTS = 'setElements',
    SET_CONNECT_FLOW = 'setConnectFlow',
    PUSH_NEW_NODE = 'pushNewNode',
    EDIT_NODE = 'editNode',
    DELETE_NODE = 'deleteNode',
    GO_TO_CONNECTION = 'goToConnection',
    LOAD_FLOW_FLOW = 'loadFLowFlow',
}

export enum ConnectFlowModelingGetters {
    MENU = 'menu',
    CONNECT_FLOW = 'connectFlow',
    ELEMENTS = 'elements',
    ELEMENT_BY_ID = 'elementById',
    ELEMENTS_NODES = 'elementsNodes',
    ELEMENTS_CONNECTIONS = 'elementsConnections',
    IS_ALL_ELEMENTS_VALID = 'isAllElementsValid',
    SCHEDULE_OPTIONS = 'scheduleOptions',
    AUDIO_OPTIONS = 'audioOptions',
    QUEUE_OPTIONS = 'queueOptions',
    SURVEY_OPTIONS = 'surveyOptions',
    EXTENSION_OPTIONS = 'extensionsOptions',
}
