import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiButton = _resolveComponent("UiButton")!

  return (_openBlock(), _createBlock(_component_UiButton, {
    variant: "inherit",
    "text-variant": $props.variant,
    "ring-variant": $props.variant,
    "no-border": "",
    "no-shadow": "",
    "no-padding": "",
    "no-rounded": "",
    class: "rounded-full text-sm",
    to: $props.to
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["text-variant", "ring-variant", "to"]))
}