<script lang="ts" setup>
import { computed, reactive, ref, Ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { required, maxLength } from '@/utils/validators';

import { useUiStore } from '@/stores';
import { RoleService, UserService } from '@/services';
import { toast, toastServiceError } from '@/services/core/notification';
import { goBack } from '@/composables/useNavigation';

import {
    DefaultServiceResult,
    FormAction,
    Permission,
    RoleFormState,
    RoleModel,
    RoleServiceCreateResult,
    UserModel,
} from '@/types';

const route = useRoute();
const uiStore = useUiStore();

const props = defineProps({
    action: {
        type: String,
        default: 'view',
    },
});

const formState = reactive<RoleFormState>({
    name: '',
    users: [],
});

const rules = computed(() => ({
    name: { required, maxLength: maxLength(500) },
    users: {},
}));

const v$: Ref<Validation<ValidationArgs<RoleFormState>>> = useVuelidate(rules, formState);

const usersRaw: Ref<UserModel[]> = ref([]);

const users = computed(() =>
    usersRaw.value.map((user) => ({
        id: user.id,
        name: user.name,
    })),
);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getUsers = async () => {
    try {
        setLoading(true);
        const response = await UserService.getAll<UserModel[]>({
            params: { page: 1, per_page: 100 },
        });
        usersRaw.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const getRole = async (id: number) => {
    try {
        setLoading(true);
        const { data } = await RoleService.get<RoleModel>(id);
        formState.name = data.name;
        formState.users = data.users?.map((u) => u.id) || [];
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const onSubmit = async () => {
    const isFormCorrect = await v$.value.$validate();
    if (!isFormCorrect) return;

    try {
        setLoading(true);

        if (props.action === FormAction.EDIT) {
            const id = Number(route.params.id);
            const response = await RoleService.update<DefaultServiceResult, RoleFormState>(
                id,
                formState,
            );
            toast.success(response.data.message);
            v$.value.$reset();
            goBack({ name: 'ViewRoleById', params: { id } });
        } else {
            const response = await RoleService.create<RoleServiceCreateResult, RoleFormState>(
                formState,
            );
            toast.success(response.data.message);
            v$.value.$reset();
            goBack({ name: 'ViewRoleById', params: { id: response.data.result.id } });
        }
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

onMounted(() => {
    getUsers();
    if (props.action === FormAction.EDIT) {
        getRole(Number(route.params.id));
    }
});
</script>

<template>
    <form class="space-y-4 p-4 sm:p-6 lg:p-8" autocomplete="off" @submit.prevent="onSubmit">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('role.' + props.action + '.title') }}
                </h1>
            </template>
            <template #actions>
                <div class="flex items-center space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ViewRoleById' }" />
                    <UiButton
                        v-if="
                            (props.action == FormAction.CREATE && $can(Permission.CREATE_ROLE)) ||
                            (props.action == FormAction.EDIT && $can(Permission.EDIT_ROLE))
                        "
                        type="submit"
                        variant="primary"
                        text-variant="white"
                    >
                        {{ $t('core.actions.Save') }}
                    </UiButton>
                </div>
            </template>
        </UiPageHeader>
        <UiPanel>
            <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
                <UiTextInput
                    v-model="v$.name.$model"
                    name="name"
                    type="text"
                    :label="$t('role.labels.name')"
                    :errors="v$.name.$errors"
                    @blur="v$.name.$touch"
                />

                <div>
                    <label for="users" class="mb-2 block text-sm font-medium text-gray-800">
                        {{ $t('role.labels.users') }}
                    </label>
                    <UiMultiselect
                        id="users"
                        name="users"
                        label="name"
                        value-prop="id"
                        v-model="formState.users"
                        multiple
                        :primitive="true"
                        :options="users"
                    />
                </div>
            </div>
        </UiPanel>
        <UiCheckDirtyBeforeRouteLeave :dirty="v$.$anyDirty" />
    </form>
</template>
