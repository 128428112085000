<script lang="ts" setup>
import { computed, reactive, Ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AxiosResponse } from 'axios';

import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { required, maxLength } from '@/utils/validators';

import { useUiStore } from '@/stores';
import { goBack } from '@/composables/useNavigation';
import { LevelService } from '@/services';
import { toast, toastServiceError } from '@/services/core/notification';

import { Permission, FormAction, LevelModel, LevelFormState } from '@/types';

const uiStore = useUiStore();
const route = useRoute();

const props = defineProps({
    action: { type: String, default: FormAction.CREATE },
});

const formState = reactive<LevelFormState>({
    name: null,
    isActive: true,
});

const rules = computed<ValidationArgs<LevelFormState>>(() => ({
    name: { required, maxLength: maxLength(500) },
    isActive: { required },
}));

const v$: Ref<Validation<ValidationArgs<LevelFormState>>> = useVuelidate(rules, formState);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchLevel = async (id: number) => {
    try {
        setLoading(true);
        const { data } = await LevelService.get<LevelModel>(id);
        formState.name = data.name;
        formState.isActive = data.isActive;
    } catch (e) {
        toastServiceError(e);
    } finally {
        setLoading(false);
    }
};

const onSubmit = async () => {
    const isFormValid = await v$.value.$validate();
    if (!isFormValid) return;

    setLoading(true);

    const handleServiceSuccess = (message: string, id: number) => {
        toast.success(message);
        v$.value.$reset();
        goBack({ name: 'ViewLevelById', params: { id } });
    };

    try {
        if (props.action === FormAction.EDIT) {
            const id = Number(route.params.id);
            const { data }: AxiosResponse = await LevelService.update(id, formState);
            handleServiceSuccess(data.message, id);
        } else {
            const { data }: AxiosResponse = await LevelService.create(formState);
            handleServiceSuccess(data.message, data.result.id);
        }
    } catch (e) {
        toastServiceError(e);
    } finally {
        setLoading(false);
    }
};

onMounted(async () => {
    if (props.action === FormAction.EDIT) {
        const levelId = Number(route.params.id);
        if (!isNaN(levelId)) fetchLevel(levelId);
    }
});
</script>

<template>
    <form class="space-y-4 p-4 sm:p-6 lg:p-8" autocomplete="off" @submit.prevent="onSubmit">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('level.' + props.action + '.title') }}
                </h1>
            </template>
            <template #actions>
                <div class="flex items-center space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ListLevel' }" />
                    <UiButton
                        v-if="
                            (props.action == FormAction.CREATE && $can(Permission.CREATE_LEVEL)) ||
                            (props.action == FormAction.EDIT && $can(Permission.EDIT_LEVEL))
                        "
                        type="submit"
                        variant="primary"
                        text-variant="white"
                    >
                        {{ $t('core.actions.Save') }}
                    </UiButton>
                </div>
            </template>
        </UiPageHeader>
        <UiPanel>
            <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
                <UiTextInput
                    v-model="v$.name.$model"
                    name="name"
                    type="text"
                    :label="$t('level.labels.name')"
                    :errors="v$.name.$errors"
                    @blur="v$.name.$touch"
                />

                <UiCheckboxInput
                    v-model="v$.isActive.$model"
                    name="isActive"
                    :label="$t('level.labels.isActive')"
                    class="md:pt-6"
                />
            </div>
        </UiPanel>
        <UiCheckDirtyBeforeRouteLeave :dirty="v$.$anyDirty" />
    </form>
</template>
