import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, ref, onMounted, type Ref } from 'vue';
import { useRoute } from 'vue-router';
import { useVuelidate, type Validation, type ValidationArgs } from '@vuelidate/core';

import { required, maxLength } from '@/utils/validators';
import { goBack } from '@/composables/useNavigation';
import { useUiStore } from '@/stores';
import { UserLabelService, UserService } from '@/services';
import { toast, toastServiceError } from '@/services/core/notification';
import {
    FormAction,
    type UserModel,
    type UserLabelModel,
    type UserLabelFormState,
    Permission,
} from '@/types';
import type { AxiosResponse } from 'axios';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserLabelFormPage',
  props: {
    action: { type: String, default: FormAction.CREATE },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const route = useRoute();

const uiStore = useUiStore();

const formState = reactive<UserLabelFormState>({
    label: null,
    users: [],
    isActive: true,
});

const rules = computed<ValidationArgs<UserLabelFormState>>(() => ({
    label: { required, maxLength: maxLength(50) },
    users: {},
    isActive: { required },
}));

const v$: Ref<Validation<ValidationArgs<UserLabelFormState>>> = useVuelidate(rules, formState);

const users: Ref<UserModel[]> = ref([]);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchUsers = async () => {
    setLoading(true);
    try {
        const { data } = await UserService.getAll<UserModel[]>({
            params: { page: 1, per_page: 100 },
        });
        users.value = data;
    } catch (e) {
        toastServiceError(e);
    } finally {
        setLoading(false);
    }
};

const fetchUserLabel = async (id: number) => {
    setLoading(true);
    try {
        const { data } = await UserLabelService.get<UserLabelModel>(id);
        formState.label = data.label;
        formState.users = data.users.map((u) => u.id);
        formState.isActive = data.isActive;
    } catch (e) {
        toastServiceError(e);
    } finally {
        setLoading(false);
    }
};

const onSubmit = async () => {
    const isFormValid = await v$.value.$validate();
    if (!isFormValid) return;

    setLoading(true);

    const handleServiceSuccess = (message: string, id: number) => {
        toast.success(message);
        v$.value.$reset();
        goBack({ name: 'ViewUserLabelById', params: { id } });
    };

    try {
        if (props.action === FormAction.EDIT) {
            const id = Number(route.params.id);
            const { data }: AxiosResponse = await UserLabelService.update(id, formState);
            handleServiceSuccess(data.message, id);
        } else {
            const { data }: AxiosResponse = await UserLabelService.create(formState);
            handleServiceSuccess(data.message, data.result.id);
        }
    } catch (e) {
        toastServiceError(e);
    } finally {
        setLoading(false);
    }
};

onMounted(async () => {
    await fetchUsers();
    if (props.action === FormAction.EDIT) {
        await fetchUserLabel(Number(route.params.id));
    }
});

const __returned__ = { props, route, uiStore, formState, rules, v$, users, setLoading, fetchUsers, fetchUserLabel, onSubmit, get FormAction() { return FormAction }, get Permission() { return Permission } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})