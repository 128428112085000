import { defineComponent as _defineComponent } from 'vue'
import { ConnectFlowSurveyModel } from '@/types';

import { AnnotationIcon } from '@heroicons/vue/outline';


export default /*@__PURE__*/_defineComponent({
  __name: 'CallSurvey',
  props: {
    survey: {
        type: Object as () => ConnectFlowSurveyModel,
        required: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();



const __returned__ = { get AnnotationIcon() { return AnnotationIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})