import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue';
import store from '@/store';
import { ChartBarIcon, CurrencyDollarIcon, PhoneIcon, ReplyIcon } from '@heroicons/vue/solid';
import { Permission, DashboardItem, AuthGetters, UserModel } from '@/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'HomePage',
  setup(__props, { expose: __expose }) {
  __expose();

const user = computed<UserModel>(() => store.getters[AuthGetters.USER]);

const links: DashboardItem[] = [
    {
        title: 'dashboard.calls',
        description: 'call.description',
        permission: Permission.VIEW_CALLS,
        routeName: 'CallList',
        icon: PhoneIcon,
    },
    {
        title: 'report.call.title',
        description: 'report.call.description',
        permission: Permission.VIEW_CALL_REPORT,
        routeName: 'CallReport',
        icon: ChartBarIcon,
    },
    {
        title: 'report.contactCenterPerDay.title',
        description: 'report.contactCenterPerDay.description',
        permission: Permission.VIEW_CONTACT_CENTER_PER_DAY_REPORT,
        routeName: 'ContactCenterPerDayReport',
        icon: ChartBarIcon,
    },
    {
        title: 'report.contactCenterPerAgent.title',
        description: 'report.contactCenterPerAgent.description',
        permission: Permission.VIEW_CONTACT_CENTER_PER_AGENT_REPORT,
        routeName: 'ContactCenterPerAgentReport',
        icon: ChartBarIcon,
    },
    {
        title: 'report.agentProductivityPerDay.title',
        description: 'report.agentProductivityPerDay.description',
        permission: Permission.VIEW_AGENT_PRODUCTIVITY_PER_DAY_REPORT,
        routeName: 'AgentProductivityPerDayReport',
        icon: ChartBarIcon,
    },
    {
        title: 'report.agentProductivityPerAgent.title',
        description: 'report.agentProductivityPerAgent.description',
        permission: Permission.VIEW_AGENT_PRODUCTIVITY_PER_AGENT_REPORT,
        routeName: 'AgentProductivityPerAgentReport',
        icon: ChartBarIcon,
    },
    {
        title: 'report.realtime.title',
        description: 'report.realtime.description',
        permission: Permission.VIEW_REALTIME_REPORT,
        routeName: 'RealtimeReport',
        icon: ChartBarIcon,
    },
    {
        title: 'report.realtimeAgentExtension.title',
        description: 'report.realtimeAgentExtension.description',
        permission: Permission.VIEW_REALTIME_AGENT_EXTENSION_REPORT,
        routeName: 'RealtimeAgentExtensionReport',
        icon: ChartBarIcon,
    },
    {
        title: 'report.billing.title',
        description: 'report.billing.description',
        permission: Permission.VIEW_BILLING_REPORT,
        routeName: 'BillingReportPage',
        icon: CurrencyDollarIcon,
    },
];

const __returned__ = { user, links, get ReplyIcon() { return ReplyIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})