<script lang="ts" setup>
import { onMounted } from 'vue';
import router from '@/router';
import { useRoute } from 'vue-router';

const route = useRoute();

onMounted(() => {
    if (route.query.token !== null) {
        localStorage.removeItem('level');
        localStorage.removeItem('locale');
        localStorage.setItem('token', route.query.token.toString());
    }
    router.push('/');
});
</script>
