<script lang="ts" setup>
import { ref, type Ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import { useUiStore } from '@/stores';
import { WordService } from '@/services';
import { toastServiceError } from '@/services/core/notification';
import { type WordModel, Permission } from '@/types';

const route = useRoute();

const uiStore = useUiStore();

const word: Ref<WordModel | undefined> = ref(undefined);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchWord = async (id: number) => {
    setLoading(true);
    try {
        const response = await WordService.get<WordModel>(id);
        word.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

onMounted(() => {
    fetchWord(Number(route.params.id));
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8" v-if="word !== undefined">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('word.view.title') }}
                </h1>
            </template>
            <template #actions>
                <div class="flex items-center space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ListWord' }" />
                    <router-link
                        v-if="$can(Permission.EDIT_WORD)"
                        :to="{
                            name: 'EditWordById',
                            params: {
                                id: word?.id,
                            },
                        }"
                        class="block hover:bg-gray-50"
                    >
                        <UiButton variant="primary" text-variant="white">
                            {{ $t('core.actions.Edit') }}
                        </UiButton>
                    </router-link>
                </div>
            </template>
        </UiPageHeader>

        <div class="space-y-4">
            <UiPanel>
                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                    <div>
                        <h3 class="mb-1 font-semibold">
                            {{ $t('word.labels.word') }}
                        </h3>
                        <p>{{ word.word }}</p>
                    </div>
                    <div>
                        <h3 class="mb-1 font-semibold">
                            {{ $t('word.labels.callParticipant') }}
                        </h3>
                        <p>{{ $t(`word.participant.${word.callParticipant}`) }}</p>
                    </div>
                </div>
                <UiAuditStatusInfo
                    :model-value="word"
                    class="bt-1 mt-4 border-t border-gray-300 pt-2"
                />
            </UiPanel>
        </div>
    </div>
</template>
