import {
    ConnectFlowModel,
    ConnectFlowFlowModel,
    VueFlowConnectionModel,
    VueFlowNodeModel,
} from '@/types';
import BaseService from '@/services/api/BaseService';
import { AxiosRequestConfig } from 'axios';

class ConnectFlowService<T> extends BaseService<ConnectFlowModel> {
    getFlow = (id: number, historyId?: number) => {
        return this.http.get<ConnectFlowFlowModel>(`${this.route}/${id}/flow`, {
            params: { historyId: historyId },
        });
    };
    saveFlow<ResponseT = T[]>(id: string, data: (VueFlowNodeModel | VueFlowConnectionModel)[]) {
        return this.http.post<ResponseT>(`${this.route}/${id}/flow`, data);
    }
    publishFlow<ResponseT = T[]>(id: string, data: (VueFlowNodeModel | VueFlowConnectionModel)[]) {
        return this.http.put<ResponseT>(`${this.route}/${id}/flow-publish`, data);
    }
    getFlowHistory<ResponseT = T[]>(id: number | string, config?: AxiosRequestConfig) {
        return this.http.get<ResponseT>(`${this.route}/${id}/history`, config);
    }
}

export default new ConnectFlowService('connect-flows');
