<script lang="ts" setup>
import { PropType } from 'vue';
import { LevelModel, Permission } from '@/types';
import { UsersIcon, PhoneIcon } from '@heroicons/vue/solid';
import { CheckCircleIcon, XCircleIcon, PencilAltIcon } from '@heroicons/vue/outline';
import UiAuditInfo from '@/components/UiAuditInfo.vue';

const props = defineProps({
    modelValue: {
        type: Object as PropType<LevelModel>,
        required: true,
    },
});

const emit = defineEmits(['activate', 'inactivate']);

const handleActivateClick = () => emit('activate', props.modelValue);
const handleInactivateClick = () => emit('inactivate', props.modelValue);
</script>

<template>
    <UiPanel class="hover:bg-white">
        <div class="flex items-center justify-between">
            <div class="flex flex-1 items-center">
                <component
                    :is="$can(Permission.VIEW_LEVEL) ? 'RouterLink' : 'span'"
                    :to="{
                        name: 'ViewLevelById',
                        params: {
                            id: modelValue.id,
                        },
                    }"
                    class="text-md text-primary truncate font-medium"
                >
                    {{ modelValue.name }}
                </component>
                <UiIsActiveBadge :model-value="modelValue" class="ml-2 text-xs" />
            </div>
            <div v-if="$can(Permission.EDIT_LEVEL)" class="flex space-x-1">
                <UiActionButton
                    v-if="modelValue.isActive"
                    v-tippy="$t('core.actions.Inactivate')"
                    variant="danger"
                    @click="handleInactivateClick"
                >
                    <span class="sr-only"> {{ $t('core.actions.Inactivate') }} </span>
                    <XCircleIcon class="h-6 w-6" aria-hidden="true" />
                </UiActionButton>
                <UiActionButton
                    v-else
                    v-tippy="$t('core.actions.Activate')"
                    variant="success"
                    @click="handleActivateClick"
                >
                    <span class="sr-only"> {{ $t('core.actions.Activate') }} </span>
                    <CheckCircleIcon class="h-6 w-6" aria-hidden="true" />
                </UiActionButton>
                <UiActionButton
                    v-tippy="$t('core.actions.Edit')"
                    variant="primary"
                    class="rounded-full"
                    :to="{
                        name: 'EditLevelById',
                        params: {
                            id: modelValue.id,
                        },
                    }"
                >
                    <span class="sr-only"> {{ $t('core.actions.Edit') }} </span>
                    <PencilAltIcon class="h-6 w-6" aria-hidden="true" />
                </UiActionButton>
            </div>
        </div>
        <div class="mt-2 sm:flex sm:justify-between">
            <div class="space-x-4 sm:flex">
                <p class="flex items-center text-sm text-gray-500">
                    <UsersIcon
                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                    />
                    {{
                        $t('level.list.usersCount', modelValue.usersCount || 0, {
                            named: { n: modelValue.usersCount },
                        })
                    }}
                </p>
                <p class="flex items-center text-sm text-gray-500 sm:ml-6 sm:mt-0">
                    <PhoneIcon
                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                    />
                    {{
                        $t('level.list.numbersCount', modelValue.numbersCount || 0, {
                            named: { n: modelValue.numbersCount },
                        })
                    }}
                </p>
            </div>
            <UiAuditInfo :model-value="modelValue" />
        </div>
    </UiPanel>
</template>
