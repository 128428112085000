import { defineComponent as _defineComponent } from 'vue'
import { PropType } from 'vue';
import { SpamNumberModel, Permission } from '@/types';
import { applyPhoneMask } from '@/utils/number';
import { PencilAltIcon } from '@heroicons/vue/outline';


export default /*@__PURE__*/_defineComponent({
  __name: 'SpamNumberListItem',
  props: {
    modelValue: {
        type: Object as PropType<SpamNumberModel>,
        required: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();



const __returned__ = { get Permission() { return Permission }, get applyPhoneMask() { return applyPhoneMask }, get PencilAltIcon() { return PencilAltIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})