<script lang="ts" setup>
import { computed } from 'vue';
import { IsActiveFlag } from '@/types';

const { modelValue } = defineProps<{
    modelValue: IsActiveFlag | boolean;
}>();

const shouldRender = computed(() => {
    return (
        typeof modelValue === 'boolean' ||
        (typeof modelValue === 'object' && 'isActive' in modelValue)
    );
});

const isActive = computed(() => {
    if (typeof modelValue === 'boolean') return modelValue;
    if (typeof modelValue === 'object') return modelValue.isActive;
    return false;
});
</script>

<template>
    <span
        v-if="shouldRender"
        :class="[isActive ? 'bg-success-50 text-success-700' : 'bg-danger-100 text-danger-700']"
        class="inline-flex rounded-full px-2 font-semibold"
    >
        {{ isActive ? $t('core.options.Active') : $t('core.options.Inactive') }}
    </span>
</template>
