import { VueFlowNodeDataModel } from '@/types';

interface IAutoChildren {
    data: VueFlowNodeDataModel;
    sourceHandle?: string;
    position: {
        x: number;
        y?: number;
    };
}

export interface FlowNodeModel {
    maxChildren: number;
    typesChildren: string[];
    autoChildren: IAutoChildren[];
    position: { x: number; y?: number };
}
