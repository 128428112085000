<script lang="ts" setup>
import { ref, Ref, computed, watch, ComputedRef, onMounted } from 'vue';
import moment from 'moment';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import CallReportService from '@/services/api/CallReportService';
import { formatSecToTime } from '@/utils/datetime';

import {
    CallReportActions,
    CallReportGetters,
    CallStatus,
    CallType,
    CallReportFilter,
    CallReportServiceGetInfoParams,
    CallsStats,
    DataItem,
    FilterItem,
    LevelModel,
} from '@/types';

import { CallReportFilters } from '@/views/pages/reports/call/components';

const callsStats: Ref<CallsStats | null> = ref(null);

const levelFilter = computed<LevelModel | null>(() => filterStore.level);
const dateRange = computed<Array<Date>>({
    get: () => filterStore.dateRange,
    set: (dateRange: Array<Date>) => filterStore.setDateRange(dateRange),
});
const filter = computed<CallReportFilter>(() => store.getters[CallReportGetters.FILTER]);
const activeFilters: ComputedRef<FilterItem[]> = computed(
    () => store.getters[CallReportGetters.ACTIVE_FILTERS],
);

const filterStore = useFilterStore();
const uiStore = useUiStore();

watch([levelFilter, dateRange, activeFilters], () => {
    fetchReportData();
});

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchReportData = async () => {
    const params: CallReportServiceGetInfoParams = {
        startDate: moment.utc(dateRange.value[0]).format('YYYY-MM-DD'),
        endDate: moment.utc(dateRange.value[1]).format('YYYY-MM-DD'),
        type: filter.value.type?.value || null,
        status: filter.value.status?.value || null,
        user: filter.value.user?.id || null,
        userLabel: filter.value.userLabel?.id || null,
        number: filter.value.number?.number || null,
        serviceTimeRange: filter.value.serviceTimeRange?.value || null,
    };

    try {
        setLoading(true);
        const response = await CallReportService.getInfo<CallsStats>({ params });
        callsStats.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveFilter = (filter: FilterItem) => {
    store.dispatch(CallReportActions.CLEAR_FILTER, filter.field);
};

const headerStats = computed<DataItem<string>[]>(() => {
    if (!callsStats.value) return [];

    return [
        { name: 'totalCalls', value: callsStats.value.total.toString() },
        { name: 'inboundCalls', value: callsStats.value.type[CallType.INBOUND].toString() },
        { name: 'outboundCalls', value: callsStats.value.type[CallType.OUTBOUND].toString() },
        { name: 'noAnswer', value: callsStats.value.status[CallStatus.NO_ANSWER].toString() },
        { name: 'busy', value: callsStats.value.status[CallStatus.BUSY].toString() },
        {
            name: 'averageServiceTime',
            value: moment.utc((callsStats.value.averageServiceTime || 0) * 1000).format('HH:mm:ss'),
        },
        {
            name: 'averageWaitingTime',
            value: callsStats.value.averageWaitingTime
                ? formatSecToTime(callsStats.value.averageWaitingTime, true)
                : '-',
        },
    ];
});

onMounted(() => {
    fetchReportData();
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('report.call.title') }}
                </h1>
            </template>
            <template #actions>
                <CallReportFilters />
                <div class="ml-4 w-full sm:w-64">
                    <UiDatePicker v-model="dateRange" range multiCalendars />
                </div>
            </template>
        </UiPageHeader>

        <div
            v-if="activeFilters.length"
            class="mt-4 flex bg-gray-50 px-4 py-4 shadow ring-1 ring-gray-300 sm:p-6 md:rounded"
        >
            <template :key="filter" v-for="filter in activeFilters">
                <UiBadge removable @remove="handleRemoveFilter(filter)">
                    {{ $t(`report.call.filters.activeTag.${filter.field}`) }}:
                    {{ filter.label }}
                </UiBadge>
            </template>
        </div>

        <dl
            v-if="headerStats"
            class="mt-4 grid grid-cols-2 gap-4"
            :class="['sm:grid-cols-' + headerStats.length]"
        >
            <UiPanel v-for="stat in headerStats" :key="stat.name">
                <dt class="text-center text-xs font-medium text-gray-500">
                    {{ $t('report.call.stats.' + stat.name) }}
                </dt>
                <dd class="mt-1 text-center text-2xl font-semibold text-gray-800">
                    {{ stat.value }}
                </dd>
            </UiPanel>
        </dl>

        <div v-if="callsStats" class="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2">
            <CallStatusDoughnutChart
                :title="$t('report.call.callStatusDoughnutChart.title')"
                :data="callsStats.status"
                class="col-span-2 h-[300px] md:col-span-1"
            />
            <CallStatusPerDayBarChart
                :title="$t('report.call.callStatusPerDayBarChart.title')"
                :data="callsStats.statusPerDay"
                class="col-span-2 h-[300px] md:col-span-1"
            />
            <CallTypesPerDayBarChart
                :title="$t('report.call.callTypePerDayBarChart.title')"
                :data="callsStats.typePerDay"
                class="col-span-2 h-[300px] md:col-span-1"
            />
            <CallAverageTimePerDayBarChart
                :title="$t('report.call.callAverageTimesChart.title')"
                :data="callsStats.tmPerDay"
                class="col-span-2 h-[300px] md:col-span-1"
            />
            <CallTimeOfDayHeatmapChart
                :title="$t('report.call.callTimeOfDayHeatmapChart.title')"
                :data="callsStats.timeOfDay"
                class="col-span-2 hidden h-[300px] sm:block"
            />
            <CallServiceTimeRangesDoughnutChart
                :title="$t('report.call.callServiceTimeRangesDoughnutChart.title')"
                :data="callsStats.serviceTimeRange"
                class="col-span-2 h-[300px] md:col-span-1"
            />
            <CallMediasDoughnutChart
                :title="$t('report.call.callMediasDoughnutChart.title')"
                :data="callsStats.media"
                class="col-span-2 h-[300px] md:col-span-1"
            />
        </div>
    </div>
</template>
