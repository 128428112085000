import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-wrap items-center space-x-1 text-gray-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiIsActiveBadge = _resolveComponent("UiIsActiveBadge")!
  const _component_UiAuditInfo = _resolveComponent("UiAuditInfo")!

  return ($props.modelValue)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["space-y-2 text-sm md:flex", [$setup.hasIsActive ? 'md:justify-between' : 'md:justify-end']])
      }, [
        ($setup.hasIsActive)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('core.status')) + ": ", 1 /* TEXT */),
              _createVNode(_component_UiIsActiveBadge, { "model-value": $props.modelValue }, null, 8 /* PROPS */, ["model-value"])
            ]))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_UiAuditInfo, { "model-value": $props.modelValue }, null, 8 /* PROPS */, ["model-value"])
      ], 2 /* CLASS */))
    : _createCommentVNode("v-if", true)
}