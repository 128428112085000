<script lang="ts" setup>
import { ref, Ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import { useUiStore } from '@/stores';
import NumberService from '@/services/api/NumberService';
import { toastServiceError } from '@/services/core/notification';
import { Permission, NumberModel } from '@/types';
import { applyPhoneMask } from '@/utils/number';

const number: Ref<NumberModel | undefined> = ref(undefined);

const route = useRoute();
const uiStore = useUiStore();

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchNumber = async (id: number) => {
    try {
        setLoading(true);
        const response = await NumberService.get<NumberModel>(id);
        number.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

onMounted(() => {
    const id = Number(route.params.id);
    if (!isNaN(id)) {
        fetchNumber(id);
    }
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8" v-if="number !== undefined">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('number.view.section.number.title') }}
                </h1>
            </template>
            <template #actions>
                <div class="flex items-center space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ListNumber' }" />
                    <router-link
                        v-if="$can(Permission.EDIT_USER)"
                        :to="{
                            name: 'EditNumberById',
                            params: {
                                id: number?.id,
                            },
                        }"
                        class="block hover:bg-gray-50"
                    >
                        <UiButton variant="primary" text-variant="white">
                            {{ $t('core.actions.Edit') }}
                        </UiButton>
                    </router-link>
                </div>
            </template>
        </UiPageHeader>
        <UiPanel>
            <div class="grid grid-cols-1 gap-4 md:grid-cols-4">
                <div>
                    <h3 class="mb-1 font-semibold">
                        {{ $t('core.entity.Level') }}
                    </h3>
                    <p>{{ number?.level.name }}</p>
                </div>
                <div>
                    <h3 class="mb-1 font-semibold">
                        {{ $t('number.labels.number') }}
                    </h3>
                    <p v-if="number.number">{{ applyPhoneMask(number.number) }}</p>
                    <p v-else>-</p>
                </div>
                <div>
                    <h3 class="mb-1 font-semibold">
                        {{ $t('number.labels.city') }}
                    </h3>
                    <p>{{ number?.city }}/{{ number?.state }}</p>
                </div>
                <div>
                    <h3 class="mb-1 font-semibold">
                        {{ $t('number.labels.type') }}
                    </h3>
                    <p>{{ $t(`number.types.${number?.type}`) }}</p>
                </div>
                <div>
                    <h3 class="mb-1 font-semibold">
                        {{ $t('core.entity.ConnectFlow') }}
                    </h3>
                    <p>{{ number?.connectFlow?.name }}</p>
                </div>
                <div>
                    <h3 class="mb-1 font-semibold">
                        {{ $t('number.labels.mediaLabel') }}
                    </h3>
                    <p>{{ number.mediaLabel }}</p>
                </div>
            </div>
            <UiAuditStatusInfo
                :model-value="number"
                class="bt-1 mt-4 border-t border-gray-300 pt-2"
            />
        </UiPanel>
    </div>
</template>
