import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "space-y-4 p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "inline-block text-xl font-semibold text-gray-800" }
const _hoisted_3 = { class: "mt-4 grid grid-cols-3 gap-2 md:grid-cols-4 lg:grid-cols-6 xl:lg:grid-cols-8" }
const _hoisted_4 = { class: "relative" }
const _hoisted_5 = { class: "text-xs font-semibold text-gray-600" }
const _hoisted_6 = { class: "text-xs font-semibold text-gray-600" }
const _hoisted_7 = { class: "text-xs font-semibold text-gray-600" }
const _hoisted_8 = { class: "grid grid-cols-1 gap-1 pt-1 text-center text-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_spinner = _resolveComponent("ui-spinner")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UiPageHeader, { class: "inline-block" }, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('report.realtimeAgentExtension.title')), 1 /* TEXT */),
        ($setup.loading)
          ? (_openBlock(), _createBlock(_component_ui_spinner, {
              key: 0,
              class: "left mb-1.5 ml-2 inline-block h-5 w-5"
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, (item, idx) => {
        return (_openBlock(), _createElementBlock("div", {
          key: `queueDetailItem${idx}`,
          class: _normalizeClass([
                    item.status == $setup.UserDeviceStatus.OFFLINE ? 'bg-danger-50' : 'bg-success-50',
                    'flex flex-col items-center justify-center space-y-1 rounded px-1 py-2 shadow',
                ])
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode($setup["UserIcon"], { class: "h-12 w-12 rounded-full border border-gray-300 bg-gray-50 p-0.5 text-gray-400 shadow" }),
            (item.userDevice === $setup.UserDeviceType.DESKTOP)
              ? (_openBlock(), _createBlock($setup["DesktopComputerIcon"], {
                  key: 0,
                  class: "absolute -right-3 top-0 h-6 w-6 text-cyan-600"
                }))
              : (item.userDevice === $setup.UserDeviceType.MOBILE)
                ? (_openBlock(), _createBlock($setup["DeviceMobileIcon"], {
                    key: 1,
                    class: "absolute -right-3 top-0 h-6 w-6 text-cyan-600"
                  }))
                : (item.userDevice === $setup.UserDeviceType.WEB)
                  ? (_openBlock(), _createBlock($setup["SVGChromeLogo"], {
                      key: 2,
                      class: "absolute -right-3 top-0 h-6 w-6 text-cyan-600"
                    }))
                  : (item.userDevice === $setup.UserDeviceType.EXTERNAL)
                    ? (_openBlock(), _createBlock($setup["SVGDeviceExternal"], {
                        key: 3,
                        class: "absolute -right-3 top-0 h-6 w-6 text-cyan-600"
                      }))
                    : _createCommentVNode("v-if", true)
          ]),
          _createElementVNode("p", _hoisted_5, _toDisplayString(item.user), 1 /* TEXT */),
          _createElementVNode("p", _hoisted_6, "ramal " + _toDisplayString(item.extension), 1 /* TEXT */),
          _createElementVNode("p", _hoisted_7, _toDisplayString($setup.applyPhoneMask(item.numberClient) || '-'), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("p", {
              class: _normalizeClass([
                            item.status == $setup.UserDeviceStatus.OFFLINE
                                ? 'border-danger-800 text-danger-700'
                                : 'border-success-800 text-success-700',
                            'rounded border-2 border-opacity-15 bg-gray-50 px-1 py-0.5 font-semibold',
                        ])
            }, _toDisplayString(_ctx.$t(`report.realtime.device.status.${item.status}`)), 3 /* TEXT, CLASS */)
          ])
        ], 2 /* CLASS */))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}