import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { UsersIcon, DocumentDownloadIcon } from '@heroicons/vue/solid';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import UserService from '@/services/api/UserService';
import { toast, toastServiceError } from '@/services/core/notification';

import {
    FilterItem,
    Permission,
    SortMenuItem,
    UserActions,
    UserFilter,
    UserGetters,
    UserModel,
    UserServiceGetAllParams,
} from '@/types';
import { UserListItem, UserListSort, UserListFilters } from '@/views/pages/user/components';

const perPage = 10;


export default /*@__PURE__*/_defineComponent({
  __name: 'UserPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const filterStore = useFilterStore();
const uiStore = useUiStore();

const users = ref<UserModel[]>([]);
const count = ref(0);
const search = computed({
    get: () => store.getters[UserGetters.SEARCH],
    set: (value: string) => store.dispatch(UserActions.SET_SEARCH, value),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[UserGetters.SORT],
    set: (value: SortMenuItem[]) => store.dispatch(UserActions.SET_SORT, value),
});

const level = computed(() => filterStore.level);

const currentPage = computed({
    get: () => store.getters[UserGetters.PAGE],
    set: (value: number) => store.dispatch(UserActions.SET_PAGE, value),
});

const filter: UserFilter = store.getters[UserGetters.FILTER];

const activeFilters = computed<FilterItem[]>(() => store.getters[UserGetters.ACTIVE_FILTERS]);

const orderBy = computed(() => sortMenuSelected.value.map((s) => s.direction + s.key).join(','));

watch([currentPage], () => {
    fetchUsers();
});

watch([level, search, filter, orderBy], () => {
    if (currentPage.value === 1) fetchUsers();
    else currentPage.value = 1;
});

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getParams = (): UserServiceGetAllParams => ({
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    is_active: filter.isActive !== null ? (filter.isActive.value as boolean) : undefined,
    roles: filter.roles.map((r) => r.id),
    search: search.value,
});

const fetchUsers = async () => {
    setLoading(true);
    try {
        const { data, headers } = await UserService.getAll<UserModel[]>({ params: getParams() });
        users.value = data;
        count.value = parseInt(headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const exportCSV = async () => {
    setLoading(true);
    try {
        const { data } = await UserService.getCsvReport<Array<UserModel>>({ params: getParams() });
        const dateNow = moment().format('DD/MM/YYYY HH:mm:ss');
        const filename = `${t('dashboard.report.users')} - ${dateNow}.csv`;
        const blob = new Blob([typeof data === 'string' ? data : JSON.stringify(data)], {
            type: 'text/csv;charset=utf-8;',
        });

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const setIsActive = async (item: UserModel, isActive: boolean) => {
    setLoading(true);
    try {
        const { data } = await (
            isActive
                ? (id: number) => UserService.activate(id)
                : (id: number) => UserService.inactivate(id)
        )(item.id);
        toast.success(data.message);
        await fetchUsers();
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveSortClick = (item: SortMenuItem) => {
    const index = sortMenuSelected.value.indexOf(item);
    if (index > -1) sortMenuSelected.value.splice(index, 1);
};

const handleRemoveFilterClick = (item: FilterItem) =>
    store.dispatch(UserActions.CLEAR_FILTER, item);

const handleActivateClick = (item: UserModel) => setIsActive(item, true);

const handleInactivateClick = (item: UserModel) => setIsActive(item, false);

onMounted(() => {
    if (route.query.clearStateOnSetup) {
        search.value = '';
        sortMenuSelected.value = [];
        currentPage.value = 1;
        router.replace({ query: {} });
    }

    fetchUsers();
});

const __returned__ = { route, router, t, filterStore, uiStore, users, count, perPage, search, sortMenuSelected, level, currentPage, filter, activeFilters, orderBy, setLoading, getParams, fetchUsers, exportCSV, setIsActive, handleRemoveSortClick, handleRemoveFilterClick, handleActivateClick, handleInactivateClick, get UsersIcon() { return UsersIcon }, get DocumentDownloadIcon() { return DocumentDownloadIcon }, get Permission() { return Permission }, get UserListItem() { return UserListItem }, get UserListSort() { return UserListSort }, get UserListFilters() { return UserListFilters } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})