import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue';

import store from '@/store';
import router from '@/router';
import { useUiStore } from '@/stores';

import { HomeIcon, LogoutIcon } from '@heroicons/vue/outline';
import BaseTemplate from '@/views/templates/BaseTemplate.vue';
import { NavigationGetters } from '@/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'SettingsTemplate',
  setup(__props, { expose: __expose }) {
  __expose();

const uiStore = useUiStore();

const sideNavigationItems = computed(
    () => store.getters[NavigationGetters.SETTINGS_SIDE_NAVIGATION_ITEMS],
);

const showLogout = computed(() => location.host !== 'embed.mobcall.com');

const handleLocaleChange = (event: Event) => {
    const selectedLocale = (event.target as HTMLInputElement).value;
    uiStore.setLocale(selectedLocale);
};

const handleHomeClick = () => {
    router.push('/');
};

const handleLogoutClick = () => {
    router.push('/logout');
};

const __returned__ = { uiStore, sideNavigationItems, showLogout, handleLocaleChange, handleHomeClick, handleLogoutClick, get HomeIcon() { return HomeIcon }, get LogoutIcon() { return LogoutIcon }, BaseTemplate }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})