import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, Ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { AxiosResponse } from 'axios';

import { useUiStore } from '@/stores';
import { ScheduleService } from '@/services';
import { goBack } from '@/composables/useNavigation';
import { toast, toastServiceError } from '@/services/core/notification';

import { Permission, FormAction, ScheduleModel, ScheduleFormState } from '@/types';

import { required, maxLength } from '@/utils/validators';


export default /*@__PURE__*/_defineComponent({
  __name: 'ScheduleFormPage',
  props: {
    action: { type: String, default: FormAction.CREATE },
},
  setup(__props, { expose: __expose }) {
  __expose();

const uiStore = useUiStore();
const route = useRoute();
const { t } = useI18n();

const props = __props;

const formState = reactive<ScheduleFormState>({
    name: null,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    timeBegin: '08:00:00',
    timeEnd: '17:00:00',
    isActive: true,
});

const rules = computed<ValidationArgs<ScheduleFormState>>(() => ({
    name: { required, maxLength: maxLength(50) },
    monday: { required },
    tuesday: { required },
    wednesday: { required },
    thursday: { required },
    friday: { required },
    saturday: { required },
    sunday: { required },
    timeBegin: { required },
    timeEnd: { required },
    isActive: { required },
}));

const v$: Ref<Validation<ValidationArgs<ScheduleFormState>>> = useVuelidate(rules, formState);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchScheduleById = async (id: number) => {
    try {
        setLoading(true);
        const { data } = await ScheduleService.get<ScheduleModel>(id);
        Object.assign(formState, data);
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const isTimeBefore = (start: string, end: string): boolean => {
    const [h1, m1] = start.split(':').map(Number);
    const [h2, m2] = end.split(':').map(Number);
    return h1 < h2 || (h1 === h2 && m1 < m2);
};

const onSubmit = async () => {
    const isValid = await v$.value.$validate();
    if (!isValid) return;

    const noDaySelected = !(
        formState.monday ||
        formState.tuesday ||
        formState.wednesday ||
        formState.thursday ||
        formState.friday ||
        formState.saturday ||
        formState.sunday
    );

    if (noDaySelected) {
        toastServiceError(t('schedule.error.dayNotSelected'));
        return;
    }

    if (!isTimeBefore(formState.timeBegin, formState.timeEnd)) {
        toastServiceError(t('schedule.error.invalidTimeRange'));
        return;
    }

    try {
        setLoading(true);
        let response: AxiosResponse;

        if (props.action === FormAction.EDIT) {
            const id = Number(route.params.id);
            response = await ScheduleService.update(id, formState);
            toast.success(response.data.message);
            goBack({ name: 'ViewScheduleById', params: { id } });
        } else {
            response = await ScheduleService.create(formState);
            toast.success(response.data.message);
            goBack({ name: 'ViewScheduleById', params: { id: response.data.result.id } });
        }

        v$.value.$reset();
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

onMounted(() => {
    if (props.action === FormAction.EDIT) {
        const scheduleId = Number(route.params.id);
        if (!isNaN(scheduleId)) fetchScheduleById(scheduleId);
    }
});

const __returned__ = { uiStore, route, t, props, formState, rules, v$, setLoading, fetchScheduleById, isTimeBefore, onSubmit, get Permission() { return Permission }, get FormAction() { return FormAction } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})