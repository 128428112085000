import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-xl font-semibold text-gray-800" }
const _hoisted_2 = { class: "flex items-center space-x-4" }
const _hoisted_3 = { class: "gap-4 md:grid md:grid-cols-8" }
const _hoisted_4 = { class: "grid grid-cols-2 gap-4 md:grid-cols-8" }
const _hoisted_5 = { class: "space-y-2" }
const _hoisted_6 = { class: "text-sm font-medium text-gray-800" }
const _hoisted_7 = { class: "grid grid-cols-2 gap-4 md:grid-cols-4 lg:grid-cols-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiBackButton = _resolveComponent("UiBackButton")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!
  const _component_UiTextInput = _resolveComponent("UiTextInput")!
  const _component_UiTimePicker = _resolveComponent("UiTimePicker")!
  const _component_UiCheckboxInput = _resolveComponent("UiCheckboxInput")!
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _component_UiCheckDirtyBeforeRouteLeave = _resolveComponent("UiCheckDirtyBeforeRouteLeave")!

  return (_openBlock(), _createElementBlock("form", {
    class: "space-y-4 p-4 sm:p-6 lg:p-8",
    autocomplete: "off",
    onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
  }, [
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('schedule.' + $setup.props.action + '.title')), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_UiBackButton, { fallbackTo: { name: 'ListSchedule' } }),
          (
                            ($setup.props.action == $setup.FormAction.CREATE &&
                                _ctx.$can($setup.Permission.CREATE_SCHEDULE)) ||
                            ($setup.props.action == $setup.FormAction.EDIT && _ctx.$can($setup.Permission.EDIT_SCHEDULE))
                        )
            ? (_openBlock(), _createBlock(_component_UiButton, {
                key: 0,
                type: "submit",
                variant: "primary",
                "text-variant": "white"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Save')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_UiPanel, { class: "space-y-4" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_UiTextInput, {
            modelValue: $setup.v$.name.$model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.v$.name.$model) = $event)),
            name: "name",
            type: "text",
            label: _ctx.$t('schedule.labels.name'),
            class: "md:col-span-6 lg:col-span-4",
            errors: $setup.v$.name.$errors,
            onBlur: $setup.v$.name.$touch
          }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_UiTimePicker, {
            modelValue: $setup.v$.timeBegin.$model,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.v$.timeBegin.$model) = $event)),
            name: "timeBegin",
            label: _ctx.$t('schedule.labels.timeBegin'),
            class: "md:col-span-3 lg:col-span-2",
            errors: $setup.v$.timeBegin.$errors,
            onBlur: $setup.v$.timeBegin.$touch
          }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"]),
          _createVNode(_component_UiTimePicker, {
            modelValue: $setup.v$.timeEnd.$model,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.v$.timeEnd.$model) = $event)),
            name: "timeEnd",
            label: _ctx.$t('schedule.labels.timeEnd'),
            class: "md:col-span-3 lg:col-span-2",
            errors: $setup.v$.timeEnd.$errors,
            onBlur: $setup.v$.timeEnd.$touch
          }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('schedule.labels.daysActive')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_UiCheckboxInput, {
              modelValue: $setup.v$.monday.$model,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.v$.monday.$model) = $event)),
              name: "monday",
              label: _ctx.$t('schedule.labels.monday')
            }, null, 8 /* PROPS */, ["modelValue", "label"]),
            _createVNode(_component_UiCheckboxInput, {
              modelValue: $setup.v$.tuesday.$model,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.v$.tuesday.$model) = $event)),
              name: "tuesday",
              label: _ctx.$t('schedule.labels.tuesday')
            }, null, 8 /* PROPS */, ["modelValue", "label"]),
            _createVNode(_component_UiCheckboxInput, {
              modelValue: $setup.v$.wednesday.$model,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.v$.wednesday.$model) = $event)),
              name: "wednesday",
              label: _ctx.$t('schedule.labels.wednesday')
            }, null, 8 /* PROPS */, ["modelValue", "label"]),
            _createVNode(_component_UiCheckboxInput, {
              modelValue: $setup.v$.thursday.$model,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.v$.thursday.$model) = $event)),
              name: "thursday",
              label: _ctx.$t('schedule.labels.thursday')
            }, null, 8 /* PROPS */, ["modelValue", "label"]),
            _createVNode(_component_UiCheckboxInput, {
              modelValue: $setup.v$.friday.$model,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.v$.friday.$model) = $event)),
              name: "friday",
              label: _ctx.$t('schedule.labels.friday')
            }, null, 8 /* PROPS */, ["modelValue", "label"]),
            _createVNode(_component_UiCheckboxInput, {
              modelValue: $setup.v$.saturday.$model,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.v$.saturday.$model) = $event)),
              name: "saturday",
              label: _ctx.$t('schedule.labels.saturday')
            }, null, 8 /* PROPS */, ["modelValue", "label"]),
            _createVNode(_component_UiCheckboxInput, {
              modelValue: $setup.v$.sunday.$model,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.v$.sunday.$model) = $event)),
              name: "sunday",
              label: _ctx.$t('schedule.labels.sunday')
            }, null, 8 /* PROPS */, ["modelValue", "label"])
          ])
        ]),
        _createVNode(_component_UiCheckboxInput, {
          modelValue: $setup.v$.isActive.$model,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.v$.isActive.$model) = $event)),
          name: "isActive",
          label: _ctx.$t('schedule.labels.isActive')
        }, null, 8 /* PROPS */, ["modelValue", "label"])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_UiCheckDirtyBeforeRouteLeave, {
      dirty: $setup.v$.$anyDirty
    }, null, 8 /* PROPS */, ["dirty"])
  ], 32 /* NEED_HYDRATION */))
}