import { defineComponent as _defineComponent } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XIcon, CogIcon } from '@heroicons/vue/solid';
import type { HTMLAttributes, PropType, VNodeProps } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiRightPanel',
  props: {
    modelValue: {
        type: Boolean,
        required: true,
        default: false,
    },
    title: {
        type: String,
        required: true,
    },
    icon: {
        type: Function as PropType<HTMLAttributes & VNodeProps>,
        required: false,
        default: CogIcon,
    },
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;

const handleToggle = (state: boolean) => {
    emit('update:modelValue', state);
};

const __returned__ = { props, emit, handleToggle, get Dialog() { return Dialog }, get DialogPanel() { return DialogPanel }, get DialogTitle() { return DialogTitle }, get TransitionChild() { return TransitionChild }, get TransitionRoot() { return TransitionRoot }, get XIcon() { return XIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})