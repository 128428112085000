import { UserModel, LevelModel, CompanyModel } from '@/types';

export interface AuthServiceLoginBody {
    email: string;
    password: string;
    rememberMe: boolean;
}

export interface AuthServiceLoginResult {
    user: UserModel;
    level: LevelModel;
    company: CompanyModel;
    jwt: string;
}
