import { defineComponent as _defineComponent } from 'vue'
import { ref, type Ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import { useUiStore } from '@/stores';
import { WordService } from '@/services';
import { toastServiceError } from '@/services/core/notification';
import { type WordModel, Permission } from '@/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'WordDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const uiStore = useUiStore();

const word: Ref<WordModel | undefined> = ref(undefined);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchWord = async (id: number) => {
    setLoading(true);
    try {
        const response = await WordService.get<WordModel>(id);
        word.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

onMounted(() => {
    fetchWord(Number(route.params.id));
});

const __returned__ = { route, uiStore, word, setLoading, fetchWord, get Permission() { return Permission } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})