<script lang="ts" setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import ContactService from '@/services/api/ContactService';
import { toastServiceError } from '@/services/core/notification';

import {
    ContactActions,
    ContactGetters,
    ContactModel,
    ContactServiceGetAllParams,
    SortMenuItem,
    Permission,
} from '@/types';

import { ContactListItem, ContactListSort } from '@/views/pages/contact/components';

import { OfficeBuildingIcon } from '@heroicons/vue/solid';

const route = useRoute();
const router = useRouter();

const filterStore = useFilterStore();
const uiStore = useUiStore();

const contacts = ref<ContactModel[]>([]);
const count = ref(0);
const perPage = 10;

const currentPage = computed({
    get: () => store.getters[ContactGetters.PAGE],
    set: (value: number) => store.dispatch(ContactActions.SET_PAGE, value),
});

const search = computed({
    get: () => store.getters[ContactGetters.SEARCH],
    set: (value: string) => store.dispatch(ContactActions.SET_SEARCH, value),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[ContactGetters.SORT],
    set: (value: SortMenuItem[]) => store.dispatch(ContactActions.SET_SORT, value),
});

const levelFilter = computed(() => filterStore.level);

const orderBy = computed(() => sortMenuSelected.value.map((s) => s.direction + s.key).join(','));

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getParams = (): ContactServiceGetAllParams => ({
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    search: search.value,
});

const fetchContacts = async () => {
    await setLoading(true);
    try {
        const { data, headers } = await ContactService.getAll<ContactModel[]>({
            params: getParams(),
        });
        contacts.value = data;
        count.value = parseInt(headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const handleRemoveSortClick = (item: SortMenuItem) => {
    const index = sortMenuSelected.value.indexOf(item);
    if (index > -1) sortMenuSelected.value.splice(index, 1);
};

watch([currentPage], () => {
    fetchContacts();
});

watch([levelFilter, search, orderBy], () => {
    if (currentPage.value === 1) fetchContacts();
    else currentPage.value = 1;
});

if (route.query.clearStateOnSetup) {
    search.value = '';
    sortMenuSelected.value = [];
    currentPage.value = 1;
    router.replace({ query: {} });
}

onMounted(fetchContacts);
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="flex items-center text-xl font-semibold text-gray-800">
                    <OfficeBuildingIcon class="mr-1 h-6 w-6 text-gray-500" aria-hidden="true" />
                    {{ $t('core.entity.Contacts') }}
                </h1>
            </template>
            <template #actions>
                <router-link v-if="$can(Permission.CREATE_CONTACT)" :to="{ name: 'CreateContact' }">
                    <UiButton variant="primary" text-variant="white">
                        {{ $t('core.actions.Add') }}
                    </UiButton>
                </router-link>
            </template>
        </UiPageHeader>

        <div class="space-y-2">
            <UiPanel class="flex px-4 py-2" no-padding>
                <UiListSearch
                    v-model="search"
                    :placeholder="$t('contact.filters.search')"
                    class="flex-1"
                />
                <div class="flex items-center space-x-4">
                    <ContactListSort v-model="sortMenuSelected" />
                </div>
            </UiPanel>

            <UiPanel v-if="sortMenuSelected.length" class="space-x-2">
                <UiSortMenuBadge
                    v-for="selected in sortMenuSelected"
                    :key="selected.key"
                    :item="selected"
                    @remove="handleRemoveSortClick(selected)"
                />
            </UiPanel>

            <div v-if="contacts.length" class="space-y-2">
                <ContactListItem
                    v-for="contact in contacts"
                    :key="contact.id"
                    :model-value="contact"
                />
            </div>
            <UiPanel v-else class="flex items-center justify-center">
                {{ $t('core.messages.noRecordsFound') }}
            </UiPanel>

            <UiPagination
                v-if="contacts.length"
                v-model="currentPage"
                :count="count"
                :perPage="perPage"
            />
        </div>
    </div>
</template>
