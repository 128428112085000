import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "sr-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["inline-flex items-center rounded-full px-1 py-0.5 text-xs font-medium", $setup.classes])
  }, [
    _renderSlot(_ctx.$slots, "default"),
    ($setup.slots.number)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["ml-1 inline-flex items-center rounded-full px-1 text-xs font-medium", $setup.numberClasses])
        }, [
          _renderSlot(_ctx.$slots, "number")
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    ($setup.props.removable)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          type: "button",
          class: "ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full focus:bg-gray-500 focus:text-white focus:outline-none",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('remove')))
        }, [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('core.actions.Remove')), 1 /* TEXT */),
          _cache[1] || (_cache[1] = _createElementVNode("svg", {
            class: "h-2 w-2",
            stroke: "currentColor",
            fill: "none",
            viewBox: "0 0 8 8"
          }, [
            _createElementVNode("path", {
              "stroke-linecap": "round",
              "stroke-width": "1.5",
              d: "M1 1l6 6m0-6L1 7"
            })
          ], -1 /* HOISTED */))
        ]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}