import * as validators from '@vuelidate/validators';
import { i18n } from '@/plugins/i18n';
import { ValidationRuleWithoutParams, ValidationRule } from '@vuelidate/core';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const { createI18nMessage } = validators;

const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) });

export const required = withI18nMessage(validators.required);
export const minLength = withI18nMessage(validators.minLength, {
    withArguments: true,
});
export const maxLength = withI18nMessage(validators.maxLength, {
    withArguments: true,
});
export const email = withI18nMessage(validators.email);
export const sameAs = withI18nMessage(validators.sameAs, {
    withArguments: true,
});

export const requiredIf = withI18nMessage(validators.requiredIf, {
    withArguments: true,
});

export const containsUppercase: ValidationRuleWithoutParams = withI18nMessage({
    $validator(value: string | undefined) {
        return /[A-Z]/.test(value || '');
    },
});
export const containsLowercase: ValidationRuleWithoutParams = withI18nMessage({
    $validator(value: string | undefined) {
        return /[a-z]/.test(value || '');
    },
});
export const containsNumber: ValidationRuleWithoutParams = withI18nMessage({
    $validator(value: string | undefined) {
        return /[0-9]/.test(value || '');
    },
});
export const containsSpecial: ValidationRuleWithoutParams = withI18nMessage({
    $validator(value: string | undefined) {
        return /[#?!@$%^&*-]/.test(value || '');
    },
});
export const atLeastOneFieldFilled: ValidationRule = (value) => {
    if (!value) return true;
    const fields = ['audio', 'audioFilePath', 'textToAudio'];
    return fields.some((field) => value[field]);
};
export const isValidPhone: ValidationRuleWithoutParams = withI18nMessage({
    $validator(value: string | undefined) {
        if (!value) return true; // optional field
        const phoneNumber = parsePhoneNumberFromString(value, {
            defaultCountry: 'BR',
        });
        return phoneNumber ? phoneNumber.isValid() : false;
    },
});
