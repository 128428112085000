import { NavigationItem } from '@/types';

export interface NavigationState {
    sideNavigationOpen: boolean;
    sideNavigationItems: NavigationItem[];
    settingsSideNavigationItems: NavigationItem[];
}

export enum NavigationMutations {
    SET_SIDE_NAVIGATION_OPEN = 'setSideNavigationOpen',
}

export enum NavigationActions {
    TOGGLE_SIDE_NAVIGATION = 'toggleSideNavigation',
}

export enum NavigationGetters {
    SIDE_NAVIGATION_OPEN = 'sideNavigationOpen',
    SIDE_NAVIGATION_ITEMS = 'sideNavigationItems',
    SETTINGS_SIDE_NAVIGATION_ITEMS = 'settingsSideNavigationItems',
}
