import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "text-center text-xs font-medium text-gray-500" }
const _hoisted_2 = { class: "mt-1 text-center text-2xl font-semibold text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiSpinner = _resolveComponent("UiSpinner")!
  const _component_UiPanel = _resolveComponent("UiPanel")!

  return (_openBlock(), _createElementBlock("dl", {
    class: _normalizeClass(["mt-4 grid grid-cols-2 gap-4", ['sm:grid-cols-' + $setup.metrics.length]])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.metrics, (metric) => {
      return (_openBlock(), _createBlock(_component_UiPanel, {
        key: metric.key,
        class: "relative"
      }, {
        default: _withCtx(() => [
          ($props.loading)
            ? (_openBlock(), _createBlock(_component_UiSpinner, {
                key: 0,
                class: "theme-color absolute right-2 top-2 h-4 w-4"
              }))
            : _createCommentVNode("v-if", true),
          _createElementVNode("dt", _hoisted_1, _toDisplayString(_ctx.$t(metric.label)), 1 /* TEXT */),
          _createElementVNode("dd", _hoisted_2, _toDisplayString($setup.props.data?.[metric.key] ?? '-'), 1 /* TEXT */)
        ]),
        _: 2 /* DYNAMIC */
      }, 1024 /* DYNAMIC_SLOTS */))
    }), 128 /* KEYED_FRAGMENT */))
  ], 2 /* CLASS */))
}