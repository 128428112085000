<script lang="ts" setup>
import { computed, ComputedRef, onMounted, PropType, Ref, ref, WritableComputedRef } from 'vue';
import {
    ConnectFlowModelingActions,
    ConnectFlowModelingGetters,
    ConnectFlowModelingMutations,
    MultiSelectOption,
    VueFlowNodeModel,
    NodeType,
    VueFlowConnectionModel,
} from '@/types';
import store from '@/store';
import { ReceiptRefundIcon } from '@heroicons/vue/outline';

import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { required } from '@/utils/validators';
import { useI18n } from 'vue-i18n';

const props = defineProps({
    node: {
        type: Object as PropType<VueFlowNodeModel>,
        required: true,
    },
});

const showForm = ref(false);

const nodeWritable: WritableComputedRef<VueFlowNodeModel> = computed({
    get: () => store.getters['flow/' + ConnectFlowModelingGetters.ELEMENT_BY_ID](props.node.id),
    set: (value: VueFlowNodeModel) => {
        store.commit('flow/' + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
            idx: null,
            value,
        });
    },
});

const { t } = useI18n();

const elementsOptions = computed(() => {
    const options: MultiSelectOption[] = [];
    store.getters['flow/' + ConnectFlowModelingGetters.ELEMENTS_NODES].forEach(
        (node: VueFlowNodeModel) => {
            if (
                ![NodeType.START, NodeType.OPEN_EDGE, NodeType.GO_TO, NodeType.HANG_UP].includes(
                    node.data.component,
                )
            ) {
                options.push({
                    label: t(`connectFlow.node.${node.data.component}.title`),
                    value: node.id,
                });
            }
        },
    );
    return options;
});
interface GoToFormState {
    node: string | null;
}
const formState: Ref<GoToFormState> = ref({
    node: null,
});
const rules = computed<ValidationArgs<GoToFormState>>(() => {
    const r: ValidationArgs<GoToFormState> = {
        node: {
            required,
        },
    };
    return r;
});
const v$: Ref<Validation<ValidationArgs<GoToFormState>>> = useVuelidate(rules, formState);

const isFormCorrect = ref(true);

function removeNode() {
    store.dispatch('flow/' + ConnectFlowModelingActions.DELETE_NODE, {
        nodeId: nodeWritable.value.id,
        parentId: nodeWritable.value.parentId,
    });
}
const sourceConnection: ComputedRef<VueFlowConnectionModel | null> = computed(() =>
    store.getters['flow/' + ConnectFlowModelingGetters.ELEMENTS_CONNECTIONS].find(
        (c: VueFlowConnectionModel) => c.target === props.node.id,
    ),
);
async function onSubmit() {
    isFormCorrect.value = await v$.value.$validate();
    if (isFormCorrect.value) {
        nodeWritable.value.data.goToNode = formState.value.node || undefined;
        showForm.value = false;
        const handle = sourceConnection.value?.sourceHandle ?? null;
        store.dispatch('flow/' + ConnectFlowModelingActions.GO_TO_CONNECTION, {
            source: props.node.id,
            target: formState.value.node,
            handle: handle,
        });
    }
    nodeWritable.value.data.hasErrors = !isFormCorrect.value;
    v$.value.$reset();
}

onMounted(() => {
    if (nodeWritable.value.data.goToNode !== undefined) {
        formState.value.node = nodeWritable.value.data.goToNode;
    }

    v$.value.$validate().then((result) => {
        isFormCorrect.value = result;
        nodeWritable.value.data.hasErrors = !isFormCorrect.value;
    });
});
</script>

<template>
    <UiNode
        :nodeId="node.id"
        :title="$t('connectFlow.node.goTo.title')"
        :icon="ReceiptRefundIcon"
        showActions
        @editNode="showForm = true"
        @removeNode="removeNode"
        :class="isFormCorrect ? '' : 'border-red-300 shadow-red-300'"
    >
        <div class="font-medium text-gray-600">
            <span>
                {{ $t('connectFlow.node.goTo.description') }}
            </span>
        </div>
    </UiNode>

    <UiRightPanel
        v-model="showForm"
        :title="$t('connectFlow.node.goTo.title')"
        :icon="ReceiptRefundIcon"
    >
        <form autocomplete="off" class="flex flex-col gap-3" @submit.prevent="onSubmit">
            <div class="sm:col-span-12">
                <label class="mb-2 block text-sm font-medium text-gray-800">
                    {{ $t('connectFlow.node.goTo.helpText') }}
                </label>
                <UiMultiselect
                    name="schedule"
                    v-model="v$.node.$model"
                    :options="elementsOptions"
                    :errors="v$.node.$errors"
                    primitive
                    required
                >
                    <template #option-label="{ option }">
                        {{ option.value }} - {{ option.label }}
                    </template>
                </UiMultiselect>
            </div>

            <div class="mt-4 grid grid-cols-2 place-content-around gap-4 text-center">
                <UiButton variant="white" text-variant="gray-700" @click="showForm = false">
                    {{ $t('core.actions.Cancel') }}
                </UiButton>
                <UiButton type="submit" variant="primary" text-variant="white">
                    {{ $t('core.actions.Apply') }}
                </UiButton>
            </div>
        </form>
    </UiRightPanel>
</template>
