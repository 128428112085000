import { defineComponent as _defineComponent } from 'vue'
import { ErrorObject } from '@vuelidate/core';
import Multiselect from '@vueform/multiselect';
import { PropType, computed, ref, watch } from 'vue';
import { MultiselectMode } from '@/types';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiMultiselect',
  props: {
    type: {
        type: String,
        default: 'text',
    },
    modelValue: {
        default: null,
        type: [String, Number, Object, Boolean, null],
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: 'label',
    },
    valueProp: {
        type: String,
        default: 'value',
    },
    placeholder: {
        type: String,
        default: '',
    },
    autocomplete: {
        type: String,
        default: 'off',
    },
    multiple: {
        type: Boolean,
        default: false,
    },
    primitive: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    minCharMessage: {
        type: Boolean,
        default: false,
    },
    canClear: {
        type: Boolean,
        default: false,
    },
    canDeselect: {
        type: Boolean,
        default: false,
    },
    searchable: {
        type: String,
        validator: (value: string) => ['async', 'local', 'no'].includes(value),
        default: 'local',
    },
    options: {
        type: [Array, Object, Function] as PropType<
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            any[] | object | ((query: string) => Promise<any[]>)
        >,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: [],
    },
    noResultsText: {
        type: String,
        default: '',
    },
    noOptionsText: {
        type: String,
        default: '',
    },
    errors: {
        type: Array as PropType<ErrorObject[]>,
        default: () => [],
    },
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const { t } = useI18n();

const props = __props;

const searchQuery = ref('');

const selectedOption = computed({
    get() {
        if (props.primitive) {
            if (props.multiple) {
                return props.options.filter((option) => {
                    return props.modelValue.includes(option[props.valueProp]);
                });
            } else {
                return props.options.find((option) => {
                    return option[props.valueProp] === props.modelValue;
                });
            }
        }
        return props.modelValue;
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    set() {},
});

const multiselectMode = computed(() => {
    if (props.multiple) {
        return MultiselectMode.MULTIPLE;
    }
    return MultiselectMode.SINGLE;
});

const emit = __emit;
function handleInput(event: ProxyConstructor) {
    //Não achei uma solução boa para converter o Proxy para um objeto
    const data = JSON.parse(JSON.stringify(event));

    if (data === null) {
        emit('update:modelValue', data);
        return;
    }

    if (props.primitive) {
        if (props.multiple) {
            const mapData = data.map((i) => i[props.valueProp]);
            emit('update:modelValue', mapData);
        } else {
            emit('update:modelValue', data[props.valueProp]);
        }
    } else {
        emit('update:modelValue', data);
    }
}

const getContainerClass = computed(() =>
    !props.errors.length
        ? 'ring-gray-200 focus:ring-primary'
        : 'border-danger focus:border-danger-800',
);

const computedNoResultsText = computed(() => {
    if (searchQuery.value !== null && searchQuery.value.length < 4 && props.minCharMessage) {
        return t('core.multiselect.minChar');
    }
    return props.noResultsText || t('core.multiselect.noResults');
});

const computedNoOptionsText = computed(() => {
    return props.noOptionsText || t('core.multiselect.noOptions');
});

watch(
    () => props.modelValue,
    (newValue) => {
        searchQuery.value = newValue;
    },
);

const __returned__ = { t, props, searchQuery, selectedOption, multiselectMode, emit, handleInput, getContainerClass, computedNoResultsText, computedNoOptionsText, get Multiselect() { return Multiselect }, get MultiselectMode() { return MultiselectMode } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})