import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "space-y-4 p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-800" }
const _hoisted_3 = { class: "flex items-center space-x-4" }
const _hoisted_4 = { class: "space-y-4" }
const _hoisted_5 = { class: "grid grid-cols-1 gap-4 md:grid-cols-3" }
const _hoisted_6 = { class: "mb-1 font-semibold" }
const _hoisted_7 = { class: "mb-1 font-semibold" }
const _hoisted_8 = { class: "mb-1 font-semibold" }
const _hoisted_9 = { class: "mb-1 font-semibold" }
const _hoisted_10 = { class: "mb-1 font-semibold" }
const _hoisted_11 = { class: "mb-4 text-base font-semibold text-gray-800" }
const _hoisted_12 = { class: "relative grid grid-cols-2 gap-4 lg:grid-cols-4 xl:grid-cols-6" }
const _hoisted_13 = {
  key: 0,
  class: "absolute -right-[215px] bottom-0 mt-6 flex flex-col justify-between rounded border-b-4 border-r-4 border-cyan-600 bg-gray-50 px-4 py-3 text-sm text-gray-600 shadow"
}
const _hoisted_14 = {
  key: 0,
  class: "relative mt-5 grid grid-cols-1 gap-4 md:grid-cols-6"
}
const _hoisted_15 = { class: "text-base font-semibold text-gray-800" }
const _hoisted_16 = { class: "text-base font-semibold text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiBackButton = _resolveComponent("UiBackButton")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!
  const _component_UiAuditStatusInfo = _resolveComponent("UiAuditStatusInfo")!
  const _component_UiPanel = _resolveComponent("UiPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('user.view.title')), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_UiBackButton, { fallbackTo: { name: 'ListUser' } }),
          (_ctx.$can($setup.Permission.EDIT_USER))
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: {
                            name: 'EditUserById',
                            params: {
                                id: $setup.route.params.id,
                            },
                        },
                class: "block hover:bg-gray-50"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_UiButton, {
                    variant: "primary",
                    "text-variant": "white"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Edit')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"]))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_UiPanel, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('user.labels.name')), 1 /* TEXT */),
              _createElementVNode("p", null, _toDisplayString($setup.user?.name), 1 /* TEXT */)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t('user.labels.email')), 1 /* TEXT */),
              _createElementVNode("p", null, _toDisplayString($setup.user?.email), 1 /* TEXT */)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.$t('user.labels.levels')), 1 /* TEXT */),
              _createElementVNode("p", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.user?.levels, (level) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: `level${level.id}`,
                    class: "mr-3 inline-flex rounded-full bg-gray-50 px-2 text-sm font-semibold text-gray-700"
                  }, _toDisplayString(level.name), 1 /* TEXT */))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t('user.labels.standardLevel')), 1 /* TEXT */),
              _createElementVNode("p", null, _toDisplayString($setup.getLevelName($setup.user?.standardLevelId)), 1 /* TEXT */)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_10, _toDisplayString(_ctx.$t('user.labels.roles')), 1 /* TEXT */),
              _createElementVNode("p", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.user?.roles, (roles) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: `roles${roles.id}`,
                    class: "mr-3 inline-flex rounded-full bg-gray-50 px-2 text-sm font-semibold text-gray-700"
                  }, _toDisplayString(roles.name), 1 /* TEXT */))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ])
          ]),
          _createVNode(_component_UiAuditStatusInfo, {
            "model-value": $setup.user,
            class: "bt-1 mt-4 border-t border-gray-300 pt-2"
          }, null, 8 /* PROPS */, ["model-value"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_UiPanel, null, {
        default: _withCtx(() => [
          _createElementVNode("h3", _hoisted_11, _toDisplayString(_ctx.$t('user.labels.devices.title')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", {
              class: _normalizeClass([
                            $setup.user?.devices?.mobile
                                ? 'bg-cyan-50 text-cyan-600'
                                : 'bg-gray-50 text-gray-400',
                            'relative flex h-40 w-40 flex-col items-center rounded shadow ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50',
                        ])
            }, [
              _createVNode($setup["DeviceMobileIcon"], { class: "mt-6 h-20 w-20" }),
              _cache[0] || (_cache[0] = _createElementVNode("p", { class: "font-medium" }, "Mobile", -1 /* HOISTED */))
            ], 2 /* CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass([
                            $setup.user?.devices?.desktop
                                ? 'bg-cyan-50 text-cyan-600'
                                : 'bg-gray-50 text-gray-400',
                            'relative flex h-40 w-40 flex-col items-center rounded shadow ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50',
                        ])
            }, [
              _createVNode($setup["DesktopComputerIcon"], { class: "mt-6 h-20 w-20" }),
              _cache[1] || (_cache[1] = _createElementVNode("p", { class: "font-medium" }, "Desktop", -1 /* HOISTED */))
            ], 2 /* CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass([
                            $setup.user?.devices?.web
                                ? 'bg-cyan-50 text-cyan-600'
                                : 'bg-gray-50 text-gray-400',
                            'relative flex h-40 w-40 flex-col items-center rounded shadow ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50',
                        ])
            }, [
              _createVNode($setup["SVGChromeLogo"], { class: "mt-6 h-20 w-20" }),
              _cache[2] || (_cache[2] = _createElementVNode("p", { class: "font-medium" }, "Web", -1 /* HOISTED */))
            ], 2 /* CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass([
                            $setup.user?.devices?.external
                                ? 'bg-cyan-50 text-cyan-600'
                                : 'bg-gray-50 text-gray-400',
                            'relative flex h-40 w-40 flex-col items-center rounded shadow ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50',
                        ])
            }, [
              _createVNode($setup["SVGDeviceExternal"], { class: "mt-6 h-20 w-20" }),
              _cache[7] || (_cache[7] = _createElementVNode("p", { class: "font-medium" }, "Externo", -1 /* HOISTED */)),
              ($setup.user?.deviceExternal)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _cache[6] || (_cache[6] = _createElementVNode("h3", { class: "mb-1 text-base font-semibold" }, "Configurações do PABX", -1 /* HOISTED */)),
                    _createElementVNode("p", null, [
                      _cache[3] || (_cache[3] = _createElementVNode("span", { class: "font-semibold" }, "Hostname:", -1 /* HOISTED */)),
                      _createTextVNode(" " + _toDisplayString($setup.user?.deviceExternal.hostname), 1 /* TEXT */)
                    ]),
                    _createElementVNode("p", null, [
                      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "font-semibold" }, "Username:", -1 /* HOISTED */)),
                      _createTextVNode(" " + _toDisplayString($setup.user?.deviceExternal.sipUser), 1 /* TEXT */)
                    ]),
                    _createElementVNode("p", null, [
                      _cache[5] || (_cache[5] = _createElementVNode("span", { class: "font-semibold" }, "Password:", -1 /* HOISTED */)),
                      _createTextVNode(" " + _toDisplayString($setup.user?.deviceExternal.sipPassword), 1 /* TEXT */)
                    ])
                  ]))
                : _createCommentVNode("v-if", true)
            ], 2 /* CLASS */)
          ]),
          ($setup.user?.devices)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("div", null, [
                  _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t('user.labels.extension.number')), 1 /* TEXT */),
                  _createElementVNode("p", null, _toDisplayString($setup.user?.extension?.number), 1 /* TEXT */)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.$t('user.labels.number')), 1 /* TEXT */),
                  _createElementVNode("pre", null, _toDisplayString($setup.getBinaNumber($setup.user?.standardLevelId, $setup.user?.number?.numberId)), 1 /* TEXT */)
                ])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ])
  ]))
}