import { defineComponent as _defineComponent } from 'vue'
import { MeetingParticipantWithSpeechPercentageModel } from '@/types';
import { useI18n } from 'vue-i18n';
import { SparklesIcon } from '@heroicons/vue/outline';


export default /*@__PURE__*/_defineComponent({
  __name: 'SpeakerInsights',
  props: {
    participants: {
        type: Array as () => MeetingParticipantWithSpeechPercentageModel[],
        required: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const props = __props;

const getParticipantColor = (index: number) => {
    const colors = ['#7c3aed', '#14b8a6', '#0ea5e9', '#6b7280'];
    return colors[index % colors.length];
};

const __returned__ = { t, props, getParticipantColor, get SparklesIcon() { return SparklesIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})