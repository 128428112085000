<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AxiosResponse } from 'axios';

import { useUiStore } from '@/stores';
import UserLabelService from '@/services/api/UserLabelService';
import { toastServiceError } from '@/services/core/notification';
import { UserLabelModel, Permission } from '@/types';
import { MailIcon } from '@heroicons/vue/solid';

const route = useRoute();

const uiStore = useUiStore();

const userLabel = ref<UserLabelModel | undefined>(undefined);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchUserLabel = async (id: number) => {
    setLoading(true);
    try {
        const response: AxiosResponse<UserLabelModel> =
            await UserLabelService.get<UserLabelModel>(id);
        userLabel.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

onMounted(() => {
    const id = Number(route.params.id);
    fetchUserLabel(id);
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('userLabel.view.title') }}
                </h1>
            </template>
            <template #actions>
                <div class="flex space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ListUserLabel' }" />
                    <router-link
                        v-if="$can(Permission.EDIT_USER_LABEL)"
                        :to="{ name: 'EditUserLabelById', params: { id: route.params.id } }"
                        class="block hover:bg-gray-50"
                    >
                        <UiButton variant="primary" text-variant="white">
                            {{ $t('core.actions.Edit') }}
                        </UiButton>
                    </router-link>
                </div>
            </template>
        </UiPageHeader>

        <div class="space-y-4">
            <UiPanel>
                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                    <div>
                        <h3 class="mb-1 font-semibold">
                            {{ $t('userLabel.labels.label') }}
                        </h3>
                        <p>{{ userLabel?.label }}</p>
                    </div>
                </div>
                <UiAuditStatusInfo
                    :model-value="userLabel"
                    class="bt-1 mt-4 border-t border-gray-300 pt-2"
                />
            </UiPanel>

            <UiPanel>
                <h3 class="mb-4 text-base font-semibold text-gray-800">
                    {{ $t('userLabel.view.section.users.title') }}
                </h3>
                <div class="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
                    <UiPanel
                        v-for="user in userLabel?.users"
                        :key="user.id"
                        class="flex bg-white p-2"
                        no-padding
                    >
                        <div class="mr-2">
                            <UiGravatar
                                :email="user.email"
                                :name="user.name"
                                class="h-12 w-12 rounded-full ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50"
                            />
                        </div>
                        <div>
                            <component
                                :is="$can(Permission.VIEW_USER) ? RouterLink : 'p'"
                                :to="{ name: 'ViewUserById', params: { id: user.id } }"
                                class="text-primary truncate text-sm font-medium"
                            >
                                {{ user.name }}
                            </component>
                            <p class="flex items-center text-sm text-gray-500">
                                <MailIcon
                                    class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                    aria-hidden="true"
                                />
                                {{ user.email }}
                            </p>
                        </div>
                    </UiPanel>
                </div>
            </UiPanel>
        </div>
    </div>
</template>
