import { defineComponent as _defineComponent } from 'vue'
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AxiosResponse } from 'axios';

import { useUiStore } from '@/stores';
import RoleService from '@/services/api/RoleService';
import { toastServiceError } from '@/services/core/notification';
import { Permission, RoleModel } from '@/types';

// Composables

export default /*@__PURE__*/_defineComponent({
  __name: 'RoleDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const uiStore = useUiStore();

// State
const role = ref<RoleModel | undefined>(undefined);

// Methods
const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchRole = async (id: number) => {
    setLoading(true);
    try {
        const response: AxiosResponse<RoleModel> = await RoleService.get(id);
        role.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

// Lifecycle
onMounted(() => {
    const id = Number(route.params.id);
    if (!isNaN(id)) fetchRole(id);
});

const __returned__ = { route, uiStore, role, setLoading, fetchRole, get Permission() { return Permission } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})