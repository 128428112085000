<script lang="ts" setup>
import { PropType } from 'vue';
import { ConnectFlowModelingActions, VueFlowNodeModel } from '@/types';
import store from '@/store';
import { PhoneMissedCallIcon } from '@heroicons/vue/solid';

const props = defineProps({
    node: {
        type: Object as PropType<VueFlowNodeModel>,
        required: true,
    },
});

function removeNode() {
    store.dispatch('flow/' + ConnectFlowModelingActions.DELETE_NODE, {
        nodeId: props.node.id,
        parentId: props.node.parentId,
    });
}
</script>

<template>
    <UiNode
        :nodeId="node.id"
        showActions
        noEdition
        @removeNode="removeNode"
        :showHandleSource="false"
        hideNodeId
        bgColor="bg-gray-50"
        borderColor="border-gray-300"
        roundedStyle="rounded-2xl"
        padding="p-0 pt-2 pr-2"
        nodeWidth="w-24"
        nodeHeight="h-20"
    >
        <div class="absolute left-5 top-5 flex flex-col items-center">
            <PhoneMissedCallIcon class="mb-1 h-10 w-10 text-gray-600" />
        </div>
    </UiNode>
</template>
