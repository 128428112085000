<script lang="ts" setup>
import { ref, Ref, computed, ComputedRef, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AxiosError } from 'axios';

import store from '@/store';
import { useUiStore } from '@/stores';
import CallService from '@/services/api/CallService';
import { toastServiceError } from '@/services/core/notification';

import {
    AuthGetters,
    CallModel,
    CallType,
    CallParticipantType,
    DefaultServiceResult,
    UserIntegrationModel,
} from '@/types';

import {
    CallDetails,
    CallPlayer,
    CallWords,
    CallSurvey,
    CallChatBot,
} from '@/views/pages/call/components';

const route = useRoute();
const uiStore = useUiStore();

const call: Ref<CallModel | null> = ref(null);

const isLoading = computed(() => uiStore.isLoading);

const chatBotIntegration: ComputedRef<UserIntegrationModel> = computed<UserIntegrationModel>(
    () => store.getters[AuthGetters.CHAT_BOT_INTEGRATION],
);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getCall = async (id: number) => {
    await setLoading(true);
    try {
        const response = await CallService.get<CallModel>(id);
        call.value = response.data;
    } catch (err) {
        toastServiceError(err as Error | AxiosError<DefaultServiceResult>);
    } finally {
        await setLoading(false);
    }
};

onMounted(async () => {
    await getCall(Number(route.params.id));
});
</script>

<template>
    <div v-if="call !== null && !isLoading" class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('core.entity.Call') }}
                </h1>
            </template>
            <template #actions>
                <UiBackButton :fallbackTo="{ name: 'CallList' }" />
            </template>
        </UiPageHeader>

        <CallDetails :call="call" :show-view-button="false" show-download-button />
        <CallPlayer :call="call" />
        <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <CallWords
                :call="call"
                :participantType="
                    call.type === CallType.INTERNAL
                        ? CallParticipantType.AgentA
                        : CallParticipantType.Agent
                "
            />
            <CallWords
                :call="call"
                :participantType="
                    call.type === CallType.INTERNAL
                        ? CallParticipantType.AgentB
                        : CallParticipantType.Client
                "
            />
        </div>
        <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <CallSurvey v-for="survey in call.surveys" :key="survey.id" :survey="survey" />
        </div>

        <CallChatBot v-if="chatBotIntegration" :call="call" />
    </div>
</template>
