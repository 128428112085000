<script lang="ts" setup>
import { computed } from 'vue';
import store from '@/store';

import { AuthGetters, UserModel } from '@/types';

const user = computed<UserModel>(() => store.getters[AuthGetters.USER]);
</script>

<template>
    <div class="bg-black/20 p-4">
        <div class="flex items-center" v-if="user !== null">
            <div>
                <UiGravatar
                    :email="user.email"
                    :name="user.name"
                    class="inline-block h-10 w-10 rounded-full"
                />
            </div>
            <div class="ml-4">
                <p class="text-sm font-medium text-white">{{ user.name }}</p>
            </div>
        </div>
    </div>
</template>
