import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { toast, toastServiceError } from '@/services/core/notification';
import { OfficeBuildingIcon } from '@heroicons/vue/solid';

import ScheduleService from '@/services/api/ScheduleService';
import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';

import {
    ScheduleListItem,
    ScheduleListSort,
    ScheduleListFilters,
} from '@/views/pages/schedule/components';

import {
    FilterItem,
    LevelFilter,
    Permission,
    ScheduleActions,
    ScheduleGetters,
    ScheduleModel,
    ScheduleServiceGetAllParams,
    SortMenuItem,
} from '@/types';

const perPage = 10;

// Computed

export default /*@__PURE__*/_defineComponent({
  __name: 'SchedulePage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();
const uiStore = useUiStore();
const filterStore = useFilterStore();

// State
const schedules = ref<ScheduleModel[]>([]);
const count = ref(0);
const search = computed({
    get: () => store.getters[ScheduleGetters.SEARCH],
    set: (value: string) => store.dispatch(ScheduleActions.SET_SEARCH, value),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[ScheduleGetters.SORT],
    set: (value: SortMenuItem[]) => store.dispatch(ScheduleActions.SET_SORT, value),
});

const currentPage = computed({
    get: () => store.getters[ScheduleGetters.PAGE],
    set: (value: number) => store.dispatch(ScheduleActions.SET_PAGE, value),
});

const filter: LevelFilter = store.getters[ScheduleGetters.FILTER];
const activeFilters = computed<FilterItem[]>(() => store.getters[ScheduleGetters.ACTIVE_FILTERS]);
const levelFilter = computed(() => filterStore.level);

const orderBy = computed(() => sortMenuSelected.value.map((s) => s.direction + s.key).join(','));

// Methods
const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getQueryParams = (): ScheduleServiceGetAllParams => ({
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    is_active: filter.isActive !== null ? (filter.isActive.value as boolean) : undefined,
    search: search.value,
});

const fetchAllSchedules = async () => {
    try {
        setLoading(true);
        const { data, headers } = await ScheduleService.getAll<ScheduleModel[]>({
            params: getQueryParams(),
        });
        schedules.value = data;
        count.value = parseInt(headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const updateScheduleStatus = async (schedule: ScheduleModel, isActive: boolean) => {
    try {
        setLoading(true);
        const { data } = await (isActive
            ? ScheduleService.activate(schedule.id)
            : ScheduleService.inactivate(schedule.id));
        toast.success(data.message);
        await fetchAllSchedules();
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleActivateClick = (item: ScheduleModel) => updateScheduleStatus(item, true);
const handleInactivateClick = (item: ScheduleModel) => updateScheduleStatus(item, false);

const handleRemoveSortClick = (item: SortMenuItem) => {
    const index = sortMenuSelected.value.indexOf(item);
    if (index > -1) sortMenuSelected.value.splice(index, 1);
};

const handleRemoveFilterClick = (item: FilterItem) =>
    store.dispatch(ScheduleActions.CLEAR_FILTER, item);

// Watchers
watch([currentPage], fetchAllSchedules);

watch([levelFilter, search, filter, orderBy], () => {
    if (currentPage.value === 1) fetchAllSchedules();
    else currentPage.value = 1;
});

// Lifecycle
onMounted(() => {
    if (route.query.clearStateOnSetup) {
        search.value = '';
        sortMenuSelected.value = [];
        currentPage.value = 1;
        router.replace({ query: {} });
    }

    fetchAllSchedules();
});

const __returned__ = { route, router, uiStore, filterStore, schedules, count, perPage, search, sortMenuSelected, currentPage, filter, activeFilters, levelFilter, orderBy, setLoading, getQueryParams, fetchAllSchedules, updateScheduleStatus, handleActivateClick, handleInactivateClick, handleRemoveSortClick, handleRemoveFilterClick, get OfficeBuildingIcon() { return OfficeBuildingIcon }, get ScheduleListItem() { return ScheduleListItem }, get ScheduleListSort() { return ScheduleListSort }, get ScheduleListFilters() { return ScheduleListFilters }, get Permission() { return Permission } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})