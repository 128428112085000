import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue';
import store from '@/store';
import router from '@/router';
import { CogIcon, LogoutIcon } from '@heroicons/vue/outline';
import BaseTemplate from '@/views/templates/BaseTemplate.vue';
import { NavigationGetters } from '@/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppTemplate',
  setup(__props, { expose: __expose }) {
  __expose();

const sideNavigationItems = computed(() => store.getters[NavigationGetters.SIDE_NAVIGATION_ITEMS]);
const showLogout = computed(() => location.host !== 'embed.mobcall.com');

const handleConfigClick = () => {
    router.push('/settings');
};

const handleLogoutClick = () => {
    router.push('/logout');
};

const __returned__ = { sideNavigationItems, showLogout, handleConfigClick, handleLogoutClick, get CogIcon() { return CogIcon }, get LogoutIcon() { return LogoutIcon }, BaseTemplate }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})