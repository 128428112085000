import { defineComponent as _defineComponent } from 'vue'
import { computed, ComputedRef } from 'vue';
import store from '@/store';

import {
    PlusCircleIcon,
    ClockIcon,
    PlayIcon,
    ReplyIcon,
    PhoneMissedCallIcon,
    ReceiptRefundIcon,
    QuestionMarkCircleIcon,
    AnnotationIcon,
} from '@heroicons/vue/outline';

import {
    ConnectFlowModelingActions,
    ConnectFlowModelingGetters,
    ConnectFlowModelingMenu,
    FlowNodeModel,
    NodeType,
    VueFlowNodeModel,
} from '@/types';

import { flowNodeRules } from '@/configs';


export default /*@__PURE__*/_defineComponent({
  __name: 'ComponentSelector',
  setup(__props, { expose: __expose }) {
  __expose();

const menu: ComputedRef<ConnectFlowModelingMenu> = computed(
    () => store.getters['flow/' + ConnectFlowModelingGetters.MENU],
);

const showForm = computed({
    get: () => menu.value.show,
    set: (value) => store.dispatch('flow/' + ConnectFlowModelingActions.SET_MENU, value),
});

const triggerNode: ComputedRef<VueFlowNodeModel> = computed(() => {
    if (menu.value.idTriggerBy) {
        return store.getters['flow/' + ConnectFlowModelingGetters.ELEMENT_BY_ID](
            menu.value.idTriggerBy,
        );
    }
    return null;
});

const rules: ComputedRef<FlowNodeModel> = computed(
    () => flowNodeRules[triggerNode.value?.data.component],
);

function setUpNode(component: string) {
    const action = menu.value.action;

    switch (action) {
        case 'add':
            store.dispatch('flow/' + ConnectFlowModelingActions.PUSH_NEW_NODE, {
                data: { component },
            });
            break;
        case 'update':
            store.dispatch('flow/' + ConnectFlowModelingActions.EDIT_NODE, {
                data: { component },
            });
            break;
    }

    showForm.value = false;
}

function getNodeComponent(component: string) {
    switch (component) {
        case NodeType.TIME_VALIDATION:
            return ClockIcon;
        case NodeType.OPEN_EDGE:
            return PlusCircleIcon;
        case NodeType.PLAY_AUDIO:
            return PlayIcon;
        case NodeType.CLIENT_INFO:
            return QuestionMarkCircleIcon;
        case NodeType.TRANSFER_EXTENSION:
        case NodeType.TRANSFER_EXTERNAL:
        case NodeType.TRANSFER_QUEUE:
            return ReplyIcon;
        case NodeType.TRANSFER_SURVEY:
            return AnnotationIcon;
        case NodeType.HANG_UP:
            return PhoneMissedCallIcon;
        case NodeType.GO_TO:
            return ReceiptRefundIcon;
    }
}

const __returned__ = { menu, showForm, triggerNode, rules, setUpNode, getNodeComponent, get NodeType() { return NodeType } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})