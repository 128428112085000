<script lang="ts" setup>
import { ref, Ref, computed, watch, ComputedRef, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import { IntegrationService } from '@/services';
import { toastServiceError } from '@/services/core/notification';

import {
    IntegrationLogListFilters,
    IntegrationLogListItem,
    IntegrationLogListSort,
} from '@/views/pages/integration/components';

import {
    FilterItem,
    IntegrationLogModel,
    IntegrationServiceGetLogsParams,
    LevelModel,
    IntegrationLogActions,
    IntegrationLogGetters,
    SortMenuItem,
} from '@/types';

import { LinkIcon } from '@heroicons/vue/solid';

const route = useRoute();
const router = useRouter();

const props = defineProps({
    type: {
        type: String,
    },
});

const filterStore = useFilterStore();
const uiStore = useUiStore();

const integrationId = Number(route.params.id);
const perPage = 10;

const cols = [
    'callId',
    'callCreatedAt',
    'callType',
    'callStatus',
    'callSource',
    'callDestination',
    'createdAt',
    'status',
    'externalId',
    'observation',
];

const integrationLogs: Ref<IntegrationLogModel[]> = ref([]);
const count = ref(0);

const search = computed<string>({
    get: () => store.getters[IntegrationLogGetters.SEARCH],
    set: (search: string) => store.dispatch(IntegrationLogActions.SET_SEARCH, search),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[IntegrationLogGetters.SORT],
    set: (sort: SortMenuItem[]) => store.dispatch(IntegrationLogActions.SET_SORT, sort),
});

const currentPage = computed<number>({
    get: () => store.getters[IntegrationLogGetters.PAGE],
    set: (page: number) => store.dispatch(IntegrationLogActions.SET_PAGE, page),
});

const orderBy = computed<string>(() =>
    sortMenuSelected.value.map((s) => s.direction + s.key).join(','),
);

const filter = computed(() => store.getters[IntegrationLogGetters.FILTER]);
const activeFilters: ComputedRef<FilterItem[]> = computed(
    () => store.getters[IntegrationLogGetters.ACTIVE_FILTERS],
);

const levelFilter = computed<LevelModel | null>(() => filterStore.level);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getIntegrationLogs = async () => {
    const params: IntegrationServiceGetLogsParams = {
        page: currentPage.value,
        per_page: perPage,
        order_by: orderBy.value,
        search: search.value,
        status: filter.value.status?.value,
    };

    try {
        await setLoading(true);
        const response = await IntegrationService.getLogs<Array<IntegrationLogModel>>(
            integrationId,
            { params },
        );

        integrationLogs.value = response.data;
        count.value = parseInt(response.headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const handleRemoveSortClick = (item: SortMenuItem) => {
    sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);
};

const handleRemoveFilterClick = (item: FilterItem) => {
    store.dispatch(IntegrationLogActions.CLEAR_FILTER, item);
};

watch(
    [levelFilter, search, filter, orderBy],
    () => {
        if (currentPage.value === 1) getIntegrationLogs();
        else currentPage.value = 1;
    },
    { deep: true },
);

watch([currentPage], () => {
    getIntegrationLogs();
});

search.value = '';
sortMenuSelected.value = [];
currentPage.value = 1;
router.replace({ query: {} });

onMounted(getIntegrationLogs);
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="flex items-center text-xl font-semibold text-gray-800">
                    <LinkIcon class="mr-1 h-6 w-6 text-gray-500" aria-hidden="true" />
                    {{ $t('core.entity.Integrations') }} -
                    {{ $t('integration.integrations.' + props.type + '.title') }} -
                    {{ $t('integration.labels.logs') }}
                </h1>
            </template>
            <template #actions>
                <div class="flex items-center space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ListIntegration' }" />
                </div>
            </template>
        </UiPageHeader>

        <div class="space-y-2">
            <UiPanel class="flex px-4 py-2" no-padding>
                <UiListSearch
                    v-model="search"
                    :placeholder="$t('core.actions.Search')"
                    class="flex-1"
                />
                <div class="flex items-center space-x-4">
                    <IntegrationLogListSort v-model="sortMenuSelected" />
                    <IntegrationLogListFilters />
                </div>
            </UiPanel>

            <UiPanel v-if="sortMenuSelected.length || activeFilters.length" class="space-x-2">
                <UiSortMenuBadge
                    v-for="selected in sortMenuSelected"
                    :key="selected.key"
                    :item="selected"
                    @remove="handleRemoveSortClick(selected)"
                />
                <UiBadge
                    v-for="item in activeFilters"
                    :key="`activeFilter${item.field}`"
                    removable
                    @remove="handleRemoveFilterClick(item)"
                >
                    {{ $t(`integration.logs.filters.${item.field}.label`) }}: {{ item.label }}
                </UiBadge>
            </UiPanel>

            <UiPanel v-if="integrationLogs.length" no-padding :rounded="false">
                <table class="min-w-full divide-y divide-gray-300">
                    <thead>
                        <tr class="bg-gray-200">
                            <th
                                v-for="col in cols"
                                :key="col"
                                scope="col"
                                class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-800"
                            >
                                {{ $t(`integration.logs.cols.${col}`) }}
                            </th>
                        </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200">
                        <IntegrationLogListItem
                            v-for="log in integrationLogs"
                            :key="log.id"
                            :model-value="log"
                            class="even:bg-gray-100"
                        />
                    </tbody>
                </table>
            </UiPanel>

            <UiPanel v-else class="flex items-center justify-center">
                {{ $t('core.messages.noRecordsFound') }}
            </UiPanel>

            <UiPagination
                v-if="integrationLogs.length"
                v-model="currentPage"
                :count="count"
                :perPage="perPage"
            />
        </div>
    </div>
</template>
