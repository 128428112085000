<script lang="ts" setup>
import { PhoneIncomingIcon } from '@heroicons/vue/solid';

defineProps({
    nodeId: {
        type: String,
        required: true,
    },
});
</script>

<template>
    <div class="flex flex-col items-center">
        <UiNode
            :node-id="nodeId"
            :showHandleTarget="false"
            hideNodeId
            bgColor="bg-blue-50"
            borderColor="border-blue-300"
            roundedStyle="rounded-full"
            nodeWidth="w-20"
            nodeHeight="h-20"
        >
            <div class="flex flex-col items-center">
                <PhoneIncomingIcon class="mb-1 h-10 w-10 text-blue-600" />
            </div>
        </UiNode>
    </div>
</template>
