import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center" }
const _hoisted_2 = { class: "flex flex-col items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiNode = _resolveComponent("UiNode")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UiNode, {
      "node-id": $props.nodeId,
      showHandleTarget: false,
      hideNodeId: "",
      bgColor: "bg-blue-50",
      borderColor: "border-blue-300",
      roundedStyle: "rounded-full",
      nodeWidth: "w-20",
      nodeHeight: "h-20"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode($setup["PhoneIncomingIcon"], { class: "mb-1 h-10 w-10 text-blue-600" })
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["node-id"])
  ]))
}