interface CallRealtimeStatistics {
    callQueue?: number;
    callInboundInProgress?: number;
    callOutboundInProgress?: number;
}

interface UserRealtimeStatistics {
    userOnline?: number;
    userOffline?: number;
    averageQuality?: number;
}

export interface RealtimeStatisticsSummary extends CallRealtimeStatistics, UserRealtimeStatistics {}
