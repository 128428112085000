import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, onMounted } from 'vue';
import moment from 'moment';
import { useI18n } from 'vue-i18n';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import { BillingReportService } from '@/services';

import {
    BarChartConfig,
    BillingModel,
    BillingReportActions,
    BillingReportGetters,
    DataItem,
    FilterItem,
} from '@/types';

import { BillingReportFilters } from './components';


export default /*@__PURE__*/_defineComponent({
  __name: 'BillingReportPage',
  setup(__props, { expose: __expose }) {
  __expose();

const reportInfo: Ref<BillingModel | null> = ref(null);

const dateRange = computed<Array<Date>>({
    get: () => filterStore.dateRange,
    set: (value) => filterStore.setDateRange(value),
});

const filter = computed(() => store.getters[BillingReportGetters.FILTER]);
const activeFilters = computed(() => store.getters[BillingReportGetters.ACTIVE_FILTERS]);

const filterStore = useFilterStore();
const uiStore = useUiStore();
const { t } = useI18n();

watch(
    [dateRange, filter],
    () => {
        fetchReportData();
    },
    { deep: true },
);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchReportData = async () => {
    const params = {
        start_date: moment.utc(dateRange.value[0]).format('YYYY-MM-DD'),
        end_date: moment.utc(dateRange.value[1]).format('YYYY-MM-DD'),
        user_id: filter.value.user?.id || null,
        source_number: filter.value.sourceNumber?.value || null,
        destination_number: filter.value.destinationNumber?.value || null,
        status: filter.value.status?.value || null,
        type: filter.value.type?.value || null,
    };

    try {
        setLoading(true);
        const response = await BillingReportService.getInfo({ params });
        reportInfo.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveFilter = (filter: FilterItem) => {
    store.dispatch(BillingReportActions.CLEAR_FILTER, filter.field);
};

const headerStats = computed<DataItem<string>[]>(() => {
    const totalMinutes = reportInfo.value?.totalMinutes || 0;
    const totalCost = reportInfo.value?.totalCost?.toFixed(2) || '0,00';

    return [
        {
            name: 'totalMinutes',
            value: `${Math.floor(totalMinutes)}:${Math.floor((totalMinutes % 1) * 60)
                .toString()
                .padStart(2, '0')}`,
        },
        {
            name: 'totalCost',
            value: `R$ ${totalCost}`,
        },
    ];
});

const getChart = (
    title: string,
    dataKey: keyof BillingModel['dailyResults'][0],
    color = '#BFDBFE',
): BarChartConfig => {
    const category =
        reportInfo.value?.dailyResults?.map((item) => moment(item.date).format('D [de] MMM')) || [];

    const value = reportInfo.value?.dailyResults?.map((item) => item[dataKey] || 0) || [];

    return {
        title,
        category,
        series: [
            {
                name: title,
                value,
                color,
            },
        ],
    };
};

const dailyConsumptionChart = computed(() =>
    getChart(t('report.billing.dailyConsumptionValue'), 'cost', '#00C3AE'),
);

onMounted(() => {
    fetchReportData();
});

const __returned__ = { reportInfo, dateRange, filter, activeFilters, filterStore, uiStore, t, setLoading, fetchReportData, handleRemoveFilter, headerStats, getChart, dailyConsumptionChart, get BillingReportFilters() { return BillingReportFilters } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})