import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import moment from 'moment';
import { useI18n } from 'vue-i18n';

import store from '@/store';
import { useUiStore, useFilterStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import CallService from '@/services/api/CallService';
import AnalyticsService from '@/services/analytics/AnalyticsService';

import {
    CallModel,
    CallType,
    CallStatus,
    SortMenuItem,
    CallActions,
    CallGetters,
    FilterItem,
    LevelModel,
} from '@/types';

import { CallListFilters, CallDetails, CallListSort } from '@/views/pages/call/components';

import {
    PhoneIcon,
    PhoneIncomingIcon,
    PhoneOutgoingIcon,
    DocumentDownloadIcon,
} from '@heroicons/vue/solid';

const perPage = 20;

export default /*@__PURE__*/_defineComponent({
  __name: 'CallPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const filterStore = useFilterStore();
const uiStore = useUiStore();

const count = ref(0);
const calls: Ref<CallModel[]> = ref([]);

const currentPage = computed<number>({
    get: () => store.getters[CallGetters.PAGE],
    set: (page: number) => store.dispatch(CallActions.SET_PAGE, page),
});

const search = computed<string>({
    get: () => store.getters[CallGetters.SEARCH],
    set: (value) => store.dispatch(CallActions.SET_SEARCH, value),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[CallGetters.SORT],
    set: (value) => store.dispatch(CallActions.SET_SORT, value),
});

const orderBy = computed(() => sortMenuSelected.value.map((s) => s.direction + s.key).join(','));

const levelFilter = computed<LevelModel | null>(() => filterStore.level);
const dateRange = computed<Array<Date>>({
    get: () => filterStore.dateRange,
    set: (range) => filterStore.setDateRange(range),
});

const filterValue = computed(() => store.getters[CallGetters.FILTER]);

const type = computed(() => filterValue.value.type?.value?.toString() ?? null);
const status = computed(() => filterValue.value.status?.value?.toString() ?? null);
const userLabel = computed(() => filterValue.value.userLabel?.id?.toString() ?? null);
const media = computed(() => filterValue.value.media?.value?.toString() ?? null);
const numberClient = computed(() => filterValue.value.numberClient?.value?.toString() ?? null);
const word = computed(() => filterValue.value.word ?? null);
const user = computed(() => filterValue.value.user?.id?.toString() ?? null);

const activeFilters = computed<FilterItem[]>(() => store.getters[CallGetters.ACTIVE_FILTERS]);

const tabFilter = computed((): FilterItem | null => {
    if (route.params.tab) {
        const filter = route.params.tab.toString().toUpperCase() as CallStatus;
        if (Object.keys(CallType).includes(filter))
            return { field: 'type', label: '', value: filter };
        if (Object.values(CallStatus).includes(filter))
            return { field: 'status', label: '', value: filter };
    }
    return null;
});

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const formatFilename = (startDate: string, endDate: string) => {
    const start = moment(startDate).format('DD/MM/YYYY');
    const end = moment(endDate).format('DD/MM/YYYY');
    return `${t('dashboard.report.call')} - ${start} - ${end}.csv`;
};

const prepareCallParams = () => {
    if (!levelFilter.value || dateRange.value.length < 2) return null;
    const params = {
        search: search.value,
        startDate: moment.utc(dateRange.value[0]).format('YYYY-MM-DD'),
        endDate: moment.utc(dateRange.value[1]).format('YYYY-MM-DD'),
        type: type.value,
        status: status.value,
        userLabel: userLabel.value,
        media: media.value,
        numberClient: numberClient.value,
        word: word.value,
        user: user.value,
        page: currentPage.value,
        per_page: perPage,
        order_by: orderBy.value,
    };
    if (tabFilter.value?.field === 'type') params.type = tabFilter.value.value;
    if (tabFilter.value?.field === 'status') params.status = tabFilter.value.value;
    return params;
};

const getCalls = async () => {
    const params = prepareCallParams();
    if (!params) return;
    try {
        await setLoading(true);
        const response = await CallService.getAll({ params });
        calls.value = response.data;
        count.value = parseInt(response.headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const getCsv = async () => {
    const params = prepareCallParams();
    if (!params) return;

    try {
        await setLoading(true);
        await AnalyticsService.trackingAction('CallCSVDownload');
        const response = await CallService.getCsvReport({ params });

        const filename = formatFilename(params.startDate, params.endDate);
        const csv =
            typeof response.data === 'string' ? response.data : JSON.stringify(response.data);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const applyTabFilter = (value: string) => {
    router.push({ name: 'CallList', params: { tab: value.toLowerCase() } });
};

const showAllCalls = () => {
    router.push({ name: 'CallList' });
};

const handleRemoveSortClick = (item: SortMenuItem) => {
    sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);
};

const handleRemoveFilter = (field: string) => {
    store.dispatch(CallActions.CLEAR_FILTER, field);
};

const bulletCallStatusColor = (status: string) => {
    switch (status) {
        case CallStatus.ANSWERED:
            return 'bg-green-600';
        case CallStatus.BUSY:
            return 'bg-yellow-500';
        case CallStatus.NO_ANSWER:
            return 'bg-red-500';
        default:
            return 'bg-gray-600';
    }
};

watch(
    [
        search,
        levelFilter,
        dateRange,
        orderBy,
        status,
        userLabel,
        type,
        media,
        numberClient,
        user,
        word,
        tabFilter,
    ],
    () => {
        if (currentPage.value === 1) getCalls();
        else currentPage.value = 1;
    },
);

watch(currentPage, () => {
    getCalls();
});

if (route.query.clearStateOnSetup) {
    currentPage.value = 1;
    router.replace({ query: {} });
}

onMounted(async () => {
    await getCalls();
});

const __returned__ = { route, router, t, filterStore, uiStore, count, perPage, calls, currentPage, search, sortMenuSelected, orderBy, levelFilter, dateRange, filterValue, type, status, userLabel, media, numberClient, word, user, activeFilters, tabFilter, setLoading, formatFilename, prepareCallParams, getCalls, getCsv, applyTabFilter, showAllCalls, handleRemoveSortClick, handleRemoveFilter, bulletCallStatusColor, get CallType() { return CallType }, get CallStatus() { return CallStatus }, get CallListFilters() { return CallListFilters }, get CallDetails() { return CallDetails }, get CallListSort() { return CallListSort }, get PhoneIcon() { return PhoneIcon }, get PhoneIncomingIcon() { return PhoneIncomingIcon }, get PhoneOutgoingIcon() { return PhoneOutgoingIcon }, get DocumentDownloadIcon() { return DocumentDownloadIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})