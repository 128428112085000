import { ListFilters, MultiSelectOption } from '@/types';

export interface ConnectFlowSurveyFilter {
    isActive: MultiSelectOption | null;
}

export interface ConnectFlowSurveyState extends ListFilters {
    filter: ConnectFlowSurveyFilter;
}

export enum ConnectFlowSurveyMutations {
    SET_SEARCH = 'connectFlowSurvey_mutation_setSearch',
    SET_SORT = 'connectFlowSurvey_mutation_setSort',
    SET_PAGE = 'connectFlowSurvey_mutation_setPage',
    SET_FILTER = 'connectFlowSurvey_mutation_setFilter',
    CLEAR_FILTER = 'connectFlowSurvey_mutation_clearFilter',
}

export enum ConnectFlowSurveyActions {
    SET_SEARCH = 'connectFlowSurvey_action_setSearch',
    SET_SORT = 'connectFlowSurvey_action_setSort',
    SET_PAGE = 'connectFlowSurvey_action_setPage',
    SET_FILTER = 'connectFlowSurvey_action_setFilter',
    CLEAR_FILTER = 'connectFlowSurvey_action_clearFilter',
}

export enum ConnectFlowSurveyGetters {
    SEARCH = 'connectFlowSurvey_getter_search',
    SORT = 'connectFlowSurvey_getter_sort',
    PAGE = 'connectFlowSurvey_getter_page',
    DEFAULT_FILTER = 'connectFlowSurvey_getter_defaultFilter',
    FILTER = 'connectFlowSurvey_getter_filter',
    ACTIVE_FILTERS = 'connectFlowSurvey_getter_activeFilters',
}
