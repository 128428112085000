import { FullAudit, UserModel } from '@/types';

export interface UserExtensionModel extends FullAudit {
    id: number;
    levelId: number;
    userId: number;
    label: string;
    number: number;
    toDelete: boolean;
    user?: UserModel;
}
