import axios from 'axios';
import { useFilterStore } from '@/stores';

const baseURL = ['production', 'staging'].includes(process.env.NODE_ENV)
    ? '/api'
    : 'http://localhost:3000/api';

const instance = axios.create({
    baseURL,
    headers: {
        'Content-type': 'application/json',
    },
});

instance.interceptors.request.use(
    (config) => {
        config.headers.authorization = 'Bearer ' + (localStorage.getItem('token') ?? '');
        config.headers['Accept-Language'] = localStorage.getItem('locale') ?? '';

        const filterStore = useFilterStore();
        const levelFilter = filterStore.level;
        if (levelFilter === null) {
            const storageLevel = localStorage.getItem('level');
            if (storageLevel !== null) {
                filterStore.setLevel(JSON.parse(atob(storageLevel)));
            }
        }
        config.headers['level'] = levelFilter?.id;
        return config;
    },
    (error) => Promise.reject(error),
);

export default instance;
