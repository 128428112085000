import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "text-xs"
}
const _hoisted_4 = ["name", "id", "type", "placeholder", "autocomplete", "value", "disabled", "rows"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.label !== false)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: $props.name,
          class: "mb-2 block text-sm font-medium text-gray-800"
        }, [
          _createTextVNode(_toDisplayString($props.label) + " ", 1 /* TEXT */),
          ($props.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
            : _createCommentVNode("v-if", true)
        ], 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    _createElementVNode("textarea", {
      name: $props.name,
      id: $props.name,
      type: $props.type,
      "no-": "",
      placeholder: $props.placeholder,
      autocomplete: $props.autocomplete,
      class: _normalizeClass(["block w-full resize-none rounded border-0 text-gray-800 shadow ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset", [
                $props.inputClass,
                !$setup.hasErros ? 'focus:ring-primary ring-gray-300' : 'ring-danger focus:ring-danger',
                $props.disabled ? 'bg-gray-300' : '',
            ]]),
      value: $setup.props.modelValue,
      onInput: $setup.handleInput,
      disabled: $props.disabled,
      onBlur: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('blur', $event))),
      rows: $setup.props.rows
    }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_4),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.errors, (error) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "text-danger bg-danger-50 absolute z-10 w-full px-2 py-2 text-sm shadow",
        key: error.$uid
      }, [
        _createElementVNode("p", null, _toDisplayString(error.$response ? error.$response : error.$message), 1 /* TEXT */)
      ]))
    }), 128 /* KEYED_FRAGMENT */)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.silentErrors, (error) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "text-danger text-sm",
        key: error.$uid
      }, [
        _createElementVNode("p", null, _toDisplayString(error.$response ? error.$response : error.$message), 1 /* TEXT */)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}