import { defineComponent as _defineComponent } from 'vue'
import { PropType } from 'vue';
import { ContactModel, Permission } from '@/types';
import { MailIcon, PhoneIcon } from '@heroicons/vue/solid';
import { PencilAltIcon } from '@heroicons/vue/outline';
import UiAuditInfo from '@/components/UiAuditInfo.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactListItem',
  props: {
    modelValue: {
        type: Object as PropType<ContactModel>,
        required: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();



const __returned__ = { get Permission() { return Permission }, get MailIcon() { return MailIcon }, get PhoneIcon() { return PhoneIcon }, get PencilAltIcon() { return PencilAltIcon }, UiAuditInfo }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})