import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, onMounted } from 'vue';
import store from '@/store';
import { useI18n } from 'vue-i18n';
import { CityOption, MultiSelectOption, NumberActions, NumberFilter, NumberGetters } from '@/types';
import { FilterIcon } from '@heroicons/vue/solid';
import NumberService from '@/services/api/NumberService';
import { toastServiceError } from '@/services/core/notification';


export default /*@__PURE__*/_defineComponent({
  __name: 'NumberListFilters',
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const filtersOpened = ref(false);

const storeFilter = computed(() => store.getters[NumberGetters.FILTER]);
const defaultFilter = computed(() => store.getters[NumberGetters.DEFAULT_FILTER]);

const filter: Ref<NumberFilter> = ref({
    hasConnectFlow: defaultFilter.value.hasConnectFlow,
    state: null,
    city: null,
});

const hasConnectFlowFilterOptions: Ref<MultiSelectOption[]> = ref([
    {
        label: t('number.filters.hasConnectFlow.options.yes'),
        value: true,
    } as MultiSelectOption,
    {
        label: t('number.filters.hasConnectFlow.options.no'),
        value: false,
    } as MultiSelectOption,
]);
const stateOptions: Ref<MultiSelectOption[]> = ref([]);
const getLevelsStates = async () => {
    try {
        const response = await NumberService.getStates<MultiSelectOption[]>();
        stateOptions.value = response.data;
    } catch (error) {
        toastServiceError(error);
    }
};

const cities: Ref<CityOption[]> = ref([]);
const cityOptions = computed(() => {
    if (filter.value.state !== null && cities.value.length) {
        return cities.value.filter(
            (city) => city.state !== undefined && city.state === filter.value.state?.value,
        );
    }
    return cities.value;
});

const getLevelsCities = async () => {
    try {
        const response = await NumberService.getCities<CityOption[]>();
        cities.value = response.data;
    } catch (error) {
        toastServiceError(error);
    }
};

function applyFilters() {
    store.dispatch(NumberActions.SET_FILTER, filter.value);
}

function clearFilters() {
    store.dispatch(NumberActions.CLEAR_FILTER);
    refreshLocalFilter();
}

function refreshLocalFilter() {
    filter.value.hasConnectFlow = storeFilter.value.hasConnectFlow;
    filter.value.state = storeFilter.value.state;
    filter.value.city = storeFilter.value.city;
}

watch(filtersOpened, () => {
    refreshLocalFilter();
});

onMounted(() => {
    getLevelsStates();
    getLevelsCities();
});

const __returned__ = { t, filtersOpened, storeFilter, defaultFilter, filter, hasConnectFlowFilterOptions, stateOptions, getLevelsStates, cities, cityOptions, getLevelsCities, applyFilters, clearFilters, refreshLocalFilter, get FilterIcon() { return FilterIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})