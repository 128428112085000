import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fixed inset-0 overflow-hidden" }
const _hoisted_2 = { class: "absolute inset-0 overflow-hidden" }
const _hoisted_3 = { class: "pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10" }
const _hoisted_4 = { class: "flex h-full flex-col overflow-y-scroll bg-gray-50 shadow" }
const _hoisted_5 = { class: "flex items-start justify-between border-b-2 border-gray-200 bg-gray-100 py-3 pl-3 pr-2" }
const _hoisted_6 = { class: "ml-4 flex h-7 items-center" }
const _hoisted_7 = { class: "sr-only" }
const _hoisted_8 = { class: "relative mt-6 flex-1 px-4 sm:px-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "button"),
    _createVNode($setup["TransitionRoot"], {
      as: "template",
      show: $setup.props.modelValue
    }, {
      default: _withCtx(() => [
        _createVNode($setup["Dialog"], {
          as: "div",
          class: "relative z-50",
          onClose: _cache[1] || (_cache[1] = ($event: any) => ($setup.handleToggle(false)))
        }, {
          default: _withCtx(() => [
            _createVNode($setup["TransitionChild"], {
              as: "template",
              enter: "ease-in-out duration-500",
              "enter-from": "opacity-0",
              "enter-to": "opacity-100",
              leave: "ease-in-out duration-500",
              "leave-from": "opacity-100",
              "leave-to": "opacity-0"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("div", { class: "fixed inset-0 bg-white bg-opacity-25 transition-opacity" }, null, -1 /* HOISTED */)
              ])),
              _: 1 /* STABLE */
            }),
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode($setup["TransitionChild"], {
                    as: "template",
                    enter: "transform transition ease-in-out duration-500 sm:duration-700",
                    "enter-from": "translate-x-full",
                    "enter-to": "translate-x-0",
                    leave: "transform transition ease-in-out duration-500 sm:duration-700",
                    "leave-from": "translate-x-0",
                    "leave-to": "translate-x-full"
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["DialogPanel"], { class: "pointer-events-auto w-screen max-w-lg" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_5, [
                                _createVNode($setup["DialogTitle"], { class: "flex items-center text-lg font-semibold text-gray-800" }, {
                                  default: _withCtx(() => [
                                    (_openBlock(), _createBlock(_resolveDynamicComponent($props.icon), {
                                      class: "text-secondary mr-1 h-8 w-8",
                                      "aria-hidden": "true"
                                    })),
                                    _createElementVNode("span", null, _toDisplayString($props.title), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createElementVNode("div", _hoisted_6, [
                                  _createElementVNode("button", {
                                    type: "button",
                                    class: "rounded bg-gray-50 p-1 text-gray-500 ring-1 ring-gray-300 hover:bg-white hover:text-gray-600 focus:ring-2 focus:ring-opacity-50",
                                    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.handleToggle(false)))
                                  }, [
                                    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('core.actions.Close')), 1 /* TEXT */),
                                    _createVNode($setup["XIcon"], {
                                      class: "h-5 w-5",
                                      "aria-hidden": "true"
                                    })
                                  ])
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_8, [
                              _renderSlot(_ctx.$slots, "default")
                            ])
                          ])
                        ]),
                        _: 3 /* FORWARDED */
                      })
                    ]),
                    _: 3 /* FORWARDED */
                  })
                ])
              ])
            ])
          ]),
          _: 3 /* FORWARDED */
        })
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["show"])
  ], 64 /* STABLE_FRAGMENT */))
}