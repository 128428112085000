<script lang="ts" setup>
import { computed, ref, watch, onMounted } from 'vue';
import moment from 'moment/moment';

import { useFilterStore, useUiStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import AgentDeviceStatusHistoryReportService from '@/services/api/AgentDeviceStatusHistoryReportService';

import { formatSecToTime } from '@/utils/datetime';
import SVGChromeLogo from '@/components/svg/SVGChromeLogo.vue';
import SVGDeviceExternal from '@/components/svg/SVGDeviceExternal.vue';

import { LevelModel, UserDeviceType, UserDeviceStatus, UserExtensionQueueStatus } from '@/types';

import { AgentDeviceStatusHistoryReportServiceGetInfoParams } from '@/types/services/AgentDeviceStatusHistoryReportService';
import { UserDeviceModel } from '@/types/models/UserDeviceModel';

import { DesktopComputerIcon } from '@heroicons/vue/solid';
import { DeviceMobileIcon } from '@heroicons/vue/outline';

const totalTimePaused = ref(0);
const totalTimeCall = ref(0);
const agentsDevices = ref<UserDeviceModel[]>([]);

const dateRange = computed<Array<Date>>({
    get: () => filterStore.dateRange,
    set: (dateRange: Array<Date>) => filterStore.setDateRange(dateRange),
});

const levelFilter = computed<LevelModel | null>(() => filterStore.level);

const filterStore = useFilterStore();
const uiStore = useUiStore();

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getDeviceIcon = (device: UserDeviceModel) =>
    ({
        [UserDeviceType.MOBILE]: DeviceMobileIcon,
        [UserDeviceType.DESKTOP]: DesktopComputerIcon,
        [UserDeviceType.WEB]: SVGChromeLogo,
        [UserDeviceType.EXTERNAL]: SVGDeviceExternal,
    })[device.deviceType];

const getStatusColor = (status: string) =>
    ({
        [UserDeviceStatus.CONNECTED]: 'text-blue-600',
        [UserDeviceStatus.UNAVAILABLE]: 'text-red-500',
        [UserDeviceStatus.IN_CALL]: 'text-green-600',
    })[status];

const getQueueColorStatus = (status: string) =>
    ({
        [UserExtensionQueueStatus.PAUSED]: 'text-yellow-600',
        [UserExtensionQueueStatus.AVAILABLE]: 'text-green-500',
    })[status];

const tableHeaders = [
    {
        key: 'userName',
        label: 'report.agentDeviceStatusHistory.details.userName',
        align: 'left',
    },
    {
        key: 'extension',
        label: 'report.agentDeviceStatusHistory.details.extension',
        align: 'center',
    },
    {
        key: 'totalTimeOnline',
        label: 'report.agentDeviceStatusHistory.details.totalTimeOnline',
        align: 'center',
    },
    {
        key: 'totalTimePaused',
        label: 'report.agentDeviceStatusHistory.details.totalTimePaused',
        align: 'center',
    },
    {
        key: 'totalTimeInCall',
        label: 'report.agentDeviceStatusHistory.details.totalTimeInCall',
        align: 'center',
    },
    {
        key: 'lastStatus',
        label: 'report.agentDeviceStatusHistory.details.lastStatus',
        align: 'center',
    },
    {
        key: 'queues',
        label: 'report.agentDeviceStatusHistory.details.queues',
        align: 'center',
    },
    {
        key: 'queueStatus',
        label: 'report.agentDeviceStatusHistory.details.queueStatus',
        align: 'center',
    },
];

const getReportData = async () => {
    const params: AgentDeviceStatusHistoryReportServiceGetInfoParams = {
        startDate: moment.utc(dateRange.value[0]).format('YYYY-MM-DD'),
        endDate: moment.utc(dateRange.value[1]).format('YYYY-MM-DD'),
    };

    try {
        setLoading(true);
        const { data } = await AgentDeviceStatusHistoryReportService.getInfo({ params });

        totalTimePaused.value = data.totalTimePaused;
        totalTimeCall.value = data.totalTimeCall;
        agentsDevices.value = data.devices;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

watch([levelFilter, dateRange], getReportData);

onMounted(() => {
    getReportData();
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('report.agentDeviceStatusHistory.title') }}
                </h1>
            </template>
            <template #actions>
                <UiDatePicker v-model="dateRange" range multiCalendars />
            </template>
        </UiPageHeader>

        <dl class="grid grid-cols-1 gap-4 md:grid-cols-2">
            <UiPanel v-for="(value, key) in { totalTimePaused, totalTimeCall }" :key="key">
                <dt class="text-center text-xs font-medium text-gray-500">
                    {{ $t(`report.agentDeviceStatusHistory.${key}`) }}
                </dt>
                <dd class="mt-1 text-center text-3xl font-semibold text-gray-800">
                    {{ formatSecToTime(value) }}
                </dd>
            </UiPanel>
        </dl>

        <UiPanel no-padding>
            <table class="w-full border-collapse text-sm">
                <thead class="sticky top-0 bg-gray-200 text-gray-800">
                    <tr>
                        <th
                            v-for="header in tableHeaders"
                            :key="header.key"
                            class="p-4"
                            :class="header.align === 'center' ? 'text-center' : 'text-left'"
                        >
                            {{ $t(header.label) }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(device, idx) in agentsDevices" :key="idx" class="even:bg-gray-100">
                        <td class="p-4">
                            <div class="flex items-center text-left">
                                <component :is="getDeviceIcon(device)" class="mr-1 h-4 w-4" />
                                <span class="whitespace-nowrap">{{ device.userName }}</span>
                            </div>
                        </td>
                        <td class="px-4 py-2 text-center">{{ device.extension }}</td>
                        <td class="px-4 py-2 text-center">
                            {{ formatSecToTime(device.totalTime.CONNECTED, true) }}
                        </td>
                        <td class="px-4 py-2 text-center">
                            {{
                                device.queues.length
                                    ? formatSecToTime(device.queues[0].pauseDuration, true)
                                    : '-'
                            }}
                        </td>
                        <td class="px-4 py-2 text-center">
                            {{ formatSecToTime(device.totalTime.IN_CALL, true) }}
                        </td>
                        <td class="px-4 py-2 text-center">
                            <span :class="getStatusColor(device.lastStatus)">
                                {{
                                    $t(
                                        `report.agentDeviceStatusHistory.deviceStatus.${device.lastStatus}`,
                                    )
                                }}
                            </span>
                            <div class="text-xs">
                                {{ moment(device.lastStatusAt).fromNow() }}
                            </div>
                        </td>
                        <td class="px-4 py-2 text-center">
                            {{ device.queues.map((q) => q.name).join(', ') }}
                        </td>
                        <td
                            class="px-4 py-2 text-center"
                            :class="getQueueColorStatus(device.queueStatus)"
                        >
                            <template v-if="device.queueStatus">
                                <span>
                                    {{
                                        $t(
                                            `report.agentDeviceStatusHistory.queueStatus.${device.queueStatus}`,
                                        )
                                    }}
                                </span>
                                <span>
                                    {{ moment(device.queueStatusAt).fromNow() }}
                                </span>
                            </template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </UiPanel>
    </div>
</template>
