import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import store from '@/store';
import { FilterIcon } from '@heroicons/vue/solid';

import { LevelFilter, MultiSelectOption, ScheduleActions, ScheduleGetters } from '@/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'ScheduleListFilters',
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const filtersOpened = ref(false);

const defaultFilter = computed(() => store.getters[ScheduleGetters.DEFAULT_FILTER]);

const filter: Ref<LevelFilter> = ref({
    isActive: defaultFilter.value.isActive,
});

const storeFilters = computed<LevelFilter>(() => store.getters[ScheduleGetters.FILTER]);

const isActiveFilterOptions = [
    {
        label: t('schedule.filters.isActive.options.active'),
        value: true,
    },
    {
        label: t('schedule.filters.isActive.options.inactive'),
        value: false,
    },
] as MultiSelectOption[];

const applyFilters = () => {
    store.dispatch(ScheduleActions.SET_FILTER, filter.value);
};

const clearFilters = () => {
    store.dispatch(ScheduleActions.CLEAR_FILTER);
    refreshLocalFilter(); // sync UI with store after clearing
};

const refreshLocalFilter = () => {
    filter.value.isActive = storeFilters.value.isActive;
};

watch(filtersOpened, () => {
    refreshLocalFilter(); // sync when panel is toggled
});

const __returned__ = { t, filtersOpened, defaultFilter, filter, storeFilters, isActiveFilterOptions, applyFilters, clearFilters, refreshLocalFilter, get FilterIcon() { return FilterIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})