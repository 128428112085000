import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';

import {
    UsersStatusDoughnutChart,
    CallQueueGaugeChart,
    QueueDetailsTable,
    UsersExtensionsDetailsTable,
    DataTotalize,
} from '@/views/pages/reports/realtime/components';

import RealtimeReportService from '@/services/api/RealtimeReportService';
import { toastServiceError } from '@/services/core/notification';

import { RealtimeStatisticsSummary, LevelModel } from '@/types';
import { useFilterStore } from '@/stores';

// Constants and route/props

// State

export default /*@__PURE__*/_defineComponent({
  __name: 'RealtimeReportPage',
  setup(__props, { expose: __expose }) {
  __expose();

// Imports
const dataTotalize = ref<RealtimeStatisticsSummary>({
    callQueue: 0,
    callInboundInProgress: undefined,
    callOutboundInProgress: undefined,
    userOffline: undefined,
    userOnline: undefined,
    averageQuality: undefined,
});

const loading = ref(false);

// Computed
const levelFilter = computed<LevelModel | null>(() => filterStore.level);

// Composables
const filterStore = useFilterStore();

// Methods
const fetchStatisticsSummary = async () => {
    try {
        loading.value = true;
        const { data } = await RealtimeReportService.getDataTotalize<RealtimeStatisticsSummary>();
        dataTotalize.value = { ...data };
    } catch (error) {
        toastServiceError(error);
    } finally {
        loading.value = false;
    }
};

let interval: number;

// Watchers
watch(levelFilter, () => {
    dataTotalize.value.callQueue = 0;
    dataTotalize.value.callInboundInProgress = undefined;
    dataTotalize.value.callOutboundInProgress = undefined;
    dataTotalize.value.userOffline = undefined;
    dataTotalize.value.userOnline = undefined;
    dataTotalize.value.averageQuality = undefined;

    clearInterval(interval);
    interval = setInterval(fetchStatisticsSummary, 5000);
});

// Lifecycle
onMounted(() => {
    interval = setInterval(fetchStatisticsSummary, 5000);
});
onUnmounted(() => {
    clearInterval(interval);
});

const __returned__ = { dataTotalize, loading, levelFilter, filterStore, fetchStatisticsSummary, get interval() { return interval }, set interval(v) { interval = v }, get UsersStatusDoughnutChart() { return UsersStatusDoughnutChart }, get CallQueueGaugeChart() { return CallQueueGaugeChart }, get QueueDetailsTable() { return QueueDetailsTable }, get UsersExtensionsDetailsTable() { return UsersExtensionsDetailsTable }, get DataTotalize() { return DataTotalize } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})