import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "multiselect-single-label" }
const _hoisted_3 = { class: "multiselect-multiple-label gap-1.5" }
const _hoisted_4 = ["onMousedown"]
const _hoisted_5 = {
  key: 0,
  class: "text-danger bg-danger-100 absolute z-10 w-full px-2 py-2 text-sm shadow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Multiselect"], {
      classes: {
                container: `multiselect ${$setup.getContainerClass}`,
            },
      id: $setup.props.name,
      name: $setup.props.name,
      object: true,
      options: $setup.props.options,
      value: $setup.selectedOption,
      mode: $setup.multiselectMode,
      label: $setup.props.label,
      valueProp: $setup.props.valueProp,
      placeholder: $setup.props.placeholder,
      "close-on-select": $setup.multiselectMode === $setup.MultiselectMode.SINGLE,
      "can-clear": $setup.props?.canClear,
      "can-deselect": $setup.props?.canDeselect,
      searchable: $setup.props.searchable !== 'no',
      minChars: 4,
      resolveOnLoad: false,
      delay: $setup.props.searchable === 'async' ? 300 : -1,
      disabled: $props.disabled,
      noResultsText: $setup.computedNoResultsText,
      noOptionsText: $setup.computedNoOptionsText,
      class: _normalizeClass([
                $props.errors.length && 'border-danger',
                $props.disabled && 'bg-gray-100 text-gray-500 ring-gray-200',
                'py-1.5 shadow ring-1',
            ]),
      onInput: $setup.handleInput,
      onSearchChange: _cache[0] || (_cache[0] = ($event: any) => ($setup.searchQuery = $event))
    }, {
      singlelabel: _withCtx(({ value }) => [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "selected-label", { value: value }, () => [
            _createTextVNode(_toDisplayString(value[$setup.props.label]), 1 /* TEXT */)
          ])
        ])
      ]),
      multiplelabel: _withCtx(({ values }) => [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(values, (item) => {
            return (_openBlock(), _createElementBlock("span", {
              key: `${$setup.props.name}${item.value}${item.value}`,
              class: "rounded border border-gray-200 p-0.5 px-1.5"
            }, _toDisplayString(item[$setup.props.valueProp]) + " - " + _toDisplayString(item[$setup.props.label]), 1 /* TEXT */))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      option: _withCtx(({ option }) => [
        _renderSlot(_ctx.$slots, "option-label", { option: option }, () => [
          _createTextVNode(_toDisplayString(option[$setup.props.label]), 1 /* TEXT */)
        ])
      ]),
      tag: _withCtx(({ option, handleTagRemove, disabled }) => [
        _createElementVNode("div", {
          class: _normalizeClass(["mb-1 mr-1 flex items-center whitespace-nowrap rounded bg-gray-400 py-0.5 pl-2 text-sm font-semibold text-white rtl:ml-1 rtl:mr-0 rtl:pl-0 rtl:pr-2", {
                        'is-disabled': disabled,
                    }])
        }, [
          _createTextVNode(_toDisplayString(option[$setup.props.label]) + " ", 1 /* TEXT */),
          (!disabled)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "multiselect-tag-remove",
                onMousedown: _withModifiers(($event: any) => (handleTagRemove(option, $event)), ["prevent"])
              }, _cache[1] || (_cache[1] = [
                _createElementVNode("span", { class: "multiselect-tag-remove-icon" }, null, -1 /* HOISTED */)
              ]), 40 /* PROPS, NEED_HYDRATION */, _hoisted_4))
            : _createCommentVNode("v-if", true)
        ], 2 /* CLASS */)
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["classes", "id", "name", "options", "value", "mode", "label", "valueProp", "placeholder", "close-on-select", "can-clear", "can-deselect", "searchable", "delay", "disabled", "noResultsText", "noOptionsText", "class"]),
    ($props.errors.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.errors, (error) => {
            return (_openBlock(), _createElementBlock("p", {
              key: error.$uid
            }, _toDisplayString(error.$message), 1 /* TEXT */))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}