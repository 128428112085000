import { defineComponent as _defineComponent } from 'vue'
import { computed, PropType, reactive, Ref, watch } from 'vue';
import { requiredIf } from '@/utils/validators';

import { UserService } from '@/services';
import { ExtensionFormState, UserDeviceFormState } from '@/types';
import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserFormExtension',
  props: {
    modelValue: {
        type: Object as PropType<ExtensionFormState>,
        required: true,
        default: () => ({
            id: null,
            levelId: null,
            label: null,
            number: null,
        }),
    },
    devices: {
        type: Object as PropType<UserDeviceFormState>,
        required: true,
    },
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const { t } = useI18n();

const props = __props;

const noDevice = computed(() => Object.values(props.devices).filter((v) => v).length === 0);

const formState = reactive<ExtensionFormState>(
    props.modelValue || {
        id: null,
        levelId: null,
        label: null,
        number: null,
    },
);

const isTaken = helpers.withAsync(async (value: number, siblings: ExtensionFormState) => {
    if (value === null || value.toString() === '') {
        return true;
    }

    const r = await UserService.checkExtensionNumber(siblings);
    if (r) {
        return true;
    } else {
        throw t('user.error.extensionUsed');
    }
});

const rules = computed<ValidationArgs<ExtensionFormState>>(() => {
    const r: ValidationArgs<ExtensionFormState> = {
        id: {},
        levelId: {},
        label: {},
        number: {
            required: requiredIf(() => {
                return (
                    formState.levelId !== null &&
                    Object.values(props.devices).filter((v) => v).length > 0
                );
            }),
            isUnique: isTaken,
        },
    };

    return r;
});

const v$: Ref<Validation<ValidationArgs<ExtensionFormState>>> = useVuelidate(rules, formState);

const emit = __emit;
watch(
    formState,
    () => {
        emit('update:modelValue', formState);
    },
    {
        deep: true,
    },
);

const __returned__ = { t, props, noDevice, formState, isTaken, rules, v$, emit }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})