<script lang="ts" setup>
import Typebot from '@typebot.io/js/dist/web';
import { computed, onMounted, onUnmounted, PropType, watch } from 'vue';
import { CallModel, CallTranscriptModel } from '@/types';
import { useI18n } from 'vue-i18n';

const { call } = defineProps({
    call: {
        type: Object as PropType<CallModel>,
        required: true,
    },
});

const { t } = useI18n();

const base64Timeline = computed(() => {
    const transcripts: string[] = [];

    call.transcripts?.forEach((callTranscript: CallTranscriptModel) => {
        const participant = t(`call.view.participant.${callTranscript.participant.toLowerCase()}`);
        transcripts.push(`${participant}: ${callTranscript.transcript}`);
    });

    return btoa(transcripts.join('\n'));
});

function startChat() {
    Typebot.initBubble({
        typebot: 'bot-onni-plugin-mobicall',
        apiHost: 'https://bot2.plataformareach.com.br',
        previewMessage: {
            message: 'Você tem dúvidas ou precisa de ajuda? Pergunte agora!',
            autoShowDelay: 2500,
            avatarUrl: 'https://api2.mobcall.com/video/api/2/a/meeting/favicon-32.png',
        },
        prefilledVariables: {
            Transcripts: base64Timeline.value,
        },
        theme: {
            button: { backgroundColor: '#5ac2ad', iconColor: '#FFFFFF' },
            chatWindow: { backgroundColor: '#ffffff' },
        },
    });
}

onMounted(() => {
    if (base64Timeline.value.length > 0) {
        startChat();
    }
});
watch(
    () => base64Timeline.value,
    () => {
        if (base64Timeline.value.length > 0) {
            startChat();
        }
    },
);
onUnmounted(() => {
    Typebot.unmount();
});
</script>
<template>
    <p class="hidden">Chatbot</p>
</template>
