<script lang="ts" setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { UserGroupIcon } from '@heroicons/vue/solid';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import SpamNumberService from '@/services/api/SpamNumberService';
import { toastServiceError } from '@/services/core/notification';

import {
    SortMenuItem,
    SpamNumberModel,
    SpamNumberServiceGetAllParams,
    Permission,
    SpamNumberActions,
    SpamNumberGetters,
} from '@/types';
import { SpamNumberListItem, SpamNumberListSort } from '@/views/pages/spam-number/components';

const route = useRoute();
const router = useRouter();
const filterStore = useFilterStore();
const uiStore = useUiStore();

const spamNumbers = ref<SpamNumberModel[]>([]);
const count = ref(0);
const perPage = 30;

const search = computed({
    get: () => store.getters[SpamNumberGetters.SEARCH],
    set: (value: string) => store.dispatch(SpamNumberActions.SET_SEARCH, value),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[SpamNumberGetters.SORT],
    set: (value: SortMenuItem[]) => store.dispatch(SpamNumberActions.SET_SORT, value),
});

const levelFilter = computed(() => filterStore.level);

const currentPage = computed({
    get: () => store.getters[SpamNumberGetters.PAGE],
    set: (value: number) => store.dispatch(SpamNumberActions.SET_PAGE, value),
});

const orderBy = computed(() => sortMenuSelected.value.map((s) => s.direction + s.key).join(','));

watch([currentPage], () => {
    fetchSpamNumbers();
});

watch([levelFilter, search, orderBy], () => {
    if (currentPage.value === 1) fetchSpamNumbers();
    else currentPage.value = 1;
});

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getParams = (): SpamNumberServiceGetAllParams => ({
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    search: search.value,
});

const fetchSpamNumbers = async () => {
    setLoading(true);
    try {
        const { data, headers } = await SpamNumberService.getAll<SpamNumberModel[]>({
            params: getParams(),
        });
        spamNumbers.value = data;
        count.value = parseInt(headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveSortClick = (item: SortMenuItem) => {
    const index = sortMenuSelected.value.indexOf(item);
    if (index > -1) sortMenuSelected.value.splice(index, 1);
};

onMounted(() => {
    if (route.query.clearStateOnSetup) {
        search.value = '';
        sortMenuSelected.value = [];
        currentPage.value = 1;
        router.replace({ query: {} });
    }

    fetchSpamNumbers();
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="flex items-center text-xl font-semibold text-gray-800">
                    <UserGroupIcon class="mr-1 h-6 w-6 text-gray-500" aria-hidden="true" />
                    {{ $t('core.entity.SpamNumbers') }}
                </h1>
            </template>
            <template #actions>
                <router-link
                    v-if="$can(Permission.CREATE_SPAM_NUMBER)"
                    :to="{ name: 'CreateSpamNumber' }"
                >
                    <UiButton variant="primary" text-variant="white">
                        {{ $t('core.actions.Add') }}
                    </UiButton>
                </router-link>
            </template>
        </UiPageHeader>

        <div class="space-y-2">
            <UiPanel class="flex px-4 py-2" no-padding>
                <UiListSearch
                    v-model="search"
                    :placeholder="$t('spamNumber.filters.search')"
                    class="flex-1"
                />
                <div class="flex items-center space-x-4">
                    <SpamNumberListSort v-model="sortMenuSelected" />
                </div>
            </UiPanel>

            <UiPanel v-if="sortMenuSelected.length" class="space-x-2">
                <UiSortMenuBadge
                    v-for="selected in sortMenuSelected"
                    :key="selected.key"
                    :item="selected"
                    @remove="handleRemoveSortClick(selected)"
                />
            </UiPanel>

            <div
                v-if="spamNumbers.length"
                class="grid grid-cols-1 gap-2 md:grid-cols-2 xl:grid-cols-3"
            >
                <SpamNumberListItem
                    v-for="spamNumber in spamNumbers"
                    :key="spamNumber.id"
                    :model-value="spamNumber"
                />
            </div>
            <UiPanel v-else class="flex items-center justify-center">
                {{ $t('core.messages.noRecordsFound') }}
            </UiPanel>

            <UiPagination
                v-if="spamNumbers.length"
                v-model="currentPage"
                :count="count"
                :perPage="perPage"
            />
        </div>
    </div>
</template>
