import { IntegrationModel, CallModel } from '@/types';

export interface IntegrationLogModel {
    id: number;
    integrationId: number;
    integration: IntegrationModel;
    callId: number;
    call: CallModel;
    externalId: string;
    status: string;
    observation: string;
    additionalInfo: object;
    createdAt: string;
}
