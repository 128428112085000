<script lang="ts" setup>
import { ref, Ref, computed, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import { CallService, SelectOptionsService } from '@/services';

import { FilterIcon } from '@heroicons/vue/solid';

import {
    CallActions,
    CallGetters,
    CallStatus,
    CallType,
    CallFilter,
    LevelModel,
    MultiSelectOption,
    SelectOptionsServiceGetWordsParams,
    CallParticipantType,
    SelectOptionsServiceGetUsersResult,
    SelectOptionsServiceGetUserLabelsResult,
    SelectOptionsServiceGetWordsResult,
} from '@/types';

const { t } = useI18n();
const route = useRoute();
const filterStore = useFilterStore();
const uiStore = useUiStore();

const filtersOpened = ref(false);
const selectedParticipant = ref<CallParticipantType | null>(null);
const filter: Ref<CallFilter> = ref({
    type: null,
    status: null,
    userLabel: null,
    media: null,
    numberClient: null,
    user: null,
    word: null,
});

const words: Ref<SelectOptionsServiceGetWordsResult[]> = ref([]);
const userLabelOptions: Ref<SelectOptionsServiceGetUserLabelsResult[]> = ref([]);
const agentOptions: Ref<SelectOptionsServiceGetUsersResult[]> = ref([]);
const mediaFilterOptions: Ref<MultiSelectOption[]> = ref([]);

const levelFilter = computed<LevelModel | null>(() => filterStore.level);
const storeFilters = computed<CallFilter>(() => store.getters[CallGetters.FILTER]);

const isCallTypeRoute = computed(() => {
    return Object.values(CallType)
        .map((value) => value.toLowerCase())
        .some((callTypeValue) => route.path.toLowerCase().includes(callTypeValue));
});

const isCallStatusRoute = computed(() => {
    return Object.values(CallStatus)
        .map((value) => value.toLowerCase())
        .some((callStatusValue) => route.path.toLowerCase().includes(callStatusValue));
});

const statusFilterOptions = [
    { label: t(`call.status.${CallStatus.ANSWERED}`), value: CallStatus.ANSWERED },
    { label: t(`call.status.${CallStatus.BUSY}`), value: CallStatus.BUSY },
    { label: t(`call.status.${CallStatus.NO_ANSWER}`), value: CallStatus.NO_ANSWER },
    { label: t(`call.status.${CallStatus.DITCHED}`), value: CallStatus.DITCHED },
];

const typeFilterOptions = [
    { label: t(`call.type.${CallType.INBOUND}`), value: CallType.INBOUND },
    { label: t(`call.type.${CallType.OUTBOUND}`), value: CallType.OUTBOUND },
    { label: t(`call.type.${CallType.INTERNAL}`), value: CallType.INTERNAL },
];

const callParticipantOptions = [
    { label: t('word.participant.ALL'), value: CallParticipantType.All },
    { label: t('word.participant.AGENT'), value: CallParticipantType.Agent },
    { label: t('word.participant.CLIENT'), value: CallParticipantType.Client },
];

watch(filtersOpened, () => {
    refreshLocalFilter();
});

watch([levelFilter], () => {
    store.dispatch(CallActions.CLEAR_FILTER);
    getUserLabels();
    getWords;
});

watch(
    () => filter.value.word?.callParticipant,
    (newParticipant) => {
        if (newParticipant !== undefined && newParticipant !== null) {
            getWords(newParticipant);
        }
    },
    { immediate: true },
);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const refreshLocalFilter = () => {
    filter.value.type = storeFilters.value.type;
    filter.value.status = storeFilters.value.status;
    filter.value.userLabel = storeFilters.value.userLabel;
    filter.value.media = storeFilters.value.media;
    filter.value.numberClient = storeFilters.value.numberClient;
    filter.value.user = storeFilters.value.user;
    filter.value.word = storeFilters.value.word;
};

const applyFilters = () => {
    store.dispatch(CallActions.SET_FILTER, filter.value);
};

const clearFilters = () => {
    store.dispatch(CallActions.CLEAR_FILTER);
    refreshLocalFilter();
};

const getWords = async (participant: string) => {
    const params: SelectOptionsServiceGetWordsParams = {
        call_participant: participant,
    };

    try {
        await setLoading(true);
        const response = await SelectOptionsService.getWords({ params });
        words.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const getUserLabels = async () => {
    try {
        const response = await SelectOptionsService.getUserLabels();
        userLabelOptions.value = response.data;
    } catch (error) {
        toastServiceError(error);
    }
};

const getAgents = async () => {
    try {
        const response = await SelectOptionsService.getUsers();
        agentOptions.value = response.data;
    } catch (error) {
        toastServiceError(error);
    }
};

const getMedias = async () => {
    try {
        const response = await CallService.getMedias<MultiSelectOption[]>();
        mediaFilterOptions.value = response.data;
    } catch (error) {
        toastServiceError(error);
    }
};

const getClientNumbers = async (query: string) => {
    const params = { search: query };
    const { data } = await CallService.getClientNumbers({ params });
    return data;
};

const filterCallParticipantTypes = (value: CallParticipantType | null) => {
    selectedParticipant.value = value || CallParticipantType.All;
    filter.value.word = null;
    getWords(value || CallParticipantType.All);
};

const getButtonVariant = (value: CallParticipantType | null) => {
    if (selectedParticipant.value === value) {
        if (value === CallParticipantType.Agent) return 'purple-200';
        if (value === CallParticipantType.Client) return 'blue-200';
        if (value === CallParticipantType.All) return 'gray-300';
    }
    return 'neutral';
};

const getTextVariant = (value: CallParticipantType | null) => {
    if (selectedParticipant.value === value) {
        if (value === CallParticipantType.Agent) return 'purple-700';
        if (value === CallParticipantType.Client) return 'blue-700';
        if (value === CallParticipantType.All) return 'gray-700';
    }
    return 'gray-700';
};

onMounted(() => {
    getMedias();
    getUserLabels();
    getAgents();
    getWords(CallParticipantType.All);
    filterCallParticipantTypes(CallParticipantType.All);
});
</script>

<template>
    <UiRightPanel v-model="filtersOpened" :title="$t('filter.sidebar.title')">
        <template #button>
            <UiButton
                size="md"
                variant="white"
                text-variant="gray-700"
                @click="filtersOpened = true"
            >
                <FilterIcon class="mr-0 h-5 w-5 text-gray-400 sm:mr-3" aria-hidden="true" />
                <span class="hidden sm:block">
                    {{ $t('core.actions.Filter') }}
                </span>
            </UiButton>
        </template>
        <template #default>
            <form autocomplete="off" class="flex flex-col gap-3">
                <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                    <div class="sm:col-span-4" v-if="!isCallTypeRoute">
                        <label class="block text-sm font-medium text-gray-800">
                            {{ $t('call.filters.type') }}
                        </label>
                        <UiMultiselect
                            name="filters_type"
                            v-model="filter.type"
                            :can-clear="true"
                            :options="typeFilterOptions"
                            :placeholder="t('core.options.All')"
                        />
                    </div>
                    <div class="sm:col-span-4" v-if="!isCallStatusRoute">
                        <label class="block text-sm font-medium text-gray-800">
                            {{ $t('call.filters.status') }}
                        </label>
                        <UiMultiselect
                            name="filters_status"
                            v-model="filter.status"
                            :can-clear="true"
                            :options="statusFilterOptions"
                            :placeholder="t('core.options.All')"
                        />
                    </div>

                    <div class="sm:col-span-4">
                        <label class="block text-sm font-medium text-gray-800">
                            {{ $t('call.filters.numberClient') }}
                        </label>
                        <UiMultiselect
                            name="filters_number_client"
                            v-model="filter.numberClient"
                            searchable="async"
                            :can-clear="true"
                            :minCharMessage="true"
                            :placeholder="$t('core.multiselect.typeSearch')"
                            :options="getClientNumbers"
                        />
                    </div>
                    <div class="sm:col-span-4">
                        <label class="block text-sm font-medium text-gray-800">
                            {{ $t('call.filters.nameAgent') }}
                        </label>
                        <UiMultiselect
                            name="filters_user"
                            v-model="filter.user"
                            label="name"
                            value-prop="id"
                            :can-clear="true"
                            :placeholder="$t('core.multiselect.typeSearch')"
                            :options="agentOptions"
                        >
                            <template #option-label="{ option }">
                                <div class="flex flex-col gap-0.5 py-1">
                                    <div class="flex flex-row gap-3">
                                        <span> {{ option.name }}</span>
                                        <span
                                            :class="[
                                                option.isActive
                                                    ? 'bg-success-50 text-success-700'
                                                    : 'bg-danger-100 text-danger-700',
                                                'rounded border px-2 py-0.5 text-xs',
                                            ]"
                                            >{{
                                                option.isActive
                                                    ? $t('core.options.Active')
                                                    : $t('core.options.Inactive')
                                            }}</span
                                        >
                                    </div>
                                    <div class="text-sm text-gray-600">
                                        {{ option.email }}
                                    </div>
                                </div>
                            </template>
                        </UiMultiselect>
                    </div>
                    <div class="sm:col-span-4">
                        <label class="block text-sm font-medium text-gray-800">
                            {{ $t('report.call.filters.userLabel') }}
                        </label>
                        <UiMultiselect
                            name="filter_userLabel"
                            v-model="filter.userLabel"
                            label="label"
                            value-prop="id"
                            :options="userLabelOptions"
                            :can-clear="true"
                            :placeholder="t('core.options.All')"
                        />
                    </div>
                    <div class="sm:col-span-4">
                        <label class="block text-left text-sm font-medium text-gray-800">
                            {{ $t('call.filters.words') }}
                        </label>
                        <div
                            class="mb-1 flex items-center justify-center rounded bg-gray-50 p-0 pt-1 shadow"
                        >
                            <UiButton
                                v-for="(option, index) in callParticipantOptions"
                                :key="option.value"
                                :variant="getButtonVariant(option.value)"
                                :text-variant="getTextVariant(option.value)"
                                :size="'md'"
                                :shape="'rounded'"
                                :shadow="'sm'"
                                :id="`participant-button-${index}`"
                                class="mx-0 w-full flex-grow text-center"
                                @click="filterCallParticipantTypes(option.value)"
                            >
                                {{ option.label }}
                            </UiButton>
                        </div>
                        <UiMultiselect
                            class="mb-4"
                            name="filters_word"
                            v-model="filter.word"
                            label="word"
                            value-prop="id"
                            :options="words"
                            :can-clear="true"
                            :placeholder="t('core.options.All')"
                        />
                    </div>
                </div>
                <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                    <div class="sm:col-span-4">
                        <label class="block text-sm font-medium text-gray-800">
                            {{ $t('call.filters.media') }}
                        </label>
                        <UiMultiselect
                            name="filters_media"
                            v-model="filter.media"
                            :can-clear="true"
                            :options="mediaFilterOptions"
                            :placeholder="t('core.options.All')"
                        />
                    </div>
                </div>
                <div class="mt-4 grid grid-cols-2 place-content-around gap-4 text-center">
                    <UiButton variant="white" text-variant="gray-700" @click="clearFilters()">
                        {{ $t('core.actions.Clear') }}
                    </UiButton>
                    <UiButton variant="primary" text-variant="white" @click="applyFilters()">
                        {{ $t('core.actions.Apply') }}
                    </UiButton>
                </div>
            </form>
        </template>
    </UiRightPanel>
</template>
