<script lang="ts" setup>
import { ErrorObject } from '@vuelidate/core';
import Multiselect from '@vueform/multiselect';
import { PropType, computed, ref, watch } from 'vue';
import { MultiselectMode } from '@/types';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
    type: {
        type: String,
        default: 'text',
    },
    modelValue: {
        default: null,
        type: [String, Number, Object, Boolean, null],
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: 'label',
    },
    valueProp: {
        type: String,
        default: 'value',
    },
    placeholder: {
        type: String,
        default: '',
    },
    autocomplete: {
        type: String,
        default: 'off',
    },
    multiple: {
        type: Boolean,
        default: false,
    },
    primitive: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    minCharMessage: {
        type: Boolean,
        default: false,
    },
    canClear: {
        type: Boolean,
        default: false,
    },
    canDeselect: {
        type: Boolean,
        default: false,
    },
    searchable: {
        type: String,
        validator: (value: string) => ['async', 'local', 'no'].includes(value),
        default: 'local',
    },
    options: {
        type: [Array, Object, Function] as PropType<
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            any[] | object | ((query: string) => Promise<any[]>)
        >,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: [],
    },
    noResultsText: {
        type: String,
        default: '',
    },
    noOptionsText: {
        type: String,
        default: '',
    },
    errors: {
        type: Array as PropType<ErrorObject[]>,
        default: () => [],
    },
});

const searchQuery = ref('');

const selectedOption = computed({
    get() {
        if (props.primitive) {
            if (props.multiple) {
                return props.options.filter((option) => {
                    return props.modelValue.includes(option[props.valueProp]);
                });
            } else {
                return props.options.find((option) => {
                    return option[props.valueProp] === props.modelValue;
                });
            }
        }
        return props.modelValue;
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    set() {},
});

const multiselectMode = computed(() => {
    if (props.multiple) {
        return MultiselectMode.MULTIPLE;
    }
    return MultiselectMode.SINGLE;
});

const emit = defineEmits(['update:modelValue']);
function handleInput(event: ProxyConstructor) {
    //Não achei uma solução boa para converter o Proxy para um objeto
    const data = JSON.parse(JSON.stringify(event));

    if (data === null) {
        emit('update:modelValue', data);
        return;
    }

    if (props.primitive) {
        if (props.multiple) {
            const mapData = data.map((i) => i[props.valueProp]);
            emit('update:modelValue', mapData);
        } else {
            emit('update:modelValue', data[props.valueProp]);
        }
    } else {
        emit('update:modelValue', data);
    }
}

const getContainerClass = computed(() =>
    !props.errors.length
        ? 'ring-gray-200 focus:ring-primary'
        : 'border-danger focus:border-danger-800',
);

const computedNoResultsText = computed(() => {
    if (searchQuery.value !== null && searchQuery.value.length < 4 && props.minCharMessage) {
        return t('core.multiselect.minChar');
    }
    return props.noResultsText || t('core.multiselect.noResults');
});

const computedNoOptionsText = computed(() => {
    return props.noOptionsText || t('core.multiselect.noOptions');
});

watch(
    () => props.modelValue,
    (newValue) => {
        searchQuery.value = newValue;
    },
);
</script>

<template>
    <div class="relative">
        <Multiselect
            :classes="{
                container: `multiselect ${getContainerClass}`,
            }"
            :id="props.name"
            :name="props.name"
            :object="true"
            :options="props.options"
            :value="selectedOption"
            :mode="multiselectMode"
            :label="props.label"
            :valueProp="props.valueProp"
            :placeholder="props.placeholder"
            :close-on-select="multiselectMode === MultiselectMode.SINGLE"
            :can-clear="props?.canClear"
            :can-deselect="props?.canDeselect"
            :searchable="props.searchable !== 'no'"
            :minChars="4"
            :resolveOnLoad="false"
            :delay="props.searchable === 'async' ? 300 : -1"
            :disabled="disabled"
            :noResultsText="computedNoResultsText"
            :noOptionsText="computedNoOptionsText"
            :class="[
                errors.length && 'border-danger',
                disabled && 'bg-gray-100 text-gray-500 ring-gray-200',
                'py-1.5 shadow ring-1',
            ]"
            @input="handleInput"
            @search-change="searchQuery = $event"
        >
            <template #singlelabel="{ value }">
                <div class="multiselect-single-label">
                    <slot name="selected-label" :value="value">
                        {{ value[props.label] }}
                    </slot>
                </div>
            </template>

            <template #multiplelabel="{ values }">
                <div class="multiselect-multiple-label gap-1.5">
                    <span
                        v-for="item in values"
                        :key="`${props.name}${item.value}${item.value}`"
                        class="rounded border border-gray-200 p-0.5 px-1.5"
                    >
                        {{ item[props.valueProp] }} - {{ item[props.label] }}
                    </span>
                </div>
            </template>

            <template #option="{ option }">
                <slot name="option-label" :option="option">
                    {{ option[props.label] }}
                </slot>
            </template>

            <template #tag="{ option, handleTagRemove, disabled }">
                <div
                    class="mb-1 mr-1 flex items-center whitespace-nowrap rounded bg-gray-400 py-0.5 pl-2 text-sm font-semibold text-white rtl:ml-1 rtl:mr-0 rtl:pl-0 rtl:pr-2"
                    :class="{
                        'is-disabled': disabled,
                    }"
                >
                    {{ option[props.label] }}
                    <span
                        v-if="!disabled"
                        class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                        <span class="multiselect-tag-remove-icon"></span>
                    </span>
                </div>
            </template>
        </Multiselect>

        <div
            class="text-danger bg-danger-100 absolute z-10 w-full px-2 py-2 text-sm shadow"
            v-if="errors.length"
        >
            <p v-for="error of errors" :key="error.$uid">{{ error.$message }}</p>
        </div>
    </div>
</template>
