import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, ComputedRef, onMounted } from 'vue';
import moment from 'moment';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import CallReportService from '@/services/api/CallReportService';
import { formatSecToTime } from '@/utils/datetime';

import {
    CallReportActions,
    CallReportGetters,
    CallStatus,
    CallType,
    CallReportFilter,
    CallReportServiceGetInfoParams,
    CallsStats,
    DataItem,
    FilterItem,
    LevelModel,
} from '@/types';

import { CallReportFilters } from '@/views/pages/reports/call/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'CallReportPage',
  setup(__props, { expose: __expose }) {
  __expose();

const callsStats: Ref<CallsStats | null> = ref(null);

const levelFilter = computed<LevelModel | null>(() => filterStore.level);
const dateRange = computed<Array<Date>>({
    get: () => filterStore.dateRange,
    set: (dateRange: Array<Date>) => filterStore.setDateRange(dateRange),
});
const filter = computed<CallReportFilter>(() => store.getters[CallReportGetters.FILTER]);
const activeFilters: ComputedRef<FilterItem[]> = computed(
    () => store.getters[CallReportGetters.ACTIVE_FILTERS],
);

const filterStore = useFilterStore();
const uiStore = useUiStore();

watch([levelFilter, dateRange, activeFilters], () => {
    fetchReportData();
});

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchReportData = async () => {
    const params: CallReportServiceGetInfoParams = {
        startDate: moment.utc(dateRange.value[0]).format('YYYY-MM-DD'),
        endDate: moment.utc(dateRange.value[1]).format('YYYY-MM-DD'),
        type: filter.value.type?.value || null,
        status: filter.value.status?.value || null,
        user: filter.value.user?.id || null,
        userLabel: filter.value.userLabel?.id || null,
        number: filter.value.number?.number || null,
        serviceTimeRange: filter.value.serviceTimeRange?.value || null,
    };

    try {
        setLoading(true);
        const response = await CallReportService.getInfo<CallsStats>({ params });
        callsStats.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveFilter = (filter: FilterItem) => {
    store.dispatch(CallReportActions.CLEAR_FILTER, filter.field);
};

const headerStats = computed<DataItem<string>[]>(() => {
    if (!callsStats.value) return [];

    return [
        { name: 'totalCalls', value: callsStats.value.total.toString() },
        { name: 'inboundCalls', value: callsStats.value.type[CallType.INBOUND].toString() },
        { name: 'outboundCalls', value: callsStats.value.type[CallType.OUTBOUND].toString() },
        { name: 'noAnswer', value: callsStats.value.status[CallStatus.NO_ANSWER].toString() },
        { name: 'busy', value: callsStats.value.status[CallStatus.BUSY].toString() },
        {
            name: 'averageServiceTime',
            value: moment.utc((callsStats.value.averageServiceTime || 0) * 1000).format('HH:mm:ss'),
        },
        {
            name: 'averageWaitingTime',
            value: callsStats.value.averageWaitingTime
                ? formatSecToTime(callsStats.value.averageWaitingTime, true)
                : '-',
        },
    ];
});

onMounted(() => {
    fetchReportData();
});

const __returned__ = { callsStats, levelFilter, dateRange, filter, activeFilters, filterStore, uiStore, setLoading, fetchReportData, handleRemoveFilter, headerStats, get CallReportFilters() { return CallReportFilters } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})