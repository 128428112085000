export enum SpamNumberMutations {
    SET_SEARCH = 'spamNumber_setSearch',
    SET_SORT = 'spamNumber_setSort',
    SET_PAGE = 'spamNumber_setPage',
}

export enum SpamNumberActions {
    SET_SEARCH = 'spamNumber_setSearch',
    SET_SORT = 'spamNumber_setSort',
    SET_PAGE = 'spamNumber_setPage',
}

export enum SpamNumberGetters {
    SEARCH = 'spamNumber_search',
    SORT = 'spamNumber_sort',
    PAGE = 'spamNumber_page',
}
