import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue';
import { CallModel, CallTranscriptModel, CallParticipantType, CallBulletType } from '@/types';
import { PlayIcon } from '@heroicons/vue/solid';


export default /*@__PURE__*/_defineComponent({
  __name: 'CallPlayerBullets',
  props: {
    call: {
        type: Object as () => CallModel,
        required: true,
    },
    callDuration: {
        type: Number,
        required: true,
    },
    bulletType: {
        type: String as () => CallBulletType,
        required: true,
    },
    participantType: {
        type: String as () => CallParticipantType,
        required: true,
    },
},
  emits: ['playSnippet'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();



const emit = __emit;

const transcripts = computed(
    () =>
        __props.call?.transcripts?.filter(({ participant, registeredWords = [], matchedWords = [] }) => {
            return (
                participant == __props.participantType &&
                ((__props.bulletType === CallBulletType.registered && registeredWords.length) ||
                    (__props.bulletType === CallBulletType.matched && matchedWords.length))
            );
        }) || [],
);

function playSnippet(start: number, end: number) {
    emit('playSnippet', start, end);
}

function getPositionBullet(item: CallTranscriptModel) {
    return (item.startedAt / __props.callDuration) * 100 + '%';
}

function getWordsByType() {
    const wordsSet = new Set();

    transcripts.value.forEach(({ registeredWords, matchedWords }) => {
        if (__props.bulletType === CallBulletType.registered) {
            registeredWords.forEach((word) => wordsSet.add(word));
        } else if (__props.bulletType === CallBulletType.matched) {
            matchedWords.forEach((word) => wordsSet.add(word));
        }
    });

    return Array.from(wordsSet).sort((a, b) => a.localeCompare(b));
}

const variant = __props.bulletType == CallBulletType.registered ? 'blue' : 'orange';
const playColor = `text-${variant}-500`;
const bulletColor = `bg-${variant}-500/50`;

const __returned__ = { emit, transcripts, playSnippet, getPositionBullet, getWordsByType, variant, playColor, bulletColor, get PlayIcon() { return PlayIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})