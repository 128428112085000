import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "font-medium text-gray-600" }
const _hoisted_2 = { class: "w-full text-sm text-gray-500" }
const _hoisted_3 = {
  key: 0,
  class: "sm:col-span-12"
}
const _hoisted_4 = { class: "mb-2 block text-sm font-medium text-gray-800" }
const _hoisted_5 = { class: "mt-4 flex flex-col justify-end pb-1.5" }
const _hoisted_6 = { class: "mt-6 grid grid-cols-2 place-content-around gap-4 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiNode = _resolveComponent("UiNode")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_UiMultiselect = _resolveComponent("UiMultiselect")!
  const _component_UiTextArea = _resolveComponent("UiTextArea")!
  const _component_UiRightPanel = _resolveComponent("UiRightPanel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_UiNode, {
      nodeId: $props.node.id,
      title: _ctx.$t('connectFlow.node.transferToExternal.title'),
      icon: $setup.ReplyIcon,
      showActions: "",
      onEditNode: _cache[0] || (_cache[0] = ($event: any) => ($setup.showForm = true)),
      onRemoveNode: $setup.removeNode,
      class: _normalizeClass($setup.isFormCorrect ? '' : 'border-red-300 shadow-red-300')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", null, _toDisplayString($setup.nodeText), 1 /* TEXT */)
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["nodeId", "title", "icon", "class"]),
    _createVNode(_component_UiRightPanel, {
      modelValue: $setup.showForm,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.showForm) = $event)),
      title: _ctx.$t('connectFlow.node.transferToExternal.title'),
      icon: $setup.ReplyIcon
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          autocomplete: "off",
          class: "flex flex-col gap-3",
          onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.formState.numbers, (number, idx) => {
            return (_openBlock(), _createBlock($setup["TransferToExternalNumberField"], {
              key: `${$props.node.id}_number_${number.id}`,
              modelValue: $setup.formState.numbers[idx].phone,
              "onUpdate:modelValue": ($event: any) => (($setup.formState.numbers[idx].phone) = $event),
              onRemove: ($event: any) => ($setup.removeNumber(idx)),
              removable: $setup.formState.numbers.length > 1
            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "onRemove", "removable"]))
          }), 128 /* KEYED_FRAGMENT */)),
          _createVNode(_component_UiButton, {
            class: "mt-6 flex items-center text-lg",
            variant: "white",
            size: "md",
            onClick: $setup.addNumber
          }, {
            default: _withCtx(() => [
              _createVNode($setup["PlusIcon"], { class: "h-4 w-4 text-gray-500" }),
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('connectFlow.node.transferToExternal.addNumber')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          ($setup.formState.numbers.length > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('connectFlow.node.transferToExternal.type')), 1 /* TEXT */),
                _createVNode(_component_UiMultiselect, {
                  name: "audio",
                  modelValue: $setup.formState.redirectType,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formState.redirectType) = $event)),
                  options: $setup.optionsType,
                  errors: $setup.v$.redirectType.$silentErrors,
                  "can-clear": "",
                  primitive: ""
                }, null, 8 /* PROPS */, ["modelValue", "options", "errors"])
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_UiTextArea, {
              label: _ctx.$t('connectFlow.node.transferToExternal.addNumber'),
              name: "whisper",
              modelValue: $setup.formState.whisper,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formState.whisper) = $event))
            }, null, 8 /* PROPS */, ["label", "modelValue"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_UiButton, {
              variant: "white",
              "text-variant": "gray-700",
              onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.showForm = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Cancel')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_UiButton, {
              type: "submit",
              variant: "primary",
              "text-variant": "white"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Apply')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ])
        ], 32 /* NEED_HYDRATION */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "title", "icon"])
  ], 64 /* STABLE_FRAGMENT */))
}