<script lang="ts" setup>
import { ref, computed, ComputedRef } from 'vue';

import {
    CallModel,
    CallWordModel,
    CallParticipantType,
    CallPlayerGetters,
    CallPlayerMutations,
} from '@/types';

import store from '@/store';

const { call, participantType } = defineProps({
    call: {
        type: Object as () => CallModel,
        required: true,
    },
    participantType: {
        type: String as () => CallParticipantType,
        required: true,
    },
});

const showHiddenWords = ref(false);
const transcripts = call?.transcripts || [];

const callPlayerSearchQuery: ComputedRef<string> = computed(
    () => store.getters[CallPlayerGetters.SEARCH_QUERY],
);
const callPlayerSelectedWords: ComputedRef<string[]> = computed(() => {
    const selectedWordsMap = store.getters[CallPlayerGetters.SELECTED_WORDS];
    return Array.from(selectedWordsMap.get(participantType) ?? []);
});

const words: ComputedRef<CallWordModel[]> = computed(() => {
    const wordMap: Map<string, CallWordModel> = new Map();

    transcripts
        .filter(({ participant }) => participant === participantType)
        .forEach(({ words, registeredWords }) => {
            words.forEach((word) => {
                const existing = wordMap.get(word);
                if (existing) existing.counter += 1;
                else
                    wordMap.set(word, {
                        text: word,
                        counter: 1,
                        registered: registeredWords.includes(word),
                        selected: callPlayerSelectedWords.value.includes(word),
                        matched: word === callPlayerSearchQuery.value,
                    });
            });
        });

    return Array.from(wordMap.values()).sort((a, b) => {
        // Sort by registered
        if (a.registered !== b.registered) return a.registered ? -1 : 1;
        // Sort by counter
        if (a.counter !== b.counter) return b.counter - a.counter;
        // Sort by text
        return a.text.localeCompare(b.text);
    });
});

const filteredWords: ComputedRef<CallWordModel[]> = computed(() =>
    showHiddenWords.value ? words.value : words.value.slice(0, 10),
);
</script>

<template>
    <UiPanel>
        <h3
            class="mb-3 text-lg font-medium"
            :class="
                participantType == CallParticipantType.Client ? 'text-purple-500' : 'text-blue-500'
            "
        >
            {{ $t('call.view.participant.' + participantType.toLowerCase()) }}
        </h3>

        <div class="relative flex flex-col flex-wrap gap-2 pb-3 sm:flex-row">
            <UiBadge
                v-for="word in filteredWords"
                :key="`${participantType}-word-${word.text}`"
                class="cursor-pointer rounded"
                :variant="word.selected ? 'orange' : word.registered ? 'blue' : 'gray'"
                @click="
                    store.commit(CallPlayerMutations.TOGGLE_SELECTED_WORD, {
                        participant: participantType,
                        word: word.text,
                    })
                "
            >
                <template #default>{{ word.text }}</template>
                <template #number>{{ word.counter }}</template>
            </UiBadge>

            <div
                v-if="words.length > 10"
                class="absolute bottom-0 right-0 cursor-pointer text-sm text-orange-600 hover:text-orange-800"
                @click="showHiddenWords = !showHiddenWords"
            >
                <span v-if="!showHiddenWords">{{ $t('core.actions.SeeMore') }}</span>
                <span v-else>{{ $t('core.actions.SeeLess') }}</span>
            </div>
        </div>
    </UiPanel>
</template>
