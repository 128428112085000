<script lang="ts" setup>
import { computed } from 'vue';
import { useAttrs } from 'vue';

const attrs = useAttrs();

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true,
    },
    label: {
        type: String,
        required: true,
    },
});

const emit = defineEmits(['update:modelValue']);

const checkedValue = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value),
});
</script>

<template>
    <label
        :class="[
            checkedValue ? 'bg-cyan-50 text-cyan-600' : 'bg-gray-50 text-gray-400',
            'h-40 w-40 rounded shadow ring-1 ring-gray-300 hover:bg-cyan-50 hover:text-cyan-600 focus:ring-2 focus:ring-opacity-50',
            'relative flex cursor-pointer flex-col items-center',
        ]"
    >
        <slot name="icon"></slot>
        <slot name="extra-info"></slot>
        <span class="mt-4 w-10/12">
            <input
                :name="attrs.name"
                :id="attrs.name"
                type="checkbox"
                class="text-primary focus:ring-primary h-4 w-4 rounded border-gray-300"
                v-model="checkedValue"
            />
            <span class="ml-4 text-sm font-medium text-gray-800">
                {{ label }}
            </span>
        </span>
    </label>
</template>
