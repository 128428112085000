import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-4 p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-800" }
const _hoisted_3 = { class: "grid grid-cols-1 gap-4 lg:grid-cols-2" }
const _hoisted_4 = { class: "flex-none items-end lg:flex" }
const _hoisted_5 = { class: "mt-6 w-full" }
const _hoisted_6 = { class: "flex justify-end px-4 sm:px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!
  const _component_UiTextInput = _resolveComponent("UiTextInput")!
  const _component_UiCheckboxInput = _resolveComponent("UiCheckboxInput")!
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _component_UiBackButton = _resolveComponent("UiBackButton")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_UiCheckDirtyBeforeRouteLeave = _resolveComponent("UiCheckDirtyBeforeRouteLeave")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('core.entity.Integrations')) + " - " + _toDisplayString(_ctx.$t('integration.integrations.pipedrive.title')), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("form", {
      class: "space-y-4",
      autocomplete: "off",
      onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
    }, [
      _createVNode(_component_UiPanel, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_UiTextInput, {
              modelValue: $setup.v$.token.$model,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.v$.token.$model) = $event)),
              name: "token",
              type: "text",
              label: _ctx.$t('integration.integrations.pipedrive.form.token'),
              errors: $setup.v$.token.$errors,
              onBlur: $setup.v$.token.$touch
            }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_UiCheckboxInput, {
                modelValue: $setup.v$.checkActivityAsDone.$model,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.v$.checkActivityAsDone.$model) = $event)),
                name: "checkActivityAsDone",
                label: 
                                _ctx.$t('integration.integrations.pipedrive.form.checkActivityAsDone')
                            ,
                class: "mb-3 ml-0 mr-0 lg:ml-4 lg:mr-4"
              }, null, 8 /* PROPS */, ["modelValue", "label"]),
              _createVNode(_component_UiCheckboxInput, {
                modelValue: $setup.v$.associateWithDeal.$model,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.v$.associateWithDeal.$model) = $event)),
                name: "associateWithDeal",
                label: _ctx.$t('integration.integrations.pipedrive.form.associateWithDeal'),
                class: "mb-3 ml-0 lg:ml-4"
              }, null, 8 /* PROPS */, ["modelValue", "label"])
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_UiCheckboxInput, {
              modelValue: $setup.v$.isActive.$model,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.v$.isActive.$model) = $event)),
              name: "isActive",
              label: _ctx.$t('integration.integrations.pipedrive.form.isActive')
            }, null, 8 /* PROPS */, ["modelValue", "label"])
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_UiBackButton, { fallbackTo: { name: 'ListIntegration' } }),
        (_ctx.$can($setup.Permission.EDIT_INTEGRATION))
          ? (_openBlock(), _createBlock(_component_UiButton, {
              key: 0,
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              class: "ml-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Save')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ])
    ], 32 /* NEED_HYDRATION */),
    _createVNode(_component_UiCheckDirtyBeforeRouteLeave, {
      dirty: $setup.v$.$anyDirty
    }, null, 8 /* PROPS */, ["dirty"])
  ]))
}