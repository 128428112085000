import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { TagIcon } from '@heroicons/vue/solid';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import WordService from '@/services/api/WordService';
import { toast, toastServiceError } from '@/services/core/notification';

import {
    FilterItem,
    Permission,
    SortMenuItem,
    WordActions,
    WordFilter,
    WordGetters,
    WordModel,
    WordServiceGetAllParams,
} from '@/types';
import { WordListItem, WordListSort, WordListFilters } from '@/views/pages/word/components';

const perPage = 10;


export default /*@__PURE__*/_defineComponent({
  __name: 'WordPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();

const filterStore = useFilterStore();
const uiStore = useUiStore();

const words = ref<WordModel[]>([]);
const count = ref(0);
const search = computed({
    get: () => store.getters[WordGetters.SEARCH],
    set: (value: string) => store.dispatch(WordActions.SET_SEARCH, value),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[WordGetters.SORT],
    set: (value: SortMenuItem[]) => store.dispatch(WordActions.SET_SORT, value),
});

const levelFilter = computed(() => filterStore.level);

const currentPage = computed({
    get: () => store.getters[WordGetters.PAGE],
    set: (value: number) => store.dispatch(WordActions.SET_PAGE, value),
});

const filter: WordFilter = store.getters[WordGetters.FILTER];

const activeFilters = computed<FilterItem[]>(() => store.getters[WordGetters.ACTIVE_FILTERS]);

const orderBy = computed(() => sortMenuSelected.value.map((s) => s.direction + s.key).join(','));

watch([currentPage], () => fetchWords());

watch([levelFilter, search, filter, orderBy], () => {
    if (currentPage.value === 1) fetchWords();
    else currentPage.value = 1;
});

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getParams = (): WordServiceGetAllParams => ({
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    call_participant:
        filter.callParticipant !== null ? (filter.callParticipant.value as string) : undefined,
    is_active: filter.isActive !== null ? (filter.isActive.value as boolean) : undefined,
    search: search.value,
});

const fetchWords = async () => {
    setLoading(true);
    try {
        const { data, headers } = await WordService.getAll<WordModel[]>({
            params: getParams(),
        });
        words.value = data;
        count.value = parseInt(headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const setIsActive = async (item: WordModel, isActive: boolean) => {
    setLoading(true);
    try {
        const { data } = await (
            isActive
                ? (id: number) => WordService.activate(id)
                : (id: number) => WordService.inactivate(id)
        )(item.id);
        toast.success(data.message);
        await fetchWords();
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveSortClick = (item: SortMenuItem) => {
    const index = sortMenuSelected.value.indexOf(item);
    if (index > -1) sortMenuSelected.value.splice(index, 1);
};

const handleRemoveFilterClick = (item: FilterItem) =>
    store.dispatch(WordActions.CLEAR_FILTER, item);

const handleActivateClick = (item: WordModel) => setIsActive(item, true);

const handleInactivateClick = (item: WordModel) => setIsActive(item, false);

onMounted(() => {
    if (route.query.clearStateOnSetup) {
        search.value = '';
        sortMenuSelected.value = [];
        currentPage.value = 1;
        router.replace({ query: {} });
    }

    fetchWords();
});

const __returned__ = { route, router, filterStore, uiStore, words, count, perPage, search, sortMenuSelected, levelFilter, currentPage, filter, activeFilters, orderBy, setLoading, getParams, fetchWords, setIsActive, handleRemoveSortClick, handleRemoveFilterClick, handleActivateClick, handleInactivateClick, get TagIcon() { return TagIcon }, get Permission() { return Permission }, get WordListItem() { return WordListItem }, get WordListSort() { return WordListSort }, get WordListFilters() { return WordListFilters } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})