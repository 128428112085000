import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, ComputedRef, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import { IntegrationService } from '@/services';
import { toastServiceError } from '@/services/core/notification';

import {
    IntegrationLogListFilters,
    IntegrationLogListItem,
    IntegrationLogListSort,
} from '@/views/pages/integration/components';

import {
    FilterItem,
    IntegrationLogModel,
    IntegrationServiceGetLogsParams,
    LevelModel,
    IntegrationLogActions,
    IntegrationLogGetters,
    SortMenuItem,
} from '@/types';

import { LinkIcon } from '@heroicons/vue/solid';

const perPage = 10;


export default /*@__PURE__*/_defineComponent({
  __name: 'IntegrationLogPage',
  props: {
    type: {
        type: String,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();

const props = __props;

const filterStore = useFilterStore();
const uiStore = useUiStore();

const integrationId = Number(route.params.id);
const cols = [
    'callId',
    'callCreatedAt',
    'callType',
    'callStatus',
    'callSource',
    'callDestination',
    'createdAt',
    'status',
    'externalId',
    'observation',
];

const integrationLogs: Ref<IntegrationLogModel[]> = ref([]);
const count = ref(0);

const search = computed<string>({
    get: () => store.getters[IntegrationLogGetters.SEARCH],
    set: (search: string) => store.dispatch(IntegrationLogActions.SET_SEARCH, search),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[IntegrationLogGetters.SORT],
    set: (sort: SortMenuItem[]) => store.dispatch(IntegrationLogActions.SET_SORT, sort),
});

const currentPage = computed<number>({
    get: () => store.getters[IntegrationLogGetters.PAGE],
    set: (page: number) => store.dispatch(IntegrationLogActions.SET_PAGE, page),
});

const orderBy = computed<string>(() =>
    sortMenuSelected.value.map((s) => s.direction + s.key).join(','),
);

const filter = computed(() => store.getters[IntegrationLogGetters.FILTER]);
const activeFilters: ComputedRef<FilterItem[]> = computed(
    () => store.getters[IntegrationLogGetters.ACTIVE_FILTERS],
);

const levelFilter = computed<LevelModel | null>(() => filterStore.level);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getIntegrationLogs = async () => {
    const params: IntegrationServiceGetLogsParams = {
        page: currentPage.value,
        per_page: perPage,
        order_by: orderBy.value,
        search: search.value,
        status: filter.value.status?.value,
    };

    try {
        await setLoading(true);
        const response = await IntegrationService.getLogs<Array<IntegrationLogModel>>(
            integrationId,
            { params },
        );

        integrationLogs.value = response.data;
        count.value = parseInt(response.headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const handleRemoveSortClick = (item: SortMenuItem) => {
    sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);
};

const handleRemoveFilterClick = (item: FilterItem) => {
    store.dispatch(IntegrationLogActions.CLEAR_FILTER, item);
};

watch(
    [levelFilter, search, filter, orderBy],
    () => {
        if (currentPage.value === 1) getIntegrationLogs();
        else currentPage.value = 1;
    },
    { deep: true },
);

watch([currentPage], () => {
    getIntegrationLogs();
});

search.value = '';
sortMenuSelected.value = [];
currentPage.value = 1;
router.replace({ query: {} });

onMounted(getIntegrationLogs);

const __returned__ = { route, router, props, filterStore, uiStore, integrationId, perPage, cols, integrationLogs, count, search, sortMenuSelected, currentPage, orderBy, filter, activeFilters, levelFilter, setLoading, getIntegrationLogs, handleRemoveSortClick, handleRemoveFilterClick, get IntegrationLogListFilters() { return IntegrationLogListFilters }, get IntegrationLogListItem() { return IntegrationLogListItem }, get IntegrationLogListSort() { return IntegrationLogListSort }, get LinkIcon() { return LinkIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})