<script lang="ts" setup>
import { ref, Ref, computed, watch } from 'vue';
import store from '@/store';
import { useI18n } from 'vue-i18n';

import { MultiSelectOption, UserLabelGetters, UserLabelActions, UserLabelFilter } from '@/types';
import { FilterIcon } from '@heroicons/vue/solid';

const { t } = useI18n();

const filtersOpened = ref(false);

const defaultFilter = computed(() => store.getters[UserLabelGetters.DEFAULT_FILTER]);

const filter: Ref<UserLabelFilter> = ref({
    isActive: defaultFilter.value.isActive,
});

const storeFilters = computed<UserLabelFilter>(() => store.getters[UserLabelGetters.FILTER]);

const isActiveFilterOptions = [
    {
        label: t('userLabel.filters.isActive.options.active'),
        value: true,
    },
    {
        label: t('userLabel.filters.isActive.options.inactive'),
        value: false,
    },
] as MultiSelectOption[];

function applyFilters() {
    store.dispatch(UserLabelActions.SET_FILTER, filter.value);
}

function clearFilters() {
    store.dispatch(UserLabelActions.CLEAR_FILTER);
    refreshLocalFilter();
}

function refreshLocalFilter() {
    filter.value.isActive = storeFilters.value.isActive;
}

watch(filtersOpened, () => {
    refreshLocalFilter();
});
</script>

<template>
    <UiRightPanel v-model="filtersOpened" :title="$t('filter.sidebar.title')">
        <template #button>
            <UiButton
                size="md"
                variant="white"
                text-variant="gray-700"
                class="hover:bg-gray-50"
                @click="filtersOpened = true"
            >
                <FilterIcon class="mr-0 h-5 w-5 text-gray-400 sm:mr-3" aria-hidden="true" />
                <span class="hidden sm:block">{{ $t('core.actions.Filter') }}</span>
            </UiButton>
        </template>
        <template #default>
            <form autocomplete="off" class="flex flex-col gap-3">
                <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                    <div class="sm:col-span-4">
                        <label class="block text-sm font-medium text-gray-800">
                            {{ $t('userLabel.filters.isActive.label') }}
                        </label>
                        <UiMultiselect
                            name="filters_isActive"
                            v-model="filter.isActive"
                            :options="isActiveFilterOptions"
                            can-clear
                            :placeholder="t('core.options.All')"
                        />
                    </div>
                </div>
                <div class="mt-4 grid grid-cols-2 place-content-around gap-4 text-center">
                    <UiButton variant="white" text-variant="gray-700" @click="clearFilters()">
                        {{ $t('core.actions.Clear') }}
                    </UiButton>
                    <UiButton variant="primary" text-variant="white" @click="applyFilters()">
                        {{ $t('core.actions.Apply') }}
                    </UiButton>
                </div>
            </form>
        </template>
    </UiRightPanel>
</template>
