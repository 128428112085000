export interface IntegrationFormState {
    type: string;
    isActive: boolean;
}

export interface PipedriveIntegrationFormState extends IntegrationFormState {
    token: string | null;
    checkActivityAsDone: boolean;
    associateWithDeal: boolean;
}

export interface OpenAiIntegrationFormState extends IntegrationFormState {
    token: string | null;
    model: string | null;
}
