import { defineComponent as _defineComponent } from 'vue'
import { PropType, computed } from 'vue';
import { EChartsOption } from 'echarts';
import { useI18n } from 'vue-i18n';
import { CallStatus, CallStatusColorMap, CallStatusCount } from '@/types';
import { CallbackDataParams } from 'echarts/types/dist/shared';


export default /*@__PURE__*/_defineComponent({
  __name: 'CallStatusDoughnutChart',
  props: {
    title: {
        type: String,
        required: true,
    },
    data: {
        type: Object as PropType<CallStatusCount>,
        required: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const props = __props;

const callStatusOptions = computed<EChartsOption>(() => {
    const data = [
        {
            value: props.data[CallStatus.ANSWERED],
            name: t('core.options.call.status.answered'),
            itemStyle: {
                color: CallStatusColorMap[CallStatus.ANSWERED],
            },
        },
        {
            value: props.data[CallStatus.NO_ANSWER],
            name: t('core.options.call.status.no_answer'),
            itemStyle: {
                color: CallStatusColorMap[CallStatus.NO_ANSWER],
            },
        },
        {
            value: props.data[CallStatus.BUSY],
            name: t('core.options.call.status.busy'),
            itemStyle: {
                color: CallStatusColorMap[CallStatus.BUSY],
            },
        },
        {
            value: props.data[CallStatus.DITCHED],
            name: t('core.options.call.status.ditched'),
            itemStyle: {
                color: CallStatusColorMap[CallStatus.DITCHED],
            },
        },
    ];

    const eChartsOption: EChartsOption = {
        title: {
            text: props.title,
            left: '14px',
            top: '14px',
            textStyle: {
                fontSize: '16px',
                fontWeight: 'bold',
                color: '#555',
            },
        },
        tooltip: {
            trigger: 'item',
        },
        legend: {
            type: 'scroll',
            width: '90%',
            height: '100%',
            bottom: '10px',
            left: 'center',
            itemHeight: 10,
            itemWidth: 10,
            icon: 'circle',
            textStyle: {
                fontSize: '11px',
                lineHeight: 14,
                rich: {
                    a: {
                        align: 'center', // Alinha o texto da legenda horizontalmente ao meio
                        verticalAlign: 'bottom', // Alinha o texto da legenda verticalmente ao meio
                    },
                    b: {
                        align: 'center', // Alinha o valor numérico horizontalmente ao meio
                        verticalAlign: 'bottom', // Alinha o valor numérico verticalmente ao meio
                    },
                },
            },
            selectedMode: true,
            formatter: (name: string) => {
                const value = data.find((d) => d.name == name)?.value || 0;
                return '{a|' + name + '} {b|(' + value + ')}';
            },
        },
        series: [
            {
                type: 'pie',
                radius: ['20%', '55%'],
                data,
                label: {
                    show: true,
                    // textStyle: {
                    //   fontSize: "11px",
                    // },
                    formatter(param: CallbackDataParams) {
                        return param.name + ' (' + (param.percent || 0) + '%)';
                    },
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                    },
                },
            },
        ],
    };
    return eChartsOption;
});

const __returned__ = { t, props, callStatusOptions }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})