import { DefaultServiceResult, WordModel } from '@/types';

export interface WordServiceGetAllParams {
    page?: number;
    per_page?: number;
    order_by?: string;
    word?: string;
    call_participant?: string;
    is_active?: boolean;
    search?: string;
}

export interface WordServiceCreateResult extends DefaultServiceResult {
    result: WordModel;
}
