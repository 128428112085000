/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    UserState,
    UserMutations,
    UserActions,
    UserGetters,
    UserFilter,
    SortMenuItem,
    FilterItem,
} from '@/types';

const getDefaultState = () => {
    return {
        search: null,
        sort: [],
        page: 1,
        filter: {
            roles: [],
            isActive: null,
        },
    } as UserState;
};

const state: UserState = getDefaultState();

const mutations: MutationTree<UserState> = {
    [UserMutations.SET_SEARCH]: (state, search: string) => (state.search = search),
    [UserMutations.SET_SORT]: (state, sort: SortMenuItem[]) => (state.sort = sort),
    [UserMutations.SET_PAGE]: (state, page: number) => (state.page = page),
    [UserMutations.SET_FILTER](state, filter: UserFilter) {
        state.filter.roles = filter.roles;
        state.filter.isActive = filter.isActive;
    },
    [UserMutations.CLEAR_FILTER](state, item?: FilterItem) {
        switch (item?.field) {
            case 'role':
                {
                    if (state.filter.roles.length) {
                        const index = state.filter.roles.findIndex((role) => role.id == item.value);
                        if (index !== -1) {
                            state.filter.roles.splice(index, 1);
                        }
                    } else {
                        state.filter.roles = getDefaultState().filter.roles;
                    }
                }
                break;
            case 'isActive':
                state.filter.isActive = getDefaultState().filter.isActive;
                break;
            default:
                state.filter.roles = getDefaultState().filter.roles;
                state.filter.isActive = getDefaultState().filter.isActive;
        }
    },
};

const actions: ActionTree<UserState, any> = {
    [UserActions.SET_SEARCH]: ({ commit }, search: string) =>
        commit(UserMutations.SET_SEARCH, search),
    [UserActions.SET_SORT]: ({ commit }, sort: SortMenuItem[]) =>
        commit(UserMutations.SET_SORT, sort),
    [UserActions.SET_PAGE]: ({ commit }, page: number) => commit(UserMutations.SET_PAGE, page),
    [UserActions.SET_FILTER]: ({ commit }, filter: UserFilter) =>
        commit(UserMutations.SET_FILTER, filter),
    [UserActions.CLEAR_FILTER]: ({ commit }, item: FilterItem) =>
        commit(UserMutations.CLEAR_FILTER, item),
};

const getters: GetterTree<UserState, any> = {
    [UserGetters.SEARCH]: (state): string | null => state.search,
    [UserGetters.SORT]: (state): SortMenuItem[] => state.sort,
    [UserGetters.PAGE]: (state): number => state.page,
    [UserGetters.FILTER]: (state): UserFilter => state.filter,
    [UserGetters.DEFAULT_FILTER]: (): UserFilter => getDefaultState().filter,
    [UserGetters.ACTIVE_FILTERS](state): FilterItem[] {
        const filters: Array<FilterItem> = [];

        state.filter.roles.forEach((role) => {
            filters.push({
                field: 'role',
                value: role.id,
                label: role.name,
            });
        });

        if (state.filter.isActive !== null) {
            filters.push({
                field: 'isActive',
                value: state.filter.isActive.value,
                label: state.filter.isActive.label,
            });
        }

        return filters;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
