/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    WordState,
    WordFilter,
    WordMutations,
    WordActions,
    WordGetters,
    SortMenuItem,
    FilterItem,
} from '@/types';

const getDefaultState = () => {
    return {
        search: null,
        sort: [],
        page: 1,
        filter: {
            callParticipant: null,
            isActive: null,
        },
    } as WordState;
};

const state: WordState = getDefaultState();

const mutations: MutationTree<WordState> = {
    [WordMutations.SET_SEARCH]: (state, search: string) => (state.search = search),
    [WordMutations.SET_SORT]: (state, sort: SortMenuItem[]) => (state.sort = sort),
    [WordMutations.SET_PAGE]: (state, page: number) => (state.page = page),
    [WordMutations.SET_FILTER](state, payload: WordFilter) {
        state.filter.callParticipant = payload.callParticipant;
        state.filter.isActive = payload.isActive;
    },
    [WordMutations.CLEAR_FILTER](state, item?: FilterItem) {
        switch (item?.field) {
            case 'callParticipant':
                state.filter.callParticipant = getDefaultState().filter.callParticipant;
                break;
            case 'isActive':
                state.filter.isActive = getDefaultState().filter.isActive;
                break;
            default:
                state.filter.callParticipant = getDefaultState().filter.callParticipant;
                state.filter.isActive = getDefaultState().filter.isActive;
        }
    },
};

const actions: ActionTree<WordState, any> = {
    [WordActions.SET_SEARCH]: ({ commit }, search: string) =>
        commit(WordMutations.SET_SEARCH, search),
    [WordActions.SET_SORT]: ({ commit }, sort: SortMenuItem[]) =>
        commit(WordMutations.SET_SORT, sort),
    [WordActions.SET_PAGE]: ({ commit }, page: number) => commit(WordMutations.SET_PAGE, page),
    [WordActions.SET_FILTER]({ commit }, payload: WordFilter) {
        commit(WordMutations.SET_FILTER, payload);
    },
    [WordActions.CLEAR_FILTER]({ commit }, item?: FilterItem) {
        commit(WordMutations.CLEAR_FILTER, item);
    },
};

const getters: GetterTree<WordState, any> = {
    [WordGetters.SEARCH]: (state): string | null => state.search,
    [WordGetters.SORT]: (state): SortMenuItem[] => state.sort,
    [WordGetters.PAGE]: (state): number => state.page,
    [WordGetters.DEFAULT_FILTER]: (): WordFilter => getDefaultState().filter,
    [WordGetters.FILTER]: (state): WordFilter => state.filter,
    [WordGetters.ACTIVE_FILTERS](state): FilterItem[] {
        const filters: Array<FilterItem> = [];

        if (state.filter.callParticipant !== null) {
            filters.push({
                field: 'callParticipant',
                value: state.filter.callParticipant.value,
                label: state.filter.callParticipant.label,
            });
        }

        if (state.filter.isActive !== null) {
            filters.push({
                field: 'isActive',
                value: state.filter.isActive.value,
                label: state.filter.isActive.label,
            });
        }

        return filters;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
