export interface LoginFormState {
    email: string;
    password: string;
    rememberMe: boolean;
}

export interface UpdatePasswordFormState {
    password: string;
    confirmPassword: string;
}
