import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid w-full grid-cols-1 sm:grid-cols-2" }
const _hoisted_2 = { class: "bg-primary relative hidden w-full items-center justify-center sm:flex" }
const _hoisted_3 = ["alt"]
const _hoisted_4 = { class: "flex w-full flex-col items-center bg-gray-50 px-8 pt-20 sm:items-start sm:justify-center sm:pt-0 lg:px-28 xl:px-40" }
const _hoisted_5 = ["alt"]
const _hoisted_6 = { class: "mb-8 text-2xl font-semibold text-gray-700" }
const _hoisted_7 = { class: "mb-4 text-sm" }
const _hoisted_8 = { class: "mb-10 list-decimal pl-6 text-sm" }
const _hoisted_9 = { class: "block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiTextInput = _resolveComponent("UiTextInput")!
  const _component_UiButton = _resolveComponent("UiButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: $setup.imageLoginPath,
        class: "h-[150px] xl:h-[250px]",
        alt: _ctx.$t('authentication.logoAlt')
      }, null, 8 /* PROPS */, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("img", {
        class: "mb-8 w-[225px]",
        src: $setup.logoPath,
        alt: _ctx.$t('authentication.logoAlt')
      }, null, 8 /* PROPS */, _hoisted_5),
      _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('authentication.updatePassword.title')), 1 /* TEXT */),
      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('authentication.updatePassword.description')), 1 /* TEXT */),
      _createElementVNode("ul", _hoisted_8, [
        _createElementVNode("li", null, _toDisplayString(_ctx.$t('authentication.updatePassword.rule.size')), 1 /* TEXT */),
        _createElementVNode("li", null, _toDisplayString(_ctx.$t('authentication.updatePassword.rule.upperCase')), 1 /* TEXT */),
        _createElementVNode("li", null, _toDisplayString(_ctx.$t('authentication.updatePassword.rule.lowerCase')), 1 /* TEXT */),
        _createElementVNode("li", null, _toDisplayString(_ctx.$t('authentication.updatePassword.rule.number')), 1 /* TEXT */),
        _createElementVNode("li", null, _toDisplayString(_ctx.$t('authentication.updatePassword.rule.special')), 1 /* TEXT */)
      ]),
      _createElementVNode("form", {
        class: "w-full space-y-4",
        onSubmit: _withModifiers($setup.handlePasswordUpdate, ["prevent"])
      }, [
        _createVNode(_component_UiTextInput, {
          modelValue: $setup.formState.password,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formState.password) = $event)),
          name: "password",
          type: "password",
          label: _ctx.$t('authentication.updatePassword.label.password'),
          inputClass: "text-base px-4 py-3",
          autocomplete: "password",
          errors: $setup.v$.password?.$errors,
          onBlur: $setup.v$.password?.$touch
        }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"]),
        _createVNode(_component_UiTextInput, {
          modelValue: $setup.formState.confirmPassword,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formState.confirmPassword) = $event)),
          name: "confirmPassword",
          type: "password",
          label: _ctx.$t('authentication.updatePassword.label.confirmPassword'),
          inputClass: "text-base px-4 py-3",
          autocomplete: "off",
          errors: $setup.v$.confirmPassword?.$errors,
          onBlur: $setup.v$.confirmPassword?.$touch
        }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"]),
        _createElementVNode("div", null, [
          _createVNode(_component_UiButton, {
            type: "submit",
            variant: "primary",
            class: "w-full"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('login.form.signIn')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ])
      ], 32 /* NEED_HYDRATION */)
    ])
  ]))
}