<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useRoute, RouterLink } from 'vue-router';
import { AxiosResponse } from 'axios';

import { useUiStore } from '@/stores';
import ConnectFlowService from '@/services/api/ConnectFlowService';
import { toastServiceError } from '@/services/core/notification';
import { ConnectFlowModel, Permission } from '@/types';
import { applyPhoneMask } from '@/utils/number';

import { PhoneIcon } from '@heroicons/vue/solid';

const route = useRoute();
const uiStore = useUiStore();

const connectFlow = ref<ConnectFlowModel | undefined>(undefined);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchConnectFlow = async (id: number) => {
    await setLoading(true);
    try {
        const response: AxiosResponse<ConnectFlowModel> =
            await ConnectFlowService.get<ConnectFlowModel>(id);
        connectFlow.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

onMounted(() => {
    const id = Number(route.params.id);
    fetchConnectFlow(id);
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('connectFlow.view.title') }}
                </h1>
            </template>
            <template #actions>
                <div class="flex space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ListConnectFlow' }" />
                    <router-link
                        v-if="$can(Permission.EDIT_CONNECT_FLOW)"
                        :to="{ name: 'EditConnectFlowById', params: { id: route.params.id } }"
                        class="block hover:bg-gray-50"
                    >
                        <UiButton variant="primary" text-variant="white">
                            {{ $t('core.actions.Edit') }}
                        </UiButton>
                    </router-link>
                </div>
            </template>
        </UiPageHeader>

        <div class="space-y-4">
            <UiPanel>
                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                    <div>
                        <h3 class="mb-1 font-semibold">
                            {{ $t('connectFlow.labels.name') }}
                        </h3>
                        <p>{{ connectFlow?.name }}</p>
                    </div>
                </div>
                <UiAuditStatusInfo
                    :model-value="connectFlow"
                    class="bt-1 mt-4 border-t border-gray-300 pt-2"
                />
            </UiPanel>

            <UiPanel>
                <h3 class="mb-4 text-base font-semibold text-gray-800">
                    {{ $t('connectFlow.view.section.numbers.title') }}
                </h3>
                <div class="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
                    <UiPanel
                        v-for="number in connectFlow?.numbers"
                        :key="number.id"
                        class="flex items-center bg-white"
                    >
                        <PhoneIcon
                            class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                        />
                        {{ applyPhoneMask(number.number) }}
                    </UiPanel>
                </div>
            </UiPanel>
        </div>
    </div>
</template>
