import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, onMounted, ComputedRef } from 'vue';
import moment from 'moment';
import store from '@/store';
import { useI18n } from 'vue-i18n';

import { useUiStore, useFilterStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import { QueueTimeReportService } from '@/services';
import { formatSecToTime } from '@/utils/datetime';

import {
    ChartDataItem,
    ContactCenterPerDayReportActions,
    ContactCenterPerDayReportFilter,
    ContactCenterPerDayReportGetters,
    DataItem,
    FilterItem,
    LevelModel,
    QueueTimeReportServiceGetInfoParams,
    QueueTimeReportServiceGetInfoResult,
} from '@/types';

// State

export default /*@__PURE__*/_defineComponent({
  __name: 'QueueTimeReportPage',
  setup(__props, { expose: __expose }) {
  __expose();

// Imports
const reportInfo: Ref<QueueTimeReportServiceGetInfoResult | null> = ref(null);

// Computed
const headerStats = computed<DataItem<string>[]>(() => {
    const stats = reportInfo.value?.stats;
    if (!stats) return [];

    return [
        { name: 'totalCalls', value: stats.totalCalls.toString() },
        { name: 'inboundCalls', value: stats.inboundCalls.toString() },
        { name: 'costumersContacted', value: stats.costumersContacted.toString() },
        { name: 'nonTalkPercentage', value: stats.nonTalkPercentage + '%' },
        { name: 'tma', value: stats.tma },
        { name: 'averageTimeQueue', value: stats.averageTimeQueue },
    ];
});

const chartDataAverageTimePerDay = computed(() => {
    const category: string[] = [];
    const series: ChartDataItem<number[]>[] = [
        {
            value: [],
            name: t('report.queueTime.averageTimePerDay.averageTime'),
            color: '#BFDBFE',
        },
    ];

    if (!reportInfo.value?.queuePerDay) return { category, series };

    for (const day of reportInfo.value.queuePerDay) {
        category.push(day.date);
        series[0].value.push(day.averageDuration);
    }

    return { category, series };
});

const chartDataTimeRangeByDay = computed(() => {
    const category: string[] = [];
    const series: ChartDataItem<number[]>[] = [];

    if (!reportInfo.value?.queuePerDay) return { category, series };

    for (const day of reportInfo.value.queuePerDay) {
        category.push(day.date);
        day.ranges.forEach((_, y) => {
            if (!series[y]) {
                series[y] = { value: [], name: '', color: '' };
            }
            series[y].value.push(day.timeRange[y]);
            series[y].name = t(`report.queueTime.range.${day.ranges[y]}`);
            series[y].color = day.rangeColor[y];
        });
    }

    return { category, series };
});

const chartDataAverageTimePerWeekDay = computed(() => {
    const category: string[] = [];
    const series: ChartDataItem<number[]>[] = [
        {
            value: [],
            name: t('report.queueTime.averageTimePerWeekDay.averageTime'),
            color: '#BFDBFE',
        },
    ];

    if (!reportInfo.value?.queuePerWeekDay) return { category, series };

    for (const day of reportInfo.value.queuePerWeekDay) {
        category.push(t(`core.days.${day.day}`));
        series[0].value.push(day.averageDuration);
    }

    return { category, series };
});

const chartDataTimeRangeByWeekDay = computed(() => {
    const category: string[] = [];
    const series: ChartDataItem<number[]>[] = [];

    if (!reportInfo.value?.queuePerWeekDay) return { category, series };

    for (const day of reportInfo.value.queuePerWeekDay) {
        category.push(t(`core.days.${day.day}`));
        day.ranges.forEach((_, y) => {
            if (!series[y]) {
                series[y] = { value: [], name: '', color: '' };
            }
            series[y].value.push(day.timeRange[y]);
            series[y].name = t(`report.queueTime.range.${day.ranges[y]}`);
            series[y].color = day.rangeColor[y];
        });
    }

    return { category, series };
});

const chartDataAverageTimePerHourOfDay = computed(() => {
    const category: string[] = Array.from({ length: 24 }, (_, h) => h.toString().padStart(2, '0'));
    const series: ChartDataItem<number[]>[] = [
        {
            value: reportInfo.value?.queuePerHour || [],
            name: t('report.queueTime.averageTimeByHourOfDay.averageTime'),
            color: '#BFDBFE',
        },
    ];

    return { category, series };
});

const chartDataTimeRangePerHourDay = computed(() => {
    const category: string[] = Array.from({ length: 24 }, (_, h) => h.toString().padStart(2, '0'));
    const series: ChartDataItem<number[]>[] = [];

    const queueData = reportInfo.value?.queueRangePorHourOfDay;
    if (!queueData) return { category, series };

    queueData.ranges.forEach((range, i) => {
        series[i] = {
            value: queueData.data[i],
            name: t(`report.queueTime.range.${range}`),
            color: queueData.rangeColor[i],
        };
    });

    return { category, series };
});

const axisLabelDateFormatter = (value: number | string) => moment.utc(value).format('DD/MM');
const axisLabelSecToTimeFormatter = (value: number) => formatSecToTime(value);

// Composables
const uiStore = useUiStore();
const filterStore = useFilterStore();
const { t } = useI18n();

// Computed from store
const levelFilter = computed<LevelModel | null>(() => filterStore.level);
const dateRange = computed<Array<Date>>({
    get: () => filterStore.dateRange,
    set: (dateRange) => filterStore.setDateRange(dateRange),
});
const filter = computed<ContactCenterPerDayReportFilter>(
    () => store.getters[ContactCenterPerDayReportGetters.FILTER],
);
const activeFilters: ComputedRef<FilterItem[]> = computed(
    () => store.getters[ContactCenterPerDayReportGetters.ACTIVE_FILTERS],
);

// Methods
const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchReportData = async () => {
    const params: QueueTimeReportServiceGetInfoParams = {
        startDate: moment.utc(dateRange.value[0]).format('YYYY-MM-DD'),
        endDate: moment.utc(dateRange.value[1]).format('YYYY-MM-DD'),
        type: filter.value.type?.value || null,
        status: filter.value.status?.value || null,
        user: filter.value.user?.id || null,
        number: filter.value.number?.number || null,
        serviceTimeRange: filter.value.serviceTimeRange?.value || null,
        userLabel: filter.value.userLabel?.id || null,
    };

    try {
        setLoading(true);
        const response = await QueueTimeReportService.getInfo({ params });
        reportInfo.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveFilter = (filter: FilterItem) => {
    store.dispatch(ContactCenterPerDayReportActions.CLEAR_FILTER, filter.field);
};

// Watchers
watch([levelFilter, dateRange, activeFilters], () => {
    fetchReportData();
});

// Lifecycle
onMounted(() => {
    fetchReportData();
});

const __returned__ = { reportInfo, headerStats, chartDataAverageTimePerDay, chartDataTimeRangeByDay, chartDataAverageTimePerWeekDay, chartDataTimeRangeByWeekDay, chartDataAverageTimePerHourOfDay, chartDataTimeRangePerHourDay, axisLabelDateFormatter, axisLabelSecToTimeFormatter, uiStore, filterStore, t, levelFilter, dateRange, filter, activeFilters, setLoading, fetchReportData, handleRemoveFilter }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})