import { UserLabelModel, UserModel } from '@/types';

export interface BillingReportFilter {
    dateRange?: [Date, Date] | null;
    user?: UserModel | null;
    userLabel?: UserLabelModel | null;
    sourceNumber?: string | null;
    destinationNumber?: string | null;
    status?: string | null;
    type?: string | null;
}

export interface BillingReportState {
    filter: BillingReportFilter;
}

export enum BillingReportMutations {
    SET_FILTER = 'billingReport_setFilter',
    CLEAR_FILTER = 'billingReport_clearFilter',
}

export enum BillingReportActions {
    SET_FILTER = 'billingReport_setFilter',
    CLEAR_FILTER = 'billingReport_clearFilter',
}

export enum BillingReportGetters {
    DEFAULT_FILTER = 'billingReport_defaultFilter',
    FILTER = 'billingReport_filter',
    ACTIVE_FILTERS = 'billingReport_activeFilters',
}
