import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["BaseTemplate"], {
    "navigation-items": $setup.sideNavigationItems,
    "router-key": _ctx.$route.fullPath
  }, {
    headerActions: _withCtx(() => [
      _createElementVNode("button", {
        class: "mr-2 hover:text-white",
        onClick: $setup.handleConfigClick
      }, [
        _createVNode($setup["CogIcon"], {
          class: "h-6 w-6",
          "aria-hidden": "true"
        })
      ]),
      ($setup.showLogout)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "hover:text-white",
            onClick: $setup.handleLogoutClick
          }, [
            _createVNode($setup["LogoutIcon"], {
              class: "h-6 w-6",
              "aria-hidden": "true"
            })
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["navigation-items", "router-key"]))
}