<script lang="ts" setup>
import { PropType } from 'vue';
import { SpamNumberModel, Permission } from '@/types';
import { applyPhoneMask } from '@/utils/number';
import { PencilAltIcon } from '@heroicons/vue/outline';

const { modelValue } = defineProps({
    modelValue: {
        type: Object as PropType<SpamNumberModel>,
        required: true,
    },
});
</script>

<template>
    <UiPanel class="space-y-2 hover:bg-white">
        <div class="flex items-center justify-between">
            <div class="flex-1">
                <component
                    :is="$can(Permission.VIEW_SPAM_NUMBER) ? 'RouterLink' : 'span'"
                    :to="{
                        name: 'ViewSpamNumberById',
                        params: {
                            id: modelValue.id,
                        },
                    }"
                    class="text-md text-primary truncate font-medium"
                >
                    {{ applyPhoneMask(modelValue.number) }}
                </component>
            </div>
            <div v-if="$can(Permission.EDIT_SPAM_NUMBER)" class="flex space-x-1">
                <UiActionButton
                    v-tippy="$t('core.actions.Edit')"
                    variant="primary"
                    :to="{
                        name: 'EditSpamNumberById',
                        params: {
                            id: modelValue.id,
                        },
                    }"
                >
                    <span class="sr-only"> {{ $t('core.actions.Edit') }} </span>
                    <PencilAltIcon class="h-6 w-6" aria-hidden="true" />
                </UiActionButton>
            </div>
        </div>
        <UiAuditInfo :model-value="modelValue" class="justify-end" />
    </UiPanel>
</template>
