import { ListFilters, MultiSelectOption } from '@/types';

export interface LevelFilter {
    isActive: MultiSelectOption | null;
}

export interface LevelState extends ListFilters {
    filter: LevelFilter;
}

export enum LevelMutations {
    SET_SEARCH = 'level_mutation_setSearch',
    SET_SORT = 'level_mutation_setSort',
    SET_PAGE = 'level_mutation_setPage',
    SET_FILTER = 'level_mutation_setFilter',
    CLEAR_FILTER = 'level_mutation_clearFilter',
}

export enum LevelActions {
    SET_SEARCH = 'level_action_setSearch',
    SET_SORT = 'level_action_setSort',
    SET_PAGE = 'level_action_setPage',
    SET_FILTER = 'level_action_setFilter',
    CLEAR_FILTER = 'level_action_clearFilter',
}

export enum LevelGetters {
    SEARCH = 'level_getter_search',
    SORT = 'level_getter_sort',
    PAGE = 'level_getter_page',
    DEFAULT_FILTER = 'level_getter_defaultFilter',
    FILTER = 'level_getter_filter',
    ACTIVE_FILTERS = 'level_getter_activeFilters',
}
