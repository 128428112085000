import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex h-screen w-full" }
const _hoisted_2 = { class: "bg-primary flex h-full flex-col overflow-y-auto sm:w-60 md:fixed" }
const _hoisted_3 = { class: "flex flex-1 flex-col overflow-y-auto" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "w-full md:pl-60" }
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "flex w-full items-center" }
const _hoisted_8 = { class: "grid w-full grid-cols-2 gap-4 md:grid-cols-5" }
const _hoisted_9 = { class: "flex items-center pl-2 text-gray-50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_UiMultiselect = _resolveComponent("UiMultiselect")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_UiLoadingDialog = _resolveComponent("UiLoadingDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["TemplateSidebar"], null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_link, { to: { name: 'Home' } }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  class: "mb-8 ml-4 mt-3 h-12",
                  src: $setup.logoPath
                }, null, 8 /* PROPS */, _hoisted_4)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode($setup["TemplateNavigation"], { items: $props.navigationItems }, null, 8 /* PROPS */, ["items"])
          ]),
          _createVNode($setup["TemplateProfile"])
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          class: _normalizeClass([$setup.headerClass, "sticky top-0 z-30 flex items-center p-2 shadow sm:px-4 sm:py-2"])
        }, [
          _createElementVNode("header", _hoisted_7, [
            _createVNode($setup["TemplateHeader"], null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_UiMultiselect, {
                    name: "template_level",
                    label: "name",
                    "value-prop": "id",
                    modelValue: $setup.levelFilter,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.levelFilter) = $event)),
                    options: $setup.levels,
                    class: "col-span-2",
                    disabled: $setup.isLevelLocked
                  }, null, 8 /* PROPS */, ["modelValue", "options", "disabled"])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _renderSlot(_ctx.$slots, "headerActions")
                ])
              ]),
              _: 3 /* FORWARDED */
            })
          ])
        ], 2 /* CLASS */),
        _createElementVNode("main", null, [
          (_openBlock(), _createBlock(_component_router_view, { key: $props.routerKey }))
        ])
      ]),
      _createVNode(_component_UiLoadingDialog, {
        show: $setup.loading,
        text: _ctx.$t('ui-loading-dialog-text')
      }, null, 8 /* PROPS */, ["show", "text"])
    ])
  ]))
}