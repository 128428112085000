<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, Ref, watch } from 'vue';

import { useFilterStore, useUiStore } from '@/stores';
import RealtimeReportService from '@/services/api/RealtimeReportService';
import { toastServiceError } from '@/services/core/notification';
import { applyPhoneMask } from '@/utils/number';

import { UserIcon, DesktopComputerIcon } from '@heroicons/vue/solid';
import { DeviceMobileIcon } from '@heroicons/vue/outline';
import SVGDeviceExternal from '@/components/svg/SVGDeviceExternal.vue';
import SVGChromeLogo from '@/components/svg/SVGChromeLogo.vue';

import { LevelModel, UserDeviceType, UserDeviceStatus } from '@/types';
import { RealtimeReportServiceGetAgentExtensionsResult } from '@/types/services/RealtimeReportService';

const items: Ref<RealtimeReportServiceGetAgentExtensionsResult[]> = ref([]);
const loading = ref(true);

const filterStore = useFilterStore();
const uiStore = useUiStore();

const levelFilter = computed<LevelModel | null>(() => filterStore.level);

const setLoading = (value: boolean) => uiStore.setIsLoading(value);

const getData = async () => {
    try {
        loading.value = true;
        const response =
            await RealtimeReportService.getAgentExtensions<
                RealtimeReportServiceGetAgentExtensionsResult[]
            >();
        items.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        loading.value = false;
    }
};

let interval: number;

watch(levelFilter, () => {
    items.value = [];
    clearInterval(interval);
    interval = setInterval(getData, 5000);
});

onMounted(() => {
    setLoading(true);
    interval = setInterval(getData, 5000);
});

onUnmounted(() => {
    clearInterval(interval);
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader class="inline-block">
            <template #info>
                <h1 class="inline-block text-xl font-semibold text-gray-800">
                    {{ $t('report.realtimeAgentExtension.title') }}
                </h1>
                <ui-spinner v-if="loading" class="left mb-1.5 ml-2 inline-block h-5 w-5" />
            </template>
        </UiPageHeader>
        <div class="mt-4 grid grid-cols-3 gap-2 md:grid-cols-4 lg:grid-cols-6 xl:lg:grid-cols-8">
            <div
                v-for="(item, idx) in items"
                :key="`queueDetailItem${idx}`"
                :class="[
                    item.status == UserDeviceStatus.OFFLINE ? 'bg-danger-50' : 'bg-success-50',
                    'flex flex-col items-center justify-center space-y-1 rounded px-1 py-2 shadow',
                ]"
            >
                <div class="relative">
                    <UserIcon
                        class="h-12 w-12 rounded-full border border-gray-300 bg-gray-50 p-0.5 text-gray-400 shadow"
                    />
                    <DesktopComputerIcon
                        v-if="item.userDevice === UserDeviceType.DESKTOP"
                        class="absolute -right-3 top-0 h-6 w-6 text-cyan-600"
                    />
                    <DeviceMobileIcon
                        v-else-if="item.userDevice === UserDeviceType.MOBILE"
                        class="absolute -right-3 top-0 h-6 w-6 text-cyan-600"
                    />
                    <SVGChromeLogo
                        v-else-if="item.userDevice === UserDeviceType.WEB"
                        class="absolute -right-3 top-0 h-6 w-6 text-cyan-600"
                    />
                    <SVGDeviceExternal
                        v-else-if="item.userDevice === UserDeviceType.EXTERNAL"
                        class="absolute -right-3 top-0 h-6 w-6 text-cyan-600"
                    />
                </div>
                <p class="text-xs font-semibold text-gray-600">{{ item.user }}</p>
                <p class="text-xs font-semibold text-gray-600">ramal {{ item.extension }}</p>
                <p class="text-xs font-semibold text-gray-600">
                    {{ applyPhoneMask(item.numberClient) || '-' }}
                </p>
                <div class="grid grid-cols-1 gap-1 pt-1 text-center text-xs">
                    <p
                        :class="[
                            item.status == UserDeviceStatus.OFFLINE
                                ? 'border-danger-800 text-danger-700'
                                : 'border-success-800 text-success-700',
                            'rounded border-2 border-opacity-15 bg-gray-50 px-1 py-0.5 font-semibold',
                        ]"
                    >
                        {{ $t(`report.realtime.device.status.${item.status}`) }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
