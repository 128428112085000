import { defineComponent as _defineComponent } from 'vue'
import { computed, PropType } from 'vue';
import moment from 'moment';
import { ScheduleModel, Permission, ScheduleWeekday } from '@/types';
import { ClockIcon, CalendarIcon } from '@heroicons/vue/solid';
import { CheckCircleIcon, XCircleIcon, PencilAltIcon } from '@heroicons/vue/outline';
import UiAuditInfo from '@/components/UiAuditInfo.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ScheduleListItem',
  props: {
    modelValue: {
        type: Object as PropType<ScheduleModel>,
        required: true,
    },
},
  emits: ['activate', 'inactivate'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const weekdays: ScheduleWeekday[] = [
    ScheduleWeekday.SUNDAY,
    ScheduleWeekday.MONDAY,
    ScheduleWeekday.TUESDAY,
    ScheduleWeekday.WEDNESDAY,
    ScheduleWeekday.THURSDAY,
    ScheduleWeekday.FRIDAY,
    ScheduleWeekday.SATURDAY,
];

const activeDays = computed(() => {
    return weekdays.filter((day: ScheduleWeekday) => props.modelValue[day]);
});

// Format day label using moment (translated by app locale)
const formatDayLabel = (day: ScheduleWeekday) => {
    return moment().day(weekdays.indexOf(day)).format('dddd');
};

const emit = __emit;

const handleActivateClick = () => emit('activate', props.modelValue);
const handleInactivateClick = () => emit('inactivate', props.modelValue);

const __returned__ = { props, weekdays, activeDays, formatDayLabel, emit, handleActivateClick, handleInactivateClick, get Permission() { return Permission }, get ClockIcon() { return ClockIcon }, get CalendarIcon() { return CalendarIcon }, get CheckCircleIcon() { return CheckCircleIcon }, get XCircleIcon() { return XCircleIcon }, get PencilAltIcon() { return PencilAltIcon }, UiAuditInfo }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})