/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    ConnectFlowAudioState,
    ConnectFlowAudioMutations,
    ConnectFlowAudioActions,
    ConnectFlowAudioGetters,
    ConnectFlowAudioFilter,
    SortMenuItem,
    FilterItem,
} from '@/types';

const getDefaultState = () => {
    return {
        search: null,
        sort: [],
        page: 1,
        filter: {
            isActive: null,
        },
    } as ConnectFlowAudioState;
};

const state: ConnectFlowAudioState = getDefaultState();

const mutations: MutationTree<ConnectFlowAudioState> = {
    [ConnectFlowAudioMutations.SET_SEARCH]: (state, search: string) => (state.search = search),
    [ConnectFlowAudioMutations.SET_SORT]: (state, sort: SortMenuItem[]) => (state.sort = sort),
    [ConnectFlowAudioMutations.SET_PAGE]: (state, page: number) => (state.page = page),
    [ConnectFlowAudioMutations.SET_FILTER](state, filter: ConnectFlowAudioFilter) {
        state.filter.isActive = filter.isActive;
    },
    [ConnectFlowAudioMutations.CLEAR_FILTER](state, item?: FilterItem) {
        switch (item?.field) {
            case 'isActive':
                state.filter.isActive = getDefaultState().filter.isActive;
                break;
            default:
                state.filter.isActive = getDefaultState().filter.isActive;
        }
    },
};

const actions: ActionTree<ConnectFlowAudioState, any> = {
    [ConnectFlowAudioActions.SET_SEARCH]: ({ commit }, search: string) =>
        commit(ConnectFlowAudioMutations.SET_SEARCH, search),
    [ConnectFlowAudioActions.SET_SORT]: ({ commit }, sort: SortMenuItem[]) =>
        commit(ConnectFlowAudioMutations.SET_SORT, sort),
    [ConnectFlowAudioActions.SET_PAGE]: ({ commit }, page: number) =>
        commit(ConnectFlowAudioMutations.SET_PAGE, page),
    [ConnectFlowAudioActions.SET_FILTER]: ({ commit }, filter: ConnectFlowAudioFilter) =>
        commit(ConnectFlowAudioMutations.SET_FILTER, filter),
    [ConnectFlowAudioActions.CLEAR_FILTER]: ({ commit }, item: FilterItem) =>
        commit(ConnectFlowAudioMutations.CLEAR_FILTER, item),
};

const getters: GetterTree<ConnectFlowAudioState, any> = {
    [ConnectFlowAudioGetters.SEARCH]: (state): string | null => state.search,
    [ConnectFlowAudioGetters.SORT]: (state): SortMenuItem[] => state.sort,
    [ConnectFlowAudioGetters.PAGE]: (state): number => state.page,
    [ConnectFlowAudioGetters.FILTER]: (state): ConnectFlowAudioFilter => state.filter,
    [ConnectFlowAudioGetters.DEFAULT_FILTER]: (): ConnectFlowAudioFilter =>
        getDefaultState().filter,
    [ConnectFlowAudioGetters.ACTIVE_FILTERS](state): FilterItem[] {
        const filters: Array<FilterItem> = [];

        if (state.filter.isActive !== null) {
            filters.push({
                field: 'isActive',
                value: state.filter.isActive.value,
                label: state.filter.isActive.label,
            });
        }

        return filters;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
