<script lang="ts" setup>
import { computed, reactive, ref, onMounted, type Ref } from 'vue';
import { useRoute } from 'vue-router';
import { useVuelidate, type Validation, type ValidationArgs } from '@vuelidate/core';

import { required, maxLength } from '@/utils/validators';
import { goBack } from '@/composables/useNavigation';
import { useUiStore } from '@/stores';
import { UserLabelService, UserService } from '@/services';
import { toast, toastServiceError } from '@/services/core/notification';
import {
    FormAction,
    type UserModel,
    type UserLabelModel,
    type UserLabelFormState,
    Permission,
} from '@/types';
import type { AxiosResponse } from 'axios';

const props = defineProps({
    action: { type: String, default: FormAction.CREATE },
});

const route = useRoute();

const uiStore = useUiStore();

const formState = reactive<UserLabelFormState>({
    label: null,
    users: [],
    isActive: true,
});

const rules = computed<ValidationArgs<UserLabelFormState>>(() => ({
    label: { required, maxLength: maxLength(50) },
    users: {},
    isActive: { required },
}));

const v$: Ref<Validation<ValidationArgs<UserLabelFormState>>> = useVuelidate(rules, formState);

const users: Ref<UserModel[]> = ref([]);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchUsers = async () => {
    setLoading(true);
    try {
        const { data } = await UserService.getAll<UserModel[]>({
            params: { page: 1, per_page: 100 },
        });
        users.value = data;
    } catch (e) {
        toastServiceError(e);
    } finally {
        setLoading(false);
    }
};

const fetchUserLabel = async (id: number) => {
    setLoading(true);
    try {
        const { data } = await UserLabelService.get<UserLabelModel>(id);
        formState.label = data.label;
        formState.users = data.users.map((u) => u.id);
        formState.isActive = data.isActive;
    } catch (e) {
        toastServiceError(e);
    } finally {
        setLoading(false);
    }
};

const onSubmit = async () => {
    const isFormValid = await v$.value.$validate();
    if (!isFormValid) return;

    setLoading(true);

    const handleServiceSuccess = (message: string, id: number) => {
        toast.success(message);
        v$.value.$reset();
        goBack({ name: 'ViewUserLabelById', params: { id } });
    };

    try {
        if (props.action === FormAction.EDIT) {
            const id = Number(route.params.id);
            const { data }: AxiosResponse = await UserLabelService.update(id, formState);
            handleServiceSuccess(data.message, id);
        } else {
            const { data }: AxiosResponse = await UserLabelService.create(formState);
            handleServiceSuccess(data.message, data.result.id);
        }
    } catch (e) {
        toastServiceError(e);
    } finally {
        setLoading(false);
    }
};

onMounted(async () => {
    await fetchUsers();
    if (props.action === FormAction.EDIT) {
        await fetchUserLabel(Number(route.params.id));
    }
});
</script>

<template>
    <form class="space-y-4 p-4 sm:p-6 lg:p-8" autocomplete="off" @submit.prevent="onSubmit">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('userLabel.' + props.action + '.title') }}
                </h1>
            </template>
            <template #actions>
                <div class="flex items-center space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ListUserLabel' }" />
                    <UiButton
                        v-if="
                            (props.action == FormAction.CREATE &&
                                $can(Permission.CREATE_USER_LABEL)) ||
                            (props.action == FormAction.EDIT && $can(Permission.EDIT_USER_LABEL))
                        "
                        type="submit"
                        variant="primary"
                        text-variant="white"
                    >
                        {{ $t('core.actions.Save') }}
                    </UiButton>
                </div>
            </template>
        </UiPageHeader>
        <UiPanel class="grid grid-cols-1 gap-4 md:grid-cols-3">
            <UiTextInput
                v-model="v$.label.$model"
                name="label"
                type="text"
                :label="$t('userLabel.labels.label')"
                :errors="v$.label.$errors"
                @blur="v$.label.$touch"
            />

            <div>
                <label for="users" class="mb-2 block text-sm font-medium text-gray-800">
                    {{ $t('userLabel.labels.users') }}
                </label>
                <UiMultiselect
                    id="users"
                    name="users"
                    label="name"
                    value-prop="id"
                    v-model="formState.users"
                    multiple
                    :primitive="true"
                    :options="users"
                />
            </div>

            <div class="flex flex-col justify-end pb-1.5">
                <UiCheckboxInput
                    v-model="v$.isActive.$model"
                    name="isActive"
                    :label="$t('userLabel.labels.isActive')"
                    class="h-6"
                />
            </div>
        </UiPanel>
        <UiCheckDirtyBeforeRouteLeave :dirty="v$.$anyDirty" />
    </form>
</template>
