<script lang="ts" setup>
import { computed, type HTMLAttributes, type PropType, type VNodeProps } from 'vue';
import { PencilAltIcon, TrashIcon } from '@heroicons/vue/outline';
import { Handle, Position } from '@vue-flow/core';
import { NodeToolbar } from '@vue-flow/node-toolbar';
import { PlusIcon } from '@heroicons/vue/solid';
import store from '@/store';
import { ConnectFlowModelingActions, ConnectFlowModelingGetters } from '@/types';

const props = defineProps({
    title: {
        type: String,
    },
    icon: {
        type: Function as PropType<HTMLAttributes & VNodeProps>,
    },
    text: {
        type: String,
        default: '',
    },
    nodeId: {
        type: String,
        required: true,
    },
    showActions: {
        type: Boolean,
        default: false,
    },
    noEdition: {
        type: Boolean,
        default: false,
    },
    showHandleTarget: {
        type: Boolean,
        default: true,
    },
    showHandleSource: {
        type: Boolean,
        default: true,
    },
    bgColor: {
        type: String,
    },
    borderColor: {
        type: String,
    },
    roundedStyle: {
        type: String,
    },
    padding: {
        type: String,
    },
    nodeWidth: {
        type: String,
    },
    nodeHeight: {
        type: String,
    },
    hideNodeId: {
        type: Boolean,
    },
});

const emit = defineEmits(['editNode', 'removeNode']);

const handleEditClick = () => {
    emit('editNode');
};

const handleRemoveClick = () => {
    emit('removeNode');
};

function openComponentSelector() {
    store.dispatch('flow/' + ConnectFlowModelingActions.SET_MENU, {
        show: true,
        idTriggerBy: props.nodeId,
    });
}

const node = computed(() =>
    store.getters['flow/' + ConnectFlowModelingGetters.ELEMENT_BY_ID](props.nodeId),
);
</script>
<template>
    <div class="flex flex-col items-center">
        <div
            v-bind="$attrs"
            :class="[
                bgColor ? bgColor : 'bg-gray-50',
                borderColor ? borderColor : 'border-gray-300',
                roundedStyle ? roundedStyle : 'rounded',
                padding ? padding : 'px-4 py-4',
                nodeWidth ? nodeWidth : 'w-[350px]',
                nodeHeight ? nodeHeight : 'h-auto',
                'relative border-2 shadow',
            ]"
        >
            <div class="relative flex w-full justify-end gap-2">
                <div
                    v-if="icon"
                    class="absolute -left-[45px] -top-[45px] z-50 rounded-full border border-gray-300 bg-emerald-50 p-1 shadow"
                >
                    <component :is="icon" class="text-secondary h-9 w-9" aria-hidden="true" />
                </div>
                <div class="text-md flex-1 font-medium text-gray-800" v-if="title">
                    {{ title }}
                </div>
                <template v-if="showActions">
                    <UiActionButton
                        v-if="!noEdition"
                        v-tippy="$t('core.actions.Edit')"
                        variant="primary"
                        @click="handleEditClick"
                    >
                        <PencilAltIcon class="h-5 w-5" />
                    </UiActionButton>
                    <UiActionButton
                        variant="danger"
                        v-tippy="$t('core.actions.Remove')"
                        @click="handleRemoveClick"
                    >
                        <TrashIcon class="h-5 w-5" />
                    </UiActionButton>
                </template>
            </div>
            <Handle v-if="showHandleTarget" type="target" :position="Position.Top" />
            <Handle v-if="showHandleSource" type="source" :position="Position.Bottom" />
            <div class="text-sm">
                <slot></slot>
                <div v-if="!hideNodeId" class="mt-3">
                    <span
                        class="rounded bg-gray-50 px-1.5 py-0.5 text-xs ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50"
                    >
                        #{{ node.id }}
                    </span>
                </div>
            </div>
        </div>
        <NodeToolbar
            class="flex items-center gap-0.5"
            :is-visible="node?.connectable as boolean"
            :position="Position.Bottom"
        >
            <button
                class="border-1 hover:shadow-blueGray-600 rounded-full bg-gray-50 p-1 shadow"
                @click="openComponentSelector"
            >
                <PlusIcon class="h-5 w-5" />
            </button>
        </NodeToolbar>
    </div>
</template>
