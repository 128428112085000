import { UserModel, CompanyModel } from '@/types';

export interface AuthState {
    user?: UserModel;
    company?: CompanyModel;
}

export enum AuthMutations {
    SET_USER = 'auth_setUser',
    SET_COMPANY = 'auth_setCompany',
}

export enum AuthActions {
    LOGIN = 'auth_login',
    LOGOUT = 'auth_logout',
}

export enum AuthGetters {
    USER = 'auth_user',
    USER_COMPANY = 'auth_company',
    USER_LEVELS = 'auth_user_levels',
    USER_ROLES = 'auth_user_roles',
    USER_PERMISSIONS = 'auth_user_permissions',
    CHAT_BOT_INTEGRATION = 'auth_get_chat_bot_integration',
}
