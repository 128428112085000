import { NavigationItem, Permission } from '@/types';
import {
    AnnotationIcon,
    BanIcon,
    BookOpenIcon,
    ChartBarIcon,
    ClockIcon,
    CloudUploadIcon,
    CogIcon,
    CurrencyDollarIcon,
    HomeIcon,
    LinkIcon,
    LockClosedIcon,
    MenuAlt2Icon,
    OfficeBuildingIcon,
    PhoneIcon,
    TagIcon,
    UserGroupIcon,
    UsersIcon,
    VideoCameraIcon,
} from '@heroicons/vue/outline';
import ConnectFlowIcon from '@/components/svg/ConnectFlowIcon';

const clearStateOnSetup = {
    query: {
        clearStateOnSetup: 'true',
    },
};

export const sideNavigationItems: NavigationItem[] = [
    {
        localeKey: 'sideNavigation.home',
        icon: HomeIcon,
        name: 'Home',
    },
    {
        localeKey: 'sideNavigation.call',
        icon: PhoneIcon,
        name: 'CallList',
        permission: Permission.VIEW_CALLS,
        ...clearStateOnSetup,
    },
    {
        localeKey: 'sideNavigation.report.group',
        icon: ChartBarIcon,
        isOpen: false,
        children: [
            {
                localeKey: 'sideNavigation.report.call',
                name: 'CallReport',
                permission: Permission.VIEW_CALL_REPORT,
            },
            {
                localeKey: 'sideNavigation.report.contactCenterPerDay',
                name: 'ContactCenterPerDayReport',
                permission: Permission.VIEW_CONTACT_CENTER_PER_DAY_REPORT,
            },
            {
                localeKey: 'sideNavigation.report.contactCenterPerAgent',
                name: 'ContactCenterPerAgentReport',
                permission: Permission.VIEW_CONTACT_CENTER_PER_AGENT_REPORT,
            },
            {
                localeKey: 'sideNavigation.report.agentProductivityPerDay',
                name: 'AgentProductivityPerDayReport',
                permission: Permission.VIEW_AGENT_PRODUCTIVITY_PER_DAY_REPORT,
            },
            {
                localeKey: 'sideNavigation.report.agentProductivityPerAgent',
                name: 'AgentProductivityPerAgentReport',
                permission: Permission.VIEW_AGENT_PRODUCTIVITY_PER_AGENT_REPORT,
            },
            {
                localeKey: 'sideNavigation.report.realtime',
                name: 'RealtimeReport',
                permission: Permission.VIEW_REALTIME_REPORT,
            },
            {
                localeKey: 'sideNavigation.report.realtimeAgentExtension',
                name: 'RealtimeAgentExtensionReport',
                permission: Permission.VIEW_REALTIME_AGENT_EXTENSION_REPORT,
            },
            {
                localeKey: 'sideNavigation.report.agentDeviceStatusHistory',
                name: 'AgentDeviceReportPage',
                permission: Permission.VIEW_AGENT_DEVICE_STATUS_HISTORY_REPORT,
            },
        ],
    },
    {
        localeKey: 'sideNavigation.meet.group',
        icon: VideoCameraIcon,
        isOpen: false,
        children: [
            {
                localeKey: 'sideNavigation.meeting',
                icon: VideoCameraIcon,
                name: 'MeetingList',
                permission: Permission.VIEW_MEETINGS,
                ...clearStateOnSetup,
                isOpen: false,
                activateBy: ['MeetingList', 'ViewMeetingById'],
            },
        ],
    },
    {
        localeKey: 'sideNavigation.report.billing',
        icon: CurrencyDollarIcon,
        name: 'BillingReportPage',
        permission: Permission.VIEW_BILLING_REPORT,
    },
    {
        localeKey: 'sideNavigation.settings',
        icon: CogIcon,
        name: 'Settings',
    },
];

export const settingSideNavigationItems: NavigationItem[] = [
    {
        localeKey: 'sideNavigation.settings',
        icon: CogIcon,
        name: 'Settings',
    },
    {
        localeKey: 'core.entity.Levels',
        icon: OfficeBuildingIcon,
        name: 'ListLevel',
        permission: Permission.VIEW_LEVELS,
        ...clearStateOnSetup,
    },
    {
        localeKey: 'core.entity.Users',
        icon: UsersIcon,
        name: 'ListUser',
        permission: Permission.VIEW_USERS,
        ...clearStateOnSetup,
    },
    {
        localeKey: 'core.entity.UserLabels',
        icon: UserGroupIcon,
        name: 'ListUserLabel',
        permission: Permission.VIEW_USER_LABELS,
        ...clearStateOnSetup,
    },
    {
        localeKey: 'core.entity.Numbers',
        icon: PhoneIcon,
        name: 'ListNumber',
        permission: Permission.VIEW_NUMBERS,
        ...clearStateOnSetup,
    },
    {
        localeKey: 'core.entity.Contacts',
        icon: BookOpenIcon,
        name: 'ListContact',
        permission: Permission.VIEW_CONTACTS,
        ...clearStateOnSetup,
    },
    {
        localeKey: 'core.entity.SpamNumbers',
        icon: BanIcon,
        name: 'ListSpamNumber',
        permission: Permission.VIEW_SPAM_NUMBERS,
        ...clearStateOnSetup,
    },
    {
        localeKey: 'core.entity.ConnectFlow',
        icon: ConnectFlowIcon,
        name: 'ListConnectFlow',
        permission: Permission.VIEW_CONNECT_FLOWS,
        ...clearStateOnSetup,
    },
    {
        localeKey: 'core.entity.ConnectFlowAudio',
        icon: CloudUploadIcon,
        name: 'ListConnectFlowAudio',
        permission: Permission.VIEW_CONNECT_FLOW_AUDIOS,
        ...clearStateOnSetup,
    },
    {
        localeKey: 'core.entity.Queues',
        icon: MenuAlt2Icon,
        name: 'ListQueue',
        permission: Permission.VIEW_QUEUES,
        ...clearStateOnSetup,
    },
    {
        localeKey: 'core.entity.Schedules',
        icon: ClockIcon,
        name: 'ListSchedule',
        permission: Permission.VIEW_SCHEDULES,
        ...clearStateOnSetup,
    },
    {
        localeKey: 'core.entity.ConnectFlowSurveys',
        icon: AnnotationIcon,
        name: 'ListConnectFlowSurvey',
        permission: Permission.VIEW_CONNECT_FLOW_SURVEYS,
        ...clearStateOnSetup,
    },
    {
        localeKey: 'core.entity.Words',
        icon: TagIcon,
        name: 'ListWord',
        permission: Permission.VIEW_WORDS,
        ...clearStateOnSetup,
    },
    {
        localeKey: 'core.entity.Integrations',
        icon: LinkIcon,
        name: 'ListIntegration',
        permission: Permission.VIEW_INTEGRATIONS,
        ...clearStateOnSetup,
    },
    {
        localeKey: 'core.entity.Permissions',
        icon: LockClosedIcon,
        name: 'Permissions',
        permission: Permission.ASSIGN_PERMISSION_TO_ROLE,
        ...clearStateOnSetup,
    },
    {
        localeKey: 'sideNavigation.home',
        icon: HomeIcon,
        name: 'Home',
    },
];
