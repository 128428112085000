<script lang="ts" setup>
import { computed, ComputedRef, ref, Ref, watch, WritableComputedRef } from 'vue';

import {
    ConnectFlowModelingGetters,
    ConnectFlowModelingMutations,
    EdgeType,
    VueFlowConnectionModel,
    VueFlowNodeModel,
    NodeType,
} from '@/types';
import store from '@/store';
import { helpers } from '@vuelidate/validators';
import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
    edgeId: {
        type: String,
    },
    modelValue: {
        type: Boolean,
        required: true,
        default: false,
    },
});

watch(
    () => props.modelValue,
    (show) => {
        internalShow.value = show;
        if (show) {
            if (edge.value?.data !== undefined) {
                formState.value.waitUser = edge.value.data?.waitUser;
                formState.value.option = edge.value.data?.uraOption;
            }
        }
    },
);

const emit = defineEmits(['update:modelValue']);

const internalShow = ref(props.modelValue);
const handleToggle = (state: boolean) => {
    emit('update:modelValue', state);
};
watch(internalShow, () => {
    handleToggle(internalShow.value);
});

const edge: WritableComputedRef<VueFlowConnectionModel> = computed({
    get: () => store.getters['flow/' + ConnectFlowModelingGetters.ELEMENT_BY_ID](props.edgeId),
    set: (value: VueFlowConnectionModel) => {
        store.commit('flow/' + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
            idx: null,
            value,
        });
    },
});

interface IEdgeValidationFormState {
    waitUser: boolean | null;
    option: number | null;
}

const formState: Ref<IEdgeValidationFormState> = ref({
    waitUser: null,
    option: null,
});
const validationFill = t('connectFlow.edge.validation.fill');

const checkFill = helpers.withMessage(validationFill, (value, state) => {
    return state.waitUser || !!state.option;
});

const connections: ComputedRef<VueFlowConnectionModel[]> = computed(
    () => store.getters['flow/' + ConnectFlowModelingGetters.ELEMENTS_CONNECTIONS],
);

const nodeTarget: ComputedRef<VueFlowNodeModel | undefined> = computed(() => {
    const nodes: VueFlowNodeModel[] =
        store.getters['flow/' + ConnectFlowModelingGetters.ELEMENTS_NODES];
    return nodes.find((n) => n.id === edge.value.target);
});

const validationUsed = t('connectFlow.edge.validation.used');
const isUsed = helpers.withMessage(validationUsed, (value, state) => {
    const siblings = connections.value.filter((c) => c.source === edge.value.source);
    let used = null;

    if (state.option >= 0) {
        used = siblings.find(
            (s) =>
                s.data?.component === EdgeType.CLIENT_INFO_EDGE &&
                s.data?.uraOption === state.option &&
                s.id !== edge.value.id,
        );
    } else if (state.waitUser != null) {
        used = siblings.find(
            (s) =>
                s.data?.component === EdgeType.CLIENT_INFO_EDGE &&
                s.data?.waitUser === state.waitUser &&
                s.id !== edge.value.id,
        );
    }
    return used === undefined;
});
const rules = computed<ValidationArgs<IEdgeValidationFormState>>(() => {
    const r: ValidationArgs<IEdgeValidationFormState> = {
        waitUser: {
            checkFill: checkFill,
            isUsed: isUsed,
        },
        option: {
            checkFill: checkFill,
            isUsed: isUsed,
        },
    };
    return r;
});
const v$: Ref<Validation<ValidationArgs<IEdgeValidationFormState>>> = useVuelidate(
    rules,
    formState,
);

const isFormCorrect = ref(true);

async function onSubmit() {
    isFormCorrect.value = await v$.value.$validate();

    if (isFormCorrect.value && edge.value.data != undefined) {
        edge.value.data.uraOption = formState.value.waitUser ? null : formState.value.option;
        edge.value.data.waitUser = formState.value.waitUser ?? false;
        edge.value.data.hasErrors = false;

        if (formState.value.waitUser) {
            edge.value.label = t('connectFlow.edge.waitUser');
        } else {
            edge.value.label = t('connectFlow.edge.option', {
                number: formState.value.option,
            });
        }
        internalShow.value = false;
    }
    if (edge.value.data !== undefined) {
        edge.value.data.hasErrors = !isFormCorrect.value;
    }

    v$.value.$reset();
}
</script>
<template>
    <UiRightPanel v-model="internalShow" :title="$t('connectFlow.edge.titleInfoClient')">
        <form autocomplete="off" class="flex flex-col gap-3" @submit.prevent="onSubmit">
            <UiTextInput
                :label="$t('connectFlow.edge.uraOption')"
                name="uraOption"
                type="number"
                v-model="v$.option.$model"
                :disabled="formState.waitUser ?? false"
                :errors="v$.waitUser.$errors"
                :silent-errors="v$.waitUser.$silentErrors"
            />

            <div
                class="mt-6 flex flex-col justify-end pb-1.5"
                v-if="nodeTarget?.data.component === NodeType.TRANSFER_EXTENSION"
            >
                <UiCheckboxInput
                    v-model="v$.waitUser.$model"
                    name="isActive"
                    :label="$t('connectFlow.edge.waitUser')"
                    class="h-6"
                    :errors="v$.waitUser.$errors"
                />
                <div
                    class="text-danger bg-danger-50 z-10 w-full px-2 py-2 text-sm shadow"
                    v-for="error of v$.waitUser.$errors"
                    :key="error.$uid"
                >
                    <p>
                        {{ error.$response ? error.$response : error.$message }}
                    </p>
                </div>
            </div>

            <div class="mt-6 grid grid-cols-2 place-content-around gap-4 text-center">
                <UiButton variant="white" text-variant="gray-700" @click="internalShow = false">
                    {{ $t('core.actions.Cancel') }}
                </UiButton>
                <UiButton type="submit" variant="primary" text-variant="white">
                    {{ $t('core.actions.Apply') }}
                </UiButton>
            </div>
        </form>
    </UiRightPanel>
</template>
