export interface ContactFormState {
    name: string | null;
    emails: ContactEmailFormState[];
    phones: ContactPhoneFormState[];
}

export interface ContactEmailFormState {
    id?: number;
    levelId?: number;
    email: string | null;
}

export interface ContactPhoneFormState {
    id?: number;
    levelId?: number;
    number: string | null;
}
