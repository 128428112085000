import { ConnectFlowSurveyQuestionAnswerModel, FullAudit, IsActiveFlag } from '@/types';

export interface ConnectFlowSurveyQuestionModel extends IsActiveFlag, FullAudit {
    id: number;
    levelId: number;
    surveyId: number;
    label: string;
    rangeAnswer: string;
    audioFilePath?: string | null;
    textToAudio?: string;
    answer?: ConnectFlowSurveyQuestionAnswerModel;
}
