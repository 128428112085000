<script lang="ts" setup>
import { computed } from 'vue';
import store from '@/store';
import { AuthGetters, Permission, UserModel, LevelModel, RoleModel } from '@/types';

const user = computed<UserModel>(() => store.getters[AuthGetters.USER]);
const userLevels = computed<LevelModel[]>(() =>
    store.getters[AuthGetters.USER_LEVELS].map((level: LevelModel) => level.id + ':' + level.name),
);
const userRoles = computed<RoleModel[]>(() =>
    store.getters[AuthGetters.USER_ROLES].map((role: RoleModel) => role.id + ':' + role.name),
);
const userPermissions = computed<Permission[]>(() => store.getters[AuthGetters.USER_PERMISSIONS]);
</script>

<template>
    <div class="p-4">
        <h3>ProfilePage</h3>
        <p>id: {{ user.id }}</p>
        <p>name: {{ user.name }}</p>
        <p>email: {{ user.email }}</p>
        <p>levels: {{ userLevels }}</p>
        <p>roles: {{ userRoles }}</p>
        <p>permissions: {{ userPermissions }}</p>
    </div>
</template>
