<script lang="ts" setup>
import { ref, computed, reactive, onMounted, Ref, ComponentPublicInstance } from 'vue';
import { useRoute } from 'vue-router';
import { AxiosResponse } from 'axios';

import { useUiStore } from '@/stores';
import { useVuelidateWithLogging } from '@/composables/useVuelidateWithLogging';
import { goBack } from '@/composables/useNavigation';
import { ContactService } from '@/services';
import { toast, toastServiceError } from '@/services/core/notification';
import { required, maxLength } from '@/utils/validators';

import { Permission, FormAction, ContactModel, ContactFormState } from '@/types';

import { ContactEmailForm, ContactPhoneForm } from '@/views/pages/contact/components';

const props = defineProps({
    action: { type: String, default: FormAction.CREATE },
});

const route = useRoute();
const uiStore = useUiStore();

const emailRefs: Ref<InstanceType<typeof ContactEmailForm>[]> = ref([]);
const phoneRefs: Ref<InstanceType<typeof ContactPhoneForm>[]> = ref([]);

const formState = reactive<ContactFormState>({
    name: null,
    emails: [],
    phones: [],
});

const rules = computed(() => ({
    name: { required, maxLength: maxLength(255) },
    emails: {},
    phones: {},
}));

const { v$, $validateWithLogging } = useVuelidateWithLogging(rules, formState);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchContact = async (id: number) => {
    try {
        await setLoading(true);
        const { data } = await ContactService.get<ContactModel>(id);
        formState.name = data.name;
        formState.emails = data.emails;
        formState.phones = data.phones;
    } catch (e) {
        toastServiceError(e);
    } finally {
        await setLoading(false);
    }
};

const setEmailRef = (el: Element | ComponentPublicInstance | null, index: number) => {
    if (el && '$el' in el) {
        emailRefs.value[index] = el as InstanceType<typeof ContactEmailForm>;
    } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        emailRefs.value[index] = null as any;
    }
};

const setPhoneRef = (el: Element | ComponentPublicInstance | null, index: number) => {
    if (el && '$el' in el) {
        phoneRefs.value[index] = el as InstanceType<typeof ContactPhoneForm>;
    } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        phoneRefs.value[index] = null as any;
    }
};

const addEmail = () => {
    formState.emails.push({ email: null });
};

const addPhone = () => {
    formState.phones.push({ number: null });
};

const removeEmail = (index: number) => {
    formState.emails.splice(index, 1);
};

const removePhone = (index: number) => {
    formState.phones.splice(index, 1);
};

const onSubmit = async () => {
    const emailValidationResults = await Promise.all(
        emailRefs.value.map((ref) => !ref || ref?.validate?.()),
    );

    const phoneValidationResults = await Promise.all(
        phoneRefs.value.map((ref) => !ref || ref?.validate?.()),
    );

    const allValid = [...emailValidationResults, ...phoneValidationResults].every(Boolean);
    const isFormValid = await $validateWithLogging();

    if (!isFormValid || !allValid) return;

    await setLoading(true);

    const handleServiceSuccess = (message: string, id: number) => {
        toast.success(message);
        v$.value.$reset();
        goBack({ name: 'ViewContactById', params: { id } });
    };

    try {
        if (props.action === FormAction.EDIT) {
            const id = Number(route.params.id);
            const { data }: AxiosResponse = await ContactService.update(id, formState);
            handleServiceSuccess(data.message, id);
        } else {
            const { data }: AxiosResponse = await ContactService.create(formState);
            handleServiceSuccess(data.message, data.result.id);
        }
    } catch (e) {
        toastServiceError(e);
    } finally {
        await setLoading(false);
    }
};

onMounted(async () => {
    if (props.action === FormAction.EDIT) {
        const contactId = Number(route.params.id);
        if (!isNaN(contactId)) fetchContact(contactId);
    }
});
</script>

<template>
    <form class="space-y-4 p-4 sm:p-6 lg:p-8" autocomplete="off" @submit.prevent="onSubmit">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('contact.' + props.action + '.title') }}
                </h1>
            </template>
            <template #actions>
                <div class="flex items-center space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ListContact' }" />
                    <UiButton
                        v-if="
                            (props.action == FormAction.CREATE &&
                                $can(Permission.CREATE_CONTACT)) ||
                            (props.action == FormAction.EDIT && $can(Permission.EDIT_CONTACT))
                        "
                        type="submit"
                        variant="primary"
                        text-variant="white"
                    >
                        {{ $t('core.actions.Save') }}
                    </UiButton>
                </div>
            </template>
        </UiPageHeader>
        <UiPanel>
            <div class="gap-4 md:grid md:grid-cols-2">
                <UiTextInput
                    v-model="v$.name.$model"
                    name="name"
                    type="text"
                    :label="$t('contact.labels.name')"
                    :errors="v$.name.$errors"
                    @blur="v$.name.$touch"
                />
            </div>
        </UiPanel>
        <UiPanel>
            <div class="flex">
                <h2 class="mb-2 flex-1 text-lg font-semibold text-gray-800">
                    {{ $t('contact.form.section.emails.title') }}
                </h2>
                <UiButton variant="primary" text-variant="white" @click="addEmail">
                    {{ $t('contact.form.actions.addEmail') }}
                </UiButton>
            </div>
            <div v-if="formState.emails.length === 0">
                <p>{{ $t('contact.errors.noEmails') }}</p>
            </div>
            <div class="gap-4 md:grid md:grid-cols-2">
                <ContactEmailForm
                    v-for="(item, index) in formState.emails"
                    :key="index"
                    :index="index"
                    v-model="formState.emails[index]"
                    :ref="(el) => setEmailRef(el, index)"
                    @remove="() => removeEmail(index)"
                />
            </div>
        </UiPanel>
        <UiPanel>
            <div class="flex">
                <h2 class="mb-2 flex-1 text-lg font-semibold text-gray-800">
                    {{ $t('contact.form.section.phones.title') }}
                </h2>
                <UiButton variant="primary" text-variant="white" @click="addPhone">
                    {{ $t('contact.form.actions.addPhone') }}
                </UiButton>
            </div>
            <div v-if="formState.phones.length === 0">
                <p>{{ $t('contact.errors.noPhones') }}</p>
            </div>
            <div class="gap-4 md:grid md:grid-cols-2">
                <ContactPhoneForm
                    v-for="(item, index) in formState.phones"
                    :key="index"
                    :index="index"
                    v-model="formState.phones[index]"
                    :ref="(el) => setPhoneRef(el, index)"
                    @remove="() => removePhone(index)"
                />
            </div>
        </UiPanel>
        <UiCheckDirtyBeforeRouteLeave :dirty="v$.$anyDirty" />
    </form>
</template>
