import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "space-y-4 p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-800" }
const _hoisted_3 = { class: "grid grid-cols-1 gap-4 md:grid-cols-2" }
const _hoisted_4 = { class: "text-center text-xs font-medium text-gray-500" }
const _hoisted_5 = { class: "mt-1 text-center text-3xl font-semibold text-gray-800" }
const _hoisted_6 = { class: "w-full border-collapse text-sm" }
const _hoisted_7 = { class: "sticky top-0 bg-gray-200 text-gray-800" }
const _hoisted_8 = { class: "p-4" }
const _hoisted_9 = { class: "flex items-center text-left" }
const _hoisted_10 = { class: "whitespace-nowrap" }
const _hoisted_11 = { class: "px-4 py-2 text-center" }
const _hoisted_12 = { class: "px-4 py-2 text-center" }
const _hoisted_13 = { class: "px-4 py-2 text-center" }
const _hoisted_14 = { class: "px-4 py-2 text-center" }
const _hoisted_15 = { class: "px-4 py-2 text-center" }
const _hoisted_16 = { class: "text-xs" }
const _hoisted_17 = { class: "px-4 py-2 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiDatePicker = _resolveComponent("UiDatePicker")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!
  const _component_UiPanel = _resolveComponent("UiPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('report.agentDeviceStatusHistory.title')), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_UiDatePicker, {
          modelValue: $setup.dateRange,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.dateRange) = $event)),
          range: "",
          multiCalendars: ""
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("dl", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList({ totalTimePaused: $setup.totalTimePaused, totalTimeCall: $setup.totalTimeCall }, (value, key) => {
        return (_openBlock(), _createBlock(_component_UiPanel, { key: key }, {
          default: _withCtx(() => [
            _createElementVNode("dt", _hoisted_4, _toDisplayString(_ctx.$t(`report.agentDeviceStatusHistory.${key}`)), 1 /* TEXT */),
            _createElementVNode("dd", _hoisted_5, _toDisplayString($setup.formatSecToTime(value)), 1 /* TEXT */)
          ]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createVNode(_component_UiPanel, { "no-padding": "" }, {
      default: _withCtx(() => [
        _createElementVNode("table", _hoisted_6, [
          _createElementVNode("thead", _hoisted_7, [
            _createElementVNode("tr", null, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.tableHeaders, (header) => {
                return _createElementVNode("th", {
                  key: header.key,
                  class: _normalizeClass(["p-4", header.align === 'center' ? 'text-center' : 'text-left'])
                }, _toDisplayString(_ctx.$t(header.label)), 3 /* TEXT, CLASS */)
              }), 64 /* STABLE_FRAGMENT */))
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.agentsDevices, (device, idx) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: idx,
                class: "even:bg-gray-100"
              }, [
                _createElementVNode("td", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent($setup.getDeviceIcon(device)), { class: "mr-1 h-4 w-4" })),
                    _createElementVNode("span", _hoisted_10, _toDisplayString(device.userName), 1 /* TEXT */)
                  ])
                ]),
                _createElementVNode("td", _hoisted_11, _toDisplayString(device.extension), 1 /* TEXT */),
                _createElementVNode("td", _hoisted_12, _toDisplayString($setup.formatSecToTime(device.totalTime.CONNECTED, true)), 1 /* TEXT */),
                _createElementVNode("td", _hoisted_13, _toDisplayString(device.queues.length
                                    ? $setup.formatSecToTime(device.queues[0].pauseDuration, true)
                                    : '-'), 1 /* TEXT */),
                _createElementVNode("td", _hoisted_14, _toDisplayString($setup.formatSecToTime(device.totalTime.IN_CALL, true)), 1 /* TEXT */),
                _createElementVNode("td", _hoisted_15, [
                  _createElementVNode("span", {
                    class: _normalizeClass($setup.getStatusColor(device.lastStatus))
                  }, _toDisplayString(_ctx.$t(
                                        `report.agentDeviceStatusHistory.deviceStatus.${device.lastStatus}`,
                                    )), 3 /* TEXT, CLASS */),
                  _createElementVNode("div", _hoisted_16, _toDisplayString($setup.moment(device.lastStatusAt).fromNow()), 1 /* TEXT */)
                ]),
                _createElementVNode("td", _hoisted_17, _toDisplayString(device.queues.map((q) => q.name).join(', ')), 1 /* TEXT */),
                _createElementVNode("td", {
                  class: _normalizeClass(["px-4 py-2 text-center", $setup.getQueueColorStatus(device.queueStatus)])
                }, [
                  (device.queueStatus)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t(
                                            `report.agentDeviceStatusHistory.queueStatus.${device.queueStatus}`,
                                        )), 1 /* TEXT */),
                        _createElementVNode("span", null, _toDisplayString($setup.moment(device.queueStatusAt).fromNow()), 1 /* TEXT */)
                      ], 64 /* STABLE_FRAGMENT */))
                    : _createCommentVNode("v-if", true)
                ], 2 /* CLASS */)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}