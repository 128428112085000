import { RoleModel, DefaultServiceResult } from '@/types';
import { BaseService } from '@/services';

class RoleService extends BaseService<RoleModel> {
    addPermission = (id: number, permissionId: string) => {
        return this.http.post<DefaultServiceResult>(`${this.route}/${id}/permission`, {
            permissionId,
        });
    };
    removePermission = (id: number, permissionId: string) => {
        return this.http.delete<DefaultServiceResult>(
            `${this.route}/${id}/permission/${permissionId}`,
        );
    };
}

export default new RoleService('roles');
