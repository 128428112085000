import { DefaultServiceResult } from '@/types';
import { AxiosError } from 'axios';
import { useToast } from 'vue-toastification';

export const toast = useToast();

export const toastServiceError = (err: unknown) => {
    let message = 'Ocorreu um erro inesperado.';

    if ((err as AxiosError<DefaultServiceResult>)?.isAxiosError) {
        const axiosError = err as AxiosError<DefaultServiceResult>;

        message =
            axiosError.response?.data?.message ||
            axiosError.response?.statusText ||
            axiosError.message ||
            message;
    } else if (err instanceof Error) {
        message = err.message;
    }

    toast.error(message);
};
