<script lang="ts" setup>
import { StepEdge, EdgeText, getSmoothStepPath } from '@vue-flow/core';
import { computed, ComputedRef } from 'vue';
import { ConnectFlowModelingGetters, VueFlowConnectionModel } from '@/types';
import store from '@/store';

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    sourceX: {
        type: Number,
        required: true,
    },
    sourceY: {
        type: Number,
        required: true,
    },
    targetX: {
        type: Number,
        required: true,
    },
    targetY: {
        type: Number,
        required: true,
    },
    sourcePosition: {
        type: String,
        required: true,
    },
    targetPosition: {
        type: String,
        required: true,
    },
    data: {
        type: Object,
        required: false,
    },
    markerEnd: {
        type: String,
        required: false,
    },
    style: {
        type: Object,
        required: false,
    },
});

defineOptions({
    inheritAttrs: false,
});

const path = computed(() => getSmoothStepPath(props));

const edge: ComputedRef<VueFlowConnectionModel> = computed(() =>
    store.getters['flow/' + ConnectFlowModelingGetters.ELEMENT_BY_ID](props.id),
);
const color = computed(() =>
    (edge.value.data?.uraOption !== undefined && edge.value.data?.uraOption !== null) ||
    (edge.value.data?.waitUser !== undefined && edge.value.data?.waitUser)
        ? '#4ADE80'
        : '#F87171',
);
</script>
<template>
    <StepEdge :id="id" :style="style" :path="path[0]" :marker-end="markerEnd" />
    <EdgeText
        :x="props.targetX - 0"
        :y="props.targetY - 30"
        :style="`stroke: ${color}`"
        style="stroke-width: 0.5; filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.1))"
        :label-show-bg="true"
        :label-bg-padding="[8, 4]"
        :label-bg-border-radius="5"
    >
        <template
            v-if="
                (edge.data?.uraOption !== undefined && edge.data?.uraOption !== null) ||
                edge.data.waitUser
            "
        >
            {{ edge.label }}
        </template>
        <template v-else>
            {{ $t('connectFlow.edge.uraOption') }}
        </template>
    </EdgeText>
</template>
