import { defineComponent as _defineComponent } from 'vue'
import { PropType } from 'vue';
import { ConnectFlowFlowModel } from '@/types';
import { BookmarkIcon } from '@heroicons/vue/solid';
import ConnectFlowIcon from '@/components/svg/ConnectFlowIcon';


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowHistoryItem',
  props: {
    modelValue: {
        type: Object as PropType<ConnectFlowFlowModel>,
        required: true,
    },
    published: {
        type: Boolean,
        default: false,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();



const __returned__ = { get BookmarkIcon() { return BookmarkIcon }, get ConnectFlowIcon() { return ConnectFlowIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})