<script lang="ts" setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { MenuAlt2Icon } from '@heroicons/vue/solid';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import { QueueService } from '@/services';
import { toast, toastServiceError } from '@/services/core/notification';

import {
    FilterItem,
    Permission,
    QueueActions,
    QueueFilter,
    QueueGetters,
    QueueModel,
    QueueServiceGetAllParams,
    SortMenuItem,
} from '@/types';
import { QueueListItem, QueueListSort, QueueListFilters } from '@/views/pages/queue/components';

const queues = ref<QueueModel[]>([]);
const count = ref(0);
const perPage = 10;

const route = useRoute();
const router = useRouter();
const filterStore = useFilterStore();
const uiStore = useUiStore();

const search = computed({
    get: () => store.getters[QueueGetters.SEARCH],
    set: (value: string) => store.dispatch(QueueActions.SET_SEARCH, value),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[QueueGetters.SORT],
    set: (value: SortMenuItem[]) => store.dispatch(QueueActions.SET_SORT, value),
});

const level = computed(() => filterStore.level);

const currentPage = computed({
    get: () => store.getters[QueueGetters.PAGE],
    set: (value: number) => store.dispatch(QueueActions.SET_PAGE, value),
});

const filter: QueueFilter = store.getters[QueueGetters.FILTER];

const activeFilters = computed<FilterItem[]>(() => store.getters[QueueGetters.ACTIVE_FILTERS]);

const orderBy = computed(() => sortMenuSelected.value.map((s) => s.direction + s.key).join(','));

watch([currentPage], () => {
    fetchQueues();
});

watch([level, search, filter, orderBy], () => {
    if (currentPage.value === 1) fetchQueues();
    else currentPage.value = 1;
});

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getParams = (): QueueServiceGetAllParams => ({
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    is_active: filter.isActive !== null ? (filter.isActive.value as boolean) : undefined,
    search: search.value,
});

const fetchQueues = async () => {
    setLoading(true);
    try {
        const { data, headers } = await QueueService.getAll<QueueModel[]>({
            params: getParams(),
        });
        queues.value = data;
        count.value = parseInt(headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const setIsActive = async (item: QueueModel, isActive: boolean) => {
    setLoading(true);
    try {
        const { data } = await (
            isActive
                ? (id: number) => QueueService.activate(id)
                : (id: number) => QueueService.inactivate(id)
        )(item.id);
        toast.success(data.message);
        await fetchQueues();
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveSortClick = (item: SortMenuItem) => {
    const index = sortMenuSelected.value.indexOf(item);
    if (index > -1) sortMenuSelected.value.splice(index, 1);
};

const handleRemoveFilterClick = (item: FilterItem) =>
    store.dispatch(QueueActions.CLEAR_FILTER, item);

const handleActivateClick = (item: QueueModel) => setIsActive(item, true);
const handleInactivateClick = (item: QueueModel) => setIsActive(item, false);

onMounted(() => {
    if (route.query.clearStateOnSetup) {
        search.value = '';
        sortMenuSelected.value = [];
        currentPage.value = 1;
        router.replace({ query: {} });
    }

    fetchQueues();
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="flex items-center text-xl font-semibold text-gray-800">
                    <MenuAlt2Icon class="mr-1 h-6 w-6 text-gray-500" aria-hidden="true" />
                    {{ $t('core.entity.Queues') }}
                </h1>
            </template>
            <template #actions>
                <router-link v-if="$can(Permission.CREATE_QUEUE)" :to="{ name: 'CreateQueue' }">
                    <UiButton variant="primary" text-variant="white">
                        {{ $t('core.actions.Add') }}
                    </UiButton>
                </router-link>
            </template>
        </UiPageHeader>

        <div class="space-y-2">
            <UiPanel class="flex px-4 py-2" no-padding>
                <UiListSearch
                    v-model="search"
                    :placeholder="$t('queue.filters.search')"
                    class="flex-1"
                />
                <div class="flex items-center space-x-4">
                    <QueueListSort v-model="sortMenuSelected" />
                    <QueueListFilters />
                </div>
            </UiPanel>

            <UiPanel v-if="sortMenuSelected.length || activeFilters.length" class="space-x-2">
                <UiSortMenuBadge
                    v-for="selected in sortMenuSelected"
                    :key="selected.key"
                    :item="selected"
                    @remove="handleRemoveSortClick(selected)"
                />
                <UiBadge
                    v-for="item in activeFilters"
                    :key="`activeFilter${item.field}`"
                    removable
                    @remove="handleRemoveFilterClick(item)"
                >
                    {{ $t(`queue.filters.${item.field}.label`) }}: {{ item.label }}
                </UiBadge>
            </UiPanel>

            <div v-if="queues.length" class="space-y-2">
                <QueueListItem
                    v-for="queue in queues"
                    :key="queue.id"
                    :model-value="queue"
                    @activate="handleActivateClick"
                    @inactivate="handleInactivateClick"
                />
            </div>
            <UiPanel v-else class="flex items-center justify-center">
                {{ $t('core.messages.noRecordsFound') }}
            </UiPanel>

            <UiPagination
                v-if="queues.length"
                v-model="currentPage"
                :count="count"
                :perPage="perPage"
            />
        </div>
    </div>
</template>
