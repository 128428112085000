<script lang="ts" setup>
const { variant, to } = defineProps({
    variant: {
        type: String,
        default: 'neutral',
    },
    to: {
        type: Object,
    },
});
</script>

<template>
    <UiButton
        variant="inherit"
        :text-variant="variant"
        :ring-variant="variant"
        no-border
        no-shadow
        no-padding
        no-rounded
        class="rounded-full text-sm"
        :to="to"
    >
        <slot></slot>
    </UiButton>
</template>
