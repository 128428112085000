<script lang="ts" setup>
import { ref, computed, reactive, watch, onMounted, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { AxiosResponse } from 'axios';

import { useUiStore } from '@/stores';
import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';

import { required, maxLength } from '@/utils/validators';
import { toast, toastServiceError } from '@/services/core/notification';
import { getAudioFormat } from '@/utils/audio';
import { goBack } from '@/composables/useNavigation';

import { ConnectFlowAudioService, VaultService } from '@/services';
import { FormAction, ConnectFlowAudioModel, ConnectFlowAudioFormState, Permission } from '@/types';

import { UiPanel } from '@/components';

const props = defineProps({
    action: { type: String, default: FormAction.CREATE },
});

const route = useRoute();
const uiStore = useUiStore();

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const formState = reactive<ConnectFlowAudioFormState>({
    name: null,
    audioFilePath: null,
    isActive: true,
});

const file = ref<File | null>(null);
const audioPreview = ref<string | null>(null);

const rules = computed<ValidationArgs<ConnectFlowAudioFormState>>(() => ({
    name: { required, maxLength: maxLength(50) },
    audioFilePath: { required },
    isActive: { required },
}));

const v$: Ref<Validation<ValidationArgs<ConnectFlowAudioFormState>>> = useVuelidate(
    rules,
    formState,
);

const fetchConnectFlowAudio = async (id: number) => {
    try {
        await setLoading(true);
        const { data } = await ConnectFlowAudioService.get<ConnectFlowAudioModel>(id);
        formState.name = data.name;
        formState.audioFilePath = data.audioFilePath;
        formState.isActive = data.isActive;
        loadAudio();
    } catch (e) {
        toastServiceError(e);
    } finally {
        await setLoading(false);
    }
};

const loadAudio = async () => {
    if (formState.audioFilePath) {
        try {
            const { data } = await VaultService.getAudioAsset(formState.audioFilePath);
            const blob = new Blob([data], { type: getAudioFormat(formState.audioFilePath) });
            file.value = new File([blob], formState.audioFilePath, {
                type: blob.type,
                lastModified: Date.now(),
            });
        } catch (e) {
            toastServiceError(e);
        }
    }
};

const onSubmit = async () => {
    const isFormValid = await v$.value.$validate();
    if (!isFormValid) return;

    await setLoading(true);

    const handleServiceSuccess = (message: string, id: number) => {
        toast.success(message);
        v$.value.$reset();
        goBack({ name: 'ViewConnectFlowAudioById', params: { id } });
    };

    try {
        const formData = new FormData();
        formData.append('name', formState.name || '');
        formData.append('isActive', formState.isActive.toString());

        if (file.value) {
            formData.append('audio', file.value);
        }

        if (props.action === FormAction.EDIT) {
            const id = Number(route.params.id);
            const { data }: AxiosResponse = await ConnectFlowAudioService.update(id, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            handleServiceSuccess(data.message, id);
        } else {
            const { data }: AxiosResponse = await ConnectFlowAudioService.create(formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            handleServiceSuccess(data.message, data.result.id);
        }
    } catch (e) {
        toastServiceError(e);
    } finally {
        await setLoading(false);
    }
};

watch([file], () => {
    if (file.value) {
        formState.audioFilePath = file.value.name;
        audioPreview.value = URL.createObjectURL(file.value);
    } else {
        formState.audioFilePath = '';
        audioPreview.value = null;
    }
});

onMounted(async () => {
    if (props.action === FormAction.EDIT) {
        await fetchConnectFlowAudio(Number(route.params.id));
    }
});
</script>

<template>
    <form class="space-y-4 p-4 sm:p-6 lg:p-8" autocomplete="off" @submit.prevent="onSubmit">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('connectFlowAudio.' + props.action + '.title') }}
                </h1>
            </template>
            <template #actions>
                <div class="flex items-center space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ListConnectFlowAudio' }" />
                    <UiButton
                        v-if="
                            (props.action == FormAction.CREATE &&
                                $can(Permission.CREATE_CONNECT_FLOW_AUDIO)) ||
                            (props.action == FormAction.EDIT &&
                                $can(Permission.EDIT_CONNECT_FLOW_AUDIO))
                        "
                        type="submit"
                        variant="primary"
                        text-variant="white"
                    >
                        {{ $t('core.actions.Save') }}
                    </UiButton>
                </div>
            </template>
        </UiPageHeader>

        <UiPanel class="grid grid-cols-1 gap-4 md:grid-cols-3">
            <UiTextInput
                v-model="v$.name.$model"
                name="name"
                type="text"
                :label="$t('connectFlowAudio.labels.name')"
                required
                :errors="v$.name.$errors"
                @blur="v$.name.$touch"
            />

            <div class="flex flex-col justify-end pb-1.5">
                <UiCheckboxInput
                    v-model="v$.isActive.$model"
                    name="isActive"
                    :label="$t('connectFlowAudio.labels.isActive')"
                    class="h-6"
                />
            </div>
        </UiPanel>

        <!-- Audio upload -->
        <UiPanel class="flex flex-col space-y-2">
            <label class="block text-sm font-medium text-gray-800">
                {{ $t('connectFlowAudio.labels.audioUpload') }}
                <span class="text-xs">*</span>
            </label>
            <UiAudioUpload
                v-model:file="file"
                :text-rule="$t('connectFlowAudio.create.uploadRules')"
                :errors="v$.audioFilePath.$errors"
            />
            <div
                class="text-danger bg-danger-100 p-2 text-sm shadow"
                v-if="v$.audioFilePath.$errors && v$.audioFilePath.$errors.length"
            >
                <p v-for="error of v$.audioFilePath.$errors" :key="error.$uid">
                    {{ error.$message }}
                </p>
            </div>
        </UiPanel>

        <!-- Audio preview -->
        <UiPanel v-if="audioPreview" class="space-y-2">
            <label class="block text-sm font-medium text-gray-800">{{
                $t('connectFlowAudio.labels.audioPreview')
            }}</label>
            <audio controls class="w-full">
                <source :src="audioPreview" />
                Your browser does not support the audio element.
            </audio>
        </UiPanel>

        <UiCheckDirtyBeforeRouteLeave :dirty="v$.$anyDirty" />
    </form>
</template>
