import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch } from 'vue';
import store from '@/store';
import { useI18n } from 'vue-i18n';

import { MultiSelectOption, QueueGetters, QueueActions, QueueFilter } from '@/types';
import { FilterIcon } from '@heroicons/vue/solid';


export default /*@__PURE__*/_defineComponent({
  __name: 'QueueListFilters',
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const filtersOpened = ref(false);

const defaultFilter = computed(() => store.getters[QueueGetters.DEFAULT_FILTER]);

const filter: Ref<QueueFilter> = ref({
    isActive: defaultFilter.value.isActive,
});

const storeFilters = computed<QueueFilter>(() => store.getters[QueueGetters.FILTER]);

const isActiveFilterOptions = [
    {
        label: t('queue.filters.isActive.options.active'),
        value: true,
    },
    {
        label: t('queue.filters.isActive.options.inactive'),
        value: false,
    },
] as MultiSelectOption[];

function applyFilters() {
    store.dispatch(QueueActions.SET_FILTER, filter.value);
}

function clearFilters() {
    store.dispatch(QueueActions.CLEAR_FILTER);
    refreshLocalFilter();
}

function refreshLocalFilter() {
    filter.value.isActive = storeFilters.value.isActive;
}

watch(filtersOpened, () => {
    refreshLocalFilter();
});

const __returned__ = { t, filtersOpened, defaultFilter, filter, storeFilters, isActiveFilterOptions, applyFilters, clearFilters, refreshLocalFilter, get FilterIcon() { return FilterIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})