import { RouteRecordRaw } from 'vue-router';
import {
    validateIdAsPositiveIntegerGuard,
    validateIntegrationTypeGuard,
} from '@/router/routeGuards';
import { FormAction } from '@/types';
import { IntegrationPage, IntegrationLogPage } from '@/views/pages/integration';
import { IntegrationDynamicForm } from '@/views/pages/integration/components';

const integrationRouter: RouteRecordRaw = {
    name: 'Integration',
    path: 'integration',
    children: [
        {
            name: 'ListIntegration',
            path: '',
            component: IntegrationPage,
        },
        {
            name: 'ViewIntegrationLogs',
            path: ':type/:id/logs',
            component: IntegrationLogPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard, validateIntegrationTypeGuard],
            props: (route) => ({
                type: route.params.type,
            }),
            meta: { isLevelLocked: true },
        },
        {
            name: 'CreateIntegration',
            path: ':type/create',
            component: IntegrationDynamicForm,
            beforeEnter: [validateIntegrationTypeGuard],
            props: { action: FormAction.CREATE },
            meta: { isLevelLocked: true },
        },
        {
            name: 'EditIntegrationById',
            path: ':type/:id/edit',
            component: IntegrationDynamicForm,
            beforeEnter: [validateIdAsPositiveIntegerGuard, validateIntegrationTypeGuard],
            props: { action: FormAction.EDIT },
            meta: { isLevelLocked: true },
        },
    ],
};

export default integrationRouter;
