import { UserLabelModel, UserModel } from '@/types';

export interface AgentProductivityReportFilter {
    user?: UserModel | null;
    userLabel?: UserLabelModel | null;
}
export interface AgentProductivityReportState {
    filter: AgentProductivityReportFilter;
}

export enum AgentProductivityReportMutations {
    SET_FILTER = 'agentProductivityReport_setFilter',
    CLEAR_FILTER = 'agentProductivityReport_clearFilter',
}

export enum AgentProductivityReportActions {
    SET_FILTER = 'agentProductivityReport_setFilter',
    CLEAR_FILTER = 'agentProductivityReport_clearFilter',
}

export enum AgentProductivityReportGetters {
    DEFAULT_FILTER = 'agentProductivityReport_defaultFilter',
    FILTER = 'agentProductivityReport_filter',
    ACTIVE_FILTERS = 'agentProductivityReport_activeFilters',
}
