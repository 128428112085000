import { ListFilters, MultiSelectOption } from '@/types';

export interface ConnectFlowAudioFilter {
    isActive: MultiSelectOption | null;
}

export interface ConnectFlowAudioState extends ListFilters {
    filter: ConnectFlowAudioFilter;
}

export enum ConnectFlowAudioMutations {
    SET_SEARCH = 'connectFlowAudio_setSearch',
    SET_SORT = 'connectFlowAudio_setSort',
    SET_PAGE = 'connectFlowAudio_setPage',
    SET_FILTER = 'connectFlowAudio_setFilter',
    CLEAR_FILTER = 'connectFlowAudio_clearFilter',
}

export enum ConnectFlowAudioActions {
    SET_SEARCH = 'connectFlowAudio_setSearch',
    SET_SORT = 'connectFlowAudio_setSort',
    SET_PAGE = 'connectFlowAudio_setPage',
    SET_FILTER = 'connectFlowAudio_setFilter',
    CLEAR_FILTER = 'connectFlowAudio_clearFilter',
}

export enum ConnectFlowAudioGetters {
    SEARCH = 'connectFlowAudio_search',
    SORT = 'connectFlowAudio_sort',
    PAGE = 'connectFlowAudio_page',
    DEFAULT_FILTER = 'connectFlowAudio_defaultFilter',
    FILTER = 'connectFlowAudio_filter',
    ACTIVE_FILTERS = 'connectFlowAudio_activeFilters',
}
