<script lang="ts" setup>
import { PropType } from 'vue';
import { ConnectFlowFlowModel } from '@/types';
import { BookmarkIcon } from '@heroicons/vue/solid';
import ConnectFlowIcon from '@/components/svg/ConnectFlowIcon';

const { modelValue } = defineProps({
    modelValue: {
        type: Object as PropType<ConnectFlowFlowModel>,
        required: true,
    },
    published: {
        type: Boolean,
        default: false,
    },
});
</script>

<template>
    <UiPanel class="flex items-center justify-between hover:bg-white">
        <UiAuditInfo :model-value="modelValue" date-format="LLL" />

        <div v-if="published" class="flex items-center">
            <BookmarkIcon class="text-primary h-6 w-6" />
            {{ $t('connectFlow.labels.published') }}
        </div>
        <UiActionButton
            v-tippy="$t('connectFlow.actions.editFlow')"
            variant="primary"
            :to="{
                name: 'EditFlowHistoryById',
                params: {
                    id: modelValue.connectFlowId,
                    historyId: modelValue.id,
                },
            }"
        >
            <span class="sr-only">
                {{ $t('connectFlow.actions.editFlow') }}
            </span>
            <ConnectFlowIcon class="h-6 w-6" aria-hidden="true" />
        </UiActionButton>
    </UiPanel>
</template>
