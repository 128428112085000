import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "flex-1" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "sr-only" }
const _hoisted_5 = { class: "space-y-2 sm:flex" }
const _hoisted_6 = { class: "space-y-2 sm:flex sm:flex-1 sm:space-x-4" }
const _hoisted_7 = { class: "flex items-center text-sm text-gray-500" }
const _hoisted_8 = { class: "flex items-center text-sm text-gray-500" }
const _hoisted_9 = { class: "flex items-center text-sm text-gray-500" }
const _hoisted_10 = { class: "flex items-center text-sm text-gray-500" }
const _hoisted_11 = { class: "flex items-center text-sm text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiActionButton = _resolveComponent("UiActionButton")!
  const _component_UiAuditInfo = _resolveComponent("UiAuditInfo")!
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock(_component_UiPanel, { class: "space-y-2 hover:bg-white" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can($setup.Permission.VIEW_NUMBER) ? 'RouterLink' : 'span'), {
            to: {
                        name: 'ViewNumberById',
                        params: {
                            id: $props.modelValue.id,
                        },
                    },
            class: "text-md text-primary truncate font-medium"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.applyPhoneMask($props.modelValue.number)), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["to"]))
        ]),
        (_ctx.$can($setup.Permission.EDIT_NUMBER))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _withDirectives((_openBlock(), _createBlock(_component_UiActionButton, {
                variant: "primary",
                onClick: $setup.handleEditClick
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('core.actions.Edit')), 1 /* TEXT */),
                  _createVNode($setup["PencilAltIcon"], {
                    class: "h-6 w-6",
                    "aria-hidden": "true"
                  })
                ]),
                _: 1 /* STABLE */
              })), [
                [_directive_tippy, _ctx.$t('core.actions.Edit')]
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, [
            _createVNode($setup["LocationMarkerIcon"], {
              class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
              "aria-hidden": "true"
            }),
            _createTextVNode(" " + _toDisplayString($props.modelValue.city) + "/" + _toDisplayString($props.modelValue.state), 1 /* TEXT */)
          ]),
          _createElementVNode("p", _hoisted_8, [
            _createVNode($setup["InformationCircleIcon"], {
              class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
              "aria-hidden": "true"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t(`number.types.${$props.modelValue.type}`)), 1 /* TEXT */)
          ]),
          _createElementVNode("p", _hoisted_9, [
            _createVNode($setup["ConnectFlowIcon"], {
              class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
              "aria-hidden": "true"
            }),
            (_openBlock(), _createBlock(_resolveDynamicComponent(
                            _ctx.$can($setup.Permission.VIEW_CONNECT_FLOW) && $props.modelValue.connectFlow?.id
                                ? 'RouterLink'
                                : 'span'
                        ), {
              to: {
                            name: 'EditFlowById',
                            params: {
                                id: $props.modelValue.connectFlow?.id,
                            },
                        },
              class: _normalizeClass([
                            _ctx.$can($setup.Permission.VIEW_CONNECT_FLOW) &&
                                $props.modelValue.connectFlow?.id &&
                                'text-primary font-medium',
                        ])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($props.modelValue.connectFlow?.name || _ctx.$t('number.list.noConnectFlow')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["to", "class"]))
          ]),
          _createElementVNode("p", _hoisted_10, [
            _createVNode($setup["TagIcon"], {
              class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
              "aria-hidden": "true"
            }),
            _createTextVNode(" " + _toDisplayString($props.modelValue.mediaLabel || _ctx.$t('number.list.noMediaLabel')), 1 /* TEXT */)
          ]),
          _createElementVNode("p", _hoisted_11, [
            _createVNode($setup["UsersIcon"], {
              class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
              "aria-hidden": "true"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('number.list.agentCount', $props.modelValue.usersNumber?.length || 0, {
                            named: { n: $props.modelValue.usersNumber?.length },
                        })), 1 /* TEXT */)
          ])
        ]),
        _createVNode(_component_UiAuditInfo, { "model-value": $props.modelValue }, null, 8 /* PROPS */, ["model-value"])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}