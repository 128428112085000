<script lang="ts" setup>
import { computed } from 'vue';
import store from '@/store';
import { ChartBarIcon, CurrencyDollarIcon, PhoneIcon, ReplyIcon } from '@heroicons/vue/solid';
import { Permission, DashboardItem, AuthGetters, UserModel } from '@/types';

const user = computed<UserModel>(() => store.getters[AuthGetters.USER]);

const links: DashboardItem[] = [
    {
        title: 'dashboard.calls',
        description: 'call.description',
        permission: Permission.VIEW_CALLS,
        routeName: 'CallList',
        icon: PhoneIcon,
    },
    {
        title: 'report.call.title',
        description: 'report.call.description',
        permission: Permission.VIEW_CALL_REPORT,
        routeName: 'CallReport',
        icon: ChartBarIcon,
    },
    {
        title: 'report.contactCenterPerDay.title',
        description: 'report.contactCenterPerDay.description',
        permission: Permission.VIEW_CONTACT_CENTER_PER_DAY_REPORT,
        routeName: 'ContactCenterPerDayReport',
        icon: ChartBarIcon,
    },
    {
        title: 'report.contactCenterPerAgent.title',
        description: 'report.contactCenterPerAgent.description',
        permission: Permission.VIEW_CONTACT_CENTER_PER_AGENT_REPORT,
        routeName: 'ContactCenterPerAgentReport',
        icon: ChartBarIcon,
    },
    {
        title: 'report.agentProductivityPerDay.title',
        description: 'report.agentProductivityPerDay.description',
        permission: Permission.VIEW_AGENT_PRODUCTIVITY_PER_DAY_REPORT,
        routeName: 'AgentProductivityPerDayReport',
        icon: ChartBarIcon,
    },
    {
        title: 'report.agentProductivityPerAgent.title',
        description: 'report.agentProductivityPerAgent.description',
        permission: Permission.VIEW_AGENT_PRODUCTIVITY_PER_AGENT_REPORT,
        routeName: 'AgentProductivityPerAgentReport',
        icon: ChartBarIcon,
    },
    {
        title: 'report.realtime.title',
        description: 'report.realtime.description',
        permission: Permission.VIEW_REALTIME_REPORT,
        routeName: 'RealtimeReport',
        icon: ChartBarIcon,
    },
    {
        title: 'report.realtimeAgentExtension.title',
        description: 'report.realtimeAgentExtension.description',
        permission: Permission.VIEW_REALTIME_AGENT_EXTENSION_REPORT,
        routeName: 'RealtimeAgentExtensionReport',
        icon: ChartBarIcon,
    },
    {
        title: 'report.billing.title',
        description: 'report.billing.description',
        permission: Permission.VIEW_BILLING_REPORT,
        routeName: 'BillingReportPage',
        icon: CurrencyDollarIcon,
    },
];
</script>

<template>
    <div class="space-y-4 p-4 sm:px-6 sm:pt-6 lg:px-8 lg:pt-8 xl:pt-0">
        <div class="flex space-x-16">
            <div class="flex flex-1 flex-col space-y-4">
                <h1 class="my-4 text-2xl font-bold text-gray-800 xl:my-8 xl:text-3xl">
                    {{ $t('welcomeLogged') }}, {{ user?.name }}.
                </h1>
                <p class="xl:text-md flex-1 font-medium text-gray-500">
                    {{ $t('dashboard.welcome') }}
                    <span class="text-primary">{{ $t('dashboard.welcomeAct') }}</span>
                </p>
                <h2 class="flex items-center text-xl font-semibold text-gray-700 xl:text-2xl">
                    <ReplyIcon class="text-secondary mr-1 h-8 w-8 rotate-180" aria-hidden="true" />
                    {{ $t('dashboard.gettingStarted') }}
                </h2>
            </div>
            <img
                src="/img/image-home-verde.png"
                class="hidden h-[300px] xl:block"
                :alt="$t('welcomeLogged')"
            />
        </div>

        <div class="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
            <UiDashboardLink
                v-for="(link, index) in links"
                :key="`dashboard-link-${index}`"
                :routeName="link.routeName"
                :permission="link.permission"
                :title="link.title"
                :description="link.description"
                :icon="link.icon"
            />
        </div>
    </div>
</template>
