/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    FilterItem,
    ContactCenterReportState,
    ContactCenterReportMutations,
    ContactCenterReportActions,
    ContactCenterReportGetters,
    ContactCenterReportFilter,
} from '@/types';

const getDefaultState = () => {
    return {
        filter: {
            type: null,
            status: null,
            user: null,
            number: null,
            serviceTimeRange: null,
            userLabel: null,
        },
    } as ContactCenterReportState;
};

const state: ContactCenterReportState = getDefaultState();

const mutations: MutationTree<ContactCenterReportState> = {
    [ContactCenterReportMutations.SET_FILTER](state, payload: ContactCenterReportFilter) {
        state.filter.type = payload.type;
        state.filter.status = payload.status;
        state.filter.user = payload.user;
        state.filter.number = payload.number;
        state.filter.serviceTimeRange = payload.serviceTimeRange;
        state.filter.userLabel = payload.userLabel;
    },
    [ContactCenterReportMutations.CLEAR_FILTER](state, field?: string) {
        switch (field) {
            case 'type':
                state.filter.type = getDefaultState().filter.type;
                break;
            case 'status':
                state.filter.status = getDefaultState().filter.status;
                break;
            case 'user':
                state.filter.user = getDefaultState().filter.user;
                break;
            case 'number':
                state.filter.number = getDefaultState().filter.number;
                break;
            case 'serviceTimeRange':
                state.filter.serviceTimeRange = getDefaultState().filter.serviceTimeRange;
                break;
            case 'userLabel':
                state.filter.userLabel = getDefaultState().filter.userLabel;
                break;
            default:
                state.filter.type = getDefaultState().filter.type;
                state.filter.status = getDefaultState().filter.status;
                state.filter.user = getDefaultState().filter.user;
                state.filter.number = getDefaultState().filter.number;
                state.filter.serviceTimeRange = getDefaultState().filter.serviceTimeRange;
                state.filter.userLabel = getDefaultState().filter.userLabel;
        }
    },
};

const actions: ActionTree<ContactCenterReportState, any> = {
    [ContactCenterReportActions.SET_FILTER]: ({ commit }, filter: ContactCenterReportFilter) =>
        commit(ContactCenterReportMutations.SET_FILTER, filter),
    [ContactCenterReportActions.CLEAR_FILTER]: ({ commit }, filter: ContactCenterReportFilter) =>
        commit(ContactCenterReportMutations.CLEAR_FILTER, filter),
};

const getters: GetterTree<ContactCenterReportState, any> = {
    [ContactCenterReportGetters.DEFAULT_FILTER]: (): ContactCenterReportFilter =>
        getDefaultState().filter,
    [ContactCenterReportGetters.FILTER]: (state): ContactCenterReportFilter => state.filter,
    [ContactCenterReportGetters.ACTIVE_FILTERS](state): FilterItem[] {
        const filters: Array<FilterItem> = [];

        if (state.filter.type)
            filters.push({
                field: 'type',
                value: state.filter.type.value,
                label: state.filter.type.label,
            });

        if (state.filter.status)
            filters.push({
                field: 'status',
                value: state.filter.status.value,
                label: state.filter.status.label,
            });

        if (state.filter.user)
            filters.push({
                field: 'user',
                value: state.filter.user.id,
                label: state.filter.user.name,
            });

        if (state.filter.number)
            filters.push({
                field: 'number',
                value: state.filter.number.id,
                label: state.filter.number.number,
            });

        if (state.filter.serviceTimeRange)
            filters.push({
                field: 'serviceTimeRange',
                value: state.filter.serviceTimeRange.value,
                label: state.filter.serviceTimeRange.label,
            });

        if (state.filter.userLabel)
            filters.push({
                field: 'userLabel',
                value: state.filter.userLabel.id,
                label: state.filter.userLabel.label,
            });

        return filters;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
