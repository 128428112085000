<script lang="ts" setup>
import { ref, computed, onMounted, type Ref } from 'vue';
import { JSX } from 'vue/jsx-runtime';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import {
    TemplateHeader,
    TemplateSidebar,
    TemplateNavigation,
    TemplateProfile,
} from '@/views/templates/components';

import { LevelModel, NavigationItem, AuthGetters } from '@/types';
import LevelService from '@/services/api/LevelService';
import { toastServiceError } from '@/services/core/notification';

defineProps<{
    navigationItems: NavigationItem[];
    headerActions?: () => JSX.Element;
    title?: string;
    routerKey?: string;
}>();

const uiStore = useUiStore();
const filterStore = useFilterStore();

const levels: Ref<LevelModel[]> = ref([]);

const logoPath = computed(() => {
    const company = store.getters[AuthGetters.USER_COMPANY];
    return company?.templateLogo || '/img/logo-mob.png';
});

const loading = computed(() => uiStore.isLoading);

const isLevelLocked = computed(() => uiStore.isLevelLocked);

const levelFilter = computed<LevelModel | null>({
    get: () => filterStore.level,
    set: (level: LevelModel) => filterStore.setLevel(level),
});

const headerClass = computed(() => {
    if (process.env.NODE_ENV === 'production') return 'bg-slate-100';
    if (process.env.NODE_ENV === 'development') return 'bg-teal-700';
    return 'bg-gray-700';
});

onMounted(async () => {
    try {
        const response = await LevelService.getAllGlobalSelect<LevelModel[]>();
        levels.value = response.data;
    } catch (error) {
        toastServiceError(error);
    }
});
</script>

<template>
    <div class="flex h-screen w-full">
        <TemplateSidebar>
            <div class="bg-primary flex h-full flex-col overflow-y-auto sm:w-60 md:fixed">
                <div class="flex flex-1 flex-col overflow-y-auto">
                    <router-link :to="{ name: 'Home' }">
                        <img class="mb-8 ml-4 mt-3 h-12" :src="logoPath" />
                    </router-link>
                    <TemplateNavigation :items="navigationItems" />
                </div>
                <TemplateProfile />
            </div>
        </TemplateSidebar>
        <div class="w-full md:pl-60">
            <div class="flex flex-col">
                <div
                    :class="headerClass"
                    class="sticky top-0 z-30 flex items-center p-2 shadow sm:px-4 sm:py-2"
                >
                    <header class="flex w-full items-center">
                        <TemplateHeader>
                            <div class="grid w-full grid-cols-2 gap-4 md:grid-cols-5">
                                <UiMultiselect
                                    name="template_level"
                                    label="name"
                                    value-prop="id"
                                    v-model="levelFilter"
                                    :options="levels"
                                    class="col-span-2"
                                    :disabled="isLevelLocked"
                                />
                            </div>
                            <div class="flex items-center pl-2 text-gray-50">
                                <slot name="headerActions"></slot>
                            </div>
                        </TemplateHeader>
                    </header>
                </div>
                <main>
                    <router-view :key="routerKey"></router-view>
                </main>
            </div>
            <UiLoadingDialog :show="loading" :text="$t('ui-loading-dialog-text')" />
        </div>
    </div>
</template>
