import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, ref, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';

import { useFilterStore, useUiStore } from '@/stores';
import { can } from '@/composables/useAuth';
import { goBack } from '@/composables/useNavigation';

import {
    required,
    minLength,
    maxLength,
    email,
    sameAs,
    containsUppercase,
    containsLowercase,
    containsNumber,
    containsSpecial,
} from '@/utils/validators';

import { toast, toastServiceError } from '@/services/core/notification';
import { UserService, LevelService, RoleService, NumberService } from '@/services';

import {
    DefaultServiceResult,
    FormAction,
    LevelModel,
    NumberModel,
    Permission,
    RoleModel,
    UserFormState,
    UserModel,
    UserServiceCreateResult,
} from '@/types';

import { DesktopComputerIcon, DeviceMobileIcon } from '@heroicons/vue/solid';
import SVGDeviceExternal from '@/components/svg/SVGDeviceExternal.vue';
import SVGChromeLogo from '@/components/svg/SVGChromeLogo.vue';
import UserFormExtension from '@/views/pages/user/components/UserFormExtension.vue';
import UserFormDevice from '@/views/pages/user/components/UserFormDevice.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserFormPage',
  props: {
    action: { type: String, default: 'view' },
},
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const { t } = useI18n();
const filterStore = useFilterStore();
const uiStore = useUiStore();

const props = __props;

const formState = reactive<UserFormState>({
    name: null,
    email: '',
    levels: [],
    roles: [],
    isActive: true,
    hasMeeting: false,
    standardLevelId: null,
    devices: {
        mobile: false,
        desktop: false,
        web: false,
        external: false,
    },
    useSmartCallerId: false,
    number: {
        numberId: null,
    },
    extension: {
        id: null,
        levelId: null,
        label: null,
        number: {
            numberId: null,
        },
    },
});

const isLoading = ref(false);
const externalConfig = ref();
const numbersRaw = ref<NumberModel[]>([]);
const levels = ref<LevelModel[]>([]);
const roles = ref<RoleModel[]>([]);

const levelFilter = computed<LevelModel | null>(() => filterStore.level);
const levelHasMeeting = computed(() => levelFilter.value?.meeting || false);
const noDevice = computed(() => Object.values(formState.devices).every((v) => !v));

const selectedLevels = computed(() =>
    levels.value.filter((lvl) => formState.levels.includes(lvl.id)),
);

const rules = computed(() => {
    const r = {
        name: {
            required,
            maxLength: maxLength(500),
        },
        email: {
            required,
            email,
            maxLength: maxLength(250),
        },
        levels: { required },
        roles: { required },
        standardLevelId: { required },
        isActive: { required },
        ...(props.action === FormAction.CREATE && {
            password: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(250),
                containsUppercase,
                containsLowercase,
                containsNumber,
                containsSpecial,
            },
            confirmPassword: {
                required,
                sameAsPassword: sameAs(formState.password, t('user.labels.password')),
            },
        }),
    };

    return r;
});

const v$ = useVuelidate(rules, formState);

const hasSmartCallerId = computed(() =>
    formState.standardLevelId
        ? getLevelNumbers(formState.standardLevelId).some((n) => n.isSmartCallerId)
        : false,
);

const setLoading = (loading: boolean) => {
    isLoading.value = loading;
    uiStore.setIsLoading(loading);
};

const fetchLevels = async () => {
    try {
        const response = await LevelService.getAll<LevelModel[]>({ params: { is_active: true } });
        levels.value = response.data ?? [];

        if (levelFilter.value && !levelFilter.value.root) {
            levels.value = [levelFilter.value];
            formState.levels = [levelFilter.value.id];
            formState.standardLevelId = levelFilter.value.id;
        }
    } catch (error) {
        toastServiceError(error);
    }
};

const fetchRoles = async () => {
    try {
        const response = await RoleService.getAll<RoleModel[]>();
        roles.value = response.data ?? [];
    } catch (error) {
        toastServiceError(error);
    }
};

const fetchUser = async (id: number) => {
    setLoading(true);
    try {
        const response = await UserService.get<UserModel>(id);
        const {
            name,
            email,
            levels,
            roles,
            isActive,
            standardLevelId,
            hasMeeting,
            number,
            extension,
            devices,
            deviceExternal,
            useSmartCallerId,
        } = response.data;

        Object.assign(formState, {
            id,
            name,
            email,
            levels: levels?.map((l) => l.id) || [],
            roles: roles?.map((l) => l.id) || [],
            isActive,
            standardLevelId: standardLevelId ?? null,
            hasMeeting,
            devices,
            useSmartCallerId: useSmartCallerId || false,
            number: number ?? { id: null, levelId: null, numberId: null },
            extension: extension ?? {
                id: null,
                levelId: standardLevelId,
                label: null,
                number: null,
            },
        });

        if (deviceExternal) {
            externalConfig.value = {
                hostname: deviceExternal.hostname,
                sipUser: deviceExternal.sipUser,
                sipPassword: deviceExternal.sipPassword,
            };
        }
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const fetchNumbers = async () => {
    setLoading(true);
    try {
        const response = await NumberService.getAll<NumberModel[]>({
            params: { levels: formState.levels },
        });
        numbersRaw.value = response.data ?? [];
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const getLevelNumbers = (levelID: number | null) => {
    return numbersRaw.value.filter((n) => n.level?.id === levelID);
};

const onSubmit = async () => {
    const isValid = await v$.value.$validate();
    if (!isValid) return;

    setLoading(true);
    try {
        const id = Number(route.params.id);
        const serviceCall =
            props.action === FormAction.EDIT
                ? UserService.update<DefaultServiceResult, UserFormState>(id, formState)
                : UserService.create<UserServiceCreateResult, UserFormState>(formState);

        const response = await serviceCall;
        toast.success(response.data.message);
        v$.value.$reset();
        goBack({ name: 'ViewUserById', params: { id } });
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

watch(
    () => formState.levels,
    () => {
        fetchNumbers();
    },
);

watch(
    () => formState.standardLevelId,
    (newValue) => {
        if (formState.number) formState.number.levelId = newValue;
        if (formState.extension) formState.extension.levelId = newValue;
    },
);

watch(
    () => formState.useSmartCallerId,
    (newValue) => {
        if (newValue && formState.number) formState.number.numberId = null;
    },
);

onMounted(() => {
    if (can(Permission.ASSIGN_LEVEL_TO_USER)) fetchLevels();
    if (can(Permission.ASSIGN_ROLE_TO_USER)) fetchRoles();
    if (props.action === FormAction.EDIT) fetchUser(Number(route.params.id));
});

const __returned__ = { route, t, filterStore, uiStore, props, formState, isLoading, externalConfig, numbersRaw, levels, roles, levelFilter, levelHasMeeting, noDevice, selectedLevels, rules, v$, hasSmartCallerId, setLoading, fetchLevels, fetchRoles, fetchUser, fetchNumbers, getLevelNumbers, onSubmit, get FormAction() { return FormAction }, get Permission() { return Permission }, get DesktopComputerIcon() { return DesktopComputerIcon }, get DeviceMobileIcon() { return DeviceMobileIcon }, SVGDeviceExternal, SVGChromeLogo, UserFormExtension, UserFormDevice }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})