import { RouteRecordRaw } from 'vue-router';
import { validateIdAsPositiveIntegerGuard } from '@/router/routeGuards';
import CallPage from '@/views/pages/call/CallPage.vue';
import CallDetailPage from '@/views/pages/call/CallDetailPage.vue';

const levelRouter: RouteRecordRaw = {
    name: 'Calls',
    path: '/calls',
    children: [
        {
            name: 'CallList',
            path: ':tab?',
            component: CallPage,
        },
        {
            name: 'ViewCallById',
            path: 'player/:id',
            component: CallDetailPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            meta: { isLevelLocked: true },
        },
    ],
};
export default levelRouter;
