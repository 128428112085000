<script lang="ts" setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import ConnectFlowSurveyService from '@/services/api/ConnectFlowSurveyService';
import { toast, toastServiceError } from '@/services/core/notification';

import {
    FilterItem,
    SortMenuItem,
    ConnectFlowSurveyModel,
    ConnectFlowSurveyFilter,
    ConnectFlowSurveyServiceGetAllParams,
    Permission,
    ConnectFlowSurveyActions,
    ConnectFlowSurveyGetters,
} from '@/types';

import {
    ConnectFlowSurveyListItem,
    ConnectFlowSurveyListSort,
    ConnectFlowSurveyListFilters,
} from '@/views/pages/connect-flow-survey/components';

import { OfficeBuildingIcon } from '@heroicons/vue/solid';

const route = useRoute();
const router = useRouter();

const filterStore = useFilterStore();
const uiStore = useUiStore();

const surveys = ref<ConnectFlowSurveyModel[]>([]);
const count = ref(0);
const perPage = 10;

const currentPage = computed({
    get: () => store.getters[ConnectFlowSurveyGetters.PAGE],
    set: (value: number) => store.dispatch(ConnectFlowSurveyActions.SET_PAGE, value),
});

const search = computed({
    get: () => store.getters[ConnectFlowSurveyGetters.SEARCH],
    set: (value: string) => store.dispatch(ConnectFlowSurveyActions.SET_SEARCH, value),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[ConnectFlowSurveyGetters.SORT],
    set: (value: SortMenuItem[]) => store.dispatch(ConnectFlowSurveyActions.SET_SORT, value),
});

const levelFilter = computed(() => filterStore.level);

const filter: ConnectFlowSurveyFilter = store.getters[ConnectFlowSurveyGetters.FILTER];

const activeFilters = computed<FilterItem[]>(
    () => store.getters[ConnectFlowSurveyGetters.ACTIVE_FILTERS],
);

const orderBy = computed(() => sortMenuSelected.value.map((s) => s.direction + s.key).join(','));

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getParams = (): ConnectFlowSurveyServiceGetAllParams => ({
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    is_active: filter.isActive !== null ? (filter.isActive.value as boolean) : undefined,
    search: search.value,
});

const fetchConnectFlowSurveys = async () => {
    await setLoading(true);
    try {
        const { data, headers } = await ConnectFlowSurveyService.getAll<ConnectFlowSurveyModel[]>({
            params: getParams(),
        });
        surveys.value = data;
        count.value = parseInt(headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const setIsActive = async (item: ConnectFlowSurveyModel, isActive: boolean) => {
    await setLoading(true);
    try {
        const { data } = await (
            isActive
                ? (id: number) => ConnectFlowSurveyService.activate(id)
                : (id: number) => ConnectFlowSurveyService.inactivate(id)
        )(item.id);
        toast.success(data.message);
        await fetchConnectFlowSurveys();
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const handleActivateClick = (item: ConnectFlowSurveyModel) => setIsActive(item, true);
const handleInactivateClick = (item: ConnectFlowSurveyModel) => setIsActive(item, false);

const handleRemoveSortClick = (item: SortMenuItem) => {
    const index = sortMenuSelected.value.indexOf(item);
    if (index > -1) sortMenuSelected.value.splice(index, 1);
};

const handleRemoveFilterClick = (item: FilterItem) => {
    store.dispatch(ConnectFlowSurveyActions.CLEAR_FILTER, item);
};

watch([currentPage], () => {
    fetchConnectFlowSurveys();
});

watch([levelFilter, search, filter, orderBy], () => {
    if (currentPage.value === 1) fetchConnectFlowSurveys();
    else currentPage.value = 1;
});

if (route.query.clearStateOnSetup) {
    search.value = '';
    sortMenuSelected.value = [];
    currentPage.value = 1;
    router.replace({ query: {} });
}

onMounted(fetchConnectFlowSurveys);
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="flex items-center text-xl font-semibold text-gray-800">
                    <OfficeBuildingIcon class="mr-1 h-6 w-6 text-gray-500" aria-hidden="true" />
                    {{ $t('core.entity.ConnectFlowSurveys') }}
                </h1>
            </template>
            <template #actions>
                <router-link
                    v-if="$can(Permission.CREATE_CONNECT_FLOW_SURVEY)"
                    :to="{ name: 'CreateConnectFlowSurvey' }"
                >
                    <UiButton variant="primary" text-variant="white">
                        {{ $t('core.actions.Add') }}
                    </UiButton>
                </router-link>
            </template>
        </UiPageHeader>

        <div class="space-y-2">
            <UiPanel class="flex px-4 py-2" no-padding>
                <UiListSearch
                    v-model="search"
                    :placeholder="$t('connectFlowSurvey.filters.search')"
                    class="flex-1"
                />
                <div class="flex items-center space-x-4">
                    <ConnectFlowSurveyListSort v-model="sortMenuSelected" />
                    <ConnectFlowSurveyListFilters />
                </div>
            </UiPanel>

            <UiPanel v-if="sortMenuSelected.length || activeFilters.length" class="space-x-2">
                <UiSortMenuBadge
                    v-for="selected in sortMenuSelected"
                    :key="selected.key"
                    :item="selected"
                    @remove="handleRemoveSortClick(selected)"
                />
                <UiBadge
                    v-for="item in activeFilters"
                    :key="`activeFilter${item.field}`"
                    removable
                    @remove="handleRemoveFilterClick(item)"
                >
                    {{ $t(`connectFlowSurvey.filters.${item.field}.label`) }}: {{ item.label }}
                </UiBadge>
            </UiPanel>

            <div v-if="surveys.length" class="space-y-2">
                <ConnectFlowSurveyListItem
                    v-for="survey in surveys"
                    :key="survey.id"
                    :model-value="survey"
                    @activate="handleActivateClick"
                    @inactivate="handleInactivateClick"
                />
            </div>
            <UiPanel v-else class="flex items-center justify-center">
                {{ $t('core.messages.noRecordsFound') }}
            </UiPanel>

            <UiPagination
                v-if="surveys.length"
                v-model="currentPage"
                :count="count"
                :perPage="perPage"
            />
        </div>
    </div>
</template>
