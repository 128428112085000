<script lang="ts" setup>
import { computed, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import VueDatePicker from '@vuepic/vue-datepicker';

import { useUiStore } from '@/stores';

const props = defineProps({
    modelValue: {
        type: [Date, Array<Date>, String, Array<string>] as PropType<
            Date | Array<Date> | string | Array<string>
        >,
    },
    modelType: {
        type: String,
    },
    range: {
        type: Boolean,
        default: false,
    },
    partialRange: {
        type: Boolean,
        default: false,
    },
    multiCalendars: {
        type: Boolean,
        default: false,
    },
    autoApply: {
        type: Boolean,
        default: true,
    },
    enableTimePicker: {
        type: Boolean,
        default: false,
    },
    clearable: {
        type: Boolean,
        default: false,
    },
    utc: {
        type: [Boolean, String] as PropType<boolean | 'preserve'>,
        default: 'preserve',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    readonly: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:modelValue']);

const uiStore = useUiStore();
const { t } = useI18n();

const currentLocale = computed(() => uiStore.currentLocale);

const format = (selected: Date | Date[]) => {
    let response = '';
    if (Array.isArray(selected)) {
        const firstDate = moment(selected[0]);
        const secondDate = moment(selected[1]);
        response = `${firstDate.format(t('core.dateFormat'))} - ${secondDate.format(t('core.dateFormat'))}`;
    } else {
        const date = moment(selected);
        response = `${date.format(t('core.dateFormat'))}`;
        response = `Selected coisa`;
    }
    return response;
};

const updateValue = (value: string) => {
    emit('update:modelValue', value);
};
</script>

<template>
    <VueDatePicker
        :model-value="props.modelValue"
        :model-type="props.modelType"
        :range="props.range"
        :partialRange="props.partialRange"
        :multiCalendars="props.multiCalendars"
        :autoApply="props.autoApply"
        :enableTimePicker="props.enableTimePicker"
        :clearable="props.clearable"
        :utc="props.utc"
        :disabled="props.disabled"
        :readonly="props.readonly"
        :locale="currentLocale"
        :format="format"
        :max-date="new Date()"
        @update:modelValue="updateValue"
    />
</template>

<style>
.dp__main .dp__input_wrap .dp__input,
.dp__main.dp__theme_light .dp__input_wrap .dp__input {
    @apply rounded border-none bg-white py-2 text-sm shadow ring-1 ring-gray-300 focus:outline-none focus:ring-2 focus:ring-opacity-50;
}
</style>
