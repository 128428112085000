import { defineComponent as _defineComponent } from 'vue'
import { computed, useSlots } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiBadge',
  props: {
    removable: {
        type: Boolean,
        default: false,
    },
    variant: {
        type: String,
        default: 'gray',
    },
    textVariant: {
        type: String,
    },
    size: {
        type: String,
        default: 'md',
    },
    disabled: Boolean,
},
  emits: ['remove'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const variantExceptions = ['inherit', 'current', 'transparent', 'black', 'white'];
const getVariantSuffix = (variant: string, darker = 400) => {
    let variantColor: string = variant;
    let variantIntensity: number | undefined = undefined;

    if (!variantExceptions.includes(variant)) {
        const variantContainsIntensity = variant.lastIndexOf('-') != -1;

        if (variantContainsIntensity) {
            variantIntensity = parseInt(
                variant.substring(variant.lastIndexOf('-') + 1, variant.length),
            );

            variantColor = variant.substring(0, variant.lastIndexOf('-'));
        } else variantIntensity = 200;
    }

    const getSuffix = (intensity: number | undefined, variation: number) => {
        if (intensity == undefined) return '';
        else {
            let suffix = intensity + variation;
            if (suffix > 900) suffix = 900;
            else if (suffix < 50) suffix = 50;

            return '-' + suffix;
        }
    };

    return {
        default: variantColor + getSuffix(variantIntensity, 0),
        darker: variantColor + getSuffix(variantIntensity, darker),
        lighter: variantColor + getSuffix(variantIntensity, -100),
    };
};

const textVariant = computed(() =>
    props.textVariant !== undefined ? props.textVariant : props.variant,
);

const classes = computed(() => {
    let sizes: string[] = [];

    switch (props.size) {
        case 'xs':
            sizes = ['px-1', 'py-1', 'text-xs'];
            break;
        case 'sm':
            sizes = ['px-2.5', 'py-1.5', 'text-xs'];
            break;
        case 'md':
            sizes = ['px-3', 'py-2', 'text-sm'];
            break;
        case 'lg':
            sizes = ['px-4', 'py-2', 'text-sm'];
            break;
        case 'xl':
            sizes = ['px-4', 'py-2', 'text-base'];
            break;
        case '2xl':
            sizes = ['px-6', 'py-3', 'text-base'];
            break;
        default:
            break;
    }

    const variantWithSuffixes = getVariantSuffix(props.variant);
    const textVariantWithSuffixes = getVariantSuffix(textVariant.value);

    let classes: (string | object)[] = [
        `bg-${variantWithSuffixes.default}`,
        `text-${textVariantWithSuffixes.darker}`,
        {
            disabled: props.disabled,
        },
    ];

    classes = classes.concat(sizes);

    return classes;
});

const numberClasses = computed(() => {
    const variantWithSuffixes = getVariantSuffix(props.variant, 100);
    const textVariantWithSuffixes = getVariantSuffix(textVariant.value);

    return `bg-${variantWithSuffixes.darker} text-${textVariantWithSuffixes.darker}`;
});

const emit = __emit;
const slots = useSlots();

const __returned__ = { props, variantExceptions, getVariantSuffix, textVariant, classes, numberClasses, emit, slots }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})