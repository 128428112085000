import { defineComponent as _defineComponent } from 'vue'
import { DownloadIcon } from '@heroicons/vue/solid';
import { VaultService } from '@/services';
import AnalyticsService from '@/services/analytics/AnalyticsService';


export default /*@__PURE__*/_defineComponent({
  __name: 'CallAudioDownloadButton',
  props: {
    callId: {
        type: Number,
        required: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();



async function downloadCallAudio(callId: number): Promise<void> {
    try {
        if (!callId) return;

        AnalyticsService.trackingAction('CallAudioDownload');

        // Fetch audio data from VaultService
        const { data } = await VaultService.getCallAudio(callId);

        // Create blob and object URL
        const blob = new Blob([data], { type: 'audio/mpeg' });
        const url = URL.createObjectURL(blob);

        // Create download link
        const link = document.createElement('a');
        link.href = url;
        link.download = `${callId}.mp3`;
        document.body.appendChild(link);
        link.click();

        // Cleanup
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
    } catch (error) {
        console.error('Error downloading call audio:', error);
    }
}

const __returned__ = { downloadCallAudio, get DownloadIcon() { return DownloadIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})