import { RouteRecordRaw } from 'vue-router';
import { validateIdAsPositiveIntegerGuard } from '@/router/routeGuards';
import { FormAction } from '@/types';
import QueuePage from '@/views/pages/queue/QueuePage.vue';
import QueueDetailPage from '@/views/pages/queue/QueueDetailPage.vue';
import QueueFormPage from '@/views/pages/queue/QueueFormPage.vue';

const queueRouter: RouteRecordRaw = {
    name: 'Queue',
    path: 'queue',
    children: [
        {
            name: 'ListQueue',
            path: '',
            component: QueuePage,
        },
        {
            name: 'ViewQueueById',
            path: ':id',
            component: QueueDetailPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            meta: { isLevelLocked: true },
        },
        {
            name: 'EditQueueById',
            path: ':id/edit',
            component: QueueFormPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            props: { action: FormAction.EDIT },
            meta: { isLevelLocked: true },
        },
        {
            name: 'CreateQueue',
            path: 'create',
            component: QueueFormPage,
            props: { action: FormAction.CREATE },
            meta: { isLevelLocked: true },
        },
    ],
};
export default queueRouter;
