export interface ConnectFlowSurveyFormState {
    name: string | null;
    textToAudio: string | null;
    audioFilePath: string | null;
    questions: ConnectFlowSurveyQuestionFormState[];
    isActive: boolean;
}

export interface ConnectFlowSurveyQuestionFormState {
    id?: number;
    label: string | null;
    rangeAnswer: string | null;
    textToAudio: string | null;
    audioFile: File | null;
    audioFilePath: string | null;
}
