import BaseService from '@/services/api/BaseService';
import { AxiosRequestConfig } from 'axios';
import {
    AgentDeviceStatusHistoryReportServiceGetInfoParams,
    AgentDeviceStatusHistoryReportServiceGetInfoResult,
} from '@/types/services/AgentDeviceStatusHistoryReportService';

class AgentDeviceStatusHistoryReportService extends BaseService<AgentDeviceStatusHistoryReportServiceGetInfoParams> {
    getInfo(config?: AxiosRequestConfig) {
        return this.http.get<AgentDeviceStatusHistoryReportServiceGetInfoResult>(
            `${this.route}`,
            config,
        );
    }
}

export default new AgentDeviceStatusHistoryReportService('reports/agent-devices-status-history');
