import { defineComponent as _defineComponent } from 'vue'
import { PlusIcon } from '@heroicons/vue/solid';
import { PropType } from 'vue';
import { ConnectFlowModelingActions, VueFlowNodeModel } from '@/types';
import store from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'OpenEdge',
  props: {
    node: {
        type: Object as PropType<VueFlowNodeModel>,
        required: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

function openComponentSelector() {
    store.dispatch('flow/' + ConnectFlowModelingActions.SET_MENU, {
        show: true,
        action: 'update',
        idTriggerBy: props.node.id,
    });
}

const __returned__ = { props, openComponentSelector, get PlusIcon() { return PlusIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})