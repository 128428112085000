export { default as BaseService } from '@/services/api/BaseService';

export { default as AgentProductivityPerAgentReportService } from '@/services/api/AgentProductivityPerAgentReportService';
export { default as AgentProductivityPerDayReportService } from '@/services/api/AgentProductivityPerDayReportService';
export { default as AnalyticsService } from '@/services/analytics/AnalyticsService';
export { default as AuthService } from '@/services/api/AuthService';
export { default as BillingReportService } from '@/services/api/BillingReportService';
export { default as CallReportService } from '@/services/api/CallReportService';
export { default as CallService } from '@/services/api/CallService';
export { default as ConnectFlowAudioService } from '@/services/api/ConnectFlowAudioService';
export { default as ConnectFlowService } from '@/services/api/ConnectFlowService';
export { default as ConnectFlowSurveyService } from '@/services/api/ConnectFlowSurveyService';
export { default as ContactCenterPerAgentReportService } from '@/services/api/ContactCenterPerAgentReportService';
export { default as ContactCenterPerDayReportService } from '@/services/api/ContactCenterPerDayReportService';
export { default as ContactService } from '@/services/api/ContactService';
export { default as IntegrationService } from '@/services/api/IntegrationService';
export { default as LevelService } from '@/services/api/LevelService';
export { default as MeetingService } from '@/services/api/MeetingService';
export { default as NumberService } from '@/services/api/NumberService';
export { default as PermissionService } from '@/services/api/PermissionService';
export { default as QueueService } from '@/services/api/QueueService';
export { default as QueueTimeReportService } from '@/services/api/QueueTimeReportService';
export { default as RealtimeReportService } from '@/services/api/RealtimeReportService';
export { default as RoleService } from '@/services/api/RoleService';
export { default as ScheduleService } from '@/services/api/ScheduleService';
export { default as SelectOptionsService } from '@/services/api/SelectOptionsService';
export { default as SpamNumberService } from '@/services/api/SpamNumberService';
export { default as UserLabelService } from '@/services/api/UserLabelService';
export { default as UserService } from '@/services/api/UserService';
export { default as VaultService } from '@/services/api/VaultService';
export { default as WordService } from '@/services/api/WordService';
