import { UserModel, AuthServiceLoginBody, AuthServiceLoginResult } from '@/types';
import BaseService from '@/services/api/BaseService';

class AuthService extends BaseService<UserModel> {
    constructor() {
        super('');
    }

    async login(loginBody: AuthServiceLoginBody) {
        return this.http.post<AuthServiceLoginResult>('auth/login', loginBody);
    }

    async check() {
        return this.http.get<AuthServiceLoginResult>('auth/check');
    }
}

export default new AuthService();
