import { ContactCenterPerDayReportServiceGetInfoResult } from '@/types';
import BaseService from '@/services/api/BaseService';
import { AxiosRequestConfig } from 'axios';

class ContactCenterPerDayReportService extends BaseService<ContactCenterPerDayReportServiceGetInfoResult> {
    getInfo(config?: AxiosRequestConfig) {
        return this.http.get<ContactCenterPerDayReportServiceGetInfoResult>(
            `${this.route}`,
            config,
        );
    }
}

export default new ContactCenterPerDayReportService('reports/contact-center-per-day');
