import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { PhoneIcon } from '@heroicons/vue/solid';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import { NumberService } from '@/services';
import { toastServiceError } from '@/services/core/notification';

import {
    FilterItem,
    SortMenuItem,
    NumberModel,
    NumberFilter,
    NumberServiceGetAllParams,
    NumberActions,
    NumberGetters,
} from '@/types';

import { NumberListItem, NumberListSort, NumberListFilters } from '@/views/pages/number/components';

const perPage = 10;


export default /*@__PURE__*/_defineComponent({
  __name: 'NumberPage',
  setup(__props, { expose: __expose }) {
  __expose();

const numbers = ref<NumberModel[]>([]);
const count = ref(0);
const search = computed({
    get: () => store.getters[NumberGetters.SEARCH],
    set: (value: string) => store.dispatch(NumberActions.SET_SEARCH, value),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[NumberGetters.SORT],
    set: (value: SortMenuItem[]) => store.dispatch(NumberActions.SET_SORT, value),
});

const levelFilter = computed(() => filterStore.level);

const currentPage = computed({
    get: () => store.getters[NumberGetters.PAGE],
    set: (value: number) => store.dispatch(NumberActions.SET_PAGE, value),
});

const filter: NumberFilter = store.getters[NumberGetters.FILTER];

const activeFilters = computed<FilterItem[]>(() => store.getters[NumberGetters.ACTIVE_FILTERS]);

const orderBy = computed(() => sortMenuSelected.value.map((s) => s.direction + s.key).join(','));

const route = useRoute();
const router = useRouter();
const filterStore = useFilterStore();
const uiStore = useUiStore();

watch([currentPage], () => {
    fetchNumbers();
});

watch([levelFilter, search, filter, orderBy], () => {
    if (currentPage.value === 1) fetchNumbers();
    else currentPage.value = 1;
});

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getParams = (): NumberServiceGetAllParams => ({
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    hasConnectFlow:
        filter.hasConnectFlow !== null ? (filter.hasConnectFlow.value as boolean) : undefined,
    state: filter.state !== null ? (filter.state.value as string) : undefined,
    city: filter.city !== null ? (filter.city.value as string) : undefined,
    search: search.value,
});

const fetchNumbers = async () => {
    setLoading(true);
    try {
        const { data, headers } = await NumberService.getAll<NumberModel[]>({
            params: getParams(),
        });
        numbers.value = data;
        count.value = parseInt(headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveSortClick = (item: SortMenuItem) => {
    const index = sortMenuSelected.value.indexOf(item);
    if (index > -1) sortMenuSelected.value.splice(index, 1);
};

const handleRemoveFilterClick = (item: FilterItem) => {
    store.dispatch(NumberActions.CLEAR_FILTER, item);
};

onMounted(() => {
    if (route.query.clearStateOnSetup) {
        search.value = '';
        sortMenuSelected.value = [];
        currentPage.value = 1;
        router.replace({ query: {} });
    }

    fetchNumbers();
});

const __returned__ = { numbers, count, perPage, search, sortMenuSelected, levelFilter, currentPage, filter, activeFilters, orderBy, route, router, filterStore, uiStore, setLoading, getParams, fetchNumbers, handleRemoveSortClick, handleRemoveFilterClick, get PhoneIcon() { return PhoneIcon }, get NumberListItem() { return NumberListItem }, get NumberListSort() { return NumberListSort }, get NumberListFilters() { return NumberListFilters } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})