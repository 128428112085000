import authenticationPtBr from '@/locales/pt-BR/authentication.json';
import callPtBr from '@/locales/pt-BR/call.json';
import connectFlowAudioPtBr from '@/locales/pt-BR/connectFlowAudio.json';
import connectFlowPtBr from '@/locales/pt-BR/connectFlow.json';
import connectFlowSurveyPtBr from '@/locales/pt-BR/connectFlowSurvey.json';
import contactPtBr from '@/locales/pt-BR/contact.json';
import corePtBr from '@/locales/pt-BR/core.json';
import dashboardPtBr from '@/locales/pt-BR/dashboard.json';
import filterPtBr from '@/locales/pt-BR/filter.json';
import forgotPasswordPtBr from '@/locales/pt-BR/forgotPassword.json';
import integrationPtBr from '@/locales/pt-BR/integration.json';
import levelPtBr from '@/locales/pt-BR/level.json';
import loginPtBr from '@/locales/pt-BR/login.json';
import meetingPtBr from '@/locales/pt-BR/meeting.json';
import numberPtBr from '@/locales/pt-BR/number.json';
import paginationPtBr from '@/locales/pt-BR/pagination.json';
import permissionPtBr from '@/locales/pt-BR/permission.json';
import queuePtBr from '@/locales/pt-BR/queue.json';
import reportPtBr from '@/locales/pt-BR/report.json';
import rolePtBr from '@/locales/pt-BR/role.json';
import schedulePtBr from '@/locales/pt-BR/schedule.json';
import sideNavigationPtBr from '@/locales/pt-BR/sideNavigation.json';
import sortPtBr from '@/locales/pt-BR/sort.json';
import spamNumberPtBr from '@/locales/pt-BR/spamNumber.json';
import uiLoadingDialogTextPtBr from '@/locales/pt-BR/uiLoadingDialogText.json';
import userLabelPtBr from '@/locales/pt-BR/userLabel.json';
import userPtBr from '@/locales/pt-BR/user.json';
import validationsPtBr from '@/locales/pt-BR/validations.json';
import welcomeLoggedPtBr from '@/locales/pt-BR/welcomeLogged.json';
import welcomePtBr from '@/locales/pt-BR/welcome.json';
import wordPtBr from '@/locales/pt-BR/word.json';

export const ptBR = {
    ...authenticationPtBr,
    ...callPtBr,
    ...connectFlowAudioPtBr,
    ...connectFlowPtBr,
    ...connectFlowSurveyPtBr,
    ...contactPtBr,
    ...corePtBr,
    ...dashboardPtBr,
    ...filterPtBr,
    ...forgotPasswordPtBr,
    ...integrationPtBr,
    ...levelPtBr,
    ...loginPtBr,
    ...meetingPtBr,
    ...numberPtBr,
    ...paginationPtBr,
    ...permissionPtBr,
    ...queuePtBr,
    ...reportPtBr,
    ...rolePtBr,
    ...schedulePtBr,
    ...sideNavigationPtBr,
    ...sortPtBr,
    ...spamNumberPtBr,
    ...uiLoadingDialogTextPtBr,
    ...userLabelPtBr,
    ...userPtBr,
    ...validationsPtBr,
    ...welcomeLoggedPtBr,
    ...welcomePtBr,
    ...wordPtBr,
};
