import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "space-y-4 p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "flex items-center text-xl font-semibold text-gray-800" }
const _hoisted_3 = {
  key: 0,
  class: "space-y-2"
}
const _hoisted_4 = {
  key: 0,
  class: "space-y-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiBackButton = _resolveComponent("UiBackButton")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _component_UiPagination = _resolveComponent("UiPagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, [
          _createVNode($setup["ConnectFlowIcon"], {
            class: "mr-1 h-6 w-6 text-gray-500",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('connectFlow.history.title')), 1 /* TEXT */)
        ])
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_UiBackButton, { fallbackTo: { name: 'ListConnectFlow' } })
      ]),
      _: 1 /* STABLE */
    }),
    ($setup.connectFlow)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          ($setup.paginatedFlows?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.paginatedFlows, (flow) => {
                  return (_openBlock(), _createBlock($setup["ConnectFlowHistoryItem"], {
                    key: flow.id,
                    "model-value": flow,
                    published: flow.id === $setup.connectFlow?.lastAppliedFlowId
                  }, null, 8 /* PROPS */, ["model-value", "published"]))
                }), 128 /* KEYED_FRAGMENT */))
              ]))
            : (_openBlock(), _createBlock(_component_UiPanel, {
                key: 1,
                class: "flex items-center justify-center"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('core.messages.noRecordsFound')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })),
          ($setup.flows?.length)
            ? (_openBlock(), _createBlock(_component_UiPagination, {
                key: 2,
                modelValue: $setup.currentPage,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.currentPage) = $event)),
                count: $setup.count,
                perPage: $setup.perPage
              }, null, 8 /* PROPS */, ["modelValue", "count"]))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}