import { CallTimeRange } from '@/types';

export interface CallTimeRangeCount {
    [CallTimeRange.BELOW_30S]: number;
    [CallTimeRange.BETWEEN_30S_AND_60S]: number;
    [CallTimeRange.BETWEEN_61_AND_180S]: number;
    [CallTimeRange.BETWEEN_181S_AND_300S]: number;
    [CallTimeRange.BETWEEN_301S_AND_480S]: number;
    [CallTimeRange.BETWEEN_481S_AND_600S]: number;
    [CallTimeRange.ABOVE_600]: number;
}
