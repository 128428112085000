import { defineComponent as _defineComponent } from 'vue'
import { PhoneIncomingIcon } from '@heroicons/vue/solid';


export default /*@__PURE__*/_defineComponent({
  __name: 'StartNode',
  props: {
    nodeId: {
        type: String,
        required: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();



const __returned__ = { get PhoneIncomingIcon() { return PhoneIncomingIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})