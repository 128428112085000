import authenticationEnUs from '@/locales/en-US/authentication.json';
import callEnUs from '@/locales/en-US/call.json';
import connectFlowAudioEnUs from '@/locales/en-US/connectFlowAudio.json';
import connectFlowEnUs from '@/locales/en-US/connectFlow.json';
import connectFlowSurveyEnUs from '@/locales/en-US/connectFlowSurvey.json';
import contactEnUs from '@/locales/en-US/contact.json';
import coreEnUs from '@/locales/en-US/core.json';
import dashboardEnUs from '@/locales/en-US/dashboard.json';
import filterEnUs from '@/locales/en-US/filter.json';
import forgotPasswordEnUs from '@/locales/en-US/forgotPassword.json';
import integrationEnUs from '@/locales/en-US/integration.json';
import levelEnUs from '@/locales/en-US/level.json';
import loginEnUs from '@/locales/en-US/login.json';
import meetingEnUs from '@/locales/en-US/meeting.json';
import numberEnUs from '@/locales/en-US/number.json';
import paginationEnUs from '@/locales/en-US/pagination.json';
import permissionEnUs from '@/locales/en-US/permission.json';
import queueEnUs from '@/locales/en-US/queue.json';
import reportEnUs from '@/locales/en-US/report.json';
import roleEnUs from '@/locales/en-US/role.json';
import scheduleEnUs from '@/locales/en-US/schedule.json';
import sideNavigationEnUs from '@/locales/en-US/sideNavigation.json';
import sortEnUs from '@/locales/en-US/sort.json';
import spamNumberEnUs from '@/locales/en-US/spamNumber.json';
import uiLoadingDialogTextEnUs from '@/locales/en-US/uiLoadingDialogText.json';
import userEnUs from '@/locales/en-US/user.json';
import userLabelEnUs from '@/locales/en-US/userLabel.json';
import validationsEnUs from '@/locales/en-US/validations.json';
import welcomeEnUs from '@/locales/en-US/welcome.json';
import welcomeLoggedEnUs from '@/locales/en-US/welcomeLogged.json';
import wordEnUs from '@/locales/en-US/word.json';

export const enUS = {
    ...authenticationEnUs,
    ...callEnUs,
    ...connectFlowAudioEnUs,
    ...connectFlowEnUs,
    ...connectFlowSurveyEnUs,
    ...contactEnUs,
    ...coreEnUs,
    ...dashboardEnUs,
    ...filterEnUs,
    ...forgotPasswordEnUs,
    ...integrationEnUs,
    ...levelEnUs,
    ...loginEnUs,
    ...meetingEnUs,
    ...numberEnUs,
    ...paginationEnUs,
    ...permissionEnUs,
    ...queueEnUs,
    ...reportEnUs,
    ...roleEnUs,
    ...scheduleEnUs,
    ...sideNavigationEnUs,
    ...sortEnUs,
    ...spamNumberEnUs,
    ...uiLoadingDialogTextEnUs,
    ...userEnUs,
    ...userLabelEnUs,
    ...validationsEnUs,
    ...welcomeEnUs,
    ...welcomeLoggedEnUs,
    ...wordEnUs,
};
