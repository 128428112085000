import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hover:bg-white" }
const _hoisted_2 = { class: "whitespace-nowrap px-2 py-2 text-sm text-gray-800" }
const _hoisted_3 = { class: "whitespace-nowrap px-2 py-2 text-sm text-gray-500" }
const _hoisted_4 = { class: "whitespace-nowrap px-2 py-2 text-sm text-gray-500" }
const _hoisted_5 = { class: "whitespace-nowrap px-2 py-2 text-sm text-gray-500" }
const _hoisted_6 = { class: "whitespace-nowrap px-2 py-2 text-sm text-gray-500" }
const _hoisted_7 = { class: "whitespace-nowrap px-2 py-2 text-sm text-gray-500" }
const _hoisted_8 = { class: "whitespace-nowrap px-2 py-2 text-sm text-gray-500" }
const _hoisted_9 = { class: "whitespace-nowrap px-2 py-2 text-sm text-gray-500" }
const _hoisted_10 = { class: "whitespace-nowrap px-2 py-2 text-sm text-gray-500" }
const _hoisted_11 = { class: "whitespace-nowrap px-2 py-2 text-sm text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", _hoisted_2, _toDisplayString($props.modelValue.call.id), 1 /* TEXT */),
    _createElementVNode("td", _hoisted_3, _toDisplayString($setup.formatDateAsString($props.modelValue.call.startedAt?.toString(), _ctx.$t('core.dateTimeFormat'))), 1 /* TEXT */),
    _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.$t('core.options.call.type.' + $props.modelValue.call.type.toLowerCase())), 1 /* TEXT */),
    _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.$t('core.options.call.status.' + $props.modelValue.call.status.toLowerCase())), 1 /* TEXT */),
    _createElementVNode("td", _hoisted_6, _toDisplayString($setup.callSource), 1 /* TEXT */),
    _createElementVNode("td", _hoisted_7, _toDisplayString($setup.callDestination), 1 /* TEXT */),
    _createElementVNode("td", _hoisted_8, _toDisplayString($setup.formatDateAsString($props.modelValue.createdAt.toString(), _ctx.$t('core.dateTimeFormat'))), 1 /* TEXT */),
    _createElementVNode("td", _hoisted_9, _toDisplayString(_ctx.$t(`integration.logs.filters.status.options.${$props.modelValue.status}`)), 1 /* TEXT */),
    _createElementVNode("td", _hoisted_10, _toDisplayString($props.modelValue.externalId), 1 /* TEXT */),
    _createElementVNode("td", _hoisted_11, _toDisplayString($props.modelValue.observation), 1 /* TEXT */)
  ]))
}