<script lang="ts" setup>
import { ref, Ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter, RouterLink } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { LockClosedIcon, PencilIcon } from '@heroicons/vue/solid';

import store from '@/store';
import { useUiStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import RoleService from '@/services/api/RoleService';
import PermissionService from '@/services/api/PermissionService';

import {
    RoleModel,
    PermissionModel,
    Permission,
    PermissionGetters,
    PermissionActions,
} from '@/types';

const roles: Ref<RoleModel[]> = ref([]);
const permissions: Ref<PermissionModel[]> = ref([]);
const orderBy: Ref<string> = ref('permission.description');
const perPage = 20;

const search = computed<string>({
    get: () => store.getters[PermissionGetters.SEARCH],
    set: (search: string) => store.dispatch(PermissionActions.SET_SEARCH, search),
});

const currentPage = computed<number>({
    get: () => store.getters[PermissionGetters.PAGE],
    set: (page: number) => store.dispatch(PermissionActions.SET_PAGE, page),
});

const descriptionalizedPermissions = computed<PermissionModel[]>(() => {
    return permissions.value.map((p) => {
        p.description = t('permission.permissions.' + p.id);
        return p;
    });
});

const filteredPermissions = computed<PermissionModel[]>(() => {
    return descriptionalizedPermissions.value.filter((p) => {
        return !search.value || p.description?.toLowerCase().includes(search.value.toLowerCase());
    });
});

const paginatedPermissions = computed<PermissionModel[]>(() => {
    return filteredPermissions.value.slice(
        (currentPage.value - 1) * perPage,
        currentPage.value * perPage,
    );
});

const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const uiStore = useUiStore();

watch([search, orderBy], () => {
    currentPage.value = 1;
});

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchRolesAndPermissions = async () => {
    try {
        await setLoading(true);
        const [roleResponse, permissionResponse] = await Promise.all([
            RoleService.getAll<RoleModel[]>(),
            PermissionService.getAll<PermissionModel[]>(),
        ]);

        roles.value = roleResponse.data;
        permissions.value = permissionResponse.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const handleAddRemovePermissionClick = async (role: RoleModel, permission: PermissionModel) => {
    try {
        setLoading(true);
        const permissionRole = permission.roles.find((r: RoleModel) => r.id == role.id);

        if (permissionRole) {
            await RoleService.removePermission(role.id, permission.id);
            permission.roles.splice(permission.roles.indexOf(permissionRole), 1);
        } else {
            await RoleService.addPermission(role.id, permission.id);
            permission.roles.push(role);
        }
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

onMounted(() => {
    if (route.query.clearStateOnSetup) {
        search.value = '';
        currentPage.value = 1;
        router.replace({ query: {} });
    }

    fetchRolesAndPermissions();
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="flex items-center text-xl font-semibold text-gray-800">
                    <LockClosedIcon class="mr-1 h-6 w-6 text-gray-500" aria-hidden="true" />
                    {{ $t('core.entity.Permissions') }}
                </h1>
            </template>
            <template #actions>
                <router-link
                    v-if="$can(Permission.CREATE_ROLE)"
                    :to="{
                        name: 'CreateRole',
                    }"
                >
                    <UiButton variant="primary" text-variant="white">
                        {{ $t('permission.actions.newRole') }}
                    </UiButton>
                </router-link>
            </template>
        </UiPageHeader>

        <UiPanel class="px-4 py-2" no-padding>
            <UiListSearch v-model="search" />
        </UiPanel>

        <UiPanel no-padding>
            <table class="min-w-full">
                <thead class="bg-gray-100">
                    <tr>
                        <th scope="col" class="p-4 text-left text-sm font-semibold text-gray-800">
                            {{ $t('permission.labels.module') }}
                        </th>
                        <th scope="col" class="p-4 text-left text-sm font-semibold text-gray-800">
                            {{ $t('permission.labels.name') }}
                        </th>
                        <th
                            v-for="role in roles"
                            :key="role.id"
                            scope="col"
                            class="p-4 text-center text-sm font-semibold text-gray-800"
                        >
                            <component
                                :is="$can(Permission.VIEW_ROLE) ? RouterLink : 'span'"
                                :to="{
                                    name: 'ViewRoleById',
                                    params: {
                                        id: role.id,
                                    },
                                }"
                                class="text-primary inline-flex items-center truncate text-sm font-medium"
                            >
                                {{ role.name }}
                                <PencilIcon
                                    v-if="$can(Permission.VIEW_ROLE)"
                                    class="ml-1 h-4 w-4"
                                    aria-hidden="true"
                                />
                            </component>
                        </th>
                    </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                    <tr v-for="permission in paginatedPermissions" :key="permission.id">
                        <td class="whitespace-nowrap p-4 text-sm font-medium text-gray-800">
                            {{ $t(`permission.modules.${permission.module}`) }}
                        </td>
                        <td class="whitespace-nowrap p-4 text-sm font-medium text-gray-800">
                            {{ permission.description }}
                        </td>
                        <td
                            v-for="role in roles"
                            :key="role.id"
                            class="whitespace-nowrap p-4 text-center text-sm text-gray-500"
                        >
                            <input
                                type="checkbox"
                                v-tippy="{
                                    content: permission.description,
                                    placement: 'left',
                                }"
                                :checked="
                                    permission.roles.find((r: RoleModel) => r.id == role.id)
                                        ? true
                                        : false
                                "
                                @change="handleAddRemovePermissionClick(role, permission)"
                            />
                        </td>
                    </tr>
                    <tr v-if="!paginatedPermissions.length">
                        <td class="whitespace-nowrap p-4 text-center text-sm text-gray-500">
                            {{ $t('core.messages.noRecordsFound') }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </UiPanel>

        <UiPagination
            v-model="currentPage"
            :count="filteredPermissions.length"
            :perPage="perPage"
        />
    </div>
</template>
