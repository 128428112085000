import { QueueTimeReportServiceGetInfoResult } from '@/types';
import BaseService from '@/services/api/BaseService';
import { AxiosRequestConfig } from 'axios';

class QueueTimeReportService extends BaseService<QueueTimeReportServiceGetInfoResult> {
    getInfo(config?: AxiosRequestConfig) {
        return this.http.get<QueueTimeReportServiceGetInfoResult>(`${this.route}`, config);
    }
}

export default new QueueTimeReportService('reports/queue-time');
