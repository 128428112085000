import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mb-2 sm:mb-0" }
const _hoisted_2 = { class: "rounded border-gray-200 bg-gray-50 px-1.5 py-0.5 text-xs ring-1 ring-gray-300" }
const _hoisted_3 = { class: "grid grid-cols-2 gap-3 lg:grid-cols-6" }
const _hoisted_4 = { class: "flex flex-wrap items-center space-x-1 text-sm text-gray-600" }
const _hoisted_5 = { class: "truncate" }
const _hoisted_6 = { class: "flex flex-wrap items-center space-x-1 text-sm text-gray-600" }
const _hoisted_7 = { class: "ml-0.5 truncate" }
const _hoisted_8 = { class: "flex flex-col text-sm" }
const _hoisted_9 = { class: "text-xs text-gray-400" }
const _hoisted_10 = {
  key: 0,
  class: "truncate"
}
const _hoisted_11 = {
  key: 1,
  class: "truncate"
}
const _hoisted_12 = {
  key: 2,
  class: "truncate"
}
const _hoisted_13 = { class: "flex flex-col text-sm" }
const _hoisted_14 = { class: "text-xs text-gray-400" }
const _hoisted_15 = { class: "truncate" }
const _hoisted_16 = { class: "flex flex-col text-sm" }
const _hoisted_17 = { class: "text-xs text-gray-400" }
const _hoisted_18 = {
  key: 0,
  class: "truncate"
}
const _hoisted_19 = {
  key: 1,
  class: "truncate"
}
const _hoisted_20 = {
  key: 0,
  class: "flex items-center lg:justify-end"
}
const _hoisted_21 = { class: "mt-2 grid grid-cols-2 gap-3 border-t pt-3 lg:grid-cols-6" }
const _hoisted_22 = { class: "flex items-center space-x-1 text-xs text-gray-600" }
const _hoisted_23 = { class: "truncate" }
const _hoisted_24 = { class: "truncate" }
const _hoisted_25 = { class: "flex items-center space-x-1 text-xs text-gray-600" }
const _hoisted_26 = { class: "flex items-center space-x-1 text-xs text-gray-600" }
const _hoisted_27 = { class: "flex items-center text-xs text-gray-600" }
const _hoisted_28 = { class: "ml-1" }
const _hoisted_29 = {
  key: 0,
  class: "rounded border bg-white px-1.5 py-0.5 text-xs text-gray-500"
}
const _hoisted_30 = { key: 1 }
const _hoisted_31 = { class: "col-span-2 flex items-start text-xs text-gray-600" }
const _hoisted_32 = { class: "flex h-full items-center" }
const _hoisted_33 = { class: "-my-0.5 flex flex-wrap px-1" }
const _hoisted_34 = { key: 1 }
const _hoisted_35 = {
  key: 0,
  class: "flex items-center gap-2 text-xs text-gray-600"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiActionButton = _resolveComponent("UiActionButton")!
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock(_component_UiPanel, { class: "hover:bg-white" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, " #" + _toDisplayString($props.call.id), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          ($props.call.type === $setup.CallType.INBOUND)
            ? (_openBlock(), _createBlock($setup["PhoneIncomingIcon"], {
                key: 0,
                class: "h-5 w-5 text-cyan-500",
                "aria-hidden": "true"
              }))
            : ($props.call.type === $setup.CallType.OUTBOUND)
              ? (_openBlock(), _createBlock($setup["PhoneOutgoingIcon"], {
                  key: 1,
                  class: "h-5 w-5 text-orange-500",
                  "aria-hidden": "true"
                }))
              : ($props.call.type === $setup.CallType.INTERNAL)
                ? (_openBlock(), _createBlock($setup["PhoneIcon"], {
                    key: 2,
                    class: "h-5 w-5 text-gray-500",
                    "aria-hidden": "true"
                  }))
                : _createCommentVNode("v-if", true),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t(`call.type.${$props.call.type}`)), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", {
            class: _normalizeClass(["flex h-2.5 w-2.5 rounded-full", $setup.CallStatusClassMap[$props.call.status]])
          }, null, 2 /* CLASS */),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t(`call.status.${$props.call.status}`)), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('call.list.origin')), 1 /* TEXT */),
          ($props.call.contact && $props.call.type === $setup.CallType.INBOUND)
            ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString($props.call.contact.name), 1 /* TEXT */))
            : ($setup.sourceEvent?.sourceUser)
              ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString($setup.sourceEvent.sourceUser.name), 1 /* TEXT */))
              : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString($setup.sourceEvent?.sourceNumber ? $setup.applyPhoneMask($setup.sourceEvent.sourceNumber) : '-'), 1 /* TEXT */))
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t('call.list.tracking')), 1 /* TEXT */),
          _createElementVNode("span", _hoisted_15, _toDisplayString($props.call.type === $setup.CallType.INBOUND && $props.call.destinationNumber
                        ? $setup.applyPhoneMask($props.call.destinationNumber)
                        : '-'), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('call.list.destination')), 1 /* TEXT */),
          ($setup.destinationEvent?.destinationUser)
            ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString($setup.destinationEvent?.destinationUser.name), 1 /* TEXT */))
            : (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString($setup.destinationEvent?.destinationNumber
                            ? $setup.formatDestinationNumber($setup.destinationEvent?.destinationNumber)
                            : '-'), 1 /* TEXT */))
        ]),
        ($props.call.audioFilePath)
          ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
              ($setup.showCallAudioDownloadButton)
                ? (_openBlock(), _createBlock($setup["CallAudioDownloadButton"], {
                    key: 0,
                    callId: $props.call.id
                  }, null, 8 /* PROPS */, ["callId"]))
                : _createCommentVNode("v-if", true),
              ($props.showViewButton && _ctx.$can($setup.Permission.VIEW_CALL) && $props.call.status == 'ANSWERED')
                ? _withDirectives((_openBlock(), _createBlock(_component_UiActionButton, {
                    key: 1,
                    variant: "primary",
                    to: {
                        name: 'ViewCallById',
                        params: {
                            id: $props.call.id,
                        },
                    }
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["PlayIcon"], {
                        class: "h-7 w-7",
                        "aria-hidden": "true"
                      }),
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('core.actions.View')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["to"])), [
                    [_directive_tippy, _ctx.$t('core.actions.View')]
                  ])
                : _createCommentVNode("v-if", true)
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("div", _hoisted_22, [
          _createVNode($setup["CalendarIcon"], {
            class: "h-4 w-4",
            "aria-hidden": "true"
          }),
          _createElementVNode("span", _hoisted_23, _toDisplayString($setup.formatDateAsString($props.call.startedAt.toString(), 'DD/MM/YYYY')), 1 /* TEXT */),
          _createElementVNode("span", _hoisted_24, _toDisplayString($setup.formatDateAsString($props.call.startedAt.toString(), 'HH:mm:ss')), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_25, [
          _createVNode($setup["ClockIcon"], {
            class: "h-4 w-4",
            "aria-hidden": "true"
          }),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('call.list.duration')) + ": ", 1 /* TEXT */),
          _createElementVNode("span", null, _toDisplayString($props.call.status == $setup.CallStatus.ANSWERED && $props.call.endedAt
                        ? $setup.formatSecToTime($setup.duration)
                        : '-'), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_26, [
          _createVNode($setup["ClockIcon"], {
            class: "h-4 w-4",
            "aria-hidden": "true"
          }),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('call.list.waitingTime')) + ": ", 1 /* TEXT */),
          _createElementVNode("span", null, _toDisplayString($props.call.status != $setup.CallStatus.ON_PROGRESS && $props.call.endedAt !== null
                        ? $setup.formatSecToTime($setup.waitingTime)
                        : '-'), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_27, [
          _createVNode($setup["TagIcon"], {
            class: "mr-1 h-4 w-4",
            "aria-hidden": "true"
          }),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('call.list.media')) + ": ", 1 /* TEXT */),
          _createElementVNode("div", _hoisted_28, [
            ($props.call.media)
              ? (_openBlock(), _createElementBlock("span", _hoisted_29, _toDisplayString($props.call.media), 1 /* TEXT */))
              : (_openBlock(), _createElementBlock("span", _hoisted_30, "-"))
          ])
        ]),
        _createElementVNode("div", _hoisted_31, [
          _createElementVNode("div", _hoisted_32, [
            _createVNode($setup["UsersIcon"], {
              class: "mr-1 h-4 w-4 flex-shrink-0",
              "aria-hidden": "true"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('call.list.participants')) + ": ", 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_33, [
            ($setup.agents.length > 1)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($setup.agents, (agent) => {
                  return (_openBlock(), _createElementBlock("span", {
                    class: "m-0.5 inline-block whitespace-nowrap rounded border bg-white px-1.5 py-0.5 text-xs text-gray-500",
                    key: `agent${$props.call.id}${agent}`
                  }, _toDisplayString(agent), 1 /* TEXT */))
                }), 128 /* KEYED_FRAGMENT */))
              : (_openBlock(), _createElementBlock("span", _hoisted_34, "-"))
          ])
        ]),
        ($props.call.ura !== null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
              _createVNode($setup["CalculatorIcon"], {
                class: "h-4 w-4",
                "aria-hidden": "true"
              }),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('call.list.ura')) + ": ", 1 /* TEXT */),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.call.ura, (ura) => {
                return (_openBlock(), _createElementBlock("span", {
                  class: "rounded border border-b-gray-400 bg-white px-1",
                  key: `${$props.call.id}${ura.node}`
                }, _toDisplayString(ura.digit), 1 /* TEXT */))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _: 1 /* STABLE */
  }))
}