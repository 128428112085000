import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "text-primary flex-1 px-4 font-semibold" }
const _hoisted_3 = { class: "bg-primary rounded-tr p-1 text-white" }
const _hoisted_4 = { class: "flex-1 px-4 text-sm text-gray-600" }
const _hoisted_5 = { class: "text-primary flex items-center rounded-b border-t border-gray-300/50 px-4 py-2 text-sm font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _component_router_link = _resolveComponent("router-link")!

  return ($props.permission && _ctx.$can($props.permission))
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: { name: $props.routeName }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_UiPanel, {
            class: "flex min-h-full flex-col space-y-2 hover:bg-white",
            "no-padding": ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t($props.title)), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_3, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent($props.icon), {
                    class: "h-8 w-8",
                    "aria-hidden": "true"
                  }))
                ])
              ]),
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t($props.description)), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_5, [
                _createVNode($setup["ArrowRightIcon"], {
                  class: "-ml-1 mr-1 h-4 w-4",
                  "aria-hidden": "true"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('core.actions.goPage')), 1 /* TEXT */)
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["to"]))
    : _createCommentVNode("v-if", true)
}