import { RouteRecordRaw } from 'vue-router';
import { validateIdAsPositiveIntegerGuard } from '@/router/routeGuards';
import { FormAction } from '@/types';
import ContactPage from '@/views/pages/contact/ContactPage.vue';
import ContactDetailPage from '@/views/pages/contact/ContactDetailPage.vue';
import ContactFormPage from '@/views/pages/contact/ContactFormPage.vue';

const ContactRouter: RouteRecordRaw = {
    name: 'Contact',
    path: 'contact',
    children: [
        {
            name: 'ListContact',
            path: '',
            component: ContactPage,
        },
        {
            name: 'ViewContactById',
            path: ':id',
            component: ContactDetailPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
        },
        {
            name: 'EditContactById',
            path: ':id/edit',
            component: ContactFormPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            props: { action: FormAction.EDIT },
        },
        {
            name: 'CreateContact',
            path: 'create',
            component: ContactFormPage,
            props: { action: FormAction.CREATE },
        },
    ],
};
export default ContactRouter;
