import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue';
import { useAttrs } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserFormDevice',
  props: {
    modelValue: {
        type: Boolean,
        required: true,
    },
    label: {
        type: String,
        required: true,
    },
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const attrs = useAttrs();

const props = __props;

const emit = __emit;

const checkedValue = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value),
});

const __returned__ = { attrs, props, emit, checkedValue }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})