import {
    CallStatusCount,
    CallTimeOfDayCount,
    CallTimeRangeCount,
    CallTMCount,
    CallTypeCount,
    ChartDataItem,
} from '@/types';

export interface CallsStats {
    total: number;
    totalProcessed: number;
    type: CallTypeCount;
    typePerDay: ChartDataItem<CallTypeCount>[];
    tmPerDay: ChartDataItem<CallTMCount>[];
    status: CallStatusCount;
    statusPerDay: ChartDataItem<CallStatusCount>[];
    media: ChartDataItem<number>[];
    serviceTimeRange: CallTimeRangeCount;
    timeOfDay: CallTimeOfDayCount[];
    uniqueNumbers: number;
    averageSilencePercentage: number;
    averageServiceTime?: number;
    averageWaitingTime?: number;
}
