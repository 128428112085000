import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent($props.to === undefined ? 'button' : 'RouterLink'), {
    type: $props.htmlType,
    to: $props.to,
    class: _normalizeClass(["flex items-center justify-center font-medium focus:outline-none", $setup.classes]),
    disabled: $props.disabled
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["type", "to", "class", "disabled"]))
}