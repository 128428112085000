<script lang="ts" setup>
import { ref, Ref, watch, onMounted } from 'vue';
import { useRoute, RouterLink } from 'vue-router';

import { useUiStore } from '@/stores';
import { ConnectFlowSurveyService, VaultService } from '@/services';
import { toastServiceError } from '@/services/core/notification';
import { ConnectFlowSurveyModel, Permission } from '@/types';
import { getAudioFormat } from '@/utils/audio';

const route = useRoute();
const uiStore = useUiStore();

const survey: Ref<ConnectFlowSurveyModel | undefined> = ref(undefined);
const audioFile: Ref<string | undefined> = ref();
const questions: Ref<
    {
        label: string;
        rangeAnswer: string;
        audioFilePath?: string | null;
        audioFile?: string | null;
        textToAudio?: string | null;
    }[]
> = ref([]);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getAudioData = async (audioFilePath: string | null | undefined) => {
    let audioData: string | undefined;

    if (audioFilePath) {
        try {
            const { data } = await VaultService.getAudioAsset(audioFilePath);
            const blob = new Blob([data], {
                type: getAudioFormat(audioFilePath),
            });

            audioData = URL.createObjectURL(blob);
        } catch (err) {
            console.error(err);
        }
    }

    return audioData;
};

const fetchConnectFlowSurvey = async (id: number) => {
    await setLoading(true);
    try {
        const response = await ConnectFlowSurveyService.get<ConnectFlowSurveyModel>(id);
        survey.value = response.data;
        audioFile.value = await getAudioData(survey.value?.audioFilePath);
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

watch([survey], async () => {
    questions.value = [];

    if (survey.value?.questions?.length) {
        for (let i = 0; i < survey.value.questions.length; i++) {
            const question = survey.value.questions[i];
            questions.value.push({
                label: question.label,
                rangeAnswer: question.rangeAnswer,
                audioFilePath: question.audioFilePath,
                audioFile: await getAudioData(question.audioFilePath),
                textToAudio: question.textToAudio,
            });
        }
    }
});

onMounted(() => {
    const surveyId = Number(route.params.id);
    if (!isNaN(surveyId)) fetchConnectFlowSurvey(surveyId);
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('connectFlowSurvey.view.title') }}
                </h1>
            </template>
            <template #actions>
                <div class="flex space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ListConnectFlowSurvey' }" />
                    <router-link
                        v-if="$can(Permission.EDIT_CONNECT_FLOW_SURVEY)"
                        :to="{
                            name: 'EditConnectFlowSurveyById',
                            params: {
                                id: route.params.id,
                            },
                        }"
                        class="block hover:bg-gray-50"
                    >
                        <UiButton variant="primary" text-variant="white">
                            {{ $t('core.actions.Edit') }}
                        </UiButton>
                    </router-link>
                </div>
            </template>
        </UiPageHeader>

        <div v-if="survey" class="space-y-4">
            <UiPanel>
                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                    <div>
                        <h3 class="mb-1 font-semibold">
                            {{ $t('connectFlowSurvey.labels.name') }}
                        </h3>
                        <p>{{ survey.name }}</p>
                    </div>
                </div>
                <UiAuditStatusInfo
                    :model-value="survey"
                    class="bt-1 mt-4 border-t border-gray-300 pt-2"
                />
            </UiPanel>

            <UiPanel>
                <h2 class="mb-4 text-lg font-semibold text-gray-800">
                    {{ $t('connectFlowSurvey.view.section.initialMessage.title') }}
                </h2>

                <div v-if="survey.textToAudio">
                    <h3 class="mb-1 font-semibold">
                        {{ $t('connectFlowSurvey.labels.textToAudio') }}
                    </h3>
                    <p>{{ survey.textToAudio }}</p>
                </div>

                <div v-if="audioFile">
                    <h3 class="mb-1 font-semibold">
                        {{ $t('connectFlowSurvey.labels.audio') }}
                    </h3>
                    <audio controls>
                        <source
                            :src="audioFile"
                            :type="getAudioFormat(survey.audioFilePath || '')"
                        />
                    </audio>
                </div>
            </UiPanel>

            <UiPanel>
                <h2 class="mb-2 text-lg font-semibold text-gray-800">
                    {{ $t('connectFlowSurvey.view.section.questions.title') }}
                </h2>

                <div v-for="(question, index) in questions" :key="index" class="bt-1 border-t py-2">
                    <h4 class="font-semibold">{{ question.label }}</h4>
                    <p v-if="question.rangeAnswer" class="mb-2 text-sm text-gray-500">
                        {{ $t('connectFlowSurvey.view.section.questions.labels.scoreRange') }}:
                        {{ question.rangeAnswer }}
                    </p>
                    <p v-if="question.textToAudio">
                        {{ question.textToAudio }}
                    </p>
                    <audio v-if="question.audioFile" controls>
                        <source
                            :src="question.audioFile"
                            :type="getAudioFormat(question.audioFilePath || '')"
                        />
                        Seu navegador não suporta o elemento de áudio.
                    </audio>
                </div>
            </UiPanel>
        </div>
    </div>
</template>
