import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-4 p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "flex items-center text-xl font-semibold text-gray-800" }
const _hoisted_3 = { class: "space-y-2" }
const _hoisted_4 = { class: "flex items-center space-x-4" }
const _hoisted_5 = {
  key: 1,
  class: "space-y-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!
  const _component_UiListSearch = _resolveComponent("UiListSearch")!
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _component_UiSortMenuBadge = _resolveComponent("UiSortMenuBadge")!
  const _component_UiBadge = _resolveComponent("UiBadge")!
  const _component_UiPagination = _resolveComponent("UiPagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, [
          _createVNode($setup["UsersIcon"], {
            class: "mr-1 h-6 w-6 text-gray-500",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('core.entity.Users')), 1 /* TEXT */)
        ])
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_UiButton, {
          variant: "white",
          "text-variant": "primary",
          class: "mr-4 hover:bg-gray-50",
          onClick: $setup.exportCSV
        }, {
          default: _withCtx(() => [
            _createVNode($setup["DocumentDownloadIcon"], {
              class: "-ml-1 mr-2 h-5 w-5",
              "aria-hidden": "true"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('core.actions.export')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        (_ctx.$can($setup.Permission.CREATE_USER))
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: { name: 'CreateUser' }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_UiButton, {
                  variant: "primary",
                  "text-variant": "white"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Add')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_UiPanel, {
        class: "flex px-4 py-2",
        "no-padding": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_UiListSearch, {
            modelValue: $setup.search,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.search) = $event)),
            placeholder: _ctx.$t('user.filters.search'),
            class: "flex-1"
          }, null, 8 /* PROPS */, ["modelValue", "placeholder"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode($setup["UserListSort"], {
              modelValue: $setup.sortMenuSelected,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.sortMenuSelected) = $event))
            }, null, 8 /* PROPS */, ["modelValue"]),
            _createVNode($setup["UserListFilters"])
          ])
        ]),
        _: 1 /* STABLE */
      }),
      ($setup.sortMenuSelected.length || $setup.activeFilters.length)
        ? (_openBlock(), _createBlock(_component_UiPanel, {
            key: 0,
            class: "space-x-2"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sortMenuSelected, (selected) => {
                return (_openBlock(), _createBlock(_component_UiSortMenuBadge, {
                  key: selected.key,
                  item: selected,
                  onRemove: ($event: any) => ($setup.handleRemoveSortClick(selected))
                }, null, 8 /* PROPS */, ["item", "onRemove"]))
              }), 128 /* KEYED_FRAGMENT */)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.activeFilters, (item) => {
                return (_openBlock(), _createBlock(_component_UiBadge, {
                  key: `activeFilter${item.field}`,
                  removable: "",
                  onRemove: ($event: any) => ($setup.handleRemoveFilterClick(item))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t(`user.filters.${item.field}.label`)) + ": " + _toDisplayString(item.label), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onRemove"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      ($setup.users.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.users, (user) => {
              return (_openBlock(), _createBlock($setup["UserListItem"], {
                key: user.id,
                "model-value": user,
                onActivate: $setup.handleActivateClick,
                onInactivate: $setup.handleInactivateClick
              }, null, 8 /* PROPS */, ["model-value"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createBlock(_component_UiPanel, {
            key: 2,
            class: "flex items-center justify-center"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('core.messages.noRecordsFound')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })),
      ($setup.users.length)
        ? (_openBlock(), _createBlock(_component_UiPagination, {
            key: 3,
            modelValue: $setup.currentPage,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.currentPage) = $event)),
            count: $setup.count,
            perPage: $setup.perPage
          }, null, 8 /* PROPS */, ["modelValue", "count"]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}