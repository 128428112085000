<script lang="ts" setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { UsersIcon, DocumentDownloadIcon } from '@heroicons/vue/solid';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import UserService from '@/services/api/UserService';
import { toast, toastServiceError } from '@/services/core/notification';

import {
    FilterItem,
    Permission,
    SortMenuItem,
    UserActions,
    UserFilter,
    UserGetters,
    UserModel,
    UserServiceGetAllParams,
} from '@/types';
import { UserListItem, UserListSort, UserListFilters } from '@/views/pages/user/components';

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const filterStore = useFilterStore();
const uiStore = useUiStore();

const users = ref<UserModel[]>([]);
const count = ref(0);
const perPage = 10;

const search = computed({
    get: () => store.getters[UserGetters.SEARCH],
    set: (value: string) => store.dispatch(UserActions.SET_SEARCH, value),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[UserGetters.SORT],
    set: (value: SortMenuItem[]) => store.dispatch(UserActions.SET_SORT, value),
});

const level = computed(() => filterStore.level);

const currentPage = computed({
    get: () => store.getters[UserGetters.PAGE],
    set: (value: number) => store.dispatch(UserActions.SET_PAGE, value),
});

const filter: UserFilter = store.getters[UserGetters.FILTER];

const activeFilters = computed<FilterItem[]>(() => store.getters[UserGetters.ACTIVE_FILTERS]);

const orderBy = computed(() => sortMenuSelected.value.map((s) => s.direction + s.key).join(','));

watch([currentPage], () => {
    fetchUsers();
});

watch([level, search, filter, orderBy], () => {
    if (currentPage.value === 1) fetchUsers();
    else currentPage.value = 1;
});

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getParams = (): UserServiceGetAllParams => ({
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    is_active: filter.isActive !== null ? (filter.isActive.value as boolean) : undefined,
    roles: filter.roles.map((r) => r.id),
    search: search.value,
});

const fetchUsers = async () => {
    setLoading(true);
    try {
        const { data, headers } = await UserService.getAll<UserModel[]>({ params: getParams() });
        users.value = data;
        count.value = parseInt(headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const exportCSV = async () => {
    setLoading(true);
    try {
        const { data } = await UserService.getCsvReport<Array<UserModel>>({ params: getParams() });
        const dateNow = moment().format('DD/MM/YYYY HH:mm:ss');
        const filename = `${t('dashboard.report.users')} - ${dateNow}.csv`;
        const blob = new Blob([typeof data === 'string' ? data : JSON.stringify(data)], {
            type: 'text/csv;charset=utf-8;',
        });

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const setIsActive = async (item: UserModel, isActive: boolean) => {
    setLoading(true);
    try {
        const { data } = await (
            isActive
                ? (id: number) => UserService.activate(id)
                : (id: number) => UserService.inactivate(id)
        )(item.id);
        toast.success(data.message);
        await fetchUsers();
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveSortClick = (item: SortMenuItem) => {
    const index = sortMenuSelected.value.indexOf(item);
    if (index > -1) sortMenuSelected.value.splice(index, 1);
};

const handleRemoveFilterClick = (item: FilterItem) =>
    store.dispatch(UserActions.CLEAR_FILTER, item);

const handleActivateClick = (item: UserModel) => setIsActive(item, true);

const handleInactivateClick = (item: UserModel) => setIsActive(item, false);

onMounted(() => {
    if (route.query.clearStateOnSetup) {
        search.value = '';
        sortMenuSelected.value = [];
        currentPage.value = 1;
        router.replace({ query: {} });
    }

    fetchUsers();
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="flex items-center text-xl font-semibold text-gray-800">
                    <UsersIcon class="mr-1 h-6 w-6 text-gray-500" aria-hidden="true" />
                    {{ $t('core.entity.Users') }}
                </h1>
            </template>
            <template #actions>
                <UiButton
                    variant="white"
                    text-variant="primary"
                    class="mr-4 hover:bg-gray-50"
                    @click="exportCSV"
                >
                    <DocumentDownloadIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                    {{ $t('core.actions.export') }}
                </UiButton>
                <router-link v-if="$can(Permission.CREATE_USER)" :to="{ name: 'CreateUser' }">
                    <UiButton variant="primary" text-variant="white">
                        {{ $t('core.actions.Add') }}
                    </UiButton>
                </router-link>
            </template>
        </UiPageHeader>

        <div class="space-y-2">
            <UiPanel class="flex px-4 py-2" no-padding>
                <UiListSearch
                    v-model="search"
                    :placeholder="$t('user.filters.search')"
                    class="flex-1"
                />
                <div class="flex items-center space-x-4">
                    <UserListSort v-model="sortMenuSelected" />
                    <UserListFilters />
                </div>
            </UiPanel>

            <UiPanel v-if="sortMenuSelected.length || activeFilters.length" class="space-x-2">
                <UiSortMenuBadge
                    v-for="selected in sortMenuSelected"
                    :key="selected.key"
                    :item="selected"
                    @remove="handleRemoveSortClick(selected)"
                />
                <UiBadge
                    v-for="item in activeFilters"
                    :key="`activeFilter${item.field}`"
                    removable
                    @remove="handleRemoveFilterClick(item)"
                >
                    {{ $t(`user.filters.${item.field}.label`) }}: {{ item.label }}
                </UiBadge>
            </UiPanel>

            <div v-if="users.length" class="space-y-2">
                <UserListItem
                    v-for="user in users"
                    :key="user.id"
                    :model-value="user"
                    @activate="handleActivateClick"
                    @inactivate="handleInactivateClick"
                />
            </div>
            <UiPanel v-else class="flex items-center justify-center">
                {{ $t('core.messages.noRecordsFound') }}
            </UiPanel>

            <UiPagination
                v-if="users.length"
                v-model="currentPage"
                :count="count"
                :perPage="perPage"
            />
        </div>
    </div>
</template>
