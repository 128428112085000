<script lang="ts" setup>
import { computed, ComputedRef } from 'vue';
import store from '@/store';

import {
    PlusCircleIcon,
    ClockIcon,
    PlayIcon,
    ReplyIcon,
    PhoneMissedCallIcon,
    ReceiptRefundIcon,
    QuestionMarkCircleIcon,
    AnnotationIcon,
} from '@heroicons/vue/outline';

import {
    ConnectFlowModelingActions,
    ConnectFlowModelingGetters,
    ConnectFlowModelingMenu,
    FlowNodeModel,
    NodeType,
    VueFlowNodeModel,
} from '@/types';

import { flowNodeRules } from '@/configs';

const menu: ComputedRef<ConnectFlowModelingMenu> = computed(
    () => store.getters['flow/' + ConnectFlowModelingGetters.MENU],
);

const showForm = computed({
    get: () => menu.value.show,
    set: (value) => store.dispatch('flow/' + ConnectFlowModelingActions.SET_MENU, value),
});

const triggerNode: ComputedRef<VueFlowNodeModel> = computed(() => {
    if (menu.value.idTriggerBy) {
        return store.getters['flow/' + ConnectFlowModelingGetters.ELEMENT_BY_ID](
            menu.value.idTriggerBy,
        );
    }
    return null;
});

const rules: ComputedRef<FlowNodeModel> = computed(
    () => flowNodeRules[triggerNode.value?.data.component],
);

function setUpNode(component: string) {
    const action = menu.value.action;

    switch (action) {
        case 'add':
            store.dispatch('flow/' + ConnectFlowModelingActions.PUSH_NEW_NODE, {
                data: { component },
            });
            break;
        case 'update':
            store.dispatch('flow/' + ConnectFlowModelingActions.EDIT_NODE, {
                data: { component },
            });
            break;
    }

    showForm.value = false;
}

function getNodeComponent(component: string) {
    switch (component) {
        case NodeType.TIME_VALIDATION:
            return ClockIcon;
        case NodeType.OPEN_EDGE:
            return PlusCircleIcon;
        case NodeType.PLAY_AUDIO:
            return PlayIcon;
        case NodeType.CLIENT_INFO:
            return QuestionMarkCircleIcon;
        case NodeType.TRANSFER_EXTENSION:
        case NodeType.TRANSFER_EXTERNAL:
        case NodeType.TRANSFER_QUEUE:
            return ReplyIcon;
        case NodeType.TRANSFER_SURVEY:
            return AnnotationIcon;
        case NodeType.HANG_UP:
            return PhoneMissedCallIcon;
        case NodeType.GO_TO:
            return ReceiptRefundIcon;
    }
}
</script>

<template>
    <UiRightPanel v-model="showForm" :title="$t('connectFlow.componentSelector')">
        <form autocomplete="off" class="flex flex-col gap-3" v-if="rules !== undefined">
            <div class="grid h-full w-full grid-cols-1 gap-3">
                <template v-for="type in NodeType" :key="`nodeButton${type}`">
                    <div
                        type="button"
                        v-if="rules.typesChildren.includes(type)"
                        @click="setUpNode(type)"
                        class="flex cursor-pointer items-center rounded bg-gray-50 shadow ring-1 ring-gray-300 hover:bg-white focus:ring-2 focus:ring-opacity-50"
                    >
                        <div
                            class="flex h-full flex-col justify-center rounded-l-lg bg-gray-50 px-2 py-4"
                        >
                            <component
                                :is="getNodeComponent(type)"
                                class="text-secondary h-8 w-8"
                            />
                        </div>
                        <div class="flex flex-col items-start p-3">
                            <p class="font-semibold text-gray-700">
                                {{ $t(`connectFlow.node.${type}.title`) }}
                            </p>
                            <p class="text-left text-xs text-gray-500">
                                {{ $t(`connectFlow.node.${type}.helpText`) }}
                            </p>
                        </div>
                    </div>
                </template>
            </div>
        </form>
    </UiRightPanel>
</template>
