import { createI18n } from 'vue-i18n';
import { enUS, ptBR } from '@/locales';

export enum Locales {
    enUS = 'en-US',
    ptBR = 'pt-BR',
}

export type MessageSchema = typeof ptBR;

export const defaultLocale = Locales.ptBR;

export const currentLocale =
    (localStorage.getItem('locale') as Locales) || navigator.language || defaultLocale;

export const messages = {
    [Locales.enUS]: enUS,
    [Locales.ptBR]: ptBR,
};

export const i18n = createI18n<[MessageSchema], Locales.enUS | Locales.ptBR>({
    legacy: false,
    globalInjection: true,
    locale: currentLocale,
    fallbackLocale: defaultLocale,
    messages,
});

export default i18n;
