<script lang="ts" setup>
import { computed, PropType } from 'vue';
import { IntegrationLogModel } from '@/types';
import { formatDateAsString } from '@/utils/datetime';

const props = defineProps({
    modelValue: {
        type: Object as PropType<IntegrationLogModel>,
        required: true,
    },
});

const callSource = computed<string>(() => {
    return (
        props.modelValue.call.events
            .filter((ce) => ce.sourceUser?.name)
            .map((ce) => ce.sourceUser?.name)
            .filter((ce, pos, self) => self.indexOf(ce) == pos)
            .join(', ') || props.modelValue.call.sourceNumber
    );
});

const callDestination = computed<string>(() => {
    return (
        props.modelValue.call.events
            .filter((ce) => ce.destinationUser?.name)
            .map((ce) => ce.destinationUser?.name)
            .filter((ce, pos, self) => self.indexOf(ce) == pos)
            .join(', ') || props.modelValue.call.destinationNumber
    );
});
</script>

<template>
    <tr class="hover:bg-white">
        <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-800">
            {{ modelValue.call.id }}
        </td>
        <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
            {{
                formatDateAsString(modelValue.call.startedAt?.toString(), $t('core.dateTimeFormat'))
            }}
        </td>
        <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
            {{ $t('core.options.call.type.' + modelValue.call.type.toLowerCase()) }}
        </td>
        <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
            {{ $t('core.options.call.status.' + modelValue.call.status.toLowerCase()) }}
        </td>
        <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
            {{ callSource }}
        </td>
        <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
            {{ callDestination }}
        </td>
        <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
            {{ formatDateAsString(modelValue.createdAt.toString(), $t('core.dateTimeFormat')) }}
        </td>
        <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
            {{ $t(`integration.logs.filters.status.options.${modelValue.status}`) }}
        </td>
        <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
            {{ modelValue.externalId }}
        </td>
        <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
            {{ modelValue.observation }}
        </td>
    </tr>
</template>
