<script lang="ts" setup>
defineProps({
    data: {
        type: Object,
        required: false,
    },
});
</script>

<template>
    <div class="border border-red-400">
        <h2 class="title">This is the end</h2>
    </div>
</template>
