import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, Ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AxiosResponse } from 'axios';

import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { required, maxLength } from '@/utils/validators';

import { useUiStore } from '@/stores';
import { goBack } from '@/composables/useNavigation';
import { IntegrationService } from '@/services';
import { toast, toastServiceError } from '@/services/core/notification';

import {
    FormAction,
    DefaultServiceResult,
    IntegrationServiceCreateResult,
    IntegrationType,
    PipedriveIntegrationModel,
    PipedriveIntegrationFormState,
    Permission,
} from '@/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'PipedriveIntegrationFormPage',
  props: {
    action: {
        type: String,
        default: FormAction.CREATE,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();

const uiStore = useUiStore();
const route = useRoute();

const props = __props;

const formState = reactive<PipedriveIntegrationFormState>({
    type: IntegrationType.Pipedrive,
    token: null,
    checkActivityAsDone: true,
    associateWithDeal: true,
    isActive: true,
});

const rules = computed<ValidationArgs<PipedriveIntegrationFormState>>(() => ({
    type: { required },
    token: { required, maxLength: maxLength(250) },
    checkActivityAsDone: { required },
    associateWithDeal: { required },
    isActive: { required },
}));

const v$: Ref<Validation<ValidationArgs<PipedriveIntegrationFormState>>> = useVuelidate(
    rules,
    formState,
);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getIntegration = async (id: number) => {
    await setLoading(true);
    try {
        const { data } = await IntegrationService.get<PipedriveIntegrationModel>(id);
        Object.assign(formState, data);
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const onSubmit = async () => {
    const isValid = await v$.value.$validate();
    if (!isValid) return;

    await setLoading(true);

    try {
        let response: AxiosResponse<DefaultServiceResult | IntegrationServiceCreateResult>;

        if (props.action === FormAction.EDIT) {
            const id = Number(route.params.id);
            response = await IntegrationService.update<DefaultServiceResult>(id, formState);
        } else {
            response = await IntegrationService.create<IntegrationServiceCreateResult>(formState);
        }

        toast.success(response.data.message);
        v$.value.$reset();
        goBack({ name: 'ListIntegration' });
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

onMounted(() => {
    if (props.action === FormAction.EDIT) {
        getIntegration(Number(route.params.id));
    }
});

const __returned__ = { uiStore, route, props, formState, rules, v$, setLoading, getIntegration, onSubmit, get Permission() { return Permission } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})