import { defineStore } from 'pinia';
import { defaultLocale, Locales } from '@/plugins/i18n';
import { moment } from '@/utils/datetime';
import { UiState } from '@/types';

export const useUiStore = defineStore('ui', {
    state: (): UiState => ({
        isLoading: false,
        currentLocale: defaultLocale,
        isLevelLocked: false,
    }),

    actions: {
        setIsLoading(isLoading: boolean) {
            this.isLoading = isLoading;
        },

        setLocale(currentLocale: string) {
            let newLocale = Locales.ptBR;

            if (this.currentLocale === '' && currentLocale === '') {
                currentLocale = localStorage.getItem('currentLocale') ?? Locales.ptBR;
            }

            switch (currentLocale) {
                case Locales.enUS:
                case 'en-US':
                    moment.locale('en');
                    newLocale = Locales.enUS;
                    break;
                default:
                case 'pt-BR':
                    moment.locale('pt-br');
                    newLocale = Locales.ptBR;
                    break;
            }

            localStorage.setItem('currentLocale', newLocale);
            this.currentLocale = newLocale;
        },

        setIsLevelLocked(isLevelLocked: boolean) {
            this.isLevelLocked = isLevelLocked;
        },
    },
});
