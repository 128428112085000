import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-100 relative h-4"
}
const _hoisted_2 = { class: "invisible absolute -ml-1 flex flex-col rounded border border-gray-200 bg-gray-50 px-2 group-hover:visible" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.callDuration)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.transcripts, (transcript) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `bullet-${$props.bulletType}-${$props.participantType}-${transcript.id}`,
            class: _normalizeClass(["group absolute h-4 w-4 rounded-full p-0 hover:-m-2 hover:h-8 hover:w-8 hover:bg-gray-50 hover:transition-all", $setup.bulletColor]),
            style: _normalizeStyle({ left: $setup.getPositionBullet(transcript) })
          }, [
            _createVNode($setup["PlayIcon"], {
              class: _normalizeClass(["invisible h-full w-full cursor-pointer group-hover:visible", $setup.playColor]),
              onClick: ($event: any) => ($setup.playSnippet(transcript.startedAt, transcript.endedAt))
            }, null, 8 /* PROPS */, ["onClick"]),
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getWordsByType(), (word) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `bullet-${$props.bulletType}-${$props.participantType}-${transcript.id}-${word}`,
                  class: "z-50 whitespace-nowrap text-gray-700"
                }, _toDisplayString(word), 1 /* TEXT */))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ], 4 /* STYLE */))
        }), 128 /* KEYED_FRAGMENT */))
      ]))
    : _createCommentVNode("v-if", true)
}