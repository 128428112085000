<script lang="ts" setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useFilterStore } from '@/stores';
import { RealtimeReportService } from '@/services';
import { toastServiceError } from '@/services/core/notification';

import { formatSecToTime } from '@/utils/datetime';
import { applyPhoneMask } from '@/utils/number';

import { LevelModel } from '@/types';
import { RealtimeReportServiceGetQueueCallsResult } from '@/types/services/RealtimeReportService';

const filterStore = useFilterStore();

const loading = ref(false);
const items = ref<RealtimeReportServiceGetQueueCallsResult[]>([]);

const levelFilter = computed<LevelModel | null>(() => filterStore.level);

const fetchData = async () => {
    try {
        loading.value = true;
        const response =
            await RealtimeReportService.getQueueCalls<RealtimeReportServiceGetQueueCallsResult[]>();
        items.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        loading.value = false;
    }
};

let interval: number;

watch(levelFilter, () => {
    items.value = [];
    clearInterval(interval);
    interval = setInterval(fetchData, 5000);
});

onMounted(() => {
    interval = setInterval(fetchData, 5000);
});

onUnmounted(() => {
    clearInterval(interval);
});
</script>

<template>
    <div class="relative flex flex-col">
        <h1 class="mb-2 text-lg font-semibold">
            {{ $t('report.realtime.detailsQueue.title') }}
        </h1>
        <UiPanel class="flex-1 overflow-y-auto" no-padding>
            <table class="min-w-full border-separate border-spacing-0">
                <thead>
                    <tr>
                        <th
                            scope="col"
                            class="sticky top-0 z-10 whitespace-nowrap border-b border-gray-300 bg-gray-200 py-1.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-800"
                        >
                            {{ $t('report.realtime.detailsQueue.sourceNumber') }}
                        </th>
                        <th
                            scope="col"
                            class="sticky top-0 z-10 max-h-4 whitespace-nowrap border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-800 backdrop-blur backdrop-filter sm:table-cell"
                        >
                            {{ $t('report.realtime.detailsQueue.destinationNumber') }}
                        </th>
                        <th
                            scope="col"
                            class="sticky top-0 z-10 max-h-4 whitespace-nowrap border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-800 backdrop-blur backdrop-filter sm:table-cell"
                        >
                            {{ $t('report.realtime.detailsQueue.queueName') }}
                        </th>
                        <th
                            scope="col"
                            class="sticky top-0 z-10 max-h-4 whitespace-nowrap border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-center text-sm font-semibold text-gray-800 backdrop-blur backdrop-filter lg:table-cell"
                        >
                            {{ $t('report.realtime.detailsQueue.duration') }}
                        </th>
                    </tr>
                </thead>
                <tbody v-if="items.length">
                    <tr
                        v-for="(item, idx) in items"
                        :key="`queueDetailItem${idx}`"
                        class="text-gray-700"
                    >
                        <td
                            class="whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium"
                        >
                            {{ applyPhoneMask(item.numberFrom) }}
                        </td>
                        <td
                            class="whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium"
                        >
                            {{ applyPhoneMask(item.numberTo) }}
                        </td>
                        <td
                            class="whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium"
                        >
                            {{ item.queueName }}
                        </td>
                        <td
                            class="whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-center text-sm font-medium"
                        >
                            {{ item.duration ? formatSecToTime(item.duration) : '-' }}
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr class="text-gray-700">
                        <td
                            colspan="4"
                            class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-center text-sm font-medium"
                        >
                            {{ $t('report.realtime.detailsQueue.empty') }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </UiPanel>

        <UiSpinner v-if="loading" class="theme-color absolute right-2 top-2 h-4 w-4" />
    </div>
</template>
