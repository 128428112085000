import { defineComponent as _defineComponent } from 'vue'
import { PropType } from 'vue';
import { ArrowCircleDownIcon, ArrowCircleUpIcon } from '@heroicons/vue/solid';
import { SortMenuItem, SortMenuItemDirection } from '@/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiSortMenuBadge',
  props: {
    item: {
        type: Object as PropType<SortMenuItem>,
        required: true,
    },
},
  emits: ['remove'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;

const handleRemoveClick = (item: SortMenuItem) => {
    emit('remove', item);
};

const __returned__ = { props, emit, handleRemoveClick, get ArrowCircleDownIcon() { return ArrowCircleDownIcon }, get ArrowCircleUpIcon() { return ArrowCircleUpIcon }, get SortMenuItemDirection() { return SortMenuItemDirection } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})