import { ScheduleWeekday, FullAudit, IsActiveFlag } from '@/types';

export interface ScheduleModel extends IsActiveFlag, FullAudit {
    id: number;
    levelId: number;
    name: string;
    [ScheduleWeekday.MONDAY]: boolean;
    [ScheduleWeekday.TUESDAY]: boolean;
    [ScheduleWeekday.WEDNESDAY]: boolean;
    [ScheduleWeekday.THURSDAY]: boolean;
    [ScheduleWeekday.FRIDAY]: boolean;
    [ScheduleWeekday.SATURDAY]: boolean;
    [ScheduleWeekday.SUNDAY]: boolean;
    timeBegin: string;
    timeEnd: string;
}
