import {
    FullAudit,
    IsActiveFlag,
    LevelModel,
    NumberModel,
    RoleModel,
    UserExtensionModel,
    UserIntegrationModel,
    UserNumberModel,
} from '@/types';

export interface UserModel extends IsActiveFlag, FullAudit {
    id: number;
    name: string;
    email: string;
    lastLogin?: string;
    lastDeviceLogin?: string;
    avatarUrl?: string;
    language?: string;
    standardLevelId?: number;
    hasMeeting?: boolean;
    useSmartCallerId?: boolean;
    integrations: UserIntegrationModel[];
    currentLevel?: LevelModel;
    roles?: RoleModel[];
    levels?: LevelModel[];
    number?: UserNumberModel;
    numbersModel?: NumberModel;
    devices: {
        mobile: boolean;
        desktop: boolean;
        web: boolean;
        external: boolean;
    };
    deviceExternal?: {
        hostname: string;
        sipUser: string;
        sipPassword: string;
    };
    extension: UserExtensionModel;
}
