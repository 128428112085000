import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "text-xl font-semibold text-gray-800" }
const _hoisted_2 = { class: "flex items-center space-x-4" }
const _hoisted_3 = { class: "flex flex-col justify-end pb-1.5" }
const _hoisted_4 = { class: "block text-sm font-medium text-gray-800" }
const _hoisted_5 = {
  key: 0,
  class: "text-danger bg-danger-100 p-2 text-sm shadow"
}
const _hoisted_6 = { class: "block text-sm font-medium text-gray-800" }
const _hoisted_7 = {
  controls: "",
  class: "w-full"
}
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiBackButton = _resolveComponent("UiBackButton")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!
  const _component_UiTextInput = _resolveComponent("UiTextInput")!
  const _component_UiCheckboxInput = _resolveComponent("UiCheckboxInput")!
  const _component_UiAudioUpload = _resolveComponent("UiAudioUpload")!
  const _component_UiCheckDirtyBeforeRouteLeave = _resolveComponent("UiCheckDirtyBeforeRouteLeave")!

  return (_openBlock(), _createElementBlock("form", {
    class: "space-y-4 p-4 sm:p-6 lg:p-8",
    autocomplete: "off",
    onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
  }, [
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('connectFlowAudio.' + $setup.props.action + '.title')), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_UiBackButton, { fallbackTo: { name: 'ListConnectFlowAudio' } }),
          (
                            ($setup.props.action == $setup.FormAction.CREATE &&
                                _ctx.$can($setup.Permission.CREATE_CONNECT_FLOW_AUDIO)) ||
                            ($setup.props.action == $setup.FormAction.EDIT &&
                                _ctx.$can($setup.Permission.EDIT_CONNECT_FLOW_AUDIO))
                        )
            ? (_openBlock(), _createBlock(_component_UiButton, {
                key: 0,
                type: "submit",
                variant: "primary",
                "text-variant": "white"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Save')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode($setup["UiPanel"], { class: "grid grid-cols-1 gap-4 md:grid-cols-3" }, {
      default: _withCtx(() => [
        _createVNode(_component_UiTextInput, {
          modelValue: $setup.v$.name.$model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.v$.name.$model) = $event)),
          name: "name",
          type: "text",
          label: _ctx.$t('connectFlowAudio.labels.name'),
          required: "",
          errors: $setup.v$.name.$errors,
          onBlur: $setup.v$.name.$touch
        }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_UiCheckboxInput, {
            modelValue: $setup.v$.isActive.$model,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.v$.isActive.$model) = $event)),
            name: "isActive",
            label: _ctx.$t('connectFlowAudio.labels.isActive'),
            class: "h-6"
          }, null, 8 /* PROPS */, ["modelValue", "label"])
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createCommentVNode(" Audio upload "),
    _createVNode($setup["UiPanel"], { class: "flex flex-col space-y-2" }, {
      default: _withCtx(() => [
        _createElementVNode("label", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.$t('connectFlowAudio.labels.audioUpload')) + " ", 1 /* TEXT */),
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-xs" }, "*", -1 /* HOISTED */))
        ]),
        _createVNode(_component_UiAudioUpload, {
          file: $setup.file,
          "onUpdate:file": _cache[2] || (_cache[2] = ($event: any) => (($setup.file) = $event)),
          "text-rule": _ctx.$t('connectFlowAudio.create.uploadRules'),
          errors: $setup.v$.audioFilePath.$errors
        }, null, 8 /* PROPS */, ["file", "text-rule", "errors"]),
        ($setup.v$.audioFilePath.$errors && $setup.v$.audioFilePath.$errors.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.v$.audioFilePath.$errors, (error) => {
                return (_openBlock(), _createElementBlock("p", {
                  key: error.$uid
                }, _toDisplayString(error.$message), 1 /* TEXT */))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createCommentVNode(" Audio preview "),
    ($setup.audioPreview)
      ? (_openBlock(), _createBlock($setup["UiPanel"], {
          key: 0,
          class: "space-y-2"
        }, {
          default: _withCtx(() => [
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('connectFlowAudio.labels.audioPreview')), 1 /* TEXT */),
            _createElementVNode("audio", _hoisted_7, [
              _createElementVNode("source", { src: $setup.audioPreview }, null, 8 /* PROPS */, _hoisted_8),
              _cache[4] || (_cache[4] = _createTextVNode(" Your browser does not support the audio element. "))
            ])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_UiCheckDirtyBeforeRouteLeave, {
      dirty: $setup.v$.$anyDirty
    }, null, 8 /* PROPS */, ["dirty"])
  ], 32 /* NEED_HYDRATION */))
}