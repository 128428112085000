import { CallsStats, CallsStatsPerDay } from '@/types';

export interface AgentProductivityPerDayReportServiceGetInfoParams {
    startDate: string;
    endDate: string;
    user: number | null;
    userLabel: number | null;
}

export interface AgentProductivityPerDayReportServiceGetInfoResult {
    stats: CallsStats;
    callsStatsPerDay: CallsStatsPerDay[];
}
