import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, reactive, watch, onMounted, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { AxiosResponse } from 'axios';

import { useUiStore } from '@/stores';
import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';

import { required, maxLength } from '@/utils/validators';
import { toast, toastServiceError } from '@/services/core/notification';
import { getAudioFormat } from '@/utils/audio';
import { goBack } from '@/composables/useNavigation';

import { ConnectFlowAudioService, VaultService } from '@/services';
import { FormAction, ConnectFlowAudioModel, ConnectFlowAudioFormState, Permission } from '@/types';

import { UiPanel } from '@/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowAudioFormPage',
  props: {
    action: { type: String, default: FormAction.CREATE },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const route = useRoute();
const uiStore = useUiStore();

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const formState = reactive<ConnectFlowAudioFormState>({
    name: null,
    audioFilePath: null,
    isActive: true,
});

const file = ref<File | null>(null);
const audioPreview = ref<string | null>(null);

const rules = computed<ValidationArgs<ConnectFlowAudioFormState>>(() => ({
    name: { required, maxLength: maxLength(50) },
    audioFilePath: { required },
    isActive: { required },
}));

const v$: Ref<Validation<ValidationArgs<ConnectFlowAudioFormState>>> = useVuelidate(
    rules,
    formState,
);

const fetchConnectFlowAudio = async (id: number) => {
    try {
        await setLoading(true);
        const { data } = await ConnectFlowAudioService.get<ConnectFlowAudioModel>(id);
        formState.name = data.name;
        formState.audioFilePath = data.audioFilePath;
        formState.isActive = data.isActive;
        loadAudio();
    } catch (e) {
        toastServiceError(e);
    } finally {
        await setLoading(false);
    }
};

const loadAudio = async () => {
    if (formState.audioFilePath) {
        try {
            const { data } = await VaultService.getAudioAsset(formState.audioFilePath);
            const blob = new Blob([data], { type: getAudioFormat(formState.audioFilePath) });
            file.value = new File([blob], formState.audioFilePath, {
                type: blob.type,
                lastModified: Date.now(),
            });
        } catch (e) {
            toastServiceError(e);
        }
    }
};

const onSubmit = async () => {
    const isFormValid = await v$.value.$validate();
    if (!isFormValid) return;

    await setLoading(true);

    const handleServiceSuccess = (message: string, id: number) => {
        toast.success(message);
        v$.value.$reset();
        goBack({ name: 'ViewConnectFlowAudioById', params: { id } });
    };

    try {
        const formData = new FormData();
        formData.append('name', formState.name || '');
        formData.append('isActive', formState.isActive.toString());

        if (file.value) {
            formData.append('audio', file.value);
        }

        if (props.action === FormAction.EDIT) {
            const id = Number(route.params.id);
            const { data }: AxiosResponse = await ConnectFlowAudioService.update(id, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            handleServiceSuccess(data.message, id);
        } else {
            const { data }: AxiosResponse = await ConnectFlowAudioService.create(formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            handleServiceSuccess(data.message, data.result.id);
        }
    } catch (e) {
        toastServiceError(e);
    } finally {
        await setLoading(false);
    }
};

watch([file], () => {
    if (file.value) {
        formState.audioFilePath = file.value.name;
        audioPreview.value = URL.createObjectURL(file.value);
    } else {
        formState.audioFilePath = '';
        audioPreview.value = null;
    }
});

onMounted(async () => {
    if (props.action === FormAction.EDIT) {
        await fetchConnectFlowAudio(Number(route.params.id));
    }
});

const __returned__ = { props, route, uiStore, setLoading, formState, file, audioPreview, rules, v$, fetchConnectFlowAudio, loadAudio, onSubmit, get FormAction() { return FormAction }, get Permission() { return Permission }, get UiPanel() { return UiPanel } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})