/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    LevelState,
    LevelMutations,
    LevelActions,
    LevelGetters,
    LevelFilter,
    SortMenuItem,
    FilterItem,
} from '@/types';

const getDefaultState = () => {
    return {
        search: null,
        sort: [],
        page: 1,
        filter: {
            roles: [],
            isActive: null,
        },
    } as LevelState;
};

const state: LevelState = getDefaultState();

const mutations: MutationTree<LevelState> = {
    [LevelMutations.SET_SEARCH]: (state, search: string) => (state.search = search),
    [LevelMutations.SET_SORT]: (state, sort: SortMenuItem[]) => (state.sort = sort),
    [LevelMutations.SET_PAGE]: (state, page: number) => (state.page = page),
    [LevelMutations.SET_FILTER](state, filter: LevelFilter) {
        state.filter.isActive = filter.isActive;
    },
    [LevelMutations.CLEAR_FILTER](state, item?: FilterItem) {
        switch (item?.field) {
            case 'isActive':
                state.filter.isActive = getDefaultState().filter.isActive;
                break;
            default:
                state.filter.isActive = getDefaultState().filter.isActive;
        }
    },
};

const actions: ActionTree<LevelState, any> = {
    [LevelActions.SET_SEARCH]: ({ commit }, search: string) =>
        commit(LevelMutations.SET_SEARCH, search),
    [LevelActions.SET_SORT]: ({ commit }, sort: SortMenuItem[]) =>
        commit(LevelMutations.SET_SORT, sort),
    [LevelActions.SET_PAGE]: ({ commit }, page: number) => commit(LevelMutations.SET_PAGE, page),
    [LevelActions.SET_FILTER]: ({ commit }, filter: LevelFilter) =>
        commit(LevelMutations.SET_FILTER, filter),
    [LevelActions.CLEAR_FILTER]: ({ commit }, item: FilterItem) =>
        commit(LevelMutations.CLEAR_FILTER, item),
};

const getters: GetterTree<LevelState, any> = {
    [LevelGetters.SEARCH]: (state): string | null => state.search,
    [LevelGetters.SORT]: (state): SortMenuItem[] => state.sort,
    [LevelGetters.PAGE]: (state): number => state.page,
    [LevelGetters.FILTER]: (state): LevelFilter => state.filter,
    [LevelGetters.DEFAULT_FILTER]: (): LevelFilter => getDefaultState().filter,
    [LevelGetters.ACTIVE_FILTERS](state): FilterItem[] {
        const filters: Array<FilterItem> = [];

        if (state.filter.isActive !== null) {
            filters.push({
                field: 'isActive',
                value: state.filter.isActive.value,
                label: state.filter.isActive.label,
            });
        }

        return filters;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
