import { defineComponent as _defineComponent } from 'vue'
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AxiosResponse } from 'axios';

import { useUiStore } from '@/stores';
import SpamNumberService from '@/services/api/SpamNumberService';
import { toastServiceError } from '@/services/core/notification';
import { Permission, SpamNumberModel } from '@/types';
import { applyPhoneMask } from '@/utils/number';


export default /*@__PURE__*/_defineComponent({
  __name: 'SpamNumberDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const uiStore = useUiStore();

const spamNumber = ref<SpamNumberModel | undefined>(undefined);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchSpamNumber = async (id: number) => {
    setLoading(true);
    try {
        const response: AxiosResponse<SpamNumberModel> =
            await SpamNumberService.get<SpamNumberModel>(id);
        spamNumber.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

onMounted(() => {
    const id = Number(route.params.id);
    if (!isNaN(id)) {
        fetchSpamNumber(id);
    }
});

const __returned__ = { route, uiStore, spamNumber, setLoading, fetchSpamNumber, get Permission() { return Permission }, get applyPhoneMask() { return applyPhoneMask } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})