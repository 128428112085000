<script lang="ts" setup>
import { onMounted, ref, Ref, computed, WritableComputedRef, PropType } from 'vue';
import {
    ConnectFlowModelingActions,
    ConnectFlowModelingGetters,
    ConnectFlowModelingMutations,
    VueFlowNodeModel,
    MultiSelectOption,
} from '@/types';
import store from '@/store';
import { ReplyIcon, PlusIcon } from '@heroicons/vue/outline';

import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { useI18n } from 'vue-i18n';
import { v4 as uuidv4 } from 'uuid';
import TransferToExternalNumberField from '@/views/pages/connect-flow/components/flow/nodes/FormElements/TransferToExternalNumberField.vue';
import { requiredIf } from '@/utils/validators';

const { t } = useI18n();

const props = defineProps({
    node: {
        type: Object as PropType<VueFlowNodeModel>,
        required: true,
    },
});

const showForm = ref(false);

const nodeWritable: WritableComputedRef<VueFlowNodeModel> = computed({
    get: () => store.getters['flow/' + ConnectFlowModelingGetters.ELEMENT_BY_ID](props.node.id),
    set: (value: VueFlowNodeModel) => {
        store.commit('flow/' + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
            idx: null,
            value,
        });
    },
});

interface IExternalNumber {
    id: string;
    phone: string;
}

interface IExternalFormState {
    numbers: IExternalNumber[];
    redirectType: undefined | string;
    whisper: null | string;
}

const formState: Ref<IExternalFormState> = ref({
    numbers: [],
    redirectType: undefined,
    whisper: null,
});

const rules = computed<ValidationArgs<IExternalFormState>>(() => {
    const r: ValidationArgs<IExternalFormState> = {
        numbers: {},
        redirectType: {
            required: requiredIf(() => {
                return formState.value.numbers.length > 1;
            }),
        },
        whisper: {},
    };
    return r;
});
const v$: Ref<Validation<ValidationArgs<IExternalFormState>>> = useVuelidate(rules, formState);

const CallingRedirectType = {
    SEQUENTIAL: 'SEQUENTIAL',
    SIMULTANEOUS: 'SIMULTANEOUS',
};

const optionsType: Ref<MultiSelectOption[]> = ref([
    {
        label: t('connectFlow.node.transferToExternal.types.sequential'),
        value: CallingRedirectType.SEQUENTIAL,
    },
    {
        label: t('connectFlow.node.transferToExternal.types.simultaneous'),
        value: CallingRedirectType.SIMULTANEOUS,
    },
]);

const nodeText = computed(() => {
    let text = t('connectFlow.node.transferToExternal.empty');
    if (nodeWritable.value.data.callTo !== undefined) {
        text = t('connectFlow.node.transferToExternal.callTo', {
            number: nodeWritable.value.data.callTo.join('; '),
        });
    }
    return text;
});

const addNumber = () => {
    formState.value.numbers.push({
        id: uuidv4(),
        phone: '',
    });
};
const removeNumber = (index: number) => {
    formState.value.numbers.splice(index, 1);
};

const isFormCorrect = ref(true);

function removeNode() {
    store.dispatch('flow/' + ConnectFlowModelingActions.DELETE_NODE, {
        nodeId: nodeWritable.value.id,
        parentId: nodeWritable.value.parentId,
    });
}

async function onSubmit() {
    isFormCorrect.value = await v$.value.$validate();
    if (isFormCorrect.value && formState.value.numbers.length) {
        nodeWritable.value.data.callTo = formState.value.numbers.map((item) => item.phone);
        nodeWritable.value.data.redirectType = formState.value.redirectType ?? undefined;
        nodeWritable.value.data.whisper = formState.value.whisper ?? undefined;

        if (formState.value.numbers.length === 1) {
            nodeWritable.value.data.redirectType = undefined;
        }

        showForm.value = false;
    }
    nodeWritable.value.data.hasErrors = !isFormCorrect.value;
    v$.value.$reset();
}

onMounted(() => {
    if (nodeWritable.value.data.callTo !== undefined) {
        formState.value.numbers = nodeWritable.value.data.callTo.map((item) => {
            return { id: uuidv4(), phone: item };
        });
    }
    formState.value.redirectType = nodeWritable.value.data.redirectType;
    if (nodeWritable.value.data.whisper !== undefined) {
        formState.value.whisper = nodeWritable.value.data.whisper;
    }

    if (formState.value.numbers.length === 0) {
        formState.value.numbers.push({ id: uuidv4(), phone: '' });
    }

    v$.value.$validate().then((result) => {
        isFormCorrect.value = result;
        nodeWritable.value.data.hasErrors = !isFormCorrect.value;
    });
});
</script>

<template>
    <UiNode
        :nodeId="node.id"
        :title="$t('connectFlow.node.transferToExternal.title')"
        :icon="ReplyIcon"
        showActions
        @editNode="showForm = true"
        @removeNode="removeNode"
        :class="isFormCorrect ? '' : 'border-red-300 shadow-red-300'"
    >
        <div class="font-medium text-gray-600">
            <span>
                {{ nodeText }}
            </span>
        </div>
    </UiNode>
    <UiRightPanel
        v-model="showForm"
        :title="$t('connectFlow.node.transferToExternal.title')"
        :icon="ReplyIcon"
    >
        <form autocomplete="off" class="flex flex-col gap-3" @submit.prevent="onSubmit">
            <template
                v-for="(number, idx) in formState.numbers"
                :key="`${node.id}_number_${number.id}`"
            >
                <TransferToExternalNumberField
                    v-model="formState.numbers[idx].phone"
                    @remove="removeNumber(idx)"
                    :removable="formState.numbers.length > 1"
                />
            </template>
            <UiButton
                class="mt-6 flex items-center text-lg"
                variant="white"
                size="md"
                @click="addNumber"
            >
                <PlusIcon class="h-4 w-4 text-gray-500" />
                <span class="w-full text-sm text-gray-500">{{
                    $t('connectFlow.node.transferToExternal.addNumber')
                }}</span>
            </UiButton>

            <div class="sm:col-span-12" v-if="formState.numbers.length > 1">
                <label class="mb-2 block text-sm font-medium text-gray-800">
                    {{ $t('connectFlow.node.transferToExternal.type') }}
                </label>
                <UiMultiselect
                    name="audio"
                    v-model="formState.redirectType"
                    :options="optionsType"
                    :errors="v$.redirectType.$silentErrors"
                    can-clear
                    primitive
                />
            </div>

            <div class="mt-4 flex flex-col justify-end pb-1.5">
                <UiTextArea
                    :label="$t('connectFlow.node.transferToExternal.addNumber')"
                    name="whisper"
                    v-model="formState.whisper"
                />
            </div>

            <div class="mt-6 grid grid-cols-2 place-content-around gap-4 text-center">
                <UiButton variant="white" text-variant="gray-700" @click="showForm = false">
                    {{ $t('core.actions.Cancel') }}
                </UiButton>
                <UiButton type="submit" variant="primary" text-variant="white">
                    {{ $t('core.actions.Apply') }}
                </UiButton>
            </div>
        </form>
    </UiRightPanel>
</template>
