import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, ComputedRef, onMounted } from 'vue';
import moment from 'moment';
import store from '@/store';
import { useI18n } from 'vue-i18n';
import { useFilterStore, useUiStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import { AgentProductivityPerDayReportService } from '@/services';

import {
    AgentProductivityPerDayReportActions,
    AgentProductivityPerDayReportGetters,
    BarChartConfig,
    CallType,
    ChartDataItem,
    DataItem,
    AgentProductivityPerDayReportFilter,
    AgentProductivityPerDayReportServiceGetInfoParams,
    AgentProductivityPerDayReportServiceGetInfoResult,
    CallsStatsPerDay,
    FilterItem,
    LevelModel,
} from '@/types';

import { AgentProductivityPerDayReportFilters } from '@/views/pages/reports/agent-productivity-per-day/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'AgentProductivityPerDayReportPage',
  setup(__props, { expose: __expose }) {
  __expose();

const reportInfo: Ref<AgentProductivityPerDayReportServiceGetInfoResult | null> = ref(null);

const levelFilter = computed<LevelModel | null>(() => filterStore.level);
const dateRange = computed<Array<Date>>({
    get: () => filterStore.dateRange,
    set: (dateRange: Array<Date>) => filterStore.setDateRange(dateRange),
});
const filter = computed<AgentProductivityPerDayReportFilter>(
    () => store.getters[AgentProductivityPerDayReportGetters.FILTER],
);
const activeFilters: ComputedRef<FilterItem[]> = computed(
    () => store.getters[AgentProductivityPerDayReportGetters.ACTIVE_FILTERS],
);

const filterStore = useFilterStore();
const uiStore = useUiStore();
const { t } = useI18n();

watch([levelFilter, dateRange, activeFilters], () => {
    fetchReportData();
});

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchReportData = async () => {
    const params: AgentProductivityPerDayReportServiceGetInfoParams = {
        startDate: moment.utc(dateRange.value[0]).format('YYYY-MM-DD'),
        endDate: moment.utc(dateRange.value[1]).format('YYYY-MM-DD'),
        user: filter.value.user?.id || null,
        userLabel: filter.value.userLabel?.id || null,
    };

    try {
        setLoading(true);
        const response = await AgentProductivityPerDayReportService.getInfo({ params });
        reportInfo.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveFilter = (filter: FilterItem) => {
    store.dispatch(AgentProductivityPerDayReportActions.CLEAR_FILTER, filter.field);
};

const headerStats = computed<DataItem<string>[]>(() => {
    const stats = reportInfo.value?.stats;
    if (!stats) return [];

    return [
        { name: 'totalCalls', value: stats.total.toString() },
        { name: 'inboundCalls', value: stats.type[CallType.INBOUND].toString() },
        { name: 'outboundCalls', value: stats.type[CallType.OUTBOUND].toString() },
        { name: 'internalCalls', value: stats.type[CallType.INTERNAL].toString() },
        { name: 'uniqueNumbers', value: stats.uniqueNumbers.toString() },
        {
            name: 'averageServiceTime',
            value: moment.utc((stats.averageServiceTime || 0) * 1000).format('HH:mm:ss'),
        },
    ];
});

const yAxisLabelFormatterAsTime = (value: number | string) => {
    value = value || 0;
    return moment.utc((value as number) * 1000).format('HH:mm:ss');
};

const xAxisLabelFormatterAsDate = (value: number | string) => {
    return moment.utc(value).format('DD/MM');
};

const getChart = (
    title: string,
    prop: keyof CallsStatsPerDay,
    color = '#BFDBFE',
    yAxisLabelFormatter = (value: number | string) => String(value),
): BarChartConfig => {
    const stats = reportInfo.value?.callsStatsPerDay || [];
    const category: string[] = [];
    const serie: ChartDataItem<number[]> = {
        name: title,
        value: [],
        color,
    };

    for (let i = stats.length - 1; i >= 0; i--) {
        const item = stats[i];
        category.push(item.date);
        serie.value.push(item[prop] as number);
    }

    return {
        title,
        category: category.reverse(),
        series: [{ ...serie, value: serie.value.reverse() }],
        yAxisLabelFormatter,
    };
};

const charts = computed<BarChartConfig[]>(() => [
    getChart(
        t('report.agentProductivityPerDay.serviceTimePerDayColumnChart.title'),
        'averageServiceTime',
        '#BFDBFE',
        yAxisLabelFormatterAsTime,
    ),
    getChart(
        t('report.agentProductivityPerDay.timeAvailablePerDayColumnChart.title'),
        'averageTimeAvailable',
        '#FBCFE8',
        yAxisLabelFormatterAsTime,
    ),
    getChart(
        t('report.agentProductivityPerDay.durationPerDayColumnChart.title'),
        'averageDuration',
        '#A5F3FC',
        yAxisLabelFormatterAsTime,
    ),
    getChart(
        t('report.agentProductivityPerDay.totalIdleTimePerDayColumnChart.title'),
        'averageTotalIdleTime',
        '#BAE6FD',
        yAxisLabelFormatterAsTime,
    ),
    getChart(
        t('report.agentProductivityPerDay.idleTimePerDayColumnChart.title'),
        'averageIdleTime',
        '#F5D0FE',
        yAxisLabelFormatterAsTime,
    ),
]);

onMounted(() => {
    fetchReportData();
});

const __returned__ = { reportInfo, levelFilter, dateRange, filter, activeFilters, filterStore, uiStore, t, setLoading, fetchReportData, handleRemoveFilter, headerStats, yAxisLabelFormatterAsTime, xAxisLabelFormatterAsDate, getChart, charts, get AgentProductivityPerDayReportFilters() { return AgentProductivityPerDayReportFilters } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})