export interface QueueTimeStats {
    totalCalls: number;
    inboundCalls: number;
    costumersContacted: number;
    nonTalkPercentage: number;
    tma: string;
    averageTimeQueue: string;
    dayBiggestQueue: string;
    timeBiggestQueue: string;
}
