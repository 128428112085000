import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "flex flex-1 items-center" }
const _hoisted_3 = {
  key: 0,
  class: "flex space-x-1"
}
const _hoisted_4 = { class: "sr-only" }
const _hoisted_5 = { class: "mt-2 sm:flex sm:justify-between" }
const _hoisted_6 = { class: "space-x-4 sm:flex" }
const _hoisted_7 = { class: "flex items-center text-sm text-gray-500" }
const _hoisted_8 = { class: "flex items-center text-sm text-gray-500 sm:ml-6 sm:mt-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiIsActiveBadge = _resolveComponent("UiIsActiveBadge")!
  const _component_UiActionButton = _resolveComponent("UiActionButton")!
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock(_component_UiPanel, { class: "hover:bg-white" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can($setup.Permission.VIEW_CONTACT) ? 'RouterLink' : 'span'), {
            to: {
                        name: 'ViewContactById',
                        params: {
                            id: $props.modelValue.id,
                        },
                    },
            class: "text-md text-primary truncate font-medium"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.modelValue.name), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["to"])),
          _createVNode(_component_UiIsActiveBadge, {
            "model-value": $props.modelValue,
            class: "ml-2 text-xs"
          }, null, 8 /* PROPS */, ["model-value"])
        ]),
        (_ctx.$can($setup.Permission.EDIT_CONTACT))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _withDirectives((_openBlock(), _createBlock(_component_UiActionButton, {
                variant: "primary",
                class: "rounded-full",
                to: {
                        name: 'EditContactById',
                        params: {
                            id: $props.modelValue.id,
                        },
                    }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('core.actions.Edit')), 1 /* TEXT */),
                  _createVNode($setup["PencilAltIcon"], {
                    class: "h-6 w-6",
                    "aria-hidden": "true"
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"])), [
                [_directive_tippy, _ctx.$t('core.actions.Edit')]
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, [
            _createVNode($setup["MailIcon"], {
              class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
              "aria-hidden": "true"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('contact.list.emailsCount', $props.modelValue.emails.length || 0, {
                            named: { n: $props.modelValue.emails.length },
                        })), 1 /* TEXT */)
          ]),
          _createElementVNode("p", _hoisted_8, [
            _createVNode($setup["PhoneIcon"], {
              class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
              "aria-hidden": "true"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('contact.list.phonesCount', $props.modelValue.phones.length || 0, {
                            named: { n: $props.modelValue.phones.length },
                        })), 1 /* TEXT */)
          ])
        ]),
        _createVNode($setup["UiAuditInfo"], { "model-value": $props.modelValue }, null, 8 /* PROPS */, ["model-value"])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}