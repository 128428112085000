import { ConnectFlowFlowModel, FullAudit, IsActiveFlag, LevelModel, NumberModel } from '@/types';

export interface ConnectFlowModel extends IsActiveFlag, FullAudit {
    id: number;
    levelId: number;
    level: LevelModel;
    name: string;
    currentFlowId: number;
    lastAppliedFlowId: number;
    numbers?: NumberModel[];
    flows?: ConnectFlowFlowModel[];
}
