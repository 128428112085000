import { defineComponent as _defineComponent } from 'vue'
import { onMounted } from 'vue';
import router from '@/router';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppLoginPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

onMounted(() => {
    if (route.query.token !== null) {
        localStorage.removeItem('level');
        localStorage.removeItem('locale');
        localStorage.setItem('token', route.query.token.toString());
    }
    router.push('/');
});

const __returned__ = { route, onMounted, get router() { return router }, get useRoute() { return useRoute } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})