import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  autocomplete: "off",
  class: "flex flex-col gap-3"
}
const _hoisted_2 = { class: "grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6" }
const _hoisted_3 = { class: "sm:col-span-5" }
const _hoisted_4 = { class: "block text-sm font-medium text-gray-800" }
const _hoisted_5 = { class: "grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6" }
const _hoisted_6 = { class: "sm:col-span-5" }
const _hoisted_7 = { class: "block text-sm font-medium text-gray-800" }
const _hoisted_8 = { class: "grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6" }
const _hoisted_9 = { class: "sm:col-span-5" }
const _hoisted_10 = { class: "block text-sm font-medium text-gray-800" }
const _hoisted_11 = { class: "grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6" }
const _hoisted_12 = { class: "sm:col-span-5" }
const _hoisted_13 = { class: "block text-sm font-medium text-gray-800" }
const _hoisted_14 = { class: "grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6" }
const _hoisted_15 = { class: "sm:col-span-5" }
const _hoisted_16 = { class: "block text-sm font-medium text-gray-800" }
const _hoisted_17 = { class: "mt-4 grid grid-cols-2 place-content-around gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_UiMultiselect = _resolveComponent("UiMultiselect")!
  const _component_UiRightPanel = _resolveComponent("UiRightPanel")!

  return (_openBlock(), _createBlock(_component_UiRightPanel, {
    modelValue: $setup.filterOpened,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.filterOpened) = $event)),
    title: _ctx.$t('filter.sidebar.title')
  }, {
    button: _withCtx(() => [
      _createVNode(_component_UiButton, {
        size: "md",
        variant: "white",
        "text-variant": "gray-700",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.filterOpened = true))
      }, {
        default: _withCtx(() => [
          _createVNode($setup["FilterIcon"], {
            class: "mr-3 h-5 w-5 text-gray-400",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('core.actions.Filter')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createCommentVNode(" User Filter "),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('report.billing.filters.user')), 1 /* TEXT */),
            _createVNode(_component_UiMultiselect, {
              name: "filter_user",
              modelValue: $setup.filter.user,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.filter.user) = $event)),
              label: "name",
              "value-prop": "id",
              options: $setup.userOptions,
              "can-clear": true,
              placeholder: $setup.t('core.options.All')
            }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createCommentVNode(" Source Number Filter "),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('report.billing.filters.sourceNumber')), 1 /* TEXT */),
            _createVNode(_component_UiMultiselect, {
              name: "filter_source_number",
              modelValue: $setup.filter.sourceNumber,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.filter.sourceNumber) = $event)),
              label: "value",
              "value-prop": "value",
              options: 
                                $setup.props.sources.map((source) => ({
                                    value: source,
                                    label: source,
                                }))
                            ,
              "can-clear": true,
              placeholder: $setup.t('core.options.All')
            }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createCommentVNode(" Destination Number Filter "),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('report.billing.filters.destinationNumber')), 1 /* TEXT */),
            _createVNode(_component_UiMultiselect, {
              name: "filter_destination_number",
              modelValue: $setup.filter.destinationNumber,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.filter.destinationNumber) = $event)),
              label: "value",
              "value-prop": "value",
              options: 
                                $setup.props.destinations.map((destination) => ({
                                    value: destination,
                                    label: destination,
                                }))
                            ,
              "can-clear": true,
              placeholder: $setup.t('core.options.All')
            }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createCommentVNode(" Status Filter "),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t('report.billing.filters.status')), 1 /* TEXT */),
            _createVNode(_component_UiMultiselect, {
              name: "filter_status",
              modelValue: $setup.filter.status,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.filter.status) = $event)),
              "value-prop": "value",
              options: [
                                {
                                    value: $setup.CallStatus.ANSWERED,
                                    label: $setup.t('core.options.call.status.answered'),
                                },
                                {
                                    value: $setup.CallStatus.NO_ANSWER,
                                    label: $setup.t('core.options.call.status.no_answer'),
                                },
                                {
                                    value: $setup.CallStatus.BUSY,
                                    label: $setup.t('core.options.call.status.busy'),
                                },
                                {
                                    value: $setup.CallStatus.DITCHED,
                                    label: $setup.t('core.options.call.status.ditched'),
                                },
                            ],
              "can-clear": true,
              placeholder: $setup.t('core.options.All')
            }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createCommentVNode(" Type Filter "),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t('report.billing.filters.type')), 1 /* TEXT */),
            _createVNode(_component_UiMultiselect, {
              name: "filter_type",
              modelValue: $setup.filter.type,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.filter.type) = $event)),
              "value-prop": "value",
              options: [
                                {
                                    value: $setup.CallType.INBOUND,
                                    label: $setup.t('core.options.call.type.inbound'),
                                },
                                {
                                    value: $setup.CallType.OUTBOUND,
                                    label: $setup.t('core.options.call.type.outbound'),
                                },
                            ],
              "can-clear": true,
              placeholder: $setup.t('core.options.All')
            }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createCommentVNode(" Buttons "),
        _createElementVNode("div", _hoisted_17, [
          _createVNode(_component_UiButton, {
            variant: "white",
            "text-variant": "gray-700",
            onClick: _cache[6] || (_cache[6] = ($event: any) => ($setup.clearFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Clear')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_UiButton, {
            variant: "primary",
            "text-variant": "white",
            onClick: _cache[7] || (_cache[7] = ($event: any) => ($setup.applyFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Apply')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title"]))
}