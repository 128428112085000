<script lang="ts" setup>
import { ref, computed, reactive, watch, onMounted, Ref, ComponentPublicInstance } from 'vue';
import { useRoute } from 'vue-router';
import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { AxiosResponse } from 'axios';

import { useUiStore } from '@/stores';
import { goBack } from '@/composables/useNavigation';
import { ConnectFlowSurveyService, VaultService } from '@/services';
import { toast, toastServiceError } from '@/services/core/notification';
import { getAudioFormat } from '@/utils/audio';

import {
    Permission,
    FormAction,
    ConnectFlowSurveyModel,
    ConnectFlowSurveyFormState,
} from '@/types';

import { required, requiredIf, minLength, maxLength } from '@/utils/validators';
import { ConnectFlowSurveyQuestionForm } from '@/views/pages/connect-flow-survey/components';

const props = defineProps({
    action: { type: String, default: FormAction.CREATE },
});

const route = useRoute();
const uiStore = useUiStore();

const formState = reactive<ConnectFlowSurveyFormState>({
    name: null,
    textToAudio: null,
    audioFilePath: null,
    questions: [],
    isActive: true,
});

const file = ref<File | null>(null);
const audioPreview = ref<string | null>(null);
const questionRefs: Ref<InstanceType<typeof ConnectFlowSurveyQuestionForm>[]> = ref([]);

const rules = computed<ValidationArgs<ConnectFlowSurveyFormState>>(() => ({
    name: { required, maxLength: maxLength(500) },
    textToAudio: {
        required: requiredIf(() => !formState.audioFilePath),
        maxLength: maxLength(500),
    },
    audioFilePath: {
        required: requiredIf(() => formState.textToAudio?.length === 0),
    },
    questions: { required, minLength: minLength(1) },
    isActive: { required },
}));

const v$: Ref<Validation<ValidationArgs<ConnectFlowSurveyFormState>>> = useVuelidate(
    rules,
    formState,
);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchConnectFlowSurvey = async (id: number) => {
    try {
        await setLoading(true);
        const { data } = await ConnectFlowSurveyService.get<ConnectFlowSurveyModel>(id);

        formState.name = data.name;
        formState.textToAudio = data.textToAudio;
        formState.audioFilePath = data.audioFilePath;
        formState.questions = data.questions.map((question) => ({
            label: question.label,
            rangeAnswer: question.rangeAnswer,
            textToAudio: question.textToAudio || null,
            audioFile: null,
            audioFilePath: question.audioFilePath || null,
        }));
        formState.isActive = data.isActive;

        await loadAudio();
    } catch (e) {
        toastServiceError(e);
    } finally {
        await setLoading(false);
    }
};

const loadAudio = async () => {
    if (formState.audioFilePath) {
        try {
            const { data } = await VaultService.getAudioAsset(formState.audioFilePath);
            const blob = new Blob([data], { type: getAudioFormat(formState.audioFilePath) });
            file.value = new File([blob], formState.audioFilePath, {
                type: blob.type,
                lastModified: Date.now(),
            });
        } catch (e) {
            toastServiceError(e);
        }
    }
};

const onSubmit = async () => {
    const questionValidationResults = await Promise.all(
        questionRefs.value.map((ref) => ref?.validate?.()),
    );

    const allQuestionsValid = questionValidationResults.every((valid) => valid);
    const isFormValid = await v$.value.$validate();

    if (!isFormValid || !allQuestionsValid) return;

    await setLoading(true);

    const formData = new FormData();
    formData.append('name', formState.name || '');
    formData.append('textToAudio', formState.textToAudio || '');
    if (file.value) formData.append('audio', file.value);
    formData.append('isActive', formState.isActive.toString());

    formState.questions.forEach((question, index) => {
        if (question.id) formData.append(`questions[${index}][id]`, question.id.toString());
        formData.append(`questions[${index}][label]`, question.label || '');
        formData.append(`questions[${index}][rangeAnswer]`, question.rangeAnswer || '');
        formData.append(`questions[${index}][textToAudio]`, question.textToAudio || '');
        if (question.audioFile)
            formData.append(`questions[${index}][question-audio]`, question.audioFile);
        if (question.audioFilePath)
            formData.append(`questions[${index}][audioFilePath]`, question.audioFilePath || '');
    });

    const handleServiceSuccess = (message: string, id: number) => {
        toast.success(message);
        v$.value.$reset();
        goBack({ name: 'ViewConnectFlowSurveyById', params: { id } });
    };

    try {
        if (props.action === FormAction.EDIT) {
            const id = Number(route.params.id);
            const { data }: AxiosResponse = await ConnectFlowSurveyService.update(id, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            handleServiceSuccess(data.message, id);
        } else {
            const { data }: AxiosResponse = await ConnectFlowSurveyService.create(formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            handleServiceSuccess(data.message, data.result.id);
        }
    } catch (e) {
        toastServiceError(e);
    } finally {
        await setLoading(false);
    }
};

const setQuestionRef = (el: Element | ComponentPublicInstance | null, index: number) => {
    if (el && '$el' in el) {
        questionRefs.value[index] = el as InstanceType<typeof ConnectFlowSurveyQuestionForm>;
    } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        questionRefs.value[index] = null as any;
    }
};

const addQuestion = () => {
    formState.questions.push({
        label: null,
        rangeAnswer: null,
        textToAudio: null,
        audioFile: null,
        audioFilePath: null,
    });
};

const removeQuestion = (index: number) => {
    formState.questions.splice(index, 1);
};

watch([file], () => {
    if (file.value) {
        formState.audioFilePath = file.value.name;
        audioPreview.value = URL.createObjectURL(file.value);
    } else {
        formState.audioFilePath = '';
        audioPreview.value = null;
    }
});

onMounted(async () => {
    if (props.action === FormAction.EDIT) {
        const surveyId = Number(route.params.id);
        if (!isNaN(surveyId)) fetchConnectFlowSurvey(surveyId);
    } else {
        addQuestion();
    }
});
</script>

<template>
    <form class="space-y-4 p-4 sm:p-6 lg:p-8" autocomplete="off" @submit.prevent="onSubmit">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('connectFlowSurvey.' + props.action + '.title') }}
                </h1>
            </template>
            <template #actions>
                <div class="flex items-center space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ListConnectFlowSurvey' }" />
                    <UiButton
                        v-if="
                            (props.action == FormAction.CREATE &&
                                $can(Permission.CREATE_CONNECT_FLOW_SURVEY)) ||
                            (props.action == FormAction.EDIT &&
                                $can(Permission.EDIT_CONNECT_FLOW_SURVEY))
                        "
                        type="submit"
                        variant="primary"
                        text-variant="white"
                    >
                        {{ $t('core.actions.Save') }}
                    </UiButton>
                </div>
            </template>
        </UiPageHeader>
        <UiPanel>
            <div class="grid grid-cols-1 gap-4 space-y-4 md:grid-cols-3 md:space-y-0">
                <UiTextInput
                    v-model="v$.name.$model"
                    name="name"
                    type="text"
                    :label="$t('connectFlowSurvey.form.labels.name')"
                    :errors="v$.name.$errors"
                    @blur="v$.name.$touch"
                />

                <UiCheckboxInput
                    v-model="v$.isActive.$model"
                    name="isActive"
                    :label="$t('connectFlowSurvey.form.labels.isActive')"
                    class="md:pt-6"
                />
            </div>
        </UiPanel>
        <UiPanel>
            <h2 class="mb-2 text-lg font-semibold text-gray-800">
                {{ $t('connectFlowSurvey.form.section.initialMessage.title') }}
                <span class="text-xs">*</span>
            </h2>

            <div class="gap-4 space-y-4 md:grid md:grid-cols-3 md:space-y-0">
                <UiTextArea
                    id="textToAudio"
                    name="textToAudio"
                    v-model="formState.textToAudio"
                    :label="$t('connectFlowSurvey.form.labels.textToAudio')"
                    :placeholder="$t('connectFlowSurvey.form.labels.textToAudioPlaceholder')"
                    :rows="4"
                    :disabled="file != undefined"
                    :errors="v$.textToAudio.$errors"
                    class="col-span-2"
                />

                <div class="flex flex-col">
                    <label class="mb-2 block text-sm font-medium text-gray-800">
                        {{ $t('connectFlowSurvey.form.labels.audioUpload') }}
                    </label>
                    <UiAudioUpload
                        v-model:file="file"
                        :text-rule="$t('connectFlowSurvey.form.labels.uploadRules')"
                        :errors="v$.audioFilePath.$errors"
                    />
                    <div
                        class="text-danger bg-danger-100 p-2 text-sm shadow"
                        v-if="v$.audioFilePath.$errors && v$.audioFilePath.$errors.length"
                    >
                        <p v-for="error of v$.audioFilePath.$errors" :key="error.$uid">
                            {{ error.$message }}
                        </p>
                    </div>
                    <audio v-if="audioPreview" controls class="mt-2 w-full">
                        <source :src="audioPreview" />
                        Your browser does not support the audio element.
                    </audio>
                </div>
            </div>
        </UiPanel>
        <UiPanel>
            <h2 class="mb-2 text-lg font-semibold text-gray-800">
                {{ $t('connectFlowSurvey.form.section.questions.title') }}
                <span class="text-xs">*</span>
            </h2>
            <ConnectFlowSurveyQuestionForm
                v-for="(item, index) in formState.questions"
                :key="index"
                :index="index"
                v-model="formState.questions[index]"
                :show-remove="formState.questions.length > 1"
                :ref="(el) => setQuestionRef(el, index)"
                @remove="removeQuestion"
            />
            <div class="mt-2 flex justify-end border-t border-gray-300 pt-4">
                <UiButton variant="primary" text-variant="white" @click="addQuestion">
                    {{ $t('connectFlowSurvey.form.actions.addQuestion') }}
                </UiButton>
            </div>
        </UiPanel>
        <UiCheckDirtyBeforeRouteLeave :dirty="v$.$anyDirty" />
    </form>
</template>
