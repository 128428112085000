import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-4 p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-800" }
const _hoisted_3 = { class: "grid grid-cols-3 gap-4" }
const _hoisted_4 = { class: "grid h-[300px] grid-cols-3 gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('report.realtime.title')), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode($setup["DataTotalize"], {
      data: $setup.dataTotalize,
      loading: $setup.loading
    }, null, 8 /* PROPS */, ["data", "loading"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode($setup["CallQueueGaugeChart"], {
        data: $setup.dataTotalize.callQueue,
        loading: $setup.loading,
        class: "col-span-3 h-[200px] md:col-span-1 md:h-[277px]"
      }, null, 8 /* PROPS */, ["data", "loading"]),
      _createVNode($setup["QueueDetailsTable"], { class: "col-span-3 h-[200px] md:col-span-2 md:h-[277px]" })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode($setup["UsersStatusDoughnutChart"], {
        data: $setup.dataTotalize,
        loading: $setup.loading,
        class: "col-span-3 h-[200px] md:col-span-1 md:h-[277px]"
      }, null, 8 /* PROPS */, ["data", "loading"]),
      _createVNode($setup["UsersExtensionsDetailsTable"], { class: "col-span-3 h-[200px] md:col-span-2 md:h-[277px]" })
    ])
  ]))
}