export interface UserFormState {
    id?: number;
    name: string | null;
    email: string | null;
    password?: string | null;
    confirmPassword?: string | null;
    levels: number[];
    roles: number[];
    isActive: boolean;
    standardLevelId: number | null;
    hasMeeting?: boolean;
    number: UserNumberFormState;
    extension: ExtensionFormState;
    useSmartCallerId?: boolean;
    devices: UserDeviceFormState;
}

export interface UserNumberFormState {
    id?: number | null;
    levelId?: number | null;
    numberId?: number | null;
}

export interface ExtensionFormState {
    id?: number | null;
    levelId: number | null;
    userId?: number;
    label: string | null;
    number: UserNumberFormState | null;
}

export interface UserDeviceFormState {
    mobile: boolean;
    desktop: boolean;
    web: boolean;
    external: boolean;
}
