import { CallParticipantType } from '@/types';

export interface CallTranscriptModel {
    id: number;
    callId: number;
    environmentId: string;
    participant: CallParticipantType;
    language: string;
    transcript: string;
    startedAt: number;
    endedAt: number;
    words: string[];
    registeredWords: string[];
    matchedWords: string[];
}
