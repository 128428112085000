import { CallsStats, UserAgentCallsStats } from '@/types';

export interface AgentProductivityReportServiceGetInfoParams {
    startDate: string;
    endDate: string;
    user: number | null;
    userLabel: number | null;
}

export interface AgentProductivityReportServiceGetInfoResult {
    stats: CallsStats;
    userAgentCallsStats: UserAgentCallsStats[];
}
