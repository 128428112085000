import { Permission } from '@/types';
import type { FunctionalComponent, HTMLAttributes, VNodeProps } from 'vue';

export interface DashboardItem {
    title: string;
    description: string;
    permission?: Permission;
    routeName?: string;
    icon: FunctionalComponent<HTMLAttributes & VNodeProps>;
}
