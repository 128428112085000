export interface NumberServiceGetAllParams {
    page?: number;
    connectFlow?: number;
    hasConnectFlow?: boolean;
    state?: string;
    city?: string;
    per_page?: number;
    order_by?: string;
    is_active?: boolean;
    search?: string;
}
