/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    FilterItem,
    ContactCenterPerDayReportState,
    ContactCenterPerDayReportMutations,
    ContactCenterPerDayReportActions,
    ContactCenterPerDayReportGetters,
    ContactCenterPerDayReportFilter,
} from '@/types';

const getDefaultState = () => {
    return {
        filter: {
            type: null,
            status: null,
            user: null,
            number: null,
            serviceTimeRange: null,
            userLabel: null,
        },
    } as ContactCenterPerDayReportState;
};

const state: ContactCenterPerDayReportState = getDefaultState();

const mutations: MutationTree<ContactCenterPerDayReportState> = {
    [ContactCenterPerDayReportMutations.SET_FILTER](
        state,
        payload: ContactCenterPerDayReportFilter,
    ) {
        state.filter.type = payload.type;
        state.filter.status = payload.status;
        state.filter.user = payload.user;
        state.filter.number = payload.number;
        state.filter.serviceTimeRange = payload.serviceTimeRange;
        state.filter.userLabel = payload.userLabel;
    },
    [ContactCenterPerDayReportMutations.CLEAR_FILTER](state, field?: string) {
        switch (field) {
            case 'type':
                state.filter.type = getDefaultState().filter.type;
                break;
            case 'status':
                state.filter.status = getDefaultState().filter.status;
                break;
            case 'user':
                state.filter.user = getDefaultState().filter.user;
                break;
            case 'number':
                state.filter.number = getDefaultState().filter.number;
                break;
            case 'userLabel':
                state.filter.userLabel = getDefaultState().filter.userLabel;
                break;
            case 'serviceTimeRange':
                state.filter.serviceTimeRange = getDefaultState().filter.serviceTimeRange;
                break;
            default:
                state.filter.type = getDefaultState().filter.type;
                state.filter.status = getDefaultState().filter.status;
                state.filter.user = getDefaultState().filter.user;
                state.filter.number = getDefaultState().filter.number;
                state.filter.serviceTimeRange = getDefaultState().filter.serviceTimeRange;
                state.filter.userLabel = getDefaultState().filter.userLabel;
        }
    },
};

const actions: ActionTree<ContactCenterPerDayReportState, any> = {
    [ContactCenterPerDayReportActions.SET_FILTER]: (
        { commit },
        filter: ContactCenterPerDayReportFilter,
    ) => commit(ContactCenterPerDayReportMutations.SET_FILTER, filter),
    [ContactCenterPerDayReportActions.CLEAR_FILTER]: (
        { commit },
        filter: ContactCenterPerDayReportFilter,
    ) => commit(ContactCenterPerDayReportMutations.CLEAR_FILTER, filter),
};

const getters: GetterTree<ContactCenterPerDayReportState, any> = {
    [ContactCenterPerDayReportGetters.DEFAULT_FILTER]: (): ContactCenterPerDayReportFilter =>
        getDefaultState().filter,
    [ContactCenterPerDayReportGetters.FILTER]: (state): ContactCenterPerDayReportFilter =>
        state.filter,
    [ContactCenterPerDayReportGetters.ACTIVE_FILTERS](state): FilterItem[] {
        const filters: Array<FilterItem> = [];

        if (state.filter.type)
            filters.push({
                field: 'type',
                value: state.filter.type.value,
                label: state.filter.type.label,
            });

        if (state.filter.status)
            filters.push({
                field: 'status',
                value: state.filter.status.value,
                label: state.filter.status.label,
            });

        if (state.filter.user)
            filters.push({
                field: 'user',
                value: state.filter.user.id,
                label: state.filter.user.name,
            });

        if (state.filter.number)
            filters.push({
                field: 'number',
                value: state.filter.number.id,
                label: state.filter.number.number,
            });

        if (state.filter.serviceTimeRange)
            filters.push({
                field: 'serviceTimeRange',
                value: state.filter.serviceTimeRange.value,
                label: state.filter.serviceTimeRange.label,
            });

        if (state.filter.userLabel)
            filters.push({
                field: 'userLabel',
                value: state.filter.userLabel.id,
                label: state.filter.userLabel.label,
            });

        return filters;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
