/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    QueueState,
    QueueMutations,
    QueueActions,
    QueueGetters,
    QueueFilter,
    SortMenuItem,
    FilterItem,
} from '@/types';

const getDefaultState = () => {
    return {
        search: null,
        sort: [],
        page: 1,
        filter: {
            isActive: null,
        },
    } as QueueState;
};

const state: QueueState = getDefaultState();

const mutations: MutationTree<QueueState> = {
    [QueueMutations.SET_SEARCH]: (state, search: string) => (state.search = search),
    [QueueMutations.SET_SORT]: (state, sort: SortMenuItem[]) => (state.sort = sort),
    [QueueMutations.SET_PAGE]: (state, page: number) => (state.page = page),
    [QueueMutations.SET_FILTER](state, filter: QueueFilter) {
        state.filter.isActive = filter.isActive;
    },
    [QueueMutations.CLEAR_FILTER](state, item?: FilterItem) {
        switch (item?.field) {
            case 'isActive':
                state.filter.isActive = getDefaultState().filter.isActive;
                break;
            default:
                state.filter.isActive = getDefaultState().filter.isActive;
        }
    },
};

const actions: ActionTree<QueueState, any> = {
    [QueueActions.SET_SEARCH]: ({ commit }, search: string) =>
        commit(QueueMutations.SET_SEARCH, search),
    [QueueActions.SET_SORT]: ({ commit }, sort: SortMenuItem[]) =>
        commit(QueueMutations.SET_SORT, sort),
    [QueueActions.SET_PAGE]: ({ commit }, page: number) => commit(QueueMutations.SET_PAGE, page),
    [QueueActions.SET_FILTER]: ({ commit }, filter: QueueFilter) =>
        commit(QueueMutations.SET_FILTER, filter),
    [QueueActions.CLEAR_FILTER]: ({ commit }, item: FilterItem) =>
        commit(QueueMutations.CLEAR_FILTER, item),
};

const getters: GetterTree<QueueState, any> = {
    [QueueGetters.SEARCH]: (state): string | null => state.search,
    [QueueGetters.SORT]: (state): SortMenuItem[] => state.sort,
    [QueueGetters.PAGE]: (state): number => state.page,
    [QueueGetters.FILTER]: (state): QueueFilter => state.filter,
    [QueueGetters.DEFAULT_FILTER]: (): QueueFilter => getDefaultState().filter,
    [QueueGetters.ACTIVE_FILTERS](state): FilterItem[] {
        const filters: Array<FilterItem> = [];

        if (state.filter.isActive !== null) {
            filters.push({
                field: 'isActive',
                value: state.filter.isActive.value,
                label: state.filter.isActive.label,
            });
        }

        return filters;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
