import { defineComponent as _defineComponent } from 'vue'
import { onMounted, ref, Ref, computed, WritableComputedRef, PropType } from 'vue';
import {
    ConnectFlowModelingActions,
    ConnectFlowModelingGetters,
    ConnectFlowModelingMutations,
    VueFlowNodeModel,
    MultiSelectOption,
    ConnectFlowSurveyModel,
} from '@/types';

import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { AnnotationIcon } from '@heroicons/vue/outline';
import { required } from '@/utils/validators';
import { useI18n } from 'vue-i18n';
import store from '@/store';

interface ITransferFormState {
    survey: null | ConnectFlowSurveyModel;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TransferToSurvey',
  props: {
    node: {
        type: Object as PropType<VueFlowNodeModel>,
        required: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const props = __props;

const showForm = ref(false);

const nodeWritable: WritableComputedRef<VueFlowNodeModel> = computed({
    get: () => store.getters['flow/' + ConnectFlowModelingGetters.ELEMENT_BY_ID](props.node.id),
    set: (value: VueFlowNodeModel) => {
        store.commit('flow/' + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
            idx: null,
            value,
        });
    },
});

const surveyOptions = computed(
    () => store.getters['flow/' + ConnectFlowModelingGetters.SURVEY_OPTIONS],
);

const formState: Ref<ITransferFormState> = ref({
    survey: null,
});

const rules = computed<ValidationArgs<ITransferFormState>>(() => {
    const r: ValidationArgs<ITransferFormState> = {
        survey: {
            required,
        },
    };
    return r;
});
const v$: Ref<Validation<ValidationArgs<ITransferFormState>>> = useVuelidate(rules, formState);

const nodeText = computed(() => {
    let text = t('connectFlow.node.transferToSurvey.empty');
    if (nodeWritable.value.data.related) {
        text = nodeWritable.value.data.related.label;
    }
    return text;
});

const isFormCorrect = ref(true);

function removeNode() {
    store.dispatch('flow/' + ConnectFlowModelingActions.DELETE_NODE, {
        nodeId: nodeWritable.value.id,
        parentId: nodeWritable.value.parentId,
    });
}
async function onSubmit() {
    isFormCorrect.value = await v$.value.$validate();
    if (isFormCorrect.value) {
        nodeWritable.value.data.related = {
            value: formState.value.survey?.id,
        } as MultiSelectOption;
        showForm.value = false;
    }
    nodeWritable.value.data.hasErrors = !isFormCorrect.value;
    v$.value.$reset();
}

onMounted(() => {
    if (nodeWritable.value.data.related !== null) {
        formState.value.survey = surveyOptions.value.find(
            (survey: ConnectFlowSurveyModel) => survey.id == nodeWritable.value.data.related?.value,
        );
    }

    v$.value.$validate().then((result) => {
        isFormCorrect.value = result;
        nodeWritable.value.data.hasErrors = !isFormCorrect.value;
    });
});

const __returned__ = { t, props, showForm, nodeWritable, surveyOptions, formState, rules, v$, nodeText, isFormCorrect, removeNode, onSubmit, get AnnotationIcon() { return AnnotationIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})