import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-2 items-center rounded bg-gray-50 px-4 py-3 ring-1 ring-gray-300 hover:bg-white focus:ring-2 focus:ring-opacity-50" }
const _hoisted_2 = { class: "mb-2 sm:mb-0" }
const _hoisted_3 = { class: "rounded border-gray-200 bg-gray-50 px-1.5 py-0.5 text-xs ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50" }
const _hoisted_4 = { class: "grid grid-cols-2 gap-3 lg:grid-cols-6" }
const _hoisted_5 = { class: "text-primary flex flex-wrap items-center space-x-1 truncate text-sm font-medium" }
const _hoisted_6 = { class: "block w-full overflow-hidden truncate whitespace-normal md:w-auto" }
const _hoisted_7 = { class: "flex flex-row text-sm lg:col-span-2" }
const _hoisted_8 = { class: "text-xs font-bold text-gray-600" }
const _hoisted_9 = { class: "ml-1 block overflow-hidden truncate whitespace-normal" }
const _hoisted_10 = { class: "flex items-center lg:col-span-1 lg:justify-end" }
const _hoisted_11 = { class: "mt-2 grid grid-cols-2 gap-3 border-t pt-3 lg:grid-cols-6" }
const _hoisted_12 = { class: "flex items-center space-x-1 text-xs text-gray-600" }
const _hoisted_13 = { class: "truncate" }
const _hoisted_14 = { class: "flex items-center space-x-1 text-xs text-gray-600" }
const _hoisted_15 = { class: "flex items-center space-x-1 text-xs text-gray-600" }
const _hoisted_16 = { class: "flex items-center space-x-1 text-xs text-gray-600 lg:col-span-3" }
const _hoisted_17 = { class: "text-xs font-bold text-gray-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiButton = _resolveComponent("UiButton")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, "#" + _toDisplayString($props.modelValue.id), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, _toDisplayString($props.modelValue.title), 1 /* TEXT */)
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", null, null, -1 /* HOISTED */)),
      _cache[1] || (_cache[1] = _createElementVNode("div", null, null, -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_7, [
        _createVNode($setup["UserGroupIcon"], {
          class: "mr-1 h-5 w-5 flex-shrink-0",
          "aria-hidden": "true"
        }),
        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('meeting.list.participants') + ':'), 1 /* TEXT */),
        _createElementVNode("span", _hoisted_9, _toDisplayString($setup.participantsNames), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_10, [
        (_ctx.$can($setup.Permission.VIEW_MEETING))
          ? _withDirectives((_openBlock(), _createBlock(_component_UiButton, {
              key: 0,
              variant: "inherit",
              "text-variant": "primary",
              "no-border": "",
              "no-shadow": "",
              class: "focus:ring-primary flex items-center border-transparent text-xs shadow-none",
              to: {
                        name: 'ViewMeetingById',
                        params: {
                            id: $props.modelValue.id,
                        },
                    }
            }, {
              default: _withCtx(() => [
                _createVNode($setup["PlayIcon"], {
                  class: "mr-1 h-7 w-7",
                  "aria-hidden": "true"
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('core.actions.View')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["to"])), [
              [_directive_tippy, _ctx.$t('core.actions.View')]
            ])
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createVNode($setup["CalendarIcon"], {
          class: "mr-1 h-5 w-5 flex-shrink-0",
          "aria-hidden": "true"
        }),
        _createElementVNode("span", _hoisted_13, _toDisplayString($setup.formattedCreatedAt), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createVNode($setup["ClockIcon"], {
          class: "mr-1 h-5 w-5 flex-shrink-0",
          "aria-hidden": "true"
        }),
        _createElementVNode("span", null, _toDisplayString($props.modelValue.speechDuration) + " segundos", 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createVNode($setup["VideoCameraIcon"], {
          class: "mr-1 h-5 w-5 flex-shrink-0",
          "aria-hidden": "true"
        }),
        _createElementVNode("span", null, _toDisplayString($setup.MeetingPlatformTypeNameMap[$props.modelValue.platform]), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createVNode($setup["UserIcon"], {
          class: "mr-1 h-5 w-5 flex-shrink-0",
          "aria-hidden": "true"
        }),
        _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('meeting.list.recordedBy')) + ":", 1 /* TEXT */),
        _createElementVNode("span", null, _toDisplayString($props.modelValue.createdByUser?.name), 1 /* TEXT */)
      ])
    ])
  ]))
}