<script lang="ts" setup>
import { computed, ref, Ref, watch, WritableComputedRef } from 'vue';

import {
    ConnectFlowModelingGetters,
    ConnectFlowModelingMutations,
    VueFlowConnectionModel,
} from '@/types';
import store from '@/store';
import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
    edgeId: {
        type: String,
    },
    modelValue: {
        type: Boolean,
        required: true,
        default: false,
    },
});

watch(
    () => props.modelValue,
    (show) => {
        internalShow.value = show;
        if (show) {
            if (edge.value?.data !== undefined) {
                formState.value.timer = edge.value.data?.timerWait ?? null;
            }
        }
    },
);

const emit = defineEmits(['update:modelValue']);

const internalShow = ref(props.modelValue);
const handleToggle = (state: boolean) => {
    emit('update:modelValue', state);
};
watch(internalShow, () => {
    handleToggle(internalShow.value);
});

const edge: WritableComputedRef<VueFlowConnectionModel> = computed({
    get: () => store.getters['flow/' + ConnectFlowModelingGetters.ELEMENT_BY_ID](props.edgeId),
    set: (value: VueFlowConnectionModel) => {
        store.commit('flow/' + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
            idx: null,
            value,
        });
    },
});

interface IEdgeValidationFormState {
    timer: number | null;
}

const formState: Ref<IEdgeValidationFormState> = ref({
    timer: null,
});

const rules = computed<ValidationArgs<IEdgeValidationFormState>>(() => {
    const r: ValidationArgs<IEdgeValidationFormState> = {
        timer: {},
    };
    return r;
});
const v$: Ref<Validation<ValidationArgs<IEdgeValidationFormState>>> = useVuelidate(
    rules,
    formState,
);

const isFormCorrect = ref(true);

async function onSubmit() {
    isFormCorrect.value = await v$.value.$validate();

    if (isFormCorrect.value && edge.value.data != undefined && formState.value.timer != null) {
        edge.value.data.timerWait = formState.value.timer;
        edge.value.data.hasErrors = false;

        if (formState.value.timer) {
            edge.value.label = t('connectFlow.edge.labelTimer', {
                time: formState.value.timer,
            });
        } else {
            edge.value.label = '';
        }
        internalShow.value = false;
    }
    if (edge.value.data !== undefined) {
        edge.value.data.hasErrors = !isFormCorrect.value;
    }

    v$.value.$reset();
}
</script>
<template>
    <UiRightPanel v-model="internalShow" :title="$t('connectFlow.edge.titleAwaitable')">
        <form autocomplete="off" class="flex flex-col gap-3" @submit.prevent="onSubmit">
            <UiTextInput
                :label="$t('connectFlow.edge.timerWait')"
                name="timerWait"
                type="number"
                v-model="v$.timer.$model"
                :errors="v$.timer.$errors"
                :silent-errors="v$.timer.$silentErrors"
            />

            <div class="mt-6 grid grid-cols-2 place-content-around gap-4 text-center">
                <UiButton variant="white" text-variant="gray-700" @click="internalShow = false">
                    {{ $t('core.actions.Cancel') }}
                </UiButton>
                <UiButton type="submit" variant="primary" text-variant="white">
                    {{ $t('core.actions.Apply') }}
                </UiButton>
            </div>
        </form>
    </UiRightPanel>
</template>
