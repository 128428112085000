import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "space-y-4 p-4 sm:p-6 lg:p-8"
}
const _hoisted_2 = { class: "text-xl font-semibold text-gray-800" }
const _hoisted_3 = { class: "flex items-center space-x-4" }
const _hoisted_4 = { class: "grid grid-cols-1 gap-4 md:grid-cols-4" }
const _hoisted_5 = { class: "mb-1 font-semibold" }
const _hoisted_6 = { class: "mb-1 font-semibold" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "mb-1 font-semibold" }
const _hoisted_10 = { class: "mb-1 font-semibold" }
const _hoisted_11 = { class: "mb-1 font-semibold" }
const _hoisted_12 = { class: "mb-1 font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiBackButton = _resolveComponent("UiBackButton")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!
  const _component_UiAuditStatusInfo = _resolveComponent("UiAuditStatusInfo")!
  const _component_UiPanel = _resolveComponent("UiPanel")!

  return ($setup.number !== undefined)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_UiPageHeader, null, {
          info: _withCtx(() => [
            _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('number.view.section.number.title')), 1 /* TEXT */)
          ]),
          actions: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_UiBackButton, { fallbackTo: { name: 'ListNumber' } }),
              (_ctx.$can($setup.Permission.EDIT_USER))
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: {
                            name: 'EditNumberById',
                            params: {
                                id: $setup.number?.id,
                            },
                        },
                    class: "block hover:bg-gray-50"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_UiButton, {
                        variant: "primary",
                        "text-variant": "white"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Edit')), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["to"]))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_UiPanel, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", null, [
                _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t('core.entity.Level')), 1 /* TEXT */),
                _createElementVNode("p", null, _toDisplayString($setup.number?.level.name), 1 /* TEXT */)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('number.labels.number')), 1 /* TEXT */),
                ($setup.number.number)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString($setup.applyPhoneMask($setup.number.number)), 1 /* TEXT */))
                  : (_openBlock(), _createElementBlock("p", _hoisted_8, "-"))
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t('number.labels.city')), 1 /* TEXT */),
                _createElementVNode("p", null, _toDisplayString($setup.number?.city) + "/" + _toDisplayString($setup.number?.state), 1 /* TEXT */)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("h3", _hoisted_10, _toDisplayString(_ctx.$t('number.labels.type')), 1 /* TEXT */),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t(`number.types.${$setup.number?.type}`)), 1 /* TEXT */)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("h3", _hoisted_11, _toDisplayString(_ctx.$t('core.entity.ConnectFlow')), 1 /* TEXT */),
                _createElementVNode("p", null, _toDisplayString($setup.number?.connectFlow?.name), 1 /* TEXT */)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("h3", _hoisted_12, _toDisplayString(_ctx.$t('number.labels.mediaLabel')), 1 /* TEXT */),
                _createElementVNode("p", null, _toDisplayString($setup.number.mediaLabel), 1 /* TEXT */)
              ])
            ]),
            _createVNode(_component_UiAuditStatusInfo, {
              "model-value": $setup.number,
              class: "bt-1 mt-4 border-t border-gray-300 pt-2"
            }, null, 8 /* PROPS */, ["model-value"])
          ]),
          _: 1 /* STABLE */
        })
      ]))
    : _createCommentVNode("v-if", true)
}