<script lang="ts" setup>
import { ref, Ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import moment from 'moment';
import { useI18n } from 'vue-i18n';

import store from '@/store';
import { useUiStore, useFilterStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import CallService from '@/services/api/CallService';
import AnalyticsService from '@/services/analytics/AnalyticsService';

import {
    CallModel,
    CallType,
    CallStatus,
    SortMenuItem,
    CallActions,
    CallGetters,
    FilterItem,
    LevelModel,
} from '@/types';

import { CallListFilters, CallDetails, CallListSort } from '@/views/pages/call/components';

import {
    PhoneIcon,
    PhoneIncomingIcon,
    PhoneOutgoingIcon,
    DocumentDownloadIcon,
} from '@heroicons/vue/solid';

const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const filterStore = useFilterStore();
const uiStore = useUiStore();

const count = ref(0);
const perPage = 20;
const calls: Ref<CallModel[]> = ref([]);

const currentPage = computed<number>({
    get: () => store.getters[CallGetters.PAGE],
    set: (page: number) => store.dispatch(CallActions.SET_PAGE, page),
});

const search = computed<string>({
    get: () => store.getters[CallGetters.SEARCH],
    set: (value) => store.dispatch(CallActions.SET_SEARCH, value),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[CallGetters.SORT],
    set: (value) => store.dispatch(CallActions.SET_SORT, value),
});

const orderBy = computed(() => sortMenuSelected.value.map((s) => s.direction + s.key).join(','));

const levelFilter = computed<LevelModel | null>(() => filterStore.level);
const dateRange = computed<Array<Date>>({
    get: () => filterStore.dateRange,
    set: (range) => filterStore.setDateRange(range),
});

const filterValue = computed(() => store.getters[CallGetters.FILTER]);

const type = computed(() => filterValue.value.type?.value?.toString() ?? null);
const status = computed(() => filterValue.value.status?.value?.toString() ?? null);
const userLabel = computed(() => filterValue.value.userLabel?.id?.toString() ?? null);
const media = computed(() => filterValue.value.media?.value?.toString() ?? null);
const numberClient = computed(() => filterValue.value.numberClient?.value?.toString() ?? null);
const word = computed(() => filterValue.value.word ?? null);
const user = computed(() => filterValue.value.user?.id?.toString() ?? null);

const activeFilters = computed<FilterItem[]>(() => store.getters[CallGetters.ACTIVE_FILTERS]);

const tabFilter = computed((): FilterItem | null => {
    if (route.params.tab) {
        const filter = route.params.tab.toString().toUpperCase() as CallStatus;
        if (Object.keys(CallType).includes(filter))
            return { field: 'type', label: '', value: filter };
        if (Object.values(CallStatus).includes(filter))
            return { field: 'status', label: '', value: filter };
    }
    return null;
});

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const formatFilename = (startDate: string, endDate: string) => {
    const start = moment(startDate).format('DD/MM/YYYY');
    const end = moment(endDate).format('DD/MM/YYYY');
    return `${t('dashboard.report.call')} - ${start} - ${end}.csv`;
};

const prepareCallParams = () => {
    if (!levelFilter.value || dateRange.value.length < 2) return null;
    const params = {
        search: search.value,
        startDate: moment.utc(dateRange.value[0]).format('YYYY-MM-DD'),
        endDate: moment.utc(dateRange.value[1]).format('YYYY-MM-DD'),
        type: type.value,
        status: status.value,
        userLabel: userLabel.value,
        media: media.value,
        numberClient: numberClient.value,
        word: word.value,
        user: user.value,
        page: currentPage.value,
        per_page: perPage,
        order_by: orderBy.value,
    };
    if (tabFilter.value?.field === 'type') params.type = tabFilter.value.value;
    if (tabFilter.value?.field === 'status') params.status = tabFilter.value.value;
    return params;
};

const getCalls = async () => {
    const params = prepareCallParams();
    if (!params) return;
    try {
        await setLoading(true);
        const response = await CallService.getAll({ params });
        calls.value = response.data;
        count.value = parseInt(response.headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const getCsv = async () => {
    const params = prepareCallParams();
    if (!params) return;

    try {
        await setLoading(true);
        await AnalyticsService.trackingAction('CallCSVDownload');
        const response = await CallService.getCsvReport({ params });

        const filename = formatFilename(params.startDate, params.endDate);
        const csv =
            typeof response.data === 'string' ? response.data : JSON.stringify(response.data);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const applyTabFilter = (value: string) => {
    router.push({ name: 'CallList', params: { tab: value.toLowerCase() } });
};

const showAllCalls = () => {
    router.push({ name: 'CallList' });
};

const handleRemoveSortClick = (item: SortMenuItem) => {
    sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);
};

const handleRemoveFilter = (field: string) => {
    store.dispatch(CallActions.CLEAR_FILTER, field);
};

const bulletCallStatusColor = (status: string) => {
    switch (status) {
        case CallStatus.ANSWERED:
            return 'bg-green-600';
        case CallStatus.BUSY:
            return 'bg-yellow-500';
        case CallStatus.NO_ANSWER:
            return 'bg-red-500';
        default:
            return 'bg-gray-600';
    }
};

watch(
    [
        search,
        levelFilter,
        dateRange,
        orderBy,
        status,
        userLabel,
        type,
        media,
        numberClient,
        user,
        word,
        tabFilter,
    ],
    () => {
        if (currentPage.value === 1) getCalls();
        else currentPage.value = 1;
    },
);

watch(currentPage, () => {
    getCalls();
});

if (route.query.clearStateOnSetup) {
    currentPage.value = 1;
    router.replace({ query: {} });
}

onMounted(async () => {
    await getCalls();
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('dashboard.calls') }}
                </h1>
            </template>
            <template #actions>
                <UiButton variant="white" text-variant="primary" @click="getCsv()">
                    <DocumentDownloadIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                    {{ $t('core.actions.export') }}
                </UiButton>
            </template>
        </UiPageHeader>
        <div class="flex flex-col justify-between lg:flex-row">
            <div class="hidden flex-wrap items-center gap-2 md:flex">
                <div
                    @click="showAllCalls()"
                    :class="tabFilter === null ? 'bg-gray-50' : 'bg-gray-200'"
                    class="flex cursor-pointer items-center rounded-xl px-2 py-1 text-sm shadow ring-1 ring-gray-300 hover:bg-white"
                >
                    <PhoneIcon class="mr-1 h-4 w-4 text-gray-500" aria-hidden="true" />
                    {{ $t('call.filters.allTypesAndStatus') }}
                </div>
                <template v-for="type in CallType" :key="`filterBar${type}`">
                    <div
                        @click="applyTabFilter(type)"
                        class="flex cursor-pointer items-center rounded-xl px-2 py-1 text-sm shadow ring-1 ring-gray-300 hover:bg-white"
                        :class="
                            tabFilter !== null && tabFilter?.value === type
                                ? 'bg-gray-50'
                                : 'bg-gray-200'
                        "
                    >
                        <PhoneIncomingIcon
                            v-if="type === CallType.INBOUND"
                            class="mr-1 h-4 w-4 text-cyan-500"
                            aria-hidden="true"
                        />
                        <PhoneOutgoingIcon
                            v-else-if="type === CallType.OUTBOUND"
                            class="mr-1 h-4 w-4 text-orange-500"
                            aria-hidden="true"
                        />
                        <PhoneIcon
                            v-else-if="type === CallType.INTERNAL"
                            class="mr-1 h-4 w-4 text-gray-500"
                            aria-hidden="true"
                        />
                        {{ $t(`call.type.${type}`) }}
                    </div>
                </template>
                <template v-for="status in Object.values(CallStatus)" :key="`filterBar${status}`">
                    <div
                        v-if="status !== CallStatus.ON_PROGRESS"
                        @click="applyTabFilter(status)"
                        class="flex cursor-pointer items-center rounded-xl px-2 py-1 text-sm shadow ring-opacity-5 hover:bg-white"
                        :class="
                            tabFilter !== null && tabFilter?.value === status
                                ? 'bg-gray-50'
                                : 'bg-gray-200'
                        "
                    >
                        <div
                            class="mr-1 flex h-2.5 w-2.5 rounded-full"
                            :class="bulletCallStatusColor(status)"
                        />
                        {{ $t(`call.status.${status}`) }}
                    </div>
                </template>
            </div>
            <div class="w-full md:mt-3 lg:mt-0 lg:w-64">
                <UiDatePicker v-model="dateRange" range multiCalendars />
            </div>
        </div>
        <div class="space-y-2">
            <UiPanel class="flex px-4 py-2" no-padding>
                <UiListSearch v-model="search" />
                <div class="ml-2 flex items-center space-x-4">
                    <CallListSort v-model="sortMenuSelected" />
                    <CallListFilters />
                </div>
            </UiPanel>

            <div
                v-if="sortMenuSelected.length || activeFilters.length"
                class="flex gap-3 rounded border-t border-gray-200 bg-gray-50 p-2 ring-1 ring-gray-300"
            >
                <UiSortMenuBadge
                    v-for="selected in sortMenuSelected"
                    :key="selected.key"
                    :item="selected"
                    @remove="handleRemoveSortClick(selected)"
                />
                <template :key="filter" v-for="filter in activeFilters">
                    <UiBadge removable @remove="handleRemoveFilter(filter.field)">
                        {{ $t(`call.filters.activeTag.${filter.field}`) }}:
                        {{ filter.label }}
                    </UiBadge>
                </template>
            </div>
            <ul role="list" class="my-2 space-y-2">
                <CallDetails
                    v-for="call in calls"
                    :key="call.id"
                    :call="call"
                    class="hover:bg-white"
                />
                <li
                    v-if="!calls.length"
                    class="mt-0.5 flex h-48 flex-col flex-wrap items-center justify-center rounded-b bg-gray-200 font-semibold text-gray-600"
                >
                    {{ $t('core.messages.noRecordsFound') }}
                </li>
            </ul>
            <UiPagination
                v-if="calls.length"
                v-model="currentPage"
                :count="count"
                :perPage="perPage"
            />
        </div>
    </div>
</template>
