<script lang="ts" setup>
import { ref, computed } from 'vue';
import { MeetingMessageWithFormattedTimestampModel } from '@/types';

import { useI18n } from 'vue-i18n';
import { MenuIcon } from '@heroicons/vue/outline';

const { t } = useI18n();

const props = defineProps({
    messages: {
        type: Array as () => MeetingMessageWithFormattedTimestampModel[],
        required: true,
    },
});

const searchQuery = ref('');

const filteredMessages = computed(() => {
    if (!searchQuery.value) {
        return props.messages;
    }
    const query = searchQuery.value.toLowerCase();
    return props.messages.filter((message) => message.transcript.toLowerCase().includes(query));
});
</script>

<template>
    <div
        class="flex flex-col rounded bg-gray-50 shadow ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50"
    >
        <!-- Cabeçalho Fixo -->
        <div class="flex items-center justify-between bg-gray-50 p-3">
            <div class="flex items-center">
                <MenuIcon
                    class="mr-3 h-6 w-6 flex-shrink-0 text-[#6dbcab] group-hover:text-[#6dbcab]"
                    aria-hidden="true"
                />
                <h3 class="text-lg font-bold text-gray-800">
                    {{ t('meeting.view.transcription.title') }}
                </h3>
            </div>

            <form class="flex w-2/4 items-center">
                <UiListSearch class="w-full rounded bg-gray-50" v-model="searchQuery" />
            </form>
        </div>

        <!-- Área de Mensagens Rolável -->
        <div class="flex-1 overflow-y-auto p-6">
            <ul class="space-y-4">
                <li v-for="message in filteredMessages" :key="message.id" class="flex flex-col">
                    <div class="flex items-center">
                        <p class="mr-1 font-semibold text-gray-700">
                            {{ message.speakerName }}
                        </p>
                        <span class="mr-1">-</span>
                        <p class="text-gray-400">
                            {{ message.formattedTimestamp }}
                        </p>
                    </div>

                    <p class="text-gray-500">{{ message.transcript }}</p>
                </li>
            </ul>
        </div>
    </div>
</template>
