import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-4 p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-800" }
const _hoisted_3 = { class: "flex space-x-4" }
const _hoisted_4 = {
  key: 0,
  class: "space-y-4"
}
const _hoisted_5 = { class: "grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3" }
const _hoisted_6 = { class: "mb-1 font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiBackButton = _resolveComponent("UiBackButton")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!
  const _component_UiAuditStatusInfo = _resolveComponent("UiAuditStatusInfo")!
  const _component_UiPanel = _resolveComponent("UiPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('spamNumber.view.title')), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_UiBackButton, { fallbackTo: { name: 'ListSpamNumber' } }),
          (_ctx.$can($setup.Permission.EDIT_SPAM_NUMBER))
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: { name: 'EditSpamNumberById', params: { id: $setup.route.params.id } },
                class: "block hover:bg-gray-50"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_UiButton, {
                    variant: "primary",
                    "text-variant": "white"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Edit')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"]))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }),
    ($setup.spamNumber)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_UiPanel, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", null, [
                  _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('spamNumber.labels.number')), 1 /* TEXT */),
                  _createElementVNode("p", null, _toDisplayString($setup.applyPhoneMask($setup.spamNumber.number)), 1 /* TEXT */)
                ])
              ]),
              _createVNode(_component_UiAuditStatusInfo, {
                "model-value": $setup.spamNumber,
                class: "bt-1 mt-4 border-t border-gray-300 pt-2"
              }, null, 8 /* PROPS */, ["model-value"])
            ]),
            _: 1 /* STABLE */
          })
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}