<script lang="ts" setup>
import { computed, onMounted, reactive, Ref } from 'vue';
import { useRoute } from 'vue-router';

import { useUiStore } from '@/stores';
import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';

import { required, maxLength } from '@/utils/validators';
import { toast, toastServiceError } from '@/services/core/notification';
import { goBack } from '@/composables/useNavigation';

import ConnectFlowService from '@/services/api/ConnectFlowService';

import {
    ConnectFlowFormState,
    ConnectFlowModel,
    ConnectFlowServiceCreateResult,
    DefaultServiceResult,
    FormAction,
    Permission,
} from '@/types';

const route = useRoute();
const uiStore = useUiStore();

const props = defineProps({
    action: {
        type: String,
        default: 'view',
    },
});

const formState = reactive<ConnectFlowFormState>({
    name: null,
    isActive: true,
});

const rules = computed<ValidationArgs<ConnectFlowFormState>>(() => ({
    name: {
        required,
        maxLength: maxLength(500),
    },
    isActive: {
        required,
    },
}));

const v$: Ref<Validation<ValidationArgs<ConnectFlowFormState>>> = useVuelidate(rules, formState);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchConnectFlow = async () => {
    if (props.action !== FormAction.EDIT) return;

    const id = Number(route.params.id);

    try {
        setLoading(true);
        const response = await ConnectFlowService.get<ConnectFlowModel>(id);
        const { name, isActive } = response.data;
        formState.name = name;
        formState.isActive = isActive;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const onSubmit = async () => {
    const isFormCorrect = await v$.value.$validate();
    if (!isFormCorrect) return;

    setLoading(true);

    const handleServiceSuccess = (message: string, id: number) => {
        toast.success(message);
        v$.value.$reset();
        goBack({
            name: 'ViewConnectFlowById',
            params: { id },
        });
    };

    try {
        if (props.action === FormAction.EDIT) {
            const id = Number(route.params.id);
            const response = await ConnectFlowService.update<
                DefaultServiceResult,
                ConnectFlowFormState
            >(id, formState);
            handleServiceSuccess(response.data.message, id);
        } else {
            const response = await ConnectFlowService.create<
                ConnectFlowServiceCreateResult,
                ConnectFlowFormState
            >(formState);
            handleServiceSuccess(response.data.message, response.data.result.id);
        }
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

onMounted(async () => {
    await fetchConnectFlow();
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('connectFlow.' + props.action + '.title') }}
                </h1>
                <p class="mt-2 text-sm text-gray-700">
                    {{ $t('connectFlow.' + props.action + '.description') }}
                </p>
            </template>
            <template #actions>
                <div class="flex items-center space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ListConnectFlow' }" />
                    <UiButton
                        v-if="
                            (props.action == FormAction.CREATE &&
                                $can(Permission.CREATE_CONNECT_FLOW)) ||
                            (props.action == FormAction.EDIT && $can(Permission.EDIT_CONNECT_FLOW))
                        "
                        type="submit"
                        variant="primary"
                        text-variant="white"
                    >
                        {{ $t('core.actions.Save') }}
                    </UiButton>
                </div>
            </template>
        </UiPageHeader>
        <form class="space-y-4" autocomplete="off" @submit.prevent="onSubmit">
            <UiPanel>
                <div class="grid grid-cols-1 gap-4 md:grid-cols-4">
                    <UiTextInput
                        v-model="v$.name.$model"
                        name="name"
                        type="text"
                        :label="$t('connectFlow.labels.name')"
                        :errors="v$.name.$errors"
                        :required="v$.name.required !== undefined"
                        @blur="v$.name.$touch"
                    />
                    <div class="flex flex-col justify-end pb-2.5">
                        <UiCheckboxInput
                            v-model="v$.isActive.$model"
                            name="isActive"
                            :label="$t('connectFlow.labels.isActive')"
                        />
                    </div>
                </div>
            </UiPanel>
        </form>
        <UiCheckDirtyBeforeRouteLeave :dirty="v$.$anyDirty" />
    </div>
</template>
