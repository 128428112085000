<script lang="ts" setup>
import { DownloadIcon } from '@heroicons/vue/solid';
import { VaultService } from '@/services';
import AnalyticsService from '@/services/analytics/AnalyticsService';

defineProps({
    callId: {
        type: Number,
        required: true,
    },
});

async function downloadCallAudio(callId: number): Promise<void> {
    try {
        if (!callId) return;

        AnalyticsService.trackingAction('CallAudioDownload');

        // Fetch audio data from VaultService
        const { data } = await VaultService.getCallAudio(callId);

        // Create blob and object URL
        const blob = new Blob([data], { type: 'audio/mpeg' });
        const url = URL.createObjectURL(blob);

        // Create download link
        const link = document.createElement('a');
        link.href = url;
        link.download = `${callId}.mp3`;
        document.body.appendChild(link);
        link.click();

        // Cleanup
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
    } catch (error) {
        console.error('Error downloading call audio:', error);
    }
}
</script>

<template>
    <UiActionButton
        v-tippy="$t('core.actions.Download')"
        variant="primary"
        @click="downloadCallAudio(callId)"
    >
        <DownloadIcon class="h-7 w-7" aria-hidden="true" />
        <span>{{ $t('core.actions.Download') }}</span>
    </UiActionButton>
</template>
