import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "mb-4 text-2xl font-bold text-gray-800 sm:mb-6 lg:mb-8 xl:text-3xl" }
const _hoisted_3 = { class: "grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiDashboardLink = _resolveComponent("UiDashboardLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('welcomeLogged')) + ", " + _toDisplayString($setup.user?.name) + ". ", 1 /* TEXT */),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.links, (link, index) => {
        return _createVNode(_component_UiDashboardLink, {
          key: `dashboard-link-${index}`,
          routeName: link.routeName,
          permission: link.permission,
          title: link.title,
          description: link.description,
          icon: link.icon
        }, null, 8 /* PROPS */, ["routeName", "permission", "title", "description", "icon"])
      }), 64 /* STABLE_FRAGMENT */))
    ])
  ]))
}