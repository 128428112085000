import { RouteRecordRaw } from 'vue-router';
import { validateIdAsPositiveIntegerGuard } from '@/router/routeGuards';
import { FormAction } from '@/types';
import ConnectFlowAudioPage from '@/views/pages/connect-flow-audio/ConnectFlowAudioPage.vue';
import ConnectFlowAudioDetailPage from '@/views/pages/connect-flow-audio/ConnectFlowAudioDetailPage.vue';
import ConnectFlowAudioFormPage from '@/views/pages/connect-flow-audio/ConnectFlowAudioFormPage.vue';

const connectFlowAudio: RouteRecordRaw = {
    name: 'connectFlowAudio',
    path: 'connect-flow/audio',
    children: [
        {
            name: 'ListConnectFlowAudio',
            path: '',
            component: ConnectFlowAudioPage,
        },
        {
            name: 'ViewConnectFlowAudioById',
            path: ':id',
            component: ConnectFlowAudioDetailPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            meta: { isLevelLocked: true },
        },
        {
            name: 'EditConnectFlowAudioById',
            path: ':id/edit',
            component: ConnectFlowAudioFormPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            props: { action: FormAction.EDIT },
            meta: { isLevelLocked: true },
        },
        {
            name: 'CreateConnectFlowAudio',
            path: 'create',
            component: ConnectFlowAudioFormPage,
            props: { action: FormAction.CREATE },
            meta: { isLevelLocked: true },
        },
    ],
};
export default connectFlowAudio;
