import { defineComponent as _defineComponent } from 'vue'
import { ref, onMounted } from 'vue';
import { useRoute, RouterLink } from 'vue-router';

import { useUiStore } from '@/stores';
import ConnectFlowAudioService from '@/services/api/ConnectFlowAudioService';
import VaultService from '@/services/api/VaultService';
import { toastServiceError } from '@/services/core/notification';

import { ConnectFlowAudioModel, Permission } from '@/types';
import { getAudioFormat } from '@/utils/audio';


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowAudioDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const uiStore = useUiStore();

const connectFlowAudio = ref<ConnectFlowAudioModel | undefined>(undefined);
const audioData = ref();

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchConnectFlowAudio = async (id: number) => {
    await setLoading(true);
    try {
        const { data } = await ConnectFlowAudioService.get<ConnectFlowAudioModel>(id);
        connectFlowAudio.value = data;

        const { data: audioAsset } = await VaultService.getAudioAsset(
            connectFlowAudio.value.audioFilePath,
        );

        audioData.value = URL.createObjectURL(
            new Blob([audioAsset], {
                type: getAudioFormat(connectFlowAudio.value?.audioFilePath),
            }),
        );
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

onMounted(() => {
    const id = Number(route.params.id);
    fetchConnectFlowAudio(id);
});

const __returned__ = { route, uiStore, connectFlowAudio, audioData, setLoading, fetchConnectFlowAudio, get RouterLink() { return RouterLink }, get Permission() { return Permission }, get getAudioFormat() { return getAudioFormat } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})