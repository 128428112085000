<script lang="ts" setup>
import { computed, PropType } from 'vue';
import moment from 'moment';

import { CallAudioDownloadButton } from '@/views/pages/call/components';
import {
    CalculatorIcon,
    CalendarIcon,
    ClockIcon,
    PhoneIcon,
    PhoneIncomingIcon,
    PhoneOutgoingIcon,
    PlayIcon,
    TagIcon,
    UsersIcon,
} from '@heroicons/vue/solid';
import { CallStatus, CallStatusClassMap, CallType, CallModel, Permission } from '@/types';
import { can } from '@/composables/useAuth';
import { formatDateAsString, formatSecToTime } from '@/utils/datetime';
import { applyPhoneMask } from '@/utils/number';

const props = defineProps({
    call: {
        type: Object as PropType<CallModel>,
        required: true,
    },
    showViewButton: {
        type: Boolean,
        default: true,
    },
    showDownloadButton: {
        type: Boolean,
        default: false,
    },
});

const duration = computed(() => {
    return moment(props.call?.endedAt).diff(moment(props.call.answeredAt), 'seconds');
});

const waitingTime = computed(() => {
    if (props.call?.status === CallStatus.ANSWERED && props.call.answeredAt) {
        return moment(props.call.answeredAt).diff(moment(props.call?.startedAt), 'seconds');
    }
    return moment(props.call.endedAt).diff(moment(props.call?.startedAt), 'seconds');
});

const agents = computed(() => {
    const agentsNames: string[] = [];
    props.call?.events.forEach((e) => {
        if (e.sourceUser) {
            if (!agentsNames.includes(e.sourceUser.name)) {
                agentsNames.push(e.sourceUser.name);
            }
        }
        if (e.destinationUser) {
            if (!agentsNames.includes(e.destinationUser.name)) {
                agentsNames.push(e.destinationUser.name);
            }
        }
    });
    return agentsNames;
});

const sourceEvent = computed(() => (props.call.events.length ? props.call.events[0] : null));

const destinationEvent = computed(() => {
    if (props.call?.type === CallType.INBOUND && props.call.status === CallStatus.ANSWERED) {
        return props.call?.events.find((event) => event.status === props.call.status);
    }
    return props.call?.events[props.call.events.length - 1];
});

const showCallAudioDownloadButton = computed(
    () =>
        props.showDownloadButton && props.call.audioFilePath && can(Permission.DOWNLOAD_CALL_AUDIO),
);

function formatDestinationNumber(number: string) {
    const pattern = /(\d+)_(\d+)_(\d+)/; // Pattern to match the "211_1_1" like strings
    const match = number.match(pattern);
    if (match) {
        return match[1];
    } else {
        return applyPhoneMask(number);
    }
}
</script>

<template>
    <UiPanel class="hover:bg-white">
        <div class="mb-2 sm:mb-0">
            <span
                class="rounded border-gray-200 bg-gray-50 px-1.5 py-0.5 text-xs ring-1 ring-gray-300"
            >
                #{{ call.id }}
            </span>
        </div>
        <div class="grid grid-cols-2 gap-3 lg:grid-cols-6">
            <div class="flex flex-wrap items-center space-x-1 text-sm text-gray-600">
                <PhoneIncomingIcon
                    v-if="call.type === CallType.INBOUND"
                    class="h-5 w-5 text-cyan-500"
                    aria-hidden="true"
                />
                <PhoneOutgoingIcon
                    v-else-if="call.type === CallType.OUTBOUND"
                    class="h-5 w-5 text-orange-500"
                    aria-hidden="true"
                />
                <PhoneIcon
                    v-else-if="call.type === CallType.INTERNAL"
                    class="h-5 w-5 text-gray-500"
                    aria-hidden="true"
                />
                <span class="truncate">
                    {{ $t(`call.type.${call.type}`) }}
                </span>
            </div>
            <div class="flex flex-wrap items-center space-x-1 text-sm text-gray-600">
                <div
                    class="flex h-2.5 w-2.5 rounded-full"
                    :class="CallStatusClassMap[call.status]"
                />
                <span class="ml-0.5 truncate">
                    {{ $t(`call.status.${call.status}`) }}
                </span>
            </div>
            <div class="flex flex-col text-sm">
                <span class="text-xs text-gray-400">
                    {{ $t('call.list.origin') }}
                </span>
                <span v-if="call.contact && call.type === CallType.INBOUND" class="truncate">
                    {{ call.contact.name }}
                </span>
                <span v-else-if="sourceEvent?.sourceUser" class="truncate">
                    {{ sourceEvent.sourceUser.name }}
                </span>
                <span v-else class="truncate">
                    {{ sourceEvent?.sourceNumber ? applyPhoneMask(sourceEvent.sourceNumber) : '-' }}
                </span>
            </div>
            <div class="flex flex-col text-sm">
                <span class="text-xs text-gray-400">
                    {{ $t('call.list.tracking') }}
                </span>
                <span class="truncate">{{
                    call.type === CallType.INBOUND && call.destinationNumber
                        ? applyPhoneMask(call.destinationNumber)
                        : '-'
                }}</span>
            </div>
            <div class="flex flex-col text-sm">
                <span class="text-xs text-gray-400">
                    {{ $t('call.list.destination') }}
                </span>
                <span v-if="destinationEvent?.destinationUser" class="truncate">
                    {{ destinationEvent?.destinationUser.name }}
                </span>
                <span v-else class="truncate">
                    {{
                        destinationEvent?.destinationNumber
                            ? formatDestinationNumber(destinationEvent?.destinationNumber)
                            : '-'
                    }}
                </span>
            </div>
            <div v-if="call.audioFilePath" class="flex items-center lg:justify-end">
                <CallAudioDownloadButton v-if="showCallAudioDownloadButton" :callId="call.id" />

                <UiActionButton
                    v-if="showViewButton && $can(Permission.VIEW_CALL) && call.status == 'ANSWERED'"
                    v-tippy="$t('core.actions.View')"
                    variant="primary"
                    :to="{
                        name: 'ViewCallById',
                        params: {
                            id: call.id,
                        },
                    }"
                >
                    <PlayIcon class="h-7 w-7" aria-hidden="true" />
                    <span>{{ $t('core.actions.View') }}</span>
                </UiActionButton>
            </div>
        </div>
        <div class="mt-2 grid grid-cols-2 gap-3 border-t pt-3 lg:grid-cols-6">
            <div class="flex items-center space-x-1 text-xs text-gray-600">
                <CalendarIcon class="h-4 w-4" aria-hidden="true" />
                <span class="truncate">{{
                    formatDateAsString(call.startedAt.toString(), 'DD/MM/YYYY')
                }}</span>
                <span class="truncate">{{
                    formatDateAsString(call.startedAt.toString(), 'HH:mm:ss')
                }}</span>
            </div>
            <div class="flex items-center space-x-1 text-xs text-gray-600">
                <ClockIcon class="h-4 w-4" aria-hidden="true" />
                <span>{{ $t('call.list.duration') }}: </span>
                <span>{{
                    call.status == CallStatus.ANSWERED && call.endedAt
                        ? formatSecToTime(duration)
                        : '-'
                }}</span>
            </div>
            <div class="flex items-center space-x-1 text-xs text-gray-600">
                <ClockIcon class="h-4 w-4" aria-hidden="true" />
                <span>{{ $t('call.list.waitingTime') }}: </span>
                <span>{{
                    call.status != CallStatus.ON_PROGRESS && call.endedAt !== null
                        ? formatSecToTime(waitingTime)
                        : '-'
                }}</span>
            </div>
            <div class="flex items-center text-xs text-gray-600">
                <TagIcon class="mr-1 h-4 w-4" aria-hidden="true" />
                <span>{{ $t('call.list.media') }}: </span>
                <div class="ml-1">
                    <span
                        v-if="call.media"
                        class="rounded border bg-white px-1.5 py-0.5 text-xs text-gray-500"
                        >{{ call.media }}</span
                    >
                    <span v-else>-</span>
                </div>
            </div>
            <div class="col-span-2 flex items-start text-xs text-gray-600">
                <div class="flex h-full items-center">
                    <UsersIcon class="mr-1 h-4 w-4 flex-shrink-0" aria-hidden="true" />
                    {{ $t('call.list.participants') }}:
                </div>
                <div class="-my-0.5 flex flex-wrap px-1">
                    <template v-if="agents.length > 1">
                        <span
                            v-for="agent in agents"
                            class="m-0.5 inline-block whitespace-nowrap rounded border bg-white px-1.5 py-0.5 text-xs text-gray-500"
                            :key="`agent${call.id}${agent}`"
                        >
                            {{ agent }}
                        </span>
                    </template>
                    <span v-else>-</span>
                </div>
            </div>

            <div v-if="call.ura !== null" class="flex items-center gap-2 text-xs text-gray-600">
                <CalculatorIcon class="h-4 w-4" aria-hidden="true" />
                <span>{{ $t('call.list.ura') }}: </span>
                <span
                    class="rounded border border-b-gray-400 bg-white px-1"
                    v-for="ura in call.ura"
                    :key="`${call.id}${ura.node}`"
                >
                    {{ ura.digit }}
                </span>
            </div>
        </div>
    </UiPanel>
</template>
