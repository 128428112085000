<script lang="ts" setup>
// Imports
import { ref, Ref, computed, watch, onMounted, ComputedRef } from 'vue';
import moment from 'moment';
import store from '@/store';
import { useI18n } from 'vue-i18n';

import { useUiStore, useFilterStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import { QueueTimeReportService } from '@/services';
import { formatSecToTime } from '@/utils/datetime';

import {
    ChartDataItem,
    ContactCenterPerDayReportActions,
    ContactCenterPerDayReportFilter,
    ContactCenterPerDayReportGetters,
    DataItem,
    FilterItem,
    LevelModel,
    QueueTimeReportServiceGetInfoParams,
    QueueTimeReportServiceGetInfoResult,
} from '@/types';

// State
const reportInfo: Ref<QueueTimeReportServiceGetInfoResult | null> = ref(null);

// Computed
const headerStats = computed<DataItem<string>[]>(() => {
    const stats = reportInfo.value?.stats;
    if (!stats) return [];

    return [
        { name: 'totalCalls', value: stats.totalCalls.toString() },
        { name: 'inboundCalls', value: stats.inboundCalls.toString() },
        { name: 'costumersContacted', value: stats.costumersContacted.toString() },
        { name: 'nonTalkPercentage', value: stats.nonTalkPercentage + '%' },
        { name: 'tma', value: stats.tma },
        { name: 'averageTimeQueue', value: stats.averageTimeQueue },
    ];
});

const chartDataAverageTimePerDay = computed(() => {
    const category: string[] = [];
    const series: ChartDataItem<number[]>[] = [
        {
            value: [],
            name: t('report.queueTime.averageTimePerDay.averageTime'),
            color: '#BFDBFE',
        },
    ];

    if (!reportInfo.value?.queuePerDay) return { category, series };

    for (const day of reportInfo.value.queuePerDay) {
        category.push(day.date);
        series[0].value.push(day.averageDuration);
    }

    return { category, series };
});

const chartDataTimeRangeByDay = computed(() => {
    const category: string[] = [];
    const series: ChartDataItem<number[]>[] = [];

    if (!reportInfo.value?.queuePerDay) return { category, series };

    for (const day of reportInfo.value.queuePerDay) {
        category.push(day.date);
        day.ranges.forEach((_, y) => {
            if (!series[y]) {
                series[y] = { value: [], name: '', color: '' };
            }
            series[y].value.push(day.timeRange[y]);
            series[y].name = t(`report.queueTime.range.${day.ranges[y]}`);
            series[y].color = day.rangeColor[y];
        });
    }

    return { category, series };
});

const chartDataAverageTimePerWeekDay = computed(() => {
    const category: string[] = [];
    const series: ChartDataItem<number[]>[] = [
        {
            value: [],
            name: t('report.queueTime.averageTimePerWeekDay.averageTime'),
            color: '#BFDBFE',
        },
    ];

    if (!reportInfo.value?.queuePerWeekDay) return { category, series };

    for (const day of reportInfo.value.queuePerWeekDay) {
        category.push(t(`core.days.${day.day}`));
        series[0].value.push(day.averageDuration);
    }

    return { category, series };
});

const chartDataTimeRangeByWeekDay = computed(() => {
    const category: string[] = [];
    const series: ChartDataItem<number[]>[] = [];

    if (!reportInfo.value?.queuePerWeekDay) return { category, series };

    for (const day of reportInfo.value.queuePerWeekDay) {
        category.push(t(`core.days.${day.day}`));
        day.ranges.forEach((_, y) => {
            if (!series[y]) {
                series[y] = { value: [], name: '', color: '' };
            }
            series[y].value.push(day.timeRange[y]);
            series[y].name = t(`report.queueTime.range.${day.ranges[y]}`);
            series[y].color = day.rangeColor[y];
        });
    }

    return { category, series };
});

const chartDataAverageTimePerHourOfDay = computed(() => {
    const category: string[] = Array.from({ length: 24 }, (_, h) => h.toString().padStart(2, '0'));
    const series: ChartDataItem<number[]>[] = [
        {
            value: reportInfo.value?.queuePerHour || [],
            name: t('report.queueTime.averageTimeByHourOfDay.averageTime'),
            color: '#BFDBFE',
        },
    ];

    return { category, series };
});

const chartDataTimeRangePerHourDay = computed(() => {
    const category: string[] = Array.from({ length: 24 }, (_, h) => h.toString().padStart(2, '0'));
    const series: ChartDataItem<number[]>[] = [];

    const queueData = reportInfo.value?.queueRangePorHourOfDay;
    if (!queueData) return { category, series };

    queueData.ranges.forEach((range, i) => {
        series[i] = {
            value: queueData.data[i],
            name: t(`report.queueTime.range.${range}`),
            color: queueData.rangeColor[i],
        };
    });

    return { category, series };
});

const axisLabelDateFormatter = (value: number | string) => moment.utc(value).format('DD/MM');
const axisLabelSecToTimeFormatter = (value: number) => formatSecToTime(value);

// Composables
const uiStore = useUiStore();
const filterStore = useFilterStore();
const { t } = useI18n();

// Computed from store
const levelFilter = computed<LevelModel | null>(() => filterStore.level);
const dateRange = computed<Array<Date>>({
    get: () => filterStore.dateRange,
    set: (dateRange) => filterStore.setDateRange(dateRange),
});
const filter = computed<ContactCenterPerDayReportFilter>(
    () => store.getters[ContactCenterPerDayReportGetters.FILTER],
);
const activeFilters: ComputedRef<FilterItem[]> = computed(
    () => store.getters[ContactCenterPerDayReportGetters.ACTIVE_FILTERS],
);

// Methods
const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchReportData = async () => {
    const params: QueueTimeReportServiceGetInfoParams = {
        startDate: moment.utc(dateRange.value[0]).format('YYYY-MM-DD'),
        endDate: moment.utc(dateRange.value[1]).format('YYYY-MM-DD'),
        type: filter.value.type?.value || null,
        status: filter.value.status?.value || null,
        user: filter.value.user?.id || null,
        number: filter.value.number?.number || null,
        serviceTimeRange: filter.value.serviceTimeRange?.value || null,
        userLabel: filter.value.userLabel?.id || null,
    };

    try {
        setLoading(true);
        const response = await QueueTimeReportService.getInfo({ params });
        reportInfo.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveFilter = (filter: FilterItem) => {
    store.dispatch(ContactCenterPerDayReportActions.CLEAR_FILTER, filter.field);
};

// Watchers
watch([levelFilter, dateRange, activeFilters], () => {
    fetchReportData();
});

// Lifecycle
onMounted(() => {
    fetchReportData();
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('report.queueTime.title') }}
                </h1>
            </template>
            <template #actions>
                <ContactCenterPerDayReportFilters />
                <UiDatePicker v-model="dateRange" range multiCalendars class="ml-4" />
            </template>
        </UiPageHeader>

        <div
            v-if="activeFilters.length"
            class="mt-4 flex bg-gray-50 px-4 py-4 shadow ring-1 ring-gray-300 sm:p-6 md:rounded"
        >
            <template :key="filter" v-for="filter in activeFilters">
                <UiBadge removable @remove="handleRemoveFilter(filter)">
                    {{ $t(`report.contactCenterPerDay.filters.activeTag.${filter.field}`) }}:
                    {{ filter.label }}
                </UiBadge>
            </template>
        </div>

        <dl
            v-if="reportInfo?.stats"
            class="mt-4 grid grid-cols-1 gap-5"
            :class="['sm:grid-cols-' + headerStats.length]"
        >
            <div
                v-for="stat in headerStats"
                :key="stat.name"
                class="overflow-hidden rounded bg-gray-50 px-4 py-4 shadow sm:p-6"
            >
                <dt class="truncate text-sm font-medium text-gray-500">
                    {{ $t('report.queueTime.stats.' + stat.name) }}
                </dt>
                <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-800">
                    {{ stat.value }}
                </dd>
            </div>
        </dl>

        <div v-if="reportInfo" class="mt-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
            <div
                class="col-span-2 -mx-4 h-[300px] bg-gray-50 shadow ring-1 ring-gray-300 sm:-mx-6 md:col-span-1 md:mx-0 md:rounded"
            >
                <BarChart
                    :title="t('report.queueTime.averageTimePerDay.title')"
                    :categoryData="chartDataAverageTimePerDay.category"
                    :seriesData="chartDataAverageTimePerDay.series"
                    :xAxisLabelFormatter="axisLabelDateFormatter"
                    :yAxisLabelFormatter="axisLabelSecToTimeFormatter"
                    column
                />
            </div>
            <div
                class="col-span-2 -mx-4 h-[300px] bg-gray-50 shadow ring-1 ring-gray-300 sm:-mx-6 md:col-span-1 md:mx-0 md:rounded"
            >
                <BarChart
                    :title="t('report.queueTime.timeRangeByDay.title')"
                    :categoryData="chartDataTimeRangeByDay.category"
                    :seriesData="chartDataTimeRangeByDay.series"
                    :xAxisLabelFormatter="axisLabelDateFormatter"
                    column
                />
            </div>
            <div
                class="col-span-2 -mx-4 h-[300px] bg-gray-50 shadow ring-1 ring-gray-300 sm:-mx-6 md:col-span-1 md:mx-0 md:rounded"
            >
                <BarChart
                    :title="t('report.queueTime.averageTimePerWeekDay.title')"
                    :categoryData="chartDataAverageTimePerWeekDay.category"
                    :seriesData="chartDataAverageTimePerWeekDay.series"
                    :yAxisLabelFormatter="axisLabelSecToTimeFormatter"
                    column
                />
            </div>
            <div
                class="col-span-2 -mx-4 h-[300px] bg-gray-50 shadow ring-1 ring-gray-300 sm:-mx-6 md:col-span-1 md:mx-0 md:rounded"
            >
                <BarChart
                    :title="t('report.queueTime.timeRangeByWeekDay.title')"
                    :categoryData="chartDataTimeRangeByWeekDay.category"
                    :seriesData="chartDataTimeRangeByWeekDay.series"
                    column
                />
            </div>
            <div
                class="col-span-2 -mx-4 h-[300px] bg-gray-50 shadow ring-1 ring-gray-300 sm:-mx-6 md:col-span-1 md:mx-0 md:rounded"
            >
                <BarChart
                    :title="t('report.queueTime.averageTimeByHourOfDay.title')"
                    :categoryData="chartDataAverageTimePerHourOfDay.category"
                    :seriesData="chartDataAverageTimePerHourOfDay.series"
                    :yAxisLabelFormatter="axisLabelSecToTimeFormatter"
                    column
                />
            </div>
            <div
                class="col-span-2 -mx-4 h-[300px] bg-gray-50 shadow ring-1 ring-gray-300 sm:-mx-6 md:col-span-1 md:mx-0 md:rounded"
            >
                <BarChart
                    :title="t('report.queueTime.averageTimeByHourOfDay.title')"
                    :categoryData="chartDataTimeRangePerHourDay.category"
                    :seriesData="chartDataTimeRangePerHourDay.series"
                    column
                />
            </div>
        </div>
    </div>
</template>
