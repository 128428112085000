import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "space-y-4 p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "flex items-center text-xl font-semibold text-gray-800" }
const _hoisted_3 = { class: "min-w-full" }
const _hoisted_4 = { class: "bg-gray-100" }
const _hoisted_5 = {
  scope: "col",
  class: "p-4 text-left text-sm font-semibold text-gray-800"
}
const _hoisted_6 = {
  scope: "col",
  class: "p-4 text-left text-sm font-semibold text-gray-800"
}
const _hoisted_7 = { class: "divide-y divide-gray-200" }
const _hoisted_8 = { class: "whitespace-nowrap p-4 text-sm font-medium text-gray-800" }
const _hoisted_9 = { class: "whitespace-nowrap p-4 text-sm font-medium text-gray-800" }
const _hoisted_10 = ["checked", "onChange"]
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "whitespace-nowrap p-4 text-center text-sm text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!
  const _component_UiListSearch = _resolveComponent("UiListSearch")!
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _component_UiPagination = _resolveComponent("UiPagination")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, [
          _createVNode($setup["LockClosedIcon"], {
            class: "mr-1 h-6 w-6 text-gray-500",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('core.entity.Permissions')), 1 /* TEXT */)
        ])
      ]),
      actions: _withCtx(() => [
        (_ctx.$can($setup.Permission.CREATE_ROLE))
          ? (_openBlock(), _createBlock($setup["RouterLink"], {
              key: 0,
              to: {
                        name: 'CreateRole',
                    }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_UiButton, {
                  variant: "primary",
                  "text-variant": "white"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('permission.actions.newRole')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_UiPanel, {
      class: "px-4 py-2",
      "no-padding": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_UiListSearch, {
          modelValue: $setup.search,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.search) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_UiPanel, { "no-padding": "" }, {
      default: _withCtx(() => [
        _createElementVNode("table", _hoisted_3, [
          _createElementVNode("thead", _hoisted_4, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.$t('permission.labels.module')), 1 /* TEXT */),
              _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t('permission.labels.name')), 1 /* TEXT */),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.roles, (role) => {
                return (_openBlock(), _createElementBlock("th", {
                  key: role.id,
                  scope: "col",
                  class: "p-4 text-center text-sm font-semibold text-gray-800"
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can($setup.Permission.VIEW_ROLE) ? $setup.RouterLink : 'span'), {
                    to: {
                                    name: 'ViewRoleById',
                                    params: {
                                        id: role.id,
                                    },
                                },
                    class: "text-primary inline-flex items-center truncate text-sm font-medium"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(role.name) + " ", 1 /* TEXT */),
                      (_ctx.$can($setup.Permission.VIEW_ROLE))
                        ? (_openBlock(), _createBlock($setup["PencilIcon"], {
                            key: 0,
                            class: "ml-1 h-4 w-4",
                            "aria-hidden": "true"
                          }))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]))
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ]),
          _createElementVNode("tbody", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.paginatedPermissions, (permission) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: permission.id
              }, [
                _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.$t(`permission.modules.${permission.module}`)), 1 /* TEXT */),
                _createElementVNode("td", _hoisted_9, _toDisplayString(permission.description), 1 /* TEXT */),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.roles, (role) => {
                  return (_openBlock(), _createElementBlock("td", {
                    key: role.id,
                    class: "whitespace-nowrap p-4 text-center text-sm text-gray-500"
                  }, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      checked: 
                                    permission.roles.find((r) => r.id == role.id)
                                        ? true
                                        : false
                                ,
                      onChange: ($event: any) => ($setup.handleAddRemovePermissionClick(role, permission))
                    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_10), [
                      [_directive_tippy, {
                                    content: permission.description,
                                    placement: 'left',
                                }]
                    ])
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ]))
            }), 128 /* KEYED_FRAGMENT */)),
            (!$setup.paginatedPermissions.length)
              ? (_openBlock(), _createElementBlock("tr", _hoisted_11, [
                  _createElementVNode("td", _hoisted_12, _toDisplayString(_ctx.$t('core.messages.noRecordsFound')), 1 /* TEXT */)
                ]))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_UiPagination, {
      modelValue: $setup.currentPage,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.currentPage) = $event)),
      count: $setup.filteredPermissions.length,
      perPage: $setup.perPage
    }, null, 8 /* PROPS */, ["modelValue", "count"])
  ]))
}