import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "mt-2 space-y-2 border-t border-gray-300 py-2" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "flex-1 font-semibold" }
const _hoisted_4 = { class: "gap-4 space-y-4 md:grid md:grid-cols-3 md:space-y-0" }
const _hoisted_5 = {
  for: "rangeAnswer",
  class: "mb-2 block text-sm font-medium text-gray-800"
}
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "mb-2 block text-sm font-medium text-gray-800" }
const _hoisted_8 = {
  key: 0,
  class: "text-danger bg-danger-100 p-2 text-sm shadow"
}
const _hoisted_9 = {
  key: 1,
  controls: "",
  class: "mt-2 w-full"
}
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiActionButton = _resolveComponent("UiActionButton")!
  const _component_UiTextInput = _resolveComponent("UiTextInput")!
  const _component_UiMultiselect = _resolveComponent("UiMultiselect")!
  const _component_UiTextArea = _resolveComponent("UiTextArea")!
  const _component_UiAudioUpload = _resolveComponent("UiAudioUpload")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, _toDisplayString(`${_ctx.$t('connectFlowSurvey.form.labels.question')} ${$props.index + 1}`), 1 /* TEXT */),
      ($props.showRemove)
        ? _withDirectives((_openBlock(), _createBlock(_component_UiActionButton, {
            key: 0,
            variant: "danger",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('remove', $props.index)))
          }, {
            default: _withCtx(() => [
              _createVNode($setup["TrashIcon"], { class: "h-5 w-5" })
            ]),
            _: 1 /* STABLE */
          })), [
            [_directive_tippy, _ctx.$t('connectFlowSurvey.form.actions.removeQuestion')]
          ])
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_UiTextInput, {
        modelValue: $setup.v$.label.$model,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.v$.label.$model) = $event)),
        name: "label",
        type: "text",
        label: _ctx.$t('connectFlowSurvey.form.labels.label'),
        class: "col-span-2",
        errors: $setup.v$.label.$errors,
        onBlur: $setup.v$.label.$touch
      }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"]),
      _createElementVNode("div", null, [
        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('connectFlowSurvey.form.labels.scoreRange')), 1 /* TEXT */),
        _createVNode(_component_UiMultiselect, {
          name: "rangeAnswer",
          modelValue: $setup.formState.rangeAnswer,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formState.rangeAnswer) = $event)),
          primitive: true,
          options: $setup.scoreOptions,
          errors: $setup.v$.rangeAnswer.$errors
        }, null, 8 /* PROPS */, ["modelValue", "errors"])
      ]),
      _createVNode(_component_UiTextArea, {
        id: "textToAudio",
        name: "textToAudio",
        modelValue: $setup.formState.textToAudio,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.formState.textToAudio) = $event)),
        label: _ctx.$t('connectFlowSurvey.form.labels.textToAudio'),
        placeholder: _ctx.$t('connectFlowSurvey.form.labels.textToAudioPlaceholder'),
        rows: 4,
        disabled: $setup.formState.audioFile != undefined,
        errors: $setup.v$.textToAudio.$errors,
        class: "col-span-2"
      }, null, 8 /* PROPS */, ["modelValue", "label", "placeholder", "disabled", "errors"]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('connectFlowSurvey.form.labels.audioUpload')), 1 /* TEXT */),
        _createVNode(_component_UiAudioUpload, {
          file: $setup.formState.audioFile,
          "onUpdate:file": _cache[4] || (_cache[4] = ($event: any) => (($setup.formState.audioFile) = $event)),
          "text-rule": _ctx.$t('connectFlowSurvey.form.labels.uploadRules'),
          errors: $setup.v$.audioFilePath.$errors
        }, null, 8 /* PROPS */, ["file", "text-rule", "errors"]),
        ($setup.v$.audioFilePath.$errors && $setup.v$.audioFilePath.$errors.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.v$.audioFilePath.$errors, (error) => {
                return (_openBlock(), _createElementBlock("p", {
                  key: error.$uid
                }, _toDisplayString(error.$message), 1 /* TEXT */))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true),
        ($setup.audioPreview)
          ? (_openBlock(), _createElementBlock("audio", _hoisted_9, [
              _createElementVNode("source", { src: $setup.audioPreview }, null, 8 /* PROPS */, _hoisted_10),
              _cache[5] || (_cache[5] = _createTextVNode(" Your browser does not support the audio element. "))
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ])
  ]))
}