import {
    CallTimeRange,
    CallStatus,
    CallType,
    NumberModel,
    UserModel,
    UserLabelModel,
    MultiSelectOption,
} from '@/types';

export interface CallReportFilter {
    type: MultiSelectOption<CallType> | null;
    status?: MultiSelectOption<CallStatus> | null;
    user?: UserModel | null;
    number?: NumberModel | null;
    userLabel?: UserLabelModel | null;
    serviceTimeRange?: MultiSelectOption<CallTimeRange> | null;
}
export interface CallReportState {
    filter: CallReportFilter;
}

export enum CallReportMutations {
    SET_FILTER = 'callReport_setFilter',
    CLEAR_FILTER = 'callReport_clearFilter',
}

export enum CallReportActions {
    SET_FILTER = 'callReport_setFilter',
    CLEAR_FILTER = 'callReport_clearFilter',
}

export enum CallReportGetters {
    DEFAULT_FILTER = 'callReport_defaultFilter',
    FILTER = 'callReport_filter',
    ACTIVE_FILTERS = 'callReport_activeFilters',
}
