import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';

import ConnectFlowService from '@/services/api/ConnectFlowService';
import { toast, toastServiceError } from '@/services/core/notification';

import {
    ConnectFlowActions,
    ConnectFlowGetters,
    ConnectFlowModel,
    ConnectFlowFilter,
    ConnectFlowServiceGetAllParams,
    FilterItem,
    SortMenuItem,
    Permission,
} from '@/types';

import {
    ConnectFlowListItem,
    ConnectFlowListSort,
    ConnectFlowListFilters,
} from '@/views/pages/connect-flow/components';

import ConnectFlowIcon from '@/components/svg/ConnectFlowIcon';

const perPage = 10;


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();

const filterStore = useFilterStore();
const uiStore = useUiStore();

const connectFlows = ref<ConnectFlowModel[]>([]);
const count = ref(0);
const currentPage = computed({
    get: () => store.getters[ConnectFlowGetters.PAGE],
    set: (value: number) => store.dispatch(ConnectFlowActions.SET_PAGE, value),
});

const search = computed({
    get: () => store.getters[ConnectFlowGetters.SEARCH],
    set: (value: string) => store.dispatch(ConnectFlowActions.SET_SEARCH, value),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[ConnectFlowGetters.SORT],
    set: (value: SortMenuItem[]) => store.dispatch(ConnectFlowActions.SET_SORT, value),
});

const levelFilter = computed(() => filterStore.level);
const filter: ConnectFlowFilter = store.getters[ConnectFlowGetters.FILTER];

const activeFilters = computed<FilterItem[]>(
    () => store.getters[ConnectFlowGetters.ACTIVE_FILTERS],
);

const orderBy = computed(() => sortMenuSelected.value.map((s) => s.direction + s.key).join(','));

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getParams = (): ConnectFlowServiceGetAllParams => ({
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    is_active: filter.isActive !== null ? (filter.isActive.value as boolean) : undefined,
    search: search.value,
});

const fetchConnectFlows = async () => {
    await setLoading(true);
    try {
        const { data, headers } = await ConnectFlowService.getAll<ConnectFlowModel[]>({
            params: getParams(),
        });
        connectFlows.value = data;
        count.value = parseInt(headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const setIsActive = async (item: ConnectFlowModel, isActive: boolean) => {
    await setLoading(true);
    try {
        const { data } = await (
            isActive
                ? (id: number) => ConnectFlowService.activate(id)
                : (id: number) => ConnectFlowService.inactivate(id)
        )(item.id);
        toast.success(data.message);
        await fetchConnectFlows();
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const handleActivateClick = (item: ConnectFlowModel) => setIsActive(item, true);
const handleInactivateClick = (item: ConnectFlowModel) => setIsActive(item, false);

const handleRemoveSortClick = (item: SortMenuItem) => {
    const index = sortMenuSelected.value.indexOf(item);
    if (index > -1) sortMenuSelected.value.splice(index, 1);
};

const handleRemoveFilterClick = (item: FilterItem) => {
    store.dispatch(ConnectFlowActions.CLEAR_FILTER, item);
};

watch([currentPage], () => {
    fetchConnectFlows();
});

watch([levelFilter, search, filter, orderBy], () => {
    if (currentPage.value === 1) fetchConnectFlows();
    else currentPage.value = 1;
});

if (route.query.clearStateOnSetup) {
    search.value = '';
    sortMenuSelected.value = [];
    currentPage.value = 1;
    router.replace({ query: {} });
}

onMounted(fetchConnectFlows);

const __returned__ = { route, router, filterStore, uiStore, connectFlows, count, perPage, currentPage, search, sortMenuSelected, levelFilter, filter, activeFilters, orderBy, setLoading, getParams, fetchConnectFlows, setIsActive, handleActivateClick, handleInactivateClick, handleRemoveSortClick, handleRemoveFilterClick, get Permission() { return Permission }, get ConnectFlowListItem() { return ConnectFlowListItem }, get ConnectFlowListSort() { return ConnectFlowListSort }, get ConnectFlowListFilters() { return ConnectFlowListFilters }, get ConnectFlowIcon() { return ConnectFlowIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})