import type { FunctionalComponent, HTMLAttributes, VNodeProps } from 'vue';
import { Permission } from '@/types';
import { LocationQueryRaw } from 'vue-router';

export interface NavigationItem {
    localeKey: string;
    icon?: FunctionalComponent<HTMLAttributes & VNodeProps>;
    to?: string;
    name?: string;
    permission?: Permission;
    children?: NavigationItem[];
    query?: LocationQueryRaw;
    isOpen?: boolean;
    activateBy?: string[];
}
