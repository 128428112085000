import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, onMounted, type Ref } from 'vue';
import { JSX } from 'vue/jsx-runtime';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import {
    TemplateHeader,
    TemplateSidebar,
    TemplateNavigation,
    TemplateProfile,
} from '@/views/templates/components';

import { LevelModel, NavigationItem, AuthGetters } from '@/types';
import LevelService from '@/services/api/LevelService';
import { toastServiceError } from '@/services/core/notification';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseTemplate',
  props: {
    navigationItems: { type: Array, required: true },
    headerActions: { type: Function, required: false },
    title: { type: String, required: false },
    routerKey: { type: String, required: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();



const uiStore = useUiStore();
const filterStore = useFilterStore();

const levels: Ref<LevelModel[]> = ref([]);

const logoPath = computed(() => {
    const company = store.getters[AuthGetters.USER_COMPANY];
    return company?.templateLogo || '/img/logo-mob.png';
});

const loading = computed(() => uiStore.isLoading);

const isLevelLocked = computed(() => uiStore.isLevelLocked);

const levelFilter = computed<LevelModel | null>({
    get: () => filterStore.level,
    set: (level: LevelModel) => filterStore.setLevel(level),
});

const headerClass = computed(() => {
    if (process.env.NODE_ENV === 'production') return 'bg-slate-100';
    if (process.env.NODE_ENV === 'development') return 'bg-teal-700';
    return 'bg-gray-700';
});

onMounted(async () => {
    try {
        const response = await LevelService.getAllGlobalSelect<LevelModel[]>();
        levels.value = response.data;
    } catch (error) {
        toastServiceError(error);
    }
});

const __returned__ = { uiStore, filterStore, levels, logoPath, loading, isLevelLocked, levelFilter, headerClass, get TemplateHeader() { return TemplateHeader }, get TemplateSidebar() { return TemplateSidebar }, get TemplateNavigation() { return TemplateNavigation }, get TemplateProfile() { return TemplateProfile } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})