import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "hidden sm:block" }
const _hoisted_2 = {
  autocomplete: "off",
  class: "flex flex-col gap-3"
}
const _hoisted_3 = { class: "grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6" }
const _hoisted_4 = { class: "sm:col-span-4" }
const _hoisted_5 = { class: "block text-sm font-medium text-gray-800" }
const _hoisted_6 = { class: "grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6" }
const _hoisted_7 = { class: "sm:col-span-4" }
const _hoisted_8 = { class: "block text-sm font-medium text-gray-800" }
const _hoisted_9 = { class: "grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6" }
const _hoisted_10 = { class: "sm:col-span-4" }
const _hoisted_11 = { class: "block text-sm font-medium text-gray-800" }
const _hoisted_12 = { class: "mt-4 grid grid-cols-2 place-content-around gap-4 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_UiMultiselect = _resolveComponent("UiMultiselect")!
  const _component_UiRightPanel = _resolveComponent("UiRightPanel")!

  return (_openBlock(), _createBlock(_component_UiRightPanel, {
    modelValue: $setup.filtersOpened,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.filtersOpened) = $event)),
    title: _ctx.$t('filter.sidebar.title')
  }, {
    button: _withCtx(() => [
      _createVNode(_component_UiButton, {
        size: "md",
        variant: "white",
        "text-variant": "gray-700",
        class: "hover:bg-gray-50",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.filtersOpened = true))
      }, {
        default: _withCtx(() => [
          _createVNode($setup["FilterIcon"], {
            class: "mr-0 h-5 w-5 text-gray-400 sm:mr-3",
            "aria-hidden": "true"
          }),
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('core.actions.Filter')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('number.filters.hasConnectFlow.label')), 1 /* TEXT */),
            _createVNode(_component_UiMultiselect, {
              name: "filters_hasConnectFlow",
              modelValue: $setup.filter.hasConnectFlow,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.filter.hasConnectFlow) = $event)),
              options: $setup.hasConnectFlowFilterOptions,
              "can-clear": "",
              placeholder: $setup.t('core.options.All')
            }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('number.filters.state.label')), 1 /* TEXT */),
            _createVNode(_component_UiMultiselect, {
              name: "filters_state",
              modelValue: $setup.filter.state,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.filter.state) = $event)),
              options: $setup.stateOptions,
              "can-clear": "",
              placeholder: $setup.t('core.options.All')
            }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('number.filters.city.label')), 1 /* TEXT */),
            _createVNode(_component_UiMultiselect, {
              name: "filters_city",
              modelValue: $setup.filter.city,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.filter.city) = $event)),
              options: $setup.cityOptions,
              "can-clear": "",
              placeholder: $setup.t('core.options.All')
            }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_UiButton, {
            variant: "white",
            "text-variant": "gray-700",
            onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.clearFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Clear')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_UiButton, {
            variant: "primary",
            "text-variant": "white",
            onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.applyFilters()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Apply')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title"]))
}