import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue';
import moment from 'moment';
import { colord } from 'colord';
import { useI18n } from 'vue-i18n';
import { EChartsOption } from 'echarts';
import { CallbackDataParams, SeriesOption } from 'echarts/types/dist/shared';
import { ChartDataItem, CallTMCount } from '@/types';
import { formatSecToTime } from '@/utils/datetime';


export default /*@__PURE__*/_defineComponent({
  __name: 'CallAverageTimePerDayBarChart',
  props: {
    title: {
        type: String,
        required: true,
    },
    data: {
        type: Array<ChartDataItem<CallTMCount>>,
        required: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const props = __props;

const chartOption = computed<EChartsOption>(() => {
    const baseColor = colord('#0FA5E8');

    const days: string[] = [];
    const data: ChartDataItem<number[]>[] = [
        {
            value: [],
            name: t('report.call.averageServiceTime'),
            color: baseColor.toHex(),
        },
        {
            value: [],
            name: t('report.call.averageWaitingTime'),
            color: baseColor.lighten(0.2).toHex(),
        },
    ];

    for (let i = 0; i < props.data.length; i++) {
        const dataItemOption = props.data[i];

        days.push(moment.utc(dataItemOption.name).format('DD/MM'));
        data[0].value.push(dataItemOption.value.service);
        data[1].value.push(dataItemOption.value.waiting);
    }

    const series: SeriesOption[] = data.map((d) => {
        const seriesOption: SeriesOption = {
            name: d.name,
            type: 'bar',
            stack: 'total',
            label: {
                show: false,
            },
            itemStyle: {
                color: d.color,
            },
            emphasis: {
                focus: 'series',
            },
            data: d.value,
        };
        return seriesOption;
    });

    const eChartsOption: EChartsOption = {
        title: {
            text: props.title,
            left: '14px',
            top: '14px',
            textStyle: {
                fontSize: '16px',
                fontWeight: 'bold',
                color: '#555',
            },
        },
        xAxis: {
            type: 'category',
            axisLabel: {
                fontSize: '9px',
            },
            data: days,
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                fontSize: '9px',
                formatter: (value: number) => {
                    return formatSecToTime(value);
                },
            },
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
            },
            formatter: (params: CallbackDataParams[]) => {
                const name = params[0].name;
                let output = '<b>' + name + '</b><br/>';
                for (let i = 0; i < params.length; i++) {
                    const value = formatSecToTime(params[i].value);
                    output +=
                        (params[i].marker || '') + (params[i].seriesName || '') + ': ' + value;

                    if (i != params.length - 1) {
                        // Append a <br/> tag if not last in loop
                        output += '<br/>';
                    }
                }
                return output;
            },
        },
        legend: {
            top: '15%',
            left: 'center',
            itemHeight: 10,
            itemWidth: 10,
            icon: 'circle',
            textStyle: {
                fontSize: '11px',
                lineHeight: 14,
                rich: {
                    a: {
                        align: 'center', // Alinha o texto da legenda horizontalmente ao meio
                        verticalAlign: 'bottom', // Alinha o texto da legenda verticalmente ao meio
                    },
                    b: {
                        align: 'center', // Alinha o valor numérico horizontalmente ao meio
                        verticalAlign: 'bottom', // Alinha o valor numérico verticalmente ao meio
                    },
                },
            },
            formatter: function (name) {
                return '{a|' + name + '}'; // Formata o texto da legenda para adicionar um valor numérico
            },
            selectedMode: true,
        },
        grid: {
            left: '3%',
            right: '3%',
            bottom: '3%',
            top: '30%',
            containLabel: true,
        },
        series: series,
    };
    return eChartsOption;
});

const __returned__ = { t, props, chartOption }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})