import { RouteRecordRaw } from 'vue-router';
import { validateIdAsPositiveIntegerGuard } from '@/router/routeGuards';
import UserPage from '@/views/pages/user/UserPage.vue';
import UserDetailPage from '@/views/pages/user/UserDetailPage.vue';
import UserFormPage from '@/views/pages/user/UserFormPage.vue';
import { FormAction } from '@/types';

const userRouter: RouteRecordRaw = {
    name: 'User',
    path: 'user',
    children: [
        {
            name: 'ListUser',
            path: '',
            component: UserPage,
        },
        {
            name: 'ViewUserById',
            path: ':id',
            component: UserDetailPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            meta: { isLevelLocked: true },
        },
        {
            name: 'EditUserById',
            path: ':id/edit',
            component: UserFormPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            props: { action: FormAction.EDIT },
            meta: { isLevelLocked: true },
        },
        {
            name: 'CreateUser',
            path: 'create',
            component: UserFormPage,
            props: { action: FormAction.CREATE },
            meta: { isLevelLocked: true },
        },
    ],
};

export default userRouter;
