import { CallParticipantType } from '@/types';

export interface CallPlayerState {
    searchQuery: string;
    selectedWords: Map<CallParticipantType, Set<string>>;
}

export enum CallPlayerMutations {
    SET_SEARCH_QUERY = 'call_player_set_search_query',
    TOGGLE_SELECTED_WORD = 'call_player_toggle_selected_word',
}

export enum CallPlayerActions {}

export enum CallPlayerGetters {
    SEARCH_QUERY = 'call_player_search_query',
    SELECTED_WORDS = 'call_player_selected_words',
}
