<script lang="ts" setup>
import { computed, reactive, ref, Ref, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { required, maxLength } from '@vuelidate/validators';

import { toast, toastServiceError } from '@/services/core/notification';
import { NumberService, LevelService, ConnectFlowService } from '@/services';
import { goBack } from '@/composables/useNavigation';
import { useUiStore } from '@/stores';
import { applyPhoneMask } from '@/utils/number';

import {
    ConnectFlowModel,
    DefaultServiceResult,
    LevelModel,
    NumberFormState,
    NumberModel,
    Permission,
} from '@/types';

const formState = reactive<NumberFormState>({
    levelId: undefined,
    connectFlowId: undefined,
    mediaLabel: undefined,
});
const number = ref<NumberModel>();
const levels = ref<LevelModel[]>([]);
const connectFlows = ref<ConnectFlowModel[]>([]);
const isInitialLoad = ref(true);

const rules = computed(() => ({
    levelId: { required },
    connectFlowId: {},
    mediaLabel: { maxLength: maxLength(250) },
}));

const route = useRoute();
const uiStore = useUiStore();
const v$: Ref<Validation<ValidationArgs<NumberFormState>>> = useVuelidate(rules, formState);

watch(
    () => formState.levelId,
    async (newVal, oldVal) => {
        if (!isInitialLoad.value && newVal !== oldVal) {
            formState.connectFlowId = null;
        }
        await fetchConnectFlows();
    },
);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchNumber = async (id: number) => {
    try {
        setLoading(true);
        const { data } = await NumberService.get<NumberModel>(id);
        number.value = data;
        formState.levelId = data.level.id;
        formState.connectFlowId = data.connectFlowId;
        formState.mediaLabel = data.mediaLabel;
    } catch (err) {
        toastServiceError(err);
    } finally {
        setLoading(false);
    }
};

const fetchLevels = async () => {
    try {
        setLoading(true);
        const { data } = await LevelService.getAll<LevelModel[]>({
            params: { page: 1, per_page: 100, isActive: true },
        });
        levels.value = data;
    } finally {
        setLoading(false);
    }
};

const fetchConnectFlows = async () => {
    if (formState.levelId) {
        try {
            const { data } = await ConnectFlowService.getAll<ConnectFlowModel[]>({
                params: {
                    page: 1,
                    per_page: 100,
                    level_id: formState.levelId,
                    isActive: true,
                },
            });
            connectFlows.value = data;
        } catch (err) {
            toastServiceError(err);
        }
    }
};

const onSubmit = async () => {
    const isFormCorrect = await v$.value.$validate();
    if (!isFormCorrect) return;

    try {
        setLoading(true);
        const id = number.value?.id as number;
        const { data } = await NumberService.update<DefaultServiceResult>(id, formState);
        toast.success(data.message);
        v$.value.$reset();
        goBack({ name: 'ViewNumberById', params: { id } });
    } catch (err) {
        toastServiceError(err);
    } finally {
        setLoading(false);
    }
};

onMounted(async () => {
    await fetchNumber(Number(route.params.id));
    await fetchLevels();

    if (formState.levelId) {
        await fetchConnectFlows();
    }

    isInitialLoad.value = false;
});
</script>

<template>
    <form class="space-y-4 p-4 sm:p-6 lg:p-8" autocomplete="off" @submit.prevent="onSubmit">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('number.edit.title') }}
                </h1>
            </template>
            <template #actions>
                <div class="flex items-center space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ListNumber' }" />
                    <UiButton
                        v-if="$can(Permission.EDIT_NUMBER)"
                        type="submit"
                        variant="primary"
                        text-variant="white"
                    >
                        {{ $t('core.actions.Save') }}
                    </UiButton>
                </div>
            </template>
        </UiPageHeader>

        <UiPanel>
            <div v-if="number" class="grid grid-cols-1 gap-4 md:grid-cols-4">
                <div>
                    <label class="mb-2 block text-sm font-medium text-gray-800">
                        {{ $t('core.entity.Level') }}
                    </label>
                    <UiMultiselect
                        v-model="formState.levelId"
                        name="level"
                        label="name"
                        value-prop="id"
                        :primitive="true"
                        :options="levels"
                    />
                </div>
                <UiTextInput
                    :model-value="number ? applyPhoneMask(number.number) : ''"
                    name="mediaLabel"
                    type="text"
                    :label="$t('number.labels.number')"
                    disabled
                />
                <UiTextInput
                    :model-value="`${number?.city} / ${number?.state}`"
                    name="mediaLabel"
                    type="text"
                    :label="$t('number.labels.city')"
                    disabled
                />
                <UiTextInput
                    :model-value="$t(`number.types.${number?.type}`)"
                    name="mediaLabel"
                    type="text"
                    :label="$t('number.labels.type')"
                    disabled
                />
                <div>
                    <label class="mb-2 block text-sm font-medium text-gray-800">
                        {{ $t('core.entity.ConnectFlow') }}
                    </label>
                    <UiMultiselect
                        v-model="formState.connectFlowId"
                        name="connectFlow"
                        label="name"
                        value-prop="id"
                        :primitive="true"
                        :options="connectFlows"
                        can-clear
                    />
                </div>
                <UiTextInput
                    v-model="formState.mediaLabel"
                    name="mediaLabel"
                    type="text"
                    :label="$t('number.labels.mediaLabel')"
                    :errors="v$.mediaLabel?.$errors"
                    @blur="v$.mediaLabel?.$touch"
                />
            </div>
        </UiPanel>
        <UiCheckDirtyBeforeRouteLeave :dirty="v$.$anyDirty" />
    </form>
</template>
