import { defineComponent as _defineComponent } from 'vue'
import { computed, Ref, ref, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

import store from '@/store';
import { useFilterStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import { UserLabelService, UserService, NumberService } from '@/services';

import {
    MultiSelectOption,
    ContactCenterReportFilter,
    UserModel,
    LevelModel,
    NumberModel,
    UserLabelModel,
    ContactCenterReportGetters,
    ContactCenterReportActions,
    CallTimeRange,
    CallStatus,
    CallType,
} from '@/types';

import { FilterIcon } from '@heroicons/vue/solid';

// Constants and props

export default /*@__PURE__*/_defineComponent({
  __name: 'ContactCenterPerAgentReportFilters',
  setup(__props, { expose: __expose }) {
  __expose();

const filterOpened = ref(false);

// Composables
const filterStore = useFilterStore();
const { t } = useI18n();

// State
const userOptions: Ref<UserModel[]> = ref([]);
const userLabelOptions: Ref<UserLabelModel[]> = ref([]);
const numberOptions: Ref<NumberModel[]> = ref([]);

const typeOptions: Ref<MultiSelectOption[]> = ref([
    { value: CallType.INBOUND, label: t('core.options.call.type.inbound') },
    { value: CallType.OUTBOUND, label: t('core.options.call.type.outbound') },
    { value: CallType.INTERNAL, label: t('core.options.call.type.internal') },
]);

const statusOptions: Ref<MultiSelectOption[]> = ref([
    { value: CallStatus.ANSWERED, label: t('core.options.call.status.answered') },
    { value: CallStatus.NO_ANSWER, label: t('core.options.call.status.no_answer') },
    { value: CallStatus.BUSY, label: t('core.options.call.status.busy') },
    { value: CallStatus.DITCHED, label: t('core.options.call.status.ditched') },
]);

const serviceTimeRangeOptions: Ref<MultiSelectOption[]> = ref(
    [
        CallTimeRange.BELOW_30S,
        CallTimeRange.BETWEEN_30S_AND_60S,
        CallTimeRange.BETWEEN_61_AND_180S,
        CallTimeRange.BETWEEN_181S_AND_300S,
        CallTimeRange.BETWEEN_301S_AND_480S,
        CallTimeRange.BETWEEN_481S_AND_600S,
        CallTimeRange.ABOVE_600,
    ].map((range) => ({
        value: range,
        label: t('core.options.call.serviceTimeRange.' + range),
    })),
);

// Computed
const defaultFilter = computed(() => store.getters[ContactCenterReportGetters.DEFAULT_FILTER]);
const storeFilter = computed(() => store.getters[ContactCenterReportGetters.FILTER]);
const levelFilter = computed<LevelModel | null>(() => filterStore.level);

const filter: Ref<ContactCenterReportFilter> = ref({
    type: defaultFilter.value.type,
    status: defaultFilter.value.status,
    user: defaultFilter.value.user,
    number: defaultFilter.value.number,
    serviceTimeRange: defaultFilter.value.serviceTimeRange,
    userLabel: defaultFilter.value.userLabel,
});

// Watchers
watch(filterOpened, () => {
    refreshLocalFilter();
});

watch(levelFilter, () => {
    store.dispatch(ContactCenterReportActions.CLEAR_FILTER, 'user');
    fetchUsers();
    store.dispatch(ContactCenterReportActions.CLEAR_FILTER, 'number');
    fetchNumbers();
    store.dispatch(ContactCenterReportActions.CLEAR_FILTER, 'userLabel');
    fetchUserLabels();
});

// Methods
const fetchUsers = async () => {
    try {
        const response = await UserService.getAll<UserModel[]>({
            params: { isAgent: true, page: 1, per_page: 100 },
        });
        userOptions.value = response.data;
    } catch (error) {
        toastServiceError(error);
    }
};

const fetchUserLabels = async () => {
    try {
        const response = await UserLabelService.getAll<UserLabelModel[]>();
        userLabelOptions.value = response.data;
    } catch (error) {
        toastServiceError(error);
    }
};

const fetchNumbers = async () => {
    try {
        const response = await NumberService.getAll<NumberModel[]>();
        numberOptions.value = response.data;
    } catch (error) {
        toastServiceError(error);
    }
};

const applyFilters = () => {
    store.dispatch(ContactCenterReportActions.SET_FILTER, filter.value);
};

const clearFilters = () => {
    store.dispatch(ContactCenterReportActions.CLEAR_FILTER);
    refreshLocalFilter();
};

const refreshLocalFilter = () => {
    filter.value.type = storeFilter.value.type;
    filter.value.status = storeFilter.value.status;
    filter.value.user = storeFilter.value.user;
    filter.value.number = storeFilter.value.number;
    filter.value.serviceTimeRange = storeFilter.value.serviceTimeRange;
    filter.value.userLabel = storeFilter.value.userLabel;
};

// Lifecycle
onMounted(() => {
    fetchUsers();
    fetchNumbers();
    fetchUserLabels();
});

const __returned__ = { filterOpened, filterStore, t, userOptions, userLabelOptions, numberOptions, typeOptions, statusOptions, serviceTimeRangeOptions, defaultFilter, storeFilter, levelFilter, filter, fetchUsers, fetchUserLabels, fetchNumbers, applyFilters, clearFilters, refreshLocalFilter, get FilterIcon() { return FilterIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})