import { defineComponent as _defineComponent } from 'vue'
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AxiosResponse } from 'axios';

import { useUiStore } from '@/stores';
import QueueService from '@/services/api/QueueService';
import VaultService from '@/services/api/VaultService';
import { toastServiceError } from '@/services/core/notification';
import { Permission, QueueModel } from '@/types';
import { getAudioFormat } from '@/utils/audio';


export default /*@__PURE__*/_defineComponent({
  __name: 'QueueDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const queue = ref<QueueModel | undefined>(undefined);
const audioData = ref();

const route = useRoute();
const uiStore = useUiStore();

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchQueue = async (id: number) => {
    setLoading(true);
    try {
        const response: AxiosResponse<QueueModel> = await QueueService.get<QueueModel>(id);
        queue.value = response.data;

        if (queue.value.fileAudio) {
            const { data: audioAsset } = await VaultService.getAudioAsset(queue.value.fileAudio);
            audioData.value = URL.createObjectURL(
                new Blob([audioAsset], {
                    type: getAudioFormat(queue.value.fileAudio),
                }),
            );
        }
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

onMounted(() => {
    const id = Number(route.params.id);
    fetchQueue(id);
});

const __returned__ = { queue, audioData, route, uiStore, setLoading, fetchQueue, get Permission() { return Permission }, get getAudioFormat() { return getAudioFormat } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})