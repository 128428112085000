export enum NodeType {
    START = 'nodeStart',
    TIME_VALIDATION = 'timeValidation',
    OPEN_EDGE = 'openEdge',
    PLAY_AUDIO = 'playAudio',
    CLIENT_INFO = 'clientInfo',
    TRANSFER_EXTENSION = 'transferToExtension',
    TRANSFER_EXTERNAL = 'transferToExternal',
    TRANSFER_QUEUE = 'transferToQueue',
    TRANSFER_SURVEY = 'transferToSurvey',
    HANG_UP = 'hangUp',
    GO_TO = 'goTo',
}
