import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, ref, Ref, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { required, maxLength } from '@/utils/validators';

import { goBack } from '@/composables/useNavigation';
import { useUiStore } from '@/stores';
import { QueueService, UserService, VaultService } from '@/services';
import { toast, toastServiceError } from '@/services/core/notification';
import { getAudioFormat } from '@/utils/audio';

import {
    FormAction,
    MultiSelectOption,
    Permission,
    QueueFormState,
    QueueModel,
    QueueStrategy,
    UserModel,
} from '@/types';

import { AxiosResponse } from 'axios';


export default /*@__PURE__*/_defineComponent({
  __name: 'QueueFormPage',
  props: {
    action: { type: String, default: FormAction.CREATE },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const route = useRoute();
const uiStore = useUiStore();
const { t } = useI18n();

const formState = reactive<QueueFormState>({
    name: '',
    strategy: '',
    users: [],
    fileAudio: '',
    isActive: true,
});

const file = ref<File | null>(null);
const audioPreview = ref<string | null>(null);
const users: Ref<UserModel[]> = ref([]);
const strategyOptions: Ref<MultiSelectOption[]> = ref([
    { label: t(`queue.strategy.${QueueStrategy.RANDOM}`), value: QueueStrategy.RANDOM },
    { label: t(`queue.strategy.${QueueStrategy.RRORDERED}`), value: QueueStrategy.RRORDERED },
    { label: t(`queue.strategy.${QueueStrategy.RRMEMORY}`), value: QueueStrategy.RRMEMORY },
    { label: t(`queue.strategy.${QueueStrategy.RINGALL}`), value: QueueStrategy.RINGALL },
    { label: t(`queue.strategy.${QueueStrategy.FEWESTCALLS}`), value: QueueStrategy.FEWESTCALLS },
    { label: t(`queue.strategy.${QueueStrategy.LEASTRECENT}`), value: QueueStrategy.LEASTRECENT },
]);

const rules = computed<ValidationArgs<QueueFormState>>(() => ({
    name: { required, maxLength: maxLength(50) },
    strategy: { required },
    users: {},
    fileAudio: {},
    isActive: { required },
}));

const v$: Ref<Validation<ValidationArgs<QueueFormState>>> = useVuelidate(rules, formState);

watch([file], () => {
    if (file.value) {
        formState.fileAudio = file.value.name;
        audioPreview.value = URL.createObjectURL(file.value);
    } else {
        formState.fileAudio = '';
        audioPreview.value = null;
    }
});

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchUsers = async () => {
    try {
        setLoading(true);
        const { data } = await UserService.getAll<UserModel[]>({
            params: { page: 1, per_page: 100 },
        });
        users.value = data;
    } catch (e) {
        toastServiceError(e);
    } finally {
        setLoading(false);
    }
};

const fetchQueue = async (id: number) => {
    try {
        setLoading(true);
        const { data } = await QueueService.get<QueueModel>(id);
        formState.name = data.name;
        formState.strategy = data.strategy;
        formState.users = (data.users || []).map((u) => u.id);
        formState.fileAudio = data.fileAudio;
        formState.isActive = data.isActive;
        loadAudio();
    } catch (e) {
        toastServiceError(e);
    } finally {
        setLoading(false);
    }
};

const loadAudio = async () => {
    if (formState.fileAudio) {
        try {
            const { data } = await VaultService.getAudioAsset(formState.fileAudio);
            const blob = new Blob([data], { type: getAudioFormat(formState.fileAudio) });
            file.value = new File([blob], formState.fileAudio, {
                type: blob.type,
                lastModified: Date.now(),
            });
        } catch (e) {
            toastServiceError(e);
        }
    }
};

const onSubmit = async () => {
    const isFormValid = await v$.value.$validate();
    if (!isFormValid) return;

    setLoading(true);

    const handleServiceSuccess = (message: string, id: number) => {
        toast.success(message);
        v$.value.$reset();
        goBack({ name: 'ViewQueueById', params: { id } });
    };

    try {
        const formData = new FormData();
        formData.append('name', formState.name);
        formData.append('strategy', formState.strategy);
        formData.append('isActive', formState.isActive.toString());
        formState.users.forEach((user, index) => {
            formData.append(`users[${index}]`, user.toString());
        });
        if (file.value) formData.append('audio', file.value);

        if (props.action === FormAction.EDIT) {
            const id = Number(route.params.id);
            const { data }: AxiosResponse = await QueueService.update(id, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            handleServiceSuccess(data.message, id);
        } else {
            const { data }: AxiosResponse = await QueueService.create(formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            handleServiceSuccess(data.message, data.result.id);
        }
    } catch (e) {
        toastServiceError(e);
    } finally {
        setLoading(false);
    }
};

onMounted(async () => {
    await fetchUsers();
    if (props.action === FormAction.EDIT) {
        await fetchQueue(Number(route.params.id));
    }
});

const __returned__ = { props, route, uiStore, t, formState, file, audioPreview, users, strategyOptions, rules, v$, setLoading, fetchUsers, fetchQueue, loadAudio, onSubmit, get FormAction() { return FormAction }, get Permission() { return Permission } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})