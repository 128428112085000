import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "text-xl font-semibold text-gray-800" }
const _hoisted_2 = { class: "flex items-center space-x-4" }
const _hoisted_3 = {
  key: 0,
  class: "grid grid-cols-1 gap-4 md:grid-cols-4"
}
const _hoisted_4 = { class: "mb-2 block text-sm font-medium text-gray-800" }
const _hoisted_5 = { class: "mb-2 block text-sm font-medium text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiBackButton = _resolveComponent("UiBackButton")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!
  const _component_UiMultiselect = _resolveComponent("UiMultiselect")!
  const _component_UiTextInput = _resolveComponent("UiTextInput")!
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _component_UiCheckDirtyBeforeRouteLeave = _resolveComponent("UiCheckDirtyBeforeRouteLeave")!

  return (_openBlock(), _createElementBlock("form", {
    class: "space-y-4 p-4 sm:p-6 lg:p-8",
    autocomplete: "off",
    onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
  }, [
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('number.edit.title')), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_UiBackButton, { fallbackTo: { name: 'ListNumber' } }),
          (_ctx.$can($setup.Permission.EDIT_NUMBER))
            ? (_openBlock(), _createBlock(_component_UiButton, {
                key: 0,
                type: "submit",
                variant: "primary",
                "text-variant": "white"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Save')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_UiPanel, null, {
      default: _withCtx(() => [
        ($setup.number)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", null, [
                _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('core.entity.Level')), 1 /* TEXT */),
                _createVNode(_component_UiMultiselect, {
                  modelValue: $setup.formState.levelId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formState.levelId) = $event)),
                  name: "level",
                  label: "name",
                  "value-prop": "id",
                  primitive: true,
                  options: $setup.levels
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ]),
              _createVNode(_component_UiTextInput, {
                "model-value": $setup.number ? $setup.applyPhoneMask($setup.number.number) : '',
                name: "mediaLabel",
                type: "text",
                label: _ctx.$t('number.labels.number'),
                disabled: ""
              }, null, 8 /* PROPS */, ["model-value", "label"]),
              _createVNode(_component_UiTextInput, {
                "model-value": `${$setup.number?.city} / ${$setup.number?.state}`,
                name: "mediaLabel",
                type: "text",
                label: _ctx.$t('number.labels.city'),
                disabled: ""
              }, null, 8 /* PROPS */, ["model-value", "label"]),
              _createVNode(_component_UiTextInput, {
                "model-value": _ctx.$t(`number.types.${$setup.number?.type}`),
                name: "mediaLabel",
                type: "text",
                label: _ctx.$t('number.labels.type'),
                disabled: ""
              }, null, 8 /* PROPS */, ["model-value", "label"]),
              _createElementVNode("div", null, [
                _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('core.entity.ConnectFlow')), 1 /* TEXT */),
                _createVNode(_component_UiMultiselect, {
                  modelValue: $setup.formState.connectFlowId,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formState.connectFlowId) = $event)),
                  name: "connectFlow",
                  label: "name",
                  "value-prop": "id",
                  primitive: true,
                  options: $setup.connectFlows,
                  "can-clear": ""
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ]),
              _createVNode(_component_UiTextInput, {
                modelValue: $setup.formState.mediaLabel,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formState.mediaLabel) = $event)),
                name: "mediaLabel",
                type: "text",
                label: _ctx.$t('number.labels.mediaLabel'),
                errors: $setup.v$.mediaLabel?.$errors,
                onBlur: $setup.v$.mediaLabel?.$touch
              }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_UiCheckDirtyBeforeRouteLeave, {
      dirty: $setup.v$.$anyDirty
    }, null, 8 /* PROPS */, ["dirty"])
  ], 32 /* NEED_HYDRATION */))
}