import { ConnectFlowAudioModel, DefaultServiceResult } from '@/types';

export interface ConnectFlowAudioServiceGetAllParams {
    page?: number;
    per_page?: number;
    order_by?: string;
    is_active?: boolean;
    search?: string;
}

export interface ConnectFlowAudioServiceCreateResult extends DefaultServiceResult {
    result: ConnectFlowAudioModel;
}
