<script lang="ts" setup>
import { computed, reactive, watch } from 'vue';
import { useVuelidateWithLogging } from '@/composables/useVuelidateWithLogging';
import { TrashIcon } from '@heroicons/vue/solid';

import { isValidPhone, required, maxLength } from '@/utils/validators';
import { ContactPhoneFormState } from '@/types';

const props = defineProps({
    modelValue: {
        type: Object as () => ContactPhoneFormState,
        required: true,
    },
    index: {
        type: Number,
        required: true,
    },
});

const emit = defineEmits(['update:modelValue', 'remove']);

const formState = reactive<ContactPhoneFormState>(props.modelValue);

const rules = computed(() => ({
    number: { isValidPhone, required, maxLength: maxLength(50) },
}));

const { v$, $validateWithLogging } = useVuelidateWithLogging(rules, formState);

defineExpose({
    validate: () => $validateWithLogging(),
});

watch(
    formState,
    () => {
        emit('update:modelValue', formState);
    },
    {
        deep: true,
    },
);
</script>

<template>
    <div class="mt-2 flex items-center space-x-2 space-y-2 py-2">
        <UiTextInput
            v-model="v$.number.$model"
            name="number"
            type="text"
            :label="$t('contact.form.labels.phone')"
            class="flex-1"
            :disabled="!!formState?.id"
            :errors="v$.number.$errors"
            @blur="v$.number.$touch"
        />

        <UiActionButton
            variant="danger"
            v-tippy="$t('contact.form.actions.removePhone')"
            class="pt-4"
            @click="emit('remove', index)"
        >
            <TrashIcon class="h-5 w-5" />
        </UiActionButton>
    </div>
</template>
