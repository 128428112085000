<script lang="ts" setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { toast, toastServiceError } from '@/services/core/notification';
import { OfficeBuildingIcon } from '@heroicons/vue/solid';

import ScheduleService from '@/services/api/ScheduleService';
import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';

import {
    ScheduleListItem,
    ScheduleListSort,
    ScheduleListFilters,
} from '@/views/pages/schedule/components';

import {
    FilterItem,
    LevelFilter,
    Permission,
    ScheduleActions,
    ScheduleGetters,
    ScheduleModel,
    ScheduleServiceGetAllParams,
    SortMenuItem,
} from '@/types';

const route = useRoute();
const router = useRouter();
const uiStore = useUiStore();
const filterStore = useFilterStore();

// State
const schedules = ref<ScheduleModel[]>([]);
const count = ref(0);
const perPage = 10;

// Computed
const search = computed({
    get: () => store.getters[ScheduleGetters.SEARCH],
    set: (value: string) => store.dispatch(ScheduleActions.SET_SEARCH, value),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[ScheduleGetters.SORT],
    set: (value: SortMenuItem[]) => store.dispatch(ScheduleActions.SET_SORT, value),
});

const currentPage = computed({
    get: () => store.getters[ScheduleGetters.PAGE],
    set: (value: number) => store.dispatch(ScheduleActions.SET_PAGE, value),
});

const filter: LevelFilter = store.getters[ScheduleGetters.FILTER];
const activeFilters = computed<FilterItem[]>(() => store.getters[ScheduleGetters.ACTIVE_FILTERS]);
const levelFilter = computed(() => filterStore.level);

const orderBy = computed(() => sortMenuSelected.value.map((s) => s.direction + s.key).join(','));

// Methods
const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getQueryParams = (): ScheduleServiceGetAllParams => ({
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    is_active: filter.isActive !== null ? (filter.isActive.value as boolean) : undefined,
    search: search.value,
});

const fetchAllSchedules = async () => {
    try {
        setLoading(true);
        const { data, headers } = await ScheduleService.getAll<ScheduleModel[]>({
            params: getQueryParams(),
        });
        schedules.value = data;
        count.value = parseInt(headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const updateScheduleStatus = async (schedule: ScheduleModel, isActive: boolean) => {
    try {
        setLoading(true);
        const { data } = await (isActive
            ? ScheduleService.activate(schedule.id)
            : ScheduleService.inactivate(schedule.id));
        toast.success(data.message);
        await fetchAllSchedules();
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleActivateClick = (item: ScheduleModel) => updateScheduleStatus(item, true);
const handleInactivateClick = (item: ScheduleModel) => updateScheduleStatus(item, false);

const handleRemoveSortClick = (item: SortMenuItem) => {
    const index = sortMenuSelected.value.indexOf(item);
    if (index > -1) sortMenuSelected.value.splice(index, 1);
};

const handleRemoveFilterClick = (item: FilterItem) =>
    store.dispatch(ScheduleActions.CLEAR_FILTER, item);

// Watchers
watch([currentPage], fetchAllSchedules);

watch([levelFilter, search, filter, orderBy], () => {
    if (currentPage.value === 1) fetchAllSchedules();
    else currentPage.value = 1;
});

// Lifecycle
onMounted(() => {
    if (route.query.clearStateOnSetup) {
        search.value = '';
        sortMenuSelected.value = [];
        currentPage.value = 1;
        router.replace({ query: {} });
    }

    fetchAllSchedules();
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="flex items-center text-xl font-semibold text-gray-800">
                    <OfficeBuildingIcon class="mr-1 h-6 w-6 text-gray-500" aria-hidden="true" />
                    {{ $t('core.entity.Schedules') }}
                </h1>
            </template>
            <template #actions>
                <router-link
                    v-if="$can(Permission.CREATE_SCHEDULE)"
                    :to="{ name: 'CreateSchedule' }"
                >
                    <UiButton variant="primary" text-variant="white">
                        {{ $t('core.actions.Add') }}
                    </UiButton>
                </router-link>
            </template>
        </UiPageHeader>

        <div class="space-y-2">
            <UiPanel class="flex px-4 py-2" no-padding>
                <UiListSearch
                    v-model="search"
                    :placeholder="$t('schedule.filters.search')"
                    class="flex-1"
                />
                <div class="flex items-center space-x-4">
                    <ScheduleListSort v-model="sortMenuSelected" />
                    <ScheduleListFilters />
                </div>
            </UiPanel>

            <UiPanel v-if="sortMenuSelected.length || activeFilters.length" class="space-x-2">
                <UiSortMenuBadge
                    v-for="selected in sortMenuSelected"
                    :key="selected.key"
                    :item="selected"
                    @remove="handleRemoveSortClick(selected)"
                />
                <UiBadge
                    v-for="item in activeFilters"
                    :key="`activeFilter${item.field}`"
                    removable
                    @remove="handleRemoveFilterClick(item)"
                >
                    {{ $t(`schedule.filters.${item.field}.label`) }}: {{ item.label }}
                </UiBadge>
            </UiPanel>

            <div v-if="schedules.length" class="space-y-2">
                <ScheduleListItem
                    v-for="schedule in schedules"
                    :key="schedule.id"
                    :model-value="schedule"
                    @activate="handleActivateClick"
                    @inactivate="handleInactivateClick"
                />
            </div>
            <UiPanel v-else class="flex items-center justify-center">
                {{ $t('core.messages.noRecordsFound') }}
            </UiPanel>

            <UiPagination
                v-if="schedules.length"
                v-model="currentPage"
                :count="count"
                :perPage="perPage"
            />
        </div>
    </div>
</template>
