<script lang="ts" setup>
import { type PropType } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { goBack } from '@/composables/useNavigation';

const { fallbackTo } = defineProps({
    fallbackTo: {
        type: Object as PropType<RouteLocationRaw>,
        required: true,
    },
});
</script>

<template>
    <UiButton
        v-tippy="$t('core.actions.Back')"
        text-variant="primary"
        no-border
        no-shadow
        class="hover:bg-white/50"
        @click="goBack(fallbackTo)"
    >
        {{ $t('core.actions.Back') }}
    </UiButton>
</template>
