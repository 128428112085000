<script lang="ts" setup>
import { computed, reactive, onMounted, type Ref } from 'vue';
import { useRoute } from 'vue-router';
import { useVuelidate, type Validation, type ValidationArgs } from '@vuelidate/core';
import { required, maxLength, isValidPhone } from '@/utils/validators';
import { goBack } from '@/composables/useNavigation';
import { useUiStore } from '@/stores';
import { SpamNumberService } from '@/services';
import { toast, toastServiceError } from '@/services/core/notification';
import { Permission, FormAction, type SpamNumberFormState, type SpamNumberModel } from '@/types';
import type { AxiosResponse } from 'axios';

const props = defineProps({
    action: { type: String, default: FormAction.CREATE },
});

const route = useRoute();

const uiStore = useUiStore();

const formState = reactive<SpamNumberFormState>({
    id: null,
    number: null,
});

const rules = computed<ValidationArgs<SpamNumberFormState>>(() => ({
    number: {
        required,
        maxLength: maxLength(15),
        isValidPhone,
    },
}));

const v$: Ref<Validation<ValidationArgs<SpamNumberFormState>>> = useVuelidate(rules, formState);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchSpamNumber = async (id: number) => {
    try {
        setLoading(true);
        const { data } = await SpamNumberService.get<SpamNumberModel>(id);
        formState.id = data.id;
        formState.number = data.number;
    } catch (e) {
        toastServiceError(e);
    } finally {
        setLoading(false);
    }
};

const onSubmit = async () => {
    const isFormValid = await v$.value.$validate();
    if (!isFormValid) return;

    setLoading(true);

    const handleServiceSuccess = (message: string, id: number) => {
        toast.success(message);
        v$.value.$reset();
        goBack({ name: 'ViewSpamNumberById', params: { id } });
    };

    try {
        if (props.action === FormAction.EDIT) {
            const id = Number(route.params.id);
            const { data }: AxiosResponse = await SpamNumberService.update(id, formState);
            handleServiceSuccess(data.message, id);
        } else {
            const { data }: AxiosResponse = await SpamNumberService.create(formState);
            handleServiceSuccess(data.message, data.result.id);
        }
    } catch (e) {
        toastServiceError(e);
    } finally {
        setLoading(false);
    }
};

onMounted(async () => {
    if (props.action === FormAction.EDIT) {
        await fetchSpamNumber(Number(route.params.id));
    }
});
</script>

<template>
    <form class="space-y-4 p-4 sm:p-6 lg:p-8" autocomplete="off" @submit.prevent="onSubmit">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('spamNumber.' + props.action + '.title') }}
                </h1>
            </template>
            <template #actions>
                <div class="flex items-center space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ListSpamNumber' }" />
                    <UiButton
                        v-if="
                            (props.action == FormAction.CREATE &&
                                $can(Permission.CREATE_SPAM_NUMBER)) ||
                            (props.action == FormAction.EDIT && $can(Permission.EDIT_SPAM_NUMBER))
                        "
                        type="submit"
                        variant="primary"
                        text-variant="white"
                    >
                        {{ $t('core.actions.Save') }}
                    </UiButton>
                </div>
            </template>
        </UiPageHeader>
        <UiPanel>
            <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
                <UiTextInput
                    v-model="v$.number.$model"
                    name="number"
                    type="text"
                    :label="$t('spamNumber.labels.number')"
                    :errors="v$.number.$errors"
                    @blur="v$.number.$touch"
                />
            </div>
        </UiPanel>
        <UiCheckDirtyBeforeRouteLeave :dirty="v$.$anyDirty" />
    </form>
</template>
