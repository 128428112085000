<script lang="ts" setup>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XIcon, CogIcon } from '@heroicons/vue/solid';
import type { HTMLAttributes, PropType, VNodeProps } from 'vue';

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true,
        default: false,
    },
    title: {
        type: String,
        required: true,
    },
    icon: {
        type: Function as PropType<HTMLAttributes & VNodeProps>,
        required: false,
        default: CogIcon,
    },
});

const emit = defineEmits(['update:modelValue']);

const handleToggle = (state: boolean) => {
    emit('update:modelValue', state);
};
</script>

<template>
    <slot name="button"></slot>
    <TransitionRoot as="template" :show="props.modelValue">
        <Dialog as="div" class="relative z-50" @close="handleToggle(false)">
            <TransitionChild
                as="template"
                enter="ease-in-out duration-500"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-500"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-white bg-opacity-25 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                        <TransitionChild
                            as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full"
                            enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-x-0"
                            leave-to="translate-x-full"
                        >
                            <DialogPanel class="pointer-events-auto w-screen max-w-lg">
                                <div
                                    class="flex h-full flex-col overflow-y-scroll bg-gray-50 shadow"
                                >
                                    <div>
                                        <div
                                            class="flex items-start justify-between border-b-2 border-gray-200 bg-gray-100 py-3 pl-3 pr-2"
                                        >
                                            <DialogTitle
                                                class="flex items-center text-lg font-semibold text-gray-800"
                                            >
                                                <component
                                                    :is="icon"
                                                    class="text-secondary mr-1 h-8 w-8"
                                                    aria-hidden="true"
                                                />
                                                <span>{{ title }}</span>
                                            </DialogTitle>
                                            <div class="ml-4 flex h-7 items-center">
                                                <button
                                                    type="button"
                                                    class="rounded bg-gray-50 p-1 text-gray-500 ring-1 ring-gray-300 hover:bg-white hover:text-gray-600 focus:ring-2 focus:ring-opacity-50"
                                                    @click="handleToggle(false)"
                                                >
                                                    <span class="sr-only">
                                                        {{ $t('core.actions.Close') }}
                                                    </span>
                                                    <XIcon class="h-5 w-5" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="relative mt-6 flex-1 px-4 sm:px-6">
                                        <slot></slot>
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
