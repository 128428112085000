import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createSlots as _createSlots, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "text-xl font-semibold text-gray-800" }
const _hoisted_2 = { class: "flex items-center space-x-4" }
const _hoisted_3 = { class: "grid grid-cols-1 gap-4 md:grid-cols-3" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  for: "roles",
  class: "mb-2 block text-sm font-medium text-gray-800"
}
const _hoisted_6 = {
  key: 1,
  class: "hidden md:block"
}
const _hoisted_7 = {
  key: 4,
  class: "hidden md:block"
}
const _hoisted_8 = { key: 5 }
const _hoisted_9 = {
  for: "levels",
  class: "mb-2 block text-sm font-medium text-gray-800"
}
const _hoisted_10 = {
  for: "levels",
  class: "mb-2 block text-sm font-medium text-gray-800"
}
const _hoisted_11 = { class: "flex flex-col justify-end pb-1.5" }
const _hoisted_12 = { class: "mb-4 text-base font-semibold text-gray-800" }
const _hoisted_13 = { class: "relative grid grid-cols-2 gap-4 lg:grid-cols-4 xl:grid-cols-6" }
const _hoisted_14 = { class: "absolute -right-[215px] bottom-0 mt-6 flex flex-col justify-between rounded border-b-4 border-r-4 border-cyan-600 bg-gray-50 px-4 py-3 text-sm text-gray-600 shadow" }
const _hoisted_15 = {
  key: 0,
  class: "relative mt-5 grid grid-cols-2 gap-4 md:grid-cols-12"
}
const _hoisted_16 = { class: "col-span-3" }
const _hoisted_17 = {
  for: "level",
  class: "block text-sm font-medium text-gray-800"
}
const _hoisted_18 = {
  key: 1,
  class: "mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiBackButton = _resolveComponent("UiBackButton")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!
  const _component_UiTextInput = _resolveComponent("UiTextInput")!
  const _component_UiMultiselect = _resolveComponent("UiMultiselect")!
  const _component_UiCheckboxInput = _resolveComponent("UiCheckboxInput")!
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _component_UiCheckDirtyBeforeRouteLeave = _resolveComponent("UiCheckDirtyBeforeRouteLeave")!

  return (_openBlock(), _createElementBlock("form", {
    class: "space-y-4 p-4 sm:p-6 lg:p-8",
    autocomplete: "off",
    onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
  }, [
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('user.' + $setup.props.action + '.title')), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_UiBackButton, { fallbackTo: { name: 'ListUser' } }),
          (
                            ($setup.props.action == $setup.FormAction.CREATE && _ctx.$can($setup.Permission.CREATE_USER)) ||
                            ($setup.props.action == $setup.FormAction.EDIT && _ctx.$can($setup.Permission.EDIT_USER))
                        )
            ? (_openBlock(), _createBlock(_component_UiButton, {
                key: 0,
                type: "submit",
                variant: "primary",
                "text-variant": "white"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Save')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_UiPanel, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_UiTextInput, {
            modelValue: $setup.formState.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formState.name) = $event)),
            name: "name",
            type: "text",
            label: _ctx.$t('user.labels.name'),
            errors: $setup.v$.name.$errors,
            required: $setup.v$.name.required !== undefined,
            onBlur: $setup.v$.name.$touch
          }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "required", "onBlur"]),
          _createVNode(_component_UiTextInput, {
            modelValue: $setup.formState.email,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formState.email) = $event)),
            name: "email",
            type: "text",
            label: _ctx.$t('user.labels.email'),
            autocomplete: "new-email",
            errors: $setup.v$.email.$errors,
            required: $setup.v$.email.required !== undefined,
            onBlur: $setup.v$.email.$touch
          }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "required", "onBlur"]),
          (_ctx.$can($setup.Permission.ASSIGN_ROLE_TO_USER))
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("label", _hoisted_5, [
                  _createTextVNode(_toDisplayString(_ctx.$t('user.labels.roles')) + " ", 1 /* TEXT */),
                  _cache[16] || (_cache[16] = _createElementVNode("span", { class: "text-xs" }, "*", -1 /* HOISTED */))
                ]),
                _createVNode(_component_UiMultiselect, {
                  name: "roles",
                  id: "roles",
                  modelValue: $setup.formState.roles,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formState.roles) = $event)),
                  options: $setup.roles,
                  primitive: true,
                  multiple: true,
                  "can-clear": true,
                  errors: $setup.v$.roles?.$errors,
                  label: "name",
                  "value-prop": "id"
                }, null, 8 /* PROPS */, ["modelValue", "options", "errors"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6)),
          ($setup.props.action == $setup.FormAction.CREATE)
            ? (_openBlock(), _createBlock(_component_UiTextInput, {
                key: 2,
                modelValue: $setup.formState.password,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.formState.password) = $event)),
                name: "password",
                type: "password",
                label: _ctx.$t('user.labels.password'),
                autocomplete: "new-password",
                errors: $setup.v$.password?.$errors,
                required: $setup.v$.password?.required !== undefined,
                onBlur: $setup.v$.password?.$touch
              }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "required", "onBlur"]))
            : _createCommentVNode("v-if", true),
          ($setup.props.action == $setup.FormAction.CREATE)
            ? (_openBlock(), _createBlock(_component_UiTextInput, {
                key: 3,
                modelValue: $setup.formState.confirmPassword,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.formState.confirmPassword) = $event)),
                name: "confirmPassword",
                type: "password",
                label: _ctx.$t('user.labels.confirmPassword'),
                errors: $setup.v$.confirmPassword?.$errors,
                required: $setup.v$.confirmPassword?.required !== undefined,
                onBlur: $setup.v$.confirmPassword?.$touch
              }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "required", "onBlur"]))
            : _createCommentVNode("v-if", true),
          ($setup.props.action == $setup.FormAction.CREATE)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7))
            : _createCommentVNode("v-if", true),
          (_ctx.$can($setup.Permission.ASSIGN_LEVEL_TO_USER))
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("label", _hoisted_9, [
                  _createTextVNode(_toDisplayString(_ctx.$t('user.labels.levels')) + " ", 1 /* TEXT */),
                  _cache[17] || (_cache[17] = _createElementVNode("span", { class: "text-xs" }, "*", -1 /* HOISTED */))
                ]),
                _createVNode(_component_UiMultiselect, {
                  name: "levels",
                  id: "levels",
                  modelValue: $setup.formState.levels,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.formState.levels) = $event)),
                  options: $setup.levels,
                  primitive: true,
                  multiple: true,
                  "can-clear": true,
                  errors: $setup.v$.levels?.$errors,
                  label: "name",
                  "value-prop": "id"
                }, null, 8 /* PROPS */, ["modelValue", "options", "errors"])
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", null, [
            _createElementVNode("label", _hoisted_10, [
              _createTextVNode(_toDisplayString(_ctx.$t('user.labels.standardLevel')) + " ", 1 /* TEXT */),
              _cache[18] || (_cache[18] = _createElementVNode("span", { class: "text-xs" }, "*", -1 /* HOISTED */))
            ]),
            _createVNode(_component_UiMultiselect, {
              name: "levels",
              id: "levels",
              modelValue: $setup.formState.standardLevelId,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.formState.standardLevelId) = $event)),
              options: $setup.selectedLevels,
              errors: $setup.v$.standardLevelId?.$errors,
              primitive: "",
              label: "name",
              "value-prop": "id"
            }, null, 8 /* PROPS */, ["modelValue", "options", "errors"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_UiCheckboxInput, {
              modelValue: $setup.formState.isActive,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.formState.isActive) = $event)),
              name: "isActive",
              label: _ctx.$t('user.labels.isActive'),
              class: "h-6"
            }, null, 8 /* PROPS */, ["modelValue", "label"]),
            ($setup.levelHasMeeting)
              ? (_openBlock(), _createBlock(_component_UiCheckboxInput, {
                  key: 0,
                  modelValue: $setup.formState.hasMeeting,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.formState.hasMeeting) = $event)),
                  name: "hasMeeting",
                  label: _ctx.$t('user.labels.hasMeeting'),
                  class: "h-6 pt-1.5"
                }, null, 8 /* PROPS */, ["modelValue", "label"]))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_UiPanel, null, {
      default: _withCtx(() => [
        _createElementVNode("h3", _hoisted_12, _toDisplayString(_ctx.$t('user.labels.devices.title')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_13, [
          _createVNode($setup["UserFormDevice"], {
            label: _ctx.$t('user.labels.devices.mobile'),
            modelValue: $setup.formState.devices.mobile,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.formState.devices.mobile) = $event))
          }, {
            icon: _withCtx(() => [
              _createVNode($setup["DeviceMobileIcon"], { class: "mt-6 h-20 w-20" })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label", "modelValue"]),
          _createVNode($setup["UserFormDevice"], {
            label: _ctx.$t('user.labels.devices.desktop'),
            modelValue: $setup.formState.devices.desktop,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.formState.devices.desktop) = $event))
          }, {
            icon: _withCtx(() => [
              _createVNode($setup["DesktopComputerIcon"], { class: "mt-6 h-20 w-20" })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label", "modelValue"]),
          _createVNode($setup["UserFormDevice"], {
            label: _ctx.$t('user.labels.devices.web'),
            modelValue: $setup.formState.devices.web,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($setup.formState.devices.web) = $event))
          }, {
            icon: _withCtx(() => [
              _createVNode($setup["SVGChromeLogo"], { class: "mt-6 h-20 w-20" })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label", "modelValue"]),
          _createVNode($setup["UserFormDevice"], {
            label: _ctx.$t('user.labels.devices.external'),
            modelValue: $setup.formState.devices.external,
            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (($setup.formState.devices.external) = $event))
          }, _createSlots({
            icon: _withCtx(() => [
              _createVNode($setup["SVGDeviceExternal"], { class: "mt-6 h-20 w-20" })
            ]),
            _: 2 /* DYNAMIC */
          }, [
            ($setup.externalConfig)
              ? {
                  name: "extra-info",
                  fn: _withCtx(() => [
                    _createElementVNode("div", _hoisted_14, [
                      _cache[22] || (_cache[22] = _createElementVNode("h3", { class: "mb-1 text-base font-semibold" }, "Configurações do PABX", -1 /* HOISTED */)),
                      _createElementVNode("p", null, [
                        _cache[19] || (_cache[19] = _createElementVNode("span", { class: "font-semibold" }, "Hostname:", -1 /* HOISTED */)),
                        _createTextVNode(" " + _toDisplayString($setup.externalConfig.hostname), 1 /* TEXT */)
                      ]),
                      _createElementVNode("p", null, [
                        _cache[20] || (_cache[20] = _createElementVNode("span", { class: "font-semibold" }, "Username:", -1 /* HOISTED */)),
                        _createTextVNode(" " + _toDisplayString($setup.externalConfig.sipUser), 1 /* TEXT */)
                      ]),
                      _createElementVNode("p", null, [
                        _cache[21] || (_cache[21] = _createElementVNode("span", { class: "font-semibold" }, "Password:", -1 /* HOISTED */)),
                        _createTextVNode(" " + _toDisplayString($setup.externalConfig.sipPassword), 1 /* TEXT */)
                      ])
                    ])
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "modelValue"])
        ]),
        (!$setup.noDevice)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createVNode($setup["UserFormExtension"], {
                modelValue: $setup.formState.extension,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($setup.formState.extension) = $event)),
                devices: $setup.formState.devices
              }, null, 8 /* PROPS */, ["modelValue", "devices"]),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('user.labels.number')), 1 /* TEXT */),
                ($setup.formState.number)
                  ? (_openBlock(), _createBlock(_component_UiMultiselect, {
                      key: 0,
                      name: `number`,
                      modelValue: $setup.formState.number.numberId,
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (($setup.formState.number.numberId) = $event)),
                      placeholder: _ctx.$t('core.random'),
                      options: $setup.getLevelNumbers($setup.formState.standardLevelId),
                      primitive: true,
                      multiple: false,
                      "can-clear": true,
                      disabled: $setup.noDevice || $setup.formState.useSmartCallerId,
                      label: "number",
                      "value-prop": "id"
                    }, null, 8 /* PROPS */, ["modelValue", "placeholder", "options", "disabled"]))
                  : _createCommentVNode("v-if", true),
                ($setup.hasSmartCallerId)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                      _createVNode(_component_UiCheckboxInput, {
                        modelValue: $setup.formState.useSmartCallerId,
                        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (($setup.formState.useSmartCallerId) = $event)),
                        label: _ctx.$t('user.labels.useSmartCallerId'),
                        name: "useSmartCallerId"
                      }, null, 8 /* PROPS */, ["modelValue", "label"])
                    ]))
                  : _createCommentVNode("v-if", true)
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_UiCheckDirtyBeforeRouteLeave, {
      dirty: $setup.v$.$anyDirty
    }, null, 8 /* PROPS */, ["dirty"])
  ], 32 /* NEED_HYDRATION */))
}