<script lang="ts" setup>
import { computed, reactive, Ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { AxiosResponse } from 'axios';

import { useUiStore } from '@/stores';
import { ScheduleService } from '@/services';
import { goBack } from '@/composables/useNavigation';
import { toast, toastServiceError } from '@/services/core/notification';

import { Permission, FormAction, ScheduleModel, ScheduleFormState } from '@/types';

import { required, maxLength } from '@/utils/validators';

const uiStore = useUiStore();
const route = useRoute();
const { t } = useI18n();

const props = defineProps({
    action: { type: String, default: FormAction.CREATE },
});

const formState = reactive<ScheduleFormState>({
    name: null,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    timeBegin: '08:00:00',
    timeEnd: '17:00:00',
    isActive: true,
});

const rules = computed<ValidationArgs<ScheduleFormState>>(() => ({
    name: { required, maxLength: maxLength(50) },
    monday: { required },
    tuesday: { required },
    wednesday: { required },
    thursday: { required },
    friday: { required },
    saturday: { required },
    sunday: { required },
    timeBegin: { required },
    timeEnd: { required },
    isActive: { required },
}));

const v$: Ref<Validation<ValidationArgs<ScheduleFormState>>> = useVuelidate(rules, formState);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchScheduleById = async (id: number) => {
    try {
        setLoading(true);
        const { data } = await ScheduleService.get<ScheduleModel>(id);
        Object.assign(formState, data);
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const isTimeBefore = (start: string, end: string): boolean => {
    const [h1, m1] = start.split(':').map(Number);
    const [h2, m2] = end.split(':').map(Number);
    return h1 < h2 || (h1 === h2 && m1 < m2);
};

const onSubmit = async () => {
    const isValid = await v$.value.$validate();
    if (!isValid) return;

    const noDaySelected = !(
        formState.monday ||
        formState.tuesday ||
        formState.wednesday ||
        formState.thursday ||
        formState.friday ||
        formState.saturday ||
        formState.sunday
    );

    if (noDaySelected) {
        toastServiceError(t('schedule.error.dayNotSelected'));
        return;
    }

    if (!isTimeBefore(formState.timeBegin, formState.timeEnd)) {
        toastServiceError(t('schedule.error.invalidTimeRange'));
        return;
    }

    try {
        setLoading(true);
        let response: AxiosResponse;

        if (props.action === FormAction.EDIT) {
            const id = Number(route.params.id);
            response = await ScheduleService.update(id, formState);
            toast.success(response.data.message);
            goBack({ name: 'ViewScheduleById', params: { id } });
        } else {
            response = await ScheduleService.create(formState);
            toast.success(response.data.message);
            goBack({ name: 'ViewScheduleById', params: { id: response.data.result.id } });
        }

        v$.value.$reset();
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

onMounted(() => {
    if (props.action === FormAction.EDIT) {
        const scheduleId = Number(route.params.id);
        if (!isNaN(scheduleId)) fetchScheduleById(scheduleId);
    }
});
</script>

<template>
    <form class="space-y-4 p-4 sm:p-6 lg:p-8" autocomplete="off" @submit.prevent="onSubmit">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('schedule.' + props.action + '.title') }}
                </h1>
            </template>
            <template #actions>
                <div class="flex items-center space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ListSchedule' }" />
                    <UiButton
                        v-if="
                            (props.action == FormAction.CREATE &&
                                $can(Permission.CREATE_SCHEDULE)) ||
                            (props.action == FormAction.EDIT && $can(Permission.EDIT_SCHEDULE))
                        "
                        type="submit"
                        variant="primary"
                        text-variant="white"
                    >
                        {{ $t('core.actions.Save') }}
                    </UiButton>
                </div>
            </template>
        </UiPageHeader>
        <UiPanel class="space-y-4">
            <div class="gap-4 md:grid md:grid-cols-8">
                <UiTextInput
                    v-model="v$.name.$model"
                    name="name"
                    type="text"
                    :label="$t('schedule.labels.name')"
                    class="md:col-span-6 lg:col-span-4"
                    :errors="v$.name.$errors"
                    @blur="v$.name.$touch"
                />
            </div>

            <div class="grid grid-cols-2 gap-4 md:grid-cols-8">
                <UiTimePicker
                    v-model="v$.timeBegin.$model"
                    name="timeBegin"
                    :label="$t('schedule.labels.timeBegin')"
                    class="md:col-span-3 lg:col-span-2"
                    :errors="v$.timeBegin.$errors"
                    @blur="v$.timeBegin.$touch"
                />

                <UiTimePicker
                    v-model="v$.timeEnd.$model"
                    name="timeEnd"
                    :label="$t('schedule.labels.timeEnd')"
                    class="md:col-span-3 lg:col-span-2"
                    :errors="v$.timeEnd.$errors"
                    @blur="v$.timeEnd.$touch"
                />
            </div>

            <div class="space-y-2">
                <h3 class="text-sm font-medium text-gray-800">
                    {{ $t('schedule.labels.daysActive') }}
                </h3>
                <div class="grid grid-cols-2 gap-4 md:grid-cols-4 lg:grid-cols-8">
                    <UiCheckboxInput
                        v-model="v$.monday.$model"
                        name="monday"
                        :label="$t('schedule.labels.monday')"
                    />

                    <UiCheckboxInput
                        v-model="v$.tuesday.$model"
                        name="tuesday"
                        :label="$t('schedule.labels.tuesday')"
                    />

                    <UiCheckboxInput
                        v-model="v$.wednesday.$model"
                        name="wednesday"
                        :label="$t('schedule.labels.wednesday')"
                    />

                    <UiCheckboxInput
                        v-model="v$.thursday.$model"
                        name="thursday"
                        :label="$t('schedule.labels.thursday')"
                    />

                    <UiCheckboxInput
                        v-model="v$.friday.$model"
                        name="friday"
                        :label="$t('schedule.labels.friday')"
                    />

                    <UiCheckboxInput
                        v-model="v$.saturday.$model"
                        name="saturday"
                        :label="$t('schedule.labels.saturday')"
                    />

                    <UiCheckboxInput
                        v-model="v$.sunday.$model"
                        name="sunday"
                        :label="$t('schedule.labels.sunday')"
                    />
                </div>
            </div>

            <UiCheckboxInput
                v-model="v$.isActive.$model"
                name="isActive"
                :label="$t('schedule.labels.isActive')"
            />
        </UiPanel>
        <UiCheckDirtyBeforeRouteLeave :dirty="v$.$anyDirty" />
    </form>
</template>
