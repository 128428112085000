<script lang="ts" setup>
import { computed } from 'vue';
import { CallModel, CallTranscriptModel, CallParticipantType, CallBulletType } from '@/types';
import { PlayIcon } from '@heroicons/vue/solid';

const { call, callDuration, bulletType, participantType } = defineProps({
    call: {
        type: Object as () => CallModel,
        required: true,
    },
    callDuration: {
        type: Number,
        required: true,
    },
    bulletType: {
        type: String as () => CallBulletType,
        required: true,
    },
    participantType: {
        type: String as () => CallParticipantType,
        required: true,
    },
});

const emit = defineEmits(['playSnippet']);

const transcripts = computed(
    () =>
        call?.transcripts?.filter(({ participant, registeredWords = [], matchedWords = [] }) => {
            return (
                participant == participantType &&
                ((bulletType === CallBulletType.registered && registeredWords.length) ||
                    (bulletType === CallBulletType.matched && matchedWords.length))
            );
        }) || [],
);

function playSnippet(start: number, end: number) {
    emit('playSnippet', start, end);
}

function getPositionBullet(item: CallTranscriptModel) {
    return (item.startedAt / callDuration) * 100 + '%';
}

function getWordsByType() {
    const wordsSet = new Set();

    transcripts.value.forEach(({ registeredWords, matchedWords }) => {
        if (bulletType === CallBulletType.registered) {
            registeredWords.forEach((word) => wordsSet.add(word));
        } else if (bulletType === CallBulletType.matched) {
            matchedWords.forEach((word) => wordsSet.add(word));
        }
    });

    return Array.from(wordsSet).sort((a, b) => a.localeCompare(b));
}

const variant = bulletType == CallBulletType.registered ? 'blue' : 'orange';
const playColor = `text-${variant}-500`;
const bulletColor = `bg-${variant}-500/50`;
</script>

<template>
    <div v-if="callDuration" class="w-100 relative h-4">
        <div
            v-for="transcript in transcripts"
            :key="`bullet-${bulletType}-${participantType}-${transcript.id}`"
            class="group absolute h-4 w-4 rounded-full p-0 hover:-m-2 hover:h-8 hover:w-8 hover:bg-gray-50 hover:transition-all"
            :class="bulletColor"
            :style="{ left: getPositionBullet(transcript) }"
        >
            <PlayIcon
                class="invisible h-full w-full cursor-pointer group-hover:visible"
                :class="playColor"
                @click="playSnippet(transcript.startedAt, transcript.endedAt)"
            />
            <div
                class="invisible absolute -ml-1 flex flex-col rounded border border-gray-200 bg-gray-50 px-2 group-hover:visible"
            >
                <div
                    v-for="word in getWordsByType()"
                    :key="`bullet-${bulletType}-${participantType}-${transcript.id}-${word}`"
                    class="z-50 whitespace-nowrap text-gray-700"
                >
                    {{ word }}
                </div>
            </div>
        </div>
    </div>
</template>
