export enum UserDeviceStatus {
    OFFLINE = 'OFFLINE',
    CONNECTED = 'CONNECTED',
    DIALING = 'DIALING',
    RING = 'RING',
    RINGING = 'RINGING',
    INUSE = 'INUSE',
    IN_PAUSE = 'IN_PAUSE',
    IN_CALL = 'IN_CALL',
    UNAVAILABLE = 'UNAVAILABLE',
}
