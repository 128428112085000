import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiActionButton = _resolveComponent("UiActionButton")!
  const _directive_tippy = _resolveDirective("tippy")!

  return _withDirectives((_openBlock(), _createBlock(_component_UiActionButton, {
    variant: "primary",
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.downloadCallAudio($props.callId)))
  }, {
    default: _withCtx(() => [
      _createVNode($setup["DownloadIcon"], {
        class: "h-7 w-7",
        "aria-hidden": "true"
      }),
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('core.actions.Download')), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  })), [
    [_directive_tippy, _ctx.$t('core.actions.Download')]
  ])
}