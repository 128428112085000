/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    BillingReportState,
    BillingReportFilter,
    BillingReportMutations,
    BillingReportActions,
    BillingReportGetters,
    FilterItem,
} from '@/types';

const getDefaultState = (): BillingReportState => {
    return {
        filter: {
            dateRange: null,
            user: null,
            userLabel: null,
            sourceNumber: null,
            destinationNumber: null,
            status: null,
            type: null,
        },
    } as BillingReportState;
};

const state: BillingReportState = getDefaultState();

const mutations: MutationTree<BillingReportState> = {
    [BillingReportMutations.SET_FILTER](state, payload: Partial<BillingReportFilter>) {
        state.filter = { ...state.filter, ...payload };
    },
    [BillingReportMutations.CLEAR_FILTER](state, field?: keyof BillingReportFilter) {
        switch (field) {
            case 'dateRange':
                state.filter.dateRange = getDefaultState().filter.dateRange;
                break;
            case 'user':
                state.filter.user = getDefaultState().filter.user;
                break;
            case 'userLabel':
                state.filter.userLabel = getDefaultState().filter.userLabel;
                break;
            case 'sourceNumber':
                state.filter.sourceNumber = getDefaultState().filter.sourceNumber;
                break;
            case 'destinationNumber':
                state.filter.destinationNumber = getDefaultState().filter.destinationNumber;
                break;
            case 'status':
                state.filter.status = getDefaultState().filter.status;
                break;
            case 'type':
                state.filter.type = getDefaultState().filter.type;
                break;
            default:
                state.filter = getDefaultState().filter;
        }
    },
};

const actions: ActionTree<BillingReportState, any> = {
    [BillingReportActions.SET_FILTER]: ({ commit }, filter: Partial<BillingReportFilter>) =>
        commit(BillingReportMutations.SET_FILTER, filter),
    [BillingReportActions.CLEAR_FILTER]: ({ commit }, field?: keyof BillingReportFilter) =>
        commit(BillingReportMutations.CLEAR_FILTER, field),
};

const getters: GetterTree<BillingReportState, any> = {
    [BillingReportGetters.DEFAULT_FILTER]: (): BillingReportFilter => getDefaultState().filter,
    [BillingReportGetters.FILTER]: (state): BillingReportFilter => state.filter,
    [BillingReportGetters.ACTIVE_FILTERS](state): FilterItem[] {
        const filters: FilterItem[] = [];

        if (state.filter.dateRange) {
            filters.push({
                field: 'dateRange',
                value: `${state.filter.dateRange[0].toISOString()} - ${state.filter.dateRange[1].toISOString()}`,
                label: 'Date Range',
            });
        }

        if (state.filter.user) {
            filters.push({
                field: 'user',
                value: state.filter.user.id,
                label: state.filter.user.name,
            });
        }

        if (state.filter.userLabel) {
            filters.push({
                field: 'userLabel',
                value: state.filter.userLabel.id,
                label: state.filter.userLabel.label,
            });
        }

        if (state.filter.sourceNumber) {
            filters.push({
                field: 'sourceNumber',
                value: state.filter.sourceNumber,
                label: state.filter.sourceNumber,
            });
        }

        if (state.filter.destinationNumber) {
            filters.push({
                field: 'destinationNumber',
                value: state.filter.destinationNumber,
                label: state.filter.destinationNumber,
            });
        }

        if (state.filter.status) {
            filters.push({
                field: 'status',
                value: state.filter.status,
                label: state.filter.status,
            });
        }

        if (state.filter.type) {
            filters.push({
                field: 'type',
                value: state.filter.type,
                label: state.filter.type,
            });
        }

        return filters;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
