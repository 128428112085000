import { defineComponent as _defineComponent } from 'vue'
import { computed, ref, watch, onMounted } from 'vue';
import moment from 'moment/moment';

import { useFilterStore, useUiStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import AgentDeviceStatusHistoryReportService from '@/services/api/AgentDeviceStatusHistoryReportService';

import { formatSecToTime } from '@/utils/datetime';
import SVGChromeLogo from '@/components/svg/SVGChromeLogo.vue';
import SVGDeviceExternal from '@/components/svg/SVGDeviceExternal.vue';

import { LevelModel, UserDeviceType, UserDeviceStatus, UserExtensionQueueStatus } from '@/types';

import { AgentDeviceStatusHistoryReportServiceGetInfoParams } from '@/types/services/AgentDeviceStatusHistoryReportService';
import { UserDeviceModel } from '@/types/models/UserDeviceModel';

import { DesktopComputerIcon } from '@heroicons/vue/solid';
import { DeviceMobileIcon } from '@heroicons/vue/outline';


export default /*@__PURE__*/_defineComponent({
  __name: 'AgentDeviceReportPage',
  setup(__props, { expose: __expose }) {
  __expose();

const totalTimePaused = ref(0);
const totalTimeCall = ref(0);
const agentsDevices = ref<UserDeviceModel[]>([]);

const dateRange = computed<Array<Date>>({
    get: () => filterStore.dateRange,
    set: (dateRange: Array<Date>) => filterStore.setDateRange(dateRange),
});

const levelFilter = computed<LevelModel | null>(() => filterStore.level);

const filterStore = useFilterStore();
const uiStore = useUiStore();

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getDeviceIcon = (device: UserDeviceModel) =>
    ({
        [UserDeviceType.MOBILE]: DeviceMobileIcon,
        [UserDeviceType.DESKTOP]: DesktopComputerIcon,
        [UserDeviceType.WEB]: SVGChromeLogo,
        [UserDeviceType.EXTERNAL]: SVGDeviceExternal,
    })[device.deviceType];

const getStatusColor = (status: string) =>
    ({
        [UserDeviceStatus.CONNECTED]: 'text-blue-600',
        [UserDeviceStatus.UNAVAILABLE]: 'text-red-500',
        [UserDeviceStatus.IN_CALL]: 'text-green-600',
    })[status];

const getQueueColorStatus = (status: string) =>
    ({
        [UserExtensionQueueStatus.PAUSED]: 'text-yellow-600',
        [UserExtensionQueueStatus.AVAILABLE]: 'text-green-500',
    })[status];

const tableHeaders = [
    {
        key: 'userName',
        label: 'report.agentDeviceStatusHistory.details.userName',
        align: 'left',
    },
    {
        key: 'extension',
        label: 'report.agentDeviceStatusHistory.details.extension',
        align: 'center',
    },
    {
        key: 'totalTimeOnline',
        label: 'report.agentDeviceStatusHistory.details.totalTimeOnline',
        align: 'center',
    },
    {
        key: 'totalTimePaused',
        label: 'report.agentDeviceStatusHistory.details.totalTimePaused',
        align: 'center',
    },
    {
        key: 'totalTimeInCall',
        label: 'report.agentDeviceStatusHistory.details.totalTimeInCall',
        align: 'center',
    },
    {
        key: 'lastStatus',
        label: 'report.agentDeviceStatusHistory.details.lastStatus',
        align: 'center',
    },
    {
        key: 'queues',
        label: 'report.agentDeviceStatusHistory.details.queues',
        align: 'center',
    },
    {
        key: 'queueStatus',
        label: 'report.agentDeviceStatusHistory.details.queueStatus',
        align: 'center',
    },
];

const getReportData = async () => {
    const params: AgentDeviceStatusHistoryReportServiceGetInfoParams = {
        startDate: moment.utc(dateRange.value[0]).format('YYYY-MM-DD'),
        endDate: moment.utc(dateRange.value[1]).format('YYYY-MM-DD'),
    };

    try {
        setLoading(true);
        const { data } = await AgentDeviceStatusHistoryReportService.getInfo({ params });

        totalTimePaused.value = data.totalTimePaused;
        totalTimeCall.value = data.totalTimeCall;
        agentsDevices.value = data.devices;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

watch([levelFilter, dateRange], getReportData);

onMounted(() => {
    getReportData();
});

const __returned__ = { totalTimePaused, totalTimeCall, agentsDevices, dateRange, levelFilter, filterStore, uiStore, setLoading, getDeviceIcon, getStatusColor, getQueueColorStatus, tableHeaders, getReportData, get moment() { return moment }, get formatSecToTime() { return formatSecToTime } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})