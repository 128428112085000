<script lang="ts" setup>
import { computed } from 'vue';
import moment from 'moment';
import { useI18n } from 'vue-i18n';

import { CallStatus, ChartDataItem, CallsStatsPerDay, CallStatusColorMap } from '@/types';

const { t } = useI18n();

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    data: {
        type: Array<CallsStatsPerDay>,
        required: true,
    },
});

interface ChartData {
    category: Array<string>;
    series: Array<ChartDataItem<number[]>>;
}

const chartData = computed<ChartData>(() => {
    const category: string[] = [];
    const series: ChartDataItem<number[]>[] = [
        {
            value: [],
            name: t('core.options.call.status.answered'),
            color: CallStatusColorMap[CallStatus.ANSWERED],
        },
        {
            value: [],
            name: t('core.options.call.status.no_answer'),
            color: CallStatusColorMap[CallStatus.NO_ANSWER],
        },
        {
            value: [],
            name: t('core.options.call.status.busy'),
            color: CallStatusColorMap[CallStatus.BUSY],
        },
        {
            value: [],
            name: t('core.options.call.status.ditched'),
            color: CallStatusColorMap[CallStatus.DITCHED],
        },
    ];

    for (let i = 0; i < props.data.length; i++) {
        const day = props.data[i];

        category.push(day.date);

        series[0].value.push(day.status[CallStatus.ANSWERED]);
        series[1].value.push(day.status[CallStatus.NO_ANSWER]);
        series[2].value.push(day.status[CallStatus.BUSY]);
        series[3].value.push(day.status[CallStatus.DITCHED]);
    }

    return {
        category,
        series,
    };
});

const xAxisLabelFormatter = (value: number | string) => {
    return moment.utc(value).format('DD/MM');
};
</script>

<template>
    <BarChart
        :title="props.title"
        :categoryData="chartData.category"
        :seriesData="chartData.series"
        :xAxisLabelFormatter="xAxisLabelFormatter"
        column
    />
</template>
