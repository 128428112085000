import { defineComponent as _defineComponent } from 'vue'
import { ErrorObject } from '@vuelidate/core';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiTimePicker',
  props: {
    type: {
        type: String,
        default: 'text',
    },
    modelValue: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: [String, Boolean],
        required: true,
    },
    placeholder: {
        type: String,
        default: '',
    },
    autocomplete: {
        type: String,
        default: 'off',
    },
    required: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    errors: {
        type: Array<ErrorObject>,
        default: [],
    },
    silentErrors: {
        type: Array<ErrorObject>,
        default: [],
    },
},
  emits: ['update:modelValue', 'blur'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const hasErrors = computed(() => props.errors.length || props.silentErrors.length);

const emit = __emit;

function handleHoursInput(value: string) {
    const hours = parseInt(value, 10).toString().padStart(2, '0');
    const minutes = getMinutes(props.modelValue);
    emit('update:modelValue', formatTime(hours, minutes));
}

function handleMinutesInput(value: string) {
    const hours = getHours(props.modelValue);
    const minutes = parseInt(value, 10).toString().padStart(2, '0');
    emit('update:modelValue', formatTime(hours, minutes));
}

function getHours(time: string): string {
    const hours = parseInt(time.slice(0, 2)) || 0;
    return hours < 10 ? `0${hours}` : `${hours}`;
}

function getMinutes(time: string): string {
    const minutes = parseInt(time.slice(3, 5), 10) || 0;
    return minutes.toString().padStart(2, '0');
}

function formatTime(hours: string, minutes: string): string {
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:00`;
}

const __returned__ = { props, hasErrors, emit, handleHoursInput, handleMinutesInput, getHours, getMinutes, formatTime }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})