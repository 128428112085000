<script lang="ts" setup>
import { computed } from 'vue';
import { formatDateAsString } from '@/utils/datetime';
import { CalendarIcon } from '@heroicons/vue/solid';
import { Permission, CreatedAudit, UpdatedAudit } from '@/types';
import { useI18n } from 'vue-i18n';
import { RouterLink } from 'vue-router';

const props = defineProps<{
    modelValue: Partial<CreatedAudit & UpdatedAudit>;
    dateFormat?: string;
}>();

const { t } = useI18n();

const lastModifiedBy = computed(() => {
    return props.modelValue.updatedByUser || props.modelValue.createdByUser || null;
});

const lastModifiedAt = computed(() => {
    return props.modelValue.updatedAt || props.modelValue.createdAt || null;
});

const lastModifiedType = computed(() => {
    return props.modelValue.updatedByUser ? 'updatedAt' : 'createdAt';
});

// fallback to 'LL' if no dateFormat is provided
const resolvedDateFormat = computed(() => props.dateFormat || 'LL');
</script>

<template>
    <div
        v-if="lastModifiedBy && lastModifiedAt"
        class="align-right flex items-center text-sm text-gray-500"
    >
        <CalendarIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
        <p>
            {{ t(`core.${lastModifiedType}`) }}
            <time :datetime="lastModifiedAt">
                {{ formatDateAsString(lastModifiedAt, resolvedDateFormat) }}
            </time>
            {{ t('core.by') }}
            <component
                :is="$can(Permission.VIEW_USER) ? RouterLink : 'span'"
                :to="{ name: 'ViewUserById', params: { id: lastModifiedBy.id } }"
                class="text-primary truncate text-sm font-medium"
            >
                {{ lastModifiedBy.name }}
            </component>
        </p>
    </div>
</template>
