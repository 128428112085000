<script lang="ts" setup>
import { ConnectFlowSurveyModel } from '@/types';

import { AnnotationIcon } from '@heroicons/vue/outline';

const { survey } = defineProps({
    survey: {
        type: Object as () => ConnectFlowSurveyModel,
        required: true,
    },
});
</script>

<template>
    <UiPanel>
        <h3 class="mb-4 flex text-lg font-medium text-blue-900">
            <AnnotationIcon class="mr-1 h-6 w-6" />
            {{ survey?.name }}
        </h3>

        <ul>
            <li v-for="question in survey.questions" :key="question.id" class="text-sm">
                <span class="font-semibold">
                    {{ question.label }} ({{ question.rangeAnswer }}):
                </span>
                {{ question.answer?.value }}
            </li>
        </ul>
    </UiPanel>
</template>
