/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    CallPlayerGetters,
    CallPlayerMutations,
    CallPlayerState,
    CallParticipantType,
} from '@/types';

const state: CallPlayerState = {
    searchQuery: '',
    selectedWords: new Map<CallParticipantType, Set<string>>(),
};

const mutations: MutationTree<CallPlayerState> = {
    [CallPlayerMutations.SET_SEARCH_QUERY]: (state, query: string) => (state.searchQuery = query),
    [CallPlayerMutations.TOGGLE_SELECTED_WORD](state, { participant, word }) {
        if (!state.selectedWords.has(participant)) {
            state.selectedWords.set(participant, new Set());
        }

        const selectedSet = state.selectedWords.get(participant);

        if (selectedSet) {
            if (selectedSet.has(word)) {
                selectedSet.delete(word);
            } else {
                selectedSet.add(word);
            }
        }
    },
};

const actions: ActionTree<CallPlayerState, any> = {};

const getters: GetterTree<CallPlayerState, any> = {
    [CallPlayerGetters.SEARCH_QUERY]: (state): string => state.searchQuery,
    [CallPlayerGetters.SELECTED_WORDS]: (state): Map<CallParticipantType, Set<string>> =>
        state.selectedWords,
};

export default {
    state,
    mutations,
    actions,
    getters,
};
