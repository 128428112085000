import { defineComponent as _defineComponent } from 'vue'
import { computed, Ref, ref, watch, onMounted } from 'vue';
import store from '@/store';
import { useI18n } from 'vue-i18n';

import { FilterIcon } from '@heroicons/vue/solid';
import {
    BillingReportFilter,
    BillingReportGetters,
    BillingReportActions,
    UserModel,
    CallType,
    CallStatus,
} from '@/types';
import { UserService } from '@/services';
import { toastServiceError } from '@/services/core/notification';


export default /*@__PURE__*/_defineComponent({
  __name: 'BillingReportFilters',
  props: {
    sources: { type: Array, required: true },
    destinations: { type: Array, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const filterOpened = ref(false);

const defaultFilter = computed(() => store.getters[BillingReportGetters.DEFAULT_FILTER]);

const props = __props;

const filter: Ref<BillingReportFilter> = ref({
    user: defaultFilter.value.user,
    sourceNumber: defaultFilter.value.sourceNumber,
    destinationNumber: defaultFilter.value.destinationNumber,
    status: defaultFilter.value.status,
    type: defaultFilter.value.type,
});
const storeFilter = computed(() => store.getters[BillingReportGetters.FILTER]);

const userOptions: Ref<UserModel[]> = ref([]);
const getUsers = async () => {
    try {
        const response = await UserService.getAll<UserModel[]>({
            params: {
                isAgent: true,
                page: 1,
                per_page: 100,
            },
        });
        userOptions.value = response.data;
    } catch (error) {
        toastServiceError(error);
    }
};

function applyFilters() {
    store.dispatch(BillingReportActions.SET_FILTER, filter.value);
}

function clearFilters() {
    store.dispatch(BillingReportActions.CLEAR_FILTER);
    refreshLocalFilter();
}

function refreshLocalFilter() {
    filter.value.user = storeFilter.value.user;
    filter.value.sourceNumber = storeFilter.value.sourceNumber;
    filter.value.destinationNumber = storeFilter.value.destinationNumber;
    filter.value.status = storeFilter.value.status;
    filter.value.type = storeFilter.value.type;
}

watch(filterOpened, () => {
    refreshLocalFilter();
});

onMounted(() => {
    getUsers();
});

const __returned__ = { t, filterOpened, defaultFilter, props, filter, storeFilter, userOptions, getUsers, applyFilters, clearFilters, refreshLocalFilter, get FilterIcon() { return FilterIcon }, get CallType() { return CallType }, get CallStatus() { return CallStatus } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})