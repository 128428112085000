/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    ContactState,
    ContactMutations,
    ContactActions,
    ContactGetters,
    SortMenuItem,
} from '@/types';

const getDefaultState = () => {
    return {
        search: null,
        sort: [],
        page: 1,
    } as ContactState;
};

const state: ContactState = getDefaultState();

const mutations: MutationTree<ContactState> = {
    [ContactMutations.SET_SEARCH]: (state, search: string) => (state.search = search),
    [ContactMutations.SET_SORT]: (state, sort: SortMenuItem[]) => (state.sort = sort),
    [ContactMutations.SET_PAGE]: (state, page: number) => (state.page = page),
};

const actions: ActionTree<ContactState, any> = {
    [ContactActions.SET_SEARCH]: ({ commit }, search: string) =>
        commit(ContactMutations.SET_SEARCH, search),
    [ContactActions.SET_SORT]: ({ commit }, sort: SortMenuItem[]) =>
        commit(ContactMutations.SET_SORT, sort),
    [ContactActions.SET_PAGE]: ({ commit }, page: number) =>
        commit(ContactMutations.SET_PAGE, page),
};

const getters: GetterTree<ContactState, any> = {
    [ContactGetters.SEARCH]: (state): string | null => state.search,
    [ContactGetters.SORT]: (state): SortMenuItem[] => state.sort,
    [ContactGetters.PAGE]: (state): number => state.page,
};

export default {
    state,
    mutations,
    actions,
    getters,
};
