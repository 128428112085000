/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    FilterItem,
    AgentProductivityPerDayReportState,
    AgentProductivityPerDayReportMutations,
    AgentProductivityPerDayReportActions,
    AgentProductivityPerDayReportGetters,
    AgentProductivityPerDayReportFilter,
} from '@/types';

const getDefaultState = () => {
    return {
        filter: {
            user: null,
            userLabel: null,
        },
    } as AgentProductivityPerDayReportState;
};

const state: AgentProductivityPerDayReportState = getDefaultState();

const mutations: MutationTree<AgentProductivityPerDayReportState> = {
    [AgentProductivityPerDayReportMutations.SET_FILTER](
        state,
        payload: AgentProductivityPerDayReportFilter,
    ) {
        state.filter.user = payload.user;
        state.filter.userLabel = payload.userLabel;
    },
    [AgentProductivityPerDayReportMutations.CLEAR_FILTER](state, field?: string) {
        switch (field) {
            case 'user':
                state.filter.user = getDefaultState().filter.user;
                break;
            case 'userLabel':
                state.filter.userLabel = getDefaultState().filter.userLabel;
                break;
            default:
                state.filter.user = getDefaultState().filter.user;
                state.filter.userLabel = getDefaultState().filter.userLabel;
        }
    },
};

const actions: ActionTree<AgentProductivityPerDayReportState, any> = {
    [AgentProductivityPerDayReportActions.SET_FILTER]: (
        { commit },
        filter: AgentProductivityPerDayReportFilter,
    ) => commit(AgentProductivityPerDayReportMutations.SET_FILTER, filter),
    [AgentProductivityPerDayReportActions.CLEAR_FILTER]: (
        { commit },
        filter: AgentProductivityPerDayReportFilter,
    ) => commit(AgentProductivityPerDayReportMutations.CLEAR_FILTER, filter),
};

const getters: GetterTree<AgentProductivityPerDayReportState, any> = {
    [AgentProductivityPerDayReportGetters.DEFAULT_FILTER]:
        (): AgentProductivityPerDayReportFilter => getDefaultState().filter,
    [AgentProductivityPerDayReportGetters.FILTER]: (state): AgentProductivityPerDayReportFilter =>
        state.filter,
    [AgentProductivityPerDayReportGetters.ACTIVE_FILTERS](state): FilterItem[] {
        const filters: Array<FilterItem> = [];

        if (state.filter.user)
            filters.push({
                field: 'user',
                value: state.filter.user.id,
                label: state.filter.user.name,
            });

        if (state.filter.userLabel)
            filters.push({
                field: 'userLabel',
                value: state.filter.userLabel.id,
                label: state.filter.userLabel.label,
            });

        return filters;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
