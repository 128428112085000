import { CallType, CallStatus, UserAgentCallsStats, CallsStats } from '@/types';

export interface ContactCenterReportServiceGetInfoParams {
    startDate: string;
    endDate: string;
    type: CallType | null;
    status: CallStatus | null;
    user: number | null;
    number: string | null;
    serviceTimeRange: string | null;
    userLabel: number | null;
}

export interface ContactCenterReportServiceGetInfoResult {
    stats: CallsStats;
    userAgentCallsStats: UserAgentCallsStats[];
}
