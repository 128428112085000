<script lang="ts" setup>
import { computed, PropType } from 'vue';
import router from '@/router';

import moment from 'moment';
import {
    FolderIcon,
    CheckCircleIcon,
    XCircleIcon,
    PencilAltIcon,
    LockClosedIcon,
    ClockIcon,
} from '@heroicons/vue/outline';
import { UserModel, Permission } from '@/types';

const { modelValue } = defineProps({
    modelValue: {
        type: Object as PropType<UserModel>,
        required: true,
    },
});

const emit = defineEmits(['activate', 'inactivate']);

const levels = computed(() => modelValue.levels || []);
const limitedLevels = computed(() => levels.value.slice(0, 3));
const roles = computed(() => modelValue?.roles || []);

const handleActivateClick = () => emit('activate', modelValue);
const handleInactivateClick = () => emit('inactivate', modelValue);

const trimText = (text: string, limit: number) => {
    if (text.length <= limit) {
        return text;
    } else {
        return text.slice(0, limit - 3) + '...';
    }
};

const checkDevices = (device: Record<string, boolean>) => {
    return Object.values(device).every((value) => value === false);
};

const handleEditClick = () => {
    router.push({
        name: 'EditUserById',
        params: {
            id: modelValue.id,
        },
    });
};
</script>

<template>
    <UiPanel class="flex flex-col hover:bg-white">
        <div class="block items-center space-y-2 md:flex">
            <div class="flex flex-1 items-center">
                <UiGravatar
                    :email="modelValue.email"
                    :name="modelValue.name"
                    class="mr-2 h-12 w-12 rounded-full ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50"
                />
                <div>
                    <span class="flex items-center space-x-2 font-semibold text-gray-800">
                        <component
                            :is="$can(Permission.VIEW_USER) ? 'RouterLink' : 'span'"
                            :to="{
                                name: 'ViewUserById',
                                params: {
                                    id: modelValue?.id,
                                },
                            }"
                            class="text-primary"
                        >
                            <span>
                                {{ trimText(modelValue.name, 25) }}
                            </span>
                        </component>
                        <UiIsActiveBadge :model-value="modelValue" class="ml-2 text-xs" />
                    </span>
                    <span class="text-sm text-gray-700">{{ modelValue.email }}</span>
                </div>
            </div>
            <div class="flex flex-1 items-center">
                <LockClosedIcon
                    class="mr-1 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                />
                <div class="flex flex-wrap">
                    <p
                        v-for="(role, index) in roles"
                        :key="`role${role.id}`"
                        :class="[
                            index != roles.length - 1 ? 'mr-0.5' : '',
                            'text-xs font-medium text-gray-500',
                        ]"
                    >
                        {{ role.name }}{{ index != roles.length - 1 ? ',' : '' }}
                    </p>
                </div>
            </div>
            <div class="flex flex-1 items-center" v-if="$can(Permission.VIEW_LEVELS)">
                <FolderIcon class="mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <div class="flex flex-wrap pr-3 md:pr-0">
                    <component
                        v-for="(level, index) in levels"
                        :key="level.id"
                        :is="$can(Permission.VIEW_LEVEL) ? 'RouterLink' : 'span'"
                        :to="{
                            name: 'ViewLevelById',
                            params: {
                                id: level.id,
                            },
                        }"
                        :class="[
                            index != levels.length - 1 ? 'mr-0.5' : '',
                            'text-primary hidden text-xs font-medium md:block',
                        ]"
                    >
                        {{ level.name }}{{ index != levels.length - 1 ? ',' : '' }}
                    </component>
                    <component
                        v-for="(level, index) in limitedLevels"
                        :key="level.id"
                        :is="$can(Permission.VIEW_LEVEL) ? 'RouterLink' : 'span'"
                        :to="{
                            name: 'ViewLevelById',
                            params: {
                                id: level.id,
                            },
                        }"
                        :class="[
                            index != limitedLevels.length - 1 ? 'mr-0.5' : '',
                            'text-primary text-xs font-medium md:hidden',
                        ]"
                    >
                        {{ level.name }}{{ index != limitedLevels.length - 1 ? ',' : '' }}
                    </component>
                    <p class="text-xs text-gray-600 md:hidden" v-if="limitedLevels.length > 2">
                        ...{{ $t('core.actions.Edit') }} {{ levels.length - 3 }}
                    </p>
                </div>
            </div>
            <div class="flex justify-end space-x-1" v-if="$can(Permission.EDIT_USER)">
                <UiActionButton
                    v-if="modelValue?.isActive"
                    v-tippy="$t('core.actions.Inactivate')"
                    variant="danger"
                    @click="handleInactivateClick"
                >
                    <span class="sr-only"> {{ $t('core.actions.Inactivate') }} </span>
                    <XCircleIcon class="h-6 w-6" aria-hidden="true" />
                </UiActionButton>
                <UiActionButton
                    v-else
                    v-tippy="$t('core.actions.Activate')"
                    variant="success"
                    @click="handleActivateClick"
                >
                    <span class="sr-only"> {{ $t('core.actions.Activate') }} </span>
                    <CheckCircleIcon class="h-6 w-6" aria-hidden="true" />
                </UiActionButton>
                <UiActionButton
                    v-tippy="$t('core.actions.Edit')"
                    variant="primary"
                    @click="handleEditClick"
                >
                    <span class="sr-only"> {{ $t('core.actions.Edit') }} </span>
                    <PencilAltIcon class="h-6 w-6" aria-hidden="true" />
                </UiActionButton>
            </div>
        </div>
        <div>
            <div
                class="col-span-9 mt-3 grid grid-cols-1 gap-3 border-t pt-3 lg:grid-cols-4 lg:gap-0"
            >
                <div class="flex flex-wrap items-center space-x-0.5 text-xs text-gray-500">
                    <span>{{ $t('core.entity.Devices') }}:</span>
                    <span
                        v-if="modelValue.devices.mobile"
                        class="rounded border bg-white px-1.5 py-0.5 text-xs text-gray-500"
                    >
                        {{ $t('user.labels.devices.mobile') }}
                    </span>
                    <span
                        v-if="modelValue.devices.desktop"
                        class="rounded border bg-white px-1.5 py-0.5 text-xs text-gray-500"
                    >
                        {{ $t('user.labels.devices.desktop') }}
                    </span>
                    <span
                        v-if="modelValue.devices.web"
                        class="rounded border bg-white px-1.5 py-0.5 text-xs text-gray-500"
                    >
                        {{ $t('user.labels.devices.web') }}
                    </span>
                    <span
                        v-if="modelValue.devices.external"
                        class="rounded border bg-white px-1.5 py-0.5 text-xs text-gray-500"
                    >
                        {{ $t('user.labels.devices.external') }}
                    </span>
                    <span v-if="checkDevices(modelValue.devices)">
                        {{ $t('user.labels.devices.null') }}
                    </span>
                </div>
                <div class="flex flex-wrap items-center space-x-0.5 text-xs text-gray-500">
                    <span>{{ $t('number.labels.extension') }}:</span>
                    <span
                        v-if="modelValue.extension?.number"
                        class="rounded border bg-white px-1.5 py-0.5 text-xs text-gray-500"
                    >
                        {{ modelValue.extension?.number }}
                    </span>
                    <span v-else>-</span>
                </div>
                <div class="flex items-center justify-end text-xs text-gray-500">
                    <ClockIcon
                        class="mr-0.5 h-4 w-4 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                    />
                    <span class="flex flex-wrap">
                        {{ $t('user.labels.lastLoginDevice') }}:
                        {{
                            modelValue.lastDeviceLogin
                                ? moment(modelValue.lastDeviceLogin).format('DD/MM/YYYY HH:mm:ss')
                                : '-'
                        }}
                    </span>
                </div>
                <div class="flex items-center justify-end text-xs text-gray-500">
                    <ClockIcon
                        class="mr-0.5 h-4 w-4 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                    />
                    <span class="flex flex-wrap">
                        {{ $t('user.labels.lastLoginPortal') }}:
                        {{
                            modelValue.lastLogin
                                ? moment(modelValue.lastLogin).format('DD/MM/YYYY HH:mm:ss')
                                : '-'
                        }}
                    </span>
                </div>
            </div>
        </div>
    </UiPanel>
</template>
