import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue';
import { IsActiveFlag } from '@/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiIsActiveBadge',
  props: {
    modelValue: { type: [Object, Boolean], required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();



const shouldRender = computed(() => {
    return (
        typeof __props.modelValue === 'boolean' ||
        (typeof __props.modelValue === 'object' && 'isActive' in __props.modelValue)
    );
});

const isActive = computed(() => {
    if (typeof __props.modelValue === 'boolean') return __props.modelValue;
    if (typeof __props.modelValue === 'object') return __props.modelValue.isActive;
    return false;
});

const __returned__ = { shouldRender, isActive }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})