import { ConnectFlowSurveyQuestionModel, FullAudit, IsActiveFlag } from '@/types';

export interface ConnectFlowSurveyModel extends IsActiveFlag, FullAudit {
    id: number;
    levelId: number;
    name: string | null;
    audioFilePath: string | null;
    textToAudio: string | null;
    questions: ConnectFlowSurveyQuestionModel[];
}
