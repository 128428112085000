import { CallDataModel, CallModel, CallStatus, CallType, UserModel } from '@/types';

export interface CallEventModel {
    id: number;
    callID: number;
    at: Date;
    type: CallType;
    status: CallStatus;
    audioFilePath?: string;
    sourceNumber: string;
    sourceUserId?: string;
    sourceExtensionNumber?: string;
    sourceUser?: UserModel;
    destinationNumber: string;
    destinationUserId?: string;
    destinationExtensionNumber?: string;
    destinationUser?: UserModel;
    externalId?: string;
    duration: number;
    geolocation?: string;
    ivrOption?: string;
    additionalInfo?: CallDataModel;
    peerIp?: string;
    rtpQosAll?: string;
    createdAt?: string;
    updatedAt?: string;
    call?: CallModel;
}
