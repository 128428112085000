import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import { colord } from 'colord';

import { CallType, ChartDataItem, CallsStatsPerDay } from '@/types';

interface ChartData {
    category: Array<string>;
    series: Array<ChartDataItem<number[]>>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CallTypePerDayColumnChart',
  props: {
    title: {
        type: String,
        required: true,
    },
    data: {
        type: Array<CallsStatsPerDay>,
        required: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const props = __props;

const baseColor = colord('#0FA5E8');

const chartData = computed<ChartData>(() => {
    const category: string[] = [];
    const series: ChartDataItem<number[]>[] = [
        {
            value: [],
            name: t('core.options.call.type.inbound'),
            color: baseColor.toHex(),
        },
        {
            value: [],
            name: t('core.options.call.type.outbound'),
            color: baseColor.lighten(0.2).toHex(),
        },
        {
            value: [],
            name: t('core.options.call.type.internal'),
            color: baseColor.lighten(0.4).toHex(),
        },
    ];

    for (let i = 0; i < props.data.length; i++) {
        const day = props.data[i];

        category.push(day.date);

        series[0].value.push(day.type[CallType.INBOUND]);
        series[1].value.push(day.type[CallType.OUTBOUND]);
        series[2].value.push(day.type[CallType.INTERNAL]);
    }

    return {
        category,
        series,
    };
});

const xAxisLabelFormatter = (value: number | string) => {
    return moment.utc(value).format('DD/MM');
};

const __returned__ = { t, props, baseColor, chartData, xAxisLabelFormatter }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})