<script lang="ts" setup>
import { onMounted, ref, Ref, computed, WritableComputedRef, PropType, ComputedRef } from 'vue';
import {
    ConnectFlowModelingActions,
    ConnectFlowModelingGetters,
    ConnectFlowModelingMutations,
    VueFlowNodeModel,
    MultiSelectOption,
    UserExtensionModel,
} from '@/types';
import store from '@/store';
import { ReplyIcon } from '@heroicons/vue/outline';
import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
    node: {
        type: Object as PropType<VueFlowNodeModel>,
        required: true,
    },
});

const showForm = ref(false);

const nodeWritable: WritableComputedRef<VueFlowNodeModel> = computed({
    get: () => store.getters['flow/' + ConnectFlowModelingGetters.ELEMENT_BY_ID](props.node.id),
    set: (value: VueFlowNodeModel) => {
        store.commit('flow/' + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
            idx: null,
            value,
        });
    },
});

const extensionsOptions: ComputedRef<UserExtensionModel[]> = computed(
    () => store.getters['flow/' + ConnectFlowModelingGetters.EXTENSION_OPTIONS],
);
interface IExtensionFormState {
    extension: null | number;
    userInput: null | boolean;
    whisper: null | string;
}

const formState: Ref<IExtensionFormState> = ref({
    extension: null,
    userInput: null,
    whisper: null,
});
const textValidation = t('connectFlow.node.transferToExtension.validation');
const atLeastOneFilled = helpers.withMessage(textValidation, (value, state) => {
    return !!state.extension || !!state.userInput;
});
const rules = computed<ValidationArgs<IExtensionFormState>>(() => {
    const r: ValidationArgs<IExtensionFormState> = {
        extension: {
            atLeastOneFilled,
        },
        userInput: {
            atLeastOneFilled,
        },
        whisper: {},
    };
    return r;
});
const v$: Ref<Validation<ValidationArgs<IExtensionFormState>>> = useVuelidate(rules, formState);

const nodeText = computed(() => {
    let text = t('connectFlow.node.transferToExtension.empty');
    if (nodeWritable.value.data.related) {
        text = t('connectFlow.node.transferToExtension.extension', {
            extension: nodeWritable.value.data.related?.value,
        });
    } else if (nodeWritable.value.data.userInput) {
        text = t('connectFlow.node.transferToExtension.userInput');
    }
    return text;
});

const isFormCorrect = ref(true);

function removeNode() {
    store.dispatch('flow/' + ConnectFlowModelingActions.DELETE_NODE, {
        nodeId: nodeWritable.value.id,
        parentId: nodeWritable.value.parentId,
    });
}
async function onSubmit() {
    isFormCorrect.value = await v$.value.$validate();
    if (isFormCorrect.value) {
        nodeWritable.value.data.related = formState.value.userInput
            ? null
            : ({
                  value: formState.value.extension,
                  label: formState.value.extension?.toString(),
              } as MultiSelectOption);
        nodeWritable.value.data.userInput = formState.value.userInput ?? undefined;
        nodeWritable.value.data.whisper = formState.value.whisper ?? undefined;

        showForm.value = false;
        if (nodeWritable.value.data.related?.label !== undefined) {
            formState.value.extension = parseInt(nodeWritable.value.data.related?.label);
        }
        formState.value.userInput = nodeWritable.value.data.userInput ?? null;
    }
    nodeWritable.value.data.hasErrors = !isFormCorrect.value;
    v$.value.$reset();
}

onMounted(() => {
    if (nodeWritable.value.data.related?.label !== undefined) {
        formState.value.extension = parseInt(nodeWritable.value.data.related?.label);
    }
    if (nodeWritable.value.data.userInput) {
        formState.value.userInput = nodeWritable.value.data.userInput;
    }

    if (nodeWritable.value.data.whisper !== undefined) {
        formState.value.whisper = nodeWritable.value.data.whisper;
    }

    v$.value.$validate().then((result) => {
        isFormCorrect.value = result;
        nodeWritable.value.data.hasErrors = !isFormCorrect.value;
    });
});
</script>

<template>
    <UiNode
        :nodeId="node.id"
        :title="$t('connectFlow.node.transferToExtension.title')"
        :icon="ReplyIcon"
        showActions
        @editNode="showForm = true"
        @removeNode="removeNode"
        :class="isFormCorrect ? '' : 'border-red-300 shadow-red-300'"
    >
        <div class="font-medium text-gray-600">
            <span>
                {{ nodeText }}
            </span>
        </div>
    </UiNode>
    <UiRightPanel
        v-model="showForm"
        :title="$t('connectFlow.node.transferToExtension.title')"
        :icon="ReplyIcon"
    >
        <form autocomplete="off" class="flex flex-col gap-3" @submit.prevent="onSubmit">
            <div class="sm:col-span-12">
                <label class="mb-2 block text-sm font-medium text-gray-800">
                    {{ $t('connectFlow.node.transferToExtension.select') }}
                </label>
                <UiMultiselect
                    name="extension"
                    v-model="v$.extension.$model"
                    :options="extensionsOptions"
                    label="number"
                    value-prop="number"
                    :errors="v$.extension.$silentErrors"
                    :disabled="formState.userInput ?? false"
                    primitive
                    can-clear
                >
                    <template #option-label="{ option }">
                        {{ option.number }} - {{ option.user.name }}
                    </template>
                </UiMultiselect>
            </div>
            <div class="mt-6 flex flex-col justify-end">
                <UiCheckboxInput
                    v-model="v$.userInput.$model"
                    name="isActive"
                    :label="$t('connectFlow.node.transferToExtension.userInput')"
                    class="h-6"
                    :errors="v$.userInput.$errors"
                />
                <div
                    class="text-danger bg-danger-50 z-10 w-full px-2 py-2 text-sm shadow"
                    v-for="error of v$.userInput.$errors"
                    :key="error.$uid"
                >
                    <p>
                        {{ error.$response ? error.$response : error.$message }}
                    </p>
                </div>
            </div>
            <div class="mt-4 flex flex-col justify-end pb-1.5">
                <UiTextArea
                    :label="$t('connectFlow.node.transferToExtension.whisper')"
                    name="whisper"
                    v-model="v$.whisper.$model"
                />
            </div>
            <div class="mt-6 grid grid-cols-2 place-content-around gap-4 text-center">
                <UiButton variant="white" text-variant="gray-700" @click="showForm = false">
                    {{ $t('core.actions.Cancel') }}
                </UiButton>
                <UiButton type="submit" variant="primary" text-variant="white">
                    {{ $t('core.actions.Apply') }}
                </UiButton>
            </div>
        </form>
    </UiRightPanel>
</template>
