import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, watch, ComputedRef, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import moment from 'moment';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import MeetingService from '@/services/api/MeetingService';
import { toastServiceError } from '@/services/core/notification';

import {
    FilterItem,
    LevelModel,
    MeetingModel,
    SortMenuItem,
    MeetingActions,
    MeetingGetters,
} from '@/types';

import {
    MeetingListItem,
    MeetingListSort,
    MeetingListFilters,
} from '@/views/pages/meeting/components';

// Stores & Routes
const perPage = 20;

// State

export default /*@__PURE__*/_defineComponent({
  __name: 'MeetingPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();
const uiStore = useUiStore();
const filterStore = useFilterStore();

// Constants
const meetings = ref<MeetingModel[]>([]);
const count = ref(0);

// Computed
const currentPage = computed<number>({
    get: () => store.getters[MeetingGetters.PAGE],
    set: (page: number) => store.dispatch(MeetingActions.SET_PAGE, page),
});

const search = computed<string>({
    get: () => store.getters[MeetingGetters.SEARCH],
    set: (search: string) => store.dispatch(MeetingActions.SET_SEARCH, search),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[MeetingGetters.SORT],
    set: (sort: SortMenuItem[]) => store.dispatch(MeetingActions.SET_SORT, sort),
});

const orderBy = computed<string>(() =>
    sortMenuSelected.value.map((s) => s.direction + s.key).join(','),
);

const levelFilter = computed<LevelModel | null>(() => filterStore.level);

const dateRange = computed<Array<Date>>({
    get: () => filterStore.dateRange,
    set: (dateRange: Array<Date>) => filterStore.setDateRange(dateRange),
});

const platform = computed<string | null>(() => {
    const platformFilter = store.getters[MeetingGetters.FILTER].platform;
    return platformFilter?.value?.toString() ?? null;
});

const activeFilters: ComputedRef<FilterItem[]> = computed(
    () => store.getters[MeetingGetters.ACTIVE_FILTERS],
);

// Methods
const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const handleRemoveSortClick = (item: SortMenuItem) => {
    sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);
};

const handleRemoveFilter = (field: string) => {
    store.dispatch(MeetingActions.CLEAR_FILTER, field);
};

const prepareMeetingParams = () => {
    return {
        search: search.value,
        startDate: moment.utc(dateRange.value[0]).format('YYYY-MM-DD'),
        endDate: moment.utc(dateRange.value[1]).format('YYYY-MM-DD'),
        platform: platform.value,
        page: currentPage.value,
        per_page: perPage,
        order_by: orderBy.value,
    };
};

const getMeetings = async () => {
    const params = prepareMeetingParams();
    if (!params) return;

    try {
        await setLoading(true);
        const response = await MeetingService.getAll({ params });
        meetings.value = response.data;
        count.value = parseInt(response.headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

// Watchers
watch(
    [search, levelFilter, dateRange, orderBy, currentPage, activeFilters],
    () => {
        getMeetings();
    },
    { immediate: true },
);

// Lifecycle
onMounted(() => {
    if (route.query.clearStateOnSetup) {
        currentPage.value = 1;
        router.replace({ query: {} });
    }

    getMeetings();
});

const __returned__ = { route, router, uiStore, filterStore, perPage, meetings, count, currentPage, search, sortMenuSelected, orderBy, levelFilter, dateRange, platform, activeFilters, setLoading, handleRemoveSortClick, handleRemoveFilter, prepareMeetingParams, getMeetings, get MeetingListItem() { return MeetingListItem }, get MeetingListSort() { return MeetingListSort }, get MeetingListFilters() { return MeetingListFilters } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})