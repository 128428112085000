import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiSortMenu = _resolveComponent("UiSortMenu")!

  return (_openBlock(), _createBlock(_component_UiSortMenu, {
    items: $setup.sortMenuItems,
    "model-value": $setup.props.modelValue,
    "onUpdate:modelValue": $setup.updateValue
  }, null, 8 /* PROPS */, ["items", "model-value"]))
}