import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import { CallService, SelectOptionsService } from '@/services';

import { FilterIcon } from '@heroicons/vue/solid';

import {
    CallActions,
    CallGetters,
    CallStatus,
    CallType,
    CallFilter,
    LevelModel,
    MultiSelectOption,
    SelectOptionsServiceGetWordsParams,
    CallParticipantType,
    SelectOptionsServiceGetUsersResult,
    SelectOptionsServiceGetUserLabelsResult,
    SelectOptionsServiceGetWordsResult,
} from '@/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'CallListFilters',
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();
const route = useRoute();
const filterStore = useFilterStore();
const uiStore = useUiStore();

const filtersOpened = ref(false);
const selectedParticipant = ref<CallParticipantType | null>(null);
const filter: Ref<CallFilter> = ref({
    type: null,
    status: null,
    userLabel: null,
    media: null,
    numberClient: null,
    user: null,
    word: null,
});

const words: Ref<SelectOptionsServiceGetWordsResult[]> = ref([]);
const userLabelOptions: Ref<SelectOptionsServiceGetUserLabelsResult[]> = ref([]);
const agentOptions: Ref<SelectOptionsServiceGetUsersResult[]> = ref([]);
const mediaFilterOptions: Ref<MultiSelectOption[]> = ref([]);

const levelFilter = computed<LevelModel | null>(() => filterStore.level);
const storeFilters = computed<CallFilter>(() => store.getters[CallGetters.FILTER]);

const isCallTypeRoute = computed(() => {
    return Object.values(CallType)
        .map((value) => value.toLowerCase())
        .some((callTypeValue) => route.path.toLowerCase().includes(callTypeValue));
});

const isCallStatusRoute = computed(() => {
    return Object.values(CallStatus)
        .map((value) => value.toLowerCase())
        .some((callStatusValue) => route.path.toLowerCase().includes(callStatusValue));
});

const statusFilterOptions = [
    { label: t(`call.status.${CallStatus.ANSWERED}`), value: CallStatus.ANSWERED },
    { label: t(`call.status.${CallStatus.BUSY}`), value: CallStatus.BUSY },
    { label: t(`call.status.${CallStatus.NO_ANSWER}`), value: CallStatus.NO_ANSWER },
    { label: t(`call.status.${CallStatus.DITCHED}`), value: CallStatus.DITCHED },
];

const typeFilterOptions = [
    { label: t(`call.type.${CallType.INBOUND}`), value: CallType.INBOUND },
    { label: t(`call.type.${CallType.OUTBOUND}`), value: CallType.OUTBOUND },
    { label: t(`call.type.${CallType.INTERNAL}`), value: CallType.INTERNAL },
];

const callParticipantOptions = [
    { label: t('word.participant.ALL'), value: CallParticipantType.All },
    { label: t('word.participant.AGENT'), value: CallParticipantType.Agent },
    { label: t('word.participant.CLIENT'), value: CallParticipantType.Client },
];

watch(filtersOpened, () => {
    refreshLocalFilter();
});

watch([levelFilter], () => {
    store.dispatch(CallActions.CLEAR_FILTER);
    getUserLabels();
    getWords;
});

watch(
    () => filter.value.word?.callParticipant,
    (newParticipant) => {
        if (newParticipant !== undefined && newParticipant !== null) {
            getWords(newParticipant);
        }
    },
    { immediate: true },
);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const refreshLocalFilter = () => {
    filter.value.type = storeFilters.value.type;
    filter.value.status = storeFilters.value.status;
    filter.value.userLabel = storeFilters.value.userLabel;
    filter.value.media = storeFilters.value.media;
    filter.value.numberClient = storeFilters.value.numberClient;
    filter.value.user = storeFilters.value.user;
    filter.value.word = storeFilters.value.word;
};

const applyFilters = () => {
    store.dispatch(CallActions.SET_FILTER, filter.value);
};

const clearFilters = () => {
    store.dispatch(CallActions.CLEAR_FILTER);
    refreshLocalFilter();
};

const getWords = async (participant: string) => {
    const params: SelectOptionsServiceGetWordsParams = {
        call_participant: participant,
    };

    try {
        await setLoading(true);
        const response = await SelectOptionsService.getWords({ params });
        words.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const getUserLabels = async () => {
    try {
        const response = await SelectOptionsService.getUserLabels();
        userLabelOptions.value = response.data;
    } catch (error) {
        toastServiceError(error);
    }
};

const getAgents = async () => {
    try {
        const response = await SelectOptionsService.getUsers();
        agentOptions.value = response.data;
    } catch (error) {
        toastServiceError(error);
    }
};

const getMedias = async () => {
    try {
        const response = await CallService.getMedias<MultiSelectOption[]>();
        mediaFilterOptions.value = response.data;
    } catch (error) {
        toastServiceError(error);
    }
};

const getClientNumbers = async (query: string) => {
    const params = { search: query };
    const { data } = await CallService.getClientNumbers({ params });
    return data;
};

const filterCallParticipantTypes = (value: CallParticipantType | null) => {
    selectedParticipant.value = value || CallParticipantType.All;
    filter.value.word = null;
    getWords(value || CallParticipantType.All);
};

const getButtonVariant = (value: CallParticipantType | null) => {
    if (selectedParticipant.value === value) {
        if (value === CallParticipantType.Agent) return 'purple-200';
        if (value === CallParticipantType.Client) return 'blue-200';
        if (value === CallParticipantType.All) return 'gray-300';
    }
    return 'neutral';
};

const getTextVariant = (value: CallParticipantType | null) => {
    if (selectedParticipant.value === value) {
        if (value === CallParticipantType.Agent) return 'purple-700';
        if (value === CallParticipantType.Client) return 'blue-700';
        if (value === CallParticipantType.All) return 'gray-700';
    }
    return 'gray-700';
};

onMounted(() => {
    getMedias();
    getUserLabels();
    getAgents();
    getWords(CallParticipantType.All);
    filterCallParticipantTypes(CallParticipantType.All);
});

const __returned__ = { t, route, filterStore, uiStore, filtersOpened, selectedParticipant, filter, words, userLabelOptions, agentOptions, mediaFilterOptions, levelFilter, storeFilters, isCallTypeRoute, isCallStatusRoute, statusFilterOptions, typeFilterOptions, callParticipantOptions, setLoading, refreshLocalFilter, applyFilters, clearFilters, getWords, getUserLabels, getAgents, getMedias, getClientNumbers, filterCallParticipantTypes, getButtonVariant, getTextVariant, get FilterIcon() { return FilterIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})