export interface SelectOptionsServiceGetUsersResult {
    id: number;
    name: string;
}

export interface SelectOptionsServiceGetUserLabelsResult {
    id: number;
    label: string;
}

export interface SelectOptionsServiceGetWordsParams {
    call_participant?: string;
}

export interface SelectOptionsServiceGetWordsResult {
    id: number;
    word: string;
}
