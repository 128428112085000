import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import { useUiStore } from '@/stores';
import NumberService from '@/services/api/NumberService';
import { toastServiceError } from '@/services/core/notification';
import { Permission, NumberModel } from '@/types';
import { applyPhoneMask } from '@/utils/number';


export default /*@__PURE__*/_defineComponent({
  __name: 'NumberDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const number: Ref<NumberModel | undefined> = ref(undefined);

const route = useRoute();
const uiStore = useUiStore();

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchNumber = async (id: number) => {
    try {
        setLoading(true);
        const response = await NumberService.get<NumberModel>(id);
        number.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

onMounted(() => {
    const id = Number(route.params.id);
    if (!isNaN(id)) {
        fetchNumber(id);
    }
});

const __returned__ = { number, route, uiStore, setLoading, fetchNumber, get Permission() { return Permission }, get applyPhoneMask() { return applyPhoneMask } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})