<script lang="ts" setup>
import { ref, Ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import store from '@/store';
import { FilterIcon } from '@heroicons/vue/solid';

import { LevelFilter, MultiSelectOption, ScheduleActions, ScheduleGetters } from '@/types';

const { t } = useI18n();

const filtersOpened = ref(false);

const defaultFilter = computed(() => store.getters[ScheduleGetters.DEFAULT_FILTER]);

const filter: Ref<LevelFilter> = ref({
    isActive: defaultFilter.value.isActive,
});

const storeFilters = computed<LevelFilter>(() => store.getters[ScheduleGetters.FILTER]);

const isActiveFilterOptions = [
    {
        label: t('schedule.filters.isActive.options.active'),
        value: true,
    },
    {
        label: t('schedule.filters.isActive.options.inactive'),
        value: false,
    },
] as MultiSelectOption[];

const applyFilters = () => {
    store.dispatch(ScheduleActions.SET_FILTER, filter.value);
};

const clearFilters = () => {
    store.dispatch(ScheduleActions.CLEAR_FILTER);
    refreshLocalFilter(); // sync UI with store after clearing
};

const refreshLocalFilter = () => {
    filter.value.isActive = storeFilters.value.isActive;
};

watch(filtersOpened, () => {
    refreshLocalFilter(); // sync when panel is toggled
});
</script>

<template>
    <UiRightPanel v-model="filtersOpened" :title="$t('filter.sidebar.title')">
        <template #button>
            <UiButton variant="white" text-variant="gray-700" @click="filtersOpened = true">
                <FilterIcon class="mr-0 h-5 w-5 text-gray-400 sm:mr-3" aria-hidden="true" />
                <span class="hidden sm:block">{{ $t('core.actions.Filter') }}</span>
            </UiButton>
        </template>
        <template #default>
            <form autocomplete="off" class="flex flex-col gap-3">
                <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                    <div class="sm:col-span-4">
                        <label class="block text-sm font-medium text-gray-800">
                            {{ $t('schedule.filters.isActive.label') }}
                        </label>
                        <UiMultiselect
                            name="filters_isActive"
                            v-model="filter.isActive"
                            :options="isActiveFilterOptions"
                            can-clear
                            :placeholder="t('core.options.All')"
                        />
                    </div>
                </div>
                <div class="mt-4 grid grid-cols-2 place-content-around gap-4 text-center">
                    <UiButton variant="white" text-variant="gray-700" @click="clearFilters()">
                        {{ $t('core.actions.Clear') }}
                    </UiButton>
                    <UiButton variant="primary" text-variant="white" @click="applyFilters()">
                        {{ $t('core.actions.Apply') }}
                    </UiButton>
                </div>
            </form>
        </template>
    </UiRightPanel>
</template>
