import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { UserGroupIcon } from '@heroicons/vue/solid';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import SpamNumberService from '@/services/api/SpamNumberService';
import { toastServiceError } from '@/services/core/notification';

import {
    SortMenuItem,
    SpamNumberModel,
    SpamNumberServiceGetAllParams,
    Permission,
    SpamNumberActions,
    SpamNumberGetters,
} from '@/types';
import { SpamNumberListItem, SpamNumberListSort } from '@/views/pages/spam-number/components';

const perPage = 30;


export default /*@__PURE__*/_defineComponent({
  __name: 'SpamNumberPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();
const filterStore = useFilterStore();
const uiStore = useUiStore();

const spamNumbers = ref<SpamNumberModel[]>([]);
const count = ref(0);
const search = computed({
    get: () => store.getters[SpamNumberGetters.SEARCH],
    set: (value: string) => store.dispatch(SpamNumberActions.SET_SEARCH, value),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[SpamNumberGetters.SORT],
    set: (value: SortMenuItem[]) => store.dispatch(SpamNumberActions.SET_SORT, value),
});

const levelFilter = computed(() => filterStore.level);

const currentPage = computed({
    get: () => store.getters[SpamNumberGetters.PAGE],
    set: (value: number) => store.dispatch(SpamNumberActions.SET_PAGE, value),
});

const orderBy = computed(() => sortMenuSelected.value.map((s) => s.direction + s.key).join(','));

watch([currentPage], () => {
    fetchSpamNumbers();
});

watch([levelFilter, search, orderBy], () => {
    if (currentPage.value === 1) fetchSpamNumbers();
    else currentPage.value = 1;
});

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getParams = (): SpamNumberServiceGetAllParams => ({
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    search: search.value,
});

const fetchSpamNumbers = async () => {
    setLoading(true);
    try {
        const { data, headers } = await SpamNumberService.getAll<SpamNumberModel[]>({
            params: getParams(),
        });
        spamNumbers.value = data;
        count.value = parseInt(headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveSortClick = (item: SortMenuItem) => {
    const index = sortMenuSelected.value.indexOf(item);
    if (index > -1) sortMenuSelected.value.splice(index, 1);
};

onMounted(() => {
    if (route.query.clearStateOnSetup) {
        search.value = '';
        sortMenuSelected.value = [];
        currentPage.value = 1;
        router.replace({ query: {} });
    }

    fetchSpamNumbers();
});

const __returned__ = { route, router, filterStore, uiStore, spamNumbers, count, perPage, search, sortMenuSelected, levelFilter, currentPage, orderBy, setLoading, getParams, fetchSpamNumbers, handleRemoveSortClick, get UserGroupIcon() { return UserGroupIcon }, get Permission() { return Permission }, get SpamNumberListItem() { return SpamNumberListItem }, get SpamNumberListSort() { return SpamNumberListSort } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})