import { ContactCenterReportServiceGetInfoResult } from '@/types';
import BaseService from '@/services/api/BaseService';
import { AxiosRequestConfig } from 'axios';

class ContactCenterPerAgentReportService extends BaseService<ContactCenterReportServiceGetInfoResult> {
    getInfo(config?: AxiosRequestConfig) {
        return this.http.get<ContactCenterReportServiceGetInfoResult>(`${this.route}`, config);
    }
}

export default new ContactCenterPerAgentReportService('reports/contact-center-per-agent');
