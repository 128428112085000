import { AgentProductivityReportServiceGetInfoResult } from '@/types';
import BaseService from '@/services/api/BaseService';
import { AxiosRequestConfig } from 'axios';

class AgentProductivityPerAgentReportService extends BaseService<AgentProductivityReportServiceGetInfoResult> {
    getInfo(config?: AxiosRequestConfig) {
        return this.http.get<AgentProductivityReportServiceGetInfoResult>(`${this.route}`, config);
    }
}

export default new AgentProductivityPerAgentReportService('reports/agent-productivity-per-agent');
