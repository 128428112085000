import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, mergeProps as _mergeProps, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "space-y-4 p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "cursor-pointer text-xl font-semibold text-gray-800" }
const _hoisted_3 = { class: "mt-3 flex items-center space-x-2" }
const _hoisted_4 = { class: "text-sm" }
const _hoisted_5 = { class: "font-semibold" }
const _hoisted_6 = {
  key: 0,
  class: "text-danger-700 rounded border border-red-300 bg-red-100 px-1.5 py-0.5 text-xs font-semibold"
}
const _hoisted_7 = { class: "flex gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiBackButton = _resolveComponent("UiBackButton")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UiPageHeader, { class: "mb-5" }, _createSlots({
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('connectFlow.' + $setup.props.action + '.title')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString($setup.t('connectFlow.labels.lastPublished')), 1 /* TEXT */),
            _createTextVNode(" " + _toDisplayString($setup.formatDateAsString($setup.connectFlow?.updatedAt, 'LLL')), 1 /* TEXT */)
          ]),
          (
                            $setup.connectFlow !== undefined &&
                            $setup.connectFlow.currentFlowId !== $setup.connectFlow.lastAppliedFlowId
                        )
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString($setup.t('connectFlow.labels.notPublished')), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 2 /* DYNAMIC */
    }, [
      (_ctx.$can($setup.Permission.CREATE_CONNECT_FLOW))
        ? {
            name: "actions",
            fn: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_UiBackButton, { fallbackTo: { name: 'ListConnectFlow' } }),
                _createVNode(_component_UiButton, {
                  variant: "primary",
                  "text-variant": "white",
                  onClick: $setup.save
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Save')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_UiButton, {
                  variant: "emerald",
                  "text-variant": "white",
                  disabled: !$setup.isAllElementsValid,
                  title: $setup.isAllElementsValid ? '' : $setup.t('connectFlow.error.hasInvalidNodes'),
                  onClick: $setup.publish
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Publish')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["disabled", "title"])
              ])
            ]),
            key: "0"
          }
        : undefined
    ]), 1024 /* DYNAMIC_SLOTS */),
    _createElementVNode("div", {
      style: _normalizeStyle($setup.fullScreen ? '' : 'height: calc(100vh - 170px);'),
      class: _normalizeClass(["rounded bg-gray-200 shadow", $setup.fullScreen ? 'fixed right-0 top-0 z-40 h-full w-full' : 'h-5/6'])
    }, [
      _createVNode($setup["VueFlow"], {
        modelValue: $setup.elements,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.elements) = $event)),
        "default-zoom": 0.5,
        "max-zoom": 1,
        "min-zoom": 0.45,
        "snap-grid": [5, 5],
        "snap-to-grid": "",
        "fit-view-on-init": ""
      }, {
        "node-input": _withCtx((node) => [
          _createVNode($setup["StartNode"], {
            nodeId: node.id
          }, null, 8 /* PROPS */, ["nodeId"])
        ]),
        "node-default": _withCtx((node) => [
          _createVNode($setup["CustomNode"], {
            nodeId: node.id
          }, null, 8 /* PROPS */, ["nodeId"])
        ]),
        "edge-custom": _withCtx((edge) => [
          ([$setup.EdgeType.AWAITABLE, $setup.EdgeType.NORMAL].includes(edge.data.component))
            ? (_openBlock(), _createBlock($setup["AwaitableEdge"], _normalizeProps(_mergeProps({ key: 0 }, edge)), null, 16 /* FULL_PROPS */))
            : _createCommentVNode("v-if", true),
          (edge.data.component === $setup.EdgeType.CLIENT_INFO_EDGE)
            ? (_openBlock(), _createBlock($setup["ClientInfoEdge"], _normalizeProps(_mergeProps({ key: 1 }, edge)), null, 16 /* FULL_PROPS */))
            : _createCommentVNode("v-if", true)
        ]),
        default: _withCtx(() => [
          _createVNode($setup["Background"]),
          _createVNode($setup["MiniMap"]),
          _createVNode($setup["Controls"], null, {
            default: _withCtx(() => [
              _createVNode($setup["ControlButton"], null, {
                default: _withCtx(() => [
                  _createVNode($setup["ArrowsExpandIcon"], {
                    class: "w-6",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.fullScreen = !$setup.fullScreen))
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ], 6 /* CLASS, STYLE */),
    _createVNode($setup["FormClienteInfoEdge"], {
      modelValue: $setup.showFormClienteInfoEdge,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.showFormClienteInfoEdge) = $event)),
      edgeId: $setup.triggeredEdge
    }, null, 8 /* PROPS */, ["modelValue", "edgeId"]),
    _createVNode($setup["FormAwaitableEdge"], {
      modelValue: $setup.showFormAwaitableEdge,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.showFormAwaitableEdge) = $event)),
      edgeId: $setup.triggeredEdge
    }, null, 8 /* PROPS */, ["modelValue", "edgeId"]),
    _createVNode($setup["ComponentSelector"])
  ]))
}