import { FlowNodeModel, NodeType } from '@/types';

const nodeStart: FlowNodeModel = {
    maxChildren: 1,
    typesChildren: [
        NodeType.TIME_VALIDATION,
        NodeType.PLAY_AUDIO,
        NodeType.CLIENT_INFO,
        NodeType.TRANSFER_EXTENSION,
        NodeType.TRANSFER_QUEUE,
        NodeType.TRANSFER_EXTERNAL,
        NodeType.HANG_UP,
    ],
    autoChildren: [],
    position: {
        x: 0,
    },
};

const nodeTimeValidation: FlowNodeModel = {
    maxChildren: 2,
    typesChildren: [
        NodeType.TIME_VALIDATION,
        NodeType.PLAY_AUDIO,
        NodeType.CLIENT_INFO,
        NodeType.TRANSFER_EXTENSION,
        NodeType.TRANSFER_EXTERNAL,
        NodeType.HANG_UP,
    ],
    position: {
        x: -75,
    },
    autoChildren: [
        {
            data: {
                title: 'connectFlow.node.timeValidation.inTime',
                component: NodeType.OPEN_EDGE,
                flowType: 'inTime',
                related: null,
                hasErrors: false,
            },
            position: {
                x: -50,
                y: 200,
            },
        },
        {
            data: {
                title: 'connectFlow.node.timeValidation.outOfTime',
                component: NodeType.OPEN_EDGE,
                flowType: 'outOfTime',
                related: null,
                hasErrors: false,
            },
            position: {
                x: 325,
                y: 200,
            },
        },
    ],
};

const playAudioValidation: FlowNodeModel = {
    maxChildren: 1,
    typesChildren: [
        NodeType.TIME_VALIDATION,
        NodeType.PLAY_AUDIO,
        NodeType.CLIENT_INFO,
        NodeType.TRANSFER_EXTENSION,
        NodeType.TRANSFER_QUEUE,
        NodeType.TRANSFER_EXTERNAL,
        NodeType.HANG_UP,
    ],
    autoChildren: [
        {
            data: {
                title: 'connectFlow.node.playAudio.end',
                component: NodeType.OPEN_EDGE,
                related: null,
                hasErrors: false,
                flowType: 'end',
            },
            position: {
                x: 135,
                y: 200,
            },
        },
    ],
    position: { x: -75 },
};

const ppenEdgeValidation: FlowNodeModel = {
    maxChildren: -1,
    typesChildren: [
        NodeType.TIME_VALIDATION,
        NodeType.PLAY_AUDIO,
        NodeType.CLIENT_INFO,
        NodeType.TRANSFER_EXTENSION,
        NodeType.TRANSFER_QUEUE,
        NodeType.TRANSFER_EXTERNAL,
        NodeType.HANG_UP,
        NodeType.GO_TO,
    ],
    autoChildren: [],
    position: { x: 0 },
};

const clientInfoValidation: FlowNodeModel = {
    maxChildren: 12,
    typesChildren: [
        NodeType.TIME_VALIDATION,
        NodeType.PLAY_AUDIO,
        NodeType.CLIENT_INFO,
        NodeType.TRANSFER_EXTENSION,
        NodeType.TRANSFER_QUEUE,
        NodeType.TRANSFER_EXTERNAL,
        NodeType.HANG_UP,
        NodeType.GO_TO,
    ],
    autoChildren: [
        {
            data: {
                title: 'connectFlow.node.clientInfo.timeout',
                component: NodeType.OPEN_EDGE,
                related: null,
                hasErrors: false,
                flowType: 'timeout',
            },
            sourceHandle: 'timeout',
            position: {
                x: -150,
                y: -30,
            },
        },
        {
            data: {
                title: 'connectFlow.node.clientInfo.invalidOption',
                component: NodeType.OPEN_EDGE,
                related: null,
                hasErrors: false,
                flowType: 'invalid',
            },
            sourceHandle: 'invalid',
            position: {
                x: -150,
                y: 110,
            },
        },
    ],
    position: { x: 0, y: 250 },
};

const transferToValidation: FlowNodeModel = {
    maxChildren: 2,
    typesChildren: [NodeType.TRANSFER_SURVEY],
    autoChildren: [
        {
            data: {
                title: 'connectFlow.node.transferToExtension.noAnswer',
                component: NodeType.OPEN_EDGE,
                related: null,
                hasErrors: false,
                flowType: 'noAnswer',
            },
            position: {
                x: -50,
                y: 200,
            },
        },
    ],
    position: { x: 0 },
};

const basicValidation: FlowNodeModel = {
    maxChildren: 0,
    typesChildren: [],
    autoChildren: [],
    position: { x: 0 },
};

export const flowNodeRules = {
    [NodeType.START]: nodeStart,
    [NodeType.TIME_VALIDATION]: nodeTimeValidation,
    [NodeType.PLAY_AUDIO]: playAudioValidation,
    [NodeType.OPEN_EDGE]: ppenEdgeValidation,
    [NodeType.CLIENT_INFO]: clientInfoValidation,
    [NodeType.TRANSFER_EXTENSION]: transferToValidation,
    [NodeType.TRANSFER_EXTERNAL]: basicValidation,
    [NodeType.TRANSFER_QUEUE]: transferToValidation,
    [NodeType.TRANSFER_SURVEY]: basicValidation,
    [NodeType.HANG_UP]: basicValidation,
    [NodeType.GO_TO]: basicValidation,
};
