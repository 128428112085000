import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "space-y-4 p-4 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-800" }
const _hoisted_3 = {
  key: 0,
  class: "flex bg-gray-50 p-4 shadow ring-1 ring-gray-300 md:rounded"
}
const _hoisted_4 = { class: "text-center text-xs font-medium text-gray-500" }
const _hoisted_5 = { class: "mt-1 text-center text-2xl font-semibold text-gray-800" }
const _hoisted_6 = { class: "grid grid-cols-1 gap-4 sm:grid-cols-2" }
const _hoisted_7 = { class: "rounded bg-gray-50 p-4 shadow" }
const _hoisted_8 = { class: "mb-4 text-lg font-semibold text-gray-800" }
const _hoisted_9 = { class: "space-y-4" }
const _hoisted_10 = { class: "flex justify-between border-b border-gray-200" }
const _hoisted_11 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_12 = { class: "text-lg font-semibold text-gray-800" }
const _hoisted_13 = { class: "text-sm text-gray-500" }
const _hoisted_14 = { class: "text-sm font-semibold text-gray-800" }
const _hoisted_15 = { class: "rounded bg-gray-50 p-4 shadow" }
const _hoisted_16 = { class: "mb-4 text-lg font-semibold text-gray-800" }
const _hoisted_17 = { class: "space-y-4" }
const _hoisted_18 = { class: "flex justify-between border-b border-gray-200" }
const _hoisted_19 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_20 = { class: "text-lg font-semibold text-gray-800" }
const _hoisted_21 = { class: "text-sm text-gray-500" }
const _hoisted_22 = { class: "text-sm font-semibold text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiDatePicker = _resolveComponent("UiDatePicker")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!
  const _component_UiBadge = _resolveComponent("UiBadge")!
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _component_BarChart = _resolveComponent("BarChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" Header com Total de Minutos e Custo Total "),
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('report.billing.title')), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createVNode($setup["BillingReportFilters"], {
          sources: $setup.reportInfo?.sources || [],
          destinations: $setup.reportInfo?.destinations || []
        }, null, 8 /* PROPS */, ["sources", "destinations"]),
        _createVNode(_component_UiDatePicker, {
          modelValue: $setup.dateRange,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.dateRange) = $event)),
          range: "",
          multiCalendars: "",
          class: "ml-4"
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }),
    _createCommentVNode(" Filtros Ativos "),
    ($setup.activeFilters.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.activeFilters, (filter) => {
            return (_openBlock(), _createBlock(_component_UiBadge, {
              key: filter,
              removable: "",
              onRemove: ($event: any) => ($setup.handleRemoveFilter(filter))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(`report.billing.filters.activeTag.${filter.field}`)) + ": " + _toDisplayString(filter.label), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onRemove"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" Estatísticas Gerais "),
    ($setup.headerStats)
      ? (_openBlock(), _createElementBlock("dl", {
          key: 1,
          class: _normalizeClass(["mt-4 grid grid-cols-2 gap-4", ['sm:grid-cols-' + $setup.headerStats.length]])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.headerStats, (stat) => {
            return (_openBlock(), _createBlock(_component_UiPanel, {
              key: stat.name
            }, {
              default: _withCtx(() => [
                _createElementVNode("dt", _hoisted_4, _toDisplayString(_ctx.$t('report.billing.stats.' + stat.name)), 1 /* TEXT */),
                _createElementVNode("dd", _hoisted_5, _toDisplayString(stat.value), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */))
          }), 128 /* KEYED_FRAGMENT */))
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" Estatísticas Entrante e Saínte "),
    _createElementVNode("div", _hoisted_6, [
      _createCommentVNode(" Seção Entrante "),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.$t('report.billing.stats.inbound')), 1 /* TEXT */),
        _createElementVNode("dl", _hoisted_9, [
          _createCommentVNode(" Custo Total Entrante "),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("dt", _hoisted_11, _toDisplayString(_ctx.$t('report.billing.stats.totalInboundCost')), 1 /* TEXT */),
            _createElementVNode("dd", _hoisted_12, " R$ " + _toDisplayString($setup.reportInfo?.inboundCost?.toFixed(2) || '0,00'), 1 /* TEXT */)
          ]),
          _createCommentVNode(" Subcustos por Tipo de Origem (Entrante) "),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.reportInfo?.costByOriginType || {}, (value, key) => {
            return (_openBlock(), _createElementBlock("div", {
              key: key,
              class: "flex justify-between"
            }, [
              _createElementVNode("dt", _hoisted_13, _toDisplayString(_ctx.$t(`report.billing.stats.originType.${key}`)), 1 /* TEXT */),
              _createElementVNode("dd", _hoisted_14, " R$ " + _toDisplayString(value?.toFixed(2) || '0,00'), 1 /* TEXT */)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _createCommentVNode(" Seção Saínte "),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("h2", _hoisted_16, _toDisplayString(_ctx.$t('report.billing.stats.outbound')), 1 /* TEXT */),
        _createElementVNode("dl", _hoisted_17, [
          _createCommentVNode(" Custo Total Sainte "),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("dt", _hoisted_19, _toDisplayString(_ctx.$t('report.billing.stats.totalOutboundCost')), 1 /* TEXT */),
            _createElementVNode("dd", _hoisted_20, " R$ " + _toDisplayString($setup.reportInfo?.outboundCost?.toFixed(2) || '0,00'), 1 /* TEXT */)
          ]),
          _createCommentVNode(" Subcustos por Tipo de Destino (Sainte) "),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.reportInfo?.costByDestinationType || {}, (value, key) => {
            return (_openBlock(), _createElementBlock("div", {
              key: key,
              class: "flex justify-between"
            }, [
              _createElementVNode("dt", _hoisted_21, _toDisplayString(_ctx.$t(`report.billing.stats.destinationType.${key}`)), 1 /* TEXT */),
              _createElementVNode("dd", _hoisted_22, " R$ " + _toDisplayString(value?.toFixed(2) || '0,00'), 1 /* TEXT */)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ])
    ]),
    _createCommentVNode(" Gráfico de Consumo por Dia "),
    _createVNode(_component_BarChart, {
      title: $setup.dailyConsumptionChart.title,
      categoryData: $setup.dailyConsumptionChart.category,
      seriesData: $setup.dailyConsumptionChart.series,
      column: true,
      class: "h-[400px]"
    }, null, 8 /* PROPS */, ["title", "categoryData", "seriesData"])
  ]))
}