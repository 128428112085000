import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative flex flex-col flex-wrap gap-2 pb-3 sm:flex-row" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiBadge = _resolveComponent("UiBadge")!
  const _component_UiPanel = _resolveComponent("UiPanel")!

  return (_openBlock(), _createBlock(_component_UiPanel, null, {
    default: _withCtx(() => [
      _createElementVNode("h3", {
        class: _normalizeClass(["mb-3 text-lg font-medium", 
                $props.participantType == $setup.CallParticipantType.Client ? 'text-purple-500' : 'text-blue-500'
            ])
      }, _toDisplayString(_ctx.$t('call.view.participant.' + $props.participantType.toLowerCase())), 3 /* TEXT, CLASS */),
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredWords, (word) => {
          return (_openBlock(), _createBlock(_component_UiBadge, {
            key: `${$props.participantType}-word-${word.text}`,
            class: "cursor-pointer rounded",
            variant: word.selected ? 'orange' : word.registered ? 'blue' : 'gray',
            onClick: ($event: any) => (
                    $setup.store.commit($setup.CallPlayerMutations.TOGGLE_SELECTED_WORD, {
                        participant: $props.participantType,
                        word: word.text,
                    })
                )
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(word.text), 1 /* TEXT */)
            ]),
            number: _withCtx(() => [
              _createTextVNode(_toDisplayString(word.counter), 1 /* TEXT */)
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["variant", "onClick"]))
        }), 128 /* KEYED_FRAGMENT */)),
        ($setup.words.length > 10)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "absolute bottom-0 right-0 cursor-pointer text-sm text-orange-600 hover:text-orange-800",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.showHiddenWords = !$setup.showHiddenWords))
            }, [
              (!$setup.showHiddenWords)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('core.actions.SeeMore')), 1 /* TEXT */))
                : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('core.actions.SeeLess')), 1 /* TEXT */))
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _: 1 /* STABLE */
  }))
}