/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    ConnectFlowState,
    ConnectFlowMutations,
    ConnectFlowActions,
    ConnectFlowGetters,
    ConnectFlowFilter,
    SortMenuItem,
    FilterItem,
} from '@/types';
import ConnectFlowSubModeStore from '@/store/modules/connectFlow.submodule.store';

const getDefaultState = () => {
    return {
        search: null,
        sort: [],
        page: 1,
        filter: {
            isActive: null,
        },
    } as ConnectFlowState;
};

const state: ConnectFlowState = getDefaultState();

const mutations: MutationTree<ConnectFlowState> = {
    [ConnectFlowMutations.SET_SEARCH]: (state, search: string) => (state.search = search),
    [ConnectFlowMutations.SET_SORT]: (state, sort: SortMenuItem[]) => (state.sort = sort),
    [ConnectFlowMutations.SET_PAGE]: (state, page: number) => (state.page = page),
    [ConnectFlowMutations.SET_FILTER](state, filter: ConnectFlowFilter) {
        state.filter.isActive = filter.isActive;
    },
    [ConnectFlowMutations.CLEAR_FILTER](state, item?: FilterItem) {
        switch (item?.field) {
            case 'isActive':
                state.filter.isActive = getDefaultState().filter.isActive;
                break;
            default:
                state.filter.isActive = getDefaultState().filter.isActive;
        }
    },
};

const actions: ActionTree<ConnectFlowState, any> = {
    [ConnectFlowActions.SET_SEARCH]: ({ commit }, search: string) =>
        commit(ConnectFlowMutations.SET_SEARCH, search),
    [ConnectFlowActions.SET_SORT]: ({ commit }, sort: SortMenuItem[]) =>
        commit(ConnectFlowMutations.SET_SORT, sort),
    [ConnectFlowActions.SET_PAGE]: ({ commit }, page: number) =>
        commit(ConnectFlowMutations.SET_PAGE, page),
    [ConnectFlowActions.SET_FILTER]: ({ commit }, filter: ConnectFlowFilter) =>
        commit(ConnectFlowMutations.SET_FILTER, filter),
    [ConnectFlowActions.CLEAR_FILTER]: ({ commit }, item: FilterItem) =>
        commit(ConnectFlowMutations.CLEAR_FILTER, item),
};

const getters: GetterTree<ConnectFlowState, any> = {
    [ConnectFlowGetters.SEARCH]: (state): string | null => state.search,
    [ConnectFlowGetters.SORT]: (state): SortMenuItem[] => state.sort,
    [ConnectFlowGetters.PAGE]: (state): number => state.page,
    [ConnectFlowGetters.FILTER]: (state): ConnectFlowFilter => state.filter,
    [ConnectFlowGetters.DEFAULT_FILTER]: (): ConnectFlowFilter => getDefaultState().filter,
    [ConnectFlowGetters.ACTIVE_FILTERS](state): FilterItem[] {
        const filters: Array<FilterItem> = [];

        if (state.filter.isActive !== null) {
            filters.push({
                field: 'isActive',
                value: state.filter.isActive.value,
                label: state.filter.isActive.label,
            });
        }

        return filters;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
    modules: {
        flow: ConnectFlowSubModeStore,
    },
};
