import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import moment from 'moment';

import { useUiStore } from '@/stores';
import ScheduleService from '@/services/api/ScheduleService';
import { toastServiceError } from '@/services/core/notification';

import { Permission, ScheduleModel, ScheduleWeekday } from '@/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'ScheduleDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const uiStore = useUiStore();

const schedule: Ref<ScheduleModel | undefined> = ref(undefined);

const weekdays: ScheduleWeekday[] = [
    ScheduleWeekday.SUNDAY,
    ScheduleWeekday.MONDAY,
    ScheduleWeekday.TUESDAY,
    ScheduleWeekday.WEDNESDAY,
    ScheduleWeekday.THURSDAY,
    ScheduleWeekday.FRIDAY,
    ScheduleWeekday.SATURDAY,
];

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchScheduleById = async (id: number) => {
    try {
        setLoading(true);
        const { data } = await ScheduleService.get<ScheduleModel>(id);
        schedule.value = data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const activeDays = computed(() => weekdays.filter((day: ScheduleWeekday) => schedule.value?.[day]));

const formatDayLabel = (day: ScheduleWeekday) => {
    return moment().day(weekdays.indexOf(day)).format('dddd');
};

onMounted(() => {
    const id = Number(route.params.id);
    if (!isNaN(id)) fetchScheduleById(id);
});

const __returned__ = { route, uiStore, schedule, weekdays, setLoading, fetchScheduleById, activeDays, formatDayLabel, get Permission() { return Permission } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})