import { defineComponent as _defineComponent } from 'vue'
import { PropType } from 'vue';
import { ConnectFlowAudioModel, Permission } from '@/types';

import { CheckCircleIcon, PencilAltIcon, XCircleIcon } from '@heroicons/vue/outline';


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowAudioListItem',
  props: {
    modelValue: {
        type: Object as PropType<ConnectFlowAudioModel>,
        required: true,
    },
},
  emits: ['activate', 'inactivate'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;

const handleActivateClick = () => emit('activate', props.modelValue);
const handleInactivateClick = () => emit('inactivate', props.modelValue);

const __returned__ = { props, emit, handleActivateClick, handleInactivateClick, get Permission() { return Permission }, get CheckCircleIcon() { return CheckCircleIcon }, get PencilAltIcon() { return PencilAltIcon }, get XCircleIcon() { return XCircleIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})