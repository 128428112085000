import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "space-y-4 p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-800" }
const _hoisted_3 = {
  key: 0,
  class: "mt-4 flex bg-gray-50 px-4 py-4 shadow ring-1 ring-gray-300 sm:p-6 md:rounded"
}
const _hoisted_4 = { class: "truncate text-sm font-medium text-gray-500" }
const _hoisted_5 = { class: "mt-1 text-3xl font-semibold tracking-tight text-gray-800" }
const _hoisted_6 = {
  key: 2,
  class: "mt-4 grid grid-cols-1 gap-4 lg:grid-cols-2"
}
const _hoisted_7 = { class: "col-span-2 -mx-4 h-[300px] bg-gray-50 shadow ring-1 ring-gray-300 sm:-mx-6 md:col-span-1 md:mx-0 md:rounded" }
const _hoisted_8 = { class: "col-span-2 -mx-4 h-[300px] bg-gray-50 shadow ring-1 ring-gray-300 sm:-mx-6 md:col-span-1 md:mx-0 md:rounded" }
const _hoisted_9 = { class: "col-span-2 -mx-4 h-[300px] bg-gray-50 shadow ring-1 ring-gray-300 sm:-mx-6 md:col-span-1 md:mx-0 md:rounded" }
const _hoisted_10 = { class: "col-span-2 -mx-4 h-[300px] bg-gray-50 shadow ring-1 ring-gray-300 sm:-mx-6 md:col-span-1 md:mx-0 md:rounded" }
const _hoisted_11 = { class: "col-span-2 -mx-4 h-[300px] bg-gray-50 shadow ring-1 ring-gray-300 sm:-mx-6 md:col-span-1 md:mx-0 md:rounded" }
const _hoisted_12 = { class: "col-span-2 -mx-4 h-[300px] bg-gray-50 shadow ring-1 ring-gray-300 sm:-mx-6 md:col-span-1 md:mx-0 md:rounded" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContactCenterPerDayReportFilters = _resolveComponent("ContactCenterPerDayReportFilters")!
  const _component_UiDatePicker = _resolveComponent("UiDatePicker")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!
  const _component_UiBadge = _resolveComponent("UiBadge")!
  const _component_BarChart = _resolveComponent("BarChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('report.queueTime.title')), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_ContactCenterPerDayReportFilters),
        _createVNode(_component_UiDatePicker, {
          modelValue: $setup.dateRange,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.dateRange) = $event)),
          range: "",
          multiCalendars: "",
          class: "ml-4"
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }),
    ($setup.activeFilters.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.activeFilters, (filter) => {
            return (_openBlock(), _createBlock(_component_UiBadge, {
              key: filter,
              removable: "",
              onRemove: ($event: any) => ($setup.handleRemoveFilter(filter))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(`report.contactCenterPerDay.filters.activeTag.${filter.field}`)) + ": " + _toDisplayString(filter.label), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onRemove"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.reportInfo?.stats)
      ? (_openBlock(), _createElementBlock("dl", {
          key: 1,
          class: _normalizeClass(["mt-4 grid grid-cols-1 gap-5", ['sm:grid-cols-' + $setup.headerStats.length]])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.headerStats, (stat) => {
            return (_openBlock(), _createElementBlock("div", {
              key: stat.name,
              class: "overflow-hidden rounded bg-gray-50 px-4 py-4 shadow sm:p-6"
            }, [
              _createElementVNode("dt", _hoisted_4, _toDisplayString(_ctx.$t('report.queueTime.stats.' + stat.name)), 1 /* TEXT */),
              _createElementVNode("dd", _hoisted_5, _toDisplayString(stat.value), 1 /* TEXT */)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    ($setup.reportInfo)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_BarChart, {
              title: $setup.t('report.queueTime.averageTimePerDay.title'),
              categoryData: $setup.chartDataAverageTimePerDay.category,
              seriesData: $setup.chartDataAverageTimePerDay.series,
              xAxisLabelFormatter: $setup.axisLabelDateFormatter,
              yAxisLabelFormatter: $setup.axisLabelSecToTimeFormatter,
              column: ""
            }, null, 8 /* PROPS */, ["title", "categoryData", "seriesData"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_BarChart, {
              title: $setup.t('report.queueTime.timeRangeByDay.title'),
              categoryData: $setup.chartDataTimeRangeByDay.category,
              seriesData: $setup.chartDataTimeRangeByDay.series,
              xAxisLabelFormatter: $setup.axisLabelDateFormatter,
              column: ""
            }, null, 8 /* PROPS */, ["title", "categoryData", "seriesData"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_BarChart, {
              title: $setup.t('report.queueTime.averageTimePerWeekDay.title'),
              categoryData: $setup.chartDataAverageTimePerWeekDay.category,
              seriesData: $setup.chartDataAverageTimePerWeekDay.series,
              yAxisLabelFormatter: $setup.axisLabelSecToTimeFormatter,
              column: ""
            }, null, 8 /* PROPS */, ["title", "categoryData", "seriesData"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_BarChart, {
              title: $setup.t('report.queueTime.timeRangeByWeekDay.title'),
              categoryData: $setup.chartDataTimeRangeByWeekDay.category,
              seriesData: $setup.chartDataTimeRangeByWeekDay.series,
              column: ""
            }, null, 8 /* PROPS */, ["title", "categoryData", "seriesData"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_BarChart, {
              title: $setup.t('report.queueTime.averageTimeByHourOfDay.title'),
              categoryData: $setup.chartDataAverageTimePerHourOfDay.category,
              seriesData: $setup.chartDataAverageTimePerHourOfDay.series,
              yAxisLabelFormatter: $setup.axisLabelSecToTimeFormatter,
              column: ""
            }, null, 8 /* PROPS */, ["title", "categoryData", "seriesData"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_BarChart, {
              title: $setup.t('report.queueTime.averageTimeByHourOfDay.title'),
              categoryData: $setup.chartDataTimeRangePerHourDay.category,
              seriesData: $setup.chartDataTimeRangePerHourDay.series,
              column: ""
            }, null, 8 /* PROPS */, ["title", "categoryData", "seriesData"])
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}