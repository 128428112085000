import { defineComponent as _defineComponent } from 'vue'
import { computed, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import VueDatePicker from '@vuepic/vue-datepicker';

import { useUiStore } from '@/stores';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiDatePicker',
  props: {
    modelValue: {
        type: [Date, Array<Date>, String, Array<string>] as PropType<
            Date | Array<Date> | string | Array<string>
        >,
    },
    modelType: {
        type: String,
    },
    range: {
        type: Boolean,
        default: false,
    },
    partialRange: {
        type: Boolean,
        default: false,
    },
    multiCalendars: {
        type: Boolean,
        default: false,
    },
    autoApply: {
        type: Boolean,
        default: true,
    },
    enableTimePicker: {
        type: Boolean,
        default: false,
    },
    clearable: {
        type: Boolean,
        default: false,
    },
    utc: {
        type: [Boolean, String] as PropType<boolean | 'preserve'>,
        default: 'preserve',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    readonly: {
        type: Boolean,
        default: false,
    },
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;

const uiStore = useUiStore();
const { t } = useI18n();

const currentLocale = computed(() => uiStore.currentLocale);

const format = (selected: Date | Date[]) => {
    let response = '';
    if (Array.isArray(selected)) {
        const firstDate = moment(selected[0]);
        const secondDate = moment(selected[1]);
        response = `${firstDate.format(t('core.dateFormat'))} - ${secondDate.format(t('core.dateFormat'))}`;
    } else {
        const date = moment(selected);
        response = `${date.format(t('core.dateFormat'))}`;
        response = `Selected coisa`;
    }
    return response;
};

const updateValue = (value: string) => {
    emit('update:modelValue', value);
};

const __returned__ = { props, emit, uiStore, t, currentLocale, format, updateValue, get VueDatePicker() { return VueDatePicker } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})