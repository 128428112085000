<script lang="ts" setup>
import { computed, Ref, ref, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import store from '@/store';

import { useFilterStore } from '@/stores';
import { UserService, UserLabelService } from '@/services';
import { toastServiceError } from '@/services/core/notification';

import {
    AgentProductivityReportFilter,
    AgentProductivityReportGetters,
    AgentProductivityReportActions,
    UserModel,
    LevelModel,
    UserLabelModel,
} from '@/types';

import { FilterIcon } from '@heroicons/vue/solid';

const filterOpened = ref(false);
const filter: Ref<AgentProductivityReportFilter> = ref({
    user: null,
    userLabel: null,
});
const userOptions: Ref<UserModel[]> = ref([]);
const userLabelOptions: Ref<UserLabelModel[]> = ref([]);

const levelFilter = computed<LevelModel | null>(() => filterStore.level);
const defaultFilter = computed(() => store.getters[AgentProductivityReportGetters.DEFAULT_FILTER]);
const storeFilter = computed(() => store.getters[AgentProductivityReportGetters.FILTER]);

const filterStore = useFilterStore();
const { t } = useI18n();

watch(filterOpened, () => {
    refreshLocalFilter();
});

watch(levelFilter, () => {
    store.dispatch(AgentProductivityReportActions.CLEAR_FILTER);
    getUsers();
    getUserLabels();
});

const getUsers = async () => {
    try {
        const response = await UserService.getAll<UserModel[]>({
            params: {
                isAgent: true,
                page: 1,
                per_page: 100,
            },
        });
        userOptions.value = response.data;
    } catch (error) {
        toastServiceError(error);
    }
};

const getUserLabels = async () => {
    try {
        const response = await UserLabelService.getAll<UserLabelModel[]>();
        userLabelOptions.value = response.data;
    } catch (error) {
        toastServiceError(error);
    }
};

const applyFilters = () => {
    store.dispatch(AgentProductivityReportActions.SET_FILTER, filter.value);
};

const clearFilters = () => {
    store.dispatch(AgentProductivityReportActions.CLEAR_FILTER);
    refreshLocalFilter();
};

const refreshLocalFilter = () => {
    filter.value.user = storeFilter.value.user;
    filter.value.userLabel = storeFilter.value.userLabel;
};

onMounted(() => {
    filter.value.user = defaultFilter.value.user;
    filter.value.userLabel = defaultFilter.value.userLabel;
    getUsers();
    getUserLabels();
});
</script>

<template>
    <UiRightPanel v-model="filterOpened" :title="$t('filter.sidebar.title')">
        <template #button>
            <UiButton
                size="md"
                variant="white"
                text-variant="gray-700"
                @click="filterOpened = true"
            >
                <FilterIcon class="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                {{ $t('core.actions.Filter') }}
            </UiButton>
        </template>
        <template #default>
            <form autocomplete="off" class="flex flex-col gap-3">
                <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                    <div class="sm:col-span-5">
                        <label class="block text-sm font-medium text-gray-800">
                            {{ $t('report.agentProductivityPerAgent.filters.userLabel') }}
                        </label>
                        <UiMultiselect
                            name="filter_user_label"
                            v-model="filter.userLabel"
                            label="label"
                            value-prop="id"
                            :options="userLabelOptions"
                            :can-clear="true"
                            :placeholder="t('core.options.All')"
                        />
                    </div>
                </div>
                <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                    <div class="sm:col-span-5">
                        <label class="block text-sm font-medium text-gray-800">
                            {{ $t('report.agentProductivityPerAgent.filters.user') }}
                        </label>
                        <UiMultiselect
                            name="filter_user"
                            v-model="filter.user"
                            label="name"
                            value-prop="id"
                            :options="userOptions"
                            :can-clear="true"
                            :placeholder="t('core.options.All')"
                        />
                    </div>
                </div>
                <div class="mt-4 grid grid-cols-2 place-content-around gap-4">
                    <UiButton variant="white" text-variant="gray-700" @click="clearFilters()">
                        {{ $t('core.actions.Clear') }}
                    </UiButton>
                    <UiButton variant="primary" text-variant="white" @click="applyFilters()">
                        {{ $t('core.actions.Apply') }}
                    </UiButton>
                </div>
            </form>
        </template>
    </UiRightPanel>
</template>
