<script lang="ts" setup>
import { computed, Ref, ref, watch, onMounted } from 'vue';
import store from '@/store';
import { useI18n } from 'vue-i18n';

import { FilterIcon } from '@heroicons/vue/solid';
import {
    BillingReportFilter,
    BillingReportGetters,
    BillingReportActions,
    UserModel,
    CallType,
    CallStatus,
} from '@/types';
import { UserService } from '@/services';
import { toastServiceError } from '@/services/core/notification';

const { t } = useI18n();

const filterOpened = ref(false);

const defaultFilter = computed(() => store.getters[BillingReportGetters.DEFAULT_FILTER]);

const props = defineProps<{
    sources: string[];
    destinations: string[];
}>();

const filter: Ref<BillingReportFilter> = ref({
    user: defaultFilter.value.user,
    sourceNumber: defaultFilter.value.sourceNumber,
    destinationNumber: defaultFilter.value.destinationNumber,
    status: defaultFilter.value.status,
    type: defaultFilter.value.type,
});
const storeFilter = computed(() => store.getters[BillingReportGetters.FILTER]);

const userOptions: Ref<UserModel[]> = ref([]);
const getUsers = async () => {
    try {
        const response = await UserService.getAll<UserModel[]>({
            params: {
                isAgent: true,
                page: 1,
                per_page: 100,
            },
        });
        userOptions.value = response.data;
    } catch (error) {
        toastServiceError(error);
    }
};

function applyFilters() {
    store.dispatch(BillingReportActions.SET_FILTER, filter.value);
}

function clearFilters() {
    store.dispatch(BillingReportActions.CLEAR_FILTER);
    refreshLocalFilter();
}

function refreshLocalFilter() {
    filter.value.user = storeFilter.value.user;
    filter.value.sourceNumber = storeFilter.value.sourceNumber;
    filter.value.destinationNumber = storeFilter.value.destinationNumber;
    filter.value.status = storeFilter.value.status;
    filter.value.type = storeFilter.value.type;
}

watch(filterOpened, () => {
    refreshLocalFilter();
});

onMounted(() => {
    getUsers();
});
</script>

<template>
    <UiRightPanel v-model="filterOpened" :title="$t('filter.sidebar.title')">
        <template #button>
            <UiButton
                size="md"
                variant="white"
                text-variant="gray-700"
                @click="filterOpened = true"
            >
                <FilterIcon class="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                {{ $t('core.actions.Filter') }}
            </UiButton>
        </template>
        <template #default>
            <form autocomplete="off" class="flex flex-col gap-3">
                <!-- User Filter -->
                <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                    <div class="sm:col-span-5">
                        <label class="block text-sm font-medium text-gray-800">
                            {{ $t('report.billing.filters.user') }}
                        </label>
                        <UiMultiselect
                            name="filter_user"
                            v-model="filter.user"
                            label="name"
                            value-prop="id"
                            :options="userOptions"
                            :can-clear="true"
                            :placeholder="t('core.options.All')"
                        />
                    </div>
                </div>

                <!-- Source Number Filter -->
                <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                    <div class="sm:col-span-5">
                        <label class="block text-sm font-medium text-gray-800">
                            {{ $t('report.billing.filters.sourceNumber') }}
                        </label>
                        <UiMultiselect
                            name="filter_source_number"
                            v-model="filter.sourceNumber"
                            label="value"
                            value-prop="value"
                            :options="
                                props.sources.map((source) => ({
                                    value: source,
                                    label: source,
                                }))
                            "
                            :can-clear="true"
                            :placeholder="t('core.options.All')"
                        />
                    </div>
                </div>

                <!-- Destination Number Filter -->
                <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                    <div class="sm:col-span-5">
                        <label class="block text-sm font-medium text-gray-800">
                            {{ $t('report.billing.filters.destinationNumber') }}
                        </label>
                        <UiMultiselect
                            name="filter_destination_number"
                            v-model="filter.destinationNumber"
                            label="value"
                            value-prop="value"
                            :options="
                                props.destinations.map((destination) => ({
                                    value: destination,
                                    label: destination,
                                }))
                            "
                            :can-clear="true"
                            :placeholder="t('core.options.All')"
                        />
                    </div>
                </div>

                <!-- Status Filter -->
                <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                    <div class="sm:col-span-5">
                        <label class="block text-sm font-medium text-gray-800">
                            {{ $t('report.billing.filters.status') }}
                        </label>
                        <UiMultiselect
                            name="filter_status"
                            v-model="filter.status"
                            value-prop="value"
                            :options="[
                                {
                                    value: CallStatus.ANSWERED,
                                    label: t('core.options.call.status.answered'),
                                },
                                {
                                    value: CallStatus.NO_ANSWER,
                                    label: t('core.options.call.status.no_answer'),
                                },
                                {
                                    value: CallStatus.BUSY,
                                    label: t('core.options.call.status.busy'),
                                },
                                {
                                    value: CallStatus.DITCHED,
                                    label: t('core.options.call.status.ditched'),
                                },
                            ]"
                            :can-clear="true"
                            :placeholder="t('core.options.All')"
                        />
                    </div>
                </div>

                <!-- Type Filter -->
                <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                    <div class="sm:col-span-5">
                        <label class="block text-sm font-medium text-gray-800">
                            {{ $t('report.billing.filters.type') }}
                        </label>
                        <UiMultiselect
                            name="filter_type"
                            v-model="filter.type"
                            value-prop="value"
                            :options="[
                                {
                                    value: CallType.INBOUND,
                                    label: t('core.options.call.type.inbound'),
                                },
                                {
                                    value: CallType.OUTBOUND,
                                    label: t('core.options.call.type.outbound'),
                                },
                            ]"
                            :can-clear="true"
                            :placeholder="t('core.options.All')"
                        />
                    </div>
                </div>

                <!-- Buttons -->
                <div class="mt-4 grid grid-cols-2 place-content-around gap-4">
                    <UiButton variant="white" text-variant="gray-700" @click="clearFilters()">
                        {{ $t('core.actions.Clear') }}
                    </UiButton>
                    <UiButton variant="primary" text-variant="white" @click="applyFilters()">
                        {{ $t('core.actions.Apply') }}
                    </UiButton>
                </div>
            </form>
        </template>
    </UiRightPanel>
</template>
