// Number utilities
// based on https://github.com/bootstrap-vue/bootstrap-vue/blob/dev/src/utils/number.js

// Converts a value (string) to an integer number
// Assumes radix base 10
export const toInteger = (value: string, defaultValue = NaN) => {
    const integer = parseInt(value, 10);
    return isNaN(integer) ? defaultValue : integer;
};

// Converts a value (string) to a number
export const toFloat = (value: string, defaultValue = NaN) => {
    const float = parseFloat(value);
    return isNaN(float) ? defaultValue : float;
};

// Converts a value (string) to a string
// representation with `precision` digits after the decimal
// Returns the string 'NaN' if the value cannot be converted
export const toFixed = (value: string, precision: string) =>
    toFloat(value).toFixed(toInteger(precision, 0));

export const isPositiveInteger = (value: string) => {
    const number = Number(value);
    const isInteger = Number.isInteger(number);
    const isPositive = number > 0;

    return isInteger && isPositive;
};

export const applyPhoneMask = (value: string): string => {
    value = value.replace(/\D/g, '');

    if (value.startsWith('55800')) {
        return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, '0$2 $3 $4');
    }

    if (value.length === 10) {
        return value.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }
    if (value.length === 11) {
        return value.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    }

    if (value.length === 12 || value.length === 13) {
        const ddi = value.slice(0, 2);
        const ddd = value.slice(2, 4);
        const phoneNumber = value.slice(4);

        let formattedPhone = '';
        if (phoneNumber.length === 8) {
            formattedPhone = phoneNumber.replace(/(\d{4})(\d{4})/, '$1-$2');
        } else if (phoneNumber.length === 9) {
            formattedPhone = phoneNumber.replace(/(\d{5})(\d{4})/, '$1-$2');
        } else {
            formattedPhone = phoneNumber;
        }

        if (ddi === '55') {
            return `(${ddd}) ${formattedPhone}`;
        } else {
            return `+${ddi} (${ddd}) ${formattedPhone}`;
        }
    }

    return value;
};
