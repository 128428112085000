import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, onMounted, type Ref } from 'vue';
import { useRoute } from 'vue-router';
import { ChevronRightIcon } from '@heroicons/vue/outline';

import { useFilterStore } from '@/stores';
import { can } from '@/composables/useAuth';

import { type NavigationItem, type LevelModel } from '@/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'TemplateNavigation',
  props: {
    items: {
        type: Array as () => NavigationItem[],
        default: () => [],
    },
},
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const filterStore = useFilterStore();

const props = __props;

const filteredNavigationItems: Ref<NavigationItem[]> = ref([]);

const levelFilter = computed<LevelModel | null>(() => filterStore.level);

const isActive = (item: NavigationItem) => {
    if (item.name === 'Home') {
        return route.name === item.name;
    } else {
        return route.matched.some(({ name }) => {
            if (typeof name !== 'string') return false;
            return name === item.name || item.activateBy?.includes(name);
        });
    }
};

const hasActiveChildren = (item: NavigationItem) =>
    (item.children || []).some((i: NavigationItem) => isActive(i));

const toggleVisibility = (item: NavigationItem) => {
    item.isOpen = !item.isOpen;
};

onMounted(() => {
    filteredNavigationItems.value = props.items
        .map((item) => {
            return !item.children?.length
                ? item
                : {
                      ...item,
                      children: item.children.filter(
                          (child) => !child.permission || can(child.permission),
                      ),
                  };
        })
        .filter((item, index) => {
            if (item.localeKey === 'sideNavigation.meet.group' && !levelFilter.value?.meeting) {
                return false;
            }
            return props.items[index].children?.length === undefined
                ? !item.permission || can(item.permission)
                : item.children?.length;
        });
});

const __returned__ = { route, filterStore, props, filteredNavigationItems, levelFilter, isActive, hasActiveChildren, toggleVisibility, get ChevronRightIcon() { return ChevronRightIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})