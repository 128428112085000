import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, ref, Ref, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { required, maxLength } from '@vuelidate/validators';

import { toast, toastServiceError } from '@/services/core/notification';
import { NumberService, LevelService, ConnectFlowService } from '@/services';
import { goBack } from '@/composables/useNavigation';
import { useUiStore } from '@/stores';
import { applyPhoneMask } from '@/utils/number';

import {
    ConnectFlowModel,
    DefaultServiceResult,
    LevelModel,
    NumberFormState,
    NumberModel,
    Permission,
} from '@/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'NumberFormPage',
  setup(__props, { expose: __expose }) {
  __expose();

const formState = reactive<NumberFormState>({
    levelId: undefined,
    connectFlowId: undefined,
    mediaLabel: undefined,
});
const number = ref<NumberModel>();
const levels = ref<LevelModel[]>([]);
const connectFlows = ref<ConnectFlowModel[]>([]);
const isInitialLoad = ref(true);

const rules = computed(() => ({
    levelId: { required },
    connectFlowId: {},
    mediaLabel: { maxLength: maxLength(250) },
}));

const route = useRoute();
const uiStore = useUiStore();
const v$: Ref<Validation<ValidationArgs<NumberFormState>>> = useVuelidate(rules, formState);

watch(
    () => formState.levelId,
    async (newVal, oldVal) => {
        if (!isInitialLoad.value && newVal !== oldVal) {
            formState.connectFlowId = null;
        }
        await fetchConnectFlows();
    },
);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchNumber = async (id: number) => {
    try {
        setLoading(true);
        const { data } = await NumberService.get<NumberModel>(id);
        number.value = data;
        formState.levelId = data.level.id;
        formState.connectFlowId = data.connectFlowId;
        formState.mediaLabel = data.mediaLabel;
    } catch (err) {
        toastServiceError(err);
    } finally {
        setLoading(false);
    }
};

const fetchLevels = async () => {
    try {
        setLoading(true);
        const { data } = await LevelService.getAll<LevelModel[]>({
            params: { page: 1, per_page: 100, isActive: true },
        });
        levels.value = data;
    } finally {
        setLoading(false);
    }
};

const fetchConnectFlows = async () => {
    if (formState.levelId) {
        try {
            const { data } = await ConnectFlowService.getAll<ConnectFlowModel[]>({
                params: {
                    page: 1,
                    per_page: 100,
                    level_id: formState.levelId,
                    isActive: true,
                },
            });
            connectFlows.value = data;
        } catch (err) {
            toastServiceError(err);
        }
    }
};

const onSubmit = async () => {
    const isFormCorrect = await v$.value.$validate();
    if (!isFormCorrect) return;

    try {
        setLoading(true);
        const id = number.value?.id as number;
        const { data } = await NumberService.update<DefaultServiceResult>(id, formState);
        toast.success(data.message);
        v$.value.$reset();
        goBack({ name: 'ViewNumberById', params: { id } });
    } catch (err) {
        toastServiceError(err);
    } finally {
        setLoading(false);
    }
};

onMounted(async () => {
    await fetchNumber(Number(route.params.id));
    await fetchLevels();

    if (formState.levelId) {
        await fetchConnectFlows();
    }

    isInitialLoad.value = false;
});

const __returned__ = { formState, number, levels, connectFlows, isInitialLoad, rules, route, uiStore, v$, setLoading, fetchNumber, fetchLevels, fetchConnectFlows, onSubmit, get applyPhoneMask() { return applyPhoneMask }, get Permission() { return Permission } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})