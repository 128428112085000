<script lang="ts" setup>
import dbounce from 'debounce';
import { SearchIcon } from '@heroicons/vue/solid';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
    modelValue: {
        type: String,
    },
    debounce: {
        type: Number,
        default: 2000,
    },
    placeholder: {
        type: String,
    },
    class: {
        type: String,
    },
});

const { t } = useI18n();

const emit = defineEmits(['update:modelValue']);

const placeholderText = computed(() => {
    if (props.placeholder !== undefined) {
        return props.placeholder;
    }
    return t('core.actions.Search');
});

const updateValue = (value: string) => {
    emit('update:modelValue', value);
};
</script>

<template>
    <label for="search-field" class="sr-only">
        {{ $t('core.actions.Search') }}
    </label>
    <div class="relative w-full text-gray-400 focus-within:text-gray-600" :class="props.class">
        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2 pr-5">
            <SearchIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
        </div>
        <input
            name="search-field"
            id="search-field"
            class="h-full w-full border-transparent bg-transparent py-2 pl-8 pr-3 text-base text-gray-800 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0"
            :placeholder="placeholderText"
            type="search"
            :value="props.modelValue"
            @input="
                dbounce(() => {
                    updateValue(($event.target as HTMLInputElement).value);
                }, props.debounce)()
            "
        />
    </div>
</template>
