import {
    CallTimeRange,
    CallStatus,
    CallType,
    UserLabelModel,
    NumberModel,
    UserModel,
    MultiSelectOption,
} from '@/types';

export interface ContactCenterPerDayReportFilter {
    type: MultiSelectOption<CallType> | null;
    status?: MultiSelectOption<CallStatus> | null;
    user?: UserModel | null;
    number?: NumberModel | null;
    serviceTimeRange?: MultiSelectOption<CallTimeRange> | null;
    userLabel: UserLabelModel | null;
}
export interface ContactCenterPerDayReportState {
    filter: ContactCenterPerDayReportFilter;
}

export enum ContactCenterPerDayReportMutations {
    SET_FILTER = 'contactCenterPerDayReport_setFilter',
    CLEAR_FILTER = 'contactCenterPerDayReport_clearFilter',
}

export enum ContactCenterPerDayReportActions {
    SET_FILTER = 'contactCenterPerDayReport_setFilter',
    CLEAR_FILTER = 'contactCenterPerDayReport_clearFilter',
}

export enum ContactCenterPerDayReportGetters {
    DEFAULT_FILTER = 'contactCenterPerDayReport_defaultFilter',
    FILTER = 'contactCenterPerDayReport_filter',
    ACTIVE_FILTERS = 'contactCenterPerDayReport_activeFilters',
}
