/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    NumberState,
    NumberFilter,
    NumberMutations,
    NumberActions,
    NumberGetters,
    SortMenuItem,
    FilterItem,
} from '@/types';

const getDefaultState = () => {
    return {
        search: null,
        sort: [],
        page: 1,
        filter: {
            hasConnectFlow: null,
            state: null,
            city: null,
        },
    } as NumberState;
};

const state: NumberState = getDefaultState();

const mutations: MutationTree<NumberState> = {
    [NumberMutations.SET_SEARCH]: (state, search: string) => (state.search = search),
    [NumberMutations.SET_SORT]: (state, sort: SortMenuItem[]) => (state.sort = sort),
    [NumberMutations.SET_PAGE]: (state, page: number) => (state.page = page),
    [NumberMutations.SET_FILTER](state, payload: NumberFilter) {
        state.filter.hasConnectFlow = payload.hasConnectFlow;
        state.filter.state = payload.state;
        state.filter.city = payload.city;
    },
    [NumberMutations.CLEAR_FILTER](state, item?: FilterItem) {
        switch (item?.field) {
            case 'hasConnectFlow':
                state.filter.hasConnectFlow = getDefaultState().filter.hasConnectFlow;
                break;
            case 'state':
                state.filter.state = getDefaultState().filter.state;
                break;
            case 'city':
                state.filter.city = getDefaultState().filter.city;
                break;
            default:
                state.filter.hasConnectFlow = getDefaultState().filter.hasConnectFlow;
                state.filter.state = getDefaultState().filter.state;
                state.filter.city = getDefaultState().filter.city;
        }
    },
};

const actions: ActionTree<NumberState, any> = {
    [NumberActions.SET_SEARCH]: ({ commit }, search: string) =>
        commit(NumberMutations.SET_SEARCH, search),
    [NumberActions.SET_SORT]: ({ commit }, sort: SortMenuItem[]) =>
        commit(NumberMutations.SET_SORT, sort),
    [NumberActions.SET_PAGE]: ({ commit }, page: number) => commit(NumberMutations.SET_PAGE, page),
    [NumberActions.SET_FILTER]({ commit }, payload: NumberFilter) {
        commit(NumberMutations.SET_FILTER, payload);
    },
    [NumberActions.CLEAR_FILTER]({ commit }, item?: FilterItem) {
        commit(NumberMutations.CLEAR_FILTER, item);
    },
};

const getters: GetterTree<NumberState, any> = {
    [NumberGetters.SEARCH]: (state): string | null => state.search,
    [NumberGetters.SORT]: (state): SortMenuItem[] => state.sort,
    [NumberGetters.PAGE]: (state): number => state.page,
    [NumberGetters.DEFAULT_FILTER]: (): NumberFilter => getDefaultState().filter,
    [NumberGetters.FILTER]: (state): NumberFilter => state.filter,
    [NumberGetters.ACTIVE_FILTERS](state): FilterItem[] {
        const filters: Array<FilterItem> = [];

        if (state.filter.hasConnectFlow !== null) {
            filters.push({
                field: 'hasConnectFlow',
                value: state.filter.hasConnectFlow.value,
                label: state.filter.hasConnectFlow.label,
            });
        }

        if (state.filter.state !== null) {
            filters.push({
                field: 'state',
                value: state.filter.state.value,
                label: state.filter.state.label,
            });
        }

        if (state.filter.city !== null) {
            filters.push({
                field: 'city',
                value: state.filter.city.value,
                label: state.filter.city.label,
            });
        }

        return filters;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
