<script lang="ts" setup>
import { computed, ref, Ref, watch, ComputedRef, onMounted } from 'vue';
import moment from 'moment';
import orderBy from 'lodash.orderby';
import { useI18n } from 'vue-i18n';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import { AgentProductivityPerAgentReportService } from '@/services';

import {
    AgentProductivityReportActions,
    AgentProductivityReportGetters,
    CallType,
    AgentProductivityReportFilter,
    AgentProductivityReportServiceGetInfoParams,
    AgentProductivityReportServiceGetInfoResult,
    CallsStats,
    ChartDataItem,
    DataItem,
    FilterItem,
    LevelModel,
    UserAgentCallsStats,
} from '@/types';

import { BarChartConfig } from '@/types/configs/ChartConfig';
import { AgentProductivityPerAgentReportFilters } from '@/views/pages/reports/agent-productivity-per-agent/components';

const reportInfo: Ref<AgentProductivityReportServiceGetInfoResult | null> = ref(null);

const levelFilter = computed<LevelModel | null>(() => filterStore.level);
const dateRange = computed<Array<Date>>({
    get: () => filterStore.dateRange,
    set: (dateRange: Array<Date>) => filterStore.setDateRange(dateRange),
});
const filter = computed<AgentProductivityReportFilter>(
    () => store.getters[AgentProductivityReportGetters.FILTER],
);
const activeFilters: ComputedRef<FilterItem[]> = computed(
    () => store.getters[AgentProductivityReportGetters.ACTIVE_FILTERS],
);

const { t } = useI18n();
const filterStore = useFilterStore();
const uiStore = useUiStore();

const headerStats = computed<DataItem<string>[]>(() => {
    const callsStats: CallsStats | undefined = reportInfo.value?.stats;
    if (!callsStats) return [];

    return [
        { name: 'totalCalls', value: callsStats.total.toString() },
        { name: 'inboundCalls', value: callsStats.type[CallType.INBOUND].toString() },
        { name: 'outboundCalls', value: callsStats.type[CallType.OUTBOUND].toString() },
        { name: 'internalCalls', value: callsStats.type[CallType.INTERNAL].toString() },
        { name: 'uniqueNumbers', value: callsStats.uniqueNumbers.toString() },
        {
            name: 'averageServiceTime',
            value: moment.utc((callsStats.averageServiceTime || 0) * 1000).format('HH:mm:ss'),
        },
    ];
});

const xAxisLabelFormatterAsTime = (value: number | string) => {
    return moment.utc((value as number) * 1000).format('HH:mm:ss');
};

const getChart = (
    title: string,
    prop: keyof UserAgentCallsStats,
    color = '#BFDBFE',
    xAxisLabelFormatter: (value: number | string) => string,
): BarChartConfig => {
    const topUsers = orderBy(reportInfo.value?.userAgentCallsStats, prop, 'desc').slice(0, 10);

    const category: string[] = [];
    const serie: ChartDataItem<number[]> = {
        name: title,
        value: [],
        color,
    };

    for (let i = topUsers.length - 1; i >= 0; i--) {
        const user = topUsers[i];
        category.push(user.name);
        serie.value.push(user[prop] as number);
    }

    return {
        title,
        category,
        series: [serie],
        xAxisLabelFormatter,
    };
};

const charts = computed<BarChartConfig[]>(() => {
    return [
        getChart(
            t('report.agentProductivityPerAgent.serviceTimePerUserBarChart.title'),
            'averageServiceTime',
            '#BFDBFE',
            xAxisLabelFormatterAsTime,
        ),
        getChart(
            t('report.agentProductivityPerAgent.timeAvailablePerUserBarChart.title'),
            'averageTimeAvailable',
            '#FBCFE8',
            xAxisLabelFormatterAsTime,
        ),
        getChart(
            t('report.agentProductivityPerAgent.durationPerUserBarChart.title'),
            'averageDuration',
            '#A5F3FC',
            xAxisLabelFormatterAsTime,
        ),
        getChart(
            t('report.agentProductivityPerAgent.totalIdleTimePerUserBarChart.title'),
            'averageTotalIdleTime',
            '#BAE6FD',
            xAxisLabelFormatterAsTime,
        ),
        getChart(
            t('report.agentProductivityPerAgent.idleTimePerUserBarChart.title'),
            'averageIdleTime',
            '#F5D0FE',
            xAxisLabelFormatterAsTime,
        ),
    ];
});

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchReportData = async () => {
    const params: AgentProductivityReportServiceGetInfoParams = {
        startDate: moment.utc(dateRange.value[0]).format('YYYY-MM-DD'),
        endDate: moment.utc(dateRange.value[1]).format('YYYY-MM-DD'),
        user: filter.value.user?.id || null,
        userLabel: filter.value.userLabel?.id || null,
    };

    try {
        setLoading(true);
        const response = await AgentProductivityPerAgentReportService.getInfo({ params });
        reportInfo.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveFilter = (filter: FilterItem) => {
    store.dispatch(AgentProductivityReportActions.CLEAR_FILTER, filter.field);
};

watch([levelFilter, dateRange, activeFilters], () => {
    fetchReportData();
});

onMounted(() => {
    fetchReportData();
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('report.agentProductivityPerAgent.title') }}
                </h1>
            </template>
            <template #actions>
                <AgentProductivityPerAgentReportFilters />
                <UiDatePicker v-model="dateRange" range multiCalendars class="ml-4" />
            </template>
        </UiPageHeader>

        <div
            v-if="activeFilters.length"
            class="mt-4 flex bg-gray-50 px-4 py-4 shadow ring-1 ring-gray-300 sm:p-6 md:rounded"
        >
            <template :key="filter" v-for="filter in activeFilters">
                <UiBadge removable @remove="handleRemoveFilter(filter)">
                    {{ $t(`report.agentProductivityPerAgent.filters.activeTag.${filter.field}`) }}:
                    {{ filter.label }}
                </UiBadge>
            </template>
        </div>

        <dl
            v-if="headerStats"
            class="mt-4 grid grid-cols-2 gap-4"
            :class="['sm:grid-cols-' + headerStats.length]"
        >
            <UiPanel v-for="stat in headerStats" :key="stat.name">
                <dt class="text-center text-xs font-medium text-gray-500">
                    {{ $t('report.contactCenterPerAgent.stats.' + stat.name) }}
                </dt>
                <dd class="mt-1 text-center text-2xl font-semibold text-gray-800">
                    {{ stat.value }}
                </dd>
            </UiPanel>
        </dl>

        <div v-if="reportInfo" class="mt-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
            <CallTypePerUserBarChart
                :title="t('report.agentProductivityPerAgent.callTypePerUserBarChart.title')"
                :data="reportInfo.userAgentCallsStats"
                class="col-span-2 h-[300px] md:col-span-1"
            />

            <CallStatusPerUserBarChart
                :title="t('report.agentProductivityPerAgent.callStatusPerUserBarChart.title')"
                :data="reportInfo.userAgentCallsStats"
                class="col-span-2 h-[300px] md:col-span-1"
            />

            <BarChart
                v-for="(chart, index) in charts"
                :key="index"
                :title="chart.title"
                :categoryData="chart.category"
                :seriesData="chart.series"
                :xAxisLabelFormatter="chart.xAxisLabelFormatter"
                class="col-span-2 h-[300px] md:col-span-1"
            />
        </div>
    </div>
</template>
