import { CallModel } from '@/types';
import BaseService from '@/services/api/BaseService';
import { AxiosRequestConfig } from 'axios';

class CallReportService extends BaseService<CallModel> {
    getInfo<CallsStats>(config?: AxiosRequestConfig) {
        return this.http.get<CallsStats>(`${this.route}`, config);
    }
}

export default new CallReportService('reports/calls');
