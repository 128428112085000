import { ListFilters, MultiSelectOption } from '@/types';

export interface ConnectFlowFilter {
    isActive: MultiSelectOption<boolean> | null;
}
export interface ConnectFlowState extends ListFilters {
    filter: ConnectFlowFilter;
}

export enum ConnectFlowMutations {
    SET_SEARCH = 'connectFlow_setSearch',
    SET_SORT = 'connectFlow_setSort',
    SET_PAGE = 'connectFlow_setPage',
    SET_FILTER = 'connectFlow_setFilter',
    CLEAR_FILTER = 'connectFlow_clearFilter',
}

export enum ConnectFlowActions {
    SET_SEARCH = 'connectFlow_setSearch',
    SET_SORT = 'connectFlow_setSort',
    SET_PAGE = 'connectFlow_setPage',
    SET_FILTER = 'connectFlow_setFilter',
    CLEAR_FILTER = 'connectFlow_clearFilter',
}

export enum ConnectFlowGetters {
    SEARCH = 'connectFlow_search',
    SORT = 'connectFlow_sort',
    PAGE = 'connectFlow_page',
    FILTER = 'connectFlow_filter',
    DEFAULT_FILTER = 'connectFlow_defaultFilter',
    ACTIVE_FILTERS = 'connectFlow_activeFilters',
}
