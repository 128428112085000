import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-2 flex items-center space-x-2 space-y-2 py-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiTextInput = _resolveComponent("UiTextInput")!
  const _component_UiActionButton = _resolveComponent("UiActionButton")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UiTextInput, {
      modelValue: $setup.v$.email.$model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.v$.email.$model) = $event)),
      name: "email",
      type: "text",
      label: _ctx.$t('contact.form.labels.email'),
      class: "flex-1",
      disabled: !!$setup.formState?.id,
      errors: $setup.v$.email.$errors,
      onBlur: $setup.v$.email.$touch
    }, null, 8 /* PROPS */, ["modelValue", "label", "disabled", "errors", "onBlur"]),
    _withDirectives((_openBlock(), _createBlock(_component_UiActionButton, {
      variant: "danger",
      class: "pt-4",
      onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.emit('remove', $props.index)))
    }, {
      default: _withCtx(() => [
        _createVNode($setup["TrashIcon"], { class: "h-5 w-5" })
      ]),
      _: 1 /* STABLE */
    })), [
      [_directive_tippy, _ctx.$t('contact.form.actions.removeEmail')]
    ])
  ]))
}