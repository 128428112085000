<script lang="ts" setup>
import { computed, reactive, ref, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';

import { useFilterStore, useUiStore } from '@/stores';
import { can } from '@/composables/useAuth';
import { goBack } from '@/composables/useNavigation';

import {
    required,
    minLength,
    maxLength,
    email,
    sameAs,
    containsUppercase,
    containsLowercase,
    containsNumber,
    containsSpecial,
} from '@/utils/validators';

import { toast, toastServiceError } from '@/services/core/notification';
import { UserService, LevelService, RoleService, NumberService } from '@/services';

import {
    DefaultServiceResult,
    FormAction,
    LevelModel,
    NumberModel,
    Permission,
    RoleModel,
    UserFormState,
    UserModel,
    UserServiceCreateResult,
} from '@/types';

import { DesktopComputerIcon, DeviceMobileIcon } from '@heroicons/vue/solid';
import SVGDeviceExternal from '@/components/svg/SVGDeviceExternal.vue';
import SVGChromeLogo from '@/components/svg/SVGChromeLogo.vue';
import UserFormExtension from '@/views/pages/user/components/UserFormExtension.vue';
import UserFormDevice from '@/views/pages/user/components/UserFormDevice.vue';

const route = useRoute();
const { t } = useI18n();
const filterStore = useFilterStore();
const uiStore = useUiStore();

const props = defineProps({
    action: { type: String, default: 'view' },
});

const formState = reactive<UserFormState>({
    name: null,
    email: '',
    levels: [],
    roles: [],
    isActive: true,
    hasMeeting: false,
    standardLevelId: null,
    devices: {
        mobile: false,
        desktop: false,
        web: false,
        external: false,
    },
    useSmartCallerId: false,
    number: {
        numberId: null,
    },
    extension: {
        id: null,
        levelId: null,
        label: null,
        number: {
            numberId: null,
        },
    },
});

const isLoading = ref(false);
const externalConfig = ref();
const numbersRaw = ref<NumberModel[]>([]);
const levels = ref<LevelModel[]>([]);
const roles = ref<RoleModel[]>([]);

const levelFilter = computed<LevelModel | null>(() => filterStore.level);
const levelHasMeeting = computed(() => levelFilter.value?.meeting || false);
const noDevice = computed(() => Object.values(formState.devices).every((v) => !v));

const selectedLevels = computed(() =>
    levels.value.filter((lvl) => formState.levels.includes(lvl.id)),
);

const rules = computed(() => {
    const r = {
        name: {
            required,
            maxLength: maxLength(500),
        },
        email: {
            required,
            email,
            maxLength: maxLength(250),
        },
        levels: { required },
        roles: { required },
        standardLevelId: { required },
        isActive: { required },
        ...(props.action === FormAction.CREATE && {
            password: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(250),
                containsUppercase,
                containsLowercase,
                containsNumber,
                containsSpecial,
            },
            confirmPassword: {
                required,
                sameAsPassword: sameAs(formState.password, t('user.labels.password')),
            },
        }),
    };

    return r;
});

const v$ = useVuelidate(rules, formState);

const hasSmartCallerId = computed(() =>
    formState.standardLevelId
        ? getLevelNumbers(formState.standardLevelId).some((n) => n.isSmartCallerId)
        : false,
);

const setLoading = (loading: boolean) => {
    isLoading.value = loading;
    uiStore.setIsLoading(loading);
};

const fetchLevels = async () => {
    try {
        const response = await LevelService.getAll<LevelModel[]>({ params: { is_active: true } });
        levels.value = response.data ?? [];

        if (levelFilter.value && !levelFilter.value.root) {
            levels.value = [levelFilter.value];
            formState.levels = [levelFilter.value.id];
            formState.standardLevelId = levelFilter.value.id;
        }
    } catch (error) {
        toastServiceError(error);
    }
};

const fetchRoles = async () => {
    try {
        const response = await RoleService.getAll<RoleModel[]>();
        roles.value = response.data ?? [];
    } catch (error) {
        toastServiceError(error);
    }
};

const fetchUser = async (id: number) => {
    setLoading(true);
    try {
        const response = await UserService.get<UserModel>(id);
        const {
            name,
            email,
            levels,
            roles,
            isActive,
            standardLevelId,
            hasMeeting,
            number,
            extension,
            devices,
            deviceExternal,
            useSmartCallerId,
        } = response.data;

        Object.assign(formState, {
            id,
            name,
            email,
            levels: levels?.map((l) => l.id) || [],
            roles: roles?.map((l) => l.id) || [],
            isActive,
            standardLevelId: standardLevelId ?? null,
            hasMeeting,
            devices,
            useSmartCallerId: useSmartCallerId || false,
            number: number ?? { id: null, levelId: null, numberId: null },
            extension: extension ?? {
                id: null,
                levelId: standardLevelId,
                label: null,
                number: null,
            },
        });

        if (deviceExternal) {
            externalConfig.value = {
                hostname: deviceExternal.hostname,
                sipUser: deviceExternal.sipUser,
                sipPassword: deviceExternal.sipPassword,
            };
        }
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const fetchNumbers = async () => {
    setLoading(true);
    try {
        const response = await NumberService.getAll<NumberModel[]>({
            params: { levels: formState.levels },
        });
        numbersRaw.value = response.data ?? [];
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const getLevelNumbers = (levelID: number | null) => {
    return numbersRaw.value.filter((n) => n.level?.id === levelID);
};

const onSubmit = async () => {
    const isValid = await v$.value.$validate();
    if (!isValid) return;

    setLoading(true);
    try {
        const id = Number(route.params.id);
        const serviceCall =
            props.action === FormAction.EDIT
                ? UserService.update<DefaultServiceResult, UserFormState>(id, formState)
                : UserService.create<UserServiceCreateResult, UserFormState>(formState);

        const response = await serviceCall;
        toast.success(response.data.message);
        v$.value.$reset();
        goBack({ name: 'ViewUserById', params: { id } });
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

watch(
    () => formState.levels,
    () => {
        fetchNumbers();
    },
);

watch(
    () => formState.standardLevelId,
    (newValue) => {
        if (formState.number) formState.number.levelId = newValue;
        if (formState.extension) formState.extension.levelId = newValue;
    },
);

watch(
    () => formState.useSmartCallerId,
    (newValue) => {
        if (newValue && formState.number) formState.number.numberId = null;
    },
);

onMounted(() => {
    if (can(Permission.ASSIGN_LEVEL_TO_USER)) fetchLevels();
    if (can(Permission.ASSIGN_ROLE_TO_USER)) fetchRoles();
    if (props.action === FormAction.EDIT) fetchUser(Number(route.params.id));
});
</script>

<template>
    <form class="space-y-4 p-4 sm:p-6 lg:p-8" autocomplete="off" @submit.prevent="onSubmit">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('user.' + props.action + '.title') }}
                </h1>
            </template>
            <template #actions>
                <div class="flex items-center space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ListUser' }" />
                    <UiButton
                        v-if="
                            (props.action == FormAction.CREATE && $can(Permission.CREATE_USER)) ||
                            (props.action == FormAction.EDIT && $can(Permission.EDIT_USER))
                        "
                        type="submit"
                        variant="primary"
                        text-variant="white"
                    >
                        {{ $t('core.actions.Save') }}
                    </UiButton>
                </div>
            </template>
        </UiPageHeader>
        <UiPanel>
            <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
                <UiTextInput
                    v-model="formState.name"
                    name="name"
                    type="text"
                    :label="$t('user.labels.name')"
                    :errors="v$.name.$errors"
                    :required="v$.name.required !== undefined"
                    @blur="v$.name.$touch"
                />
                <UiTextInput
                    v-model="formState.email"
                    name="email"
                    type="text"
                    :label="$t('user.labels.email')"
                    autocomplete="new-email"
                    :errors="v$.email.$errors"
                    :required="v$.email.required !== undefined"
                    @blur="v$.email.$touch"
                />
                <div v-if="$can(Permission.ASSIGN_ROLE_TO_USER)">
                    <label for="roles" class="mb-2 block text-sm font-medium text-gray-800">
                        {{ $t('user.labels.roles') }}
                        <span class="text-xs">*</span>
                    </label>
                    <UiMultiselect
                        name="roles"
                        id="roles"
                        v-model="formState.roles"
                        :options="roles"
                        :primitive="true"
                        :multiple="true"
                        :can-clear="true"
                        :errors="v$.roles?.$errors"
                        label="name"
                        value-prop="id"
                    />
                </div>
                <div v-else class="hidden md:block"></div>
                <UiTextInput
                    v-if="props.action == FormAction.CREATE"
                    v-model="formState.password"
                    name="password"
                    type="password"
                    :label="$t('user.labels.password')"
                    autocomplete="new-password"
                    :errors="v$.password?.$errors"
                    :required="v$.password?.required !== undefined"
                    @blur="v$.password?.$touch"
                />
                <UiTextInput
                    v-if="props.action == FormAction.CREATE"
                    v-model="formState.confirmPassword"
                    name="confirmPassword"
                    type="password"
                    :label="$t('user.labels.confirmPassword')"
                    :errors="v$.confirmPassword?.$errors"
                    :required="v$.confirmPassword?.required !== undefined"
                    @blur="v$.confirmPassword?.$touch"
                />
                <div v-if="props.action == FormAction.CREATE" class="hidden md:block"></div>
                <div v-if="$can(Permission.ASSIGN_LEVEL_TO_USER)">
                    <label for="levels" class="mb-2 block text-sm font-medium text-gray-800">
                        {{ $t('user.labels.levels') }}
                        <span class="text-xs">*</span>
                    </label>
                    <UiMultiselect
                        name="levels"
                        id="levels"
                        v-model="formState.levels"
                        :options="levels"
                        :primitive="true"
                        :multiple="true"
                        :can-clear="true"
                        :errors="v$.levels?.$errors"
                        label="name"
                        value-prop="id"
                    />
                </div>
                <div>
                    <label for="levels" class="mb-2 block text-sm font-medium text-gray-800">
                        {{ $t('user.labels.standardLevel') }}
                        <span class="text-xs">*</span>
                    </label>
                    <UiMultiselect
                        name="levels"
                        id="levels"
                        v-model="formState.standardLevelId"
                        :options="selectedLevels"
                        :errors="v$.standardLevelId?.$errors"
                        primitive
                        label="name"
                        value-prop="id"
                    />
                </div>
                <div class="flex flex-col justify-end pb-1.5">
                    <UiCheckboxInput
                        v-model="formState.isActive"
                        name="isActive"
                        :label="$t('user.labels.isActive')"
                        class="h-6"
                    />
                    <UiCheckboxInput
                        v-if="levelHasMeeting"
                        v-model="formState.hasMeeting"
                        name="hasMeeting"
                        :label="$t('user.labels.hasMeeting')"
                        class="h-6 pt-1.5"
                    />
                </div>
            </div>
        </UiPanel>

        <UiPanel>
            <h3 class="mb-4 text-base font-semibold text-gray-800">
                {{ $t('user.labels.devices.title') }}
            </h3>
            <div class="relative grid grid-cols-2 gap-4 lg:grid-cols-4 xl:grid-cols-6">
                <UserFormDevice
                    :label="$t('user.labels.devices.mobile')"
                    v-model="formState.devices.mobile"
                >
                    <template #icon>
                        <DeviceMobileIcon class="mt-6 h-20 w-20" />
                    </template>
                </UserFormDevice>
                <UserFormDevice
                    :label="$t('user.labels.devices.desktop')"
                    v-model="formState.devices.desktop"
                >
                    <template #icon>
                        <DesktopComputerIcon class="mt-6 h-20 w-20" />
                    </template>
                </UserFormDevice>
                <UserFormDevice
                    :label="$t('user.labels.devices.web')"
                    v-model="formState.devices.web"
                >
                    <template #icon>
                        <SVGChromeLogo class="mt-6 h-20 w-20" />
                    </template>
                </UserFormDevice>
                <UserFormDevice
                    :label="$t('user.labels.devices.external')"
                    v-model="formState.devices.external"
                >
                    <template #icon>
                        <SVGDeviceExternal class="mt-6 h-20 w-20" />
                    </template>
                    <template #extra-info v-if="externalConfig">
                        <div
                            class="absolute -right-[215px] bottom-0 mt-6 flex flex-col justify-between rounded border-b-4 border-r-4 border-cyan-600 bg-gray-50 px-4 py-3 text-sm text-gray-600 shadow"
                        >
                            <h3 class="mb-1 text-base font-semibold">Configurações do PABX</h3>
                            <p>
                                <span class="font-semibold">Hostname:</span>
                                {{ externalConfig.hostname }}
                            </p>
                            <p>
                                <span class="font-semibold">Username:</span>
                                {{ externalConfig.sipUser }}
                            </p>
                            <p>
                                <span class="font-semibold">Password:</span>
                                {{ externalConfig.sipPassword }}
                            </p>
                        </div>
                    </template>
                </UserFormDevice>
            </div>
            <div v-if="!noDevice" class="relative mt-5 grid grid-cols-2 gap-4 md:grid-cols-12">
                <UserFormExtension v-model="formState.extension" :devices="formState.devices" />
                <div class="col-span-3">
                    <label for="level" class="block text-sm font-medium text-gray-800">
                        {{ $t('user.labels.number') }}
                    </label>
                    <UiMultiselect
                        v-if="formState.number"
                        :name="`number`"
                        v-model="formState.number.numberId"
                        :placeholder="$t('core.random')"
                        :options="getLevelNumbers(formState.standardLevelId)"
                        :primitive="true"
                        :multiple="false"
                        :can-clear="true"
                        :disabled="noDevice || formState.useSmartCallerId"
                        label="number"
                        value-prop="id"
                    />
                    <div v-if="hasSmartCallerId" class="mt-2">
                        <UiCheckboxInput
                            v-model="formState.useSmartCallerId"
                            :label="$t('user.labels.useSmartCallerId')"
                            name="useSmartCallerId"
                        />
                    </div>
                </div>
            </div>
        </UiPanel>
        <UiCheckDirtyBeforeRouteLeave :dirty="v$.$anyDirty" />
    </form>
</template>
