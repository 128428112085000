import { defineComponent as _defineComponent } from 'vue'
import { computed, onMounted, reactive, Ref } from 'vue';
import { useRoute } from 'vue-router';

import { useUiStore } from '@/stores';
import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';

import { required, maxLength } from '@/utils/validators';
import { toast, toastServiceError } from '@/services/core/notification';
import { goBack } from '@/composables/useNavigation';

import ConnectFlowService from '@/services/api/ConnectFlowService';

import {
    ConnectFlowFormState,
    ConnectFlowModel,
    ConnectFlowServiceCreateResult,
    DefaultServiceResult,
    FormAction,
    Permission,
} from '@/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowFormPage',
  props: {
    action: {
        type: String,
        default: 'view',
    },
},
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const uiStore = useUiStore();

const props = __props;

const formState = reactive<ConnectFlowFormState>({
    name: null,
    isActive: true,
});

const rules = computed<ValidationArgs<ConnectFlowFormState>>(() => ({
    name: {
        required,
        maxLength: maxLength(500),
    },
    isActive: {
        required,
    },
}));

const v$: Ref<Validation<ValidationArgs<ConnectFlowFormState>>> = useVuelidate(rules, formState);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchConnectFlow = async () => {
    if (props.action !== FormAction.EDIT) return;

    const id = Number(route.params.id);

    try {
        setLoading(true);
        const response = await ConnectFlowService.get<ConnectFlowModel>(id);
        const { name, isActive } = response.data;
        formState.name = name;
        formState.isActive = isActive;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const onSubmit = async () => {
    const isFormCorrect = await v$.value.$validate();
    if (!isFormCorrect) return;

    setLoading(true);

    const handleServiceSuccess = (message: string, id: number) => {
        toast.success(message);
        v$.value.$reset();
        goBack({
            name: 'ViewConnectFlowById',
            params: { id },
        });
    };

    try {
        if (props.action === FormAction.EDIT) {
            const id = Number(route.params.id);
            const response = await ConnectFlowService.update<
                DefaultServiceResult,
                ConnectFlowFormState
            >(id, formState);
            handleServiceSuccess(response.data.message, id);
        } else {
            const response = await ConnectFlowService.create<
                ConnectFlowServiceCreateResult,
                ConnectFlowFormState
            >(formState);
            handleServiceSuccess(response.data.message, response.data.result.id);
        }
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

onMounted(async () => {
    await fetchConnectFlow();
});

const __returned__ = { route, uiStore, props, formState, rules, v$, setLoading, fetchConnectFlow, onSubmit, get FormAction() { return FormAction }, get Permission() { return Permission } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})