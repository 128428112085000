import { createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "flex w-full flex-col" }
const _hoisted_3 = { class: "text-sm font-semibold text-gray-600" }
const _hoisted_4 = { class: "text-[10px] text-gray-500" }
const _hoisted_5 = ["name", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative flex w-full items-center gap-3 rounded border-0 p-3 text-gray-800 shadow ring-1 ring-inset transition", [
            $setup.disabledClass,
            $setup.hasErrors ? 'ring-danger focus:ring-danger' : 'focus:ring-primary ring-gray-300',
        ]])
  }, [
    _createCommentVNode(" Upload/Label Section "),
    _createElementVNode("label", {
      for: $setup.props.name,
      class: _normalizeClass(["flex flex-1 cursor-pointer items-center gap-4", $setup.props.disabled && 'cursor-not-allowed'])
    }, [
      _createVNode($setup["CloudUploadIcon"], { class: "h-8 w-8 text-gray-300" }),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString($setup.file?.name || _ctx.$t('connectFlowAudio.create.fileLabel')), 1 /* TEXT */),
        _createElementVNode("span", _hoisted_4, _toDisplayString($setup.props.textRule), 1 /* TEXT */)
      ]),
      _createElementVNode("input", {
        onChange: $setup.handleAudioUpload,
        name: $setup.props.name,
        type: "file",
        disabled: $setup.props.disabled,
        class: "sr-only"
      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_5)
    ], 10 /* CLASS, PROPS */, _hoisted_1),
    _createCommentVNode(" Remove Button "),
    ($setup.file && !$setup.props.disabled)
      ? (_openBlock(), _createBlock($setup["XIcon"], {
          key: 0,
          class: "h-5 w-5 cursor-pointer text-gray-400 transition hover:text-red-500",
          onClick: $setup.removeFile
        }))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}