import { ContactModel, DefaultServiceResult } from '@/types';

export interface ContactServiceGetAllParams {
    page?: number;
    per_page?: number;
    order_by?: string;
    search?: string;
}

export interface ContactServiceCreateResult extends DefaultServiceResult {
    result: ContactModel;
}
