import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-4 p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "flex items-center text-xl font-semibold text-gray-800" }
const _hoisted_3 = { class: "grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, [
          _createVNode($setup["LinkIcon"], {
            class: "mr-1 h-6 w-6 text-gray-500",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('core.entity.Integrations')), 1 /* TEXT */)
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.configuredIntegrations, (integration, index) => {
        return (_openBlock(), _createBlock($setup["IntegrationWidget"], {
          key: index,
          isActive: integration.isActive,
          logoPath: $setup.integrationInfo[integration.type].logoPath,
          contentText: $setup.integrationInfo[integration.type].description,
          onConfigClick: ($event: any) => ($setup.handleConfigClick(integration)),
          onViewLogsClick: ($event: any) => ($setup.handleViewLogsClick(integration)),
          onActivateClick: ($event: any) => ($setup.handleActivateClick(integration))
        }, null, 8 /* PROPS */, ["isActive", "logoPath", "contentText", "onConfigClick", "onViewLogsClick", "onActivateClick"]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}