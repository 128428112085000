<script lang="ts" setup>
import { computed, PropType, reactive, Ref, watch } from 'vue';
import { requiredIf } from '@/utils/validators';

import { UserService } from '@/services';
import { ExtensionFormState, UserDeviceFormState } from '@/types';
import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
    modelValue: {
        type: Object as PropType<ExtensionFormState>,
        required: true,
        default: () => ({
            id: null,
            levelId: null,
            label: null,
            number: null,
        }),
    },
    devices: {
        type: Object as PropType<UserDeviceFormState>,
        required: true,
    },
});

const noDevice = computed(() => Object.values(props.devices).filter((v) => v).length === 0);

const formState = reactive<ExtensionFormState>(
    props.modelValue || {
        id: null,
        levelId: null,
        label: null,
        number: null,
    },
);

const isTaken = helpers.withAsync(async (value: number, siblings: ExtensionFormState) => {
    if (value === null || value.toString() === '') {
        return true;
    }

    const r = await UserService.checkExtensionNumber(siblings);
    if (r) {
        return true;
    } else {
        throw t('user.error.extensionUsed');
    }
});

const rules = computed<ValidationArgs<ExtensionFormState>>(() => {
    const r: ValidationArgs<ExtensionFormState> = {
        id: {},
        levelId: {},
        label: {},
        number: {
            required: requiredIf(() => {
                return (
                    formState.levelId !== null &&
                    Object.values(props.devices).filter((v) => v).length > 0
                );
            }),
            isUnique: isTaken,
        },
    };

    return r;
});

const v$: Ref<Validation<ValidationArgs<ExtensionFormState>>> = useVuelidate(rules, formState);

const emit = defineEmits(['update:modelValue']);
watch(
    formState,
    () => {
        emit('update:modelValue', formState);
    },
    {
        deep: true,
    },
);
</script>

<template>
    <div class="col-span-3">
        <label class="block text-sm font-medium text-gray-800">
            {{ $t('user.labels.extension.number') }}
        </label>
        <UiTextInput
            :label="false"
            v-model.lazy.number="formState.number"
            name="extension"
            type="number"
            :errors="v$.number.$errors"
            :silentErrors="v$.number.$silentErrors"
            class="col-span-3"
            :disabled="noDevice || modelValue.levelId === null"
        />
    </div>
</template>
