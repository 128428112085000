import { CreatedAudit } from '@/types';

export interface MeetingMessageModel extends CreatedAudit {
    id: number;
    meetingId: number;
    speakerName: string;
    transcript: string;
    deviceExternalId: string;
    messageExternalId: string;
    messageVersion: number;
    version: string;
    isPinned: boolean;
    timestamp: number;
    tags: object[];
}
