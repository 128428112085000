import { defineComponent as _defineComponent } from 'vue'
import { computed, Ref, ref, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import store from '@/store';

import { useFilterStore } from '@/stores';
import { UserService, UserLabelService } from '@/services';
import { toastServiceError } from '@/services/core/notification';

import {
    AgentProductivityPerDayReportFilter,
    AgentProductivityPerDayReportGetters,
    AgentProductivityPerDayReportActions,
    UserModel,
    LevelModel,
    UserLabelModel,
} from '@/types';

import { FilterIcon } from '@heroicons/vue/solid';


export default /*@__PURE__*/_defineComponent({
  __name: 'AgentProductivityPerDayReportFilters',
  setup(__props, { expose: __expose }) {
  __expose();

const userOptions: Ref<UserModel[]> = ref([]);
const userLabelOptions: Ref<UserLabelModel[]> = ref([]);

const levelFilter = computed<LevelModel | null>(() => filterStore.level);
const defaultFilter = computed(
    () => store.getters[AgentProductivityPerDayReportGetters.DEFAULT_FILTER],
);
const filterOpened = ref(false);
const filter: Ref<AgentProductivityPerDayReportFilter> = ref({
    user: defaultFilter.value.user,
    userLabel: defaultFilter.value.userLabel,
});
const storeFilter = computed(() => store.getters[AgentProductivityPerDayReportGetters.FILTER]);

const filterStore = useFilterStore();
const { t } = useI18n();

watch(filterOpened, () => {
    refreshLocalFilter();
});

watch(levelFilter, () => {
    store.dispatch(AgentProductivityPerDayReportActions.CLEAR_FILTER, 'user');
    fetchUsers();
    fetchUserLabels();
});

const fetchUsers = async () => {
    try {
        const response = await UserService.getAll<UserModel[]>({
            params: {
                isAgent: true,
                page: 1,
                per_page: 100,
            },
        });
        userOptions.value = response.data;
    } catch (error) {
        toastServiceError(error);
    }
};

const fetchUserLabels = async () => {
    try {
        const response = await UserLabelService.getAll<UserLabelModel[]>();
        userLabelOptions.value = response.data;
    } catch (error) {
        toastServiceError(error);
    }
};

const applyFilters = () => {
    store.dispatch(AgentProductivityPerDayReportActions.SET_FILTER, filter.value);
};

const clearFilters = () => {
    store.dispatch(AgentProductivityPerDayReportActions.CLEAR_FILTER);
    refreshLocalFilter();
};

const refreshLocalFilter = () => {
    filter.value.user = storeFilter.value.user;
    filter.value.userLabel = storeFilter.value.userLabel;
};

onMounted(() => {
    fetchUsers();
    fetchUserLabels();
});

const __returned__ = { userOptions, userLabelOptions, levelFilter, defaultFilter, filterOpened, filter, storeFilter, filterStore, t, fetchUsers, fetchUserLabels, applyFilters, clearFilters, refreshLocalFilter, get FilterIcon() { return FilterIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})