<script lang="ts" setup>
import { computed, onMounted, ref, WritableComputedRef } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

import store from '@/store';
import { useUiStore } from '@/stores';
import { ConnectFlowService } from '@/services';
import { toast, toastServiceError } from '@/services/core/notification';
import { formatDateAsString } from '@/utils/datetime';

import {
    ConnectFlowModelingGetters,
    ConnectFlowModelingActions,
    ConnectFlowServiceSaveFlowResult,
    ConnectFlowModel,
    EdgeType,
    Permission,
} from '@/types';

import {
    ComponentSelector,
    CustomNode,
    FormClienteInfoEdge,
    FormAwaitableEdge,
} from './components/flow';

import { AwaitableEdge, ClientInfoEdge } from '@/views/pages/connect-flow/components/flow/edges';
import { StartNode } from './components/flow/nodes';

import { VueFlow, useVueFlow } from '@vue-flow/core';
import { Background } from '@vue-flow/background';
import { Controls, ControlButton } from '@vue-flow/controls';
import { MiniMap } from '@vue-flow/minimap';

import { ArrowsExpandIcon } from '@heroicons/vue/outline';

const route = useRoute();
const uiStore = useUiStore();
const { t } = useI18n();

const props = defineProps({
    action: {
        type: String,
        default: 'view',
    },
});

const fullScreen = ref(false);
const showFormClienteInfoEdge = ref(false);
const showFormAwaitableEdge = ref(false);
const triggeredEdge = ref();

const connectFlow: WritableComputedRef<ConnectFlowModel> = computed({
    get: () => store.getters['flow/' + ConnectFlowModelingGetters.CONNECT_FLOW],
    set: (value) => store.dispatch('flow/' + ConnectFlowModelingActions.SET_CONNECT_FLOW, value),
});

const elements = computed({
    get: () => store.getters['flow/' + ConnectFlowModelingGetters.ELEMENTS],
    set: (value) => store.dispatch('flow/' + ConnectFlowModelingActions.SET_ELEMENTS, value),
});

const isAllElementsValid = computed(
    () => store.getters['flow/' + ConnectFlowModelingGetters.IS_ALL_ELEMENTS_VALID],
);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const save = async () => {
    try {
        await setLoading(true);
        const response = await ConnectFlowService.saveFlow<ConnectFlowServiceSaveFlowResult>(
            route.params.id.toString(),
            elements.value,
        );
        connectFlow.value = response.data.connectFlow;
        toast.success(response.data.message);
    } finally {
        await setLoading(false);
    }
};

const publish = async () => {
    try {
        await setLoading(true);
        await ConnectFlowService.saveFlow<ConnectFlowServiceSaveFlowResult>(
            route.params.id.toString(),
            elements.value,
        );

        const response = await ConnectFlowService.publishFlow<ConnectFlowServiceSaveFlowResult>(
            route.params.id.toString(),
            elements.value,
        );

        connectFlow.value = response.data.connectFlow;
        toast.success(response.data.message);
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const { onPaneReady, onEdgeClick } = useVueFlow();

onPaneReady(({ fitView }) => {
    fitView();
});

onEdgeClick((event) => {
    if (event.edge.data.component === EdgeType.AWAITABLE) {
        showFormAwaitableEdge.value = true;
        triggeredEdge.value = event.edge.id;
    }
    if (event.edge.data.component === EdgeType.CLIENT_INFO_EDGE) {
        showFormClienteInfoEdge.value = true;
        triggeredEdge.value = event.edge.id;
    }
});

onMounted(async () => {
    try {
        await setLoading(true);
        await store.dispatch('flow/' + ConnectFlowModelingActions.LOAD_FLOW_FLOW, {
            id: route.params.id,
            historyId: route.params.historyId,
        });
    } finally {
        await setLoading(false);
    }
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader class="mb-5">
            <template #info>
                <h1 class="cursor-pointer text-xl font-semibold text-gray-800">
                    {{ $t('connectFlow.' + props.action + '.title') }}
                </h1>
                <div class="mt-3 flex items-center space-x-2">
                    <p class="text-sm">
                        <span class="font-semibold">{{
                            t('connectFlow.labels.lastPublished')
                        }}</span>
                        {{ formatDateAsString(connectFlow?.updatedAt, 'LLL') }}
                    </p>
                    <span
                        v-if="
                            connectFlow !== undefined &&
                            connectFlow.currentFlowId !== connectFlow.lastAppliedFlowId
                        "
                        class="text-danger-700 rounded border border-red-300 bg-red-100 px-1.5 py-0.5 text-xs font-semibold"
                    >
                        {{ t('connectFlow.labels.notPublished') }}
                    </span>
                </div>
            </template>
            <template v-if="$can(Permission.CREATE_CONNECT_FLOW)" #actions>
                <div class="flex gap-2">
                    <UiBackButton :fallbackTo="{ name: 'ListConnectFlow' }" />
                    <UiButton variant="primary" text-variant="white" @click="save">
                        {{ $t('core.actions.Save') }}
                    </UiButton>
                    <UiButton
                        variant="emerald"
                        text-variant="white"
                        :disabled="!isAllElementsValid"
                        :title="isAllElementsValid ? '' : t('connectFlow.error.hasInvalidNodes')"
                        @click="publish"
                    >
                        {{ $t('core.actions.Publish') }}
                    </UiButton>
                </div>
            </template>
        </UiPageHeader>
        <div
            :style="fullScreen ? '' : 'height: calc(100vh - 170px);'"
            class="rounded bg-gray-200 shadow"
            :class="fullScreen ? 'fixed right-0 top-0 z-40 h-full w-full' : 'h-5/6'"
        >
            <VueFlow
                v-model="elements"
                :default-zoom="0.5"
                :max-zoom="1"
                :min-zoom="0.45"
                :snap-grid="[5, 5]"
                snap-to-grid
                fit-view-on-init
            >
                <template #node-input="node">
                    <StartNode :nodeId="node.id" />
                </template>

                <template #node-default="node">
                    <CustomNode :nodeId="node.id" />
                </template>

                <template #edge-custom="edge">
                    <AwaitableEdge
                        v-bind="edge"
                        v-if="[EdgeType.AWAITABLE, EdgeType.NORMAL].includes(edge.data.component)"
                    />
                    <ClientInfoEdge
                        v-bind="edge"
                        v-if="edge.data.component === EdgeType.CLIENT_INFO_EDGE"
                    />
                </template>

                <Background />
                <MiniMap />
                <Controls>
                    <ControlButton>
                        <ArrowsExpandIcon class="w-6" @click="fullScreen = !fullScreen" />
                    </ControlButton>
                </Controls>
            </VueFlow>
        </div>
        <FormClienteInfoEdge v-model="showFormClienteInfoEdge" :edgeId="triggeredEdge" />
        <FormAwaitableEdge v-model="showFormAwaitableEdge" :edgeId="triggeredEdge" />
        <ComponentSelector />
    </div>
</template>
