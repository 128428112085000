import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, onMounted } from 'vue';
import { useRoute, RouterLink } from 'vue-router';

import { useUiStore } from '@/stores';
import ContactService from '@/services/api/ContactService';
import { toastServiceError } from '@/services/core/notification';
import { ContactModel, Permission } from '@/types';
import { applyPhoneMask } from '@/utils/number';

import { MailIcon, PhoneIcon } from '@heroicons/vue/solid';


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const uiStore = useUiStore();

const contact: Ref<ContactModel | undefined> = ref(undefined);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchContact = async (id: number) => {
    await setLoading(true);
    try {
        const response = await ContactService.get<ContactModel>(id);
        contact.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

onMounted(() => {
    const contactId = Number(route.params.id);
    if (!isNaN(contactId)) fetchContact(contactId);
});

const __returned__ = { route, uiStore, contact, setLoading, fetchContact, get RouterLink() { return RouterLink }, get Permission() { return Permission }, get applyPhoneMask() { return applyPhoneMask }, get MailIcon() { return MailIcon }, get PhoneIcon() { return PhoneIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})