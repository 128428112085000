import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["BaseTemplate"], {
    "navigation-items": $setup.sideNavigationItems,
    "router-key": _ctx.$route.fullPath
  }, {
    headerActions: _withCtx(() => [
      false
        ? _withDirectives((_openBlock(), _createElementBlock("select", {
            key: 0,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
            class: "border-none bg-inherit ring-0 focus:border-none focus:outline-none focus:ring-0",
            onChange: $setup.handleLocaleChange
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$i18n.availableLocales, (locale) => {
              return (_openBlock(), _createElementBlock("option", {
                key: `locale-${locale}`,
                value: locale
              }, _toDisplayString(locale), 9 /* TEXT, PROPS */, _hoisted_1))
            }), 128 /* KEYED_FRAGMENT */))
          ], 544 /* NEED_HYDRATION, NEED_PATCH */)), [
            [_vModelSelect, _ctx.$i18n.locale]
          ])
        : _createCommentVNode("v-if", true),
      _createElementVNode("button", {
        class: "mr-2 hover:text-white",
        onClick: $setup.handleHomeClick
      }, [
        _createVNode($setup["HomeIcon"], {
          class: "h-6 w-6",
          "aria-hidden": "true"
        })
      ]),
      ($setup.showLogout)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "hover:text-white",
            onClick: $setup.handleLogoutClick
          }, [
            _createVNode($setup["LogoutIcon"], {
              class: "h-6 w-6",
              "aria-hidden": "true"
            })
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["navigation-items", "router-key"]))
}