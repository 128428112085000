<script lang="ts" setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { PhoneIcon } from '@heroicons/vue/solid';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import { NumberService } from '@/services';
import { toastServiceError } from '@/services/core/notification';

import {
    FilterItem,
    SortMenuItem,
    NumberModel,
    NumberFilter,
    NumberServiceGetAllParams,
    NumberActions,
    NumberGetters,
} from '@/types';

import { NumberListItem, NumberListSort, NumberListFilters } from '@/views/pages/number/components';

const numbers = ref<NumberModel[]>([]);
const count = ref(0);
const perPage = 10;

const search = computed({
    get: () => store.getters[NumberGetters.SEARCH],
    set: (value: string) => store.dispatch(NumberActions.SET_SEARCH, value),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[NumberGetters.SORT],
    set: (value: SortMenuItem[]) => store.dispatch(NumberActions.SET_SORT, value),
});

const levelFilter = computed(() => filterStore.level);

const currentPage = computed({
    get: () => store.getters[NumberGetters.PAGE],
    set: (value: number) => store.dispatch(NumberActions.SET_PAGE, value),
});

const filter: NumberFilter = store.getters[NumberGetters.FILTER];

const activeFilters = computed<FilterItem[]>(() => store.getters[NumberGetters.ACTIVE_FILTERS]);

const orderBy = computed(() => sortMenuSelected.value.map((s) => s.direction + s.key).join(','));

const route = useRoute();
const router = useRouter();
const filterStore = useFilterStore();
const uiStore = useUiStore();

watch([currentPage], () => {
    fetchNumbers();
});

watch([levelFilter, search, filter, orderBy], () => {
    if (currentPage.value === 1) fetchNumbers();
    else currentPage.value = 1;
});

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getParams = (): NumberServiceGetAllParams => ({
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    hasConnectFlow:
        filter.hasConnectFlow !== null ? (filter.hasConnectFlow.value as boolean) : undefined,
    state: filter.state !== null ? (filter.state.value as string) : undefined,
    city: filter.city !== null ? (filter.city.value as string) : undefined,
    search: search.value,
});

const fetchNumbers = async () => {
    setLoading(true);
    try {
        const { data, headers } = await NumberService.getAll<NumberModel[]>({
            params: getParams(),
        });
        numbers.value = data;
        count.value = parseInt(headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveSortClick = (item: SortMenuItem) => {
    const index = sortMenuSelected.value.indexOf(item);
    if (index > -1) sortMenuSelected.value.splice(index, 1);
};

const handleRemoveFilterClick = (item: FilterItem) => {
    store.dispatch(NumberActions.CLEAR_FILTER, item);
};

onMounted(() => {
    if (route.query.clearStateOnSetup) {
        search.value = '';
        sortMenuSelected.value = [];
        currentPage.value = 1;
        router.replace({ query: {} });
    }

    fetchNumbers();
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="flex items-center text-xl font-semibold text-gray-800">
                    <PhoneIcon class="mr-1 h-6 w-6 text-gray-500" aria-hidden="true" />
                    {{ $t('core.entity.Numbers') }}
                </h1>
            </template>
        </UiPageHeader>

        <div class="space-y-2">
            <UiPanel class="flex px-4 py-2" no-padding>
                <UiListSearch
                    v-model="search"
                    :placeholder="$t('number.filters.search')"
                    class="flex-1"
                />
                <div class="flex items-center space-x-4">
                    <NumberListSort v-model="sortMenuSelected" />
                    <NumberListFilters />
                </div>
            </UiPanel>

            <UiPanel v-if="sortMenuSelected.length || activeFilters.length" class="space-x-2">
                <UiSortMenuBadge
                    v-for="selected in sortMenuSelected"
                    :key="selected.key"
                    :item="selected"
                    @remove="handleRemoveSortClick(selected)"
                />
                <UiBadge
                    v-for="item in activeFilters"
                    :key="`activeFilter${item.field}`"
                    removable
                    @remove="handleRemoveFilterClick(item)"
                >
                    {{ $t(`number.filters.${item.field}.label`) }}: {{ item.label }}
                </UiBadge>
            </UiPanel>

            <div v-if="numbers.length" class="space-y-2">
                <NumberListItem v-for="number in numbers" :key="number.id" :model-value="number" />
            </div>
            <UiPanel v-else class="flex items-center justify-center">
                {{ $t('core.messages.noRecordsFound') }}
            </UiPanel>

            <UiPagination
                v-if="numbers.length"
                v-model="currentPage"
                :count="count"
                :perPage="perPage"
            />
        </div>
    </div>
</template>
