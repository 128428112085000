<script lang="ts" setup>
import { computed, reactive, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useVuelidate } from '@vuelidate/core';
import { useI18n } from 'vue-i18n';

import { useUiStore } from '@/stores';
import { AnalyticsService, WordService } from '@/services';
import { toast, toastServiceError } from '@/services/core/notification';
import { goBack } from '@/composables/useNavigation';

import {
    CallParticipantType,
    DefaultServiceResult,
    FormAction,
    Permission,
    WordModel,
    WordServiceCreateResult,
} from '@/types';
import { WordFormState } from '@/types/form-state/WordFormState';
import { required, maxLength } from '@/utils/validators';

const route = useRoute();
const { t } = useI18n();

const props = defineProps({
    action: { type: String, default: FormAction.CREATE },
});

const uiStore = useUiStore();

const formState = reactive<WordFormState>({
    word: null,
    callParticipant: null,
    isActive: true,
});

const rules = computed(() => ({
    word: { required, maxLength: maxLength(15) },
    callParticipant: { required },
    isActive: { required },
}));

const v$ = useVuelidate(rules, formState);

const participants = computed(() => [
    { value: CallParticipantType.All, label: t(`word.participant.${CallParticipantType.All}`) },
    { value: CallParticipantType.Agent, label: t(`word.participant.${CallParticipantType.Agent}`) },
    {
        value: CallParticipantType.Client,
        label: t(`word.participant.${CallParticipantType.Client}`),
    },
]);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchWord = async (id: number) => {
    setLoading(true);
    try {
        const { data } = await WordService.get<WordModel>(id);
        Object.assign(formState, data);
    } catch (err) {
        toastServiceError(err);
    } finally {
        setLoading(false);
    }
};

const handleServiceSuccess = (message: string, id: number) => {
    toast.success(message);
    v$.value.$reset();
    goBack({ name: 'ViewWordById', params: { id } });
};

const onSubmit = async () => {
    if (!(await v$.value.$validate())) return;

    setLoading(true);
    try {
        if (props.action === FormAction.EDIT) {
            const id = Number(route.params.id);
            const { data } = await WordService.update<DefaultServiceResult>(id, formState);
            handleServiceSuccess(data.message, id);
        } else {
            const { data } = await WordService.create<WordServiceCreateResult>(formState);
            AnalyticsService.trackingAction('NewWord');
            handleServiceSuccess(data.message, data.result.id);
        }
    } catch (err) {
        toastServiceError(err);
    } finally {
        setLoading(false);
    }
};

onMounted(() => {
    if (props.action === FormAction.EDIT) {
        fetchWord(Number(route.params.id));
    }
});
</script>

<template>
    <form class="space-y-4 p-4 sm:p-6 lg:p-8" autocomplete="off" @submit.prevent="onSubmit">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('word.' + props.action + '.title') }}
                </h1>
            </template>
            <template #actions>
                <div class="flex items-center space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ListWord' }" />
                    <UiButton
                        v-if="
                            (props.action == FormAction.CREATE && $can(Permission.CREATE_WORD)) ||
                            (props.action == FormAction.EDIT && $can(Permission.EDIT_WORD))
                        "
                        type="submit"
                        variant="primary"
                        text-variant="white"
                    >
                        {{ $t('core.actions.Save') }}
                    </UiButton>
                </div>
            </template>
        </UiPageHeader>
        <UiPanel>
            <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
                <UiTextInput
                    v-model="v$.word.$model"
                    name="word"
                    type="text"
                    :label="$t('word.labels.word')"
                    :errors="v$.word.$errors"
                    @blur="v$.word.$touch"
                />

                <div>
                    <label
                        for="callParticipants"
                        class="mb-2 block text-sm font-medium text-gray-800"
                    >
                        {{ $t('word.labels.callParticipant') }}
                    </label>
                    <UiMultiselect
                        name="callParticipants"
                        v-model="formState.callParticipant"
                        :primitive="true"
                        :options="participants"
                        :errors="v$.callParticipant.$errors"
                    />
                </div>

                <div class="flex flex-col justify-end pb-1.5">
                    <UiCheckboxInput
                        v-model="v$.isActive.$model"
                        name="isActive"
                        :label="$t('word.labels.isActive')"
                        class="h-6"
                    />
                </div>
            </div>
        </UiPanel>
        <UiCheckDirtyBeforeRouteLeave :dirty="v$.$anyDirty" />
    </form>
</template>
