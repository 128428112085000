import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, onMounted } from 'vue';
import store from '@/store';
import { useI18n } from 'vue-i18n';

import { MultiSelectOption, UserGetters, UserActions, UserFilter, RoleModel } from '@/types';
import { FilterIcon } from '@heroicons/vue/solid';
import { RoleService } from '@/services';
import { toastServiceError } from '@/services/core/notification';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserListFilters',
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const filtersOpened = ref(false);

const defaultFilter = computed(() => store.getters[UserGetters.DEFAULT_FILTER]);

const filter: Ref<UserFilter> = ref({
    roles: defaultFilter.value.roles,
    isActive: defaultFilter.value.isActive,
});

const storeFilters = computed<UserFilter>(() => store.getters[UserGetters.FILTER]);

const isActiveFilterOptions = [
    {
        label: t('user.filters.isActive.options.active'),
        value: true,
    },
    {
        label: t('user.filters.isActive.options.inactive'),
        value: false,
    },
] as MultiSelectOption[];

const rolesOptions: Ref<RoleModel[]> = ref([]);
const getRoles = async () => {
    try {
        const response = await RoleService.getAll<RoleModel[]>({
            params: {
                page: 1,
                per_page: 100,
            },
        });
        rolesOptions.value = rolesOptions.value.concat(response.data);
    } catch (error) {
        toastServiceError(error);
    }
};

onMounted(() => {
    getRoles();
});

function applyFilters() {
    store.dispatch(UserActions.SET_FILTER, filter.value);
}

function clearFilters() {
    store.dispatch(UserActions.CLEAR_FILTER);
    refreshLocalFilter();
}

function refreshLocalFilter() {
    filter.value.roles = storeFilters.value.roles;
    filter.value.isActive = storeFilters.value.isActive;
}

watch(filtersOpened, () => {
    refreshLocalFilter();
});

const __returned__ = { t, filtersOpened, defaultFilter, filter, storeFilters, isActiveFilterOptions, rolesOptions, getRoles, applyFilters, clearFilters, refreshLocalFilter, get FilterIcon() { return FilterIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})