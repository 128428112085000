import { ScheduleWeekday } from '@/types';

export interface ScheduleFormState {
    name: string | null;
    [ScheduleWeekday.MONDAY]: boolean;
    [ScheduleWeekday.TUESDAY]: boolean;
    [ScheduleWeekday.WEDNESDAY]: boolean;
    [ScheduleWeekday.THURSDAY]: boolean;
    [ScheduleWeekday.FRIDAY]: boolean;
    [ScheduleWeekday.SATURDAY]: boolean;
    [ScheduleWeekday.SUNDAY]: boolean;
    timeBegin: string;
    timeEnd: string;
    isActive: boolean;
}
