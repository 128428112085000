<script lang="ts" setup>
import { ref, Ref, computed, watch, ComputedRef, onMounted } from 'vue';
import moment from 'moment';
import { useI18n } from 'vue-i18n';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import { ContactCenterPerDayReportService } from '@/services';

import {
    BarChartConfig,
    CallsStatsPerDay,
    CallType,
    ContactCenterPerDayReportActions,
    ContactCenterPerDayReportFilter,
    ContactCenterPerDayReportGetters,
    ContactCenterPerDayReportServiceGetInfoParams,
    ContactCenterPerDayReportServiceGetInfoResult,
    DataItem,
    FilterItem,
    LevelModel,
} from '@/types';

import { ContactCenterPerDayReportFilters } from '@/views/pages/reports/contact-center-per-day/components/';

// Constants
const chartColorBlue = '#BFDBFE';
const chartColorCyan = '#A5F3FC';

// State
const reportInfo: Ref<ContactCenterPerDayReportServiceGetInfoResult | null> = ref(null);

// Computed
const headerStats = computed<DataItem<string>[]>(() => {
    const stats = reportInfo.value?.stats;
    if (!stats) return [];

    return [
        { name: 'totalCalls', value: stats.total.toString() },
        { name: 'inboundCalls', value: stats.type[CallType.INBOUND].toString() },
        { name: 'outboundCalls', value: stats.type[CallType.OUTBOUND].toString() },
        { name: 'internalCalls', value: stats.type[CallType.INTERNAL].toString() },
        { name: 'uniqueNumbers', value: stats.uniqueNumbers.toString() },
        {
            name: 'averageServiceTime',
            value: moment.utc((stats.averageServiceTime || 0) * 1000).format('HH:mm:ss'),
        },
    ];
});

const charts = computed<BarChartConfig[]>(() => [
    getChart(
        t('report.contactCenterPerDay.serviceTimePerDayColumnChart.title'),
        'averageServiceTime',
        chartColorBlue,
        yAxisLabelFormatterAsTime,
    ),
    getChart(
        t('report.contactCenterPerDay.durationPerDayColumnChart.title'),
        'averageDuration',
        chartColorCyan,
        yAxisLabelFormatterAsTime,
    ),
]);

// Composables
const uiStore = useUiStore();
const filterStore = useFilterStore();
const { t } = useI18n();

const levelFilter = computed<LevelModel | null>(() => filterStore.level);
const dateRange = computed<Array<Date>>({
    get: () => filterStore.dateRange,
    set: (value) => filterStore.setDateRange(value),
});
const filter = computed<ContactCenterPerDayReportFilter>(
    () => store.getters[ContactCenterPerDayReportGetters.FILTER],
);
const activeFilters: ComputedRef<FilterItem[]> = computed(
    () => store.getters[ContactCenterPerDayReportGetters.ACTIVE_FILTERS],
);

// Watchers
watch([levelFilter, dateRange, activeFilters], () => {
    fetchReportData();
});

// Methods
const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchReportData = async () => {
    const params: ContactCenterPerDayReportServiceGetInfoParams = {
        startDate: moment.utc(dateRange.value[0]).format('YYYY-MM-DD'),
        endDate: moment.utc(dateRange.value[1]).format('YYYY-MM-DD'),
        type: filter.value.type?.value || null,
        status: filter.value.status?.value || null,
        user: filter.value.user?.id || null,
        number: filter.value.number?.number || null,
        serviceTimeRange: filter.value.serviceTimeRange?.value || null,
        userLabel: filter.value.userLabel?.id || null,
    };

    try {
        setLoading(true);
        const { data } = await ContactCenterPerDayReportService.getInfo({ params });
        reportInfo.value = data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveFilter = (filter: FilterItem) => {
    store.dispatch(ContactCenterPerDayReportActions.CLEAR_FILTER, filter.field);
};

const getChart = (
    title: string,
    prop: keyof CallsStatsPerDay,
    color = chartColorBlue,
    yAxisLabelFormatter = (value: number | string) => String(value),
): BarChartConfig => {
    const stats = [...(reportInfo.value?.callsStatsPerDay || [])].reverse();

    const category = stats.map((s) => s.date);
    const value = stats.map((s) => s[prop] || 0);

    return {
        title,
        category,
        series: [
            {
                name: title,
                value,
                color,
            },
        ],
        yAxisLabelFormatter,
    };
};

const yAxisLabelFormatterAsTime = (value: number | string) =>
    moment.utc((Number(value) || 0) * 1000).format('HH:mm:ss');

const xAxisLabelFormatterAsDate = (value: number | string) => moment.utc(value).format('DD/MM');

// Lifecycle
onMounted(() => {
    fetchReportData();
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('report.contactCenterPerDay.title') }}
                </h1>
            </template>
            <template #actions>
                <ContactCenterPerDayReportFilters />
                <UiDatePicker v-model="dateRange" range multiCalendars class="ml-4" />
            </template>
        </UiPageHeader>

        <div
            v-if="activeFilters.length"
            class="mt-4 flex bg-gray-50 px-4 py-4 shadow ring-1 ring-gray-300 sm:p-6 md:rounded"
        >
            <template :key="filter" v-for="filter in activeFilters">
                <UiBadge removable @remove="handleRemoveFilter(filter)">
                    {{ $t(`report.contactCenterPerDay.filters.activeTag.${filter.field}`) }}:
                    {{ filter.label }}
                </UiBadge>
            </template>
        </div>

        <dl
            v-if="headerStats"
            class="mt-4 grid grid-cols-2 gap-4"
            :class="['sm:grid-cols-' + headerStats.length]"
        >
            <UiPanel v-for="stat in headerStats" :key="stat.name">
                <dt class="text-center text-xs font-medium text-gray-500">
                    {{ $t('report.contactCenterPerDay.stats.' + stat.name) }}
                </dt>
                <dd class="mt-1 text-center text-2xl font-semibold text-gray-800">
                    {{ stat.value }}
                </dd>
            </UiPanel>
        </dl>

        <div v-if="reportInfo" class="mt-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
            <CallTypePerDayColumnChart
                :title="t('report.contactCenterPerDay.callTypePerDayColumnChart.title')"
                :data="reportInfo.callsStatsPerDay"
                class="col-span-2 h-[300px] md:col-span-1"
            />

            <CallStatusPerDayColumnChart
                :title="t('report.contactCenterPerDay.callStatusPerDayColumnChart.title')"
                :data="reportInfo.callsStatsPerDay"
                class="col-span-2 h-[300px] md:col-span-1"
            />

            <BarChart
                v-for="(chart, index) in charts"
                :key="index"
                :title="chart.title"
                :categoryData="chart.category"
                :seriesData="chart.series"
                :xAxisLabelFormatter="xAxisLabelFormatterAsDate"
                :yAxisLabelFormatter="chart.yAxisLabelFormatter"
                class="col-span-2 h-[300px] md:col-span-1"
                column
            />
        </div>
    </div>
</template>
