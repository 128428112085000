import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useFilterStore } from '@/stores';
import { RealtimeReportService } from '@/services';
import { toastServiceError } from '@/services/core/notification';

import { formatSecToTime } from '@/utils/datetime';
import { applyPhoneMask } from '@/utils/number';

import { LevelModel } from '@/types';
import { RealtimeReportServiceGetQueueCallsResult } from '@/types/services/RealtimeReportService';


export default /*@__PURE__*/_defineComponent({
  __name: 'QueueDetailsTable',
  setup(__props, { expose: __expose }) {
  __expose();

const filterStore = useFilterStore();

const loading = ref(false);
const items = ref<RealtimeReportServiceGetQueueCallsResult[]>([]);

const levelFilter = computed<LevelModel | null>(() => filterStore.level);

const fetchData = async () => {
    try {
        loading.value = true;
        const response =
            await RealtimeReportService.getQueueCalls<RealtimeReportServiceGetQueueCallsResult[]>();
        items.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        loading.value = false;
    }
};

let interval: number;

watch(levelFilter, () => {
    items.value = [];
    clearInterval(interval);
    interval = setInterval(fetchData, 5000);
});

onMounted(() => {
    interval = setInterval(fetchData, 5000);
});

onUnmounted(() => {
    clearInterval(interval);
});

const __returned__ = { filterStore, loading, items, levelFilter, fetchData, get interval() { return interval }, set interval(v) { interval = v }, get formatSecToTime() { return formatSecToTime }, get applyPhoneMask() { return applyPhoneMask } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})