<script lang="ts" setup>
import { computed } from 'vue';
import moment from 'moment';
import { colord } from 'colord';

import { EChartsOption } from 'echarts';
import { CallTimeOfDayCount } from '@/types';

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    data: {
        type: Array<CallTimeOfDayCount>,
        required: true,
    },
});

const chartOption = computed<EChartsOption>(() => {
    const baseColor = colord('#0FA5E8');

    const hours = [];
    for (let i = 0; i < 24; i++) {
        hours.push(('0' + i).substr(-2));
    }

    const days = [];
    for (let i = 6; i >= 0; i--) {
        days.push(moment().day(i).format('ddd'));
    }

    let max = 0;
    const data = props.data.map((d) => {
        if (d.value > max) max = d.value;
        return [d.hour, d.weekDayIndex, d.value || '-'];
    });

    const eChartsOption: EChartsOption = {
        title: {
            text: props.title,
            left: '14px',
            top: '14px',
            textStyle: {
                fontSize: '16px',
                fontWeight: 'bold',
                color: '#555',
            },
        },
        tooltip: {
            position: 'top',
        },
        grid: {
            height: '65%',
            bottom: '15%',
            left: '5%',
        },
        xAxis: {
            type: 'category',
            data: hours,
            splitArea: {
                show: true,
            },
        },
        yAxis: {
            type: 'category',
            data: days,
            splitArea: {
                show: true,
            },
        },
        visualMap: {
            min: 0,
            max: max,
            calculable: true,
            orient: 'vertical',
            bottom: '15%',
            right: '3%',
            inRange: {
                color: [
                    baseColor.lighten(0.6).toHex(),
                    baseColor.lighten(0.3).toHex(),
                    baseColor.lighten(0.1).toHex(),
                    baseColor.toHex(),
                ],
            },
        },
        series: [
            {
                type: 'heatmap',
                data: data,
                label: {
                    show: true,
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                    },
                },
            },
        ],
    };
    return eChartsOption;
});
</script>

<template>
    <UiChart :option="chartOption" />
</template>
