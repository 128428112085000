<script lang="ts" setup>
import { ref, Ref, computed, watch, ComputedRef, onMounted } from 'vue';
import moment from 'moment';
import store from '@/store';
import { useI18n } from 'vue-i18n';
import { useFilterStore, useUiStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import { AgentProductivityPerDayReportService } from '@/services';

import {
    AgentProductivityPerDayReportActions,
    AgentProductivityPerDayReportGetters,
    BarChartConfig,
    CallType,
    ChartDataItem,
    DataItem,
    AgentProductivityPerDayReportFilter,
    AgentProductivityPerDayReportServiceGetInfoParams,
    AgentProductivityPerDayReportServiceGetInfoResult,
    CallsStatsPerDay,
    FilterItem,
    LevelModel,
} from '@/types';

import { AgentProductivityPerDayReportFilters } from '@/views/pages/reports/agent-productivity-per-day/components';

const reportInfo: Ref<AgentProductivityPerDayReportServiceGetInfoResult | null> = ref(null);

const levelFilter = computed<LevelModel | null>(() => filterStore.level);
const dateRange = computed<Array<Date>>({
    get: () => filterStore.dateRange,
    set: (dateRange: Array<Date>) => filterStore.setDateRange(dateRange),
});
const filter = computed<AgentProductivityPerDayReportFilter>(
    () => store.getters[AgentProductivityPerDayReportGetters.FILTER],
);
const activeFilters: ComputedRef<FilterItem[]> = computed(
    () => store.getters[AgentProductivityPerDayReportGetters.ACTIVE_FILTERS],
);

const filterStore = useFilterStore();
const uiStore = useUiStore();
const { t } = useI18n();

watch([levelFilter, dateRange, activeFilters], () => {
    fetchReportData();
});

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchReportData = async () => {
    const params: AgentProductivityPerDayReportServiceGetInfoParams = {
        startDate: moment.utc(dateRange.value[0]).format('YYYY-MM-DD'),
        endDate: moment.utc(dateRange.value[1]).format('YYYY-MM-DD'),
        user: filter.value.user?.id || null,
        userLabel: filter.value.userLabel?.id || null,
    };

    try {
        setLoading(true);
        const response = await AgentProductivityPerDayReportService.getInfo({ params });
        reportInfo.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveFilter = (filter: FilterItem) => {
    store.dispatch(AgentProductivityPerDayReportActions.CLEAR_FILTER, filter.field);
};

const headerStats = computed<DataItem<string>[]>(() => {
    const stats = reportInfo.value?.stats;
    if (!stats) return [];

    return [
        { name: 'totalCalls', value: stats.total.toString() },
        { name: 'inboundCalls', value: stats.type[CallType.INBOUND].toString() },
        { name: 'outboundCalls', value: stats.type[CallType.OUTBOUND].toString() },
        { name: 'internalCalls', value: stats.type[CallType.INTERNAL].toString() },
        { name: 'uniqueNumbers', value: stats.uniqueNumbers.toString() },
        {
            name: 'averageServiceTime',
            value: moment.utc((stats.averageServiceTime || 0) * 1000).format('HH:mm:ss'),
        },
    ];
});

const yAxisLabelFormatterAsTime = (value: number | string) => {
    value = value || 0;
    return moment.utc((value as number) * 1000).format('HH:mm:ss');
};

const xAxisLabelFormatterAsDate = (value: number | string) => {
    return moment.utc(value).format('DD/MM');
};

const getChart = (
    title: string,
    prop: keyof CallsStatsPerDay,
    color = '#BFDBFE',
    yAxisLabelFormatter = (value: number | string) => String(value),
): BarChartConfig => {
    const stats = reportInfo.value?.callsStatsPerDay || [];
    const category: string[] = [];
    const serie: ChartDataItem<number[]> = {
        name: title,
        value: [],
        color,
    };

    for (let i = stats.length - 1; i >= 0; i--) {
        const item = stats[i];
        category.push(item.date);
        serie.value.push(item[prop] as number);
    }

    return {
        title,
        category: category.reverse(),
        series: [{ ...serie, value: serie.value.reverse() }],
        yAxisLabelFormatter,
    };
};

const charts = computed<BarChartConfig[]>(() => [
    getChart(
        t('report.agentProductivityPerDay.serviceTimePerDayColumnChart.title'),
        'averageServiceTime',
        '#BFDBFE',
        yAxisLabelFormatterAsTime,
    ),
    getChart(
        t('report.agentProductivityPerDay.timeAvailablePerDayColumnChart.title'),
        'averageTimeAvailable',
        '#FBCFE8',
        yAxisLabelFormatterAsTime,
    ),
    getChart(
        t('report.agentProductivityPerDay.durationPerDayColumnChart.title'),
        'averageDuration',
        '#A5F3FC',
        yAxisLabelFormatterAsTime,
    ),
    getChart(
        t('report.agentProductivityPerDay.totalIdleTimePerDayColumnChart.title'),
        'averageTotalIdleTime',
        '#BAE6FD',
        yAxisLabelFormatterAsTime,
    ),
    getChart(
        t('report.agentProductivityPerDay.idleTimePerDayColumnChart.title'),
        'averageIdleTime',
        '#F5D0FE',
        yAxisLabelFormatterAsTime,
    ),
]);

onMounted(() => {
    fetchReportData();
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('report.agentProductivityPerDay.title') }}
                </h1>
            </template>
            <template #actions>
                <AgentProductivityPerDayReportFilters />
                <UiDatePicker v-model="dateRange" range multiCalendars class="ml-4" />
            </template>
        </UiPageHeader>

        <div
            v-if="activeFilters.length"
            class="mt-4 flex bg-gray-50 px-4 py-4 shadow ring-1 ring-gray-300 sm:p-6 md:rounded"
        >
            <template :key="filter" v-for="filter in activeFilters">
                <UiBadge removable @remove="handleRemoveFilter(filter)">
                    {{ $t(`report.agentProductivityPerDay.filters.activeTag.${filter.field}`) }}:
                    {{ filter.label }}
                </UiBadge>
            </template>
        </div>

        <dl
            v-if="headerStats"
            class="mt-4 grid grid-cols-2 gap-4"
            :class="['sm:grid-cols-' + headerStats.length]"
        >
            <UiPanel v-for="stat in headerStats" :key="stat.name">
                <dt class="text-center text-xs font-medium text-gray-500">
                    {{ $t('report.contactCenterPerAgent.stats.' + stat.name) }}
                </dt>
                <dd class="mt-1 text-center text-2xl font-semibold text-gray-800">
                    {{ stat.value }}
                </dd>
            </UiPanel>
        </dl>

        <div v-if="reportInfo" class="mt-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
            <CallTypePerDayColumnChart
                :title="t('report.agentProductivityPerDay.callTypePerDayColumnChart.title')"
                :data="reportInfo.callsStatsPerDay"
                class="col-span-2 h-[300px] md:col-span-1"
            />

            <CallStatusPerDayColumnChart
                :title="t('report.agentProductivityPerDay.callStatusPerDayColumnChart.title')"
                :data="reportInfo.callsStatsPerDay"
                class="col-span-2 h-[300px] md:col-span-1"
            />

            <BarChart
                v-for="(chart, index) in charts"
                :key="index"
                :title="chart.title"
                :categoryData="chart.category"
                :seriesData="chart.series"
                :xAxisLabelFormatter="xAxisLabelFormatterAsDate"
                :yAxisLabelFormatter="chart.yAxisLabelFormatter"
                column
                class="col-span-2 h-[300px] md:col-span-1"
            />
        </div>
    </div>
</template>
