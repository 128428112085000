import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import { AnalyticsService, AuthService } from '@/services';
import { isPositiveInteger } from '@/utils/number';
import { NavigationGuardNext, NavigationGuardWithThis, RouteLocationNormalized } from 'vue-router';
import { AuthActions, AuthGetters, IntegrationType } from '@/types';

const NO_CHECK_ROUTES = ['/app/login', '/update-password'];

async function handleAuthCheck(to: RouteLocationNormalized, next: NavigationGuardNext) {
    try {
        const { data } = await AuthService.check();
        const user = data.user;

        if (user.lastLogin === null) {
            return next({ name: 'UpdatePassword' });
        }

        await store.dispatch(AuthActions.LOGIN, data);

        return to.path === '/login' ? next({ name: 'Home' }) : next();
    } catch {
        return to.path === '/login' ? next() : next({ name: 'Login' });
    }
}

export const authGuard: NavigationGuardWithThis<undefined> = async (to, from, next) => {
    const user = store.getters[AuthGetters.USER];

    if (!user) {
        if (NO_CHECK_ROUTES.includes(to.path)) {
            return next();
        }
        return handleAuthCheck(to, next);
    }

    const filterStore = useFilterStore();
    if (to.name && filterStore.level) {
        await AnalyticsService.trackingNavigation(filterStore.level, user, to.name.toString());
    }

    next();
};

export const validateIdAsPositiveIntegerGuard: NavigationGuardWithThis<undefined> = (
    to,
    from,
    next,
) => {
    const id = to.params.id;

    return typeof id === 'string' && isPositiveInteger(id) ? next() : next('not-found');
};

export const validateIntegrationTypeGuard: NavigationGuardWithThis<undefined> = (
    to,
    from,
    next,
) => {
    const type = to.params.type;

    return typeof type === 'string' &&
        Object.values(IntegrationType).includes(type as IntegrationType)
        ? next()
        : next('not-found');
};

export const levelLockGuard: NavigationGuardWithThis<undefined> = (to, from, next) => {
    const uiStore = useUiStore();
    uiStore.setIsLevelLocked(Boolean(to.meta.isLevelLocked));
    next();
};
