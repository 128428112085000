import { ConnectFlowModel, DefaultServiceResult } from '@/types';

export interface ConnectFlowServiceGetAllParams {
    page: number;
    per_page: number;
    order_by: string;
    level?: number | null;
    is_active?: boolean | null;
    search?: string;
}

export interface ConnectFlowServiceCreateResult extends DefaultServiceResult {
    result: ConnectFlowModel;
}

export interface ConnectFlowServiceSaveFlowResult extends DefaultServiceResult {
    connectFlow: ConnectFlowModel;
}
