export interface BillingModel {
    totalMinutes: number;
    totalCost: number;
    inboundCost: number;
    outboundCost: number;
    costByOriginType: Record<string, number>;
    costByDestinationType: Record<string, number>;
    dailyResults: Array<{ date: string; minutes: number; cost: number }>;
    participants: Array<{ id: number; name: string }>;
    sources: string[];
    destinations: string[];
}
