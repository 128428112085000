/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    SortMenuItem,
    MeetingState,
    MeetingMutations,
    MeetingActions,
    MeetingGetters,
    MeetingFilter,
    FilterItem,
} from '@/types';

const state: MeetingState = {
    search: null,
    sort: [],
    page: 1,
    filter: {
        platform: null,
    },
};

const mutations: MutationTree<MeetingState> = {
    [MeetingMutations.SET_SEARCH]: (state, search: string) => (state.search = search),
    [MeetingMutations.SET_SORT]: (state, sort: SortMenuItem[]) => (state.sort = sort),
    [MeetingMutations.SET_PAGE]: (state, page: number) => (state.page = page),
    [MeetingMutations.SET_FILTER](state, filter: MeetingFilter) {
        state.filter = { ...state.filter, ...filter };
    },
    [MeetingMutations.CLEAR_FILTER](state, field?: string) {
        if (field === 'platform') {
            state.filter.platform = null;
        } else {
            state.filter.platform = null;
        }
    },
};

const actions: ActionTree<MeetingState, any> = {
    [MeetingActions.SET_SEARCH]: ({ commit }, search: string) =>
        commit(MeetingMutations.SET_SEARCH, search),
    [MeetingActions.SET_SORT]: ({ commit }, sort: SortMenuItem[]) =>
        commit(MeetingMutations.SET_SORT, sort),
    [MeetingActions.SET_PAGE]: ({ commit }, page: number) =>
        commit(MeetingMutations.SET_PAGE, page),
    [MeetingActions.SET_FILTER]: ({ commit }, filter: MeetingFilter) => {
        commit(MeetingMutations.SET_FILTER, filter);
    },
    [MeetingActions.CLEAR_FILTER]: ({ commit }, field?: string) =>
        commit(MeetingMutations.CLEAR_FILTER, field),
};

const getters: GetterTree<MeetingState, any> = {
    [MeetingGetters.SEARCH]: (state): string | null => state.search,
    [MeetingGetters.SORT]: (state): SortMenuItem[] => state.sort,
    [MeetingGetters.PAGE]: (state): number => state.page,
    [MeetingGetters.FILTER]: (state): MeetingFilter => state.filter,
    [MeetingGetters.DEFAULT_FILTER]: (): MeetingFilter => ({
        platform: null,
    }),
    [MeetingGetters.ACTIVE_FILTERS](state): FilterItem[] {
        const filters: Array<FilterItem> = [];

        if (state.filter.platform) {
            filters.push({
                field: 'platform',
                label: state.filter.platform.label,
                value: state.filter.platform.value,
            });
        }

        return filters;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
