export interface CallDataModel {
    callId: number;
    environmentId: number;
    startedAt: number;
    endedAt: number;
    duration: number;
    silence: number;
    agentStartedAt: number;
    agentEndedAt: number;
    agentSilenceTime: number;
    agentTalkTime: number;
    agentDuration: number;
    agentTalkPercentage: number;
    agentTalkOver: number;
    agentEmotion: string;
    agentStartEmotion: string;
    agentMiddleEmotion: string;
    agentEndEmotion: string;
    agentSentiment: string;
    agentStartSentiment: string;
    agentMiddleSentiment: string;
    agentEndSentiment: string;
    clientStartedAt: number;
    clientEndedAt: number;
    clientSilenceTime: number;
    clientTalkTime: number;
    clientDuration: number;
    clientTalkPercentage: number;
    clientTalkOver: number;
    clientEmotion: string;
    clientStartEmotion: string;
    clientMiddleEmotion: string;
    clientEndEmotion: string;
    clientSentiment: string;
    clientStartSentiment: string;
    clientMiddleSentiment: string;
    clientEndSentiment: string;
    failed: boolean;
    errorMessage: string;
}
