import BaseService from '@/services/api/BaseService';
import { AxiosRequestConfig } from 'axios';

class VaultService extends BaseService<Blob> {
    constructor() {
        super('vault');
    }

    getAudioAsset<ResponseT = Blob>(file: string, config?: AxiosRequestConfig) {
        return this.http.get<ResponseT>(`${this.route}/stream/asset/audio/${file}`, {
            responseType: 'blob',
            ...config,
        });
    }

    getCallAudio<ResponseT = Blob>(callId: string | number, config?: AxiosRequestConfig) {
        return this.http.get<ResponseT>(`${this.route}/stream/call/${callId}/audio`, {
            responseType: 'blob',
            ...config,
        });
    }
}

export default new VaultService();
