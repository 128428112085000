import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue';
import store from '@/store';
import { UserModel, DashboardItem, AuthGetters, Permission } from '@/types';
import {
    AnnotationIcon,
    BanIcon,
    BookOpenIcon,
    ClockIcon,
    CloudUploadIcon,
    LinkIcon,
    LockClosedIcon,
    MenuAlt2Icon,
    OfficeBuildingIcon,
    PhoneIcon,
    TagIcon,
    UserGroupIcon,
    UsersIcon,
} from '@heroicons/vue/solid';
import ConnectFlowIcon from '@/components/svg/ConnectFlowIcon';


export default /*@__PURE__*/_defineComponent({
  __name: 'SettingsPage',
  setup(__props, { expose: __expose }) {
  __expose();

const user = computed<UserModel>(() => store.getters[AuthGetters.USER]);

const links: DashboardItem[] = [
    {
        title: 'dashboard.settings.levels',
        description: 'level.description',
        permission: Permission.VIEW_LEVELS,
        routeName: 'ListLevel',
        icon: OfficeBuildingIcon,
    },
    {
        title: 'dashboard.settings.users',
        description: 'user.description',
        permission: Permission.VIEW_USERS,
        routeName: 'ListUser',
        icon: UsersIcon,
    },
    {
        title: 'dashboard.settings.userLabel',
        description: 'userLabel.description',
        permission: Permission.VIEW_USER_LABELS,
        routeName: 'ListUserLabel',
        icon: UserGroupIcon,
    },
    {
        title: 'dashboard.settings.numbers',
        description: 'number.description',
        permission: Permission.VIEW_NUMBERS,
        routeName: 'ListNumber',
        icon: PhoneIcon,
    },
    {
        title: 'dashboard.settings.contacts',
        description: 'contact.description',
        permission: Permission.VIEW_CONTACTS,
        routeName: 'ListContact',
        icon: BookOpenIcon,
    },
    {
        title: 'dashboard.settings.spamNumber',
        description: 'spamNumber.description',
        permission: Permission.VIEW_SPAM_NUMBERS,
        routeName: 'ListSpamNumber',
        icon: BanIcon,
    },
    {
        title: 'dashboard.settings.connectFlow',
        description: 'connectFlow.description',
        permission: Permission.VIEW_CONNECT_FLOWS,
        routeName: 'ListConnectFlow',
        icon: ConnectFlowIcon,
    },
    {
        title: 'dashboard.settings.connectFlowAudios',
        description: 'connectFlowAudio.description',
        permission: Permission.VIEW_CONNECT_FLOW_AUDIOS,
        routeName: 'ListConnectFlowAudio',
        icon: CloudUploadIcon,
    },
    {
        title: 'dashboard.settings.queues',
        description: 'queue.description',
        permission: Permission.VIEW_QUEUES,
        routeName: 'ListQueue',
        icon: MenuAlt2Icon,
    },
    {
        title: 'dashboard.settings.schedules',
        description: 'schedule.description',
        permission: Permission.VIEW_SCHEDULES,
        routeName: 'ListSchedule',
        icon: ClockIcon,
    },
    {
        title: 'dashboard.settings.surveys',
        description: 'connectFlowSurvey.description',
        permission: Permission.VIEW_CONNECT_FLOW_SURVEYS,
        routeName: 'ListConnectFlowSurvey',
        icon: AnnotationIcon,
    },
    {
        title: 'dashboard.settings.words',
        description: 'word.description',
        permission: Permission.VIEW_WORDS,
        routeName: 'ListWord',
        icon: TagIcon,
    },
    {
        title: 'dashboard.settings.integrations',
        description: 'integration.description',
        permission: Permission.VIEW_INTEGRATIONS,
        routeName: 'ListIntegration',
        icon: LinkIcon,
    },
    {
        title: 'dashboard.settings.roles',
        description: 'role.description',
        permission: Permission.ASSIGN_ROLE_TO_USER,
        routeName: 'Permissions',
        icon: LockClosedIcon,
    },
];

const __returned__ = { user, links }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})