<script lang="ts" setup>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { ECBasicOption } from 'echarts/types/dist/shared';
import { PieChart, GaugeChart, BarChart, HeatmapChart } from 'echarts/charts';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    VisualMapComponent,
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';
import { PropType, provide } from 'vue';

use([
    CanvasRenderer,
    PieChart,
    BarChart,
    GaugeChart,
    HeatmapChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    VisualMapComponent,
]);

provide(THEME_KEY, 'light');

const props = defineProps({
    option: {
        type: Object as PropType<ECBasicOption>,
    },
    autoresize: {
        type: Boolean,
        default: true,
    },
});
</script>

<template>
    <UiPanel no-padding>
        <v-chart class="chart" :option="props.option" :autoresize="props.autoresize" />
    </UiPanel>
</template>
