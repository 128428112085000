import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

import { useUiStore } from '@/stores';
import { UserService, NumberService } from '@/services';
import { toastServiceError } from '@/services/core/notification';
import { UserModel, NumberModel, Permission } from '@/types';

import { DesktopComputerIcon, DeviceMobileIcon } from '@heroicons/vue/solid';
import SVGDeviceExternal from '@/components/svg/SVGDeviceExternal.vue';
import SVGChromeLogo from '@/components/svg/SVGChromeLogo.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const { t } = useI18n();

const uiStore = useUiStore();

const user = ref<UserModel | null>(null);
const numbersRaw = ref<NumberModel[]>([]);
const isLoading = ref(false);

const setLoading = (loading: boolean) => {
    isLoading.value = loading;
    uiStore.setIsLoading(loading);
};

const fetchUserAndNumbers = async (id: number) => {
    setLoading(true);
    try {
        const userResponse = await UserService.get<UserModel>(id);
        user.value = userResponse.data;

        if (user.value?.levels?.length) {
            const numbersResponse = await NumberService.getAll<NumberModel[]>({
                params: { levels: user.value.levels },
            });
            numbersRaw.value = numbersResponse.data ?? [];
        }
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const getLevelName = (levelID: number | undefined) =>
    computed(() => user.value?.levels?.find((level) => level.id === levelID)?.name || '');

const getBinaNumber = (levelID: number | undefined, binaID: number | undefined) =>
    computed(() => {
        if (!levelID || !binaID || numbersRaw.value.length === 0) return t('core.random');
        const number = numbersRaw.value.find((n) => n.level?.id === levelID && n.id === binaID);
        return number?.number ?? t('core.random');
    });

onMounted(() => {
    fetchUserAndNumbers(Number(route.params.id));
});

const __returned__ = { route, t, uiStore, user, numbersRaw, isLoading, setLoading, fetchUserAndNumbers, getLevelName, getBinaNumber, get Permission() { return Permission }, get DesktopComputerIcon() { return DesktopComputerIcon }, get DeviceMobileIcon() { return DeviceMobileIcon }, SVGDeviceExternal, SVGChromeLogo }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})