<script lang="ts" setup>
import { computed, Ref, ref, watch } from 'vue';
import store from '@/store';

import { FilterIcon } from '@heroicons/vue/solid';
import {
    MultiSelectOption,
    IntegrationLogFilter,
    IntegrationLogGetters,
    IntegrationLogActions,
} from '@/types';
import { useI18n } from 'vue-i18n';

const filterOpened = ref(false);

const defaultFilter = computed(() => store.getters[IntegrationLogGetters.DEFAULT_FILTER]);

const filter: Ref<IntegrationLogFilter> = ref({
    status: defaultFilter.value.status,
});

const storeFilter = computed(() => store.getters[IntegrationLogGetters.FILTER]);

const { t } = useI18n();

const statusFilterOptions = [
    {
        label: t('integration.logs.filters.status.options.ok'),
        value: 'ok',
    },
    {
        label: t('integration.logs.filters.status.options.nok'),
        value: 'nok',
    },
] as MultiSelectOption[];

function applyFilters() {
    store.dispatch(IntegrationLogActions.SET_FILTER, filter.value);
}

function clearFilters() {
    store.dispatch(IntegrationLogActions.CLEAR_FILTER);
    refreshLocalFilter();
}
function refreshLocalFilter() {
    filter.value.status = storeFilter.value.status;
}

watch(filterOpened, () => {
    refreshLocalFilter();
});
</script>

<template>
    <UiRightPanel v-model="filterOpened" :title="$t('filter.sidebar.title')">
        <template #button>
            <UiButton
                size="md"
                variant="white"
                text-variant="gray-700"
                @click="filterOpened = true"
            >
                <FilterIcon class="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                {{ $t('core.actions.Filter') }}
            </UiButton>
        </template>
        <template #default>
            <form autocomplete="off" class="flex flex-col gap-3">
                <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                    <div class="sm:col-span-3">
                        <label for="filter_status" class="block text-sm font-medium text-gray-800">
                            {{ $t('integration.logs.filters.status.label') }}
                        </label>
                        <UiMultiselect
                            name="filter_status"
                            v-model="filter.status"
                            :options="statusFilterOptions"
                            :placeholder="t('core.options.All')"
                            can-clear
                        />
                    </div>
                </div>
                <div class="mt-4 grid grid-cols-2 place-content-around gap-4">
                    <UiButton variant="white" text-variant="gray-700" @click="clearFilters()">
                        {{ $t('core.actions.Clear') }}
                    </UiButton>
                    <UiButton variant="primary" text-variant="white" @click="applyFilters()">
                        {{ $t('core.actions.Apply') }}
                    </UiButton>
                </div>
            </form>
        </template>
    </UiRightPanel>
</template>
