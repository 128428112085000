import { defineComponent as _defineComponent } from 'vue'
import { computed, type HTMLAttributes, type PropType, type VNodeProps } from 'vue';
import { PencilAltIcon, TrashIcon } from '@heroicons/vue/outline';
import { Handle, Position } from '@vue-flow/core';
import { NodeToolbar } from '@vue-flow/node-toolbar';
import { PlusIcon } from '@heroicons/vue/solid';
import store from '@/store';
import { ConnectFlowModelingActions, ConnectFlowModelingGetters } from '@/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiNode',
  props: {
    title: {
        type: String,
    },
    icon: {
        type: Function as PropType<HTMLAttributes & VNodeProps>,
    },
    text: {
        type: String,
        default: '',
    },
    nodeId: {
        type: String,
        required: true,
    },
    showActions: {
        type: Boolean,
        default: false,
    },
    noEdition: {
        type: Boolean,
        default: false,
    },
    showHandleTarget: {
        type: Boolean,
        default: true,
    },
    showHandleSource: {
        type: Boolean,
        default: true,
    },
    bgColor: {
        type: String,
    },
    borderColor: {
        type: String,
    },
    roundedStyle: {
        type: String,
    },
    padding: {
        type: String,
    },
    nodeWidth: {
        type: String,
    },
    nodeHeight: {
        type: String,
    },
    hideNodeId: {
        type: Boolean,
    },
},
  emits: ['editNode', 'removeNode'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;

const handleEditClick = () => {
    emit('editNode');
};

const handleRemoveClick = () => {
    emit('removeNode');
};

function openComponentSelector() {
    store.dispatch('flow/' + ConnectFlowModelingActions.SET_MENU, {
        show: true,
        idTriggerBy: props.nodeId,
    });
}

const node = computed(() =>
    store.getters['flow/' + ConnectFlowModelingGetters.ELEMENT_BY_ID](props.nodeId),
);

const __returned__ = { props, emit, handleEditClick, handleRemoveClick, openComponentSelector, node, get PencilAltIcon() { return PencilAltIcon }, get TrashIcon() { return TrashIcon }, get Handle() { return Handle }, get Position() { return Position }, get NodeToolbar() { return NodeToolbar }, get PlusIcon() { return PlusIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})