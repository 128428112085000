import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue';
import { IsActiveFlag, CreatedAudit, UpdatedAudit } from '@/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiAuditStatusInfo',
  props: {
    modelValue: { type: Object, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();



const hasIsActive = computed(() => typeof __props.modelValue?.isActive === 'boolean');

const __returned__ = { hasIsActive }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})