import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue';
import { CloudUploadIcon, XIcon } from '@heroicons/vue/outline';
import { ErrorObject } from '@vuelidate/core';

// v-model binding for `file`

export default /*@__PURE__*/_defineComponent({
  __name: 'UiAudioUpload',
  props: /*@__PURE__*/_mergeModels({
    name: String,
    disabled: { type: Boolean, default: false },
    textRule: String,
    errors: { type: Array as () => ErrorObject[], default: () => [] },
    silentErrors: { type: Array as () => ErrorObject[], default: () => [] },
}, {
    "file": { type: null },
    "fileModifiers": {},
  }),
  emits: ["update:file"],
  setup(__props, { expose: __expose }) {
  __expose();

const file = _useModel<File | null>(__props, 'file');

const props = __props;

function handleAudioUpload(event: Event) {
    const input = event.target as HTMLInputElement;
    file.value = input.files?.[0] || null;
}

function removeFile() {
    file.value = null;
}

const hasErrors = computed(() => props.errors.length > 0 || props.silentErrors.length > 0);

const disabledClass = computed(() =>
    props.disabled ? 'bg-gray-200 text-gray-400 cursor-not-allowed' : 'bg-white',
);

const __returned__ = { file, props, handleAudioUpload, removeFile, hasErrors, disabledClass, get CloudUploadIcon() { return CloudUploadIcon }, get XIcon() { return XIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})