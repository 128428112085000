import { defineComponent as _defineComponent } from 'vue'
import { computed, onMounted, onUnmounted, ref, Ref, watch } from 'vue';

import { useFilterStore, useUiStore } from '@/stores';
import RealtimeReportService from '@/services/api/RealtimeReportService';
import { toastServiceError } from '@/services/core/notification';
import { applyPhoneMask } from '@/utils/number';

import { UserIcon, DesktopComputerIcon } from '@heroicons/vue/solid';
import { DeviceMobileIcon } from '@heroicons/vue/outline';
import SVGDeviceExternal from '@/components/svg/SVGDeviceExternal.vue';
import SVGChromeLogo from '@/components/svg/SVGChromeLogo.vue';

import { LevelModel, UserDeviceType, UserDeviceStatus } from '@/types';
import { RealtimeReportServiceGetAgentExtensionsResult } from '@/types/services/RealtimeReportService';


export default /*@__PURE__*/_defineComponent({
  __name: 'AgentExtensionReportPage',
  setup(__props, { expose: __expose }) {
  __expose();

const items: Ref<RealtimeReportServiceGetAgentExtensionsResult[]> = ref([]);
const loading = ref(true);

const filterStore = useFilterStore();
const uiStore = useUiStore();

const levelFilter = computed<LevelModel | null>(() => filterStore.level);

const setLoading = (value: boolean) => uiStore.setIsLoading(value);

const getData = async () => {
    try {
        loading.value = true;
        const response =
            await RealtimeReportService.getAgentExtensions<
                RealtimeReportServiceGetAgentExtensionsResult[]
            >();
        items.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        loading.value = false;
    }
};

let interval: number;

watch(levelFilter, () => {
    items.value = [];
    clearInterval(interval);
    interval = setInterval(getData, 5000);
});

onMounted(() => {
    setLoading(true);
    interval = setInterval(getData, 5000);
});

onUnmounted(() => {
    clearInterval(interval);
});

const __returned__ = { items, loading, filterStore, uiStore, levelFilter, setLoading, getData, get interval() { return interval }, set interval(v) { interval = v }, get applyPhoneMask() { return applyPhoneMask }, get UserIcon() { return UserIcon }, get DesktopComputerIcon() { return DesktopComputerIcon }, get DeviceMobileIcon() { return DeviceMobileIcon }, SVGDeviceExternal, SVGChromeLogo, get UserDeviceType() { return UserDeviceType }, get UserDeviceStatus() { return UserDeviceStatus } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})