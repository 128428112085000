import { defineComponent as _defineComponent } from 'vue'
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

import { useUiStore } from '@/stores';
import MeetingService from '@/services/api/MeetingService';
import { toastServiceError } from '@/services/core/notification';

import {
    calculateSpeechPercentage,
    formatMessagesWithElapsedTime,
    formatDateTime,
} from '@/utils/meeting';

import { SpeakerInsights, TranscriptSection } from '@/views/pages/meeting/components';
import { MeetingModel } from '@/types';
import { ChevronLeftIcon } from '@heroicons/vue/outline';

// Stores & Route

export default /*@__PURE__*/_defineComponent({
  __name: 'MeetingDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const uiStore = useUiStore();
const route = useRoute();
const { t } = useI18n();

// State
const meeting = ref<MeetingModel | null>(null);

// Computed
const isLoading = computed(() => uiStore.isLoading);
const formattedCreatedAt = computed(() => formatDateTime(meeting.value?.createdAt));

// Methods
const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getMeeting = async (id: number) => {
    try {
        await setLoading(true);

        const response = await MeetingService.get<MeetingModel>(id);
        meeting.value = response.data;

        if (meeting.value?.participants) {
            meeting.value.participants = calculateSpeechPercentage(meeting.value.participants);
        }

        if (meeting.value?.messages) {
            meeting.value.messages = formatMessagesWithElapsedTime(meeting.value.messages);
        }
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

// Lifecycle
onMounted(() => {
    const meetingId = Number(route.params.id);
    getMeeting(meetingId);
});

const __returned__ = { uiStore, route, t, meeting, isLoading, formattedCreatedAt, setLoading, getMeeting, get SpeakerInsights() { return SpeakerInsights }, get TranscriptSection() { return TranscriptSection }, get ChevronLeftIcon() { return ChevronLeftIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})