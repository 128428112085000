<script lang="ts" setup>
import { computed } from 'vue';
import orderBy from 'lodash.orderby';
import { useI18n } from 'vue-i18n';

import { CallStatus, ChartDataItem, UserAgentCallsStats, CallStatusColorMap } from '@/types';

const { t } = useI18n();

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    data: {
        type: Array<UserAgentCallsStats>,
        required: true,
    },
});

interface ChartData {
    category: Array<string>;
    series: Array<ChartDataItem<number[]>>;
}

const chartData = computed<ChartData>(() => {
    const topUsers = orderBy(
        props.data,
        (user) => {
            return (
                user.status[CallStatus.ANSWERED] +
                user.status[CallStatus.NO_ANSWER] +
                user.status[CallStatus.BUSY] +
                user.status[CallStatus.DITCHED]
            );
        },
        'desc',
    ).slice(0, 10);

    const category: string[] = [];
    const series: ChartDataItem<number[]>[] = [
        {
            value: [],
            name: t('core.options.call.status.answered'),
            color: CallStatusColorMap[CallStatus.ANSWERED],
        },
        {
            value: [],
            name: t('core.options.call.status.no_answer'),
            color: CallStatusColorMap[CallStatus.NO_ANSWER],
        },
        {
            value: [],
            name: t('core.options.call.status.busy'),
            color: CallStatusColorMap[CallStatus.BUSY],
        },
        {
            value: [],
            name: t('core.options.call.status.ditched'),
            color: CallStatusColorMap[CallStatus.DITCHED],
        },
    ];

    for (let i = topUsers.length - 1; i >= 0; i--) {
        const user = topUsers[i];

        category.push(user.name);

        series[0].value.push(user.status[CallStatus.ANSWERED]);
        series[1].value.push(user.status[CallStatus.NO_ANSWER]);
        series[2].value.push(user.status[CallStatus.BUSY]);
        series[3].value.push(user.status[CallStatus.DITCHED]);
    }

    return {
        category,
        series,
    };
});
</script>

<template>
    <BarChart
        :title="props.title"
        :categoryData="chartData.category"
        :seriesData="chartData.series"
    />
</template>
