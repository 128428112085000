import { defineComponent as _defineComponent } from 'vue'
import { computed, PropType } from 'vue';
import moment from 'moment';

import { CallAudioDownloadButton } from '@/views/pages/call/components';
import {
    CalculatorIcon,
    CalendarIcon,
    ClockIcon,
    PhoneIcon,
    PhoneIncomingIcon,
    PhoneOutgoingIcon,
    PlayIcon,
    TagIcon,
    UsersIcon,
} from '@heroicons/vue/solid';
import { CallStatus, CallStatusClassMap, CallType, CallModel, Permission } from '@/types';
import { can } from '@/composables/useAuth';
import { formatDateAsString, formatSecToTime } from '@/utils/datetime';
import { applyPhoneMask } from '@/utils/number';


export default /*@__PURE__*/_defineComponent({
  __name: 'CallDetails',
  props: {
    call: {
        type: Object as PropType<CallModel>,
        required: true,
    },
    showViewButton: {
        type: Boolean,
        default: true,
    },
    showDownloadButton: {
        type: Boolean,
        default: false,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const duration = computed(() => {
    return moment(props.call?.endedAt).diff(moment(props.call.answeredAt), 'seconds');
});

const waitingTime = computed(() => {
    if (props.call?.status === CallStatus.ANSWERED && props.call.answeredAt) {
        return moment(props.call.answeredAt).diff(moment(props.call?.startedAt), 'seconds');
    }
    return moment(props.call.endedAt).diff(moment(props.call?.startedAt), 'seconds');
});

const agents = computed(() => {
    const agentsNames: string[] = [];
    props.call?.events.forEach((e) => {
        if (e.sourceUser) {
            if (!agentsNames.includes(e.sourceUser.name)) {
                agentsNames.push(e.sourceUser.name);
            }
        }
        if (e.destinationUser) {
            if (!agentsNames.includes(e.destinationUser.name)) {
                agentsNames.push(e.destinationUser.name);
            }
        }
    });
    return agentsNames;
});

const sourceEvent = computed(() => (props.call.events.length ? props.call.events[0] : null));

const destinationEvent = computed(() => {
    if (props.call?.type === CallType.INBOUND && props.call.status === CallStatus.ANSWERED) {
        return props.call?.events.find((event) => event.status === props.call.status);
    }
    return props.call?.events[props.call.events.length - 1];
});

const showCallAudioDownloadButton = computed(
    () =>
        props.showDownloadButton && props.call.audioFilePath && can(Permission.DOWNLOAD_CALL_AUDIO),
);

function formatDestinationNumber(number: string) {
    const pattern = /(\d+)_(\d+)_(\d+)/; // Pattern to match the "211_1_1" like strings
    const match = number.match(pattern);
    if (match) {
        return match[1];
    } else {
        return applyPhoneMask(number);
    }
}

const __returned__ = { props, duration, waitingTime, agents, sourceEvent, destinationEvent, showCallAudioDownloadButton, formatDestinationNumber, get CallAudioDownloadButton() { return CallAudioDownloadButton }, get CalculatorIcon() { return CalculatorIcon }, get CalendarIcon() { return CalendarIcon }, get ClockIcon() { return ClockIcon }, get PhoneIcon() { return PhoneIcon }, get PhoneIncomingIcon() { return PhoneIncomingIcon }, get PhoneOutgoingIcon() { return PhoneOutgoingIcon }, get PlayIcon() { return PlayIcon }, get TagIcon() { return TagIcon }, get UsersIcon() { return UsersIcon }, get CallStatus() { return CallStatus }, get CallStatusClassMap() { return CallStatusClassMap }, get CallType() { return CallType }, get Permission() { return Permission }, get formatDateAsString() { return formatDateAsString }, get formatSecToTime() { return formatSecToTime }, get applyPhoneMask() { return applyPhoneMask } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})