import { ListFilters, MultiSelectOption } from '@/types';

export interface QueueFilter {
    isActive: MultiSelectOption | null;
}

export interface QueueState extends ListFilters {
    filter: QueueFilter;
}

export enum QueueMutations {
    SET_SEARCH = 'queue_setSearch',
    SET_SORT = 'queue_setSort',
    SET_PAGE = 'queue_setPage',
    SET_FILTER = 'queue_setFilter',
    CLEAR_FILTER = 'queue_clearFilter',
}

export enum QueueActions {
    SET_SEARCH = 'queue_setSearch',
    SET_SORT = 'queue_setSort',
    SET_PAGE = 'queue_setPage',
    SET_FILTER = 'queue_setFilter',
    CLEAR_FILTER = 'queue_clearFilter',
}

export enum QueueGetters {
    SEARCH = 'queue_search',
    SORT = 'queue_sort',
    PAGE = 'queue_page',
    DEFAULT_FILTER = 'queue_defaultFilter',
    FILTER = 'queue_filter',
    ACTIVE_FILTERS = 'queue_activeFilters',
}
