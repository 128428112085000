<template>
    <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
            <slot name="info"></slot>
        </div>
        <div class="mt-4 flex items-center sm:ml-16 sm:mt-0">
            <slot name="actions"></slot>
        </div>
    </div>
</template>
