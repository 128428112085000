import { defineComponent as _defineComponent } from 'vue'
import dbounce from 'debounce';
import { SearchIcon } from '@heroicons/vue/solid';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiListSearch',
  props: {
    modelValue: {
        type: String,
    },
    debounce: {
        type: Number,
        default: 2000,
    },
    placeholder: {
        type: String,
    },
    class: {
        type: String,
    },
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const { t } = useI18n();

const emit = __emit;

const placeholderText = computed(() => {
    if (props.placeholder !== undefined) {
        return props.placeholder;
    }
    return t('core.actions.Search');
});

const updateValue = (value: string) => {
    emit('update:modelValue', value);
};

const __returned__ = { props, t, emit, placeholderText, updateValue, get dbounce() { return dbounce }, get SearchIcon() { return SearchIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})