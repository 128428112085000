<script lang="ts" setup>
import { computed } from 'vue';
import { CloudUploadIcon, XIcon } from '@heroicons/vue/outline';
import { ErrorObject } from '@vuelidate/core';

// v-model binding for `file`
const file = defineModel<File | null>('file');

const props = defineProps({
    name: String,
    disabled: { type: Boolean, default: false },
    textRule: String,
    errors: { type: Array as () => ErrorObject[], default: () => [] },
    silentErrors: { type: Array as () => ErrorObject[], default: () => [] },
});

function handleAudioUpload(event: Event) {
    const input = event.target as HTMLInputElement;
    file.value = input.files?.[0] || null;
}

function removeFile() {
    file.value = null;
}

const hasErrors = computed(() => props.errors.length > 0 || props.silentErrors.length > 0);

const disabledClass = computed(() =>
    props.disabled ? 'bg-gray-200 text-gray-400 cursor-not-allowed' : 'bg-white',
);
</script>

<template>
    <div
        class="relative flex w-full items-center gap-3 rounded border-0 p-3 text-gray-800 shadow ring-1 ring-inset transition"
        :class="[
            disabledClass,
            hasErrors ? 'ring-danger focus:ring-danger' : 'focus:ring-primary ring-gray-300',
        ]"
    >
        <!-- Upload/Label Section -->
        <label
            :for="props.name"
            class="flex flex-1 cursor-pointer items-center gap-4"
            :class="props.disabled && 'cursor-not-allowed'"
        >
            <CloudUploadIcon class="h-8 w-8 text-gray-300" />

            <div class="flex w-full flex-col">
                <span class="text-sm font-semibold text-gray-600">
                    {{ file?.name || $t('connectFlowAudio.create.fileLabel') }}
                </span>
                <span class="text-[10px] text-gray-500">
                    {{ props.textRule }}
                </span>
            </div>

            <input
                @change="handleAudioUpload"
                :name="props.name"
                type="file"
                :disabled="props.disabled"
                class="sr-only"
            />
        </label>

        <!-- Remove Button -->
        <XIcon
            v-if="file && !props.disabled"
            class="h-5 w-5 cursor-pointer text-gray-400 transition hover:text-red-500"
            @click="removeFile"
        />
    </div>
</template>
