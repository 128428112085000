import { UserModel } from '@/types';

export interface CreatedAudit {
    createdBy: number;
    createdAt: string;
    createdByUser?: UserModel;
}

export interface UpdatedAudit {
    updatedBy?: number;
    updatedAt?: string;
    updatedByUser?: UserModel;
}

export type FullAudit = CreatedAudit & UpdatedAudit;
