<script lang="ts" setup>
import { computed } from 'vue';
import { PropType } from 'vue';

import { RealtimeStatisticsSummary } from '@/types';

const props = defineProps({
    data: {
        type: Object as PropType<RealtimeStatisticsSummary>,
        required: true,
    },
    loading: {
        type: Boolean,
        required: true,
    },
});

const metrics = computed(() => [
    { key: 'callQueue', label: 'report.realtime.callQueue' },
    {
        key: 'callInboundInProgress',
        label: 'report.realtime.callInboundInProgress',
    },
    {
        key: 'callOutboundInProgress',
        label: 'report.realtime.callOutboundInProgress',
    },
    { key: 'userOffline', label: 'report.realtime.devicesOffline' },
    { key: 'averageQuality', label: 'report.realtime.averageQuality' },
]);
</script>

<template>
    <dl class="mt-4 grid grid-cols-2 gap-4" :class="['sm:grid-cols-' + metrics.length]">
        <UiPanel v-for="metric in metrics" :key="metric.key" class="relative">
            <UiSpinner v-if="loading" class="theme-color absolute right-2 top-2 h-4 w-4" />
            <dt class="text-center text-xs font-medium text-gray-500">
                {{ $t(metric.label) }}
            </dt>
            <dd class="mt-1 text-center text-2xl font-semibold text-gray-800">
                {{ props.data?.[metric.key] ?? '-' }}
            </dd>
        </UiPanel>
    </dl>
</template>
