<script lang="ts" setup>
import { ref, computed, watch, ComputedRef, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import moment from 'moment';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import MeetingService from '@/services/api/MeetingService';
import { toastServiceError } from '@/services/core/notification';

import {
    FilterItem,
    LevelModel,
    MeetingModel,
    SortMenuItem,
    MeetingActions,
    MeetingGetters,
} from '@/types';

import {
    MeetingListItem,
    MeetingListSort,
    MeetingListFilters,
} from '@/views/pages/meeting/components';

// Stores & Routes
const route = useRoute();
const router = useRouter();
const uiStore = useUiStore();
const filterStore = useFilterStore();

// Constants
const perPage = 20;

// State
const meetings = ref<MeetingModel[]>([]);
const count = ref(0);

// Computed
const currentPage = computed<number>({
    get: () => store.getters[MeetingGetters.PAGE],
    set: (page: number) => store.dispatch(MeetingActions.SET_PAGE, page),
});

const search = computed<string>({
    get: () => store.getters[MeetingGetters.SEARCH],
    set: (search: string) => store.dispatch(MeetingActions.SET_SEARCH, search),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[MeetingGetters.SORT],
    set: (sort: SortMenuItem[]) => store.dispatch(MeetingActions.SET_SORT, sort),
});

const orderBy = computed<string>(() =>
    sortMenuSelected.value.map((s) => s.direction + s.key).join(','),
);

const levelFilter = computed<LevelModel | null>(() => filterStore.level);

const dateRange = computed<Array<Date>>({
    get: () => filterStore.dateRange,
    set: (dateRange: Array<Date>) => filterStore.setDateRange(dateRange),
});

const platform = computed<string | null>(() => {
    const platformFilter = store.getters[MeetingGetters.FILTER].platform;
    return platformFilter?.value?.toString() ?? null;
});

const activeFilters: ComputedRef<FilterItem[]> = computed(
    () => store.getters[MeetingGetters.ACTIVE_FILTERS],
);

// Methods
const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const handleRemoveSortClick = (item: SortMenuItem) => {
    sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);
};

const handleRemoveFilter = (field: string) => {
    store.dispatch(MeetingActions.CLEAR_FILTER, field);
};

const prepareMeetingParams = () => {
    return {
        search: search.value,
        startDate: moment.utc(dateRange.value[0]).format('YYYY-MM-DD'),
        endDate: moment.utc(dateRange.value[1]).format('YYYY-MM-DD'),
        platform: platform.value,
        page: currentPage.value,
        per_page: perPage,
        order_by: orderBy.value,
    };
};

const getMeetings = async () => {
    const params = prepareMeetingParams();
    if (!params) return;

    try {
        await setLoading(true);
        const response = await MeetingService.getAll({ params });
        meetings.value = response.data;
        count.value = parseInt(response.headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

// Watchers
watch(
    [search, levelFilter, dateRange, orderBy, currentPage, activeFilters],
    () => {
        getMeetings();
    },
    { immediate: true },
);

// Lifecycle
onMounted(() => {
    if (route.query.clearStateOnSetup) {
        currentPage.value = 1;
        router.replace({ query: {} });
    }

    getMeetings();
});
</script>

<template>
    <div class="p-4 sm:p-6 sm:pt-4 lg:p-8 lg:pt-4">
        <UiPageHeader>
            <template #info>
                <h1 class="mb-5 text-xl font-semibold text-gray-800">
                    {{ $t('dashboard.meetings') }}
                </h1>
            </template>
        </UiPageHeader>
        <div class="flex flex-col justify-end lg:flex-row">
            <div class="w-full md:mt-3 lg:mt-0 lg:w-64">
                <UiDatePicker v-model="dateRange" range multiCalendars />
            </div>
        </div>
        <div class="mt-5 w-full">
            <div
                class="flex h-14 rounded bg-gray-50 ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50"
            >
                <div class="flex flex-1 justify-between px-4">
                    <form class="flex flex-1" action="#" method="GET">
                        <UiListSearch v-model="search" />
                    </form>
                    <div class="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
                        <MeetingListSort v-model="sortMenuSelected" />
                        <MeetingListFilters />
                    </div>
                </div>
            </div>
            <div
                v-if="
                    (sortMenuSelected && sortMenuSelected.length) ||
                    (activeFilters && activeFilters.length)
                "
                class="flex gap-3 rounded border-t border-gray-200 bg-gray-50 p-2 ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50"
            >
                <UiSortMenuBadge
                    v-for="selected in sortMenuSelected"
                    :key="selected.key"
                    :item="selected"
                    @remove="handleRemoveSortClick(selected)"
                />
                <template v-for="filter in activeFilters" :key="filter">
                    <UiBadge removable @remove="handleRemoveFilter(filter.field)">
                        {{ $t(`meeting.filters.${filter.field}.label`) }}:
                        {{ filter.label }}
                    </UiBadge>
                </template>
            </div>
            <ul role="list">
                <MeetingListItem
                    v-for="(meeting, index) in meetings"
                    :key="index"
                    :model-value="meeting"
                />
                <li
                    v-if="!meetings.length"
                    class="mt-0.5 flex h-48 flex-col flex-wrap items-center justify-center rounded-b bg-gray-200 font-semibold text-gray-600"
                >
                    {{ $t('core.messages.noRecordsFound') }}
                </li>
            </ul>
            <UiPagination
                v-if="meetings.length"
                v-model="currentPage"
                :count="count"
                :perPage="perPage"
            />
        </div>
    </div>
</template>
