import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { authGuard, levelLockGuard } from '@/router/routeGuards';

import AppTemplate from '@/views/templates/AppTemplate.vue';
import SettingsTemplate from '@/views/templates/SettingsTemplate.vue';

import AuthenticationRoutes from '@/router/modules/authentication';
import CallRoutes from '@/router/modules/call';
import ConnectFlowAudioRoutes from '@/router/modules/connectFlowAudio';
import ConnectFlowRoutes from '@/router/modules/connectFlow';
import ConnectFlowSurveyRoutes from '@/router/modules/connectFlowSurvey';
import ContactRoutes from '@/router/modules/contact';
import HomePage from '@/views/HomePage.vue';
import IntegrationRoutes from '@/router/modules/integration';
import LevelRoutes from '@/router/modules/level';
import MeetingRoutes from '@/router/modules/meeting';
import NotFoundPage from '@/views/NotFoundPage.vue';
import NumberRoutes from '@/router/modules/number';
import PermissionPage from '@/views/pages/permission/PermissionPage.vue';
import ProfilePage from '@/views/pages/profile/ProfilePage.vue';
import QueueRoutes from '@/router/modules/queue';
import ReportRoutes from '@/router/modules/report';
import RoleRoutes from '@/router/modules/role';
import ScheduleRoutes from '@/router/modules/schedule';
import SettingsPage from '@/views/SettingsPage.vue';
import SpamNumberRoutes from '@/router/modules/spamNumber';
import UserLabel from '@/router/modules/userLabel';
import UserRoutes from '@/router/modules/user';
import WordRouter from '@/router/modules/word';

const routes: RouteRecordRaw[] = AuthenticationRoutes;
routes.push({
    name: 'AppTemplate',
    path: '/',
    component: AppTemplate,
    children: [
        {
            name: 'Home',
            path: '',
            component: HomePage,
        },
        {
            name: 'Profile',
            path: '/profile',
            component: ProfilePage,
        },
        ReportRoutes,
        CallRoutes,
        MeetingRoutes,
        {
            path: '/:catchAll(.*)',
            component: NotFoundPage,
        },
    ],
});
routes.push({
    name: 'SettingsTemplate',
    path: '/settings',
    component: SettingsTemplate,
    children: [
        {
            name: 'Settings',
            path: '',
            component: SettingsPage,
        },
        {
            name: 'Permissions',
            path: 'permissions',
            component: PermissionPage,
            meta: { isLevelLocked: true },
        },
        ConnectFlowAudioRoutes,
        ConnectFlowRoutes,
        ConnectFlowSurveyRoutes,
        ContactRoutes,
        IntegrationRoutes,
        LevelRoutes,
        NumberRoutes,
        QueueRoutes,
        RoleRoutes,
        ScheduleRoutes,
        SpamNumberRoutes,
        UserLabel,
        UserRoutes,
        WordRouter,
        {
            path: '/:catchAll(.*)',
            component: NotFoundPage,
            meta: { isLevelLocked: true },
        },
    ],
});

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(authGuard);
router.beforeEach(levelLockGuard);

export default router;
