import { defineComponent as _defineComponent } from 'vue'
import { computed, ComputedRef, onMounted, PropType, Ref, ref, WritableComputedRef } from 'vue';
import {
    ConnectFlowModelingActions,
    ConnectFlowModelingGetters,
    ConnectFlowModelingMutations,
    MultiSelectOption,
    VueFlowNodeModel,
    NodeType,
    VueFlowConnectionModel,
} from '@/types';
import store from '@/store';
import { ReceiptRefundIcon } from '@heroicons/vue/outline';

import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { required } from '@/utils/validators';
import { useI18n } from 'vue-i18n';

interface GoToFormState {
    node: string | null;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'GoTo',
  props: {
    node: {
        type: Object as PropType<VueFlowNodeModel>,
        required: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const showForm = ref(false);

const nodeWritable: WritableComputedRef<VueFlowNodeModel> = computed({
    get: () => store.getters['flow/' + ConnectFlowModelingGetters.ELEMENT_BY_ID](props.node.id),
    set: (value: VueFlowNodeModel) => {
        store.commit('flow/' + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
            idx: null,
            value,
        });
    },
});

const { t } = useI18n();

const elementsOptions = computed(() => {
    const options: MultiSelectOption[] = [];
    store.getters['flow/' + ConnectFlowModelingGetters.ELEMENTS_NODES].forEach(
        (node: VueFlowNodeModel) => {
            if (
                ![NodeType.START, NodeType.OPEN_EDGE, NodeType.GO_TO, NodeType.HANG_UP].includes(
                    node.data.component,
                )
            ) {
                options.push({
                    label: t(`connectFlow.node.${node.data.component}.title`),
                    value: node.id,
                });
            }
        },
    );
    return options;
});
const formState: Ref<GoToFormState> = ref({
    node: null,
});
const rules = computed<ValidationArgs<GoToFormState>>(() => {
    const r: ValidationArgs<GoToFormState> = {
        node: {
            required,
        },
    };
    return r;
});
const v$: Ref<Validation<ValidationArgs<GoToFormState>>> = useVuelidate(rules, formState);

const isFormCorrect = ref(true);

function removeNode() {
    store.dispatch('flow/' + ConnectFlowModelingActions.DELETE_NODE, {
        nodeId: nodeWritable.value.id,
        parentId: nodeWritable.value.parentId,
    });
}
const sourceConnection: ComputedRef<VueFlowConnectionModel | null> = computed(() =>
    store.getters['flow/' + ConnectFlowModelingGetters.ELEMENTS_CONNECTIONS].find(
        (c: VueFlowConnectionModel) => c.target === props.node.id,
    ),
);
async function onSubmit() {
    isFormCorrect.value = await v$.value.$validate();
    if (isFormCorrect.value) {
        nodeWritable.value.data.goToNode = formState.value.node || undefined;
        showForm.value = false;
        const handle = sourceConnection.value?.sourceHandle ?? null;
        store.dispatch('flow/' + ConnectFlowModelingActions.GO_TO_CONNECTION, {
            source: props.node.id,
            target: formState.value.node,
            handle: handle,
        });
    }
    nodeWritable.value.data.hasErrors = !isFormCorrect.value;
    v$.value.$reset();
}

onMounted(() => {
    if (nodeWritable.value.data.goToNode !== undefined) {
        formState.value.node = nodeWritable.value.data.goToNode;
    }

    v$.value.$validate().then((result) => {
        isFormCorrect.value = result;
        nodeWritable.value.data.hasErrors = !isFormCorrect.value;
    });
});

const __returned__ = { props, showForm, nodeWritable, t, elementsOptions, formState, rules, v$, isFormCorrect, removeNode, sourceConnection, onSubmit, get ReceiptRefundIcon() { return ReceiptRefundIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})