import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "align-right flex items-center text-sm text-gray-500"
}
const _hoisted_2 = ["datetime"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.lastModifiedBy && $setup.lastModifiedAt)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode($setup["CalendarIcon"], {
          class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
          "aria-hidden": "true"
        }),
        _createElementVNode("p", null, [
          _createTextVNode(_toDisplayString($setup.t(`core.${$setup.lastModifiedType}`)) + " ", 1 /* TEXT */),
          _createElementVNode("time", { datetime: $setup.lastModifiedAt }, _toDisplayString($setup.formatDateAsString($setup.lastModifiedAt, $setup.resolvedDateFormat)), 9 /* TEXT, PROPS */, _hoisted_2),
          _createTextVNode(" " + _toDisplayString($setup.t('core.by')) + " ", 1 /* TEXT */),
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can($setup.Permission.VIEW_USER) ? $setup.RouterLink : 'span'), {
            to: { name: 'ViewUserById', params: { id: $setup.lastModifiedBy.id } },
            class: "text-primary truncate text-sm font-medium"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.lastModifiedBy.name), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["to"]))
        ])
      ]))
    : _createCommentVNode("v-if", true)
}