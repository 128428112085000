<script lang="ts" setup>
import { computed } from 'vue';
import store from '@/store';
import { UserModel, DashboardItem, AuthGetters, Permission } from '@/types';
import {
    AnnotationIcon,
    BanIcon,
    BookOpenIcon,
    ClockIcon,
    CloudUploadIcon,
    LinkIcon,
    LockClosedIcon,
    MenuAlt2Icon,
    OfficeBuildingIcon,
    PhoneIcon,
    TagIcon,
    UserGroupIcon,
    UsersIcon,
} from '@heroicons/vue/solid';
import ConnectFlowIcon from '@/components/svg/ConnectFlowIcon';

const user = computed<UserModel>(() => store.getters[AuthGetters.USER]);

const links: DashboardItem[] = [
    {
        title: 'dashboard.settings.levels',
        description: 'level.description',
        permission: Permission.VIEW_LEVELS,
        routeName: 'ListLevel',
        icon: OfficeBuildingIcon,
    },
    {
        title: 'dashboard.settings.users',
        description: 'user.description',
        permission: Permission.VIEW_USERS,
        routeName: 'ListUser',
        icon: UsersIcon,
    },
    {
        title: 'dashboard.settings.userLabel',
        description: 'userLabel.description',
        permission: Permission.VIEW_USER_LABELS,
        routeName: 'ListUserLabel',
        icon: UserGroupIcon,
    },
    {
        title: 'dashboard.settings.numbers',
        description: 'number.description',
        permission: Permission.VIEW_NUMBERS,
        routeName: 'ListNumber',
        icon: PhoneIcon,
    },
    {
        title: 'dashboard.settings.contacts',
        description: 'contact.description',
        permission: Permission.VIEW_CONTACTS,
        routeName: 'ListContact',
        icon: BookOpenIcon,
    },
    {
        title: 'dashboard.settings.spamNumber',
        description: 'spamNumber.description',
        permission: Permission.VIEW_SPAM_NUMBERS,
        routeName: 'ListSpamNumber',
        icon: BanIcon,
    },
    {
        title: 'dashboard.settings.connectFlow',
        description: 'connectFlow.description',
        permission: Permission.VIEW_CONNECT_FLOWS,
        routeName: 'ListConnectFlow',
        icon: ConnectFlowIcon,
    },
    {
        title: 'dashboard.settings.connectFlowAudios',
        description: 'connectFlowAudio.description',
        permission: Permission.VIEW_CONNECT_FLOW_AUDIOS,
        routeName: 'ListConnectFlowAudio',
        icon: CloudUploadIcon,
    },
    {
        title: 'dashboard.settings.queues',
        description: 'queue.description',
        permission: Permission.VIEW_QUEUES,
        routeName: 'ListQueue',
        icon: MenuAlt2Icon,
    },
    {
        title: 'dashboard.settings.schedules',
        description: 'schedule.description',
        permission: Permission.VIEW_SCHEDULES,
        routeName: 'ListSchedule',
        icon: ClockIcon,
    },
    {
        title: 'dashboard.settings.surveys',
        description: 'connectFlowSurvey.description',
        permission: Permission.VIEW_CONNECT_FLOW_SURVEYS,
        routeName: 'ListConnectFlowSurvey',
        icon: AnnotationIcon,
    },
    {
        title: 'dashboard.settings.words',
        description: 'word.description',
        permission: Permission.VIEW_WORDS,
        routeName: 'ListWord',
        icon: TagIcon,
    },
    {
        title: 'dashboard.settings.integrations',
        description: 'integration.description',
        permission: Permission.VIEW_INTEGRATIONS,
        routeName: 'ListIntegration',
        icon: LinkIcon,
    },
    {
        title: 'dashboard.settings.roles',
        description: 'role.description',
        permission: Permission.ASSIGN_ROLE_TO_USER,
        routeName: 'Permissions',
        icon: LockClosedIcon,
    },
];
</script>

<template>
    <div class="p-4 sm:p-6 lg:p-8">
        <h1 class="mb-4 text-2xl font-bold text-gray-800 sm:mb-6 lg:mb-8 xl:text-3xl">
            {{ $t('welcomeLogged') }}, {{ user?.name }}.
        </h1>

        <div class="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
            <UiDashboardLink
                v-for="(link, index) in links"
                :key="`dashboard-link-${index}`"
                :routeName="link.routeName"
                :permission="link.permission"
                :title="link.title"
                :description="link.description"
                :icon="link.icon"
            />
        </div>
    </div>
</template>
