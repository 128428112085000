import {
    CallDataModel,
    CallEventModel,
    CallStatus,
    CallTranscriptModel,
    CallType,
    FullAudit,
    CallTagModel,
    CallUraModel,
    ConnectFlowSurveyModel,
    ContactModel,
    LevelModel,
    UserModel,
} from '@/types';

export interface CallModel extends FullAudit {
    id: number;
    level: LevelModel;
    environmentId: string;
    type: CallType;
    startedAt: Date;
    endedAt?: Date;
    answeredAt?: Date;
    sourceNumber: string;
    sourceUserId?: string;
    sourceExtensionNumber?: string;
    destinationUser?: UserModel;
    destinationNumber: string;
    destinationUserId?: string;
    destinationExtensionNumber?: string;
    media?: string;
    adId?: string;
    keyword?: string;
    audioFilePath?: string;
    externalId?: string;
    duration: number;
    billSec?: number;
    geolocation?: string;
    status: CallStatus;
    surveys?: ConnectFlowSurveyModel[];
    ura: CallUraModel[];
    isFirstContact: boolean;
    transcripts?: CallTranscriptModel[];
    tags: CallTagModel[];
    events: CallEventModel[];
    data: CallDataModel;
    contact: ContactModel;
}
