<script lang="ts" setup>
import { computed, PropType } from 'vue';
import moment from 'moment';
import { ScheduleModel, Permission, ScheduleWeekday } from '@/types';
import { ClockIcon, CalendarIcon } from '@heroicons/vue/solid';
import { CheckCircleIcon, XCircleIcon, PencilAltIcon } from '@heroicons/vue/outline';
import UiAuditInfo from '@/components/UiAuditInfo.vue';

const props = defineProps({
    modelValue: {
        type: Object as PropType<ScheduleModel>,
        required: true,
    },
});

const weekdays: ScheduleWeekday[] = [
    ScheduleWeekday.SUNDAY,
    ScheduleWeekday.MONDAY,
    ScheduleWeekday.TUESDAY,
    ScheduleWeekday.WEDNESDAY,
    ScheduleWeekday.THURSDAY,
    ScheduleWeekday.FRIDAY,
    ScheduleWeekday.SATURDAY,
];

const activeDays = computed(() => {
    return weekdays.filter((day: ScheduleWeekday) => props.modelValue[day]);
});

// Format day label using moment (translated by app locale)
const formatDayLabel = (day: ScheduleWeekday) => {
    return moment().day(weekdays.indexOf(day)).format('dddd');
};

const emit = defineEmits(['activate', 'inactivate']);

const handleActivateClick = () => emit('activate', props.modelValue);
const handleInactivateClick = () => emit('inactivate', props.modelValue);
</script>

<template>
    <UiPanel class="hover:bg-white">
        <div class="flex items-center justify-between">
            <div class="flex flex-1 items-center">
                <component
                    :is="$can(Permission.VIEW_SCHEDULE) ? 'RouterLink' : 'span'"
                    :to="{
                        name: 'ViewScheduleById',
                        params: {
                            id: modelValue.id,
                        },
                    }"
                    class="text-md text-primary truncate font-medium"
                >
                    {{ modelValue.name }}
                </component>
                <UiIsActiveBadge :model-value="modelValue" class="ml-2 text-xs" />
            </div>
            <div v-if="$can(Permission.EDIT_SCHEDULE)" class="flex space-x-1">
                <UiActionButton
                    v-if="modelValue.isActive"
                    v-tippy="$t('core.actions.Inactivate')"
                    variant="danger"
                    @click="handleInactivateClick"
                >
                    <span class="sr-only"> {{ $t('core.actions.Inactivate') }} </span>
                    <XCircleIcon class="h-6 w-6" aria-hidden="true" />
                </UiActionButton>
                <UiActionButton
                    v-else
                    v-tippy="$t('core.actions.Activate')"
                    variant="success"
                    @click="handleActivateClick"
                >
                    <span class="sr-only"> {{ $t('core.actions.Activate') }} </span>
                    <CheckCircleIcon class="h-6 w-6" aria-hidden="true" />
                </UiActionButton>
                <UiActionButton
                    v-tippy="$t('core.actions.Edit')"
                    variant="primary"
                    class="rounded-full"
                    :to="{
                        name: 'EditScheduleById',
                        params: {
                            id: modelValue.id,
                        },
                    }"
                >
                    <span class="sr-only"> {{ $t('core.actions.Edit') }} </span>
                    <PencilAltIcon class="h-6 w-6" aria-hidden="true" />
                </UiActionButton>
            </div>
        </div>
        <div class="mt-2 space-y-2 sm:flex sm:justify-between sm:space-y-0">
            <div class="space-y-2 sm:flex sm:space-x-4 sm:space-y-0">
                <p class="flex items-center text-sm text-gray-500">
                    <ClockIcon
                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                    />
                    {{ props.modelValue.timeBegin.substring(0, 5) }} -
                    {{ props.modelValue.timeEnd.substring(0, 5) }}
                </p>
                <p class="flex flex-wrap gap-2 text-sm text-gray-500">
                    <CalendarIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <span
                        v-for="day in activeDays"
                        :key="day"
                        class="whitespace-nowrap rounded border bg-white px-1.5 py-0.5 text-xs text-gray-500"
                    >
                        {{ formatDayLabel(day) }}
                    </span>
                </p>
            </div>
            <UiAuditInfo :model-value="modelValue" />
        </div>
    </UiPanel>
</template>
