<script lang="ts" setup>
import { ref, Ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import moment from 'moment';

import { useUiStore } from '@/stores';
import ScheduleService from '@/services/api/ScheduleService';
import { toastServiceError } from '@/services/core/notification';

import { Permission, ScheduleModel, ScheduleWeekday } from '@/types';

const route = useRoute();
const uiStore = useUiStore();

const schedule: Ref<ScheduleModel | undefined> = ref(undefined);

const weekdays: ScheduleWeekday[] = [
    ScheduleWeekday.SUNDAY,
    ScheduleWeekday.MONDAY,
    ScheduleWeekday.TUESDAY,
    ScheduleWeekday.WEDNESDAY,
    ScheduleWeekday.THURSDAY,
    ScheduleWeekday.FRIDAY,
    ScheduleWeekday.SATURDAY,
];

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchScheduleById = async (id: number) => {
    try {
        setLoading(true);
        const { data } = await ScheduleService.get<ScheduleModel>(id);
        schedule.value = data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const activeDays = computed(() => weekdays.filter((day: ScheduleWeekday) => schedule.value?.[day]));

const formatDayLabel = (day: ScheduleWeekday) => {
    return moment().day(weekdays.indexOf(day)).format('dddd');
};

onMounted(() => {
    const id = Number(route.params.id);
    if (!isNaN(id)) fetchScheduleById(id);
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('schedule.view.title') }}
                </h1>
            </template>
            <template #actions>
                <div class="flex space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ListSchedule' }" />
                    <router-link
                        v-if="$can(Permission.EDIT_SCHEDULE)"
                        :to="{
                            name: 'EditScheduleById',
                            params: {
                                id: route.params.id,
                            },
                        }"
                        class="block hover:bg-gray-50"
                    >
                        <UiButton variant="primary" text-variant="white">
                            {{ $t('core.actions.Edit') }}
                        </UiButton>
                    </router-link>
                </div>
            </template>
        </UiPageHeader>

        <div class="space-y-4">
            <UiPanel>
                <div class="gap-4 sm:grid-cols-2 md:grid lg:grid-cols-3">
                    <div>
                        <h3 class="mb-1 font-semibold">
                            {{ $t('schedule.labels.name') }}
                        </h3>
                        <p>{{ schedule?.name }}</p>
                    </div>
                    <div>
                        <h3 class="mb-1 font-semibold">
                            {{ $t('schedule.labels.timeBegin') }}
                        </h3>
                        <p>{{ schedule?.timeBegin.substring(0, 5) }}</p>
                    </div>
                    <div>
                        <h3 class="mb-1 font-semibold">
                            {{ $t('schedule.labels.timeEnd') }}
                        </h3>
                        <p>{{ schedule?.timeEnd.substring(0, 5) }}</p>
                    </div>
                    <div class="sm:col-span-2 lg:col-span-3">
                        <h3 class="mb-1 font-semibold">
                            {{ $t('schedule.labels.activeDays') }}
                        </h3>
                        <div class="space-x-2 text-xs text-gray-500">
                            <span
                                v-for="day in activeDays"
                                :key="day"
                                class="rounded border bg-white px-1.5 py-0.5"
                            >
                                {{ formatDayLabel(day) }}
                            </span>
                        </div>
                    </div>
                </div>
                <UiAuditStatusInfo
                    :model-value="schedule"
                    class="bt-1 mt-4 border-t border-gray-300 pt-2"
                />
            </UiPanel>
        </div>
    </div>
</template>
