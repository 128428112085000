import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "space-y-4 p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "flex items-center text-xl font-semibold text-gray-800" }
const _hoisted_3 = { class: "flex items-center space-x-4" }
const _hoisted_4 = { class: "space-y-2" }
const _hoisted_5 = { class: "flex items-center space-x-4" }
const _hoisted_6 = { class: "min-w-full divide-y divide-gray-300" }
const _hoisted_7 = { class: "bg-gray-200" }
const _hoisted_8 = { class: "divide-y divide-gray-200" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiBackButton = _resolveComponent("UiBackButton")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!
  const _component_UiListSearch = _resolveComponent("UiListSearch")!
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _component_UiSortMenuBadge = _resolveComponent("UiSortMenuBadge")!
  const _component_UiBadge = _resolveComponent("UiBadge")!
  const _component_UiPagination = _resolveComponent("UiPagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, [
          _createVNode($setup["LinkIcon"], {
            class: "mr-1 h-6 w-6 text-gray-500",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('core.entity.Integrations')) + " - " + _toDisplayString(_ctx.$t('integration.integrations.' + $setup.props.type + '.title')) + " - " + _toDisplayString(_ctx.$t('integration.labels.logs')), 1 /* TEXT */)
        ])
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_UiBackButton, { fallbackTo: { name: 'ListIntegration' } })
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_UiPanel, {
        class: "flex px-4 py-2",
        "no-padding": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_UiListSearch, {
            modelValue: $setup.search,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.search) = $event)),
            placeholder: _ctx.$t('core.actions.Search'),
            class: "flex-1"
          }, null, 8 /* PROPS */, ["modelValue", "placeholder"]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode($setup["IntegrationLogListSort"], {
              modelValue: $setup.sortMenuSelected,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.sortMenuSelected) = $event))
            }, null, 8 /* PROPS */, ["modelValue"]),
            _createVNode($setup["IntegrationLogListFilters"])
          ])
        ]),
        _: 1 /* STABLE */
      }),
      ($setup.sortMenuSelected.length || $setup.activeFilters.length)
        ? (_openBlock(), _createBlock(_component_UiPanel, {
            key: 0,
            class: "space-x-2"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sortMenuSelected, (selected) => {
                return (_openBlock(), _createBlock(_component_UiSortMenuBadge, {
                  key: selected.key,
                  item: selected,
                  onRemove: ($event: any) => ($setup.handleRemoveSortClick(selected))
                }, null, 8 /* PROPS */, ["item", "onRemove"]))
              }), 128 /* KEYED_FRAGMENT */)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.activeFilters, (item) => {
                return (_openBlock(), _createBlock(_component_UiBadge, {
                  key: `activeFilter${item.field}`,
                  removable: "",
                  onRemove: ($event: any) => ($setup.handleRemoveFilterClick(item))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t(`integration.logs.filters.${item.field}.label`)) + ": " + _toDisplayString(item.label), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onRemove"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      ($setup.integrationLogs.length)
        ? (_openBlock(), _createBlock(_component_UiPanel, {
            key: 1,
            "no-padding": "",
            rounded: false
          }, {
            default: _withCtx(() => [
              _createElementVNode("table", _hoisted_6, [
                _createElementVNode("thead", null, [
                  _createElementVNode("tr", _hoisted_7, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.cols, (col) => {
                      return _createElementVNode("th", {
                        key: col,
                        scope: "col",
                        class: "whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-800"
                      }, _toDisplayString(_ctx.$t(`integration.logs.cols.${col}`)), 1 /* TEXT */)
                    }), 64 /* STABLE_FRAGMENT */))
                  ])
                ]),
                _createElementVNode("tbody", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.integrationLogs, (log) => {
                    return (_openBlock(), _createBlock($setup["IntegrationLogListItem"], {
                      key: log.id,
                      "model-value": log,
                      class: "even:bg-gray-100"
                    }, null, 8 /* PROPS */, ["model-value"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ])
              ])
            ]),
            _: 1 /* STABLE */
          }))
        : (_openBlock(), _createBlock(_component_UiPanel, {
            key: 2,
            class: "flex items-center justify-center"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('core.messages.noRecordsFound')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })),
      ($setup.integrationLogs.length)
        ? (_openBlock(), _createBlock(_component_UiPagination, {
            key: 3,
            modelValue: $setup.currentPage,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.currentPage) = $event)),
            count: $setup.count,
            perPage: $setup.perPage
          }, null, 8 /* PROPS */, ["modelValue", "count"]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}