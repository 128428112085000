<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useRoute, RouterLink } from 'vue-router';

import { useUiStore } from '@/stores';
import ConnectFlowAudioService from '@/services/api/ConnectFlowAudioService';
import VaultService from '@/services/api/VaultService';
import { toastServiceError } from '@/services/core/notification';

import { ConnectFlowAudioModel, Permission } from '@/types';
import { getAudioFormat } from '@/utils/audio';

const route = useRoute();
const uiStore = useUiStore();

const connectFlowAudio = ref<ConnectFlowAudioModel | undefined>(undefined);
const audioData = ref();

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchConnectFlowAudio = async (id: number) => {
    await setLoading(true);
    try {
        const { data } = await ConnectFlowAudioService.get<ConnectFlowAudioModel>(id);
        connectFlowAudio.value = data;

        const { data: audioAsset } = await VaultService.getAudioAsset(
            connectFlowAudio.value.audioFilePath,
        );

        audioData.value = URL.createObjectURL(
            new Blob([audioAsset], {
                type: getAudioFormat(connectFlowAudio.value?.audioFilePath),
            }),
        );
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

onMounted(() => {
    const id = Number(route.params.id);
    fetchConnectFlowAudio(id);
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('connectFlowAudio.view.title') }}
                </h1>
            </template>
            <template #actions>
                <div class="flex space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ListConnectFlowAudio' }" />
                    <router-link
                        v-if="$can(Permission.EDIT_CONNECT_FLOW_AUDIO)"
                        :to="{ name: 'EditConnectFlowAudioById', params: { id: route.params.id } }"
                        class="block hover:bg-gray-50"
                    >
                        <UiButton variant="primary" text-variant="white">
                            {{ $t('core.actions.Edit') }}
                        </UiButton>
                    </router-link>
                </div>
            </template>
        </UiPageHeader>

        <div class="space-y-4">
            <UiPanel>
                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                    <div>
                        <h3 class="mb-1 font-semibold">
                            {{ $t('connectFlowAudio.labels.name') }}
                        </h3>
                        <p>{{ connectFlowAudio?.name }}</p>
                    </div>
                    <div v-if="audioData">
                        <h3 class="mb-1 font-semibold">
                            {{ $t('connectFlowAudio.labels.file') }}
                        </h3>
                        <audio controls>
                            <source
                                :src="audioData"
                                :type="getAudioFormat(connectFlowAudio?.audioFilePath || '')"
                            />
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                </div>
                <UiAuditStatusInfo
                    :model-value="connectFlowAudio"
                    class="bt-1 mt-4 border-t border-gray-300 pt-2"
                />
            </UiPanel>
        </div>
    </div>
</template>
