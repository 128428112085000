import { createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex min-h-screen items-center justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0" }
const _hoisted_2 = { class: "relative inline-block w-52 transform overflow-hidden rounded bg-gray-50 px-4 pb-4 pt-5 text-left align-bottom shadow transition-all sm:my-8 sm:p-6 sm:align-middle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiSpinner = _resolveComponent("UiSpinner")!

  return (_openBlock(), _createBlock($setup["TransitionRoot"], {
    as: "template",
    show: $props.show
  }, {
    default: _withCtx(() => [
      _createVNode($setup["Dialog"], {
        as: "div",
        class: "fixed inset-0 z-50 overflow-y-auto"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode($setup["TransitionChild"], {
              as: "template",
              enter: "ease-out duration-300",
              "enter-from": "opacity-0",
              "enter-to": "opacity-100",
              leave: "ease-in duration-200",
              "leave-from": "opacity-100",
              "leave-to": "opacity-0"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["DialogOverlay"], { class: "fixed inset-0 bg-gray-500/75 transition-opacity" })
              ]),
              _: 1 /* STABLE */
            }),
            _createCommentVNode(" This element is to trick the browser into centering the modal contents. "),
            _cache[0] || (_cache[0] = _createElementVNode("span", {
              class: "hidden sm:inline-block sm:h-screen sm:align-middle",
              "aria-hidden": "true"
            }, "​", -1 /* HOISTED */)),
            _createVNode($setup["TransitionChild"], {
              as: "template",
              enter: "ease-out duration-300",
              "enter-from": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
              "enter-to": "opacity-100 translate-y-0 sm:scale-100",
              leave: "ease-in duration-200",
              "leave-from": "opacity-100 translate-y-0 sm:scale-100",
              "leave-to": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode($setup["DialogTitle"], { class: "text-lg font-medium text-gray-700" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_UiSpinner, { class: "theme-color float-left mr-3 h-10 w-10" }),
                      _createTextVNode(" " + _toDisplayString($props.text !== undefined ? $props.text : _ctx.$t('loading')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("button", null, "This is a focusable element.", -1 /* HOISTED */))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["show"]))
}