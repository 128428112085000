import { CallType, CallStatus, CallsStatsPerDay, CallsStats } from '@/types';

export interface ContactCenterPerDayReportServiceGetInfoParams {
    startDate: string;
    endDate: string;
    type: CallType | null;
    status: CallStatus | null;
    user: number | null;
    number: string | null;
    serviceTimeRange: string | null;
    userLabel: number | null;
}

export interface ContactCenterPerDayReportServiceGetInfoResult {
    stats: CallsStats;
    callsStatsPerDay: CallsStatsPerDay[];
}
