import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import ContactService from '@/services/api/ContactService';
import { toastServiceError } from '@/services/core/notification';

import {
    ContactActions,
    ContactGetters,
    ContactModel,
    ContactServiceGetAllParams,
    SortMenuItem,
    Permission,
} from '@/types';

import { ContactListItem, ContactListSort } from '@/views/pages/contact/components';

import { OfficeBuildingIcon } from '@heroicons/vue/solid';

const perPage = 10;


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();

const filterStore = useFilterStore();
const uiStore = useUiStore();

const contacts = ref<ContactModel[]>([]);
const count = ref(0);
const currentPage = computed({
    get: () => store.getters[ContactGetters.PAGE],
    set: (value: number) => store.dispatch(ContactActions.SET_PAGE, value),
});

const search = computed({
    get: () => store.getters[ContactGetters.SEARCH],
    set: (value: string) => store.dispatch(ContactActions.SET_SEARCH, value),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[ContactGetters.SORT],
    set: (value: SortMenuItem[]) => store.dispatch(ContactActions.SET_SORT, value),
});

const levelFilter = computed(() => filterStore.level);

const orderBy = computed(() => sortMenuSelected.value.map((s) => s.direction + s.key).join(','));

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getParams = (): ContactServiceGetAllParams => ({
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    search: search.value,
});

const fetchContacts = async () => {
    await setLoading(true);
    try {
        const { data, headers } = await ContactService.getAll<ContactModel[]>({
            params: getParams(),
        });
        contacts.value = data;
        count.value = parseInt(headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const handleRemoveSortClick = (item: SortMenuItem) => {
    const index = sortMenuSelected.value.indexOf(item);
    if (index > -1) sortMenuSelected.value.splice(index, 1);
};

watch([currentPage], () => {
    fetchContacts();
});

watch([levelFilter, search, orderBy], () => {
    if (currentPage.value === 1) fetchContacts();
    else currentPage.value = 1;
});

if (route.query.clearStateOnSetup) {
    search.value = '';
    sortMenuSelected.value = [];
    currentPage.value = 1;
    router.replace({ query: {} });
}

onMounted(fetchContacts);

const __returned__ = { route, router, filterStore, uiStore, contacts, count, perPage, currentPage, search, sortMenuSelected, levelFilter, orderBy, setLoading, getParams, fetchContacts, handleRemoveSortClick, get Permission() { return Permission }, get ContactListItem() { return ContactListItem }, get ContactListSort() { return ContactListSort }, get OfficeBuildingIcon() { return OfficeBuildingIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})