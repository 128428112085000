import { defineComponent as _defineComponent } from 'vue'
import { computed, Ref, ref, watch } from 'vue';
import store from '@/store';

import { FilterIcon } from '@heroicons/vue/solid';
import {
    MultiSelectOption,
    IntegrationLogFilter,
    IntegrationLogGetters,
    IntegrationLogActions,
} from '@/types';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'IntegrationLogListFilters',
  setup(__props, { expose: __expose }) {
  __expose();

const filterOpened = ref(false);

const defaultFilter = computed(() => store.getters[IntegrationLogGetters.DEFAULT_FILTER]);

const filter: Ref<IntegrationLogFilter> = ref({
    status: defaultFilter.value.status,
});

const storeFilter = computed(() => store.getters[IntegrationLogGetters.FILTER]);

const { t } = useI18n();

const statusFilterOptions = [
    {
        label: t('integration.logs.filters.status.options.ok'),
        value: 'ok',
    },
    {
        label: t('integration.logs.filters.status.options.nok'),
        value: 'nok',
    },
] as MultiSelectOption[];

function applyFilters() {
    store.dispatch(IntegrationLogActions.SET_FILTER, filter.value);
}

function clearFilters() {
    store.dispatch(IntegrationLogActions.CLEAR_FILTER);
    refreshLocalFilter();
}
function refreshLocalFilter() {
    filter.value.status = storeFilter.value.status;
}

watch(filterOpened, () => {
    refreshLocalFilter();
});

const __returned__ = { filterOpened, defaultFilter, filter, storeFilter, t, statusFilterOptions, applyFilters, clearFilters, refreshLocalFilter, get FilterIcon() { return FilterIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})