import { CallsStats } from '@/types';

export interface UserAgentCallsStats extends CallsStats {
    id: number;
    name: string;
    averageDuration: number;
    averageTimeAvailable?: number;
    averageTotalIdleTime?: number;
    averageIdleTime?: number;
}
