import { RouteRecordRaw } from 'vue-router';
import { validateIdAsPositiveIntegerGuard } from '@/router/routeGuards';
import SpamNumberPage from '@/views/pages/spam-number/SpamNumberPage.vue';
import SpamNumberDetailPage from '@/views/pages/spam-number/SpamNumberDetailPage.vue';
import SpamNumberFormPage from '@/views/pages/spam-number/SpamNumberFormPage.vue';
import { FormAction } from '@/types';

const numberRouter: RouteRecordRaw = {
    name: 'SpamNumber',
    path: 'spam-number',
    children: [
        {
            name: 'ListSpamNumber',
            path: '',
            component: SpamNumberPage,
        },
        {
            name: 'ViewSpamNumberById',
            path: ':id',
            component: SpamNumberDetailPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            meta: { isLevelLocked: true },
        },
        {
            name: 'EditSpamNumberById',
            path: ':id/edit',
            component: SpamNumberFormPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            props: { action: FormAction.EDIT },
            meta: { isLevelLocked: true },
        },
        {
            name: 'CreateSpamNumber',
            path: 'create',
            component: SpamNumberFormPage,
            props: { action: FormAction.CREATE },
            meta: { isLevelLocked: true },
        },
    ],
};

export default numberRouter;
