<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

import { useUiStore } from '@/stores';
import { UserService, NumberService } from '@/services';
import { toastServiceError } from '@/services/core/notification';
import { UserModel, NumberModel, Permission } from '@/types';

import { DesktopComputerIcon, DeviceMobileIcon } from '@heroicons/vue/solid';
import SVGDeviceExternal from '@/components/svg/SVGDeviceExternal.vue';
import SVGChromeLogo from '@/components/svg/SVGChromeLogo.vue';

const route = useRoute();
const { t } = useI18n();

const uiStore = useUiStore();

const user = ref<UserModel | null>(null);
const numbersRaw = ref<NumberModel[]>([]);
const isLoading = ref(false);

const setLoading = (loading: boolean) => {
    isLoading.value = loading;
    uiStore.setIsLoading(loading);
};

const fetchUserAndNumbers = async (id: number) => {
    setLoading(true);
    try {
        const userResponse = await UserService.get<UserModel>(id);
        user.value = userResponse.data;

        if (user.value?.levels?.length) {
            const numbersResponse = await NumberService.getAll<NumberModel[]>({
                params: { levels: user.value.levels },
            });
            numbersRaw.value = numbersResponse.data ?? [];
        }
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const getLevelName = (levelID: number | undefined) =>
    computed(() => user.value?.levels?.find((level) => level.id === levelID)?.name || '');

const getBinaNumber = (levelID: number | undefined, binaID: number | undefined) =>
    computed(() => {
        if (!levelID || !binaID || numbersRaw.value.length === 0) return t('core.random');
        const number = numbersRaw.value.find((n) => n.level?.id === levelID && n.id === binaID);
        return number?.number ?? t('core.random');
    });

onMounted(() => {
    fetchUserAndNumbers(Number(route.params.id));
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('user.view.title') }}
                </h1>
            </template>
            <template #actions>
                <div class="flex items-center space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ListUser' }" />
                    <router-link
                        v-if="$can(Permission.EDIT_USER)"
                        :to="{
                            name: 'EditUserById',
                            params: {
                                id: route.params.id,
                            },
                        }"
                        class="block hover:bg-gray-50"
                    >
                        <UiButton variant="primary" text-variant="white">
                            {{ $t('core.actions.Edit') }}
                        </UiButton>
                    </router-link>
                </div>
            </template>
        </UiPageHeader>

        <div class="space-y-4">
            <UiPanel>
                <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
                    <div>
                        <h3 class="mb-1 font-semibold">{{ $t('user.labels.name') }}</h3>
                        <p>{{ user?.name }}</p>
                    </div>
                    <div>
                        <h3 class="mb-1 font-semibold">{{ $t('user.labels.email') }}</h3>
                        <p>{{ user?.email }}</p>
                    </div>
                    <div>
                        <h3 class="mb-1 font-semibold">{{ $t('user.labels.levels') }}</h3>
                        <p>
                            <span
                                v-for="level of user?.levels"
                                :key="`level${level.id}`"
                                class="mr-3 inline-flex rounded-full bg-gray-50 px-2 text-sm font-semibold text-gray-700"
                            >
                                {{ level.name }}
                            </span>
                        </p>
                    </div>
                    <div>
                        <h3 class="mb-1 font-semibold">
                            {{ $t('user.labels.standardLevel') }}
                        </h3>
                        <p>
                            {{ getLevelName(user?.standardLevelId) }}
                        </p>
                    </div>
                    <div>
                        <h3 class="mb-1 font-semibold">{{ $t('user.labels.roles') }}</h3>
                        <p>
                            <span
                                v-for="roles of user?.roles"
                                :key="`roles${roles.id}`"
                                class="mr-3 inline-flex rounded-full bg-gray-50 px-2 text-sm font-semibold text-gray-700"
                            >
                                {{ roles.name }}
                            </span>
                        </p>
                    </div>
                </div>
                <UiAuditStatusInfo
                    :model-value="user"
                    class="bt-1 mt-4 border-t border-gray-300 pt-2"
                />
            </UiPanel>

            <UiPanel>
                <h3 class="mb-4 text-base font-semibold text-gray-800">
                    {{ $t('user.labels.devices.title') }}
                </h3>
                <div class="relative grid grid-cols-2 gap-4 lg:grid-cols-4 xl:grid-cols-6">
                    <div
                        :class="[
                            user?.devices?.mobile
                                ? 'bg-cyan-50 text-cyan-600'
                                : 'bg-gray-50 text-gray-400',
                            'relative flex h-40 w-40 flex-col items-center rounded shadow ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50',
                        ]"
                    >
                        <DeviceMobileIcon class="mt-6 h-20 w-20" />
                        <p class="font-medium">Mobile</p>
                    </div>
                    <div
                        :class="[
                            user?.devices?.desktop
                                ? 'bg-cyan-50 text-cyan-600'
                                : 'bg-gray-50 text-gray-400',
                            'relative flex h-40 w-40 flex-col items-center rounded shadow ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50',
                        ]"
                    >
                        <DesktopComputerIcon class="mt-6 h-20 w-20" />
                        <p class="font-medium">Desktop</p>
                    </div>
                    <div
                        :class="[
                            user?.devices?.web
                                ? 'bg-cyan-50 text-cyan-600'
                                : 'bg-gray-50 text-gray-400',
                            'relative flex h-40 w-40 flex-col items-center rounded shadow ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50',
                        ]"
                    >
                        <SVGChromeLogo class="mt-6 h-20 w-20" />
                        <p class="font-medium">Web</p>
                    </div>
                    <div
                        :class="[
                            user?.devices?.external
                                ? 'bg-cyan-50 text-cyan-600'
                                : 'bg-gray-50 text-gray-400',
                            'relative flex h-40 w-40 flex-col items-center rounded shadow ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50',
                        ]"
                    >
                        <SVGDeviceExternal class="mt-6 h-20 w-20" />
                        <p class="font-medium">Externo</p>
                        <div
                            v-if="user?.deviceExternal"
                            class="absolute -right-[215px] bottom-0 mt-6 flex flex-col justify-between rounded border-b-4 border-r-4 border-cyan-600 bg-gray-50 px-4 py-3 text-sm text-gray-600 shadow"
                        >
                            <h3 class="mb-1 text-base font-semibold">Configurações do PABX</h3>
                            <p>
                                <span class="font-semibold">Hostname:</span>
                                {{ user?.deviceExternal.hostname }}
                            </p>
                            <p>
                                <span class="font-semibold">Username:</span>
                                {{ user?.deviceExternal.sipUser }}
                            </p>
                            <p>
                                <span class="font-semibold">Password:</span>
                                {{ user?.deviceExternal.sipPassword }}
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    class="relative mt-5 grid grid-cols-1 gap-4 md:grid-cols-6"
                    v-if="user?.devices"
                >
                    <div>
                        <p class="text-base font-semibold text-gray-800">
                            {{ $t('user.labels.extension.number') }}
                        </p>
                        <p>{{ user?.extension?.number }}</p>
                    </div>
                    <div>
                        <p class="text-base font-semibold text-gray-800">
                            {{ $t('user.labels.number') }}
                        </p>
                        <pre>{{
                            getBinaNumber(user?.standardLevelId, user?.number?.numberId)
                        }}</pre>
                    </div>
                </div>
            </UiPanel>
        </div>
    </div>
</template>
