<script lang="ts" setup>
import { ref, Ref, computed, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

import { useFilterStore, useUiStore } from '@/stores';
import { IntegrationService } from '@/services';
import { toastServiceError } from '@/services/core/notification';

import { IntegrationWidget } from './components';
import { LevelModel, IntegrationModel, IntegrationType } from '@/types';

import { LinkIcon } from '@heroicons/vue/solid';

const router = useRouter();
const { t } = useI18n();
const filterStore = useFilterStore();
const uiStore = useUiStore();

const integrations: Ref<IntegrationModel[]> = ref([]);

const integrationInfo = {
    [IntegrationType.Pipedrive]: {
        logoPath: '/img/integration/pipedrive.png',
        description: t('integration.integrations.pipedrive.description'),
    },
    [IntegrationType.OpenAi]: {
        logoPath: '/img/integration/openai.png',
        description: t('integration.integrations.openai.description'),
    },
};

const configuredIntegrations = computed<IntegrationModel[]>(() =>
    integrations.value.filter((integration) => integrationInfo[integration.type]),
);

const levelFilter = computed<LevelModel | null>(() => filterStore.level);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getIntegrations = async () => {
    try {
        await setLoading(true);
        const response = await IntegrationService.getAll<Array<IntegrationModel>>();
        integrations.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const handleConfigClick = (integration: IntegrationModel) => {
    router.push({
        name: 'EditIntegrationById',
        params: {
            type: integration.type,
            id: integration.id,
        },
    });
};

const handleViewLogsClick = (integration: IntegrationModel) => {
    router.push({
        name: 'ViewIntegrationLogs',
        params: {
            type: integration.type,
            id: integration.id,
        },
    });
};

const handleActivateClick = (integration: IntegrationModel) => {
    router.push({
        name: 'CreateIntegration',
        params: {
            type: integration.type,
        },
    });
};

watch([levelFilter], getIntegrations, { deep: true });

onMounted(getIntegrations);
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="flex items-center text-xl font-semibold text-gray-800">
                    <LinkIcon class="mr-1 h-6 w-6 text-gray-500" aria-hidden="true" />
                    {{ $t('core.entity.Integrations') }}
                </h1>
            </template>
        </UiPageHeader>
        <div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            <IntegrationWidget
                v-for="(integration, index) in configuredIntegrations"
                :key="index"
                :isActive="integration.isActive"
                :logoPath="integrationInfo[integration.type].logoPath"
                :contentText="integrationInfo[integration.type].description"
                @configClick="handleConfigClick(integration)"
                @viewLogsClick="handleViewLogsClick(integration)"
                @activateClick="handleActivateClick(integration)"
            />
        </div>
    </div>
</template>
