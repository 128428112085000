import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BarChart = _resolveComponent("BarChart")!

  return (_openBlock(), _createBlock(_component_BarChart, {
    title: $setup.props.title,
    categoryData: $setup.chartData.category,
    seriesData: $setup.chartData.series
  }, null, 8 /* PROPS */, ["title", "categoryData", "seriesData"]))
}