import { defineComponent as _defineComponent } from 'vue'
import { PropType } from 'vue';
import { ConnectFlowModelingActions, VueFlowNodeModel } from '@/types';
import store from '@/store';
import { PhoneMissedCallIcon } from '@heroicons/vue/solid';


export default /*@__PURE__*/_defineComponent({
  __name: 'HangUp',
  props: {
    node: {
        type: Object as PropType<VueFlowNodeModel>,
        required: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

function removeNode() {
    store.dispatch('flow/' + ConnectFlowModelingActions.DELETE_NODE, {
        nodeId: props.node.id,
        parentId: props.node.parentId,
    });
}

const __returned__ = { props, removeNode, get PhoneMissedCallIcon() { return PhoneMissedCallIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})