<script lang="ts" setup>
import { computed, reactive, ref, Ref } from 'vue';
import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { email, maxLength, required, requiredIf } from '@/utils/validators';
import { AxiosResponse } from 'axios';

import router from '@/router';
import store from '@/store';
import { useUiStore } from '@/stores';
import AuthService from '@/services/api/AuthService';
import { UserService } from '@/services';
import { toastServiceError } from '@/services/core/notification';

import {
    AuthActions,
    AuthGetters,
    AuthServiceLoginResult,
    LoginFormState,
    UserModel,
} from '@/types';

enum FormType {
    LOGIN = 'LOGIN',
    RECOVER = 'RECOVER',
    SENT_RECOVER = 'SENT_RECOVER',
}

const uiStore = useUiStore();

const formState = reactive<LoginFormState>({
    email: '',
    password: '',
    rememberMe: true,
});

const showFormType = ref<FormType>(FormType.LOGIN);

const user = computed<UserModel>(() => store.getters[AuthGetters.USER]);

const rules = computed<ValidationArgs<LoginFormState>>(() => ({
    email: {
        email,
        required,
        maxLength: maxLength(250),
    },
    password: {
        required: requiredIf(() => showFormType.value === FormType.LOGIN),
    },
    rememberMe: {},
}));

const v$: Ref<Validation<ValidationArgs<LoginFormState>>> = useVuelidate(rules, formState);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const handleFormValidation = () => v$.value.$validate();

const handleLoginSuccess = async (response: AxiosResponse<AuthServiceLoginResult>) => {
    await store.dispatch(AuthActions.LOGIN, response.data);

    if (!user.value.lastLogin) {
        await router.push({ name: 'UpdatePassword' });
        return;
    }

    await router.push('/');
};

const onSubmitLogin = async () => {
    if (!(await handleFormValidation())) return;

    try {
        await setLoading(true);
        const response = await AuthService.login(formState);
        await handleLoginSuccess(response);
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const onSubmitResetPassword = async () => {
    if (!(await handleFormValidation())) return;

    try {
        await setLoading(true);
        await UserService.recoveryPassword(formState);
        showFormType.value = FormType.SENT_RECOVER;
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};
</script>

<template>
    <div class="grid w-full grid-cols-1 sm:grid-cols-2">
        <div class="bg-primary relative hidden w-full items-center justify-center sm:flex">
            <span class="login-bg h-[150px] xl:h-[250px]" />
        </div>
        <div
            class="flex w-full flex-col items-center bg-gray-50 px-8 pt-20 sm:items-start sm:justify-center sm:pt-0 lg:px-28 xl:px-40"
        >
            <span class="login-logo mb-8 w-[225px]" />

            <!-- Login Form Header -->
            <template v-if="showFormType === FormType.LOGIN">
                <h3 class="mb-8 text-2xl font-semibold text-gray-700">
                    {{ $t('welcome') }}
                </h3>
            </template>

            <!-- Recover Password Header -->
            <template v-if="showFormType === FormType.RECOVER">
                <h3 class="mb-3 text-2xl font-semibold text-gray-700">
                    {{ $t('forgotPassword.title') }}
                </h3>
                <p class="mb-8 text-sm">
                    {{ $t('forgotPassword.description') }}
                </p>
            </template>

            <!-- Password Recovery Sent Header -->
            <template v-if="showFormType === FormType.SENT_RECOVER">
                <h3 class="mb-3 text-2xl font-semibold text-gray-700">
                    {{ $t('forgotPassword.requested.title') }}
                </h3>
                <p class="mb-8 text-sm">
                    {{ $t('forgotPassword.requested.description') }}
                </p>
            </template>

            <!-- Login Form -->
            <form
                v-if="showFormType === FormType.LOGIN"
                class="w-full space-y-4"
                @submit.prevent="onSubmitLogin"
            >
                <UiTextInput
                    v-model="formState.email"
                    name="email"
                    type="text"
                    :label="$t('login.form.emailAddress')"
                    inputClass="text-base px-4 py-3"
                    autocomplete="email"
                    :errors="v$.email.$errors"
                    @blur="v$.email.$touch"
                />
                <UiTextInput
                    v-model="formState.password"
                    name="password"
                    type="password"
                    :label="$t('login.form.password')"
                    inputClass="text-base px-4 py-3"
                    autocomplete="password"
                    :errors="v$.password?.$errors"
                    @blur="v$.password?.$touch"
                />
                <div class="flex items-center justify-between">
                    <UiCheckboxInput
                        v-model="formState.rememberMe"
                        name="rememberMe"
                        :label="$t('login.form.rememberMe')"
                        class="h-6"
                    />
                    <div class="text-sm">
                        <span
                            class="text-primary hover:text-primary-darker cursor-pointer text-sm font-medium"
                            @click="showFormType = FormType.RECOVER"
                        >
                            {{ $t('login.form.forgotYourPassword') }}
                        </span>
                    </div>
                </div>
                <div>
                    <UiButton type="submit" variant="primary" class="w-full">
                        <span class="block">{{ $t('login.form.signIn') }}</span>
                    </UiButton>
                </div>
            </form>

            <!-- Recover Password Form -->
            <form
                v-if="showFormType === FormType.RECOVER"
                class="w-full space-y-4"
                @submit.prevent="onSubmitResetPassword"
            >
                <UiTextInput
                    v-model="formState.email"
                    name="email"
                    type="text"
                    :label="$t('forgotPassword.form.emailAddress')"
                    inputClass="text-base px-4 py-3"
                    autocomplete="email"
                    :errors="v$.email.$errors"
                    @blur="v$.email.$touch"
                />
                <div>
                    <UiButton type="submit" variant="primary" class="w-full">
                        <span class="block">{{ $t('forgotPassword.form.resetPass') }}</span>
                    </UiButton>
                </div>
            </form>

            <!-- Recovery Sent Confirmation -->
            <div v-if="showFormType === FormType.SENT_RECOVER">
                <UiButton variant="primary" class="w-full" @click="showFormType = FormType.LOGIN">
                    <span class="block">{{ $t('forgotPassword.requested.action') }}</span>
                </UiButton>
            </div>
        </div>
    </div>
</template>
