<script lang="ts" setup>
import { PropType } from 'vue';
import { ConnectFlowModel, Permission } from '@/types';
import { applyPhoneMask } from '@/utils/number';
import { PhoneIcon } from '@heroicons/vue/solid';
import ConnectFlowIcon from '@/components/svg/ConnectFlowIcon';
import { ArchiveIcon, CheckCircleIcon, PencilAltIcon, XCircleIcon } from '@heroicons/vue/outline';

const props = defineProps({
    modelValue: {
        type: Object as PropType<ConnectFlowModel>,
        required: true,
    },
});

const emit = defineEmits(['activate', 'inactivate']);

const handleActivateClick = () => emit('activate', props.modelValue);
const handleInactivateClick = () => emit('inactivate', props.modelValue);
</script>

<template>
    <UiPanel class="space-y-2 hover:bg-white">
        <div class="flex items-center justify-between">
            <div class="flex-1">
                <component
                    :is="$can(Permission.VIEW_CONNECT_FLOW) ? 'RouterLink' : 'span'"
                    :to="{
                        name: 'ViewConnectFlowById',
                        params: {
                            id: modelValue.id,
                        },
                    }"
                    class="text-md text-primary truncate font-medium"
                >
                    {{ modelValue.name }}
                </component>
                <UiIsActiveBadge :model-value="modelValue" class="ml-2 text-xs" />
            </div>
            <div v-if="$can(Permission.EDIT_CONNECT_FLOW)" class="flex space-x-1">
                <UiActionButton
                    v-if="modelValue.isActive"
                    v-tippy="$t('core.actions.Inactivate')"
                    variant="danger"
                    @click="handleInactivateClick"
                >
                    <span class="sr-only"> {{ $t('core.actions.Inactivate') }} </span>
                    <XCircleIcon class="h-6 w-6" aria-hidden="true" />
                </UiActionButton>
                <UiActionButton
                    v-else
                    v-tippy="$t('core.actions.Activate')"
                    variant="success"
                    @click="handleActivateClick"
                >
                    <span class="sr-only"> {{ $t('core.actions.Activate') }} </span>
                    <CheckCircleIcon class="h-6 w-6" aria-hidden="true" />
                </UiActionButton>
                <UiActionButton
                    v-tippy="$t('core.actions.Edit')"
                    variant="primary"
                    :to="{
                        name: 'EditConnectFlowById',
                        params: {
                            id: modelValue.id,
                        },
                    }"
                >
                    <span class="sr-only"> {{ $t('core.actions.Edit') }} </span>
                    <PencilAltIcon class="h-6 w-6" aria-hidden="true" />
                </UiActionButton>
                <UiActionButton
                    v-tippy="$t('connectFlow.actions.editFlow')"
                    variant="primary"
                    :to="{
                        name: 'EditFlowById',
                        params: {
                            id: modelValue.id,
                        },
                    }"
                >
                    <span class="sr-only">
                        {{ $t('connectFlow.actions.editFlow') }}
                    </span>
                    <ConnectFlowIcon class="h-6 w-6" aria-hidden="true" />
                </UiActionButton>
                <UiActionButton
                    v-tippy="$t('connectFlow.actions.history')"
                    variant="primary"
                    :to="{
                        name: 'ViewConnectFlowHistoryById',
                        params: {
                            id: modelValue.id,
                        },
                    }"
                >
                    <span class="sr-only">
                        {{ $t('connectFlow.actions.history') }}
                    </span>
                    <ArchiveIcon class="h-6 w-6" aria-hidden="true" />
                </UiActionButton>
            </div>
        </div>
        <div class="sm:flex sm:justify-between">
            <div class="sm:flex">
                <div class="flex flex-1 items-center text-sm text-gray-500">
                    <PhoneIcon
                        class="mr-1 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                    />
                    <span v-if="!modelValue.numbers?.length">
                        {{ $t('connectFlow.list.noNumbers') }}
                    </span>
                    <div v-else class="flex flex-wrap">
                        <template v-for="(number, index) in modelValue.numbers" :key="number.id">
                            <component
                                :is="$can(Permission.VIEW_NUMBER) ? 'RouterLink' : 'span'"
                                :to="{
                                    name: 'ViewNumberById',
                                    params: {
                                        id: number.id,
                                    },
                                }"
                                class="flex items-center text-sm text-gray-500"
                                :class="!$can(Permission.VIEW_LEVEL) || 'text-primary font-medium'"
                            >
                                {{ applyPhoneMask(number.number) }}
                            </component>
                            {{ index != modelValue.numbers.length - 1 ? ', ' : '' }}
                        </template>
                    </div>
                </div>
            </div>
            <UiAuditInfo :model-value="modelValue" />
        </div>
    </UiPanel>
</template>
