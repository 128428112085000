import { RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { AuthActions } from '@/types';
import LoginPage from '@/views/pages/authentication/LoginPage.vue';
import AppLoginPage from '@/views/pages/authentication/AppLoginPage.vue';
import UpdatePasswordPage from '@/views/pages/authentication/UpdatePasswordPage.vue';

const routes: RouteRecordRaw[] = [
    {
        name: 'Login',
        path: '/login',
        component: LoginPage,
    },
    {
        name: 'Logout',
        path: '/logout',
        component: LoginPage,
        beforeEnter: async () => {
            await store.dispatch(AuthActions.LOGOUT);
            return true;
        },
    },
    {
        name: 'UpdatePassword',
        path: '/update-password',
        component: UpdatePasswordPage,
    },
    {
        name: 'LoginInsideApp',
        path: '/app/login',
        component: AppLoginPage,
    },
];
export default routes;
