<script lang="ts" setup>
import { ErrorObject } from '@vuelidate/core';
import { computed } from 'vue';
import { applyPhoneMask } from '@/utils/number';

const props = defineProps({
    type: {
        type: String,
        default: 'text',
    },
    modelValue: {
        type: [String, Number],
        default: '',
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: [String, Boolean],
        required: true,
    },
    placeholder: {
        type: String,
        default: '',
    },
    autocomplete: {
        type: String,
        default: 'off',
    },
    required: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    isPhoneNumber: {
        type: Boolean,
        default: false,
    },
    errors: {
        type: Array<ErrorObject>,
        default: [],
    },
    silentErrors: {
        type: Array<ErrorObject>,
        default: [],
    },
    inputClass: {
        type: String,
        default: 'px-4 py-3',
    },
});
const disabledClass = computed(() => (props.disabled ? 'bg-gray-100 text-gray-500' : ''));

const hasErros = computed(() => props.errors.length || props.silentErrors.length);
const emit = defineEmits(['update:modelValue', 'blur']);
function handleInput(event: Event) {
    let value = (event.target as HTMLInputElement).value;
    if (props.isPhoneNumber) {
        value = applyPhoneMask(value);
    }
    emit('update:modelValue', value);
}
</script>

<template>
    <div class="relative">
        <label
            v-if="label !== false"
            :for="name"
            class="mb-2 block text-sm font-medium text-gray-800"
        >
            {{ label }} <span class="text-xs" v-if="required">*</span>
        </label>
        <input
            :name="name"
            :id="name"
            :type="type"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            class="block w-full rounded border-0 text-gray-800 shadow ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset"
            :class="[
                disabledClass,
                inputClass,
                !hasErros ? 'focus:ring-primary ring-gray-200' : 'ring-danger focus:ring-danger',
            ]"
            :value="props.modelValue"
            @input="handleInput"
            :disabled="disabled"
            @blur="emit('blur', $event)"
        />
        <div
            class="text-danger bg-danger-100 absolute z-10 w-full px-2 py-2 text-sm shadow"
            v-if="errors.length"
        >
            <p v-for="error of errors" :key="error.$uid">
                {{ error.$response ? error.$response : error.$message }}
            </p>
        </div>
        <div
            class="text-danger bg-danger-100 absolute z-10 w-full px-2 py-2 text-sm shadow"
            v-if="silentErrors.length"
        >
            <p v-for="error of silentErrors" :key="error.$uid">
                {{ error.$response ? error.$response : error.$message }}
            </p>
        </div>
    </div>
</template>
