import { defineComponent as _defineComponent } from 'vue'
import { computed, onMounted, ref, WritableComputedRef } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

import store from '@/store';
import { useUiStore } from '@/stores';
import { ConnectFlowService } from '@/services';
import { toast, toastServiceError } from '@/services/core/notification';
import { formatDateAsString } from '@/utils/datetime';

import {
    ConnectFlowModelingGetters,
    ConnectFlowModelingActions,
    ConnectFlowServiceSaveFlowResult,
    ConnectFlowModel,
    EdgeType,
    Permission,
} from '@/types';

import {
    ComponentSelector,
    CustomNode,
    FormClienteInfoEdge,
    FormAwaitableEdge,
} from './components/flow';

import { AwaitableEdge, ClientInfoEdge } from '@/views/pages/connect-flow/components/flow/edges';
import { StartNode } from './components/flow/nodes';

import { VueFlow, useVueFlow } from '@vue-flow/core';
import { Background } from '@vue-flow/background';
import { Controls, ControlButton } from '@vue-flow/controls';
import { MiniMap } from '@vue-flow/minimap';

import { ArrowsExpandIcon } from '@heroicons/vue/outline';


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowModelingPage',
  props: {
    action: {
        type: String,
        default: 'view',
    },
},
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const uiStore = useUiStore();
const { t } = useI18n();

const props = __props;

const fullScreen = ref(false);
const showFormClienteInfoEdge = ref(false);
const showFormAwaitableEdge = ref(false);
const triggeredEdge = ref();

const connectFlow: WritableComputedRef<ConnectFlowModel> = computed({
    get: () => store.getters['flow/' + ConnectFlowModelingGetters.CONNECT_FLOW],
    set: (value) => store.dispatch('flow/' + ConnectFlowModelingActions.SET_CONNECT_FLOW, value),
});

const elements = computed({
    get: () => store.getters['flow/' + ConnectFlowModelingGetters.ELEMENTS],
    set: (value) => store.dispatch('flow/' + ConnectFlowModelingActions.SET_ELEMENTS, value),
});

const isAllElementsValid = computed(
    () => store.getters['flow/' + ConnectFlowModelingGetters.IS_ALL_ELEMENTS_VALID],
);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const save = async () => {
    try {
        await setLoading(true);
        const response = await ConnectFlowService.saveFlow<ConnectFlowServiceSaveFlowResult>(
            route.params.id.toString(),
            elements.value,
        );
        connectFlow.value = response.data.connectFlow;
        toast.success(response.data.message);
    } finally {
        await setLoading(false);
    }
};

const publish = async () => {
    try {
        await setLoading(true);
        await ConnectFlowService.saveFlow<ConnectFlowServiceSaveFlowResult>(
            route.params.id.toString(),
            elements.value,
        );

        const response = await ConnectFlowService.publishFlow<ConnectFlowServiceSaveFlowResult>(
            route.params.id.toString(),
            elements.value,
        );

        connectFlow.value = response.data.connectFlow;
        toast.success(response.data.message);
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const { onPaneReady, onEdgeClick } = useVueFlow();

onPaneReady(({ fitView }) => {
    fitView();
});

onEdgeClick((event) => {
    if (event.edge.data.component === EdgeType.AWAITABLE) {
        showFormAwaitableEdge.value = true;
        triggeredEdge.value = event.edge.id;
    }
    if (event.edge.data.component === EdgeType.CLIENT_INFO_EDGE) {
        showFormClienteInfoEdge.value = true;
        triggeredEdge.value = event.edge.id;
    }
});

onMounted(async () => {
    try {
        await setLoading(true);
        await store.dispatch('flow/' + ConnectFlowModelingActions.LOAD_FLOW_FLOW, {
            id: route.params.id,
            historyId: route.params.historyId,
        });
    } finally {
        await setLoading(false);
    }
});

const __returned__ = { route, uiStore, t, props, fullScreen, showFormClienteInfoEdge, showFormAwaitableEdge, triggeredEdge, connectFlow, elements, isAllElementsValid, setLoading, save, publish, onPaneReady, onEdgeClick, get formatDateAsString() { return formatDateAsString }, get EdgeType() { return EdgeType }, get Permission() { return Permission }, get ComponentSelector() { return ComponentSelector }, get CustomNode() { return CustomNode }, get FormClienteInfoEdge() { return FormClienteInfoEdge }, get FormAwaitableEdge() { return FormAwaitableEdge }, get AwaitableEdge() { return AwaitableEdge }, get ClientInfoEdge() { return ClientInfoEdge }, get StartNode() { return StartNode }, get VueFlow() { return VueFlow }, get Background() { return Background }, get Controls() { return Controls }, get ControlButton() { return ControlButton }, get MiniMap() { return MiniMap }, get ArrowsExpandIcon() { return ArrowsExpandIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})