/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import {
    ConnectFlowModelingActions,
    ConnectFlowModelingGetters,
    ConnectFlowModelingMutations,
    ConnectFlowFlowModel,
    ConnectFlowModelingMenu,
    ConnectFlowModelingState,
    VueFlowConnectionModel,
    FlowNodeModel,
    VueFlowNodeModel,
    NodeType,
    EdgeType,
    ScheduleModel,
    QueueModel,
    ConnectFlowModel,
    UserExtensionModel,
    ConnectFlowAudioModel,
    ConnectFlowSurveyModel,
} from '@/types';
import { flowNodeRules } from '@/configs';
import {
    ConnectFlowAudioService,
    ConnectFlowService,
    QueueService,
    ScheduleService,
    ConnectFlowSurveyService,
} from '@/services';
import { toastServiceError } from '@/services/core/notification';
import { MarkerType } from '@vue-flow/core';
import store from '@/store';
import { i18n } from '@/plugins/i18n';
import UserExtensionsService from '@/services/api/UserExtensionsService';

const getDefaultState = () => {
    return {
        elements: [
            {
                id: '1',
                type: 'input',
                parentId: '0',
                data: { component: NodeType.START },
                draggable: false,
                selectable: false,
                connectable: true,
                position: { x: 0, y: 300 },
            },
        ],
        menu: {
            show: false,
            action: 'add',
            idTriggerBy: null,
        },
        scheduleOptions: [] as ScheduleModel[],
        queueOptions: [] as QueueModel[],
        extensionsOptions: [] as UserExtensionModel[],
        audioOptions: [] as ConnectFlowAudioModel[],
        surveyOptions: [] as ConnectFlowSurveyModel[],
    } as ConnectFlowModelingState;
};

const state: ConnectFlowModelingState = getDefaultState();

const mutations: MutationTree<ConnectFlowModelingState> = {
    [ConnectFlowModelingMutations.SET_MENU]: (state, value: { show: boolean; action: string }) => {
        state.menu.show = value.show;
        state.menu.action = value.action ?? 'add';
    },
    [ConnectFlowModelingMutations.SET_MENU_TRIGGER_BY]: (state, value: string) => {
        state.menu.idTriggerBy = value;
    },
    [ConnectFlowModelingMutations.PUSH_NEW_ELEMENT]: (
        state,
        value: VueFlowNodeModel | VueFlowConnectionModel,
    ) => state.elements.push(value),
    [ConnectFlowModelingMutations.SET_ELEMENTS]: (
        state,
        value: (VueFlowNodeModel | VueFlowConnectionModel)[],
    ) => (state.elements = value),
    [ConnectFlowModelingMutations.SET_CONNECT_FLOW]: (state, value: ConnectFlowModel) =>
        (state.connectFlow = value),
    [ConnectFlowModelingMutations.SET_SCHEDULE]: (state, value: ScheduleModel[]) =>
        (state.scheduleOptions = value),
    [ConnectFlowModelingMutations.SET_QUEUE]: (state, value: QueueModel[]) =>
        (state.queueOptions = value),
    [ConnectFlowModelingMutations.SET_SURVEY]: (state, value: ConnectFlowSurveyModel[]) =>
        (state.surveyOptions = value),
    [ConnectFlowModelingMutations.SET_AUDIO]: (state, value: ConnectFlowAudioModel[]) =>
        (state.audioOptions = value),
    [ConnectFlowModelingMutations.SET_EXTENSIONS]: (state, value: UserExtensionModel[]) =>
        (state.extensionsOptions = value),
    [ConnectFlowModelingMutations.UPDATE_ELEMENT]: (
        state,
        value: { idx: number | null; value: VueFlowNodeModel | VueFlowConnectionModel },
    ) => {
        let idx = value.idx;
        if (idx === null) {
            idx = state.elements.findIndex((e) => e.id === value.value.id);
        }
        state.elements[idx] = value.value;
    },
};

const actions: ActionTree<ConnectFlowModelingState, any> = {
    [ConnectFlowModelingActions.SET_MENU]: (
        { commit },
        value: {
            show: boolean;
            action: string;
            idTriggerBy: string;
        },
    ) => {
        commit(ConnectFlowModelingMutations.SET_MENU, {
            show: value.show,
            action: value.action,
        });
        if (value.idTriggerBy !== undefined) {
            commit(ConnectFlowModelingMutations.SET_MENU_TRIGGER_BY, value.idTriggerBy);
        }
    },
    [ConnectFlowModelingActions.SET_ELEMENTS]: (
        { commit },
        value: (VueFlowNodeModel | VueFlowConnectionModel)[],
    ) => commit(ConnectFlowModelingMutations.SET_ELEMENTS, value),
    [ConnectFlowModelingActions.SET_CONNECT_FLOW]: ({ commit }, value: ConnectFlowFlowModel) =>
        commit(ConnectFlowModelingMutations.SET_CONNECT_FLOW, value),
    [ConnectFlowModelingActions.EDIT_NODE]: async (
        { getters, state },
        newNode: VueFlowNodeModel,
    ) => {
        const parentNode: VueFlowNodeModel = getters[ConnectFlowModelingGetters.ELEMENTS].find(
            (n: VueFlowNodeModel) => n.id === state.menu.idTriggerBy,
        );

        if (parentNode === undefined) {
            return;
        }

        const newNodeRules = flowNodeRules[newNode.data.component];
        parentNode.connectable = newNodeRules.maxChildren > 0;
        parentNode.data.component = newNode.data.component;
        parentNode.data.hasErrors = false;
        await insertAutoChildren(newNodeRules, parentNode);
    },
    [ConnectFlowModelingActions.PUSH_NEW_NODE]: async (
        { commit, getters, state },
        newNode: VueFlowNodeModel,
    ) => {
        const parentNode: VueFlowNodeModel = getters[ConnectFlowModelingGetters.ELEMENTS].find(
            (n: VueFlowNodeModel) => n.id === state.menu.idTriggerBy,
        );

        if (parentNode === undefined) {
            console.error("OH NO! Daddy wasn't found");
            return;
        }
        const newNodeRules = flowNodeRules[newNode.data.component];
        const parentNodeRules = flowNodeRules[parentNode.data.component];

        const siblings = getters[ConnectFlowModelingGetters.ELEMENTS_NODES].filter(
            (n: VueFlowNodeModel) => n.parentId === parentNode.id,
        );
        parentNode.connectable = parentNodeRules.maxChildren > siblings.length + 1;
        newNode.connectable = newNodeRules.maxChildren > 0;

        let xDeviation = 0;
        let yDeviation = 200;
        let x = parentNode.position.x;
        let y = parentNode.position.y;
        const lastItem = siblings.length - 1;

        if (newNode?.position?.x !== undefined) {
            xDeviation = newNode.position.x;
        } else if (newNodeRules?.position?.x !== undefined) {
            xDeviation = newNodeRules.position.x;
        }

        if (newNode?.position?.y !== undefined) {
            yDeviation = newNode.position.y;
        } else if (newNodeRules?.position?.y !== undefined) {
            yDeviation = newNodeRules.position.y;
        }

        if (newNode?.position?.y !== undefined) {
            yDeviation = newNode.position.y;
        }

        if (lastItem >= 2) {
            x = siblings[lastItem].position.x;
            y = siblings[lastItem].position.y;
            xDeviation = 270;
            yDeviation = 0;
        }

        newNode.position = {
            x: x + xDeviation,
            y: y + yDeviation,
        };

        const newNodeId = generateRandomId();
        newNode.id = newNodeId;
        newNode.parentId = parentNode.id;
        const connection: VueFlowConnectionModel = {
            id: `e${generateRandomId()}`,
            source: parentNode.id,
            target: newNodeId,
            type: 'custom',
            markerEnd: MarkerType.ArrowClosed,
        };

        if (newNode.sourceHandle !== undefined) {
            connection.sourceHandle = newNode.sourceHandle;
        }

        if (
            parentNode.data.component === NodeType.CLIENT_INFO &&
            newNode.data.component !== NodeType.OPEN_EDGE
        ) {
            connection.data = {
                component: EdgeType.CLIENT_INFO_EDGE,
                waitUser: false,
                uraOption: null,
                hasErrors: true,
            };
        }

        if (parentNode.data.component === NodeType.START) {
            connection.data = {
                component: EdgeType.AWAITABLE,
                waitUser: false,
                uraOption: null,
                hasErrors: false,
            };
        }

        if (connection.data === undefined) {
            connection.data = {
                component: EdgeType.NORMAL,
                waitUser: false,
                uraOption: null,
                hasErrors: false,
            };
        }

        if (newNode.data?.flowType) {
            connection.data.flowType = newNode.data?.flowType;
        }

        if (newNode.data.component === NodeType.OPEN_EDGE && newNode.data?.title) {
            connection.label = i18n.global.t(newNode.data?.title);
            newNode.data.hasErrors = true;
        } else {
            newNode.data.hasErrors = false;
        }

        commit(ConnectFlowModelingMutations.PUSH_NEW_ELEMENT, newNode);
        commit(ConnectFlowModelingMutations.PUSH_NEW_ELEMENT, connection);

        await insertAutoChildren(newNodeRules, newNode);
    },
    [ConnectFlowModelingActions.DELETE_NODE]: async (
        { commit, state, getters },
        params: { nodeId: string; parentId: string },
    ) => {
        const elementsToRemove = findFamilyElements(state.elements, params.nodeId);

        const elements = state.elements.filter((n: VueFlowNodeModel | VueFlowConnectionModel) => {
            return !elementsToRemove.includes(n.id);
        });
        commit(ConnectFlowModelingMutations.SET_ELEMENTS, elements);

        const parentNode = getters[ConnectFlowModelingGetters.ELEMENTS_NODES].find(
            (node: VueFlowNodeModel) => {
                return node.id === params.parentId;
            },
        );
        const component = parentNode.data.component as NodeType;
        const nodeRules: FlowNodeModel = flowNodeRules[component];
        const childrenCount = getters[ConnectFlowModelingGetters.ELEMENTS_NODES].filter(
            (n: VueFlowNodeModel) => n.parentId === parentNode.id,
        ).length;
        parentNode.connectable = nodeRules.maxChildren > childrenCount;

        if (parentNode.connectable && nodeRules.autoChildren.length) {
            const nodeConnections: VueFlowConnectionModel[] = getters[
                ConnectFlowModelingGetters.ELEMENTS_CONNECTIONS
            ].filter((c: VueFlowConnectionModel) => c.source === parentNode.id);

            insertAutoChildren(nodeRules, parentNode, nodeConnections);
        }
    },
    [ConnectFlowModelingActions.GO_TO_CONNECTION]: async (
        { getters, commit, dispatch },
        goTo: { source: string; target: string; handle: string },
    ) => {
        const oldConnection = getters[ConnectFlowModelingGetters.ELEMENTS].find(
            (conn: VueFlowConnectionModel) => conn.source === goTo.source,
        );

        if (oldConnection) {
            await dispatch(ConnectFlowModelingActions.DELETE_NODE, oldConnection.id);
        }

        commit(ConnectFlowModelingMutations.PUSH_NEW_ELEMENT, {
            id: `e${generateRandomId()}`,
            source: goTo.source,
            target: goTo.target,
            type: 'smoothstep',
            targetHandle: goTo.handle,
            markerEnd: MarkerType.ArrowClosed,
        });
    },
    [ConnectFlowModelingActions.LOAD_FLOW_FLOW]: async (
        { commit },
        params: { id: number; historyId?: number },
    ) => {
        try {
            // Execute all parallel requests
            const [
                scheduleResponse,
                queueResponse,
                surveyResponse,
                extensionsResponse,
                audioResponse,
            ] = await Promise.all([
                ScheduleService.getAll<Array<ScheduleModel>>({
                    params: { is_active: true },
                }),
                QueueService.getAll<Array<QueueModel>>({
                    params: { is_active: true },
                }),
                ConnectFlowSurveyService.getAll<Array<ConnectFlowSurveyModel>>({
                    params: { is_active: true },
                }),
                UserExtensionsService.getAll<UserExtensionModel[]>({
                    params: { is_active: true },
                }),
                ConnectFlowAudioService.getAll<ConnectFlowAudioModel[]>({
                    params: { is_active: true },
                }),
            ]);

            // Commit all the parallel responses
            commit(ConnectFlowModelingMutations.SET_SCHEDULE, scheduleResponse.data);
            commit(ConnectFlowModelingMutations.SET_QUEUE, queueResponse.data);
            commit(ConnectFlowModelingMutations.SET_SURVEY, surveyResponse.data);
            commit(ConnectFlowModelingMutations.SET_EXTENSIONS, extensionsResponse.data);
            commit(ConnectFlowModelingMutations.SET_AUDIO, audioResponse.data);

            // Handle the flow request
            const flowResponse = await ConnectFlowService.getFlow(params.id, params.historyId);

            if (flowResponse.data.flow !== undefined) {
                commit(
                    ConnectFlowModelingMutations.SET_CONNECT_FLOW,
                    flowResponse.data.connectFlow,
                );
                commit(ConnectFlowModelingMutations.SET_ELEMENTS, flowResponse.data.flow);
            } else {
                commit(ConnectFlowModelingMutations.SET_ELEMENTS, getDefaultState().elements);
            }
        } catch (err) {
            toastServiceError(err);
        }
    },
};

const getters: GetterTree<ConnectFlowModelingState, any> = {
    [ConnectFlowModelingGetters.MENU]: (state): ConnectFlowModelingMenu => state.menu,
    [ConnectFlowModelingGetters.CONNECT_FLOW]: (state) => state.connectFlow,
    [ConnectFlowModelingGetters.ELEMENTS]: (state) => state.elements,
    [ConnectFlowModelingGetters.ELEMENT_BY_ID]: (state) => (nodeID: string) =>
        state.elements.find((n) => n.id === nodeID),
    [ConnectFlowModelingGetters.ELEMENTS_NODES]: (
        state: ConnectFlowModelingState,
    ): VueFlowNodeModel[] => state.elements.filter(isFlowNode),
    [ConnectFlowModelingGetters.ELEMENTS_CONNECTIONS]: (
        state: ConnectFlowModelingState,
    ): VueFlowConnectionModel[] => state.elements.filter(isFlowConnection),
    [ConnectFlowModelingGetters.IS_ALL_ELEMENTS_VALID]: (
        state: ConnectFlowModelingState,
    ): boolean =>
        state.elements.filter((e) => e.data !== undefined && e.data?.hasErrors).length === 0,
    [ConnectFlowModelingGetters.SCHEDULE_OPTIONS]: (
        state: ConnectFlowModelingState,
    ): ScheduleModel[] => state.scheduleOptions,
    [ConnectFlowModelingGetters.AUDIO_OPTIONS]: (
        state: ConnectFlowModelingState,
    ): ConnectFlowAudioModel[] => state.audioOptions,
    [ConnectFlowModelingGetters.QUEUE_OPTIONS]: (state: ConnectFlowModelingState): QueueModel[] =>
        state.queueOptions,
    [ConnectFlowModelingGetters.SURVEY_OPTIONS]: (
        state: ConnectFlowModelingState,
    ): ConnectFlowSurveyModel[] => state.surveyOptions,
    [ConnectFlowModelingGetters.EXTENSION_OPTIONS]: (
        state: ConnectFlowModelingState,
    ): UserExtensionModel[] => state.extensionsOptions,
};

function generateRandomId() {
    return Math.random().toString(36).substring(2, 6);
}

async function insertAutoChildren(
    nodeRules: FlowNodeModel,
    node: VueFlowNodeModel,
    connections: VueFlowConnectionModel[] = [],
) {
    if (nodeRules.autoChildren.length) {
        store.commit('flow/' + ConnectFlowModelingMutations.SET_MENU_TRIGGER_BY, node.id);
        for (const child of nodeRules.autoChildren) {
            const existingConnection = connections.filter((c) => {
                return c.data !== undefined && c.data.flowType === child.data.flowType;
            });
            if (existingConnection.length === 0) {
                await store.dispatch(
                    'flow/' + ConnectFlowModelingActions.PUSH_NEW_NODE,
                    cloneDeep(child),
                );
            }
        }
    }
}

function findFamilyElements(
    elements: (VueFlowNodeModel | VueFlowConnectionModel)[],
    nodeId: string,
    result: string[] = [],
): string[] {
    result.push(nodeId);
    for (const e of elements) {
        if (
            isFlowConnection(e) &&
            e.id !== undefined &&
            (e.source === nodeId || e.target === nodeId)
        ) {
            result.push(e.id);
        }

        if (isFlowNode(e) && e.parentId === nodeId) {
            result.push(e.id);
            findFamilyElements(elements, e.id, result);
        }
    }
    return result;
}

function isFlowNode(
    element: VueFlowNodeModel | VueFlowConnectionModel,
): element is VueFlowNodeModel {
    return (
        (element as VueFlowNodeModel).parentId !== undefined &&
        (element as VueFlowNodeModel).position !== undefined
    );
}

function isFlowConnection(
    element: VueFlowNodeModel | VueFlowConnectionModel,
): element is VueFlowConnectionModel {
    return (
        (element as VueFlowConnectionModel).source !== undefined &&
        (element as VueFlowConnectionModel).target !== undefined
    );
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
