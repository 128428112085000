import { LevelModel } from '@/types/models/LevelModel';
import { NumberModel } from '@/types/models/NumberModel';
import { CreatedAudit } from '@/types/models/shared/AuditMetadata';

export interface UserNumberModel extends CreatedAudit {
    id: number;
    levelId: number;
    numberId: number;
    level: LevelModel;
    number: NumberModel;
}
