import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  role: "list",
  class: "flex flex-1 flex-col"
}
const _hoisted_2 = {
  key: 0,
  class: "last:mt-auto"
}
const _hoisted_3 = { class: "ml-2" }
const _hoisted_4 = {
  key: 1,
  class: "last:mt-auto"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["hidden"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredNavigationItems, (item) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: item.name
      }, [
        (!item.children && item.name)
          ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
              _createVNode(_component_router_link, {
                to: {
                        name: item.name,
                        query: item.query,
                    },
                class: _normalizeClass([
                        $setup.isActive(item)
                            ? 'bg-indigo-900/70 text-white'
                            : 'text-gray-400 hover:bg-indigo-900/70 hover:text-white',
                        'group flex w-full items-center py-2 font-medium',
                    ])
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                    class: _normalizeClass([
                            $setup.isActive(item)
                                ? 'text-secondary'
                                : 'group-hover:text-secondary text-gray-400',
                            'ml-2 h-6 w-6 flex-shrink-0',
                        ]),
                    "aria-hidden": "true"
                  }, null, 8 /* PROPS */, ["class"])),
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t(item.localeKey)), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "class"])
            ]))
          : (_openBlock(), _createElementBlock("li", _hoisted_4, [
              _createElementVNode("div", null, [
                _createElementVNode("button", {
                  type: "button",
                  class: "group flex w-full items-center rounded py-2 pl-2 pr-1 text-left font-medium text-gray-400 hover:bg-indigo-900/70 hover:text-white",
                  onClick: ($event: any) => ($setup.toggleVisibility(item))
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                    class: _normalizeClass([
                                $setup.hasActiveChildren(item) || item.isOpen ? 'text-secondary' : '',
                                'mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-[#6dbcab]',
                            ]),
                    "aria-hidden": "true"
                  }, null, 8 /* PROPS */, ["class"])),
                  _createElementVNode("span", {
                    class: _normalizeClass([
                                $setup.hasActiveChildren(item) || item.isOpen ? 'text-white' : '',
                                'flex-1',
                            ])
                  }, _toDisplayString(_ctx.$t(item.localeKey)), 3 /* TEXT, CLASS */),
                  (_openBlock(), _createElementBlock("svg", {
                    class: _normalizeClass([
                                $setup.hasActiveChildren(item) || item.isOpen
                                    ? 'rotate-90 text-gray-400'
                                    : 'text-gray-300',
                                'ml-4 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400',
                            ]),
                    viewBox: "0 0 20 20",
                    "aria-hidden": "true"
                  }, [...(_cache[0] || (_cache[0] = [
                    _createElementVNode("path", {
                      d: "M6 6L14 10L6 14V6Z",
                      fill: "currentColor"
                    }, null, -1 /* HOISTED */)
                  ]))], 2 /* CLASS */))
                ], 8 /* PROPS */, _hoisted_5),
                _createCommentVNode(" Expandable link section, show/hide based on state. "),
                _createElementVNode("ul", {
                  class: "mt-1",
                  id: "sub-menu-1",
                  hidden: !($setup.hasActiveChildren(item) || item.isOpen)
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (subItem, index) => {
                    return (_openBlock(), _createElementBlock("li", { key: index }, [
                      _createVNode(_component_router_link, {
                        to: {
                                    name: subItem.name,
                                    query: subItem.query,
                                },
                        href: "#",
                        class: _normalizeClass([
                                    $setup.isActive(subItem)
                                        ? 'bg-indigo-900/70 text-white'
                                        : 'text-gray-400 hover:bg-indigo-900/70 hover:text-white',
                                    'group flex w-full items-center py-2 pl-2 font-medium',
                                ])
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["ChevronRightIcon"], {
                            class: "mr-1 h-4 w-4",
                            "aria-hidden": "true"
                          }),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t(subItem.localeKey)), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "class"])
                    ]))
                  }), 128 /* KEYED_FRAGMENT */))
                ], 8 /* PROPS */, _hoisted_6)
              ])
            ]))
      ], 64 /* STABLE_FRAGMENT */))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}