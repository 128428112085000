import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "flex-1" }
const _hoisted_3 = {
  key: 0,
  class: "flex space-x-1"
}
const _hoisted_4 = { class: "sr-only" }
const _hoisted_5 = { class: "sr-only" }
const _hoisted_6 = { class: "sr-only" }
const _hoisted_7 = { class: "sm:flex sm:justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiIsActiveBadge = _resolveComponent("UiIsActiveBadge")!
  const _component_UiActionButton = _resolveComponent("UiActionButton")!
  const _component_UiAuditInfo = _resolveComponent("UiAuditInfo")!
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock(_component_UiPanel, { class: "space-y-2 hover:bg-white" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can($setup.Permission.VIEW_CONNECT_FLOW_AUDIO) ? 'RouterLink' : 'span'), {
            to: {
                        name: 'ViewConnectFlowAudioById',
                        params: {
                            id: $props.modelValue.id,
                        },
                    },
            class: "text-md text-primary truncate font-medium"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.modelValue.name), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["to"])),
          _createVNode(_component_UiIsActiveBadge, {
            "model-value": $props.modelValue,
            class: "ml-2 text-xs"
          }, null, 8 /* PROPS */, ["model-value"])
        ]),
        (_ctx.$can($setup.Permission.EDIT_CONNECT_FLOW_AUDIO))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              ($props.modelValue.isActive)
                ? _withDirectives((_openBlock(), _createBlock(_component_UiActionButton, {
                    key: 0,
                    variant: "danger",
                    onClick: $setup.handleInactivateClick
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('core.actions.Inactivate')), 1 /* TEXT */),
                      _createVNode($setup["XCircleIcon"], {
                        class: "h-6 w-6",
                        "aria-hidden": "true"
                      })
                    ]),
                    _: 1 /* STABLE */
                  })), [
                    [_directive_tippy, _ctx.$t('core.actions.Inactivate')]
                  ])
                : _withDirectives((_openBlock(), _createBlock(_component_UiActionButton, {
                    key: 1,
                    variant: "success",
                    onClick: $setup.handleActivateClick
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('core.actions.Activate')), 1 /* TEXT */),
                      _createVNode($setup["CheckCircleIcon"], {
                        class: "h-6 w-6",
                        "aria-hidden": "true"
                      })
                    ]),
                    _: 1 /* STABLE */
                  })), [
                    [_directive_tippy, _ctx.$t('core.actions.Activate')]
                  ]),
              _withDirectives((_openBlock(), _createBlock(_component_UiActionButton, {
                variant: "primary",
                to: {
                        name: 'EditConnectFlowAudioById',
                        params: {
                            id: $props.modelValue.id,
                        },
                    }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('core.actions.Edit')), 1 /* TEXT */),
                  _createVNode($setup["PencilAltIcon"], {
                    class: "h-6 w-6",
                    "aria-hidden": "true"
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"])), [
                [_directive_tippy, _ctx.$t('core.actions.Edit')]
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_UiAuditInfo, { "model-value": $props.modelValue }, null, 8 /* PROPS */, ["model-value"])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}