<script lang="ts" setup>
import { ref, computed, onMounted, type Ref } from 'vue';
import { useRoute } from 'vue-router';
import { ChevronRightIcon } from '@heroicons/vue/outline';

import { useFilterStore } from '@/stores';
import { can } from '@/composables/useAuth';

import { type NavigationItem, type LevelModel } from '@/types';

const route = useRoute();
const filterStore = useFilterStore();

const props = defineProps({
    items: {
        type: Array as () => NavigationItem[],
        default: () => [],
    },
});

const filteredNavigationItems: Ref<NavigationItem[]> = ref([]);

const levelFilter = computed<LevelModel | null>(() => filterStore.level);

const isActive = (item: NavigationItem) => {
    if (item.name === 'Home') {
        return route.name === item.name;
    } else {
        return route.matched.some(({ name }) => {
            if (typeof name !== 'string') return false;
            return name === item.name || item.activateBy?.includes(name);
        });
    }
};

const hasActiveChildren = (item: NavigationItem) =>
    (item.children || []).some((i: NavigationItem) => isActive(i));

const toggleVisibility = (item: NavigationItem) => {
    item.isOpen = !item.isOpen;
};

onMounted(() => {
    filteredNavigationItems.value = props.items
        .map((item) => {
            return !item.children?.length
                ? item
                : {
                      ...item,
                      children: item.children.filter(
                          (child) => !child.permission || can(child.permission),
                      ),
                  };
        })
        .filter((item, index) => {
            if (item.localeKey === 'sideNavigation.meet.group' && !levelFilter.value?.meeting) {
                return false;
            }
            return props.items[index].children?.length === undefined
                ? !item.permission || can(item.permission)
                : item.children?.length;
        });
});
</script>

<template>
    <ul role="list" class="flex flex-1 flex-col">
        <template v-for="item in filteredNavigationItems" :key="item.name">
            <li v-if="!item.children && item.name" class="last:mt-auto">
                <router-link
                    :to="{
                        name: item.name,
                        query: item.query,
                    }"
                    :class="[
                        isActive(item)
                            ? 'bg-indigo-900/70 text-white'
                            : 'text-gray-400 hover:bg-indigo-900/70 hover:text-white',
                        'group flex w-full items-center py-2 font-medium',
                    ]"
                >
                    <component
                        :is="item.icon"
                        :class="[
                            isActive(item)
                                ? 'text-secondary'
                                : 'group-hover:text-secondary text-gray-400',
                            'ml-2 h-6 w-6 flex-shrink-0',
                        ]"
                        aria-hidden="true"
                    />
                    <span class="ml-2">{{ $t(item.localeKey) }}</span>
                </router-link>
            </li>
            <li v-else class="last:mt-auto">
                <div>
                    <button
                        type="button"
                        class="group flex w-full items-center rounded py-2 pl-2 pr-1 text-left font-medium text-gray-400 hover:bg-indigo-900/70 hover:text-white"
                        @click="toggleVisibility(item)"
                    >
                        <component
                            :is="item.icon"
                            :class="[
                                hasActiveChildren(item) || item.isOpen ? 'text-secondary' : '',
                                'mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-[#6dbcab]',
                            ]"
                            aria-hidden="true"
                        />
                        <span
                            :class="[
                                hasActiveChildren(item) || item.isOpen ? 'text-white' : '',
                                'flex-1',
                            ]"
                            >{{ $t(item.localeKey) }}</span
                        >
                        <svg
                            :class="[
                                hasActiveChildren(item) || item.isOpen
                                    ? 'rotate-90 text-gray-400'
                                    : 'text-gray-300',
                                'ml-4 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400',
                            ]"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                        >
                            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                        </svg>
                    </button>
                    <!-- Expandable link section, show/hide based on state. -->
                    <ul
                        class="mt-1"
                        id="sub-menu-1"
                        :hidden="!(hasActiveChildren(item) || item.isOpen)"
                    >
                        <li v-for="(subItem, index) in item.children" :key="index">
                            <router-link
                                :to="{
                                    name: subItem.name,
                                    query: subItem.query,
                                }"
                                href="#"
                                :class="[
                                    isActive(subItem)
                                        ? 'bg-indigo-900/70 text-white'
                                        : 'text-gray-400 hover:bg-indigo-900/70 hover:text-white',
                                    'group flex w-full items-center py-2 pl-2 font-medium',
                                ]"
                            >
                                <ChevronRightIcon class="mr-1 h-4 w-4" aria-hidden="true" />
                                {{ $t(subItem.localeKey) }}</router-link
                            >
                        </li>
                    </ul>
                </div>
            </li>
        </template>
    </ul>
</template>
