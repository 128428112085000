import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative flex flex-col" }
const _hoisted_2 = { class: "mb-2 text-lg font-semibold" }
const _hoisted_3 = { class: "min-w-full border-separate border-spacing-0" }
const _hoisted_4 = {
  scope: "col",
  class: "sticky top-0 z-10 whitespace-nowrap border-b border-gray-300 bg-gray-200 py-1.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-800"
}
const _hoisted_5 = {
  scope: "col",
  class: "sticky top-0 z-10 max-h-4 whitespace-nowrap border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-800 backdrop-blur backdrop-filter sm:table-cell"
}
const _hoisted_6 = {
  scope: "col",
  class: "sticky top-0 z-10 max-h-4 whitespace-nowrap border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-800 backdrop-blur backdrop-filter sm:table-cell"
}
const _hoisted_7 = {
  scope: "col",
  class: "sticky top-0 z-10 max-h-4 whitespace-nowrap border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-center text-sm font-semibold text-gray-800 backdrop-blur backdrop-filter lg:table-cell"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium" }
const _hoisted_10 = { class: "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium" }
const _hoisted_11 = { class: "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium" }
const _hoisted_12 = { class: "whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-center text-sm font-medium" }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "text-gray-700" }
const _hoisted_15 = {
  colspan: "4",
  class: "whitespace-nowrap border-b border-gray-200 px-3 py-4 text-center text-sm font-medium"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _component_UiSpinner = _resolveComponent("UiSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('report.realtime.detailsQueue.title')), 1 /* TEXT */),
    _createVNode(_component_UiPanel, {
      class: "flex-1 overflow-y-auto",
      "no-padding": ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("table", _hoisted_3, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.$t('report.realtime.detailsQueue.sourceNumber')), 1 /* TEXT */),
              _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.$t('report.realtime.detailsQueue.destinationNumber')), 1 /* TEXT */),
              _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t('report.realtime.detailsQueue.queueName')), 1 /* TEXT */),
              _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.$t('report.realtime.detailsQueue.duration')), 1 /* TEXT */)
            ])
          ]),
          ($setup.items.length)
            ? (_openBlock(), _createElementBlock("tbody", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, (item, idx) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: `queueDetailItem${idx}`,
                    class: "text-gray-700"
                  }, [
                    _createElementVNode("td", _hoisted_9, _toDisplayString($setup.applyPhoneMask(item.numberFrom)), 1 /* TEXT */),
                    _createElementVNode("td", _hoisted_10, _toDisplayString($setup.applyPhoneMask(item.numberTo)), 1 /* TEXT */),
                    _createElementVNode("td", _hoisted_11, _toDisplayString(item.queueName), 1 /* TEXT */),
                    _createElementVNode("td", _hoisted_12, _toDisplayString(item.duration ? $setup.formatSecToTime(item.duration) : '-'), 1 /* TEXT */)
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ]))
            : (_openBlock(), _createElementBlock("tbody", _hoisted_13, [
                _createElementVNode("tr", _hoisted_14, [
                  _createElementVNode("td", _hoisted_15, _toDisplayString(_ctx.$t('report.realtime.detailsQueue.empty')), 1 /* TEXT */)
                ])
              ]))
        ])
      ]),
      _: 1 /* STABLE */
    }),
    ($setup.loading)
      ? (_openBlock(), _createBlock(_component_UiSpinner, {
          key: 0,
          class: "theme-color absolute right-2 top-2 h-4 w-4"
        }))
      : _createCommentVNode("v-if", true)
  ]))
}