<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
    modelValue: {
        type: Boolean,
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        required: true,
    },
});

const emit = defineEmits(['update:modelValue']);
const model = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    },
});
</script>

<template>
    <div class="flex items-center">
        <input
            :name="name"
            :id="name"
            type="checkbox"
            class="text-primary focus:ring-primary h-4 w-4 rounded border-gray-300"
            v-model="model"
        />
        <label :for="name" class="ml-4 text-sm font-medium text-gray-800">
            {{ label }}
        </label>
    </div>
</template>
