import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "space-y-4 p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-800" }
const _hoisted_3 = { class: "flex flex-col justify-between lg:flex-row" }
const _hoisted_4 = { class: "hidden flex-wrap items-center gap-2 md:flex" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "w-full md:mt-3 lg:mt-0 lg:w-64" }
const _hoisted_8 = { class: "space-y-2" }
const _hoisted_9 = { class: "ml-2 flex items-center space-x-4" }
const _hoisted_10 = {
  key: 0,
  class: "flex gap-3 rounded border-t border-gray-200 bg-gray-50 p-2 ring-1 ring-gray-300"
}
const _hoisted_11 = {
  role: "list",
  class: "my-2 space-y-2"
}
const _hoisted_12 = {
  key: 0,
  class: "mt-0.5 flex h-48 flex-col flex-wrap items-center justify-center rounded-b bg-gray-200 font-semibold text-gray-600"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!
  const _component_UiDatePicker = _resolveComponent("UiDatePicker")!
  const _component_UiListSearch = _resolveComponent("UiListSearch")!
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _component_UiSortMenuBadge = _resolveComponent("UiSortMenuBadge")!
  const _component_UiBadge = _resolveComponent("UiBadge")!
  const _component_UiPagination = _resolveComponent("UiPagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('dashboard.calls')), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_UiButton, {
          variant: "white",
          "text-variant": "primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.getCsv()))
        }, {
          default: _withCtx(() => [
            _createVNode($setup["DocumentDownloadIcon"], {
              class: "-ml-1 mr-2 h-5 w-5",
              "aria-hidden": "true"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('core.actions.export')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.showAllCalls())),
          class: _normalizeClass([$setup.tabFilter === null ? 'bg-gray-50' : 'bg-gray-200', "flex cursor-pointer items-center rounded-xl px-2 py-1 text-sm shadow ring-1 ring-gray-300 hover:bg-white"])
        }, [
          _createVNode($setup["PhoneIcon"], {
            class: "mr-1 h-4 w-4 text-gray-500",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('call.filters.allTypesAndStatus')), 1 /* TEXT */)
        ], 2 /* CLASS */),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.CallType, (type) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `filterBar${type}`,
            onClick: ($event: any) => ($setup.applyTabFilter(type)),
            class: _normalizeClass(["flex cursor-pointer items-center rounded-xl px-2 py-1 text-sm shadow ring-1 ring-gray-300 hover:bg-white", 
                            $setup.tabFilter !== null && $setup.tabFilter?.value === type
                                ? 'bg-gray-50'
                                : 'bg-gray-200'
                        ])
          }, [
            (type === $setup.CallType.INBOUND)
              ? (_openBlock(), _createBlock($setup["PhoneIncomingIcon"], {
                  key: 0,
                  class: "mr-1 h-4 w-4 text-cyan-500",
                  "aria-hidden": "true"
                }))
              : (type === $setup.CallType.OUTBOUND)
                ? (_openBlock(), _createBlock($setup["PhoneOutgoingIcon"], {
                    key: 1,
                    class: "mr-1 h-4 w-4 text-orange-500",
                    "aria-hidden": "true"
                  }))
                : (type === $setup.CallType.INTERNAL)
                  ? (_openBlock(), _createBlock($setup["PhoneIcon"], {
                      key: 2,
                      class: "mr-1 h-4 w-4 text-gray-500",
                      "aria-hidden": "true"
                    }))
                  : _createCommentVNode("v-if", true),
            _createTextVNode(" " + _toDisplayString(_ctx.$t(`call.type.${type}`)), 1 /* TEXT */)
          ], 10 /* CLASS, PROPS */, _hoisted_5))
        }), 128 /* KEYED_FRAGMENT */)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values($setup.CallStatus), (status) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: `filterBar${status}`
          }, [
            (status !== $setup.CallStatus.ON_PROGRESS)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: ($event: any) => ($setup.applyTabFilter(status)),
                  class: _normalizeClass(["flex cursor-pointer items-center rounded-xl px-2 py-1 text-sm shadow ring-opacity-5 hover:bg-white", 
                            $setup.tabFilter !== null && $setup.tabFilter?.value === status
                                ? 'bg-gray-50'
                                : 'bg-gray-200'
                        ])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["mr-1 flex h-2.5 w-2.5 rounded-full", $setup.bulletCallStatusColor(status)])
                  }, null, 2 /* CLASS */),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t(`call.status.${status}`)), 1 /* TEXT */)
                ], 10 /* CLASS, PROPS */, _hoisted_6))
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_UiDatePicker, {
          modelValue: $setup.dateRange,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.dateRange) = $event)),
          range: "",
          multiCalendars: ""
        }, null, 8 /* PROPS */, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_UiPanel, {
        class: "flex px-4 py-2",
        "no-padding": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_UiListSearch, {
            modelValue: $setup.search,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.search) = $event))
          }, null, 8 /* PROPS */, ["modelValue"]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode($setup["CallListSort"], {
              modelValue: $setup.sortMenuSelected,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.sortMenuSelected) = $event))
            }, null, 8 /* PROPS */, ["modelValue"]),
            _createVNode($setup["CallListFilters"])
          ])
        ]),
        _: 1 /* STABLE */
      }),
      ($setup.sortMenuSelected.length || $setup.activeFilters.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sortMenuSelected, (selected) => {
              return (_openBlock(), _createBlock(_component_UiSortMenuBadge, {
                key: selected.key,
                item: selected,
                onRemove: ($event: any) => ($setup.handleRemoveSortClick(selected))
              }, null, 8 /* PROPS */, ["item", "onRemove"]))
            }), 128 /* KEYED_FRAGMENT */)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.activeFilters, (filter) => {
              return (_openBlock(), _createBlock(_component_UiBadge, {
                key: filter,
                removable: "",
                onRemove: ($event: any) => ($setup.handleRemoveFilter(filter.field))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(`call.filters.activeTag.${filter.field}`)) + ": " + _toDisplayString(filter.label), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onRemove"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("ul", _hoisted_11, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.calls, (call) => {
          return (_openBlock(), _createBlock($setup["CallDetails"], {
            key: call.id,
            call: call,
            class: "hover:bg-white"
          }, null, 8 /* PROPS */, ["call"]))
        }), 128 /* KEYED_FRAGMENT */)),
        (!$setup.calls.length)
          ? (_openBlock(), _createElementBlock("li", _hoisted_12, _toDisplayString(_ctx.$t('core.messages.noRecordsFound')), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ]),
      ($setup.calls.length)
        ? (_openBlock(), _createBlock(_component_UiPagination, {
            key: 1,
            modelValue: $setup.currentPage,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.currentPage) = $event)),
            count: $setup.count,
            perPage: $setup.perPage
          }, null, 8 /* PROPS */, ["modelValue", "count"]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}