export interface UserDeviceModel {
    deviceType: string;
    userName: string;
    extension: number;
    totalTime: {
        CONNECTED: number;
        NOT_INUSE: number;
        IN_CALL: number;
        UNAVAILABLE: number;
    };
    lastStatus: string;
    lastStatusAt: string;
    queues: {
        name: string;
        queueId: string;
        pauseDuration: number;
        pausedAt: string;
        unpausedAt: string;
    }[];
    queueStatus: string;
    queueStatusAt: string;
}
