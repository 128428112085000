import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { OfficeBuildingIcon } from '@heroicons/vue/solid';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import LevelService from '@/services/api/LevelService';
import { toast, toastServiceError } from '@/services/core/notification';

import {
    FilterItem,
    SortMenuItem,
    LevelModel,
    LevelFilter,
    LevelServiceGetAllParams,
    Permission,
    LevelActions,
    LevelGetters,
} from '@/types';

import { LevelListItem, LevelListSort, LevelListFilters } from '@/views/pages/level/components';

const perPage = 10;


export default /*@__PURE__*/_defineComponent({
  __name: 'LevelPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();
const uiStore = useUiStore();
const filterStore = useFilterStore();

const levels = ref<LevelModel[]>([]);
const count = ref(0);
const search = computed({
    get: () => store.getters[LevelGetters.SEARCH],
    set: (value: string) => store.dispatch(LevelActions.SET_SEARCH, value),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[LevelGetters.SORT],
    set: (value: SortMenuItem[]) => store.dispatch(LevelActions.SET_SORT, value),
});

const levelFilter = computed(() => filterStore.level);

const currentPage = computed({
    get: () => store.getters[LevelGetters.PAGE],
    set: (value: number) => store.dispatch(LevelActions.SET_PAGE, value),
});

const filter: LevelFilter = store.getters[LevelGetters.FILTER];
const activeFilters = computed<FilterItem[]>(() => store.getters[LevelGetters.ACTIVE_FILTERS]);

const orderBy = computed(() => sortMenuSelected.value.map((s) => s.direction + s.key).join(','));

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

if (route.query.clearStateOnSetup) {
    search.value = '';
    sortMenuSelected.value = [];
    currentPage.value = 1;
    router.replace({ query: {} });
}

const getParams = (): LevelServiceGetAllParams => ({
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    is_active: filter.isActive !== null ? (filter.isActive.value as boolean) : undefined,
    search: search.value,
});

const fetchLevels = async () => {
    await setLoading(true);
    try {
        const { data, headers } = await LevelService.getAll<LevelModel[]>({
            params: getParams(),
        });
        levels.value = data;
        count.value = parseInt(headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const setIsActive = async (item: LevelModel, isActive: boolean) => {
    await setLoading(true);
    try {
        const { data } = await (
            isActive
                ? (id: number) => LevelService.activate(id)
                : (id: number) => LevelService.inactivate(id)
        )(item.id);
        toast.success(data.message);
        await fetchLevels();
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const handleRemoveSortClick = (item: SortMenuItem) => {
    const index = sortMenuSelected.value.indexOf(item);
    if (index > -1) sortMenuSelected.value.splice(index, 1);
};

const handleRemoveFilterClick = (item: FilterItem) => {
    store.dispatch(LevelActions.CLEAR_FILTER, item);
};

const handleActivateClick = (item: LevelModel) => setIsActive(item, true);
const handleInactivateClick = (item: LevelModel) => setIsActive(item, false);

watch([currentPage], () => {
    fetchLevels();
});

watch([levelFilter, search, filter, orderBy], () => {
    if (currentPage.value === 1) fetchLevels();
    else currentPage.value = 1;
});

onMounted(fetchLevels);

const __returned__ = { route, router, uiStore, filterStore, levels, count, perPage, search, sortMenuSelected, levelFilter, currentPage, filter, activeFilters, orderBy, setLoading, getParams, fetchLevels, setIsActive, handleRemoveSortClick, handleRemoveFilterClick, handleActivateClick, handleInactivateClick, get OfficeBuildingIcon() { return OfficeBuildingIcon }, get Permission() { return Permission }, get LevelListItem() { return LevelListItem }, get LevelListSort() { return LevelListSort }, get LevelListFilters() { return LevelListFilters } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})