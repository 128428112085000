<script lang="ts" setup>
import { computed } from 'vue';
import { IsActiveFlag, CreatedAudit, UpdatedAudit } from '@/types';

const { modelValue } = defineProps<{
    modelValue: Partial<IsActiveFlag & CreatedAudit & UpdatedAudit>;
}>();

const hasIsActive = computed(() => typeof modelValue?.isActive === 'boolean');
</script>

<template>
    <div
        v-if="modelValue"
        class="space-y-2 text-sm md:flex"
        :class="[hasIsActive ? 'md:justify-between' : 'md:justify-end']"
    >
        <div v-if="hasIsActive" class="flex flex-wrap items-center space-x-1 text-gray-500">
            <span> {{ $t('core.status') }}: </span>
            <UiIsActiveBadge :model-value="modelValue" />
        </div>
        <UiAuditInfo :model-value="modelValue" />
    </div>
</template>
