<script lang="ts" setup>
import { ref, Ref, computed, watch, ComputedRef, onMounted } from 'vue';
import moment from 'moment';
import orderBy from 'lodash.orderby';
import { useI18n } from 'vue-i18n';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import { ContactCenterPerAgentReportService } from '@/services';

import {
    CallType,
    ChartDataItem,
    ContactCenterReportActions,
    ContactCenterReportFilter,
    ContactCenterReportGetters,
    ContactCenterReportServiceGetInfoParams,
    ContactCenterReportServiceGetInfoResult,
    DataItem,
    FilterItem,
    LevelModel,
    UserAgentCallsStats,
} from '@/types';

import { ContactCenterPerAgentReportFilters } from '@/views/pages/reports/contact-center-per-agent/components/';

// Constants and props
interface IContactCenterReportChart {
    title: string;
    category: Array<string>;
    series: Array<ChartDataItem<number[]>>;
    xAxisLabelFormatter: (value: number) => string;
}

// State
const reportInfo: Ref<ContactCenterReportServiceGetInfoResult | null> = ref(null);

// Computed
const headerStats = computed<DataItem<string>[]>(() => {
    const stats = reportInfo.value?.stats;
    if (!stats) return [];

    return [
        { name: 'totalCalls', value: stats.total.toString() },
        { name: 'inboundCalls', value: stats.type[CallType.INBOUND].toString() },
        { name: 'outboundCalls', value: stats.type[CallType.OUTBOUND].toString() },
        { name: 'internalCalls', value: stats.type[CallType.INTERNAL].toString() },
        { name: 'uniqueNumbers', value: stats.uniqueNumbers.toString() },
        {
            name: 'averageServiceTime',
            value: moment.utc((stats.averageServiceTime || 0) * 1000).format('HH:mm:ss'),
        },
    ];
});

const xAxisLabelFormatterAsTime = (value: number) => moment.utc(value * 1000).format('HH:mm:ss');

const charts = computed<IContactCenterReportChart[]>(() => {
    return [
        getChart(
            t('report.contactCenterPerAgent.serviceTimePerUserBarChart.title'),
            'averageServiceTime',
            '#BFDBFE',
            xAxisLabelFormatterAsTime,
        ),
        getChart(
            t('report.contactCenterPerAgent.durationPerUserBarChart.title'),
            'averageDuration',
            '#A5F3FC',
            xAxisLabelFormatterAsTime,
        ),
    ];
});

// Composables
const filterStore = useFilterStore();
const uiStore = useUiStore();
const { t } = useI18n();

const levelFilter = computed<LevelModel | null>(() => filterStore.level);
const dateRange = computed<Array<Date>>({
    get: () => filterStore.dateRange,
    set: (value) => filterStore.setDateRange(value),
});
const filter = computed<ContactCenterReportFilter>(
    () => store.getters[ContactCenterReportGetters.FILTER],
);
const activeFilters: ComputedRef<FilterItem[]> = computed(
    () => store.getters[ContactCenterReportGetters.ACTIVE_FILTERS],
);

// Watchers
watch([levelFilter, dateRange, activeFilters], () => {
    fetchReportData();
});

// Methods
const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchReportData = async () => {
    const params: ContactCenterReportServiceGetInfoParams = {
        startDate: moment.utc(dateRange.value[0]).format('YYYY-MM-DD'),
        endDate: moment.utc(dateRange.value[1]).format('YYYY-MM-DD'),
        type: filter.value.type?.value || null,
        status: filter.value.status?.value || null,
        user: filter.value.user?.id || null,
        number: filter.value.number?.number || null,
        serviceTimeRange: filter.value.serviceTimeRange?.value || null,
        userLabel: filter.value.userLabel?.id || null,
    };

    try {
        setLoading(true);
        const { data } = await ContactCenterPerAgentReportService.getInfo({ params });
        reportInfo.value = data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveFilter = (filter: FilterItem) => {
    store.dispatch(ContactCenterReportActions.CLEAR_FILTER, filter.field);
};

const getChart = (
    title: string,
    prop: keyof UserAgentCallsStats,
    color: string,
    xAxisLabelFormatter: (value: number) => string,
): IContactCenterReportChart => {
    const topUsers = orderBy(reportInfo.value?.userAgentCallsStats, prop, 'desc').slice(0, 10);
    const category: string[] = [];
    const serie: ChartDataItem<number[]> = { name: title, value: [], color };

    for (let i = topUsers.length - 1; i >= 0; i--) {
        const user = topUsers[i];
        category.push(user.name);
        serie.value.push(user[prop] as number);
    }

    return {
        title,
        category,
        series: [serie],
        xAxisLabelFormatter,
    };
};

// Lifecycle
onMounted(() => {
    fetchReportData();
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('report.contactCenterPerAgent.title') }}
                </h1>
            </template>
            <template #actions>
                <ContactCenterPerAgentReportFilters />
                <UiDatePicker v-model="dateRange" range multiCalendars class="ml-4" />
            </template>
        </UiPageHeader>

        <div
            v-if="activeFilters.length"
            class="mt-4 flex bg-gray-50 px-4 py-4 shadow ring-1 ring-gray-300 sm:pr-6 md:rounded"
        >
            <template :key="filter" v-for="filter in activeFilters">
                <UiBadge removable @remove="handleRemoveFilter(filter)">
                    {{ $t(`report.contactCenterPerAgent.filters.activeTag.${filter.field}`) }}:
                    {{ filter.label }}
                </UiBadge>
            </template>
        </div>

        <dl
            v-if="headerStats"
            class="mt-4 grid grid-cols-2 gap-4"
            :class="['sm:grid-cols-' + headerStats.length]"
        >
            <UiPanel v-for="stat in headerStats" :key="stat.name">
                <dt class="text-center text-xs font-medium text-gray-500">
                    {{ $t('report.contactCenterPerAgent.stats.' + stat.name) }}
                </dt>
                <dd class="mt-1 text-center text-2xl font-semibold text-gray-800">
                    {{ stat.value }}
                </dd>
            </UiPanel>
        </dl>

        <div v-if="reportInfo" class="mt-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
            <CallTypePerUserBarChart
                :title="t('report.contactCenterPerAgent.callTypePerUserBarChart.title')"
                :data="reportInfo.userAgentCallsStats"
                class="col-span-2 h-[300px] md:col-span-1"
            />

            <CallStatusPerUserBarChart
                :title="t('report.contactCenterPerAgent.callStatusPerUserBarChart.title')"
                :data="reportInfo.userAgentCallsStats"
                class="col-span-2 h-[300px] md:col-span-1"
            />

            <BarChart
                v-for="(chart, index) in charts"
                :key="index"
                :title="chart.title"
                :categoryData="chart.category"
                :seriesData="chart.series"
                :xAxisLabelFormatter="chart.xAxisLabelFormatter"
                class="col-span-2 h-[300px] md:col-span-1"
            />
        </div>
    </div>
</template>
