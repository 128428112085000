import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue';
import { formatDateAsString } from '@/utils/datetime';
import { CalendarIcon } from '@heroicons/vue/solid';
import { Permission, CreatedAudit, UpdatedAudit } from '@/types';
import { useI18n } from 'vue-i18n';
import { RouterLink } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiAuditInfo',
  props: {
    modelValue: { type: Object, required: true },
    dateFormat: { type: String, required: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const { t } = useI18n();

const lastModifiedBy = computed(() => {
    return props.modelValue.updatedByUser || props.modelValue.createdByUser || null;
});

const lastModifiedAt = computed(() => {
    return props.modelValue.updatedAt || props.modelValue.createdAt || null;
});

const lastModifiedType = computed(() => {
    return props.modelValue.updatedByUser ? 'updatedAt' : 'createdAt';
});

// fallback to 'LL' if no dateFormat is provided
const resolvedDateFormat = computed(() => props.dateFormat || 'LL');

const __returned__ = { props, t, lastModifiedBy, lastModifiedAt, lastModifiedType, resolvedDateFormat, get formatDateAsString() { return formatDateAsString }, get CalendarIcon() { return CalendarIcon }, get Permission() { return Permission }, get RouterLink() { return RouterLink } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})