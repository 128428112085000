import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import ConnectFlowAudioService from '@/services/api/ConnectFlowAudioService';
import { toast, toastServiceError } from '@/services/core/notification';

import {
    FilterItem,
    SortMenuItem,
    ConnectFlowAudioModel,
    ConnectFlowAudioFilter,
    ConnectFlowAudioServiceGetAllParams,
    Permission,
    ConnectFlowAudioActions,
    ConnectFlowAudioGetters,
} from '@/types';

import {
    ConnectFlowAudioListItem,
    ConnectFlowAudioListSort,
    ConnectFlowAudioListFilters,
} from '@/views/pages/connect-flow-audio/components';

import { UserGroupIcon } from '@heroicons/vue/solid';

const perPage = 10;


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowAudioPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();

const filterStore = useFilterStore();
const uiStore = useUiStore();

const connectFlowAudios = ref<ConnectFlowAudioModel[]>([]);
const count = ref(0);
const search = computed({
    get: () => store.getters[ConnectFlowAudioGetters.SEARCH],
    set: (value: string) => store.dispatch(ConnectFlowAudioActions.SET_SEARCH, value),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[ConnectFlowAudioGetters.SORT],
    set: (value: SortMenuItem[]) => store.dispatch(ConnectFlowAudioActions.SET_SORT, value),
});

const levelFilter = computed(() => filterStore.level);

const currentPage = computed({
    get: () => store.getters[ConnectFlowAudioGetters.PAGE],
    set: (value: number) => store.dispatch(ConnectFlowAudioActions.SET_PAGE, value),
});

const filter: ConnectFlowAudioFilter = store.getters[ConnectFlowAudioGetters.FILTER];

const activeFilters = computed<FilterItem[]>(
    () => store.getters[ConnectFlowAudioGetters.ACTIVE_FILTERS],
);

const orderBy = computed(() => sortMenuSelected.value.map((s) => s.direction + s.key).join(','));

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getParams = (): ConnectFlowAudioServiceGetAllParams => ({
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    is_active: filter.isActive !== null ? (filter.isActive.value as boolean) : undefined,
    search: search.value,
});

const fetchConnectFlowAudios = async () => {
    await setLoading(true);
    try {
        const { data, headers } = await ConnectFlowAudioService.getAll<ConnectFlowAudioModel[]>({
            params: getParams(),
        });
        connectFlowAudios.value = data;
        count.value = parseInt(headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const setIsActive = async (item: ConnectFlowAudioModel, isActive: boolean) => {
    await setLoading(true);
    try {
        const { data } = await (
            isActive
                ? (id: number) => ConnectFlowAudioService.activate(id)
                : (id: number) => ConnectFlowAudioService.inactivate(id)
        )(item.id);
        toast.success(data.message);
        await fetchConnectFlowAudios();
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const handleActivateClick = (item: ConnectFlowAudioModel) => setIsActive(item, true);
const handleInactivateClick = (item: ConnectFlowAudioModel) => setIsActive(item, false);

const handleRemoveSortClick = (item: SortMenuItem) => {
    const index = sortMenuSelected.value.indexOf(item);
    if (index > -1) sortMenuSelected.value.splice(index, 1);
};

const handleRemoveFilterClick = (item: FilterItem) =>
    store.dispatch(ConnectFlowAudioActions.CLEAR_FILTER, item);

watch([currentPage], () => {
    fetchConnectFlowAudios();
});

watch([levelFilter, search, filter, orderBy], () => {
    if (currentPage.value === 1) fetchConnectFlowAudios();
    else currentPage.value = 1;
});

if (route.query.clearStateOnSetup) {
    search.value = '';
    sortMenuSelected.value = [];
    currentPage.value = 1;
    router.replace({ query: {} });
}

onMounted(fetchConnectFlowAudios);

const __returned__ = { route, router, filterStore, uiStore, connectFlowAudios, count, perPage, search, sortMenuSelected, levelFilter, currentPage, filter, activeFilters, orderBy, setLoading, getParams, fetchConnectFlowAudios, setIsActive, handleActivateClick, handleInactivateClick, handleRemoveSortClick, handleRemoveFilterClick, get Permission() { return Permission }, get ConnectFlowAudioListItem() { return ConnectFlowAudioListItem }, get ConnectFlowAudioListSort() { return ConnectFlowAudioListSort }, get ConnectFlowAudioListFilters() { return ConnectFlowAudioListFilters }, get UserGroupIcon() { return UserGroupIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})