import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "text-xl font-semibold text-gray-800" }
const _hoisted_2 = { class: "flex items-center space-x-4" }
const _hoisted_3 = { class: "gap-4 md:grid md:grid-cols-2" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "mb-2 flex-1 text-lg font-semibold text-gray-800" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "gap-4 md:grid md:grid-cols-2" }
const _hoisted_8 = { class: "flex" }
const _hoisted_9 = { class: "mb-2 flex-1 text-lg font-semibold text-gray-800" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "gap-4 md:grid md:grid-cols-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiBackButton = _resolveComponent("UiBackButton")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!
  const _component_UiTextInput = _resolveComponent("UiTextInput")!
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _component_UiCheckDirtyBeforeRouteLeave = _resolveComponent("UiCheckDirtyBeforeRouteLeave")!

  return (_openBlock(), _createElementBlock("form", {
    class: "space-y-4 p-4 sm:p-6 lg:p-8",
    autocomplete: "off",
    onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
  }, [
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('contact.' + $setup.props.action + '.title')), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_UiBackButton, { fallbackTo: { name: 'ListContact' } }),
          (
                            ($setup.props.action == $setup.FormAction.CREATE &&
                                _ctx.$can($setup.Permission.CREATE_CONTACT)) ||
                            ($setup.props.action == $setup.FormAction.EDIT && _ctx.$can($setup.Permission.EDIT_CONTACT))
                        )
            ? (_openBlock(), _createBlock(_component_UiButton, {
                key: 0,
                type: "submit",
                variant: "primary",
                "text-variant": "white"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Save')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_UiPanel, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_UiTextInput, {
            modelValue: $setup.v$.name.$model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.v$.name.$model) = $event)),
            name: "name",
            type: "text",
            label: _ctx.$t('contact.labels.name'),
            errors: $setup.v$.name.$errors,
            onBlur: $setup.v$.name.$touch
          }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"])
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_UiPanel, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t('contact.form.section.emails.title')), 1 /* TEXT */),
          _createVNode(_component_UiButton, {
            variant: "primary",
            "text-variant": "white",
            onClick: $setup.addEmail
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('contact.form.actions.addEmail')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        ($setup.formState.emails.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('contact.errors.noEmails')), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.formState.emails, (item, index) => {
            return (_openBlock(), _createBlock($setup["ContactEmailForm"], {
              key: index,
              index: index,
              modelValue: $setup.formState.emails[index],
              "onUpdate:modelValue": ($event: any) => (($setup.formState.emails[index]) = $event),
              ref_for: true,
              ref: (el) => $setup.setEmailRef(el, index),
              onRemove: () => $setup.removeEmail(index)
            }, null, 8 /* PROPS */, ["index", "modelValue", "onUpdate:modelValue", "onRemove"]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_UiPanel, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("h2", _hoisted_9, _toDisplayString(_ctx.$t('contact.form.section.phones.title')), 1 /* TEXT */),
          _createVNode(_component_UiButton, {
            variant: "primary",
            "text-variant": "white",
            onClick: $setup.addPhone
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('contact.form.actions.addPhone')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        ($setup.formState.phones.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('contact.errors.noPhones')), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_11, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.formState.phones, (item, index) => {
            return (_openBlock(), _createBlock($setup["ContactPhoneForm"], {
              key: index,
              index: index,
              modelValue: $setup.formState.phones[index],
              "onUpdate:modelValue": ($event: any) => (($setup.formState.phones[index]) = $event),
              ref_for: true,
              ref: (el) => $setup.setPhoneRef(el, index),
              onRemove: () => $setup.removePhone(index)
            }, null, 8 /* PROPS */, ["index", "modelValue", "onUpdate:modelValue", "onRemove"]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_UiCheckDirtyBeforeRouteLeave, {
      dirty: $setup.v$.$anyDirty
    }, null, 8 /* PROPS */, ["dirty"])
  ], 32 /* NEED_HYDRATION */))
}