import { createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "space-y-4"
}
const _hoisted_2 = { class: "flex rounded bg-gray-100 text-gray-400 focus-within:text-gray-600" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "flex flex-col items-center justify-center space-y-4 pr-2" }
const _hoisted_6 = { class: "flex flex-1 flex-col space-y-1" }
const _hoisted_7 = { ref: "audioElement" }
const _hoisted_8 = { class: "flex flex-shrink flex-col sm:w-auto" }
const _hoisted_9 = { class: "flex grow items-center" }
const _hoisted_10 = { class: "w-full rotate-90 transform text-center text-gray-500" }
const _hoisted_11 = { class: "flex grow items-center" }
const _hoisted_12 = { class: "w-full rotate-90 transform text-center text-gray-500" }
const _hoisted_13 = { class: "flex px-0 md:px-14" }
const _hoisted_14 = { class: "flex space-x-4" }
const _hoisted_15 = { class: "flex items-center text-xs text-blue-500" }
const _hoisted_16 = { class: "flex items-center text-xs text-orange-500" }
const _hoisted_17 = { class: "ml-auto text-sm text-gray-500" }
const _hoisted_18 = {
  key: 1,
  class: "text-danger-700"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock(_component_UiPanel, null, {
    default: _withCtx(() => [
      (!$setup.playerError)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode($setup["SearchIcon"], {
                class: "my-2 ml-4 h-6 w-6",
                "aria-hidden": "true"
              }),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchWord) = $event)),
                type: "search",
                autocomplete: "off",
                placeholder: _ctx.$t('core.actions.Search'),
                class: "grow border-transparent bg-transparent px-4 py-2 text-base text-gray-800 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0",
                onInput: _cache[1] || (_cache[1] = ($event: any) => (
                        $setup.debounce(
                            () => $setup.store.commit($setup.CallPlayerMutations.SET_SEARCH_QUERY, $setup.searchWord),
                            1000,
                        )()
                    ))
              }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_3), [
                [_vModelText, $setup.searchWord]
              ])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _withDirectives((_openBlock(), _createBlock(_component_UiButton, {
                  variant: "primary",
                  "text-variant": "white",
                  "no-padding": "",
                  "no-rounded": "",
                  class: "h-10 w-10 rounded-full",
                  onClick: $setup.playPause
                }, {
                  default: _withCtx(() => [
                    (!$setup.isPlaying)
                      ? (_openBlock(), _createBlock($setup["PlayIcon"], {
                          key: 0,
                          class: "text-lg text-white"
                        }))
                      : (_openBlock(), _createBlock($setup["PauseIcon"], {
                          key: 1,
                          class: "text-lg text-white"
                        }))
                  ]),
                  _: 1 /* STABLE */
                })), [
                  [_directive_tippy, _ctx.$t('call.view.player.PlayPause')]
                ]),
                _withDirectives((_openBlock(), _createBlock(_component_UiButton, {
                  variant: "primary",
                  "text-variant": "white",
                  "no-padding": "",
                  class: "h-6 w-6 text-sm",
                  onClick: $setup.changeSpeed
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString($setup.playbackRate) + "x", 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })), [
                  [_directive_tippy, _ctx.$t('call.view.player.ChangeSpeed')]
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode($setup["CallPlayerBullets"], {
                  call: $props.call,
                  callDuration: $setup.callDuration,
                  bulletType: $setup.CallBulletType.registered,
                  participantType: 
                            $props.call.type == $setup.CallType.INTERNAL
                                ? $setup.CallParticipantType.AgentA
                                : $setup.CallParticipantType.Agent
                        ,
                  onPlaySnippet: $setup.handlePlaySnippet
                }, null, 8 /* PROPS */, ["call", "callDuration", "bulletType", "participantType"]),
                _createVNode($setup["CallPlayerBullets"], {
                  call: $props.call,
                  callDuration: $setup.callDuration,
                  bulletType: $setup.CallBulletType.matched,
                  participantType: 
                            $props.call.type == $setup.CallType.INTERNAL
                                ? $setup.CallParticipantType.AgentA
                                : $setup.CallParticipantType.Agent
                        ,
                  onPlaySnippet: $setup.handlePlaySnippet
                }, null, 8 /* PROPS */, ["call", "callDuration", "bulletType", "participantType"]),
                _createElementVNode("div", _hoisted_7, null, 512 /* NEED_PATCH */),
                _createVNode($setup["CallPlayerBullets"], {
                  call: $props.call,
                  callDuration: $setup.callDuration,
                  bulletType: $setup.CallBulletType.matched,
                  participantType: 
                            $props.call.type == $setup.CallType.INTERNAL
                                ? $setup.CallParticipantType.AgentB
                                : $setup.CallParticipantType.Client
                        ,
                  onPlaySnippet: $setup.handlePlaySnippet
                }, null, 8 /* PROPS */, ["call", "callDuration", "bulletType", "participantType"]),
                _createVNode($setup["CallPlayerBullets"], {
                  call: $props.call,
                  callDuration: $setup.callDuration,
                  bulletType: $setup.CallBulletType.registered,
                  participantType: 
                            $props.call.type == $setup.CallType.INTERNAL
                                ? $setup.CallParticipantType.AgentB
                                : $setup.CallParticipantType.Client
                        ,
                  onPlaySnippet: $setup.handlePlaySnippet
                }, null, 8 /* PROPS */, ["call", "callDuration", "bulletType", "participantType"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t(
                                    'call.view.participant.' +
                                        ($props.call.type === $setup.CallType.INTERNAL
                                            ? $setup.CallParticipantType.AgentA.toLowerCase()
                                            : $setup.CallParticipantType.Agent.toLowerCase()),
                                )), 1 /* TEXT */)
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t(
                                    'call.view.participant.' +
                                        ($props.call.type === $setup.CallType.INTERNAL
                                            ? $setup.CallParticipantType.AgentB.toLowerCase()
                                            : $setup.CallParticipantType.Client.toLowerCase()),
                                )), 1 /* TEXT */)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _cache[2] || (_cache[2] = _createElementVNode("div", { class: "group mr-1 h-4 w-4 rounded-full bg-blue-500/50 p-0" }, null, -1 /* HOISTED */)),
                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('call.audioPlayer.label.words')), 1 /* TEXT */)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _cache[3] || (_cache[3] = _createElementVNode("div", { class: "group mr-1 h-4 w-4 rounded-full bg-orange-500/50 p-0" }, null, -1 /* HOISTED */)),
                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('call.audioPlayer.label.matchedWords')), 1 /* TEXT */)
                ])
              ]),
              _createElementVNode("div", _hoisted_17, _toDisplayString($setup.formatSecToTime($setup.secondsCurrent)) + " / " + _toDisplayString($setup.formatSecToTime($setup.callDuration)), 1 /* TEXT */)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_18, [
            _createVNode($setup["ExclamationCircleIcon"], { class: "inline h-6 w-6" }),
            _createTextVNode(" " + _toDisplayString($setup.playerError), 1 /* TEXT */)
          ]))
    ]),
    _: 1 /* STABLE */
  }))
}