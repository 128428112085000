import { defineComponent as _defineComponent } from 'vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ArrowCircleDownIcon, ArrowCircleUpIcon, SwitchVerticalIcon } from '@heroicons/vue/solid';
import { SortMenuItem, SortMenuItemDirection } from '@/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiSortMenu',
  props: {
    modelValue: {
        type: Array<SortMenuItem>,
        default: [],
    },
    label: {
        type: String,
    },
    items: {
        type: Array<SortMenuItem>,
        required: true,
        default: [],
    },
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;

const handleItemClick = (item: SortMenuItem, direction?: SortMenuItemDirection) => {
    const currentItems = [...props.modelValue];
    const currentItem = currentItems.find((cItem) => cItem.key == item.key);

    if (currentItem) {
        if (currentItem.direction === direction) {
            currentItems.splice(currentItems.indexOf(currentItem), 1);
        } else if (direction) {
            currentItem.direction = direction;
        } else {
            currentItem.direction =
                currentItem.direction === SortMenuItemDirection.DESC
                    ? SortMenuItemDirection.ASC
                    : SortMenuItemDirection.DESC;
        }
    } else {
        currentItems.push({ ...item, direction: direction || SortMenuItemDirection.ASC });
    }

    emit('update:modelValue', currentItems);
};

const __returned__ = { props, emit, handleItemClick, get Menu() { return Menu }, get MenuButton() { return MenuButton }, get MenuItem() { return MenuItem }, get MenuItems() { return MenuItems }, get ArrowCircleDownIcon() { return ArrowCircleDownIcon }, get ArrowCircleUpIcon() { return ArrowCircleUpIcon }, get SwitchVerticalIcon() { return SwitchVerticalIcon }, get SortMenuItemDirection() { return SortMenuItemDirection } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})