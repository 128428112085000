<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

import { useUiStore } from '@/stores';
import MeetingService from '@/services/api/MeetingService';
import { toastServiceError } from '@/services/core/notification';

import {
    calculateSpeechPercentage,
    formatMessagesWithElapsedTime,
    formatDateTime,
} from '@/utils/meeting';

import { SpeakerInsights, TranscriptSection } from '@/views/pages/meeting/components';
import { MeetingModel } from '@/types';
import { ChevronLeftIcon } from '@heroicons/vue/outline';

// Stores & Route
const uiStore = useUiStore();
const route = useRoute();
const { t } = useI18n();

// State
const meeting = ref<MeetingModel | null>(null);

// Computed
const isLoading = computed(() => uiStore.isLoading);
const formattedCreatedAt = computed(() => formatDateTime(meeting.value?.createdAt));

// Methods
const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getMeeting = async (id: number) => {
    try {
        await setLoading(true);

        const response = await MeetingService.get<MeetingModel>(id);
        meeting.value = response.data;

        if (meeting.value?.participants) {
            meeting.value.participants = calculateSpeechPercentage(meeting.value.participants);
        }

        if (meeting.value?.messages) {
            meeting.value.messages = formatMessagesWithElapsedTime(meeting.value.messages);
        }
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

// Lifecycle
onMounted(() => {
    const meetingId = Number(route.params.id);
    getMeeting(meetingId);
});
</script>

<template>
    <div class="space-y-4 overflow-hidden p-6" v-if="meeting !== null && !isLoading">
        <!-- Título da reunião e detalhes -->
        <div>
            <div class="flex justify-end">
                <router-link
                    class="text-primary hover:text-primary-dark ml-auto flex flex-row items-center"
                    :to="{ name: 'MeetingList' }"
                >
                    <ChevronLeftIcon class="h-5 w-6" />
                    {{ t('core.actions.Back') }}
                </router-link>
            </div>
            <h1 class="text-2xl font-semibold text-gray-800">{{ meeting.title }}</h1>
            <p class="mt-1 text-sm text-gray-600">
                Gravado por {{ meeting.createdByUser?.name }}, {{ formattedCreatedAt }},
                {{ Math.floor(meeting.speechDuration / 60) }} min.
            </p>
        </div>

        <div class="flex flex-col gap-4 lg:flex-row">
            <!-- Coluna da esquerda -->
            <div class="h-[75vh] space-y-4 lg:w-1/2">
                <!-- Insights dos palestrantes -->
                <SpeakerInsights :participants="meeting.participants" />
            </div>

            <!-- Coluna da direita: Transcrição da reunião -->
            <div class="flex h-[75vh] flex-col lg:w-1/2">
                <TranscriptSection :messages="meeting.messages" class="overflow-y-auto rounded" />
            </div>
        </div>
    </div>
</template>
