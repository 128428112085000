import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "flex h-[75vh] flex-col rounded bg-gray-50 p-6 shadow ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50" }
const _hoisted_2 = { class: "mb-4 flex items-center" }
const _hoisted_3 = { class: "text-lg font-bold text-gray-800" }
const _hoisted_4 = { class: "flex-1 space-y-3 overflow-y-auto" }
const _hoisted_5 = { class: "min-w-[30%] max-w-[30%] font-semibold text-gray-700" }
const _hoisted_6 = { class: "flex flex-1 items-center" }
const _hoisted_7 = { class: "mr-3 h-2 w-full rounded-full bg-gray-300" }
const _hoisted_8 = { class: "min-w-[40px] text-right text-sm font-semibold text-gray-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["SparklesIcon"], {
        class: "mr-3 h-6 w-6 flex-shrink-0 text-[#6dbcab] group-hover:text-[#6dbcab]",
        "aria-hidden": "true"
      }),
      _createElementVNode("h3", _hoisted_3, _toDisplayString($setup.t('meeting.view.insights.title')), 1 /* TEXT */)
    ]),
    _createElementVNode("ul", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.participants, (participant, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: participant.id,
          class: "flex items-center justify-between"
        }, [
          _createCommentVNode(" Nome do participante com largura fixa "),
          _createElementVNode("span", _hoisted_5, _toDisplayString(participant.name), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_6, [
            _createCommentVNode(" Barra de progresso "),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", {
                class: "h-2 rounded-full",
                style: _normalizeStyle({
                                width: participant.speechPercentage + '%',
                                backgroundColor: $setup.getParticipantColor(index),
                            })
              }, null, 4 /* STYLE */)
            ]),
            _createCommentVNode(" Porcentagem com largura fixa "),
            _createElementVNode("span", _hoisted_8, _toDisplayString(participant.speechPercentage) + "%", 1 /* TEXT */)
          ])
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createCommentVNode(" Prompt de IA (futuro) "),
    _createCommentVNode(" <div\n      class=\"bg-gray-50 shadow rounded p-6 ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50 flex-grow flex flex-col\"\n    >\n      <div class=\"flex items-center mb-4\">\n        <ChatIcon\n          class=\"mr-3 h-6 w-6 flex-shrink-0 text-[#6dbcab] group-hover:text-[#6dbcab]\"\n          aria-hidden=\"true\"\n        />\n        <h3 class=\"text-lg font-semibold text-gray-800\">\n          {{ t(\"meeting.view.ia.title\") }}\n        </h3>\n      </div>\n\n      <div class=\"p-4 rounded flex-1 overflow-y-auto\">\n        <p class=\"text-gray-500\">{{ t(\"meeting.view.ia.devMessage\") }}</p>\n      </div>\n    </div> ")
  ]))
}