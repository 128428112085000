import { ListFilters, MultiSelectOption } from '@/types';

export interface WordFilter {
    callParticipant: MultiSelectOption | null;
    isActive: MultiSelectOption | null;
}

export interface WordState extends ListFilters {
    filter: WordFilter;
}

export enum WordMutations {
    SET_SEARCH = 'word_setSearch',
    SET_SORT = 'word_setSort',
    SET_PAGE = 'word_setPage',
    SET_FILTER = 'word_setFilter',
    CLEAR_FILTER = 'word_clearFilter',
}

export enum WordActions {
    SET_SEARCH = 'word_setSearch',
    SET_SORT = 'word_setSort',
    SET_PAGE = 'word_setPage',
    SET_FILTER = 'word_setFilter',
    CLEAR_FILTER = 'word_clearFilter',
}

export enum WordGetters {
    SEARCH = 'word_search',
    SORT = 'word_sort',
    PAGE = 'word_page',
    DEFAULT_FILTER = 'word_defaultFilter',
    FILTER = 'word_filter',
    ACTIVE_FILTERS = 'word_activeFilters',
}
