<script setup>
import UiButton from '@/components/UiButton.vue';
import { CheckCircleIcon } from '@heroicons/vue/solid';

defineProps({
    isActive: {
        type: Boolean,
    },
    logoPath: {
        type: String,
        required: true,
    },
    contentText: {
        type: String,
        required: true,
    },
});
const emit = defineEmits(['configClick', 'viewLogsClick', 'activateClick']);

const configClick = () => {
    emit('configClick');
};

const viewLogsClick = () => {
    emit('viewLogsClick');
};

const activateClick = () => {
    emit('activateClick');
};
</script>

<template>
    <div class="rounded bg-gray-50 p-4 shadow">
        <div class="text-right">
            <CheckCircleIcon
                class="inline-block h-6 w-6"
                :class="[isActive ? 'text-secondary' : 'text-slate-300']"
                aria-hidden="true"
            />
        </div>
        <img :src="logoPath" :alt="contentText" class="mx-auto mb-4 h-12" />
        <p class="mb-4 text-center text-gray-600">
            {{ contentText }}
        </p>
        <div class="flex space-x-4">
            <UiButton
                v-if="isActive != undefined"
                variant="primary"
                text-variant="white"
                class="flex-1"
                @click="configClick"
            >
                {{ $t('integration.labels.config') }}
            </UiButton>
            <UiButton
                v-if="isActive != undefined"
                variant="white"
                text-variant="primary"
                class="flex-1"
                @click="viewLogsClick"
            >
                {{ $t('integration.labels.viewlogs') }}
            </UiButton>
            <UiButton
                v-else
                variant="primary"
                text-variant="white"
                class="flex-1"
                @click="activateClick"
            >
                {{ $t('integration.labels.activate') }}
            </UiButton>
        </div>
    </div>
</template>
