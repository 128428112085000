<script lang="ts" setup>
import { PropType } from 'vue';
import { ArrowCircleDownIcon, ArrowCircleUpIcon } from '@heroicons/vue/solid';
import { SortMenuItem, SortMenuItemDirection } from '@/types';

const props = defineProps({
    item: {
        type: Object as PropType<SortMenuItem>,
        required: true,
    },
});

const emit = defineEmits(['remove']);

const handleRemoveClick = (item: SortMenuItem) => {
    emit('remove', item);
};
</script>

<template>
    <UiBadge removable @remove="handleRemoveClick(props.item)">
        <component
            :is="
                props.item.direction == SortMenuItemDirection.ASC
                    ? ArrowCircleDownIcon
                    : ArrowCircleUpIcon
            "
            class="text-primary h-4 w-4 cursor-pointer"
            aria-hidden="true"
        />
        {{ props.item.label }}
    </UiBadge>
</template>
