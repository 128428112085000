import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, onMounted } from 'vue';
import { useRoute, RouterLink } from 'vue-router';

import { useUiStore } from '@/stores';
import LevelService from '@/services/api/LevelService';
import { toastServiceError } from '@/services/core/notification';

import { LevelModel, Permission } from '@/types';
import { MailIcon } from '@heroicons/vue/solid';


export default /*@__PURE__*/_defineComponent({
  __name: 'LevelDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const uiStore = useUiStore();

const level: Ref<LevelModel | undefined> = ref(undefined);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchLevel = async (id: number) => {
    await setLoading(true);
    try {
        const response = await LevelService.get<LevelModel>(id);
        level.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

onMounted(() => {
    const levelId = Number(route.params.id);
    if (!isNaN(levelId)) fetchLevel(levelId);
});

const __returned__ = { route, uiStore, level, setLoading, fetchLevel, get RouterLink() { return RouterLink }, get Permission() { return Permission }, get MailIcon() { return MailIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})