import { NumberFormState, NumberModel, UserServiceCheckExtensionNumberBody } from '@/types';
import BaseService from '@/services/api/BaseService';
import { AxiosRequestConfig } from 'axios';

class NumberService<T> extends BaseService<NumberModel | NumberFormState> {
    getStates<ResponseT = T[]>(config?: AxiosRequestConfig) {
        return this.http.get<ResponseT>(`${this.route}/options/states/`, config);
    }
    getCities<ResponseT = T[]>(config?: AxiosRequestConfig) {
        return this.http.get<ResponseT>(`${this.route}/options/cities/`, config);
    }
    checkExtensionNumber<ResponseT = T[]>(
        siblings: UserServiceCheckExtensionNumberBody,
        config?: AxiosRequestConfig,
    ) {
        return this.http.post<ResponseT>(`${this.route}/check-extension-number`, siblings, config);
    }
}

export default new NumberService('numbers');
