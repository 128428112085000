import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "text-xs"
}
const _hoisted_4 = { class: "flex rounded" }
const _hoisted_5 = ["name", "id", "value", "disabled"]
const _hoisted_6 = ["value"]
const _hoisted_7 = ["name", "id", "value", "disabled"]
const _hoisted_8 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.label !== false)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: $props.name,
          class: "mb-2 block text-sm font-medium text-gray-800"
        }, [
          _createTextVNode(_toDisplayString($props.label) + " ", 1 /* TEXT */),
          ($props.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
            : _createCommentVNode("v-if", true)
        ], 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("select", {
        name: $props.name + '-hours',
        id: $props.name + '-hours',
        class: _normalizeClass(["focus:border-primary block flex-1 rounded border border-gray-300 px-3 py-2 text-gray-800 shadow placeholder:text-gray-400 focus:outline-none focus:ring", [
                    !$setup.hasErrors
                        ? 'focus:ring-primary ring-gray-300'
                        : 'ring-danger focus:ring-danger',
                ]]),
        value: $setup.getHours($setup.props.modelValue),
        onChange: _cache[0] || (_cache[0] = ($event: any) => ($setup.handleHoursInput(($event.target as HTMLInputElement).value))),
        disabled: $props.disabled,
        onBlur: _cache[1] || (_cache[1] = ($event: any) => ($setup.emit('blur', $event)))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Array.from({ length: 24 }, (_, i) => i), (hour) => {
          return (_openBlock(), _createElementBlock("option", {
            value: hour.toString().padStart(2, '0'),
            key: hour
          }, _toDisplayString(hour.toString().padStart(2, '0')), 9 /* TEXT, PROPS */, _hoisted_6))
        }), 128 /* KEYED_FRAGMENT */))
      ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_5),
      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "mx-2 mb-2 mt-2 text-lg font-bold" }, ":", -1 /* HOISTED */)),
      _createElementVNode("select", {
        name: $props.name + '-minutes',
        id: $props.name + '-minutes',
        class: _normalizeClass(["focus:border-primary block flex-1 rounded border border-gray-300 px-3 py-2 text-gray-800 shadow placeholder:text-gray-400 focus:outline-none focus:ring", [
                    !$setup.hasErrors
                        ? 'focus:ring-primary ring-gray-300'
                        : 'ring-danger focus:ring-danger',
                ]]),
        value: $setup.getMinutes($setup.props.modelValue),
        onChange: _cache[2] || (_cache[2] = ($event: any) => (
                    $setup.handleMinutesInput(
                        $event.target ? ($event.target as HTMLInputElement).value : '',
                    )
                )),
        disabled: $props.disabled,
        onBlur: _cache[3] || (_cache[3] = ($event: any) => ($setup.emit('blur', $event)))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Array.from({ length: 60 }, (_, i) => i), (minute) => {
          return (_openBlock(), _createElementBlock("option", {
            value: minute.toString().padStart(2, '0'),
            key: minute
          }, _toDisplayString(minute.toString().padStart(2, '0')), 9 /* TEXT, PROPS */, _hoisted_8))
        }), 128 /* KEYED_FRAGMENT */))
      ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_7)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.errors, (error) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "text-danger text-sm",
        key: error.$uid
      }, [
        _createElementVNode("p", null, _toDisplayString(error.$response ? error.$response : error.$message), 1 /* TEXT */)
      ]))
    }), 128 /* KEYED_FRAGMENT */)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.silentErrors, (error) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "text-danger text-sm",
        key: error.$uid
      }, [
        _createElementVNode("p", null, _toDisplayString(error.$response ? error.$response : error.$message), 1 /* TEXT */)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}