<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AxiosResponse } from 'axios';

import { useUiStore } from '@/stores';
import SpamNumberService from '@/services/api/SpamNumberService';
import { toastServiceError } from '@/services/core/notification';
import { Permission, SpamNumberModel } from '@/types';
import { applyPhoneMask } from '@/utils/number';

const route = useRoute();

const uiStore = useUiStore();

const spamNumber = ref<SpamNumberModel | undefined>(undefined);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchSpamNumber = async (id: number) => {
    setLoading(true);
    try {
        const response: AxiosResponse<SpamNumberModel> =
            await SpamNumberService.get<SpamNumberModel>(id);
        spamNumber.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

onMounted(() => {
    const id = Number(route.params.id);
    if (!isNaN(id)) {
        fetchSpamNumber(id);
    }
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('spamNumber.view.title') }}
                </h1>
            </template>
            <template #actions>
                <div class="flex space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ListSpamNumber' }" />
                    <router-link
                        v-if="$can(Permission.EDIT_SPAM_NUMBER)"
                        :to="{ name: 'EditSpamNumberById', params: { id: route.params.id } }"
                        class="block hover:bg-gray-50"
                    >
                        <UiButton variant="primary" text-variant="white">
                            {{ $t('core.actions.Edit') }}
                        </UiButton>
                    </router-link>
                </div>
            </template>
        </UiPageHeader>

        <div v-if="spamNumber" class="space-y-4">
            <UiPanel>
                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                    <div>
                        <h3 class="mb-1 font-semibold">
                            {{ $t('spamNumber.labels.number') }}
                        </h3>
                        <p>{{ applyPhoneMask(spamNumber.number) }}</p>
                    </div>
                </div>
                <UiAuditStatusInfo
                    :model-value="spamNumber"
                    class="bt-1 mt-4 border-t border-gray-300 pt-2"
                />
            </UiPanel>
        </div>
    </div>
</template>
