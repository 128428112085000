<script lang="ts" setup>
import { ref, Ref, computed, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

import store from '@/store';
import { useFilterStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import { UserLabelService, UserService, NumberService } from '@/services';

import {
    MultiSelectOption,
    ContactCenterPerDayReportFilter,
    ContactCenterPerDayReportGetters,
    ContactCenterPerDayReportActions,
    UserModel,
    LevelModel,
    UserLabelModel,
    NumberModel,
    CallTimeRange,
    CallStatus,
    CallType,
} from '@/types';

import { FilterIcon } from '@heroicons/vue/solid';

// Constants
const filterOpened = ref(false);

// Composables
const filterStore = useFilterStore();
const { t } = useI18n();

// State
const userOptions: Ref<UserModel[]> = ref([]);
const numberOptions: Ref<NumberModel[]> = ref([]);
const userLabelOptions: Ref<UserLabelModel[]> = ref([]);

const typeOptions: Ref<MultiSelectOption[]> = ref([
    { value: CallType.INBOUND, label: t('core.options.call.type.inbound') },
    { value: CallType.OUTBOUND, label: t('core.options.call.type.outbound') },
    { value: CallType.INTERNAL, label: t('core.options.call.type.internal') },
]);

const statusOptions: Ref<MultiSelectOption[]> = ref([
    { value: CallStatus.ANSWERED, label: t('core.options.call.status.answered') },
    { value: CallStatus.NO_ANSWER, label: t('core.options.call.status.no_answer') },
    { value: CallStatus.BUSY, label: t('core.options.call.status.busy') },
    { value: CallStatus.DITCHED, label: t('core.options.call.status.ditched') },
]);

const serviceTimeRangeOptions: Ref<MultiSelectOption[]> = ref(
    [
        CallTimeRange.BELOW_30S,
        CallTimeRange.BETWEEN_30S_AND_60S,
        CallTimeRange.BETWEEN_61_AND_180S,
        CallTimeRange.BETWEEN_181S_AND_300S,
        CallTimeRange.BETWEEN_301S_AND_480S,
        CallTimeRange.BETWEEN_481S_AND_600S,
        CallTimeRange.ABOVE_600,
    ].map((range) => ({
        value: range,
        label: t('core.options.call.serviceTimeRange.' + range),
    })),
);

// Computed
const defaultFilter = computed(
    () => store.getters[ContactCenterPerDayReportGetters.DEFAULT_FILTER],
);
const storeFilter = computed(() => store.getters[ContactCenterPerDayReportGetters.FILTER]);
const levelFilter = computed<LevelModel | null>(() => filterStore.level);

const filter: Ref<ContactCenterPerDayReportFilter> = ref({
    type: defaultFilter.value.type,
    status: defaultFilter.value.status,
    user: defaultFilter.value.user,
    number: defaultFilter.value.number,
    serviceTimeRange: defaultFilter.value.serviceTimeRange,
    userLabel: defaultFilter.value.userLabel,
});

// Watchers
watch(filterOpened, () => {
    refreshLocalFilter();
});

watch(levelFilter, () => {
    store.dispatch(ContactCenterPerDayReportActions.CLEAR_FILTER, 'user');
    fetchUsers();
    store.dispatch(ContactCenterPerDayReportActions.CLEAR_FILTER, 'number');
    fetchNumbers();
    store.dispatch(ContactCenterPerDayReportActions.CLEAR_FILTER, 'userLabel');
    fetchUserLabels();
});

// Methods
const fetchUsers = async () => {
    try {
        const response = await UserService.getAll<UserModel[]>({
            params: { isAgent: true, page: 1, per_page: 100 },
        });
        userOptions.value = response.data;
    } catch (error) {
        toastServiceError(error);
    }
};

const fetchNumbers = async () => {
    try {
        const response = await NumberService.getAll<NumberModel[]>();
        numberOptions.value = response.data;
    } catch (error) {
        toastServiceError(error);
    }
};

const fetchUserLabels = async () => {
    try {
        const response = await UserLabelService.getAll<UserLabelModel[]>();
        userLabelOptions.value = response.data;
    } catch (error) {
        toastServiceError(error);
    }
};

const applyFilters = () => {
    store.dispatch(ContactCenterPerDayReportActions.SET_FILTER, filter.value);
};

const clearFilters = () => {
    store.dispatch(ContactCenterPerDayReportActions.CLEAR_FILTER);
    refreshLocalFilter();
};

const refreshLocalFilter = () => {
    filter.value.type = storeFilter.value.type;
    filter.value.status = storeFilter.value.status;
    filter.value.user = storeFilter.value.user;
    filter.value.number = storeFilter.value.number;
    filter.value.serviceTimeRange = storeFilter.value.serviceTimeRange;
    filter.value.userLabel = storeFilter.value.userLabel;
};

// Lifecycle
onMounted(() => {
    fetchUsers();
    fetchNumbers();
    fetchUserLabels();
});
</script>

<template>
    <UiRightPanel v-model="filterOpened" :title="$t('filter.sidebar.title')">
        <template #button>
            <UiButton
                size="md"
                variant="white"
                text-variant="gray-700"
                @click="filterOpened = true"
            >
                <FilterIcon class="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                {{ $t('core.actions.Filter') }}
            </UiButton>
        </template>
        <template #default>
            <form autocomplete="off" class="flex flex-col gap-3">
                <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                    <div class="sm:col-span-3">
                        <label class="block text-sm font-medium text-gray-800">
                            {{ $t('report.contactCenterPerDay.filters.type') }}
                        </label>
                        <UiMultiselect
                            name="filter_type"
                            v-model="filter.type"
                            :options="typeOptions"
                            :can-clear="true"
                            :placeholder="t('core.options.All')"
                        />
                    </div>
                </div>
                <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                    <div class="sm:col-span-3">
                        <label class="block text-sm font-medium text-gray-800">
                            {{ $t('report.contactCenterPerDay.filters.status') }}
                        </label>
                        <UiMultiselect
                            name="filter_status"
                            v-model="filter.status"
                            :options="statusOptions"
                            :can-clear="true"
                            :placeholder="t('core.options.All')"
                        />
                    </div>
                </div>
                <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                    <div class="sm:col-span-5">
                        <label class="block text-sm font-medium text-gray-800">
                            {{ $t('report.contactCenterPerDay.filters.userLabel') }}
                        </label>
                        <UiMultiselect
                            name="filter_user_label"
                            v-model="filter.userLabel"
                            label="label"
                            value-prop="id"
                            :options="userLabelOptions"
                            :can-clear="true"
                            :placeholder="t('core.options.All')"
                        />
                    </div>
                </div>
                <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                    <div class="sm:col-span-5">
                        <label class="block text-sm font-medium text-gray-800">
                            {{ $t('report.contactCenterPerDay.filters.user') }}
                        </label>
                        <UiMultiselect
                            name="filter_user"
                            v-model="filter.user"
                            label="name"
                            value-prop="id"
                            :options="userOptions"
                            :can-clear="true"
                            :placeholder="t('core.options.All')"
                        />
                    </div>
                </div>
                <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                    <div class="sm:col-span-5">
                        <label class="block text-sm font-medium text-gray-800">
                            {{ $t('report.contactCenterPerDay.filters.number') }}
                        </label>
                        <UiMultiselect
                            name="filter_number"
                            v-model="filter.number"
                            label="number"
                            value-prop="id"
                            :options="numberOptions"
                            :can-clear="true"
                            :placeholder="t('core.options.All')"
                        />
                    </div>
                </div>
                <div class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                    <div class="sm:col-span-5">
                        <label class="block text-sm font-medium text-gray-800">
                            {{ $t('report.contactCenterPerDay.filters.serviceTimeRange') }}
                        </label>
                        <UiMultiselect
                            name="filter_serviceTimeRange"
                            v-model="filter.serviceTimeRange"
                            :options="serviceTimeRangeOptions"
                            :can-clear="true"
                            :placeholder="t('core.options.All')"
                        />
                    </div>
                </div>
                <div class="mt-4 grid grid-cols-2 place-content-around gap-4">
                    <UiButton variant="white" text-variant="gray-700" @click="clearFilters()">
                        {{ $t('core.actions.Clear') }}
                    </UiButton>
                    <UiButton variant="primary" text-variant="white" @click="applyFilters()">
                        {{ $t('core.actions.Apply') }}
                    </UiButton>
                </div>
            </form>
        </template>
    </UiRightPanel>
</template>
