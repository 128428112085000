export interface RealtimeReportServiceGetQueueCallsResult {
    numberFrom: string;
    numberTo: string;
    duration: number;
    queueId: string;
    queueName: string;
}

export interface RealtimeReportServiceGetAgentExtensionsResult {
    extension: string;
    user: string;
    userDevice: string;
    status: string;
    numberClient: string;
    quality: string;
    type: string;
    duration: number;
}
