<script lang="ts" setup>
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue';

defineProps({
    show: {
        type: Boolean,
        required: true,
        default: false,
    },
    text: {
        type: String,
    },
});
</script>

<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="fixed inset-0 z-50 overflow-y-auto">
            <div
                class="flex min-h-screen items-center justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0"
            >
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay class="fixed inset-0 bg-gray-500/75 transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true"
                    >&#8203;</span
                >
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="relative inline-block w-52 transform overflow-hidden rounded bg-gray-50 px-4 pb-4 pt-5 text-left align-bottom shadow transition-all sm:my-8 sm:p-6 sm:align-middle"
                    >
                        <DialogTitle class="text-lg font-medium text-gray-700">
                            <UiSpinner class="theme-color float-left mr-3 h-10 w-10" />
                            {{ text !== undefined ? text : $t('loading') }}
                        </DialogTitle>
                    </div>
                </TransitionChild>
            </div>
            <button>This is a focusable element.</button>
        </Dialog>
    </TransitionRoot>
</template>
