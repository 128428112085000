import { ListFilters, MultiSelectOption } from '@/types';

export interface UserLabelFilter {
    isActive: MultiSelectOption | null;
}

export interface UserLabelState extends ListFilters {
    filter: UserLabelFilter;
}

export enum UserLabelMutations {
    SET_SEARCH = 'userLabel_setSearch',
    SET_SORT = 'userLabel_setSort',
    SET_PAGE = 'userLabel_setPage',
    SET_FILTER = 'userLabel_setFilter',
    CLEAR_FILTER = 'userLabel_clearFilter',
}

export enum UserLabelActions {
    SET_SEARCH = 'userLabel_setSearch',
    SET_SORT = 'userLabel_setSort',
    SET_PAGE = 'userLabel_setPage',
    SET_FILTER = 'userLabel_setFilter',
    CLEAR_FILTER = 'userLabel_clearFilter',
}

export enum UserLabelGetters {
    SEARCH = 'userLabel_search',
    SORT = 'userLabel_sort',
    PAGE = 'userLabel_page',
    DEFAULT_FILTER = 'userLabel_defaultFilter',
    FILTER = 'userLabel_filter',
    ACTIVE_FILTERS = 'userLabel_activeFilters',
}
