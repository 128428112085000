import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "block items-center space-y-2 md:flex" }
const _hoisted_2 = { class: "flex flex-1 items-center" }
const _hoisted_3 = { class: "flex items-center space-x-2 font-semibold text-gray-800" }
const _hoisted_4 = { class: "text-sm text-gray-700" }
const _hoisted_5 = { class: "flex flex-1 items-center" }
const _hoisted_6 = { class: "flex flex-wrap" }
const _hoisted_7 = {
  key: 0,
  class: "flex flex-1 items-center"
}
const _hoisted_8 = { class: "flex flex-wrap pr-3 md:pr-0" }
const _hoisted_9 = {
  key: 0,
  class: "text-xs text-gray-600 md:hidden"
}
const _hoisted_10 = {
  key: 1,
  class: "flex justify-end space-x-1"
}
const _hoisted_11 = { class: "sr-only" }
const _hoisted_12 = { class: "sr-only" }
const _hoisted_13 = { class: "sr-only" }
const _hoisted_14 = { class: "col-span-9 mt-3 grid grid-cols-1 gap-3 border-t pt-3 lg:grid-cols-4 lg:gap-0" }
const _hoisted_15 = { class: "flex flex-wrap items-center space-x-0.5 text-xs text-gray-500" }
const _hoisted_16 = {
  key: 0,
  class: "rounded border bg-white px-1.5 py-0.5 text-xs text-gray-500"
}
const _hoisted_17 = {
  key: 1,
  class: "rounded border bg-white px-1.5 py-0.5 text-xs text-gray-500"
}
const _hoisted_18 = {
  key: 2,
  class: "rounded border bg-white px-1.5 py-0.5 text-xs text-gray-500"
}
const _hoisted_19 = {
  key: 3,
  class: "rounded border bg-white px-1.5 py-0.5 text-xs text-gray-500"
}
const _hoisted_20 = { key: 4 }
const _hoisted_21 = { class: "flex flex-wrap items-center space-x-0.5 text-xs text-gray-500" }
const _hoisted_22 = {
  key: 0,
  class: "rounded border bg-white px-1.5 py-0.5 text-xs text-gray-500"
}
const _hoisted_23 = { key: 1 }
const _hoisted_24 = { class: "flex items-center justify-end text-xs text-gray-500" }
const _hoisted_25 = { class: "flex flex-wrap" }
const _hoisted_26 = { class: "flex items-center justify-end text-xs text-gray-500" }
const _hoisted_27 = { class: "flex flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiGravatar = _resolveComponent("UiGravatar")!
  const _component_UiIsActiveBadge = _resolveComponent("UiIsActiveBadge")!
  const _component_UiActionButton = _resolveComponent("UiActionButton")!
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock(_component_UiPanel, { class: "flex flex-col hover:bg-white" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_UiGravatar, {
            email: $props.modelValue.email,
            name: $props.modelValue.name,
            class: "mr-2 h-12 w-12 rounded-full ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50"
          }, null, 8 /* PROPS */, ["email", "name"]),
          _createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_3, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can($setup.Permission.VIEW_USER) ? 'RouterLink' : 'span'), {
                to: {
                                name: 'ViewUserById',
                                params: {
                                    id: $props.modelValue?.id,
                                },
                            },
                class: "text-primary"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString($setup.trimText($props.modelValue.name, 25)), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"])),
              _createVNode(_component_UiIsActiveBadge, {
                "model-value": $props.modelValue,
                class: "ml-2 text-xs"
              }, null, 8 /* PROPS */, ["model-value"])
            ]),
            _createElementVNode("span", _hoisted_4, _toDisplayString($props.modelValue.email), 1 /* TEXT */)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode($setup["LockClosedIcon"], {
            class: "mr-1 h-5 w-5 flex-shrink-0 text-gray-400",
            "aria-hidden": "true"
          }),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.roles, (role, index) => {
              return (_openBlock(), _createElementBlock("p", {
                key: `role${role.id}`,
                class: _normalizeClass([
                            index != $setup.roles.length - 1 ? 'mr-0.5' : '',
                            'text-xs font-medium text-gray-500',
                        ])
              }, _toDisplayString(role.name) + _toDisplayString(index != $setup.roles.length - 1 ? ',' : ''), 3 /* TEXT, CLASS */))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]),
        (_ctx.$can($setup.Permission.VIEW_LEVELS))
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode($setup["FolderIcon"], {
                class: "mr-1 h-5 w-5 flex-shrink-0 text-gray-400",
                "aria-hidden": "true"
              }),
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.levels, (level, index) => {
                  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can($setup.Permission.VIEW_LEVEL) ? 'RouterLink' : 'span'), {
                    key: level.id,
                    to: {
                            name: 'ViewLevelById',
                            params: {
                                id: level.id,
                            },
                        },
                    class: _normalizeClass([
                            index != $setup.levels.length - 1 ? 'mr-0.5' : '',
                            'text-primary hidden text-xs font-medium md:block',
                        ])
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(level.name) + _toDisplayString(index != $setup.levels.length - 1 ? ',' : ''), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "class"]))
                }), 128 /* KEYED_FRAGMENT */)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.limitedLevels, (level, index) => {
                  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can($setup.Permission.VIEW_LEVEL) ? 'RouterLink' : 'span'), {
                    key: level.id,
                    to: {
                            name: 'ViewLevelById',
                            params: {
                                id: level.id,
                            },
                        },
                    class: _normalizeClass([
                            index != $setup.limitedLevels.length - 1 ? 'mr-0.5' : '',
                            'text-primary text-xs font-medium md:hidden',
                        ])
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(level.name) + _toDisplayString(index != $setup.limitedLevels.length - 1 ? ',' : ''), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "class"]))
                }), 128 /* KEYED_FRAGMENT */)),
                ($setup.limitedLevels.length > 2)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_9, " ..." + _toDisplayString(_ctx.$t('core.actions.Edit')) + " " + _toDisplayString($setup.levels.length - 3), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ])
            ]))
          : _createCommentVNode("v-if", true),
        (_ctx.$can($setup.Permission.EDIT_USER))
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              ($props.modelValue?.isActive)
                ? _withDirectives((_openBlock(), _createBlock(_component_UiActionButton, {
                    key: 0,
                    variant: "danger",
                    onClick: $setup.handleInactivateClick
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('core.actions.Inactivate')), 1 /* TEXT */),
                      _createVNode($setup["XCircleIcon"], {
                        class: "h-6 w-6",
                        "aria-hidden": "true"
                      })
                    ]),
                    _: 1 /* STABLE */
                  })), [
                    [_directive_tippy, _ctx.$t('core.actions.Inactivate')]
                  ])
                : _withDirectives((_openBlock(), _createBlock(_component_UiActionButton, {
                    key: 1,
                    variant: "success",
                    onClick: $setup.handleActivateClick
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('core.actions.Activate')), 1 /* TEXT */),
                      _createVNode($setup["CheckCircleIcon"], {
                        class: "h-6 w-6",
                        "aria-hidden": "true"
                      })
                    ]),
                    _: 1 /* STABLE */
                  })), [
                    [_directive_tippy, _ctx.$t('core.actions.Activate')]
                  ]),
              _withDirectives((_openBlock(), _createBlock(_component_UiActionButton, {
                variant: "primary",
                onClick: $setup.handleEditClick
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('core.actions.Edit')), 1 /* TEXT */),
                  _createVNode($setup["PencilAltIcon"], {
                    class: "h-6 w-6",
                    "aria-hidden": "true"
                  })
                ]),
                _: 1 /* STABLE */
              })), [
                [_directive_tippy, _ctx.$t('core.actions.Edit')]
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('core.entity.Devices')) + ":", 1 /* TEXT */),
            ($props.modelValue.devices.mobile)
              ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.$t('user.labels.devices.mobile')), 1 /* TEXT */))
              : _createCommentVNode("v-if", true),
            ($props.modelValue.devices.desktop)
              ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.$t('user.labels.devices.desktop')), 1 /* TEXT */))
              : _createCommentVNode("v-if", true),
            ($props.modelValue.devices.web)
              ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.$t('user.labels.devices.web')), 1 /* TEXT */))
              : _createCommentVNode("v-if", true),
            ($props.modelValue.devices.external)
              ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.$t('user.labels.devices.external')), 1 /* TEXT */))
              : _createCommentVNode("v-if", true),
            ($setup.checkDevices($props.modelValue.devices))
              ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.$t('user.labels.devices.null')), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('number.labels.extension')) + ":", 1 /* TEXT */),
            ($props.modelValue.extension?.number)
              ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString($props.modelValue.extension?.number), 1 /* TEXT */))
              : (_openBlock(), _createElementBlock("span", _hoisted_23, "-"))
          ]),
          _createElementVNode("div", _hoisted_24, [
            _createVNode($setup["ClockIcon"], {
              class: "mr-0.5 h-4 w-4 flex-shrink-0 text-gray-400",
              "aria-hidden": "true"
            }),
            _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$t('user.labels.lastLoginDevice')) + ": " + _toDisplayString($props.modelValue.lastDeviceLogin
                                ? $setup.moment($props.modelValue.lastDeviceLogin).format('DD/MM/YYYY HH:mm:ss')
                                : '-'), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createVNode($setup["ClockIcon"], {
              class: "mr-0.5 h-4 w-4 flex-shrink-0 text-gray-400",
              "aria-hidden": "true"
            }),
            _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.$t('user.labels.lastLoginPortal')) + ": " + _toDisplayString($props.modelValue.lastLogin
                                ? $setup.moment($props.modelValue.lastLogin).format('DD/MM/YYYY HH:mm:ss')
                                : '-'), 1 /* TEXT */)
          ])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}