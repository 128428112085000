import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "space-y-4 p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-800" }
const _hoisted_3 = {
  key: 0,
  class: "mt-4 flex bg-gray-50 px-4 py-4 shadow ring-1 ring-gray-300 sm:pr-6 md:rounded"
}
const _hoisted_4 = { class: "text-center text-xs font-medium text-gray-500" }
const _hoisted_5 = { class: "mt-1 text-center text-2xl font-semibold text-gray-800" }
const _hoisted_6 = {
  key: 2,
  class: "mt-4 grid grid-cols-1 gap-4 lg:grid-cols-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiDatePicker = _resolveComponent("UiDatePicker")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!
  const _component_UiBadge = _resolveComponent("UiBadge")!
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _component_CallTypePerUserBarChart = _resolveComponent("CallTypePerUserBarChart")!
  const _component_CallStatusPerUserBarChart = _resolveComponent("CallStatusPerUserBarChart")!
  const _component_BarChart = _resolveComponent("BarChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('report.contactCenterPerAgent.title')), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createVNode($setup["ContactCenterPerAgentReportFilters"]),
        _createVNode(_component_UiDatePicker, {
          modelValue: $setup.dateRange,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.dateRange) = $event)),
          range: "",
          multiCalendars: "",
          class: "ml-4"
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }),
    ($setup.activeFilters.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.activeFilters, (filter) => {
            return (_openBlock(), _createBlock(_component_UiBadge, {
              key: filter,
              removable: "",
              onRemove: ($event: any) => ($setup.handleRemoveFilter(filter))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(`report.contactCenterPerAgent.filters.activeTag.${filter.field}`)) + ": " + _toDisplayString(filter.label), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onRemove"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.headerStats)
      ? (_openBlock(), _createElementBlock("dl", {
          key: 1,
          class: _normalizeClass(["mt-4 grid grid-cols-2 gap-4", ['sm:grid-cols-' + $setup.headerStats.length]])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.headerStats, (stat) => {
            return (_openBlock(), _createBlock(_component_UiPanel, {
              key: stat.name
            }, {
              default: _withCtx(() => [
                _createElementVNode("dt", _hoisted_4, _toDisplayString(_ctx.$t('report.contactCenterPerAgent.stats.' + stat.name)), 1 /* TEXT */),
                _createElementVNode("dd", _hoisted_5, _toDisplayString(stat.value), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */))
          }), 128 /* KEYED_FRAGMENT */))
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    ($setup.reportInfo)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_CallTypePerUserBarChart, {
            title: $setup.t('report.contactCenterPerAgent.callTypePerUserBarChart.title'),
            data: $setup.reportInfo.userAgentCallsStats,
            class: "col-span-2 h-[300px] md:col-span-1"
          }, null, 8 /* PROPS */, ["title", "data"]),
          _createVNode(_component_CallStatusPerUserBarChart, {
            title: $setup.t('report.contactCenterPerAgent.callStatusPerUserBarChart.title'),
            data: $setup.reportInfo.userAgentCallsStats,
            class: "col-span-2 h-[300px] md:col-span-1"
          }, null, 8 /* PROPS */, ["title", "data"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.charts, (chart, index) => {
            return (_openBlock(), _createBlock(_component_BarChart, {
              key: index,
              title: chart.title,
              categoryData: chart.category,
              seriesData: chart.series,
              xAxisLabelFormatter: chart.xAxisLabelFormatter,
              class: "col-span-2 h-[300px] md:col-span-1"
            }, null, 8 /* PROPS */, ["title", "categoryData", "seriesData", "xAxisLabelFormatter"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}