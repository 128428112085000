<script lang="ts" setup>
import { onMounted, ref, Ref, computed, WritableComputedRef, PropType } from 'vue';
import {
    ConnectFlowModelingActions,
    ConnectFlowModelingGetters,
    ConnectFlowModelingMutations,
    VueFlowNodeModel,
    MultiSelectOption,
    ConnectFlowSurveyModel,
} from '@/types';

import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { AnnotationIcon } from '@heroicons/vue/outline';
import { required } from '@/utils/validators';
import { useI18n } from 'vue-i18n';
import store from '@/store';

const { t } = useI18n();

const props = defineProps({
    node: {
        type: Object as PropType<VueFlowNodeModel>,
        required: true,
    },
});

const showForm = ref(false);

const nodeWritable: WritableComputedRef<VueFlowNodeModel> = computed({
    get: () => store.getters['flow/' + ConnectFlowModelingGetters.ELEMENT_BY_ID](props.node.id),
    set: (value: VueFlowNodeModel) => {
        store.commit('flow/' + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
            idx: null,
            value,
        });
    },
});

const surveyOptions = computed(
    () => store.getters['flow/' + ConnectFlowModelingGetters.SURVEY_OPTIONS],
);

interface ITransferFormState {
    survey: null | ConnectFlowSurveyModel;
}

const formState: Ref<ITransferFormState> = ref({
    survey: null,
});

const rules = computed<ValidationArgs<ITransferFormState>>(() => {
    const r: ValidationArgs<ITransferFormState> = {
        survey: {
            required,
        },
    };
    return r;
});
const v$: Ref<Validation<ValidationArgs<ITransferFormState>>> = useVuelidate(rules, formState);

const nodeText = computed(() => {
    let text = t('connectFlow.node.transferToSurvey.empty');
    if (nodeWritable.value.data.related) {
        text = nodeWritable.value.data.related.label;
    }
    return text;
});

const isFormCorrect = ref(true);

function removeNode() {
    store.dispatch('flow/' + ConnectFlowModelingActions.DELETE_NODE, {
        nodeId: nodeWritable.value.id,
        parentId: nodeWritable.value.parentId,
    });
}
async function onSubmit() {
    isFormCorrect.value = await v$.value.$validate();
    if (isFormCorrect.value) {
        nodeWritable.value.data.related = {
            value: formState.value.survey?.id,
        } as MultiSelectOption;
        showForm.value = false;
    }
    nodeWritable.value.data.hasErrors = !isFormCorrect.value;
    v$.value.$reset();
}

onMounted(() => {
    if (nodeWritable.value.data.related !== null) {
        formState.value.survey = surveyOptions.value.find(
            (survey: ConnectFlowSurveyModel) => survey.id == nodeWritable.value.data.related?.value,
        );
    }

    v$.value.$validate().then((result) => {
        isFormCorrect.value = result;
        nodeWritable.value.data.hasErrors = !isFormCorrect.value;
    });
});
</script>

<template>
    <UiNode
        :nodeId="node.id"
        :title="$t('connectFlow.node.transferToSurvey.title')"
        :icon="AnnotationIcon"
        showActions
        @editNode="showForm = true"
        @removeNode="removeNode"
        :class="isFormCorrect ? '' : 'border-red-300 shadow-red-300'"
    >
        <div class="font-medium text-gray-600">
            <span>
                {{ nodeText }}
            </span>
        </div>
    </UiNode>
    <UiRightPanel
        v-model="showForm"
        :title="$t('connectFlow.node.transferToSurvey.title')"
        :icon="AnnotationIcon"
    >
        <form autocomplete="off" class="flex flex-col gap-3" @submit.prevent="onSubmit">
            <div class="sm:col-span-12">
                <label class="mb-2 block text-sm font-medium text-gray-800">
                    {{ $t('connectFlow.node.transferToSurvey.select') }}
                </label>
                <UiMultiselect
                    name="survey"
                    label="name"
                    value-prop="id"
                    v-model="v$.survey.$model"
                    :options="surveyOptions"
                    :errors="v$.survey.$silentErrors"
                    can-clear
                />
            </div>
            <div class="mt-6 grid grid-cols-2 place-content-around gap-4 text-center">
                <UiButton variant="white" text-variant="gray-700" @click="showForm = false">
                    {{ $t('core.actions.Cancel') }}
                </UiButton>
                <UiButton type="submit" variant="primary" text-variant="white">
                    {{ $t('core.actions.Apply') }}
                </UiButton>
            </div>
        </form>
    </UiRightPanel>
</template>
