import { defineComponent as _defineComponent } from 'vue'
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { ECBasicOption } from 'echarts/types/dist/shared';
import { PieChart, GaugeChart, BarChart, HeatmapChart } from 'echarts/charts';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    VisualMapComponent,
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';
import { PropType, provide } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiChart',
  props: {
    option: {
        type: Object as PropType<ECBasicOption>,
    },
    autoresize: {
        type: Boolean,
        default: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();

use([
    CanvasRenderer,
    PieChart,
    BarChart,
    GaugeChart,
    HeatmapChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    VisualMapComponent,
]);

provide(THEME_KEY, 'light');

const props = __props;

const __returned__ = { props, get VChart() { return VChart } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})