import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, Fragment as _Fragment, createElementVNode as _createElementVNode, renderSlot as _renderSlot, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center" }
const _hoisted_2 = { class: "relative flex w-full justify-end gap-2" }
const _hoisted_3 = {
  key: 0,
  class: "absolute -left-[45px] -top-[45px] z-50 rounded-full border border-gray-300 bg-emerald-50 p-1 shadow"
}
const _hoisted_4 = {
  key: 1,
  class: "text-md flex-1 font-medium text-gray-800"
}
const _hoisted_5 = { class: "text-sm" }
const _hoisted_6 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_7 = { class: "rounded bg-gray-50 px-1.5 py-0.5 text-xs ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiActionButton = _resolveComponent("UiActionButton")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _mergeProps(_ctx.$attrs, {
      class: [
                $props.bgColor ? $props.bgColor : 'bg-gray-50',
                $props.borderColor ? $props.borderColor : 'border-gray-300',
                $props.roundedStyle ? $props.roundedStyle : 'rounded',
                $props.padding ? $props.padding : 'px-4 py-4',
                $props.nodeWidth ? $props.nodeWidth : 'w-[350px]',
                $props.nodeHeight ? $props.nodeHeight : 'h-auto',
                'relative border-2 shadow',
            ]
    }), [
      _createElementVNode("div", _hoisted_2, [
        ($props.icon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(), _createBlock(_resolveDynamicComponent($props.icon), {
                class: "text-secondary h-9 w-9",
                "aria-hidden": "true"
              }))
            ]))
          : _createCommentVNode("v-if", true),
        ($props.title)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($props.title), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        ($props.showActions)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              (!$props.noEdition)
                ? _withDirectives((_openBlock(), _createBlock(_component_UiActionButton, {
                    key: 0,
                    variant: "primary",
                    onClick: $setup.handleEditClick
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["PencilAltIcon"], { class: "h-5 w-5" })
                    ]),
                    _: 1 /* STABLE */
                  })), [
                    [_directive_tippy, _ctx.$t('core.actions.Edit')]
                  ])
                : _createCommentVNode("v-if", true),
              _withDirectives((_openBlock(), _createBlock(_component_UiActionButton, {
                variant: "danger",
                onClick: $setup.handleRemoveClick
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["TrashIcon"], { class: "h-5 w-5" })
                ]),
                _: 1 /* STABLE */
              })), [
                [_directive_tippy, _ctx.$t('core.actions.Remove')]
              ])
            ], 64 /* STABLE_FRAGMENT */))
          : _createCommentVNode("v-if", true)
      ]),
      ($props.showHandleTarget)
        ? (_openBlock(), _createBlock($setup["Handle"], {
            key: 0,
            type: "target",
            position: $setup.Position.Top
          }, null, 8 /* PROPS */, ["position"]))
        : _createCommentVNode("v-if", true),
      ($props.showHandleSource)
        ? (_openBlock(), _createBlock($setup["Handle"], {
            key: 1,
            type: "source",
            position: $setup.Position.Bottom
          }, null, 8 /* PROPS */, ["position"]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "default"),
        (!$props.hideNodeId)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, " #" + _toDisplayString($setup.node.id), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ], 16 /* FULL_PROPS */),
    _createVNode($setup["NodeToolbar"], {
      class: "flex items-center gap-0.5",
      "is-visible": $setup.node?.connectable as boolean,
      position: $setup.Position.Bottom
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          class: "border-1 hover:shadow-blueGray-600 rounded-full bg-gray-50 p-1 shadow",
          onClick: $setup.openComponentSelector
        }, [
          _createVNode($setup["PlusIcon"], { class: "h-5 w-5" })
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["is-visible", "position"])
  ]))
}