/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    FilterItem,
    AgentProductivityReportState,
    AgentProductivityReportMutations,
    AgentProductivityReportActions,
    AgentProductivityReportGetters,
    AgentProductivityReportFilter,
} from '@/types';

const getDefaultState = () => {
    return {
        filter: {
            user: null,
        },
    } as AgentProductivityReportState;
};

const state: AgentProductivityReportState = getDefaultState();

const mutations: MutationTree<AgentProductivityReportState> = {
    [AgentProductivityReportMutations.SET_FILTER](state, payload: AgentProductivityReportFilter) {
        state.filter.user = payload.user;
        state.filter.userLabel = payload.userLabel;
    },
    [AgentProductivityReportMutations.CLEAR_FILTER](state, field?: string) {
        switch (field) {
            case 'user':
                state.filter.user = getDefaultState().filter.user;
                break;
            case 'userLabel':
                state.filter.userLabel = getDefaultState().filter.userLabel;
                break;
            default:
                state.filter.user = getDefaultState().filter.user;
                state.filter.userLabel = getDefaultState().filter.userLabel;
        }
    },
};

const actions: ActionTree<AgentProductivityReportState, any> = {
    [AgentProductivityReportActions.SET_FILTER]: (
        { commit },
        filter: AgentProductivityReportFilter,
    ) => commit(AgentProductivityReportMutations.SET_FILTER, filter),
    [AgentProductivityReportActions.CLEAR_FILTER]: (
        { commit },
        filter: AgentProductivityReportFilter,
    ) => commit(AgentProductivityReportMutations.CLEAR_FILTER, filter),
};

const getters: GetterTree<AgentProductivityReportState, any> = {
    [AgentProductivityReportGetters.DEFAULT_FILTER]: (): AgentProductivityReportFilter =>
        getDefaultState().filter,
    [AgentProductivityReportGetters.FILTER]: (state): AgentProductivityReportFilter => state.filter,
    [AgentProductivityReportGetters.ACTIVE_FILTERS](state): FilterItem[] {
        const filters: Array<FilterItem> = [];

        if (state.filter.user)
            filters.push({
                field: 'user',
                value: state.filter.user.id,
                label: state.filter.user.name,
            });

        if (state.filter.userLabel)
            filters.push({
                field: 'userLabel',
                value: state.filter.userLabel.id,
                label: state.filter.userLabel.label,
            });

        return filters;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
