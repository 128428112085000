/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    SpamNumberMutations,
    SpamNumberActions,
    SpamNumberGetters,
    SortMenuItem,
    ListFilters,
} from '@/types';

const getDefaultState = () => {
    return {
        search: null,
        sort: [],
        page: 1,
    } as ListFilters;
};

const state: ListFilters = getDefaultState();

const mutations: MutationTree<ListFilters> = {
    [SpamNumberMutations.SET_SEARCH]: (state, search: string) => (state.search = search),
    [SpamNumberMutations.SET_SORT]: (state, sort: SortMenuItem[]) => (state.sort = sort),
    [SpamNumberMutations.SET_PAGE]: (state, page: number) => (state.page = page),
};

const actions: ActionTree<ListFilters, any> = {
    [SpamNumberActions.SET_SEARCH]: ({ commit }, search: string) =>
        commit(SpamNumberMutations.SET_SEARCH, search),
    [SpamNumberActions.SET_SORT]: ({ commit }, sort: SortMenuItem[]) =>
        commit(SpamNumberMutations.SET_SORT, sort),
    [SpamNumberActions.SET_PAGE]: ({ commit }, page: number) =>
        commit(SpamNumberMutations.SET_PAGE, page),
};

const getters: GetterTree<ListFilters, any> = {
    [SpamNumberGetters.SEARCH]: (state): string | null => state.search,
    [SpamNumberGetters.SORT]: (state): SortMenuItem[] => state.sort,
    [SpamNumberGetters.PAGE]: (state): number => state.page,
};

export default {
    state,
    mutations,
    actions,
    getters,
};
