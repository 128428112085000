import { RouteRecordRaw } from 'vue-router';
import { validateIdAsPositiveIntegerGuard } from '@/router/routeGuards';
import WordPage from '@/views/pages/word/WordPage.vue';
import WordDetailPage from '@/views/pages/word/WordDetailPage.vue';
import WordFormPage from '@/views/pages/word/WordFormPage.vue';
import { FormAction } from '@/types';

const wordRouter: RouteRecordRaw = {
    name: 'Word',
    path: 'word',
    children: [
        {
            name: 'ListWord',
            path: '',
            component: WordPage,
        },
        {
            name: 'ViewWordById',
            path: ':id',
            component: WordDetailPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            meta: { isLevelLocked: true },
        },
        {
            name: 'EditWordById',
            path: ':id/edit',
            component: WordFormPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            props: { action: FormAction.EDIT },
            meta: { isLevelLocked: true },
        },
        {
            name: 'CreateWord',
            path: 'create',
            component: WordFormPage,
            props: { action: FormAction.CREATE },
            meta: { isLevelLocked: true },
        },
    ],
};

export default wordRouter;
