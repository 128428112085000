<script lang="ts" setup>
import { Ref, ref, computed, watch } from 'vue';

import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { minLength, required } from '@/utils/validators';
import { TrashIcon } from '@heroicons/vue/outline';

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },
    removable: {
        type: Boolean,
        default: false,
    },
});

interface IExternalFormState {
    formState: string;
}

const number: Ref<string> = ref<string>(props.modelValue);

const rules = computed<ValidationArgs<IExternalFormState>>(() => {
    const r: ValidationArgs<IExternalFormState> = {
        formState: {
            required,
            minLength: minLength(13),
        },
    };
    return r;
});
const v$: Ref<Validation<ValidationArgs<IExternalFormState>>> = useVuelidate(rules, {
    formState: number,
});
const emit = defineEmits(['update:modelValue', 'remove']);

watch(number, () => {
    emit('update:modelValue', number.value);
});
</script>

<template>
    <div class="flex w-full items-end gap-1.5">
        <UiTextInput
            name="extension"
            type="text"
            :label="$t('connectFlow.node.transferToExternal.number')"
            :class="props.removable ? 'w-11/12' : 'w-full'"
            v-model.lazy="number"
            :errors="v$.formState.$silentErrors"
            is-phone-number
        />

        <UiButton
            v-if="props.removable"
            class="py-4 text-lg"
            variant="white"
            size="md"
            @click="emit('remove')"
        >
            <TrashIcon class="h-4 w-4 text-gray-500" />
        </UiButton>
    </div>
</template>
