import { RouteRecordRaw } from 'vue-router';
import { validateIdAsPositiveIntegerGuard } from '@/router/routeGuards';
import ConnectFlowPage from '@/views/pages/connect-flow/ConnectFlowPage.vue';
import ConnectFlowDetailPage from '@/views/pages/connect-flow/ConnectFlowDetailPage.vue';
import ConnectFlowFormPage from '@/views/pages/connect-flow/ConnectFlowFormPage.vue';
import ConnectFlowFlowPage from '@/views/pages/connect-flow/ConnectFlowModelingPage.vue';
import ConnectFlowHistoryPage from '@/views/pages/connect-flow/ConnectFlowHistoryPage.vue';
import { FormAction } from '@/types';

const connectFlowRouter: RouteRecordRaw = {
    name: 'ConnectFlow',
    path: 'connect-flow',
    children: [
        {
            name: 'ListConnectFlow',
            path: '',
            component: ConnectFlowPage,
        },
        {
            name: 'ViewConnectFlowById',
            path: ':id',
            component: ConnectFlowDetailPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            meta: { isLevelLocked: true },
        },
        {
            name: 'EditConnectFlowById',
            path: ':id/edit',
            component: ConnectFlowFormPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            props: { action: FormAction.EDIT },
            meta: { isLevelLocked: true },
        },
        {
            name: 'CreateConnectFlow',
            path: 'create',
            component: ConnectFlowFormPage,
            props: { action: FormAction.CREATE },
            meta: { isLevelLocked: true },
        },
        {
            name: 'EditFlowById',
            path: ':id/flow',
            component: ConnectFlowFlowPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            meta: { isLevelLocked: true },
        },
        {
            name: 'EditFlowHistoryById',
            path: ':id/flow/:historyId/history',
            component: ConnectFlowFlowPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            meta: { isLevelLocked: true },
        },
        {
            name: 'ViewConnectFlowHistoryById',
            path: ':id/history',
            component: ConnectFlowHistoryPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            meta: { isLevelLocked: true },
        },
    ],
};

export default connectFlowRouter;
