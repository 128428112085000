<script lang="ts" setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AxiosResponse } from 'axios';

import { useUiStore } from '@/stores';
import ConnectFlowService from '@/services/api/ConnectFlowService';
import { toastServiceError } from '@/services/core/notification';

import { ConnectFlowModel, ConnectFlowFlowModel } from '@/types';

import { ConnectFlowHistoryItem } from '@/views/pages/connect-flow/components';
import ConnectFlowIcon from '@/components/svg/ConnectFlowIcon';

const route = useRoute();
const uiStore = useUiStore();

const connectFlow = ref<ConnectFlowModel | undefined>(undefined);
const currentPage = ref(1);
const perPage = 10;

const flows = computed<ConnectFlowFlowModel[]>(() => {
    return (connectFlow.value?.flows || []).slice().sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
});

const count = computed<number>(() => flows.value.length);

const paginatedFlows = computed<ConnectFlowFlowModel[]>(() => {
    const start = (currentPage.value - 1) * perPage;
    return flows.value.slice(start, start + perPage);
});

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchConnectFlow = async (id: number) => {
    await setLoading(true);
    try {
        const response: AxiosResponse<ConnectFlowModel> =
            await ConnectFlowService.get<ConnectFlowModel>(id);
        connectFlow.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

watch(flows, () => {
    currentPage.value = 1;
});

onMounted(() => {
    const id = Number(route.params.id);
    fetchConnectFlow(id);
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="flex items-center text-xl font-semibold text-gray-800">
                    <ConnectFlowIcon class="mr-1 h-6 w-6 text-gray-500" aria-hidden="true" />
                    {{ $t('connectFlow.history.title') }}
                </h1>
            </template>
            <template #actions>
                <UiBackButton :fallbackTo="{ name: 'ListConnectFlow' }" />
            </template>
        </UiPageHeader>

        <div v-if="connectFlow" class="space-y-2">
            <div v-if="paginatedFlows?.length" class="space-y-2">
                <ConnectFlowHistoryItem
                    v-for="flow in paginatedFlows"
                    :key="flow.id"
                    :model-value="flow"
                    :published="flow.id === connectFlow?.lastAppliedFlowId"
                />
            </div>
            <UiPanel v-else class="flex items-center justify-center">
                {{ $t('core.messages.noRecordsFound') }}
            </UiPanel>

            <UiPagination
                v-if="flows?.length"
                v-model="currentPage"
                :count="count"
                :perPage="perPage"
            />
        </div>
    </div>
</template>
