import { defineComponent as _defineComponent } from 'vue'
import md5 from 'md5';
import { computed, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiGravatar',
  props: {
    email: {
        type: String,
        required: true,
    },
    name: {
        type: String,
        required: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const gravatarUrl = computed<string>(() => {
    const hash = md5(props.email.trim().toLowerCase());
    return `https://www.gravatar.com/avatar/${hash}?d=404`;
});

const initials = computed<string>(() => getInitials(props.name));

const showImage = ref(true);

function getInitials(name: string) {
    const names = name.split(' ');
    if (names.length >= 2) {
        const firstInitial = names[0].charAt(0).toUpperCase();
        const lastInitial = names[names.length - 1].charAt(0).toUpperCase();
        return `${firstInitial}${lastInitial}`;
    } else if (names.length === 1) {
        return names[0].charAt(0).toUpperCase();
    }
    return '';
}

function handleImageError() {
    showImage.value = false;
}

const __returned__ = { props, gravatarUrl, initials, showImage, getInitials, handleImageError }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})