import {
    SelectOptionsServiceGetUsersResult,
    SelectOptionsServiceGetUserLabelsResult,
    SelectOptionsServiceGetWordsResult,
} from '@/types';
import BaseService from '@/services/api/BaseService';
import { AxiosRequestConfig } from 'axios';

class SelectOptionsService extends BaseService<SelectOptionsServiceGetUsersResult> {
    getUsers(config?: AxiosRequestConfig) {
        return this.http.get<SelectOptionsServiceGetUsersResult[]>(`${this.route}/users`, config);
    }

    getUserLabels(config?: AxiosRequestConfig) {
        return this.http.get<SelectOptionsServiceGetUserLabelsResult[]>(
            `${this.route}/users-labels`,
            config,
        );
    }

    getWords(config?: AxiosRequestConfig) {
        return this.http.get<SelectOptionsServiceGetWordsResult[]>(`${this.route}/words`, config);
    }
}

export default new SelectOptionsService('select-options');
