<script lang="ts" setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ArrowCircleDownIcon, ArrowCircleUpIcon, SwitchVerticalIcon } from '@heroicons/vue/solid';
import { SortMenuItem, SortMenuItemDirection } from '@/types';

const props = defineProps({
    modelValue: {
        type: Array<SortMenuItem>,
        default: [],
    },
    label: {
        type: String,
    },
    items: {
        type: Array<SortMenuItem>,
        required: true,
        default: [],
    },
});

const emit = defineEmits(['update:modelValue']);

const handleItemClick = (item: SortMenuItem, direction?: SortMenuItemDirection) => {
    const currentItems = [...props.modelValue];
    const currentItem = currentItems.find((cItem) => cItem.key == item.key);

    if (currentItem) {
        if (currentItem.direction === direction) {
            currentItems.splice(currentItems.indexOf(currentItem), 1);
        } else if (direction) {
            currentItem.direction = direction;
        } else {
            currentItem.direction =
                currentItem.direction === SortMenuItemDirection.DESC
                    ? SortMenuItemDirection.ASC
                    : SortMenuItemDirection.DESC;
        }
    } else {
        currentItems.push({ ...item, direction: direction || SortMenuItemDirection.ASC });
    }

    emit('update:modelValue', currentItems);
};
</script>

<template>
    <Menu as="div" class="relative">
        <MenuButton
            class="inline-flex rounded bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow ring-1 ring-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-opacity-50"
        >
            <SwitchVerticalIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            <span class="ml-2 hidden sm:block">{{ props.label || $t('core.actions.Sort') }}</span>
        </MenuButton>
        <MenuItems
            class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded bg-white shadow ring-1 ring-gray-300 focus:outline-none"
        >
            <div class="py-1">
                <MenuItem v-slot="{ active }" v-for="(item, index) in items" :key="index">
                    <div
                        :class="[
                            active ? 'bg-gray-100 text-gray-800' : 'text-gray-700',
                            'group flex items-center px-4 py-2 text-sm',
                        ]"
                        @click.prevent
                    >
                        <span class="flex-1">{{ item.label }}</span>
                        <ArrowCircleDownIcon
                            v-tippy="$t('sort.ascending')"
                            :class="[
                                props.modelValue.find(
                                    (m) =>
                                        m.key == item.key &&
                                        m.direction == SortMenuItemDirection.ASC,
                                )
                                    ? 'text-primary hover:text-gray-300'
                                    : 'hover:text-primary text-gray-400',
                            ]"
                            class="h-6 w-6 cursor-pointer"
                            aria-hidden="true"
                            @click="handleItemClick(item, SortMenuItemDirection.ASC)"
                        />
                        <ArrowCircleUpIcon
                            v-tippy="$t('sort.descending')"
                            :class="[
                                props.modelValue.find(
                                    (m) =>
                                        m.key == item.key &&
                                        m.direction == SortMenuItemDirection.DESC,
                                )
                                    ? 'text-primary hover:text-gray-300'
                                    : 'hover:text-primary text-gray-400',
                            ]"
                            class="h-6 w-6 cursor-pointer"
                            aria-hidden="true"
                            @click="handleItemClick(item, SortMenuItemDirection.DESC)"
                        />
                    </div>
                </MenuItem>
            </div>
        </MenuItems>
    </Menu>
</template>
