import { defineComponent as _defineComponent } from 'vue'
import { computed, onMounted, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useVuelidate, ValidationArgs } from '@vuelidate/core';

import store from '@/store';
import { useUiStore } from '@/stores';
import { UserService } from '@/services';
import { toastServiceError } from '@/services/core/notification';

import {
    required,
    maxLength,
    minLength,
    containsUppercase,
    containsLowercase,
    containsNumber,
    containsSpecial,
    sameAs,
} from '@/utils/validators';

import { UpdatePasswordFormState, UserModel, AuthGetters } from '@/types';

const imageLoginPath = '/img/slogan-login.png';
const logoPath = '/img/logomob.png';


export default /*@__PURE__*/_defineComponent({
  __name: 'UpdatePasswordPage',
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const uiStore = useUiStore();

const formState = reactive<UpdatePasswordFormState>({
    password: '',
    confirmPassword: '',
});

const token = computed(() => route.query.token as string | undefined);

const rules = computed<ValidationArgs<UpdatePasswordFormState>>(() => ({
    password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(250),
        containsUppercase,
        containsLowercase,
        containsNumber,
        containsSpecial,
    },
    confirmPassword: {
        required,
        sameAsPassword: sameAs(formState.password, t('user.labels.password')),
    },
}));

const v$ = useVuelidate(rules, formState);

const user = computed<UserModel>(() => store.getters[AuthGetters.USER]);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const handlePasswordUpdate = async () => {
    const isFormValid = await v$.value.$validate();
    if (!isFormValid) return;

    try {
        await setLoading(true);

        if (token.value) {
            await UserService.resetPassword({
                token: token.value,
                password: formState.password,
            });
        } else {
            await UserService.firstLogin(formState);
        }

        await router.push('/');
    } catch (error) {
        toastServiceError(error);
        if (token.value) {
            await router.push({ name: 'Login' });
        }
    } finally {
        await setLoading(false);
    }
};

onMounted(() => {
    if (user.value?.lastLogin) {
        router.push({ name: 'Home' });
    }
});

const __returned__ = { imageLoginPath, logoPath, t, route, router, uiStore, formState, token, rules, v$, user, setLoading, handlePasswordUpdate }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})