<script lang="ts" setup>
import { computed } from 'vue';

import store from '@/store';
import router from '@/router';
import { useUiStore } from '@/stores';

import { HomeIcon, LogoutIcon } from '@heroicons/vue/outline';
import BaseTemplate from '@/views/templates/BaseTemplate.vue';
import { NavigationGetters } from '@/types';

const uiStore = useUiStore();

const sideNavigationItems = computed(
    () => store.getters[NavigationGetters.SETTINGS_SIDE_NAVIGATION_ITEMS],
);

const showLogout = computed(() => location.host !== 'embed.mobcall.com');

const handleLocaleChange = (event: Event) => {
    const selectedLocale = (event.target as HTMLInputElement).value;
    uiStore.setLocale(selectedLocale);
};

const handleHomeClick = () => {
    router.push('/');
};

const handleLogoutClick = () => {
    router.push('/logout');
};
</script>

<template>
    <BaseTemplate :navigation-items="sideNavigationItems" :router-key="$route.fullPath">
        <template #headerActions>
            <select
                v-if="false"
                v-model="$i18n.locale"
                class="border-none bg-inherit ring-0 focus:border-none focus:outline-none focus:ring-0"
                @change="handleLocaleChange"
            >
                <option
                    v-for="locale in $i18n.availableLocales"
                    :key="`locale-${locale}`"
                    :value="locale"
                >
                    {{ locale }}
                </option>
            </select>
            <button class="mr-2 hover:text-white" @click="handleHomeClick">
                <HomeIcon class="h-6 w-6" aria-hidden="true" />
            </button>
            <button v-if="showLogout" class="hover:text-white" @click="handleLogoutClick">
                <LogoutIcon class="h-6 w-6" aria-hidden="true" />
            </button>
        </template>
    </BaseTemplate>
</template>
