import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, reactive, onMounted, Ref, ComponentPublicInstance } from 'vue';
import { useRoute } from 'vue-router';
import { AxiosResponse } from 'axios';

import { useUiStore } from '@/stores';
import { useVuelidateWithLogging } from '@/composables/useVuelidateWithLogging';
import { goBack } from '@/composables/useNavigation';
import { ContactService } from '@/services';
import { toast, toastServiceError } from '@/services/core/notification';
import { required, maxLength } from '@/utils/validators';

import { Permission, FormAction, ContactModel, ContactFormState } from '@/types';

import { ContactEmailForm, ContactPhoneForm } from '@/views/pages/contact/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactFormPage',
  props: {
    action: { type: String, default: FormAction.CREATE },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const route = useRoute();
const uiStore = useUiStore();

const emailRefs: Ref<InstanceType<typeof ContactEmailForm>[]> = ref([]);
const phoneRefs: Ref<InstanceType<typeof ContactPhoneForm>[]> = ref([]);

const formState = reactive<ContactFormState>({
    name: null,
    emails: [],
    phones: [],
});

const rules = computed(() => ({
    name: { required, maxLength: maxLength(255) },
    emails: {},
    phones: {},
}));

const { v$, $validateWithLogging } = useVuelidateWithLogging(rules, formState);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchContact = async (id: number) => {
    try {
        await setLoading(true);
        const { data } = await ContactService.get<ContactModel>(id);
        formState.name = data.name;
        formState.emails = data.emails;
        formState.phones = data.phones;
    } catch (e) {
        toastServiceError(e);
    } finally {
        await setLoading(false);
    }
};

const setEmailRef = (el: Element | ComponentPublicInstance | null, index: number) => {
    if (el && '$el' in el) {
        emailRefs.value[index] = el as InstanceType<typeof ContactEmailForm>;
    } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        emailRefs.value[index] = null as any;
    }
};

const setPhoneRef = (el: Element | ComponentPublicInstance | null, index: number) => {
    if (el && '$el' in el) {
        phoneRefs.value[index] = el as InstanceType<typeof ContactPhoneForm>;
    } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        phoneRefs.value[index] = null as any;
    }
};

const addEmail = () => {
    formState.emails.push({ email: null });
};

const addPhone = () => {
    formState.phones.push({ number: null });
};

const removeEmail = (index: number) => {
    formState.emails.splice(index, 1);
};

const removePhone = (index: number) => {
    formState.phones.splice(index, 1);
};

const onSubmit = async () => {
    const emailValidationResults = await Promise.all(
        emailRefs.value.map((ref) => !ref || ref?.validate?.()),
    );

    const phoneValidationResults = await Promise.all(
        phoneRefs.value.map((ref) => !ref || ref?.validate?.()),
    );

    const allValid = [...emailValidationResults, ...phoneValidationResults].every(Boolean);
    const isFormValid = await $validateWithLogging();

    if (!isFormValid || !allValid) return;

    await setLoading(true);

    const handleServiceSuccess = (message: string, id: number) => {
        toast.success(message);
        v$.value.$reset();
        goBack({ name: 'ViewContactById', params: { id } });
    };

    try {
        if (props.action === FormAction.EDIT) {
            const id = Number(route.params.id);
            const { data }: AxiosResponse = await ContactService.update(id, formState);
            handleServiceSuccess(data.message, id);
        } else {
            const { data }: AxiosResponse = await ContactService.create(formState);
            handleServiceSuccess(data.message, data.result.id);
        }
    } catch (e) {
        toastServiceError(e);
    } finally {
        await setLoading(false);
    }
};

onMounted(async () => {
    if (props.action === FormAction.EDIT) {
        const contactId = Number(route.params.id);
        if (!isNaN(contactId)) fetchContact(contactId);
    }
});

const __returned__ = { props, route, uiStore, emailRefs, phoneRefs, formState, rules, v$, $validateWithLogging, setLoading, fetchContact, setEmailRef, setPhoneRef, addEmail, addPhone, removeEmail, removePhone, onSubmit, get Permission() { return Permission }, get FormAction() { return FormAction }, get ContactEmailForm() { return ContactEmailForm }, get ContactPhoneForm() { return ContactPhoneForm } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})