import { defineComponent as _defineComponent } from 'vue'
import { computed, ref, Ref, watch, ComputedRef, onMounted } from 'vue';
import moment from 'moment';
import orderBy from 'lodash.orderby';
import { useI18n } from 'vue-i18n';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import { AgentProductivityPerAgentReportService } from '@/services';

import {
    AgentProductivityReportActions,
    AgentProductivityReportGetters,
    CallType,
    AgentProductivityReportFilter,
    AgentProductivityReportServiceGetInfoParams,
    AgentProductivityReportServiceGetInfoResult,
    CallsStats,
    ChartDataItem,
    DataItem,
    FilterItem,
    LevelModel,
    UserAgentCallsStats,
} from '@/types';

import { BarChartConfig } from '@/types/configs/ChartConfig';
import { AgentProductivityPerAgentReportFilters } from '@/views/pages/reports/agent-productivity-per-agent/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'AgentProductivityPerAgentReportPage',
  setup(__props, { expose: __expose }) {
  __expose();

const reportInfo: Ref<AgentProductivityReportServiceGetInfoResult | null> = ref(null);

const levelFilter = computed<LevelModel | null>(() => filterStore.level);
const dateRange = computed<Array<Date>>({
    get: () => filterStore.dateRange,
    set: (dateRange: Array<Date>) => filterStore.setDateRange(dateRange),
});
const filter = computed<AgentProductivityReportFilter>(
    () => store.getters[AgentProductivityReportGetters.FILTER],
);
const activeFilters: ComputedRef<FilterItem[]> = computed(
    () => store.getters[AgentProductivityReportGetters.ACTIVE_FILTERS],
);

const { t } = useI18n();
const filterStore = useFilterStore();
const uiStore = useUiStore();

const headerStats = computed<DataItem<string>[]>(() => {
    const callsStats: CallsStats | undefined = reportInfo.value?.stats;
    if (!callsStats) return [];

    return [
        { name: 'totalCalls', value: callsStats.total.toString() },
        { name: 'inboundCalls', value: callsStats.type[CallType.INBOUND].toString() },
        { name: 'outboundCalls', value: callsStats.type[CallType.OUTBOUND].toString() },
        { name: 'internalCalls', value: callsStats.type[CallType.INTERNAL].toString() },
        { name: 'uniqueNumbers', value: callsStats.uniqueNumbers.toString() },
        {
            name: 'averageServiceTime',
            value: moment.utc((callsStats.averageServiceTime || 0) * 1000).format('HH:mm:ss'),
        },
    ];
});

const xAxisLabelFormatterAsTime = (value: number | string) => {
    return moment.utc((value as number) * 1000).format('HH:mm:ss');
};

const getChart = (
    title: string,
    prop: keyof UserAgentCallsStats,
    color = '#BFDBFE',
    xAxisLabelFormatter: (value: number | string) => string,
): BarChartConfig => {
    const topUsers = orderBy(reportInfo.value?.userAgentCallsStats, prop, 'desc').slice(0, 10);

    const category: string[] = [];
    const serie: ChartDataItem<number[]> = {
        name: title,
        value: [],
        color,
    };

    for (let i = topUsers.length - 1; i >= 0; i--) {
        const user = topUsers[i];
        category.push(user.name);
        serie.value.push(user[prop] as number);
    }

    return {
        title,
        category,
        series: [serie],
        xAxisLabelFormatter,
    };
};

const charts = computed<BarChartConfig[]>(() => {
    return [
        getChart(
            t('report.agentProductivityPerAgent.serviceTimePerUserBarChart.title'),
            'averageServiceTime',
            '#BFDBFE',
            xAxisLabelFormatterAsTime,
        ),
        getChart(
            t('report.agentProductivityPerAgent.timeAvailablePerUserBarChart.title'),
            'averageTimeAvailable',
            '#FBCFE8',
            xAxisLabelFormatterAsTime,
        ),
        getChart(
            t('report.agentProductivityPerAgent.durationPerUserBarChart.title'),
            'averageDuration',
            '#A5F3FC',
            xAxisLabelFormatterAsTime,
        ),
        getChart(
            t('report.agentProductivityPerAgent.totalIdleTimePerUserBarChart.title'),
            'averageTotalIdleTime',
            '#BAE6FD',
            xAxisLabelFormatterAsTime,
        ),
        getChart(
            t('report.agentProductivityPerAgent.idleTimePerUserBarChart.title'),
            'averageIdleTime',
            '#F5D0FE',
            xAxisLabelFormatterAsTime,
        ),
    ];
});

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchReportData = async () => {
    const params: AgentProductivityReportServiceGetInfoParams = {
        startDate: moment.utc(dateRange.value[0]).format('YYYY-MM-DD'),
        endDate: moment.utc(dateRange.value[1]).format('YYYY-MM-DD'),
        user: filter.value.user?.id || null,
        userLabel: filter.value.userLabel?.id || null,
    };

    try {
        setLoading(true);
        const response = await AgentProductivityPerAgentReportService.getInfo({ params });
        reportInfo.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveFilter = (filter: FilterItem) => {
    store.dispatch(AgentProductivityReportActions.CLEAR_FILTER, filter.field);
};

watch([levelFilter, dateRange, activeFilters], () => {
    fetchReportData();
});

onMounted(() => {
    fetchReportData();
});

const __returned__ = { reportInfo, levelFilter, dateRange, filter, activeFilters, t, filterStore, uiStore, headerStats, xAxisLabelFormatterAsTime, getChart, charts, setLoading, fetchReportData, handleRemoveFilter, get AgentProductivityPerAgentReportFilters() { return AgentProductivityPerAgentReportFilters } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})