import { ListFilters, MultiSelectOption, UserModel, UserLabelModel, WordModel } from '@/types';

export interface CallFilter {
    type: MultiSelectOption<string> | null;
    status: MultiSelectOption<string> | null;
    userLabel: UserLabelModel | null;
    media: MultiSelectOption<string> | null;
    numberClient: MultiSelectOption<string> | null;
    user: UserModel | null;
    word: WordModel | null;
}

export interface CallState extends ListFilters {
    filter: CallFilter;
}

export enum CallMutations {
    SET_SEARCH = 'call_setSearch',
    SET_SORT = 'call_setSort',
    SET_PAGE = 'call_setPage',
    SET_FILTER = 'call_setFilter',
    CLEAR_FILTER = 'call_clearFilter',
}

export enum CallActions {
    SET_SEARCH = 'call_setSearch',
    SET_SORT = 'call_setSort',
    SET_PAGE = 'call_setPage',
    SET_FILTER = 'call_setFilter',
    CLEAR_FILTER = 'call_clearFilter',
}

export enum CallGetters {
    SEARCH = 'call_search',
    SORT = 'call_sort',
    PAGE = 'call_page',
    FILTER = 'call_filter',
    DEFAULT_FILTER = 'call_defaultFilter',
    ACTIVE_FILTERS = 'call_activeFilters',
}
