import { defineComponent as _defineComponent } from 'vue'
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AxiosResponse } from 'axios';

import { useUiStore } from '@/stores';
import UserLabelService from '@/services/api/UserLabelService';
import { toastServiceError } from '@/services/core/notification';
import { UserLabelModel, Permission } from '@/types';
import { MailIcon } from '@heroicons/vue/solid';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserLabelDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const uiStore = useUiStore();

const userLabel = ref<UserLabelModel | undefined>(undefined);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchUserLabel = async (id: number) => {
    setLoading(true);
    try {
        const response: AxiosResponse<UserLabelModel> =
            await UserLabelService.get<UserLabelModel>(id);
        userLabel.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

onMounted(() => {
    const id = Number(route.params.id);
    fetchUserLabel(id);
});

const __returned__ = { route, uiStore, userLabel, setLoading, fetchUserLabel, get Permission() { return Permission }, get MailIcon() { return MailIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})