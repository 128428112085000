import { defineComponent as _defineComponent } from 'vue'
import { ref, computed } from 'vue';
import { MeetingMessageWithFormattedTimestampModel } from '@/types';

import { useI18n } from 'vue-i18n';
import { MenuIcon } from '@heroicons/vue/outline';


export default /*@__PURE__*/_defineComponent({
  __name: 'TranscriptSection',
  props: {
    messages: {
        type: Array as () => MeetingMessageWithFormattedTimestampModel[],
        required: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const props = __props;

const searchQuery = ref('');

const filteredMessages = computed(() => {
    if (!searchQuery.value) {
        return props.messages;
    }
    const query = searchQuery.value.toLowerCase();
    return props.messages.filter((message) => message.transcript.toLowerCase().includes(query));
});

const __returned__ = { t, props, searchQuery, filteredMessages, get MenuIcon() { return MenuIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})