import { defineStore } from 'pinia';
import moment from 'moment';
import { FilterState } from '@/types';
import type { LevelModel } from '@/types';

const startDate = moment.utc().startOf('day').toDate();
const endDate = moment.utc().startOf('day').subtract(6, 'd').toDate();

export const useFilterStore = defineStore('filter', {
    state: (): FilterState => ({
        level: null,
        dateRange: [endDate, startDate],
    }),

    actions: {
        setLevel(level: LevelModel) {
            this.level = level;
            localStorage.setItem('level', btoa(JSON.stringify(level)));
        },

        setDateRange(dateRange: Date[]) {
            this.dateRange = dateRange;
        },
    },
});
