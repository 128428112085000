<script lang="ts" setup>
import { ref, Ref, computed, watch, onMounted } from 'vue';
import moment from 'moment';
import { useI18n } from 'vue-i18n';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import { BillingReportService } from '@/services';

import {
    BarChartConfig,
    BillingModel,
    BillingReportActions,
    BillingReportGetters,
    DataItem,
    FilterItem,
} from '@/types';

import { BillingReportFilters } from './components';

const reportInfo: Ref<BillingModel | null> = ref(null);

const dateRange = computed<Array<Date>>({
    get: () => filterStore.dateRange,
    set: (value) => filterStore.setDateRange(value),
});

const filter = computed(() => store.getters[BillingReportGetters.FILTER]);
const activeFilters = computed(() => store.getters[BillingReportGetters.ACTIVE_FILTERS]);

const filterStore = useFilterStore();
const uiStore = useUiStore();
const { t } = useI18n();

watch(
    [dateRange, filter],
    () => {
        fetchReportData();
    },
    { deep: true },
);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchReportData = async () => {
    const params = {
        start_date: moment.utc(dateRange.value[0]).format('YYYY-MM-DD'),
        end_date: moment.utc(dateRange.value[1]).format('YYYY-MM-DD'),
        user_id: filter.value.user?.id || null,
        source_number: filter.value.sourceNumber?.value || null,
        destination_number: filter.value.destinationNumber?.value || null,
        status: filter.value.status?.value || null,
        type: filter.value.type?.value || null,
    };

    try {
        setLoading(true);
        const response = await BillingReportService.getInfo({ params });
        reportInfo.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveFilter = (filter: FilterItem) => {
    store.dispatch(BillingReportActions.CLEAR_FILTER, filter.field);
};

const headerStats = computed<DataItem<string>[]>(() => {
    const totalMinutes = reportInfo.value?.totalMinutes || 0;
    const totalCost = reportInfo.value?.totalCost?.toFixed(2) || '0,00';

    return [
        {
            name: 'totalMinutes',
            value: `${Math.floor(totalMinutes)}:${Math.floor((totalMinutes % 1) * 60)
                .toString()
                .padStart(2, '0')}`,
        },
        {
            name: 'totalCost',
            value: `R$ ${totalCost}`,
        },
    ];
});

const getChart = (
    title: string,
    dataKey: keyof BillingModel['dailyResults'][0],
    color = '#BFDBFE',
): BarChartConfig => {
    const category =
        reportInfo.value?.dailyResults?.map((item) => moment(item.date).format('D [de] MMM')) || [];

    const value = reportInfo.value?.dailyResults?.map((item) => item[dataKey] || 0) || [];

    return {
        title,
        category,
        series: [
            {
                name: title,
                value,
                color,
            },
        ],
    };
};

const dailyConsumptionChart = computed(() =>
    getChart(t('report.billing.dailyConsumptionValue'), 'cost', '#00C3AE'),
);

onMounted(() => {
    fetchReportData();
});
</script>

<template>
    <div class="space-y-4 p-4 lg:p-8">
        <!-- Header com Total de Minutos e Custo Total -->
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('report.billing.title') }}
                </h1>
            </template>

            <template #actions>
                <BillingReportFilters
                    :sources="reportInfo?.sources || []"
                    :destinations="reportInfo?.destinations || []"
                />
                <UiDatePicker v-model="dateRange" range multiCalendars class="ml-4" />
            </template>
        </UiPageHeader>

        <!-- Filtros Ativos -->
        <div
            v-if="activeFilters.length"
            class="flex bg-gray-50 p-4 shadow ring-1 ring-gray-300 md:rounded"
        >
            <template :key="filter" v-for="filter in activeFilters">
                <UiBadge removable @remove="handleRemoveFilter(filter)">
                    {{ $t(`report.billing.filters.activeTag.${filter.field}`) }}:
                    {{ filter.label }}
                </UiBadge>
            </template>
        </div>

        <!-- Estatísticas Gerais -->
        <dl
            v-if="headerStats"
            class="mt-4 grid grid-cols-2 gap-4"
            :class="['sm:grid-cols-' + headerStats.length]"
        >
            <UiPanel v-for="stat in headerStats" :key="stat.name">
                <dt class="text-center text-xs font-medium text-gray-500">
                    {{ $t('report.billing.stats.' + stat.name) }}
                </dt>
                <dd class="mt-1 text-center text-2xl font-semibold text-gray-800">
                    {{ stat.value }}
                </dd>
            </UiPanel>
        </dl>

        <!-- Estatísticas Entrante e Saínte -->
        <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <!-- Seção Entrante -->
            <div class="rounded bg-gray-50 p-4 shadow">
                <h2 class="mb-4 text-lg font-semibold text-gray-800">
                    {{ $t('report.billing.stats.inbound') }}
                </h2>
                <dl class="space-y-4">
                    <!-- Custo Total Entrante -->
                    <div class="flex justify-between border-b border-gray-200">
                        <dt class="text-sm font-medium text-gray-500">
                            {{ $t('report.billing.stats.totalInboundCost') }}
                        </dt>
                        <dd class="text-lg font-semibold text-gray-800">
                            R$ {{ reportInfo?.inboundCost?.toFixed(2) || '0,00' }}
                        </dd>
                    </div>
                    <!-- Subcustos por Tipo de Origem (Entrante) -->
                    <div
                        v-for="(value, key) in reportInfo?.costByOriginType || {}"
                        :key="key"
                        class="flex justify-between"
                    >
                        <dt class="text-sm text-gray-500">
                            {{ $t(`report.billing.stats.originType.${key}`) }}
                        </dt>
                        <dd class="text-sm font-semibold text-gray-800">
                            R$ {{ value?.toFixed(2) || '0,00' }}
                        </dd>
                    </div>
                </dl>
            </div>

            <!-- Seção Saínte -->
            <div class="rounded bg-gray-50 p-4 shadow">
                <h2 class="mb-4 text-lg font-semibold text-gray-800">
                    {{ $t('report.billing.stats.outbound') }}
                </h2>
                <dl class="space-y-4">
                    <!-- Custo Total Sainte -->
                    <div class="flex justify-between border-b border-gray-200">
                        <dt class="text-sm font-medium text-gray-500">
                            {{ $t('report.billing.stats.totalOutboundCost') }}
                        </dt>
                        <dd class="text-lg font-semibold text-gray-800">
                            R$ {{ reportInfo?.outboundCost?.toFixed(2) || '0,00' }}
                        </dd>
                    </div>
                    <!-- Subcustos por Tipo de Destino (Sainte) -->
                    <div
                        v-for="(value, key) in reportInfo?.costByDestinationType || {}"
                        :key="key"
                        class="flex justify-between"
                    >
                        <dt class="text-sm text-gray-500">
                            {{ $t(`report.billing.stats.destinationType.${key}`) }}
                        </dt>
                        <dd class="text-sm font-semibold text-gray-800">
                            R$ {{ value?.toFixed(2) || '0,00' }}
                        </dd>
                    </div>
                </dl>
            </div>
        </div>

        <!-- Gráfico de Consumo por Dia -->
        <BarChart
            :title="dailyConsumptionChart.title"
            :categoryData="dailyConsumptionChart.category"
            :seriesData="dailyConsumptionChart.series"
            :column="true"
            class="h-[400px]"
        />
    </div>
</template>
