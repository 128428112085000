import {
    MeetingPlatformType,
    MeetingMessageModel,
    MeetingParticipantModel,
    FullAudit,
} from '@/types';

export interface MeetingModel extends FullAudit {
    id: number;
    levelId: string;
    title: string;
    platform: MeetingPlatformType;
    speechDuration: number;
    externalId: string;
    participants: Array<MeetingParticipantModel>;
    messages?: Array<MeetingMessageModel>;
}
