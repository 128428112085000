import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import ConnectFlowSurveyService from '@/services/api/ConnectFlowSurveyService';
import { toast, toastServiceError } from '@/services/core/notification';

import {
    FilterItem,
    SortMenuItem,
    ConnectFlowSurveyModel,
    ConnectFlowSurveyFilter,
    ConnectFlowSurveyServiceGetAllParams,
    Permission,
    ConnectFlowSurveyActions,
    ConnectFlowSurveyGetters,
} from '@/types';

import {
    ConnectFlowSurveyListItem,
    ConnectFlowSurveyListSort,
    ConnectFlowSurveyListFilters,
} from '@/views/pages/connect-flow-survey/components';

import { OfficeBuildingIcon } from '@heroicons/vue/solid';

const perPage = 10;


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowSurveyPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();

const filterStore = useFilterStore();
const uiStore = useUiStore();

const surveys = ref<ConnectFlowSurveyModel[]>([]);
const count = ref(0);
const currentPage = computed({
    get: () => store.getters[ConnectFlowSurveyGetters.PAGE],
    set: (value: number) => store.dispatch(ConnectFlowSurveyActions.SET_PAGE, value),
});

const search = computed({
    get: () => store.getters[ConnectFlowSurveyGetters.SEARCH],
    set: (value: string) => store.dispatch(ConnectFlowSurveyActions.SET_SEARCH, value),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[ConnectFlowSurveyGetters.SORT],
    set: (value: SortMenuItem[]) => store.dispatch(ConnectFlowSurveyActions.SET_SORT, value),
});

const levelFilter = computed(() => filterStore.level);

const filter: ConnectFlowSurveyFilter = store.getters[ConnectFlowSurveyGetters.FILTER];

const activeFilters = computed<FilterItem[]>(
    () => store.getters[ConnectFlowSurveyGetters.ACTIVE_FILTERS],
);

const orderBy = computed(() => sortMenuSelected.value.map((s) => s.direction + s.key).join(','));

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getParams = (): ConnectFlowSurveyServiceGetAllParams => ({
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    is_active: filter.isActive !== null ? (filter.isActive.value as boolean) : undefined,
    search: search.value,
});

const fetchConnectFlowSurveys = async () => {
    await setLoading(true);
    try {
        const { data, headers } = await ConnectFlowSurveyService.getAll<ConnectFlowSurveyModel[]>({
            params: getParams(),
        });
        surveys.value = data;
        count.value = parseInt(headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const setIsActive = async (item: ConnectFlowSurveyModel, isActive: boolean) => {
    await setLoading(true);
    try {
        const { data } = await (
            isActive
                ? (id: number) => ConnectFlowSurveyService.activate(id)
                : (id: number) => ConnectFlowSurveyService.inactivate(id)
        )(item.id);
        toast.success(data.message);
        await fetchConnectFlowSurveys();
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const handleActivateClick = (item: ConnectFlowSurveyModel) => setIsActive(item, true);
const handleInactivateClick = (item: ConnectFlowSurveyModel) => setIsActive(item, false);

const handleRemoveSortClick = (item: SortMenuItem) => {
    const index = sortMenuSelected.value.indexOf(item);
    if (index > -1) sortMenuSelected.value.splice(index, 1);
};

const handleRemoveFilterClick = (item: FilterItem) => {
    store.dispatch(ConnectFlowSurveyActions.CLEAR_FILTER, item);
};

watch([currentPage], () => {
    fetchConnectFlowSurveys();
});

watch([levelFilter, search, filter, orderBy], () => {
    if (currentPage.value === 1) fetchConnectFlowSurveys();
    else currentPage.value = 1;
});

if (route.query.clearStateOnSetup) {
    search.value = '';
    sortMenuSelected.value = [];
    currentPage.value = 1;
    router.replace({ query: {} });
}

onMounted(fetchConnectFlowSurveys);

const __returned__ = { route, router, filterStore, uiStore, surveys, count, perPage, currentPage, search, sortMenuSelected, levelFilter, filter, activeFilters, orderBy, setLoading, getParams, fetchConnectFlowSurveys, setIsActive, handleActivateClick, handleInactivateClick, handleRemoveSortClick, handleRemoveFilterClick, get Permission() { return Permission }, get ConnectFlowSurveyListItem() { return ConnectFlowSurveyListItem }, get ConnectFlowSurveyListSort() { return ConnectFlowSurveyListSort }, get ConnectFlowSurveyListFilters() { return ConnectFlowSurveyListFilters }, get OfficeBuildingIcon() { return OfficeBuildingIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})