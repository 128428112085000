import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-4 p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-800" }
const _hoisted_3 = { class: "flex space-x-4" }
const _hoisted_4 = { class: "space-y-4" }
const _hoisted_5 = { class: "grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3" }
const _hoisted_6 = { class: "mb-1 font-semibold" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "mb-1 font-semibold" }
const _hoisted_9 = { controls: "" }
const _hoisted_10 = ["src", "type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiBackButton = _resolveComponent("UiBackButton")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!
  const _component_UiAuditStatusInfo = _resolveComponent("UiAuditStatusInfo")!
  const _component_UiPanel = _resolveComponent("UiPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('connectFlowAudio.view.title')), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_UiBackButton, { fallbackTo: { name: 'ListConnectFlowAudio' } }),
          (_ctx.$can($setup.Permission.EDIT_CONNECT_FLOW_AUDIO))
            ? (_openBlock(), _createBlock($setup["RouterLink"], {
                key: 0,
                to: { name: 'EditConnectFlowAudioById', params: { id: $setup.route.params.id } },
                class: "block hover:bg-gray-50"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_UiButton, {
                    variant: "primary",
                    "text-variant": "white"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Edit')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"]))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_UiPanel, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('connectFlowAudio.labels.name')), 1 /* TEXT */),
              _createElementVNode("p", null, _toDisplayString($setup.connectFlowAudio?.name), 1 /* TEXT */)
            ]),
            ($setup.audioData)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.$t('connectFlowAudio.labels.file')), 1 /* TEXT */),
                  _createElementVNode("audio", _hoisted_9, [
                    _createElementVNode("source", {
                      src: $setup.audioData,
                      type: $setup.getAudioFormat($setup.connectFlowAudio?.audioFilePath || '')
                    }, null, 8 /* PROPS */, _hoisted_10),
                    _cache[0] || (_cache[0] = _createTextVNode(" Your browser does not support the audio element. "))
                  ])
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _createVNode(_component_UiAuditStatusInfo, {
            "model-value": $setup.connectFlowAudio,
            class: "bt-1 mt-4 border-t border-gray-300 pt-2"
          }, null, 8 /* PROPS */, ["model-value"])
        ]),
        _: 1 /* STABLE */
      })
    ])
  ]))
}