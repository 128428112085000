<script lang="ts" setup>
defineProps({
    noPadding: {
        type: Boolean,
        default: false,
    },
    rounded: {
        type: Boolean,
        default: true,
    },
});
</script>
<template>
    <div
        class="bg-gray-50 shadow ring-1 ring-gray-300"
        :class="[noPadding ? '' : 'p-4', rounded ? 'rounded' : '']"
    >
        <slot></slot>
    </div>
</template>
