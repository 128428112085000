<script lang="ts" setup>
import { PropType } from 'vue';
import type { Component } from 'vue';
import { Permission } from '@/types';
import { ArrowRightIcon } from '@heroicons/vue/solid';

defineProps({
    routeName: {
        type: String,
        required: true,
    },
    permission: {
        type: String as PropType<Permission>,
        required: false,
    },
    title: {
        type: String,
        required: true,
    },
    description: {
        type: String,
        required: true,
    },
    icon: {
        type: [Object, String, Function] as PropType<string | Component>,
        required: true,
    },
});
</script>

<template>
    <router-link :to="{ name: routeName }" v-if="permission && $can(permission)">
        <UiPanel class="flex min-h-full flex-col space-y-2 hover:bg-white" no-padding>
            <div class="flex items-center">
                <h3 class="text-primary flex-1 px-4 font-semibold">
                    {{ $t(title) }}
                </h3>
                <div class="bg-primary rounded-tr p-1 text-white">
                    <component :is="icon" class="h-8 w-8" aria-hidden="true" />
                </div>
            </div>
            <p class="flex-1 px-4 text-sm text-gray-600">
                {{ $t(description) }}
            </p>
            <div
                class="text-primary flex items-center rounded-b border-t border-gray-300/50 px-4 py-2 text-sm font-semibold"
            >
                <ArrowRightIcon class="-ml-1 mr-1 h-4 w-4" aria-hidden="true" />
                {{ $t('core.actions.goPage') }}
            </div>
        </UiPanel>
    </router-link>
</template>
