import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-xl font-semibold text-gray-800" }
const _hoisted_2 = { class: "flex items-center space-x-4" }
const _hoisted_3 = { class: "grid grid-cols-1 gap-4 md:grid-cols-3" }
const _hoisted_4 = {
  for: "callParticipants",
  class: "mb-2 block text-sm font-medium text-gray-800"
}
const _hoisted_5 = { class: "flex flex-col justify-end pb-1.5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiBackButton = _resolveComponent("UiBackButton")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!
  const _component_UiTextInput = _resolveComponent("UiTextInput")!
  const _component_UiMultiselect = _resolveComponent("UiMultiselect")!
  const _component_UiCheckboxInput = _resolveComponent("UiCheckboxInput")!
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _component_UiCheckDirtyBeforeRouteLeave = _resolveComponent("UiCheckDirtyBeforeRouteLeave")!

  return (_openBlock(), _createElementBlock("form", {
    class: "space-y-4 p-4 sm:p-6 lg:p-8",
    autocomplete: "off",
    onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
  }, [
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('word.' + $setup.props.action + '.title')), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_UiBackButton, { fallbackTo: { name: 'ListWord' } }),
          (
                            ($setup.props.action == $setup.FormAction.CREATE && _ctx.$can($setup.Permission.CREATE_WORD)) ||
                            ($setup.props.action == $setup.FormAction.EDIT && _ctx.$can($setup.Permission.EDIT_WORD))
                        )
            ? (_openBlock(), _createBlock(_component_UiButton, {
                key: 0,
                type: "submit",
                variant: "primary",
                "text-variant": "white"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Save')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_UiPanel, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_UiTextInput, {
            modelValue: $setup.v$.word.$model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.v$.word.$model) = $event)),
            name: "word",
            type: "text",
            label: _ctx.$t('word.labels.word'),
            errors: $setup.v$.word.$errors,
            onBlur: $setup.v$.word.$touch
          }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"]),
          _createElementVNode("div", null, [
            _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('word.labels.callParticipant')), 1 /* TEXT */),
            _createVNode(_component_UiMultiselect, {
              name: "callParticipants",
              modelValue: $setup.formState.callParticipant,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formState.callParticipant) = $event)),
              primitive: true,
              options: $setup.participants,
              errors: $setup.v$.callParticipant.$errors
            }, null, 8 /* PROPS */, ["modelValue", "options", "errors"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_UiCheckboxInput, {
              modelValue: $setup.v$.isActive.$model,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.v$.isActive.$model) = $event)),
              name: "isActive",
              label: _ctx.$t('word.labels.isActive'),
              class: "h-6"
            }, null, 8 /* PROPS */, ["modelValue", "label"])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_UiCheckDirtyBeforeRouteLeave, {
      dirty: $setup.v$.$anyDirty
    }, null, 8 /* PROPS */, ["dirty"])
  ], 32 /* NEED_HYDRATION */))
}