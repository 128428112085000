<script lang="ts" setup>
import { MeetingParticipantWithSpeechPercentageModel } from '@/types';
import { useI18n } from 'vue-i18n';
import { SparklesIcon } from '@heroicons/vue/outline';

const { t } = useI18n();

const props = defineProps({
    participants: {
        type: Array as () => MeetingParticipantWithSpeechPercentageModel[],
        required: true,
    },
});

const getParticipantColor = (index: number) => {
    const colors = ['#7c3aed', '#14b8a6', '#0ea5e9', '#6b7280'];
    return colors[index % colors.length];
};
</script>

<template>
    <div
        class="flex h-[75vh] flex-col rounded bg-gray-50 p-6 shadow ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50"
    >
        <div class="mb-4 flex items-center">
            <SparklesIcon
                class="mr-3 h-6 w-6 flex-shrink-0 text-[#6dbcab] group-hover:text-[#6dbcab]"
                aria-hidden="true"
            />
            <h3 class="text-lg font-bold text-gray-800">
                {{ t('meeting.view.insights.title') }}
            </h3>
        </div>

        <ul class="flex-1 space-y-3 overflow-y-auto">
            <li
                v-for="(participant, index) in props.participants"
                :key="participant.id"
                class="flex items-center justify-between"
            >
                <!-- Nome do participante com largura fixa -->
                <span class="min-w-[30%] max-w-[30%] font-semibold text-gray-700">{{
                    participant.name
                }}</span>

                <div class="flex flex-1 items-center">
                    <!-- Barra de progresso -->
                    <div class="mr-3 h-2 w-full rounded-full bg-gray-300">
                        <div
                            class="h-2 rounded-full"
                            :style="{
                                width: participant.speechPercentage + '%',
                                backgroundColor: getParticipantColor(index),
                            }"
                        ></div>
                    </div>

                    <!-- Porcentagem com largura fixa -->
                    <span class="min-w-[40px] text-right text-sm font-semibold text-gray-700"
                        >{{ participant.speechPercentage }}%</span
                    >
                </div>
            </li>
        </ul>

        <!-- Prompt de IA (futuro) -->
        <!-- <div
      class="bg-gray-50 shadow rounded p-6 ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50 flex-grow flex flex-col"
    >
      <div class="flex items-center mb-4">
        <ChatIcon
          class="mr-3 h-6 w-6 flex-shrink-0 text-[#6dbcab] group-hover:text-[#6dbcab]"
          aria-hidden="true"
        />
        <h3 class="text-lg font-semibold text-gray-800">
          {{ t("meeting.view.ia.title") }}
        </h3>
      </div>

      <div class="p-4 rounded flex-1 overflow-y-auto">
        <p class="text-gray-500">{{ t("meeting.view.ia.devMessage") }}</p>
      </div>
    </div> -->
    </div>
</template>
