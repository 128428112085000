<script lang="ts" setup>
// Imports
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';

import {
    UsersStatusDoughnutChart,
    CallQueueGaugeChart,
    QueueDetailsTable,
    UsersExtensionsDetailsTable,
    DataTotalize,
} from '@/views/pages/reports/realtime/components';

import RealtimeReportService from '@/services/api/RealtimeReportService';
import { toastServiceError } from '@/services/core/notification';

import { RealtimeStatisticsSummary, LevelModel } from '@/types';
import { useFilterStore } from '@/stores';

// Constants and route/props

// State
const dataTotalize = ref<RealtimeStatisticsSummary>({
    callQueue: 0,
    callInboundInProgress: undefined,
    callOutboundInProgress: undefined,
    userOffline: undefined,
    userOnline: undefined,
    averageQuality: undefined,
});

const loading = ref(false);

// Computed
const levelFilter = computed<LevelModel | null>(() => filterStore.level);

// Composables
const filterStore = useFilterStore();

// Methods
const fetchStatisticsSummary = async () => {
    try {
        loading.value = true;
        const { data } = await RealtimeReportService.getDataTotalize<RealtimeStatisticsSummary>();
        dataTotalize.value = { ...data };
    } catch (error) {
        toastServiceError(error);
    } finally {
        loading.value = false;
    }
};

let interval: number;

// Watchers
watch(levelFilter, () => {
    dataTotalize.value.callQueue = 0;
    dataTotalize.value.callInboundInProgress = undefined;
    dataTotalize.value.callOutboundInProgress = undefined;
    dataTotalize.value.userOffline = undefined;
    dataTotalize.value.userOnline = undefined;
    dataTotalize.value.averageQuality = undefined;

    clearInterval(interval);
    interval = setInterval(fetchStatisticsSummary, 5000);
});

// Lifecycle
onMounted(() => {
    interval = setInterval(fetchStatisticsSummary, 5000);
});
onUnmounted(() => {
    clearInterval(interval);
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('report.realtime.title') }}
                </h1>
            </template>
        </UiPageHeader>

        <DataTotalize :data="dataTotalize" :loading="loading" />

        <div class="grid grid-cols-3 gap-4">
            <CallQueueGaugeChart
                :data="dataTotalize.callQueue"
                :loading="loading"
                class="col-span-3 h-[200px] md:col-span-1 md:h-[277px]"
            />

            <QueueDetailsTable class="col-span-3 h-[200px] md:col-span-2 md:h-[277px]" />
        </div>

        <div class="grid h-[300px] grid-cols-3 gap-4">
            <UsersStatusDoughnutChart
                :data="dataTotalize"
                :loading="loading"
                class="col-span-3 h-[200px] md:col-span-1 md:h-[277px]"
            />

            <UsersExtensionsDetailsTable class="col-span-3 h-[200px] md:col-span-2 md:h-[277px]" />
        </div>
    </div>
</template>
