import { UserDeviceModel } from '@/types';

export interface AgentDeviceStatusHistoryReportServiceGetInfoParams {
    startDate: string;
    endDate: string;
}

export interface AgentDeviceStatusHistoryReportServiceGetInfoResult {
    totalTimePaused: number;
    totalTimeCall: number;
    devices: UserDeviceModel[];
}
