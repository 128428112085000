/* eslint-disable @typescript-eslint/no-explicit-any */
import useVuelidate from '@vuelidate/core';

export function useVuelidateWithLogging(rules: any, state: any) {
    const v$ = useVuelidate(rules, state);

    const $validateWithLogging = async () => {
        const isValid = await v$.value.$validate();

        if (!isValid) {
            const collectErrors = (v: any, path = '') => {
                const errors: string[] = [];

                for (const key in v) {
                    if (key.startsWith('$')) continue;

                    const field = v[key];
                    const currentPath = path ? `${path}.${key}` : key;

                    if (field?.$errors?.length) {
                        field.$errors.forEach((e: any) => {
                            errors.push(`${currentPath}: ${e.$message}`);
                        });
                    }

                    if (typeof field === 'object') {
                        errors.push(...collectErrors(field, currentPath));
                    }
                }

                return errors;
            };

            const allErrors = collectErrors(v$.value);
            console.warn('Validation errors:', allErrors);
        }

        return isValid;
    };

    return { v$, $validateWithLogging };
}
