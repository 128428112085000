import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, ref, Ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { required, maxLength } from '@/utils/validators';

import { useUiStore } from '@/stores';
import { RoleService, UserService } from '@/services';
import { toast, toastServiceError } from '@/services/core/notification';
import { goBack } from '@/composables/useNavigation';

import {
    DefaultServiceResult,
    FormAction,
    Permission,
    RoleFormState,
    RoleModel,
    RoleServiceCreateResult,
    UserModel,
} from '@/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'RoleFormPage',
  props: {
    action: {
        type: String,
        default: 'view',
    },
},
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const uiStore = useUiStore();

const props = __props;

const formState = reactive<RoleFormState>({
    name: '',
    users: [],
});

const rules = computed(() => ({
    name: { required, maxLength: maxLength(500) },
    users: {},
}));

const v$: Ref<Validation<ValidationArgs<RoleFormState>>> = useVuelidate(rules, formState);

const usersRaw: Ref<UserModel[]> = ref([]);

const users = computed(() =>
    usersRaw.value.map((user) => ({
        id: user.id,
        name: user.name,
    })),
);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getUsers = async () => {
    try {
        setLoading(true);
        const response = await UserService.getAll<UserModel[]>({
            params: { page: 1, per_page: 100 },
        });
        usersRaw.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const getRole = async (id: number) => {
    try {
        setLoading(true);
        const { data } = await RoleService.get<RoleModel>(id);
        formState.name = data.name;
        formState.users = data.users?.map((u) => u.id) || [];
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const onSubmit = async () => {
    const isFormCorrect = await v$.value.$validate();
    if (!isFormCorrect) return;

    try {
        setLoading(true);

        if (props.action === FormAction.EDIT) {
            const id = Number(route.params.id);
            const response = await RoleService.update<DefaultServiceResult, RoleFormState>(
                id,
                formState,
            );
            toast.success(response.data.message);
            v$.value.$reset();
            goBack({ name: 'ViewRoleById', params: { id } });
        } else {
            const response = await RoleService.create<RoleServiceCreateResult, RoleFormState>(
                formState,
            );
            toast.success(response.data.message);
            v$.value.$reset();
            goBack({ name: 'ViewRoleById', params: { id: response.data.result.id } });
        }
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

onMounted(() => {
    getUsers();
    if (props.action === FormAction.EDIT) {
        getRole(Number(route.params.id));
    }
});

const __returned__ = { route, uiStore, props, formState, rules, v$, usersRaw, users, setLoading, getUsers, getRole, onSubmit, get FormAction() { return FormAction }, get Permission() { return Permission } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})