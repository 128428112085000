import { LevelModel } from '@/types';
import BaseService from '@/services/api/BaseService';
import { AxiosRequestConfig } from 'axios';

class LevelService extends BaseService<LevelModel> {
    getAllGlobalSelect<ResponseT = LevelModel[]>(config?: AxiosRequestConfig) {
        return this.http.get<ResponseT>(`${this.route}/global-select`, config);
    }
}

export default new LevelService('levels');
