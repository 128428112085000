<script lang="ts" setup>
import { PropType, computed, WritableComputedRef, ref, Ref, onMounted } from 'vue';
import {
    ConnectFlowModelingActions,
    ConnectFlowModelingGetters,
    ConnectFlowModelingMutations,
    VueFlowNodeModel,
    MultiSelectOption,
    ScheduleModel,
} from '@/types';
import store from '@/store';
import { ClockIcon } from '@heroicons/vue/outline';

import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { required } from '@/utils/validators';

const props = defineProps({
    node: {
        type: Object as PropType<VueFlowNodeModel>,
        required: true,
    },
});

const showForm = ref(false);

const nodeWritable: WritableComputedRef<VueFlowNodeModel> = computed({
    get: () => store.getters['flow/' + ConnectFlowModelingGetters.ELEMENT_BY_ID](props.node.id),
    set: (value: VueFlowNodeModel) => {
        store.commit('flow/' + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
            idx: null,
            value,
        });
    },
});

const scheduleOptions = computed(
    () => store.getters['flow/' + ConnectFlowModelingGetters.SCHEDULE_OPTIONS],
);
interface ITimeFormState {
    schedule: null | ScheduleModel;
}
const formState: Ref<ITimeFormState> = ref({
    schedule: null,
});
const rules = computed<ValidationArgs<ITimeFormState>>(() => {
    const r: ValidationArgs<ITimeFormState> = {
        schedule: {
            required,
        },
    };
    return r;
});
const v$: Ref<Validation<ValidationArgs<ITimeFormState>>> = useVuelidate(rules, formState);

const isFormCorrect = ref(true);

function removeNode() {
    store.dispatch('flow/' + ConnectFlowModelingActions.DELETE_NODE, {
        nodeId: nodeWritable.value.id,
        parentId: nodeWritable.value.parentId,
    });
}
async function onSubmit() {
    isFormCorrect.value = await v$.value.$validate();
    if (isFormCorrect.value) {
        nodeWritable.value.data.related = {
            value: formState.value.schedule?.id,
            label: formState.value.schedule?.name,
        } as MultiSelectOption;
        showForm.value = false;
    }
    nodeWritable.value.data.hasErrors = !isFormCorrect.value;
    v$.value.$reset();
}

onMounted(() => {
    if (nodeWritable.value.data.related !== null) {
        formState.value.schedule = scheduleOptions.value.find(
            (schedule: ScheduleModel) => schedule.id == nodeWritable.value.data.related?.value,
        );
    }

    v$.value.$validate().then((result) => {
        isFormCorrect.value = result;
        nodeWritable.value.data.hasErrors = !isFormCorrect.value;
    });
});
</script>

<template>
    <UiNode
        :nodeId="node.id"
        showActions
        :title="$t('connectFlow.node.timeValidation.title')"
        :icon="ClockIcon"
        @editNode="showForm = true"
        @removeNode="removeNode"
        :class="isFormCorrect ? '' : 'border-red-300 shadow-red-300'"
    >
        <div class="font-medium text-gray-600">
            <span v-if="nodeWritable.data.related">
                {{ nodeWritable.data.related.label }}
            </span>
            <span v-else>
                {{ $t('connectFlow.node.timeValidation.empty') }}
            </span>
        </div>
    </UiNode>
    <UiRightPanel
        v-model="showForm"
        :title="$t('connectFlow.node.timeValidation.title')"
        :icon="ClockIcon"
    >
        <form autocomplete="off" class="flex flex-col gap-3" @submit.prevent="onSubmit">
            <div class="sm:col-span-12">
                <label class="mb-2 block text-sm font-medium text-gray-800">
                    {{ $t('connectFlow.node.timeValidation.selectLabel') }}
                </label>
                <UiMultiselect
                    name="schedule"
                    v-model="v$.schedule.$model"
                    :options="scheduleOptions"
                    label="name"
                    value-prop="id"
                    :errors="v$.schedule.$errors"
                    required
                />
            </div>

            <div class="mt-4 grid grid-cols-2 place-content-around gap-4 text-center">
                <UiButton variant="white" text-variant="gray-700" @click="showForm = false">
                    {{ $t('core.actions.Cancel') }}
                </UiButton>
                <UiButton type="submit" variant="primary" text-variant="white">
                    {{ $t('core.actions.Apply') }}
                </UiButton>
            </div>
        </form>
    </UiRightPanel>
</template>
