import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue';
import store from '@/store';
import { NavigationGetters, NavigationActions } from '@/types';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/solid';


export default /*@__PURE__*/_defineComponent({
  __name: 'TemplateSidebar',
  setup(__props, { expose: __expose }) {
  __expose();

const sidebarOpen = computed(() => store.getters[NavigationGetters.SIDE_NAVIGATION_OPEN]);

function closeSidebar() {
    store.dispatch(NavigationActions.TOGGLE_SIDE_NAVIGATION, true);
}

function handleDialogCLose() {
    closeSidebar();
}

const __returned__ = { sidebarOpen, closeSidebar, handleDialogCLose, get Dialog() { return Dialog }, get DialogPanel() { return DialogPanel }, get TransitionChild() { return TransitionChild }, get TransitionRoot() { return TransitionRoot }, get XIcon() { return XIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})