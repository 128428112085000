<script lang="ts" setup>
import { ErrorObject } from '@vuelidate/core';
import { computed } from 'vue';

const props = defineProps({
    type: {
        type: String,
        default: 'text',
    },
    modelValue: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: [String, Boolean],
        required: true,
    },
    placeholder: {
        type: String,
        default: '',
    },
    autocomplete: {
        type: String,
        default: 'off',
    },
    required: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    errors: {
        type: Array<ErrorObject>,
        default: [],
    },
    silentErrors: {
        type: Array<ErrorObject>,
        default: [],
    },
});

const hasErrors = computed(() => props.errors.length || props.silentErrors.length);

const emit = defineEmits(['update:modelValue', 'blur']);

function handleHoursInput(value: string) {
    const hours = parseInt(value, 10).toString().padStart(2, '0');
    const minutes = getMinutes(props.modelValue);
    emit('update:modelValue', formatTime(hours, minutes));
}

function handleMinutesInput(value: string) {
    const hours = getHours(props.modelValue);
    const minutes = parseInt(value, 10).toString().padStart(2, '0');
    emit('update:modelValue', formatTime(hours, minutes));
}

function getHours(time: string): string {
    const hours = parseInt(time.slice(0, 2)) || 0;
    return hours < 10 ? `0${hours}` : `${hours}`;
}

function getMinutes(time: string): string {
    const minutes = parseInt(time.slice(3, 5), 10) || 0;
    return minutes.toString().padStart(2, '0');
}

function formatTime(hours: string, minutes: string): string {
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:00`;
}
</script>

<template>
    <div class="flex flex-col">
        <label
            v-if="label !== false"
            :for="name"
            class="mb-2 block text-sm font-medium text-gray-800"
        >
            {{ label }} <span class="text-xs" v-if="required">*</span>
        </label>
        <div class="flex rounded">
            <select
                :name="name + '-hours'"
                :id="name + '-hours'"
                class="focus:border-primary block flex-1 rounded border border-gray-300 px-3 py-2 text-gray-800 shadow placeholder:text-gray-400 focus:outline-none focus:ring"
                :class="[
                    !hasErrors
                        ? 'focus:ring-primary ring-gray-300'
                        : 'ring-danger focus:ring-danger',
                ]"
                :value="getHours(props.modelValue)"
                @change="handleHoursInput(($event.target as HTMLInputElement).value)"
                :disabled="disabled"
                @blur="emit('blur', $event)"
            >
                <option
                    v-for="hour in Array.from({ length: 24 }, (_, i) => i)"
                    :value="hour.toString().padStart(2, '0')"
                    :key="hour"
                >
                    {{ hour.toString().padStart(2, '0') }}
                </option>
            </select>

            <span class="mx-2 mb-2 mt-2 text-lg font-bold">:</span>

            <select
                :name="name + '-minutes'"
                :id="name + '-minutes'"
                class="focus:border-primary block flex-1 rounded border border-gray-300 px-3 py-2 text-gray-800 shadow placeholder:text-gray-400 focus:outline-none focus:ring"
                :class="[
                    !hasErrors
                        ? 'focus:ring-primary ring-gray-300'
                        : 'ring-danger focus:ring-danger',
                ]"
                :value="getMinutes(props.modelValue)"
                @change="
                    handleMinutesInput(
                        $event.target ? ($event.target as HTMLInputElement).value : '',
                    )
                "
                :disabled="disabled"
                @blur="emit('blur', $event)"
            >
                <option
                    v-for="minute in Array.from({ length: 60 }, (_, i) => i)"
                    :value="minute.toString().padStart(2, '0')"
                    :key="minute"
                >
                    {{ minute.toString().padStart(2, '0') }}
                </option>
            </select>
        </div>
        <div class="text-danger text-sm" v-for="error of errors" :key="error.$uid">
            <p>
                {{ error.$response ? error.$response : error.$message }}
            </p>
        </div>
        <div class="text-danger text-sm" v-for="error of silentErrors" :key="error.$uid">
            <p>
                {{ error.$response ? error.$response : error.$message }}
            </p>
        </div>
    </div>
</template>
