import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "flex-1" }
const _hoisted_3 = {
  key: 0,
  class: "flex space-x-1"
}
const _hoisted_4 = { class: "sr-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiActionButton = _resolveComponent("UiActionButton")!
  const _component_UiAuditInfo = _resolveComponent("UiAuditInfo")!
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock(_component_UiPanel, { class: "space-y-2 hover:bg-white" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can($setup.Permission.VIEW_SPAM_NUMBER) ? 'RouterLink' : 'span'), {
            to: {
                        name: 'ViewSpamNumberById',
                        params: {
                            id: $props.modelValue.id,
                        },
                    },
            class: "text-md text-primary truncate font-medium"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.applyPhoneMask($props.modelValue.number)), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["to"]))
        ]),
        (_ctx.$can($setup.Permission.EDIT_SPAM_NUMBER))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _withDirectives((_openBlock(), _createBlock(_component_UiActionButton, {
                variant: "primary",
                to: {
                        name: 'EditSpamNumberById',
                        params: {
                            id: $props.modelValue.id,
                        },
                    }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('core.actions.Edit')), 1 /* TEXT */),
                  _createVNode($setup["PencilAltIcon"], {
                    class: "h-6 w-6",
                    "aria-hidden": "true"
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"])), [
                [_directive_tippy, _ctx.$t('core.actions.Edit')]
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createVNode(_component_UiAuditInfo, {
        "model-value": $props.modelValue,
        class: "justify-end"
      }, null, 8 /* PROPS */, ["model-value"])
    ]),
    _: 1 /* STABLE */
  }))
}