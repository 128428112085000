import { UserIntegrationType } from '@/types/enums/UserIntegrationType';
import { CreatedAudit } from '@/types/models/shared/AuditMetadata';

export interface UserIntegrationModel extends CreatedAudit {
    id: number;
    userId: number;
    levelId: number;
    integration: UserIntegrationType;
    token: string;
}
