<script lang="ts" setup>
import { computed, reactive, Ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AxiosResponse } from 'axios';

import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { required, maxLength } from '@/utils/validators';

import { useUiStore } from '@/stores';
import { goBack } from '@/composables/useNavigation';
import { IntegrationService } from '@/services';
import { toast, toastServiceError } from '@/services/core/notification';

import {
    FormAction,
    DefaultServiceResult,
    IntegrationServiceCreateResult,
    IntegrationType,
    OpenAiIntegrationModel,
    OpenAiIntegrationFormState,
    Permission,
} from '@/types';

const uiStore = useUiStore();
const route = useRoute();

const props = defineProps({
    action: {
        type: String,
        default: FormAction.CREATE,
    },
});

const formState = reactive<OpenAiIntegrationFormState>({
    type: IntegrationType.OpenAi,
    token: null,
    model: null,
    isActive: true,
});

const rules = computed<ValidationArgs<OpenAiIntegrationFormState>>(() => ({
    type: { required },
    token: { required, maxLength: maxLength(250) },
    model: { required, maxLength: maxLength(100) },
    isActive: { required },
}));

const v$: Ref<Validation<ValidationArgs<OpenAiIntegrationFormState>>> = useVuelidate(
    rules,
    formState,
);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getIntegration = async (id: number) => {
    await setLoading(true);
    try {
        const response = await IntegrationService.get<OpenAiIntegrationModel>(id);
        const { token, model, isActive } = response.data;
        formState.token = token;
        formState.model = model;
        formState.isActive = isActive;
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const onSubmit = async () => {
    const isValid = await v$.value.$validate();
    if (!isValid) return;

    await setLoading(true);

    try {
        let response: AxiosResponse<DefaultServiceResult | IntegrationServiceCreateResult>;

        if (props.action === FormAction.EDIT) {
            const id = Number(route.params.id);
            response = await IntegrationService.update<DefaultServiceResult>(id, formState);
        } else {
            response = await IntegrationService.create<IntegrationServiceCreateResult>(formState);
        }

        toast.success(response.data.message);
        v$.value.$reset();
        goBack({ name: 'ListIntegration' });
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

onMounted(async () => {
    if (props.action === FormAction.EDIT) {
        await getIntegration(Number(route.params.id));
    }
});
</script>

<template>
    <form class="space-y-4 p-4 sm:p-6 lg:p-8" autocomplete="off" @submit.prevent="onSubmit">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('core.entity.Integrations') }} -
                    {{ $t('integration.integrations.openai.title') }}
                </h1>
            </template>
            <template #actions>
                <div class="flex items-center space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ListIntegration' }" />
                    <UiButton
                        v-if="$can(Permission.EDIT_INTEGRATION)"
                        type="submit"
                        variant="primary"
                        text-variant="white"
                        class="ml-4"
                    >
                        {{ $t('core.actions.Save') }}
                    </UiButton>
                </div>
            </template>
        </UiPageHeader>
        <UiPanel>
            <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <UiTextInput
                    v-model="v$.token.$model"
                    name="token"
                    type="text"
                    :label="$t('integration.integrations.openai.form.token')"
                    :errors="v$.token.$errors"
                    @blur="v$.token.$touch"
                />
                <UiTextInput
                    v-model="v$.model.$model"
                    name="model"
                    type="text"
                    :label="$t('integration.integrations.openai.form.model')"
                    :errors="v$.model.$errors"
                    @blur="v$.model.$touch"
                />
            </div>
            <div class="mt-6 w-full">
                <UiCheckboxInput
                    v-model="v$.isActive.$model"
                    name="isActive"
                    :label="$t('integration.integrations.openai.form.isActive')"
                />
            </div>
        </UiPanel>

        <UiCheckDirtyBeforeRouteLeave :dirty="v$.$anyDirty" />
    </form>
</template>
