/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    ScheduleState,
    ScheduleMutations,
    ScheduleActions,
    ScheduleGetters,
    ScheduleFilter,
    SortMenuItem,
    FilterItem,
} from '@/types';

const getDefaultState = () => {
    return {
        search: null,
        sort: [],
        page: 1,
        filter: {
            roles: [],
            isActive: null,
        },
    } as ScheduleState;
};

const state: ScheduleState = getDefaultState();

const mutations: MutationTree<ScheduleState> = {
    [ScheduleMutations.SET_SEARCH]: (state, search: string) => (state.search = search),
    [ScheduleMutations.SET_SORT]: (state, sort: SortMenuItem[]) => (state.sort = sort),
    [ScheduleMutations.SET_PAGE]: (state, page: number) => (state.page = page),
    [ScheduleMutations.SET_FILTER](state, filter: ScheduleFilter) {
        state.filter.isActive = filter.isActive;
    },
    [ScheduleMutations.CLEAR_FILTER](state, item?: FilterItem) {
        switch (item?.field) {
            case 'isActive':
                state.filter.isActive = getDefaultState().filter.isActive;
                break;
            default:
                state.filter.isActive = getDefaultState().filter.isActive;
        }
    },
};

const actions: ActionTree<ScheduleState, any> = {
    [ScheduleActions.SET_SEARCH]: ({ commit }, search: string) =>
        commit(ScheduleMutations.SET_SEARCH, search),
    [ScheduleActions.SET_SORT]: ({ commit }, sort: SortMenuItem[]) =>
        commit(ScheduleMutations.SET_SORT, sort),
    [ScheduleActions.SET_PAGE]: ({ commit }, page: number) =>
        commit(ScheduleMutations.SET_PAGE, page),
    [ScheduleActions.SET_FILTER]: ({ commit }, filter: ScheduleFilter) =>
        commit(ScheduleMutations.SET_FILTER, filter),
    [ScheduleActions.CLEAR_FILTER]: ({ commit }, item: FilterItem) =>
        commit(ScheduleMutations.CLEAR_FILTER, item),
};

const getters: GetterTree<ScheduleState, any> = {
    [ScheduleGetters.SEARCH]: (state): string | null => state.search,
    [ScheduleGetters.SORT]: (state): SortMenuItem[] => state.sort,
    [ScheduleGetters.PAGE]: (state): number => state.page,
    [ScheduleGetters.FILTER]: (state): ScheduleFilter => state.filter,
    [ScheduleGetters.DEFAULT_FILTER]: (): ScheduleFilter => getDefaultState().filter,
    [ScheduleGetters.ACTIVE_FILTERS](state): FilterItem[] {
        const filters: Array<FilterItem> = [];

        if (state.filter.isActive !== null) {
            filters.push({
                field: 'isActive',
                value: state.filter.isActive.value,
                label: state.filter.isActive.label,
            });
        }

        return filters;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
