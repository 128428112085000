import { App } from 'vue';
import { can } from '@/composables/useAuth';
import { Permission } from '@/types';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $window: Window;
        $can: (permission: Permission) => boolean;
        $analytics: void;
    }
}

const globalProperties = {
    install(app: App<Element>) {
        app.config.globalProperties.$window = window;
        app.config.globalProperties.$can = can;
    },
};

export default globalProperties;
