import { RouteRecordRaw } from 'vue-router';
import { validateIdAsPositiveIntegerGuard } from '@/router/routeGuards';
import { FormAction } from '@/types';
import SchedulePage from '@/views/pages/schedule/SchedulePage.vue';
import ScheduleDetailPage from '@/views/pages/schedule/ScheduleDetailPage.vue';
import ScheduleFormPage from '@/views/pages/schedule/ScheduleFormPage.vue';

const scheduleRouter: RouteRecordRaw = {
    name: 'Schedule',
    path: 'schedule',
    children: [
        {
            name: 'ListSchedule',
            path: '',
            component: SchedulePage,
        },
        {
            name: 'ViewScheduleById',
            path: ':id',
            component: ScheduleDetailPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            meta: { isLevelLocked: true },
        },
        {
            name: 'EditScheduleById',
            path: ':id/edit',
            component: ScheduleFormPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            props: { action: FormAction.EDIT },
            meta: { isLevelLocked: true },
        },
        {
            name: 'CreateSchedule',
            path: 'create',
            component: ScheduleFormPage,
            props: { action: FormAction.CREATE },
            meta: { isLevelLocked: true },
        },
    ],
};
export default scheduleRouter;
