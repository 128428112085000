import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useVuelidate } from '@vuelidate/core';
import { useI18n } from 'vue-i18n';

import { useUiStore } from '@/stores';
import { AnalyticsService, WordService } from '@/services';
import { toast, toastServiceError } from '@/services/core/notification';
import { goBack } from '@/composables/useNavigation';

import {
    CallParticipantType,
    DefaultServiceResult,
    FormAction,
    Permission,
    WordModel,
    WordServiceCreateResult,
} from '@/types';
import { WordFormState } from '@/types/form-state/WordFormState';
import { required, maxLength } from '@/utils/validators';


export default /*@__PURE__*/_defineComponent({
  __name: 'WordFormPage',
  props: {
    action: { type: String, default: FormAction.CREATE },
},
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const { t } = useI18n();

const props = __props;

const uiStore = useUiStore();

const formState = reactive<WordFormState>({
    word: null,
    callParticipant: null,
    isActive: true,
});

const rules = computed(() => ({
    word: { required, maxLength: maxLength(15) },
    callParticipant: { required },
    isActive: { required },
}));

const v$ = useVuelidate(rules, formState);

const participants = computed(() => [
    { value: CallParticipantType.All, label: t(`word.participant.${CallParticipantType.All}`) },
    { value: CallParticipantType.Agent, label: t(`word.participant.${CallParticipantType.Agent}`) },
    {
        value: CallParticipantType.Client,
        label: t(`word.participant.${CallParticipantType.Client}`),
    },
]);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchWord = async (id: number) => {
    setLoading(true);
    try {
        const { data } = await WordService.get<WordModel>(id);
        Object.assign(formState, data);
    } catch (err) {
        toastServiceError(err);
    } finally {
        setLoading(false);
    }
};

const handleServiceSuccess = (message: string, id: number) => {
    toast.success(message);
    v$.value.$reset();
    goBack({ name: 'ViewWordById', params: { id } });
};

const onSubmit = async () => {
    if (!(await v$.value.$validate())) return;

    setLoading(true);
    try {
        if (props.action === FormAction.EDIT) {
            const id = Number(route.params.id);
            const { data } = await WordService.update<DefaultServiceResult>(id, formState);
            handleServiceSuccess(data.message, id);
        } else {
            const { data } = await WordService.create<WordServiceCreateResult>(formState);
            AnalyticsService.trackingAction('NewWord');
            handleServiceSuccess(data.message, data.result.id);
        }
    } catch (err) {
        toastServiceError(err);
    } finally {
        setLoading(false);
    }
};

onMounted(() => {
    if (props.action === FormAction.EDIT) {
        fetchWord(Number(route.params.id));
    }
});

const __returned__ = { route, t, props, uiStore, formState, rules, v$, participants, setLoading, fetchWord, handleServiceSuccess, onSubmit, get FormAction() { return FormAction }, get Permission() { return Permission } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})