import { UserModel, DefaultServiceResult } from '@/types';

export interface UserServiceGetAllParams {
    page: number;
    per_page: number;
    order_by: string;
    is_active?: boolean;
    is_agent?: boolean;
    roles?: number[];
    search?: string;
}

export interface UserServiceCreateResult extends DefaultServiceResult {
    result: UserModel;
}

export interface UserServiceUpdatePasswordBody {
    password: string;
    token?: string;
}

export interface UserServiceCheckExtensionNumberBody {
    id: number | null;
    numberId: number | null;
    userId: number | null;
    name?: string;
    extension: number | null;
    toDelete: boolean;
}
