<script lang="ts" setup>
// Imports
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useFilterStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import RealtimeReportService from '@/services/api/RealtimeReportService';

import { formatSecToTime } from '@/utils/datetime';
import { applyPhoneMask } from '@/utils/number';

import { LevelModel, UserDeviceStatus } from '@/types';
import { RealtimeReportServiceGetAgentExtensionsResult } from '@/types/services/RealtimeReportService';

// State
const items = ref<RealtimeReportServiceGetAgentExtensionsResult[]>([]);
const loading = ref(false);

// Computed
const levelFilter = computed<LevelModel | null>(() => filterStore.level);

// Composables
const filterStore = useFilterStore();

// Methods
const fetchAgentExtensions = async () => {
    try {
        loading.value = true;
        const response =
            await RealtimeReportService.getAgentExtensions<
                RealtimeReportServiceGetAgentExtensionsResult[]
            >();
        items.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        loading.value = false;
    }
};

let interval: number;

// Watchers
watch(levelFilter, () => {
    items.value = [];
    clearInterval(interval);
    interval = setInterval(fetchAgentExtensions, 5000);
});

// Lifecycle
onMounted(() => {
    interval = setInterval(fetchAgentExtensions, 5000);
});
onUnmounted(() => {
    clearInterval(interval);
});
</script>

<template>
    <div class="relative flex flex-col">
        <h1 class="mb-2 text-lg font-semibold">
            {{ $t('report.realtime.detailsUsers.title') }}
        </h1>
        <UiPanel class="flex-1 overflow-y-auto" no-padding>
            <table class="min-w-full border-separate border-spacing-0">
                <thead>
                    <tr>
                        <th
                            scope="col"
                            class="sticky top-0 z-10 whitespace-nowrap border-b border-gray-300 bg-gray-200 py-1.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-800"
                        >
                            {{ $t('report.realtime.detailsUsers.extension') }}
                        </th>
                        <th
                            scope="col"
                            class="sticky top-0 z-10 max-h-4 whitespace-nowrap border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-800 backdrop-blur backdrop-filter sm:table-cell"
                        >
                            {{ $t('report.realtime.detailsUsers.user') }}
                        </th>
                        <th
                            scope="col"
                            class="sticky top-0 z-10 max-h-4 whitespace-nowrap border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-800 backdrop-blur backdrop-filter sm:table-cell"
                        >
                            {{ $t('report.realtime.detailsUsers.device') }}
                        </th>
                        <th
                            scope="col"
                            class="sticky top-0 z-10 max-h-4 whitespace-nowrap border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-center text-sm font-semibold text-gray-800 backdrop-blur backdrop-filter lg:table-cell"
                        >
                            {{ $t('report.realtime.detailsUsers.status') }}
                        </th>
                        <th
                            scope="col"
                            class="sticky top-0 z-10 max-h-4 whitespace-nowrap border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-800 backdrop-blur backdrop-filter lg:table-cell"
                        >
                            {{ $t('report.realtime.detailsUsers.numberClient') }}
                        </th>
                        <th
                            scope="col"
                            class="sticky top-0 z-10 max-h-4 whitespace-nowrap border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-800 backdrop-blur backdrop-filter lg:table-cell"
                        >
                            {{ $t('report.realtime.detailsUsers.quality') }}
                        </th>
                        <th
                            scope="col"
                            class="sticky top-0 z-10 max-h-4 whitespace-nowrap border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-800 backdrop-blur backdrop-filter lg:table-cell"
                        >
                            {{ $t('report.realtime.detailsUsers.type') }}
                        </th>
                        <th
                            scope="col"
                            class="sticky top-0 z-10 max-h-4 whitespace-nowrap border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-800 backdrop-blur backdrop-filter lg:table-cell"
                        >
                            {{ $t('report.realtime.detailsUsers.duration') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, idx) in items" :key="`queueDetailItem${idx}`">
                        <td
                            class="whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium"
                        >
                            {{ item.extension }}
                        </td>
                        <td
                            class="whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium"
                        >
                            {{ item.user }}
                        </td>
                        <td
                            class="whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium"
                        >
                            {{ $t(`core.device.${item.userDevice}`) }}
                        </td>
                        <td
                            :class="[
                                item.status == UserDeviceStatus.OFFLINE
                                    ? 'bg-danger-100 text-danger-700'
                                    : 'bg-success-50 text-success-700',
                            ]"
                            class="whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-center text-sm font-medium"
                        >
                            {{ $t(`report.realtime.device.status.${item.status}`) }}
                        </td>
                        <td
                            class="whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium"
                        >
                            {{ item.numberClient ? applyPhoneMask(item.numberClient) : '' }}
                        </td>
                        <td
                            class="whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium"
                        >
                            {{ item.quality }}
                        </td>
                        <td
                            class="whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium"
                        >
                            {{ item.type ? $t(`call.type.${item.type}`) : '' }}
                        </td>
                        <td
                            class="whitespace-nowrap border-b border-gray-200 px-3 py-1.5 text-sm font-medium"
                        >
                            {{ item.duration ? formatSecToTime(item.duration) : '' }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </UiPanel>

        <UiSpinner v-if="loading" class="theme-color absolute right-2 top-2 h-4 w-4" />
    </div>
</template>
