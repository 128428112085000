import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue';
import { PropType } from 'vue';

import { RealtimeStatisticsSummary } from '@/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'DataTotalize',
  props: {
    data: {
        type: Object as PropType<RealtimeStatisticsSummary>,
        required: true,
    },
    loading: {
        type: Boolean,
        required: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const metrics = computed(() => [
    { key: 'callQueue', label: 'report.realtime.callQueue' },
    {
        key: 'callInboundInProgress',
        label: 'report.realtime.callInboundInProgress',
    },
    {
        key: 'callOutboundInProgress',
        label: 'report.realtime.callOutboundInProgress',
    },
    { key: 'userOffline', label: 'report.realtime.devicesOffline' },
    { key: 'averageQuality', label: 'report.realtime.averageQuality' },
]);

const __returned__ = { props, metrics }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})