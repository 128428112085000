<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { IntegrationType } from '@/types';
import { PipedriveIntegrationFormPage, OpenAiIntegrationFormPage } from '@/views/pages/integration';

// Configuration object for integration types
const integrationConfig = {
    [IntegrationType.Pipedrive]: PipedriveIntegrationFormPage,
    [IntegrationType.OpenAi]: OpenAiIntegrationFormPage,
};

// Access the current route
const route = useRoute();

// Resolve the component based on the route parameter
const resolvedComponent = computed(() => {
    const type = route.params.type as IntegrationType;
    return integrationConfig[type];
});

// Combine route props with passed props
const routeProps = computed(() => ({
    ...route.params,
}));
</script>

<template>
    <component :is="resolvedComponent" v-bind="routeProps" />
</template>
