import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "space-y-4 p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-800" }
const _hoisted_3 = { class: "flex space-x-4" }
const _hoisted_4 = { class: "space-y-4" }
const _hoisted_5 = { class: "grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3" }
const _hoisted_6 = { class: "mb-1 font-semibold" }
const _hoisted_7 = { class: "mb-4 text-base font-semibold text-gray-800" }
const _hoisted_8 = { class: "grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3" }
const _hoisted_9 = { class: "mr-2" }
const _hoisted_10 = { class: "flex items-center text-sm text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiBackButton = _resolveComponent("UiBackButton")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!
  const _component_UiAuditStatusInfo = _resolveComponent("UiAuditStatusInfo")!
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _component_UiGravatar = _resolveComponent("UiGravatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('level.view.title')), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_UiBackButton, { fallbackTo: { name: 'ListLevel' } }),
          (_ctx.$can($setup.Permission.EDIT_LEVEL))
            ? (_openBlock(), _createBlock($setup["RouterLink"], {
                key: 0,
                to: {
                            name: 'EditLevelById',
                            params: {
                                id: $setup.route.params.id,
                            },
                        },
                class: "block hover:bg-gray-50"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_UiButton, {
                    variant: "primary",
                    "text-variant": "white"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Edit')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"]))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_UiPanel, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('level.labels.name')), 1 /* TEXT */),
              _createElementVNode("p", null, _toDisplayString($setup.level?.name), 1 /* TEXT */)
            ])
          ]),
          _createVNode(_component_UiAuditStatusInfo, {
            "model-value": $setup.level,
            class: "bt-1 mt-4 border-t border-gray-300 pt-2"
          }, null, 8 /* PROPS */, ["model-value"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_UiPanel, null, {
        default: _withCtx(() => [
          _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t('level.view.section.users.title')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.level?.users, (user) => {
              return (_openBlock(), _createBlock(_component_UiPanel, {
                key: user.id,
                class: "flex bg-white p-2",
                "no-padding": ""
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_UiGravatar, {
                      email: user.email,
                      name: user.name,
                      class: "h-12 w-12 rounded-full ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50"
                    }, null, 8 /* PROPS */, ["email", "name"])
                  ]),
                  _createElementVNode("div", null, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can($setup.Permission.VIEW_USER) ? $setup.RouterLink : 'p'), {
                      to: {
                                    name: 'ViewUserById',
                                    params: {
                                        id: user?.id,
                                    },
                                },
                      class: "text-primary truncate text-sm font-medium"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(user?.name), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])),
                    _createElementVNode("p", _hoisted_10, [
                      _createVNode($setup["MailIcon"], {
                        class: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
                        "aria-hidden": "true"
                      }),
                      _createTextVNode(" " + _toDisplayString(user?.email), 1 /* TEXT */)
                    ])
                  ])
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]),
        _: 1 /* STABLE */
      })
    ])
  ]))
}