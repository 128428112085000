import Vuex from 'vuex';

import agentProductivityPerAgentReportStore from '@/store/modules/agentProductivityPerAgent.report.store';
import agentProductivityPerDayReportStore from '@/store/modules/agentProductivityPerDay.report.store';
import authStore from '@/store/modules/auth.store';
import billingReportStore from '@/store/modules/billing.report.store';
import callPlayerStore from '@/store/modules/callPlayer.store';
import callReportStore from '@/store/modules/call.report.store';
import callStore from '@/store/modules/call.store';
import connectFlowAudioStore from '@/store/modules/connectFlowAudio.store';
import connectFlowStore from '@/store/modules/connectFlow.store';
import connectFlowSurvey from '@/store/modules/connectFlowSurvey.store';
import contactCenterPerAgentReportStore from '@/store/modules/contactCenterPerAgent.report.store';
import contactCenterPerDayReportStore from '@/store/modules/contactCenterPerDay.report.store';
import contactStore from '@/store/modules/contact.store';
import integrationLogStore from '@/store/modules/integrationLog.store';
import levelStore from '@/store/modules/level.store';
import meetingStore from '@/store/modules/meeting.store';
import navigationStore from '@/store/modules/navigation.store';
import numberStore from '@/store/modules/number.store';
import permissionStore from '@/store/modules/permission.store';
import queueStore from '@/store/modules/queue.store';
import scheduleStore from '@/store/modules/schedule.store';
import spamNumberStore from '@/store/modules/spamNumber.store';
import userLabelStore from '@/store/modules/userLabel.store';
import userStore from '@/store/modules/user.store';
import wordStore from '@/store/modules/word.store';

export default new Vuex.Store({
    modules: {
        agentProductivityPerAgentReportStore: agentProductivityPerAgentReportStore,
        agentProductivityPerDayReportStore: agentProductivityPerDayReportStore,
        auth: authStore,
        billingReportStore: billingReportStore,
        call: callStore,
        callPlayer: callPlayerStore,
        callReportStore: callReportStore,
        connectFlowAudio: connectFlowAudioStore,
        connectFlowStore: connectFlowStore,
        connectFlowSurvey: connectFlowSurvey,
        contactCenterPerAgentReportStore: contactCenterPerAgentReportStore,
        contactCenterPerDayReportStore: contactCenterPerDayReportStore,
        contactStoreStore: contactStore,
        integrationLog: integrationLogStore,
        level: levelStore,
        meetingStore: meetingStore,
        navigation: navigationStore,
        number: numberStore,
        permission: permissionStore,
        queue: queueStore,
        schedule: scheduleStore,
        spamNumberStore: spamNumberStore,
        user: userStore,
        userLabel: userLabelStore,
        word: wordStore,
    },
});
