import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { SortMenuItem } from '@/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserLabelListSort',
  props: {
    modelValue: {
        type: Array<SortMenuItem>,
    },
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const { t } = useI18n();

const sortMenuItems = ref([
    {
        label: t('userLabel.sortMenu.items.label'),
        key: 'label',
    },
] as SortMenuItem[]);

const props = __props;

const emit = __emit;

const updateValue = (value: string) => {
    emit('update:modelValue', value);
};

const __returned__ = { t, sortMenuItems, props, emit, updateValue }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})