import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, ref, Ref } from 'vue';
import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { email, maxLength, required, requiredIf } from '@/utils/validators';
import { AxiosResponse } from 'axios';

import router from '@/router';
import store from '@/store';
import { useUiStore } from '@/stores';
import AuthService from '@/services/api/AuthService';
import { UserService } from '@/services';
import { toastServiceError } from '@/services/core/notification';

import {
    AuthActions,
    AuthGetters,
    AuthServiceLoginResult,
    LoginFormState,
    UserModel,
} from '@/types';

enum FormType {
    LOGIN = 'LOGIN',
    RECOVER = 'RECOVER',
    SENT_RECOVER = 'SENT_RECOVER',
}


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginPage',
  setup(__props, { expose: __expose }) {
  __expose();

const uiStore = useUiStore();

const formState = reactive<LoginFormState>({
    email: '',
    password: '',
    rememberMe: true,
});

const showFormType = ref<FormType>(FormType.LOGIN);

const user = computed<UserModel>(() => store.getters[AuthGetters.USER]);

const rules = computed<ValidationArgs<LoginFormState>>(() => ({
    email: {
        email,
        required,
        maxLength: maxLength(250),
    },
    password: {
        required: requiredIf(() => showFormType.value === FormType.LOGIN),
    },
    rememberMe: {},
}));

const v$: Ref<Validation<ValidationArgs<LoginFormState>>> = useVuelidate(rules, formState);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const handleFormValidation = () => v$.value.$validate();

const handleLoginSuccess = async (response: AxiosResponse<AuthServiceLoginResult>) => {
    await store.dispatch(AuthActions.LOGIN, response.data);

    if (!user.value.lastLogin) {
        await router.push({ name: 'UpdatePassword' });
        return;
    }

    await router.push('/');
};

const onSubmitLogin = async () => {
    if (!(await handleFormValidation())) return;

    try {
        await setLoading(true);
        const response = await AuthService.login(formState);
        await handleLoginSuccess(response);
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const onSubmitResetPassword = async () => {
    if (!(await handleFormValidation())) return;

    try {
        await setLoading(true);
        await UserService.recoveryPassword(formState);
        showFormType.value = FormType.SENT_RECOVER;
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const __returned__ = { FormType, uiStore, formState, showFormType, user, rules, v$, setLoading, handleFormValidation, handleLoginSuccess, onSubmitLogin, onSubmitResetPassword }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})