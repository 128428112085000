import { defineComponent as _defineComponent } from 'vue'
import { PropType } from 'vue';
import type { Component } from 'vue';
import { Permission } from '@/types';
import { ArrowRightIcon } from '@heroicons/vue/solid';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiDashboardLink',
  props: {
    routeName: {
        type: String,
        required: true,
    },
    permission: {
        type: String as PropType<Permission>,
        required: false,
    },
    title: {
        type: String,
        required: true,
    },
    description: {
        type: String,
        required: true,
    },
    icon: {
        type: [Object, String, Function] as PropType<string | Component>,
        required: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();



const __returned__ = { get ArrowRightIcon() { return ArrowRightIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})