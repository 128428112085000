import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, Ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AxiosResponse } from 'axios';

import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { required, maxLength } from '@/utils/validators';

import { useUiStore } from '@/stores';
import { goBack } from '@/composables/useNavigation';
import { LevelService } from '@/services';
import { toast, toastServiceError } from '@/services/core/notification';

import { Permission, FormAction, LevelModel, LevelFormState } from '@/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'LevelFormPage',
  props: {
    action: { type: String, default: FormAction.CREATE },
},
  setup(__props, { expose: __expose }) {
  __expose();

const uiStore = useUiStore();
const route = useRoute();

const props = __props;

const formState = reactive<LevelFormState>({
    name: null,
    isActive: true,
});

const rules = computed<ValidationArgs<LevelFormState>>(() => ({
    name: { required, maxLength: maxLength(500) },
    isActive: { required },
}));

const v$: Ref<Validation<ValidationArgs<LevelFormState>>> = useVuelidate(rules, formState);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchLevel = async (id: number) => {
    try {
        setLoading(true);
        const { data } = await LevelService.get<LevelModel>(id);
        formState.name = data.name;
        formState.isActive = data.isActive;
    } catch (e) {
        toastServiceError(e);
    } finally {
        setLoading(false);
    }
};

const onSubmit = async () => {
    const isFormValid = await v$.value.$validate();
    if (!isFormValid) return;

    setLoading(true);

    const handleServiceSuccess = (message: string, id: number) => {
        toast.success(message);
        v$.value.$reset();
        goBack({ name: 'ViewLevelById', params: { id } });
    };

    try {
        if (props.action === FormAction.EDIT) {
            const id = Number(route.params.id);
            const { data }: AxiosResponse = await LevelService.update(id, formState);
            handleServiceSuccess(data.message, id);
        } else {
            const { data }: AxiosResponse = await LevelService.create(formState);
            handleServiceSuccess(data.message, data.result.id);
        }
    } catch (e) {
        toastServiceError(e);
    } finally {
        setLoading(false);
    }
};

onMounted(async () => {
    if (props.action === FormAction.EDIT) {
        const levelId = Number(route.params.id);
        if (!isNaN(levelId)) fetchLevel(levelId);
    }
});

const __returned__ = { uiStore, route, props, formState, rules, v$, setLoading, fetchLevel, onSubmit, get Permission() { return Permission }, get FormAction() { return FormAction } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})