import { ListFilters, MultiSelectOption } from '@/types';

export interface ScheduleFilter {
    isActive: MultiSelectOption | null;
}

export interface ScheduleState extends ListFilters {
    filter: ScheduleFilter;
}

export enum ScheduleMutations {
    SET_SEARCH = 'schedule_mutation_setSearch',
    SET_SORT = 'schedule_mutation_setSort',
    SET_PAGE = 'schedule_mutation_setPage',
    SET_FILTER = 'schedule_mutation_setFilter',
    CLEAR_FILTER = 'schedule_mutation_clearFilter',
}

export enum ScheduleActions {
    SET_SEARCH = 'schedule_action_setSearch',
    SET_SORT = 'schedule_action_setSort',
    SET_PAGE = 'schedule_action_setPage',
    SET_FILTER = 'schedule_action_setFilter',
    CLEAR_FILTER = 'schedule_action_clearFilter',
}

export enum ScheduleGetters {
    SEARCH = 'schedule_getter_search',
    SORT = 'schedule_getter_sort',
    PAGE = 'schedule_getter_page',
    DEFAULT_FILTER = 'schedule_getter_defaultFilter',
    FILTER = 'schedule_getter_filter',
    ACTIVE_FILTERS = 'schedule_getter_activeFilters',
}
