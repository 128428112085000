import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiPanel = _resolveComponent("UiPanel")!

  return (_openBlock(), _createBlock(_component_UiPanel, { "no-padding": "" }, {
    default: _withCtx(() => [
      _createVNode($setup["VChart"], {
        class: "chart",
        option: $setup.props.option,
        autoresize: $setup.props.autoresize
      }, null, 8 /* PROPS */, ["option", "autoresize"])
    ]),
    _: 1 /* STABLE */
  }))
}