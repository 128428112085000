<script lang="ts" setup>
import { computed } from 'vue';
import { EChartsOption } from 'echarts';
import orderBy from 'lodash.orderby';
import { colord } from 'colord';
import { ChartDataItem } from '@/types';
import { CallbackDataParams } from 'echarts/types/dist/shared';

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    data: {
        type: Array<ChartDataItem<number>>,
        required: true,
    },
});

const callMediasOptions = computed<EChartsOption>(() => {
    const orderedMedias = orderBy(props.data, 'value', 'desc');
    const baseColor = colord('#0FA5E8');

    const data = orderedMedias.map((m, index) => {
        return {
            value: m.value,
            name: m.name,
            itemStyle: {
                color: baseColor.lighten(index / orderedMedias.length / 2.5).toHex(),
            },
        };
    });

    const eChartsOption: EChartsOption = {
        title: {
            text: props.title,
            left: '14px',
            top: '14px',
            textStyle: {
                fontSize: '16px',
                fontWeight: 'bold',
                color: '#555',
            },
        },
        tooltip: {
            trigger: 'item',
        },
        legend: {
            type: 'scroll',
            width: '90%',
            height: '100%',
            bottom: '10px',
            left: 'center',
            itemHeight: 10,
            itemWidth: 10,
            icon: 'circle',
            textStyle: {
                fontSize: '11px',
                lineHeight: 14,
                rich: {
                    a: {
                        align: 'center', // Alinha o texto da legenda horizontalmente ao meio
                        verticalAlign: 'bottom', // Alinha o texto da legenda verticalmente ao meio
                    },
                    b: {
                        align: 'center', // Alinha o valor numérico horizontalmente ao meio
                        verticalAlign: 'bottom', // Alinha o valor numérico verticalmente ao meio
                    },
                },
            },
            selectedMode: true,
            formatter: (name: string) => {
                const value = data.find((d) => d.name == name)?.value || 0;
                return '{a|' + name + '} {b|(' + value + ')}';
            },
        },
        series: [
            {
                type: 'pie',
                radius: ['20%', '55%'],
                data,
                label: {
                    show: true,
                    // textStyle: {
                    //   fontSize: "11px",
                    // },
                    formatter(param: CallbackDataParams) {
                        return param.name + ' (' + (param.percent || 0) + '%)';
                    },
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                    },
                },
            },
        ],
    };
    return eChartsOption;
});
</script>

<template>
    <UiChart :option="callMediasOptions" />
</template>
