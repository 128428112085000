import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ml-2 hidden sm:block" }
const _hoisted_2 = { class: "py-1" }
const _hoisted_3 = { class: "flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock($setup["Menu"], {
    as: "div",
    class: "relative"
  }, {
    default: _withCtx(() => [
      _createVNode($setup["MenuButton"], { class: "inline-flex rounded bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow ring-1 ring-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-opacity-50" }, {
        default: _withCtx(() => [
          _createVNode($setup["SwitchVerticalIcon"], {
            class: "h-5 w-5 text-gray-400",
            "aria-hidden": "true"
          }),
          _createElementVNode("span", _hoisted_1, _toDisplayString($setup.props.label || _ctx.$t('core.actions.Sort')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode($setup["MenuItems"], { class: "absolute right-0 z-10 mt-2 w-56 origin-top-right rounded bg-white shadow ring-1 ring-gray-300 focus:outline-none" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item, index) => {
              return (_openBlock(), _createBlock($setup["MenuItem"], { key: index }, {
                default: _withCtx(({ active }) => [
                  _createElementVNode("div", {
                    class: _normalizeClass([
                            active ? 'bg-gray-100 text-gray-800' : 'text-gray-700',
                            'group flex items-center px-4 py-2 text-sm',
                        ]),
                    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
                  }, [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(item.label), 1 /* TEXT */),
                    _withDirectives(_createVNode($setup["ArrowCircleDownIcon"], {
                      class: _normalizeClass([[
                                $setup.props.modelValue.find(
                                    (m) =>
                                        m.key == item.key &&
                                        m.direction == $setup.SortMenuItemDirection.ASC,
                                )
                                    ? 'text-primary hover:text-gray-300'
                                    : 'hover:text-primary text-gray-400',
                            ], "h-6 w-6 cursor-pointer"]),
                      "aria-hidden": "true",
                      onClick: ($event: any) => ($setup.handleItemClick(item, $setup.SortMenuItemDirection.ASC))
                    }, null, 8 /* PROPS */, ["class", "onClick"]), [
                      [_directive_tippy, _ctx.$t('sort.ascending')]
                    ]),
                    _withDirectives(_createVNode($setup["ArrowCircleUpIcon"], {
                      class: _normalizeClass([[
                                $setup.props.modelValue.find(
                                    (m) =>
                                        m.key == item.key &&
                                        m.direction == $setup.SortMenuItemDirection.DESC,
                                )
                                    ? 'text-primary hover:text-gray-300'
                                    : 'hover:text-primary text-gray-400',
                            ], "h-6 w-6 cursor-pointer"]),
                      "aria-hidden": "true",
                      onClick: ($event: any) => ($setup.handleItemClick(item, $setup.SortMenuItemDirection.DESC))
                    }, null, 8 /* PROPS */, ["class", "onClick"]), [
                      [_directive_tippy, _ctx.$t('sort.descending')]
                    ])
                  ], 2 /* CLASS */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}