import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { MenuAlt2Icon } from '@heroicons/vue/solid';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import { QueueService } from '@/services';
import { toast, toastServiceError } from '@/services/core/notification';

import {
    FilterItem,
    Permission,
    QueueActions,
    QueueFilter,
    QueueGetters,
    QueueModel,
    QueueServiceGetAllParams,
    SortMenuItem,
} from '@/types';
import { QueueListItem, QueueListSort, QueueListFilters } from '@/views/pages/queue/components';

const perPage = 10;


export default /*@__PURE__*/_defineComponent({
  __name: 'QueuePage',
  setup(__props, { expose: __expose }) {
  __expose();

const queues = ref<QueueModel[]>([]);
const count = ref(0);
const route = useRoute();
const router = useRouter();
const filterStore = useFilterStore();
const uiStore = useUiStore();

const search = computed({
    get: () => store.getters[QueueGetters.SEARCH],
    set: (value: string) => store.dispatch(QueueActions.SET_SEARCH, value),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[QueueGetters.SORT],
    set: (value: SortMenuItem[]) => store.dispatch(QueueActions.SET_SORT, value),
});

const level = computed(() => filterStore.level);

const currentPage = computed({
    get: () => store.getters[QueueGetters.PAGE],
    set: (value: number) => store.dispatch(QueueActions.SET_PAGE, value),
});

const filter: QueueFilter = store.getters[QueueGetters.FILTER];

const activeFilters = computed<FilterItem[]>(() => store.getters[QueueGetters.ACTIVE_FILTERS]);

const orderBy = computed(() => sortMenuSelected.value.map((s) => s.direction + s.key).join(','));

watch([currentPage], () => {
    fetchQueues();
});

watch([level, search, filter, orderBy], () => {
    if (currentPage.value === 1) fetchQueues();
    else currentPage.value = 1;
});

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getParams = (): QueueServiceGetAllParams => ({
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    is_active: filter.isActive !== null ? (filter.isActive.value as boolean) : undefined,
    search: search.value,
});

const fetchQueues = async () => {
    setLoading(true);
    try {
        const { data, headers } = await QueueService.getAll<QueueModel[]>({
            params: getParams(),
        });
        queues.value = data;
        count.value = parseInt(headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const setIsActive = async (item: QueueModel, isActive: boolean) => {
    setLoading(true);
    try {
        const { data } = await (
            isActive
                ? (id: number) => QueueService.activate(id)
                : (id: number) => QueueService.inactivate(id)
        )(item.id);
        toast.success(data.message);
        await fetchQueues();
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

const handleRemoveSortClick = (item: SortMenuItem) => {
    const index = sortMenuSelected.value.indexOf(item);
    if (index > -1) sortMenuSelected.value.splice(index, 1);
};

const handleRemoveFilterClick = (item: FilterItem) =>
    store.dispatch(QueueActions.CLEAR_FILTER, item);

const handleActivateClick = (item: QueueModel) => setIsActive(item, true);
const handleInactivateClick = (item: QueueModel) => setIsActive(item, false);

onMounted(() => {
    if (route.query.clearStateOnSetup) {
        search.value = '';
        sortMenuSelected.value = [];
        currentPage.value = 1;
        router.replace({ query: {} });
    }

    fetchQueues();
});

const __returned__ = { queues, count, perPage, route, router, filterStore, uiStore, search, sortMenuSelected, level, currentPage, filter, activeFilters, orderBy, setLoading, getParams, fetchQueues, setIsActive, handleRemoveSortClick, handleRemoveFilterClick, handleActivateClick, handleInactivateClick, get MenuAlt2Icon() { return MenuAlt2Icon }, get Permission() { return Permission }, get QueueListItem() { return QueueListItem }, get QueueListSort() { return QueueListSort }, get QueueListFilters() { return QueueListFilters } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})