import { CallType, CallStatus, QueueTimeStats } from '@/types';

export interface QueueTimeReportServiceGetInfoParams {
    startDate: string;
    endDate: string;
    type: CallType | null;
    status: CallStatus | null;
    user: number | null;
    number: string | null;
    serviceTimeRange: string | null;
    userLabel: number | null;
}

interface QueueTimePerDay {
    date: string;
    ranges: string[];
    rangeColor: string[];
    timeRange: number[];
    averageDuration: number;
    averageTimeAvailable?: number;
    averageTotalIdleTime?: number;
    averageIdleTime?: number;
}

interface QueueTimePerWeekDay {
    day: number;
    averageDuration: number;
    ranges: string[];
    rangeColor: string[];
    timeRange: number[];
}

export interface QueueTimeReportServiceGetInfoResult {
    stats: QueueTimeStats;
    queuePerDay: QueueTimePerDay[];
    queuePerWeekDay: QueueTimePerWeekDay[];
    queuePerHour: number[];
    queueRangePorHourOfDay: {
        ranges: string[];
        rangeColor: string[];
        data: number[][];
    };
}
