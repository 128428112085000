/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import router from '@/router';
import { useFilterStore } from '@/stores';
import {
    AuthActions,
    AuthGetters,
    AuthMutations,
    AuthServiceLoginResult,
    AuthState,
    CompanyModel,
    UserIntegrationType,
    UserModel,
    Permission,
} from '@/types';

const state: AuthState = {
    user: undefined,
    company: undefined,
};

const mutations: MutationTree<AuthState> = {
    [AuthMutations.SET_USER](state, user: UserModel) {
        state.user = user;
    },
    [AuthMutations.SET_COMPANY](state, company: CompanyModel) {
        state.company = company;
    },
};

const actions: ActionTree<AuthState, any> = {
    [AuthActions.LOGIN]({ commit }, loginResult: AuthServiceLoginResult) {
        const filterStore = useFilterStore();

        const { user, level, company } = loginResult;

        commit(AuthMutations.SET_USER, user);
        commit(AuthMutations.SET_COMPANY, company);
        filterStore.setLevel(level);
        localStorage.setItem('level', btoa(JSON.stringify(level)));

        if (loginResult.jwt) {
            localStorage.setItem('token', loginResult.jwt);
        }
    },
    [AuthActions.LOGOUT]({ commit }) {
        commit(AuthMutations.SET_USER, null);
        commit(AuthMutations.SET_COMPANY, null);
        localStorage.removeItem('token');
        localStorage.removeItem('level');
        localStorage.removeItem('locale');
        router.push('/login');
    },
};

const getters: GetterTree<AuthState, any> = {
    [AuthGetters.USER]: (state) => state.user,
    [AuthGetters.USER_COMPANY]: (state) => state.company,
    [AuthGetters.USER_LEVELS]: (state) => state.user?.levels,
    [AuthGetters.USER_ROLES]: (state) => state.user?.roles,
    [AuthGetters.USER_PERMISSIONS]: (state) => {
        const permissions: Permission[] = [];

        const roles = state.user?.roles || [];
        for (let i = 0; i < roles.length; i++) {
            const rolePermissions = roles[i].permissions || [];
            for (let ii = 0; ii < rolePermissions.length; ii++) {
                const rolePermission = rolePermissions[ii].id as Permission;
                if (
                    Object.values(Permission).includes(rolePermission) &&
                    !permissions.includes(rolePermission)
                )
                    permissions.push(rolePermission);
            }
        }

        return permissions;
    },
    [AuthGetters.CHAT_BOT_INTEGRATION]: (state) => {
        if (state.user !== undefined && state.user?.integrations !== undefined)
            return state.user?.integrations.find((integration) => {
                return (
                    integration.integration === UserIntegrationType.CHAT_BOT &&
                    integration.levelId === state.user?.currentLevel?.id
                );
            });
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
