import { ListFilters, MultiSelectOption, RoleModel } from '@/types';

export interface UserFilter {
    roles: RoleModel[];
    isActive: MultiSelectOption | null;
}
export interface UserState extends ListFilters {
    filter: UserFilter;
}

export enum UserMutations {
    SET_SEARCH = 'user_setSearch',
    SET_SORT = 'user_setSort',
    SET_PAGE = 'user_setPage',
    SET_FILTER = 'user_setFilter',
    CLEAR_FILTER = 'user_clearFilter',
}

export enum UserActions {
    SET_SEARCH = 'user_setSearch',
    SET_SORT = 'user_setSort',
    SET_PAGE = 'user_setPage',
    SET_FILTER = 'user_setFilter',
    CLEAR_FILTER = 'user_clearFilter',
    FIRST_LOGIN = 'user_first_login',
}

export enum UserGetters {
    SEARCH = 'user_search',
    SORT = 'user_sort',
    PAGE = 'user_page',
    FILTER = 'user_filter',
    DEFAULT_FILTER = 'user_defaultFilter',
    ACTIVE_FILTERS = 'user_activeFilters',
}
