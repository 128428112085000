import { DefaultServiceResult, IntegrationModel } from '@/types';

export interface IntegrationServiceGetLogsParams {
    page?: number;
    per_page?: number;
    order_by?: string;
    status?: string;
    search?: string;
}

export interface IntegrationServiceCreateResult extends DefaultServiceResult {
    result: IntegrationModel;
}
