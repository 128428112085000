import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, ref, watch, onMounted } from 'vue';
import { useVuelidate, ValidationArgs } from '@vuelidate/core';
import { VaultService } from '@/services';
import { toastServiceError } from '@/services/core/notification';
import { getAudioFormat } from '@/utils/audio';
import { TrashIcon } from '@heroicons/vue/solid';

import { required, requiredIf, maxLength } from '@/utils/validators';
import { ConnectFlowSurveyQuestionFormState, MultiSelectOption } from '@/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowSurveyQuestionForm',
  props: {
    modelValue: {
        type: Object as () => ConnectFlowSurveyQuestionFormState,
        required: true,
    },
    index: {
        type: Number,
        required: true,
    },
    showRemove: {
        type: Boolean,
        required: true,
    },
},
  emits: ['update:modelValue', 'remove'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const audioPreview = ref<string | null>(null);

const scoreOptions = [
    { label: '0-5', value: '0-5' },
    { label: '0-9', value: '0-9' },
    { label: '0-10', value: '0-10' },
    { label: '1-2', value: '1-2' },
    { label: '1-5', value: '1-5' },
    { label: '1-9', value: '1-9' },
    { label: '1-10', value: '1-10' },
] as MultiSelectOption[];

const formState = reactive<ConnectFlowSurveyQuestionFormState>(props.modelValue);

const rules = computed<ValidationArgs<ConnectFlowSurveyQuestionFormState>>(() => ({
    label: { required, maxLength: maxLength(500) },
    rangeAnswer: { required },
    audioFile: {},
    textToAudio: {
        required: requiredIf(() => !formState.audioFilePath),
        maxLength: maxLength(500),
    },
    audioFilePath: {
        required: requiredIf(() => formState.textToAudio?.length === 0),
    },
}));

const v$ = useVuelidate(rules, formState);

__expose({
    validate: () => v$.value.$validate(),
});

// Load audio preview for edit mode
const loadAudio = async () => {
    if (formState.audioFilePath) {
        try {
            const { data } = await VaultService.getAudioAsset(formState.audioFilePath);
            const blob = new Blob([data], { type: getAudioFormat(formState.audioFilePath) });
            formState.audioFile = new File([blob], formState.audioFilePath, {
                type: blob.type,
                lastModified: Date.now(),
            });
        } catch (e) {
            toastServiceError(e);
        }
    }
};

// Handle file selection
watch(
    () => formState.audioFile,
    () => {
        if (formState.audioFile) {
            formState.audioFilePath = formState.audioFile.name;
            audioPreview.value = URL.createObjectURL(formState.audioFile);
        } else {
            formState.audioFilePath = '';
            audioPreview.value = null;
        }
    },
);

watch(
    formState,
    () => {
        emit('update:modelValue', formState);
    },
    {
        deep: true,
    },
);

onMounted(loadAudio);

const __returned__ = { props, emit, audioPreview, scoreOptions, formState, rules, v$, loadAudio, get TrashIcon() { return TrashIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})