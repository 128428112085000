import { RouteRecordRaw } from 'vue-router';
import { validateIdAsPositiveIntegerGuard } from '@/router/routeGuards';
import { FormAction } from '@/types';
import LevelPage from '@/views/pages/level/LevelPage.vue';
import LevelDetailPage from '@/views/pages/level/LevelDetailPage.vue';
import LevelFormPage from '@/views/pages/level/LevelFormPage.vue';

const levelRouter: RouteRecordRaw = {
    name: 'Level',
    path: 'level',
    children: [
        {
            name: 'ListLevel',
            path: '',
            component: LevelPage,
            meta: { isLevelLocked: true },
        },
        {
            name: 'ViewLevelById',
            path: ':id',
            component: LevelDetailPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            meta: { isLevelLocked: true },
        },
        {
            name: 'EditLevelById',
            path: ':id/edit',
            component: LevelFormPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            props: { action: FormAction.EDIT },
            meta: { isLevelLocked: true },
        },
        {
            name: 'CreateLevel',
            path: 'create',
            component: LevelFormPage,
            props: { action: FormAction.CREATE },
            meta: { isLevelLocked: true },
        },
    ],
};
export default levelRouter;
