<script lang="ts" setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { SortMenuItem } from '@/types';

const { t } = useI18n();

const sortMenuItems = ref([
    {
        label: t('number.sortMenu.items.number'),
        key: 'number',
    },
    {
        label: t('number.sortMenu.items.city'),
        key: 'city',
    },
    {
        label: t('number.sortMenu.items.state'),
        key: 'state',
    },
] as SortMenuItem[]);

const props = defineProps({
    modelValue: {
        type: Array<SortMenuItem>,
    },
});

const emit = defineEmits(['update:modelValue']);

const updateValue = (value: string) => {
    emit('update:modelValue', value);
};
</script>

<template>
    <UiSortMenu
        :items="sortMenuItems"
        :model-value="props.modelValue"
        @update:modelValue="updateValue"
    />
</template>
