import { RouteRecordRaw } from 'vue-router';
import { validateIdAsPositiveIntegerGuard } from '@/router/routeGuards';
import { FormAction } from '@/types';
import ConnectFlowSurveyPage from '@/views/pages/connect-flow-survey/ConnectFlowSurveyPage.vue';
import ConnectFlowSurveyDetailPage from '@/views/pages/connect-flow-survey/ConnectFlowSurveyDetailPage.vue';
import ConnectFlowSurveyFormPage from '@/views/pages/connect-flow-survey/ConnectFlowSurveyFormPage.vue';

const surveyRouter: RouteRecordRaw = {
    name: 'ConnectFlowSurvey',
    path: 'survey',
    children: [
        {
            name: 'ListConnectFlowSurvey',
            path: '',
            component: ConnectFlowSurveyPage,
        },
        {
            name: 'ViewConnectFlowSurveyById',
            path: ':id',
            component: ConnectFlowSurveyDetailPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            meta: { isLevelLocked: true },
        },
        {
            name: 'EditConnectFlowSurveyById',
            path: ':id/edit',
            component: ConnectFlowSurveyFormPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            props: { action: FormAction.EDIT },
            meta: { isLevelLocked: true },
        },
        {
            name: 'CreateConnectFlowSurvey',
            path: 'create',
            component: ConnectFlowSurveyFormPage,
            props: { action: FormAction.CREATE },
            meta: { isLevelLocked: true },
        },
    ],
};
export default surveyRouter;
