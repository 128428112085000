<script lang="ts" setup>
import { computed } from 'vue';
import store from '@/store';
import router from '@/router';
import { CogIcon, LogoutIcon } from '@heroicons/vue/outline';
import BaseTemplate from '@/views/templates/BaseTemplate.vue';
import { NavigationGetters } from '@/types';

const sideNavigationItems = computed(() => store.getters[NavigationGetters.SIDE_NAVIGATION_ITEMS]);
const showLogout = computed(() => location.host !== 'embed.mobcall.com');

const handleConfigClick = () => {
    router.push('/settings');
};

const handleLogoutClick = () => {
    router.push('/logout');
};
</script>

<template>
    <BaseTemplate :navigation-items="sideNavigationItems" :router-key="$route.fullPath">
        <template #headerActions>
            <button class="mr-2 hover:text-white" @click="handleConfigClick">
                <CogIcon class="h-6 w-6" aria-hidden="true" />
            </button>
            <button v-if="showLogout" class="hover:text-white" @click="handleLogoutClick">
                <LogoutIcon class="h-6 w-6" aria-hidden="true" />
            </button>
        </template>
    </BaseTemplate>
</template>
