import { AgentProductivityPerDayReportServiceGetInfoResult } from '@/types';
import BaseService from '@/services/api/BaseService';
import { AxiosRequestConfig } from 'axios';

class AgentProductivityPerDayReportService extends BaseService<AgentProductivityPerDayReportServiceGetInfoResult> {
    getInfo(config?: AxiosRequestConfig) {
        return this.http.get<AgentProductivityPerDayReportServiceGetInfoResult>(
            `${this.route}`,
            config,
        );
    }
}

export default new AgentProductivityPerDayReportService('reports/agent-productivity-per-day');
