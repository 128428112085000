import { FullAudit, IsActiveFlag, UserModel, QueueUserModel } from '@/types';

export interface QueueModel extends IsActiveFlag, FullAudit {
    id: number;
    levelId: number;
    name: string;
    strategy: string;
    fileAudio: string;
    users?: UserModel[];
    queueUsers?: QueueUserModel[];
}
