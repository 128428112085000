import { FullAudit, IsActiveFlag, NumberModel, UserModel } from '@/types';

export interface LevelModel extends IsActiveFlag, FullAudit {
    id: number;
    name: string;
    meeting?: boolean;
    trackingId?: string;
    root?: boolean;
    users?: UserModel[];
    usersCount?: number;
    numbers?: NumberModel[];
    numbersCount?: number;
}
