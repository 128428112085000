import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiButton = _resolveComponent("UiButton")!
  const _directive_tippy = _resolveDirective("tippy")!

  return _withDirectives((_openBlock(), _createBlock(_component_UiButton, {
    "text-variant": "primary",
    "no-border": "",
    "no-shadow": "",
    class: "hover:bg-white/50",
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.goBack($props.fallbackTo)))
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Back')), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  })), [
    [_directive_tippy, _ctx.$t('core.actions.Back')]
  ])
}