<script lang="ts" setup>
import { computed, useSlots } from 'vue';

const props = defineProps({
    removable: {
        type: Boolean,
        default: false,
    },
    variant: {
        type: String,
        default: 'gray',
    },
    textVariant: {
        type: String,
    },
    size: {
        type: String,
        default: 'md',
    },
    disabled: Boolean,
});

const variantExceptions = ['inherit', 'current', 'transparent', 'black', 'white'];
const getVariantSuffix = (variant: string, darker = 400) => {
    let variantColor: string = variant;
    let variantIntensity: number | undefined = undefined;

    if (!variantExceptions.includes(variant)) {
        const variantContainsIntensity = variant.lastIndexOf('-') != -1;

        if (variantContainsIntensity) {
            variantIntensity = parseInt(
                variant.substring(variant.lastIndexOf('-') + 1, variant.length),
            );

            variantColor = variant.substring(0, variant.lastIndexOf('-'));
        } else variantIntensity = 200;
    }

    const getSuffix = (intensity: number | undefined, variation: number) => {
        if (intensity == undefined) return '';
        else {
            let suffix = intensity + variation;
            if (suffix > 900) suffix = 900;
            else if (suffix < 50) suffix = 50;

            return '-' + suffix;
        }
    };

    return {
        default: variantColor + getSuffix(variantIntensity, 0),
        darker: variantColor + getSuffix(variantIntensity, darker),
        lighter: variantColor + getSuffix(variantIntensity, -100),
    };
};

const textVariant = computed(() =>
    props.textVariant !== undefined ? props.textVariant : props.variant,
);

const classes = computed(() => {
    let sizes: string[] = [];

    switch (props.size) {
        case 'xs':
            sizes = ['px-1', 'py-1', 'text-xs'];
            break;
        case 'sm':
            sizes = ['px-2.5', 'py-1.5', 'text-xs'];
            break;
        case 'md':
            sizes = ['px-3', 'py-2', 'text-sm'];
            break;
        case 'lg':
            sizes = ['px-4', 'py-2', 'text-sm'];
            break;
        case 'xl':
            sizes = ['px-4', 'py-2', 'text-base'];
            break;
        case '2xl':
            sizes = ['px-6', 'py-3', 'text-base'];
            break;
        default:
            break;
    }

    const variantWithSuffixes = getVariantSuffix(props.variant);
    const textVariantWithSuffixes = getVariantSuffix(textVariant.value);

    let classes: (string | object)[] = [
        `bg-${variantWithSuffixes.default}`,
        `text-${textVariantWithSuffixes.darker}`,
        {
            disabled: props.disabled,
        },
    ];

    classes = classes.concat(sizes);

    return classes;
});

const numberClasses = computed(() => {
    const variantWithSuffixes = getVariantSuffix(props.variant, 100);
    const textVariantWithSuffixes = getVariantSuffix(textVariant.value);

    return `bg-${variantWithSuffixes.darker} text-${textVariantWithSuffixes.darker}`;
});

const emit = defineEmits(['remove']);
const slots = useSlots();
</script>

<template>
    <span
        class="inline-flex items-center rounded-full px-1 py-0.5 text-xs font-medium"
        :class="classes"
    >
        <slot></slot>
        <span
            v-if="slots.number"
            class="ml-1 inline-flex items-center rounded-full px-1 text-xs font-medium"
            :class="numberClasses"
        >
            <slot name="number"></slot>
        </span>
        <button
            v-if="props.removable"
            type="button"
            class="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full focus:bg-gray-500 focus:text-white focus:outline-none"
            @click="emit('remove')"
        >
            <span class="sr-only">{{ $t('core.actions.Remove') }}</span>
            <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
            </svg>
        </button>
    </span>
</template>
