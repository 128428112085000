<script lang="ts" setup>
import { computed, reactive, watch } from 'vue';
import { useVuelidateWithLogging } from '@/composables/useVuelidateWithLogging';
import { TrashIcon } from '@heroicons/vue/solid';

import { email, required, maxLength } from '@/utils/validators';
import { ContactEmailFormState } from '@/types';

const props = defineProps({
    modelValue: {
        type: Object as () => ContactEmailFormState,
        required: true,
    },
    index: {
        type: Number,
        required: true,
    },
});

const emit = defineEmits(['update:modelValue', 'remove']);

const formState = reactive<ContactEmailFormState>(props.modelValue);

const rules = computed(() => ({
    email: { email, required, maxLength: maxLength(50) },
}));

const { v$, $validateWithLogging } = useVuelidateWithLogging(rules, formState);

defineExpose({
    validate: () => $validateWithLogging(),
});

watch(
    formState,
    () => {
        emit('update:modelValue', formState);
    },
    {
        deep: true,
    },
);
</script>

<template>
    <div class="mt-2 flex items-center space-x-2 space-y-2 py-2">
        <UiTextInput
            v-model="v$.email.$model"
            name="email"
            type="text"
            :label="$t('contact.form.labels.email')"
            class="flex-1"
            :disabled="!!formState?.id"
            :errors="v$.email.$errors"
            @blur="v$.email.$touch"
        />

        <UiActionButton
            variant="danger"
            v-tippy="$t('contact.form.actions.removeEmail')"
            class="pt-4"
            @click="emit('remove', index)"
        >
            <TrashIcon class="h-5 w-5" />
        </UiActionButton>
    </div>
</template>
