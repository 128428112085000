<script lang="ts" setup>
import { computed, onMounted, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useVuelidate, ValidationArgs } from '@vuelidate/core';

import store from '@/store';
import { useUiStore } from '@/stores';
import { UserService } from '@/services';
import { toastServiceError } from '@/services/core/notification';

import {
    required,
    maxLength,
    minLength,
    containsUppercase,
    containsLowercase,
    containsNumber,
    containsSpecial,
    sameAs,
} from '@/utils/validators';

import { UpdatePasswordFormState, UserModel, AuthGetters } from '@/types';

const imageLoginPath = '/img/slogan-login.png';
const logoPath = '/img/logomob.png';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const uiStore = useUiStore();

const formState = reactive<UpdatePasswordFormState>({
    password: '',
    confirmPassword: '',
});

const token = computed(() => route.query.token as string | undefined);

const rules = computed<ValidationArgs<UpdatePasswordFormState>>(() => ({
    password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(250),
        containsUppercase,
        containsLowercase,
        containsNumber,
        containsSpecial,
    },
    confirmPassword: {
        required,
        sameAsPassword: sameAs(formState.password, t('user.labels.password')),
    },
}));

const v$ = useVuelidate(rules, formState);

const user = computed<UserModel>(() => store.getters[AuthGetters.USER]);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const handlePasswordUpdate = async () => {
    const isFormValid = await v$.value.$validate();
    if (!isFormValid) return;

    try {
        await setLoading(true);

        if (token.value) {
            await UserService.resetPassword({
                token: token.value,
                password: formState.password,
            });
        } else {
            await UserService.firstLogin(formState);
        }

        await router.push('/');
    } catch (error) {
        toastServiceError(error);
        if (token.value) {
            await router.push({ name: 'Login' });
        }
    } finally {
        await setLoading(false);
    }
};

onMounted(() => {
    if (user.value?.lastLogin) {
        router.push({ name: 'Home' });
    }
});
</script>

<template>
    <div class="grid w-full grid-cols-1 sm:grid-cols-2">
        <div class="bg-primary relative hidden w-full items-center justify-center sm:flex">
            <img
                :src="imageLoginPath"
                class="h-[150px] xl:h-[250px]"
                :alt="$t('authentication.logoAlt')"
            />
        </div>

        <div
            class="flex w-full flex-col items-center bg-gray-50 px-8 pt-20 sm:items-start sm:justify-center sm:pt-0 lg:px-28 xl:px-40"
        >
            <img class="mb-8 w-[225px]" :src="logoPath" :alt="$t('authentication.logoAlt')" />

            <h3 class="mb-8 text-2xl font-semibold text-gray-700">
                {{ $t('authentication.updatePassword.title') }}
            </h3>

            <p class="mb-4 text-sm">
                {{ $t('authentication.updatePassword.description') }}
            </p>

            <ul class="mb-10 list-decimal pl-6 text-sm">
                <li>{{ $t('authentication.updatePassword.rule.size') }}</li>
                <li>{{ $t('authentication.updatePassword.rule.upperCase') }}</li>
                <li>{{ $t('authentication.updatePassword.rule.lowerCase') }}</li>
                <li>{{ $t('authentication.updatePassword.rule.number') }}</li>
                <li>{{ $t('authentication.updatePassword.rule.special') }}</li>
            </ul>

            <form class="w-full space-y-4" @submit.prevent="handlePasswordUpdate">
                <UiTextInput
                    v-model="formState.password"
                    name="password"
                    type="password"
                    :label="$t('authentication.updatePassword.label.password')"
                    inputClass="text-base px-4 py-3"
                    autocomplete="password"
                    :errors="v$.password?.$errors"
                    @blur="v$.password?.$touch"
                />

                <UiTextInput
                    v-model="formState.confirmPassword"
                    name="confirmPassword"
                    type="password"
                    :label="$t('authentication.updatePassword.label.confirmPassword')"
                    inputClass="text-base px-4 py-3"
                    autocomplete="off"
                    :errors="v$.confirmPassword?.$errors"
                    @blur="v$.confirmPassword?.$touch"
                />

                <div>
                    <UiButton type="submit" variant="primary" class="w-full">
                        <span class="block">{{ $t('login.form.signIn') }}</span>
                    </UiButton>
                </div>
            </form>
        </div>
    </div>
</template>
