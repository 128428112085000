import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter, RouterLink } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { LockClosedIcon, PencilIcon } from '@heroicons/vue/solid';

import store from '@/store';
import { useUiStore } from '@/stores';
import { toastServiceError } from '@/services/core/notification';
import RoleService from '@/services/api/RoleService';
import PermissionService from '@/services/api/PermissionService';

import {
    RoleModel,
    PermissionModel,
    Permission,
    PermissionGetters,
    PermissionActions,
} from '@/types';

const perPage = 20;


export default /*@__PURE__*/_defineComponent({
  __name: 'PermissionPage',
  setup(__props, { expose: __expose }) {
  __expose();

const roles: Ref<RoleModel[]> = ref([]);
const permissions: Ref<PermissionModel[]> = ref([]);
const orderBy: Ref<string> = ref('permission.description');
const search = computed<string>({
    get: () => store.getters[PermissionGetters.SEARCH],
    set: (search: string) => store.dispatch(PermissionActions.SET_SEARCH, search),
});

const currentPage = computed<number>({
    get: () => store.getters[PermissionGetters.PAGE],
    set: (page: number) => store.dispatch(PermissionActions.SET_PAGE, page),
});

const descriptionalizedPermissions = computed<PermissionModel[]>(() => {
    return permissions.value.map((p) => {
        p.description = t('permission.permissions.' + p.id);
        return p;
    });
});

const filteredPermissions = computed<PermissionModel[]>(() => {
    return descriptionalizedPermissions.value.filter((p) => {
        return !search.value || p.description?.toLowerCase().includes(search.value.toLowerCase());
    });
});

const paginatedPermissions = computed<PermissionModel[]>(() => {
    return filteredPermissions.value.slice(
        (currentPage.value - 1) * perPage,
        currentPage.value * perPage,
    );
});

const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const uiStore = useUiStore();

watch([search, orderBy], () => {
    currentPage.value = 1;
});

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchRolesAndPermissions = async () => {
    try {
        await setLoading(true);
        const [roleResponse, permissionResponse] = await Promise.all([
            RoleService.getAll<RoleModel[]>(),
            PermissionService.getAll<PermissionModel[]>(),
        ]);

        roles.value = roleResponse.data;
        permissions.value = permissionResponse.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const handleAddRemovePermissionClick = async (role: RoleModel, permission: PermissionModel) => {
    try {
        setLoading(true);
        const permissionRole = permission.roles.find((r: RoleModel) => r.id == role.id);

        if (permissionRole) {
            await RoleService.removePermission(role.id, permission.id);
            permission.roles.splice(permission.roles.indexOf(permissionRole), 1);
        } else {
            await RoleService.addPermission(role.id, permission.id);
            permission.roles.push(role);
        }
    } catch (error) {
        toastServiceError(error);
    } finally {
        setLoading(false);
    }
};

onMounted(() => {
    if (route.query.clearStateOnSetup) {
        search.value = '';
        currentPage.value = 1;
        router.replace({ query: {} });
    }

    fetchRolesAndPermissions();
});

const __returned__ = { roles, permissions, orderBy, perPage, search, currentPage, descriptionalizedPermissions, filteredPermissions, paginatedPermissions, route, router, t, uiStore, setLoading, fetchRolesAndPermissions, handleAddRemovePermissionClick, get RouterLink() { return RouterLink }, get LockClosedIcon() { return LockClosedIcon }, get PencilIcon() { return PencilIcon }, get Permission() { return Permission } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})