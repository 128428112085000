import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "font-medium text-gray-600" }
const _hoisted_2 = { class: "sm:col-span-12" }
const _hoisted_3 = { class: "mb-2 block text-sm font-medium text-gray-800" }
const _hoisted_4 = { class: "mt-6 flex flex-col justify-end" }
const _hoisted_5 = { class: "mt-4 flex flex-col justify-end pb-1.5" }
const _hoisted_6 = { class: "mt-6 grid grid-cols-2 place-content-around gap-4 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiNode = _resolveComponent("UiNode")!
  const _component_UiMultiselect = _resolveComponent("UiMultiselect")!
  const _component_UiCheckboxInput = _resolveComponent("UiCheckboxInput")!
  const _component_UiTextArea = _resolveComponent("UiTextArea")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_UiRightPanel = _resolveComponent("UiRightPanel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_UiNode, {
      nodeId: $props.node.id,
      title: _ctx.$t('connectFlow.node.transferToExtension.title'),
      icon: $setup.ReplyIcon,
      showActions: "",
      onEditNode: _cache[0] || (_cache[0] = ($event: any) => ($setup.showForm = true)),
      onRemoveNode: $setup.removeNode,
      class: _normalizeClass($setup.isFormCorrect ? '' : 'border-red-300 shadow-red-300')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", null, _toDisplayString($setup.nodeText), 1 /* TEXT */)
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["nodeId", "title", "icon", "class"]),
    _createVNode(_component_UiRightPanel, {
      modelValue: $setup.showForm,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.showForm) = $event)),
      title: _ctx.$t('connectFlow.node.transferToExtension.title'),
      icon: $setup.ReplyIcon
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          autocomplete: "off",
          class: "flex flex-col gap-3",
          onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('connectFlow.node.transferToExtension.select')), 1 /* TEXT */),
            _createVNode(_component_UiMultiselect, {
              name: "extension",
              modelValue: $setup.v$.extension.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.v$.extension.$model) = $event)),
              options: $setup.extensionsOptions,
              label: "number",
              "value-prop": "number",
              errors: $setup.v$.extension.$silentErrors,
              disabled: $setup.formState.userInput ?? false,
              primitive: "",
              "can-clear": ""
            }, {
              "option-label": _withCtx(({ option }) => [
                _createTextVNode(_toDisplayString(option.number) + " - " + _toDisplayString(option.user.name), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "options", "errors", "disabled"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_UiCheckboxInput, {
              modelValue: $setup.v$.userInput.$model,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.v$.userInput.$model) = $event)),
              name: "isActive",
              label: _ctx.$t('connectFlow.node.transferToExtension.userInput'),
              class: "h-6",
              errors: $setup.v$.userInput.$errors
            }, null, 8 /* PROPS */, ["modelValue", "label", "errors"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.v$.userInput.$errors, (error) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "text-danger bg-danger-50 z-10 w-full px-2 py-2 text-sm shadow",
                key: error.$uid
              }, [
                _createElementVNode("p", null, _toDisplayString(error.$response ? error.$response : error.$message), 1 /* TEXT */)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_UiTextArea, {
              label: _ctx.$t('connectFlow.node.transferToExtension.whisper'),
              name: "whisper",
              modelValue: $setup.v$.whisper.$model,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.v$.whisper.$model) = $event))
            }, null, 8 /* PROPS */, ["label", "modelValue"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_UiButton, {
              variant: "white",
              "text-variant": "gray-700",
              onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.showForm = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Cancel')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_UiButton, {
              type: "submit",
              variant: "primary",
              "text-variant": "white"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Apply')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ])
        ], 32 /* NEED_HYDRATION */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "title", "icon"])
  ], 64 /* STABLE_FRAGMENT */))
}