import { ChartDataItem } from '@/types';

export interface BaseChartConfig {
    title: string;
    category: Array<string>;
    series: Array<ChartDataItem<number[]>>;
}

export interface BarChartConfig extends BaseChartConfig {
    xAxisLabelFormatter?: (value: number | string) => string;
    yAxisLabelFormatter?: (value: number | string) => string;
}
