import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-4 flex text-lg font-medium text-blue-900" }
const _hoisted_2 = { class: "font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiPanel = _resolveComponent("UiPanel")!

  return (_openBlock(), _createBlock(_component_UiPanel, null, {
    default: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, [
        _createVNode($setup["AnnotationIcon"], { class: "mr-1 h-6 w-6" }),
        _createTextVNode(" " + _toDisplayString($props.survey?.name), 1 /* TEXT */)
      ]),
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.survey.questions, (question) => {
          return (_openBlock(), _createElementBlock("li", {
            key: question.id,
            class: "text-sm"
          }, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(question.label) + " (" + _toDisplayString(question.rangeAnswer) + "): ", 1 /* TEXT */),
            _createTextVNode(" " + _toDisplayString(question.answer?.value), 1 /* TEXT */)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    _: 1 /* STABLE */
  }))
}