<script lang="ts" setup>
import { ButtonHTMLAttributes, PropType, computed } from 'vue';

const props = defineProps({
    htmlType: {
        type: String as PropType<ButtonHTMLAttributes['type']>,
        default: 'button',
    },
    variant: {
        type: String,
        default: 'transparent',
    },
    borderVariant: {
        type: String,
        default: '', // will fallback to variant if empty
    },
    ringVariant: {
        type: String,
        default: '', // will fallback to variant if empty
    },
    textVariant: {
        type: String,
        default: 'white',
    },
    noPadding: {
        type: Boolean,
        default: false,
    },
    noRounded: {
        type: Boolean,
        default: false,
    },
    noShadow: {
        type: Boolean,
        default: false,
    },
    noBorder: {
        type: Boolean,
        default: false,
    },
    noRing: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    to: {
        type: Object,
    },
});

const classes = computed(() => {
    const variantExceptions = ['inherit', 'current', 'transparent', 'black', 'white'];

    let sizes: string[] = [];

    if (!props.noPadding) {
        sizes = ['px-3', 'py-2', 'text-sm'];
    }

    const getVariantSuffix = (variant: string) => {
        let variantColor: string = variant;
        let variantIntensity: number | undefined = undefined;

        if (!variantExceptions.includes(variant)) {
            const variantContainsIntensity = variant.lastIndexOf('-') != -1;

            if (variantContainsIntensity) {
                variantIntensity = parseInt(
                    variant.substring(variant.lastIndexOf('-') + 1, variant.length),
                );
                variantColor = variant.substring(0, variant.lastIndexOf('-'));
            }
        }

        const getSufix = (intensity = 500, variation: number) => {
            let sufix = intensity + variation;
            if (sufix > 900) sufix = 900;
            else if (sufix < 50) sufix = 50;

            return '-' + sufix;
        };

        const variantSuffix = {
            default: variantColor,
            darker: variantColor,
            lighter: variantColor,
        };

        if (!variantExceptions.includes(variantColor)) {
            variantSuffix.default = variantSuffix.default + getSufix(variantIntensity, 0);
            variantSuffix.darker = variantSuffix.darker + getSufix(variantIntensity, 100);
            variantSuffix.lighter = variantSuffix.lighter + getSufix(variantIntensity, -100);
        }

        return variantSuffix;
    };

    const variantWithSuffixes = getVariantSuffix(props.variant);
    const textVariantWithSuffixes = getVariantSuffix(props.textVariant);

    let classes: (string | object)[] = [
        `bg-${variantWithSuffixes.default}`,
        `text-${textVariantWithSuffixes.default}`,
    ];

    if (props.disabled) {
        classes = ['bg-gray-400', 'text-gray-300', 'ring-0'];
    } else {
        if (!variantExceptions.includes(props.variant)) {
            classes = classes.concat([`hover:bg-${variantWithSuffixes.darker}`]);
        } else if (props.variant === 'white') {
            classes = classes.concat(['hover:bg-gray-50']);
        }
    }

    if (!variantExceptions.includes(props.textVariant)) {
        classes = classes.concat([`hover:text-${textVariantWithSuffixes.darker}`]);
    }

    if (!props.noPadding) {
        classes = classes.concat(sizes);
    }

    if (!props.noRounded) {
        classes.push('rounded');
    }

    if (!props.noShadow) {
        classes.push('shadow');
    }

    if (!props.noBorder) {
        let resolvedBorderVariant = props.borderVariant || props.variant;
        if (!props.borderVariant && props.variant === 'white') {
            resolvedBorderVariant = 'gray-300';
        }
        classes.push('ring-1', `ring-${resolvedBorderVariant}`);
    }

    if (!props.noRing) {
        let resolvedRingVariant = props.ringVariant || props.variant;
        if (!props.ringVariant && props.variant === 'white') {
            resolvedRingVariant = 'gray-300';
        }
        classes.push(`focus:ring-${resolvedRingVariant}`, 'focus:ring-2', 'focus:ring-opacity-50');
    }

    return classes;
});
</script>

<template>
    <component
        :is="to === undefined ? 'button' : 'RouterLink'"
        :type="htmlType"
        :to="to"
        class="flex items-center justify-center font-medium focus:outline-none"
        :class="classes"
        :disabled="disabled"
    >
        <slot></slot>
    </component>
</template>
