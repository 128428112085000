import {
    CallTimeRange,
    CallStatus,
    CallType,
    NumberModel,
    UserModel,
    MultiSelectOption,
    UserLabelModel,
} from '@/types';

export interface ContactCenterReportFilter {
    type: MultiSelectOption<CallType> | null;
    status?: MultiSelectOption<CallStatus> | null;
    user?: UserModel | null;
    number?: NumberModel | null;
    serviceTimeRange?: MultiSelectOption<CallTimeRange> | null;
    userLabel: UserLabelModel | null;
}
export interface ContactCenterReportState {
    filter: ContactCenterReportFilter;
}

export enum ContactCenterReportMutations {
    SET_FILTER = 'contactCenterReport_setFilter',
    CLEAR_FILTER = 'contactCenterReport_clearFilter',
}

export enum ContactCenterReportActions {
    SET_FILTER = 'contactCenterReport_setFilter',
    CLEAR_FILTER = 'contactCenterReport_clearFilter',
}

export enum ContactCenterReportGetters {
    DEFAULT_FILTER = 'contactCenterReport_defaultFilter',
    FILTER = 'contactCenterReport_filter',
    ACTIVE_FILTERS = 'contactCenterReport_activeFilters',
}
