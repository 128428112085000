<script lang="ts" setup>
import { PropType } from 'vue';
import { UserLabelModel, Permission } from '@/types';
import { UsersIcon } from '@heroicons/vue/solid';

import { CheckCircleIcon, PencilAltIcon, XCircleIcon } from '@heroicons/vue/outline';

const props = defineProps({
    modelValue: {
        type: Object as PropType<UserLabelModel>,
        required: true,
    },
});

const emit = defineEmits(['activate', 'inactivate']);

const handleActivateClick = () => emit('activate', props.modelValue);
const handleInactivateClick = () => emit('inactivate', props.modelValue);
</script>

<template>
    <UiPanel class="space-y-2 hover:bg-white">
        <div class="flex items-center justify-between">
            <div class="flex-1">
                <component
                    :is="$can(Permission.VIEW_USER_LABEL) ? 'RouterLink' : 'span'"
                    :to="{
                        name: 'ViewUserLabelById',
                        params: {
                            id: modelValue.id,
                        },
                    }"
                    class="text-md text-primary truncate font-medium"
                >
                    {{ modelValue.label }}
                </component>
                <UiIsActiveBadge :model-value="modelValue" class="ml-2 text-xs" />
            </div>
            <div v-if="$can(Permission.EDIT_USER_LABEL)" class="flex space-x-1">
                <UiActionButton
                    v-if="modelValue.isActive"
                    v-tippy="$t('core.actions.Inactivate')"
                    variant="danger"
                    @click="handleInactivateClick"
                >
                    <span class="sr-only"> {{ $t('core.actions.Inactivate') }} </span>
                    <XCircleIcon class="h-6 w-6" aria-hidden="true" />
                </UiActionButton>
                <UiActionButton
                    v-else
                    v-tippy="$t('core.actions.Activate')"
                    variant="success"
                    @click="handleActivateClick"
                >
                    <span class="sr-only"> {{ $t('core.actions.Activate') }} </span>
                    <CheckCircleIcon class="h-6 w-6" aria-hidden="true" />
                </UiActionButton>
                <UiActionButton
                    v-tippy="$t('core.actions.Edit')"
                    variant="primary"
                    :to="{
                        name: 'EditUserLabelById',
                        params: {
                            id: modelValue.id,
                        },
                    }"
                >
                    <span class="sr-only"> {{ $t('core.actions.Edit') }} </span>
                    <PencilAltIcon class="h-6 w-6" aria-hidden="true" />
                </UiActionButton>
            </div>
        </div>
        <div class="sm:flex sm:justify-between">
            <div class="sm:flex">
                <p class="flex items-center text-sm text-gray-500">
                    <UsersIcon
                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                    />
                    {{
                        $t('number.list.agentCount', props.modelValue.users.length || 0, {
                            named: { n: props.modelValue.users.length },
                        })
                    }}
                </p>
            </div>
            <UiAuditInfo :model-value="modelValue" />
        </div>
    </UiPanel>
</template>
