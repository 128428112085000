<script lang="ts" setup>
import { computed, PropType } from 'vue';

import {
    CalendarIcon,
    ClockIcon,
    UserGroupIcon,
    PlayIcon,
    VideoCameraIcon,
    UserIcon,
} from '@heroicons/vue/solid';
import { MeetingModel, Permission, MeetingPlatformTypeNameMap } from '@/types';
import { formatDateAsString } from '@/utils/datetime';

const props = defineProps({
    modelValue: {
        type: Object as PropType<MeetingModel>,
        required: true,
    },
});

const participantsNames = computed(() =>
    props.modelValue.participants.map((p) => p.name).join(', '),
);

const formattedCreatedAt = computed(() => {
    return props.modelValue.createdAt
        ? formatDateAsString(props.modelValue.createdAt, 'DD/MM/YYYY HH:mm:ss')
        : '-';
});
</script>

<template>
    <div
        class="my-2 items-center rounded bg-gray-50 px-4 py-3 ring-1 ring-gray-300 hover:bg-white focus:ring-2 focus:ring-opacity-50"
    >
        <div class="mb-2 sm:mb-0">
            <span
                class="rounded border-gray-200 bg-gray-50 px-1.5 py-0.5 text-xs ring-1 ring-gray-300 focus:ring-2 focus:ring-opacity-50"
                >#{{ modelValue.id }}</span
            >
        </div>
        <div class="grid grid-cols-2 gap-3 lg:grid-cols-6">
            <div
                class="text-primary flex flex-wrap items-center space-x-1 truncate text-sm font-medium"
            >
                <span class="block w-full overflow-hidden truncate whitespace-normal md:w-auto">{{
                    modelValue.title
                }}</span>
            </div>
            <div></div>
            <div></div>
            <div class="flex flex-row text-sm lg:col-span-2">
                <UserGroupIcon class="mr-1 h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span class="text-xs font-bold text-gray-600"
                    >{{ $t('meeting.list.participants') + ':' }}
                </span>
                <span class="ml-1 block overflow-hidden truncate whitespace-normal">{{
                    participantsNames
                }}</span>
            </div>
            <div class="flex items-center lg:col-span-1 lg:justify-end">
                <UiButton
                    v-tippy="$t('core.actions.View')"
                    v-if="$can(Permission.VIEW_MEETING)"
                    variant="inherit"
                    text-variant="primary"
                    no-border
                    no-shadow
                    class="focus:ring-primary flex items-center border-transparent text-xs shadow-none"
                    :to="{
                        name: 'ViewMeetingById',
                        params: {
                            id: modelValue.id,
                        },
                    }"
                >
                    <PlayIcon class="mr-1 h-7 w-7" aria-hidden="true" />
                    <span>{{ $t('core.actions.View') }}</span>
                </UiButton>
            </div>
        </div>
        <div class="mt-2 grid grid-cols-2 gap-3 border-t pt-3 lg:grid-cols-6">
            <div class="flex items-center space-x-1 text-xs text-gray-600">
                <CalendarIcon class="mr-1 h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span class="truncate">{{ formattedCreatedAt }}</span>
            </div>
            <div class="flex items-center space-x-1 text-xs text-gray-600">
                <ClockIcon class="mr-1 h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span>{{ modelValue.speechDuration }} segundos</span>
            </div>
            <div class="flex items-center space-x-1 text-xs text-gray-600">
                <VideoCameraIcon class="mr-1 h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span>{{ MeetingPlatformTypeNameMap[modelValue.platform] }}</span>
            </div>
            <div class="flex items-center space-x-1 text-xs text-gray-600 lg:col-span-3">
                <UserIcon class="mr-1 h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span class="text-xs font-bold text-gray-600"
                    >{{ $t('meeting.list.recordedBy') }}:</span
                >
                <span>{{ modelValue.createdByUser?.name }}</span>
            </div>
        </div>
    </div>
</template>
