import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "space-y-4 p-4 sm:p-6 lg:p-8"
}
const _hoisted_2 = { class: "text-xl font-semibold text-gray-800" }
const _hoisted_3 = { class: "grid grid-cols-1 gap-4 sm:grid-cols-2" }
const _hoisted_4 = { class: "grid grid-cols-1 gap-4 sm:grid-cols-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiBackButton = _resolveComponent("UiBackButton")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!

  return ($setup.call !== null && !$setup.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_UiPageHeader, null, {
          info: _withCtx(() => [
            _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('core.entity.Call')), 1 /* TEXT */)
          ]),
          actions: _withCtx(() => [
            _createVNode(_component_UiBackButton, { fallbackTo: { name: 'CallList' } })
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["CallDetails"], {
          call: $setup.call,
          "show-view-button": false,
          "show-download-button": ""
        }, null, 8 /* PROPS */, ["call"]),
        _createVNode($setup["CallPlayer"], { call: $setup.call }, null, 8 /* PROPS */, ["call"]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode($setup["CallWords"], {
            call: $setup.call,
            participantType: 
                    $setup.call.type === $setup.CallType.INTERNAL
                        ? $setup.CallParticipantType.AgentA
                        : $setup.CallParticipantType.Agent
                
          }, null, 8 /* PROPS */, ["call", "participantType"]),
          _createVNode($setup["CallWords"], {
            call: $setup.call,
            participantType: 
                    $setup.call.type === $setup.CallType.INTERNAL
                        ? $setup.CallParticipantType.AgentB
                        : $setup.CallParticipantType.Client
                
          }, null, 8 /* PROPS */, ["call", "participantType"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.call.surveys, (survey) => {
            return (_openBlock(), _createBlock($setup["CallSurvey"], {
              key: survey.id,
              survey: survey
            }, null, 8 /* PROPS */, ["survey"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        ($setup.chatBotIntegration)
          ? (_openBlock(), _createBlock($setup["CallChatBot"], {
              key: 0,
              call: $setup.call
            }, null, 8 /* PROPS */, ["call"]))
          : _createCommentVNode("v-if", true)
      ]))
    : _createCommentVNode("v-if", true)
}