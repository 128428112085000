import { createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "grid w-full grid-cols-1 sm:grid-cols-2" }
const _hoisted_2 = { class: "flex w-full flex-col items-center bg-gray-50 px-8 pt-20 sm:items-start sm:justify-center sm:pt-0 lg:px-28 xl:px-40" }
const _hoisted_3 = {
  key: 0,
  class: "mb-8 text-2xl font-semibold text-gray-700"
}
const _hoisted_4 = { class: "mb-3 text-2xl font-semibold text-gray-700" }
const _hoisted_5 = { class: "mb-8 text-sm" }
const _hoisted_6 = { class: "mb-3 text-2xl font-semibold text-gray-700" }
const _hoisted_7 = { class: "mb-8 text-sm" }
const _hoisted_8 = { class: "flex items-center justify-between" }
const _hoisted_9 = { class: "text-sm" }
const _hoisted_10 = { class: "block" }
const _hoisted_11 = { class: "block" }
const _hoisted_12 = { key: 5 }
const _hoisted_13 = { class: "block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiTextInput = _resolveComponent("UiTextInput")!
  const _component_UiCheckboxInput = _resolveComponent("UiCheckboxInput")!
  const _component_UiButton = _resolveComponent("UiButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "bg-primary relative hidden w-full items-center justify-center sm:flex" }, [
      _createElementVNode("span", { class: "login-bg h-[150px] xl:h-[250px]" })
    ], -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_2, [
      _cache[6] || (_cache[6] = _createElementVNode("span", { class: "login-logo mb-8 w-[225px]" }, null, -1 /* HOISTED */)),
      _createCommentVNode(" Login Form Header "),
      ($setup.showFormType === $setup.FormType.LOGIN)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.$t('welcome')), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      _createCommentVNode(" Recover Password Header "),
      ($setup.showFormType === $setup.FormType.RECOVER)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t('forgotPassword.title')), 1 /* TEXT */),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('forgotPassword.description')), 1 /* TEXT */)
          ], 64 /* STABLE_FRAGMENT */))
        : _createCommentVNode("v-if", true),
      _createCommentVNode(" Password Recovery Sent Header "),
      ($setup.showFormType === $setup.FormType.SENT_RECOVER)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('forgotPassword.requested.title')), 1 /* TEXT */),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('forgotPassword.requested.description')), 1 /* TEXT */)
          ], 64 /* STABLE_FRAGMENT */))
        : _createCommentVNode("v-if", true),
      _createCommentVNode(" Login Form "),
      ($setup.showFormType === $setup.FormType.LOGIN)
        ? (_openBlock(), _createElementBlock("form", {
            key: 3,
            class: "w-full space-y-4",
            onSubmit: _withModifiers($setup.onSubmitLogin, ["prevent"])
          }, [
            _createVNode(_component_UiTextInput, {
              modelValue: $setup.formState.email,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formState.email) = $event)),
              name: "email",
              type: "text",
              label: _ctx.$t('login.form.emailAddress'),
              inputClass: "text-base px-4 py-3",
              autocomplete: "email",
              errors: $setup.v$.email.$errors,
              onBlur: $setup.v$.email.$touch
            }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"]),
            _createVNode(_component_UiTextInput, {
              modelValue: $setup.formState.password,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formState.password) = $event)),
              name: "password",
              type: "password",
              label: _ctx.$t('login.form.password'),
              inputClass: "text-base px-4 py-3",
              autocomplete: "password",
              errors: $setup.v$.password?.$errors,
              onBlur: $setup.v$.password?.$touch
            }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_UiCheckboxInput, {
                modelValue: $setup.formState.rememberMe,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formState.rememberMe) = $event)),
                name: "rememberMe",
                label: _ctx.$t('login.form.rememberMe'),
                class: "h-6"
              }, null, 8 /* PROPS */, ["modelValue", "label"]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", {
                  class: "text-primary hover:text-primary-darker cursor-pointer text-sm font-medium",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.showFormType = $setup.FormType.RECOVER))
                }, _toDisplayString(_ctx.$t('login.form.forgotYourPassword')), 1 /* TEXT */)
              ])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_UiButton, {
                type: "submit",
                variant: "primary",
                class: "w-full"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('login.form.signIn')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ], 32 /* NEED_HYDRATION */))
        : _createCommentVNode("v-if", true),
      _createCommentVNode(" Recover Password Form "),
      ($setup.showFormType === $setup.FormType.RECOVER)
        ? (_openBlock(), _createElementBlock("form", {
            key: 4,
            class: "w-full space-y-4",
            onSubmit: _withModifiers($setup.onSubmitResetPassword, ["prevent"])
          }, [
            _createVNode(_component_UiTextInput, {
              modelValue: $setup.formState.email,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.formState.email) = $event)),
              name: "email",
              type: "text",
              label: _ctx.$t('forgotPassword.form.emailAddress'),
              inputClass: "text-base px-4 py-3",
              autocomplete: "email",
              errors: $setup.v$.email.$errors,
              onBlur: $setup.v$.email.$touch
            }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"]),
            _createElementVNode("div", null, [
              _createVNode(_component_UiButton, {
                type: "submit",
                variant: "primary",
                class: "w-full"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('forgotPassword.form.resetPass')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ], 32 /* NEED_HYDRATION */))
        : _createCommentVNode("v-if", true),
      _createCommentVNode(" Recovery Sent Confirmation "),
      ($setup.showFormType === $setup.FormType.SENT_RECOVER)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createVNode(_component_UiButton, {
              variant: "primary",
              class: "w-full",
              onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.showFormType = $setup.FormType.LOGIN))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('forgotPassword.requested.action')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}