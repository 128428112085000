<script lang="ts" setup>
import { computed } from 'vue';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid';

const props = defineProps({
    modelValue: {
        type: Number,
        required: true,
    },
    count: {
        type: Number,
        required: true,
    },
    perPage: {
        type: Number,
        required: true,
    },
});

const pageCount = computed(() => {
    return Math.ceil(props.count / props.perPage);
});

const pages = computed(() => {
    const response: number[] = [];
    const current = props.modelValue;

    let start = current - 2 <= 0 ? 1 : current - 2;
    let end = current + 2 < pageCount.value ? current + 2 : pageCount.value;

    const diff = end - start;
    if (diff < 4 && end + (4 - diff) < pageCount.value) {
        end = end + (4 - diff);
    } else if (diff < 4 && start - (4 - diff) > 0) {
        start = start - (4 - diff);
    }

    for (let i = start; i <= end; i++) {
        response.push(i);
    }

    return response;
});

const paginationLabelData = computed(() => {
    let start = 1;
    let end = props.modelValue * props.perPage;
    const total = props.count;

    if (props.modelValue >= 1) start = props.modelValue * props.perPage - props.perPage + 1;

    if (props.modelValue == pageCount.value) end = props.count;

    return {
        start,
        end,
        total,
    };
});

const emit = defineEmits(['update:modelValue']);
function goTo(page: number) {
    if (props.modelValue !== page) {
        emit('update:modelValue', page);
    }
}
</script>
<template>
    <UiPanel class="flex items-center justify-center px-4 py-2" no-padding>
        <div class="hidden flex-1 sm:block">
            <p class="text-sm text-gray-700" v-if="count > 1">
                {{ $t('pagination.label', paginationLabelData) }}
            </p>
            <p class="text-sm text-gray-700" v-if="count === 1">
                {{
                    $t('pagination.labelOnePage', {
                        total: count,
                    })
                }}
            </p>
        </div>
        <div>
            <nav class="inline-flex -space-x-px rounded shadow" aria-label="Pagination">
                <span
                    @click="goTo(props.modelValue - 1)"
                    v-if="props.modelValue > 1"
                    class="relative inline-flex cursor-pointer items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                    <span class="sr-only">Previous</span>
                    <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
                </span>
                <template v-for="page in pages" :key="`page${page}`">
                    <span
                        @click="goTo(page)"
                        aria-current="page"
                        class="relative inline-flex items-center border bg-white px-4 py-2 text-sm font-medium"
                        :class="
                            page === props.modelValue
                                ? 'theme-border-color theme-color z-3'
                                : 'z-2 cursor-pointer border-gray-300 text-gray-500 hover:bg-gray-50'
                        "
                    >
                        {{ page }}
                    </span>
                </template>
                <span
                    @click="goTo(props.modelValue + 1)"
                    v-if="props.modelValue < pageCount"
                    class="relative inline-flex cursor-pointer items-center rounded-r-md border border-gray-300 bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                    <span class="sr-only">Next</span>
                    <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
                </span>
            </nav>
        </div>
    </UiPanel>
</template>
