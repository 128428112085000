<script lang="ts" setup>
import {
    GoTo,
    HangUp,
    OpenEdge,
    PlayAudio,
    ClientInfo,
    TransferToExtension,
    TransferToExternal,
    TransferToQueue,
    TransferToSurvey,
    TimeValidationNode,
} from '@/views/pages/connect-flow/components/flow/nodes';
import { computed } from 'vue';
import store from '@/store';
import { ConnectFlowModelingGetters, NodeType } from '@/types';

function getNodeComponent(component: string) {
    switch (component) {
        case NodeType.TIME_VALIDATION:
            return TimeValidationNode;
        case NodeType.OPEN_EDGE:
            return OpenEdge;
        case NodeType.PLAY_AUDIO:
            return PlayAudio;
        case NodeType.CLIENT_INFO:
            return ClientInfo;
        case NodeType.TRANSFER_EXTENSION:
            return TransferToExtension;
        case NodeType.TRANSFER_EXTERNAL:
            return TransferToExternal;
        case NodeType.TRANSFER_QUEUE:
            return TransferToQueue;
        case NodeType.TRANSFER_SURVEY:
            return TransferToSurvey;
        case NodeType.HANG_UP:
            return HangUp;
        case NodeType.GO_TO:
            return GoTo;
    }
}

const props = defineProps({
    nodeId: {
        type: String,
        required: true,
    },
});

const node = computed(() =>
    store.getters['flow/' + ConnectFlowModelingGetters.ELEMENT_BY_ID](props.nodeId),
);
</script>

<template>
    <template v-if="node">
        <component :is="getNodeComponent(node.data.component)" :node="node" />
    </template>
</template>
