import {
    UserModel,
    ExtensionFormState,
    UserServiceUpdatePasswordBody,
    AuthServiceLoginResult,
} from '@/types';
import BaseService from '@/services/api/BaseService';
import { AxiosRequestConfig } from 'axios';

class UserService extends BaseService<UserModel> {
    async checkExtensionNumber(siblings: ExtensionFormState, config?: AxiosRequestConfig) {
        if (siblings.number !== null && siblings.levelId !== null) {
            const { data } = await this.http.post(
                `${this.route}/is-number-extension-available`,
                siblings,
                config,
            );
            return data;
        } else {
            return true;
        }
    }
    async firstLogin(loginBody: UserServiceUpdatePasswordBody) {
        return this.http.put<AuthServiceLoginResult>(`${this.route}/first-login`, loginBody);
    }
    async resetPassword(loginBody: UserServiceUpdatePasswordBody) {
        return this.http.put<AuthServiceLoginResult>(`${this.route}/reset-password`, loginBody);
    }
    async recoveryPassword(loginBody: UserServiceUpdatePasswordBody) {
        return this.http.post<AuthServiceLoginResult>(`${this.route}/recovery-password`, loginBody);
    }
    async getCsvReport<ResponseT = Blob>(config?: AxiosRequestConfig) {
        return this.http.get<ResponseT>(`${this.route}/report/csv`, config);
    }
}

export default new UserService('users');
