import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "space-y-4 p-4 sm:px-6 sm:pt-6 lg:px-8 lg:pt-8 xl:pt-0" }
const _hoisted_2 = { class: "flex space-x-16" }
const _hoisted_3 = { class: "flex flex-1 flex-col space-y-4" }
const _hoisted_4 = { class: "my-4 text-2xl font-bold text-gray-800 xl:my-8 xl:text-3xl" }
const _hoisted_5 = { class: "xl:text-md flex-1 font-medium text-gray-500" }
const _hoisted_6 = { class: "text-primary" }
const _hoisted_7 = { class: "flex items-center text-xl font-semibold text-gray-700 xl:text-2xl" }
const _hoisted_8 = ["alt"]
const _hoisted_9 = { class: "grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiDashboardLink = _resolveComponent("UiDashboardLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('welcomeLogged')) + ", " + _toDisplayString($setup.user?.name) + ". ", 1 /* TEXT */),
        _createElementVNode("p", _hoisted_5, [
          _createTextVNode(_toDisplayString(_ctx.$t('dashboard.welcome')) + " ", 1 /* TEXT */),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('dashboard.welcomeAct')), 1 /* TEXT */)
        ]),
        _createElementVNode("h2", _hoisted_7, [
          _createVNode($setup["ReplyIcon"], {
            class: "text-secondary mr-1 h-8 w-8 rotate-180",
            "aria-hidden": "true"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('dashboard.gettingStarted')), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("img", {
        src: "/img/image-home-verde.png",
        class: "hidden h-[300px] xl:block",
        alt: _ctx.$t('welcomeLogged')
      }, null, 8 /* PROPS */, _hoisted_8)
    ]),
    _createElementVNode("div", _hoisted_9, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.links, (link, index) => {
        return _createVNode(_component_UiDashboardLink, {
          key: `dashboard-link-${index}`,
          routeName: link.routeName,
          permission: link.permission,
          title: link.title,
          description: link.description,
          icon: link.icon
        }, null, 8 /* PROPS */, ["routeName", "permission", "title", "description", "icon"])
      }), 64 /* STABLE_FRAGMENT */))
    ])
  ]))
}