import { CallModel } from '@/types';
import BaseService from '@/services/api/BaseService';
import { AxiosRequestConfig } from 'axios';

class RealtimeReportService<T> extends BaseService<CallModel> {
    getDataTotalize<T>(config?: AxiosRequestConfig) {
        return this.http.get<T>(`${this.route}`, config);
    }
    getAgentExtensions<ResponseT = T[]>(config?: AxiosRequestConfig) {
        return this.http.get<ResponseT>(`${this.route}/agent-extensions`, config);
    }
    getQueueCalls<ResponseT = T[]>(config?: AxiosRequestConfig) {
        return this.http.get<ResponseT>(`${this.route}/queue-calls`, config);
    }
}

export default new RealtimeReportService('reports/realtime');
