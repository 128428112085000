import { defineComponent as _defineComponent } from 'vue'
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'UiCheckDirtyBeforeRouteLeave',
  props: {
    dirty: {
        type: Boolean,
        default: false,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const props = __props;

onBeforeRouteLeave(() => {
    if (props.dirty) {
        const answer = window.confirm(t('core.messages.confirmRouteLeave'));
        if (!answer) return false;
    } else return true;
});

const __returned__ = { t, props }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})