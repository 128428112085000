import { Node } from '@vue-flow/core/dist/types/node';
import { Connection } from '@vue-flow/core/dist/types/connection';
import { NodeType, EdgeType, MultiSelectOption } from '@/types';

export interface VueFlowNodeDataModel {
    title?: string;
    description?: string;
    component: NodeType;
    related: MultiSelectOption | null;
    goToNode?: string;
    textToAudio?: string;
    callTo?: string[];
    redirectType?: string;
    userInput?: boolean;
    whisper?: string;
    hasErrors: boolean;
    flowType?: string;
}

export interface VueFlowNodeModel extends Node {
    parentId: string;
    sourceHandle?: string;
    data: VueFlowNodeDataModel;
}

export interface VueFlowConnectionDataModel {
    component: EdgeType;
    uraOption: number | null;
    waitUser: boolean;
    timerWait?: number;
    hasErrors: boolean;
    flowType?: string;
}
export interface VueFlowConnectionModel extends Connection {
    id: string;
    label?: string;
    type?: string;
    data?: VueFlowConnectionDataModel;
    sourceHandle?: string;
    markerEnd?: string;
}
