import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_2 = { class: "sr-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiAuditInfo = _resolveComponent("UiAuditInfo")!
  const _component_UiActionButton = _resolveComponent("UiActionButton")!
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock(_component_UiPanel, { class: "flex items-center justify-between hover:bg-white" }, {
    default: _withCtx(() => [
      _createVNode(_component_UiAuditInfo, {
        "model-value": $props.modelValue,
        "date-format": "LLL"
      }, null, 8 /* PROPS */, ["model-value"]),
      ($props.published)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode($setup["BookmarkIcon"], { class: "text-primary h-6 w-6" }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('connectFlow.labels.published')), 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true),
      _withDirectives((_openBlock(), _createBlock(_component_UiActionButton, {
        variant: "primary",
        to: {
                name: 'EditFlowHistoryById',
                params: {
                    id: $props.modelValue.connectFlowId,
                    historyId: $props.modelValue.id,
                },
            }
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('connectFlow.actions.editFlow')), 1 /* TEXT */),
          _createVNode($setup["ConnectFlowIcon"], {
            class: "h-6 w-6",
            "aria-hidden": "true"
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["to"])), [
        [_directive_tippy, _ctx.$t('connectFlow.actions.editFlow')]
      ])
    ]),
    _: 1 /* STABLE */
  }))
}