<script lang="ts" setup>
import { ref, Ref, onMounted } from 'vue';
import { useRoute, RouterLink } from 'vue-router';

import { useUiStore } from '@/stores';
import ContactService from '@/services/api/ContactService';
import { toastServiceError } from '@/services/core/notification';
import { ContactModel, Permission } from '@/types';
import { applyPhoneMask } from '@/utils/number';

import { MailIcon, PhoneIcon } from '@heroicons/vue/solid';

const route = useRoute();
const uiStore = useUiStore();

const contact: Ref<ContactModel | undefined> = ref(undefined);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchContact = async (id: number) => {
    await setLoading(true);
    try {
        const response = await ContactService.get<ContactModel>(id);
        contact.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

onMounted(() => {
    const contactId = Number(route.params.id);
    if (!isNaN(contactId)) fetchContact(contactId);
});
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="text-xl font-semibold text-gray-800">
                    {{ $t('contact.view.title') }}
                </h1>
            </template>
            <template #actions>
                <div class="flex space-x-4">
                    <UiBackButton :fallbackTo="{ name: 'ListContact' }" />
                    <router-link
                        v-if="$can(Permission.EDIT_CONTACT)"
                        :to="{
                            name: 'EditContactById',
                            params: {
                                id: route.params.id,
                            },
                        }"
                        class="block hover:bg-gray-50"
                    >
                        <UiButton variant="primary" text-variant="white">
                            {{ $t('core.actions.Edit') }}
                        </UiButton>
                    </router-link>
                </div>
            </template>
        </UiPageHeader>

        <div class="space-y-4">
            <UiPanel>
                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                    <div>
                        <h3 class="mb-1 font-semibold">
                            {{ $t('contact.labels.name') }}
                        </h3>
                        <p>{{ contact?.name }}</p>
                    </div>
                </div>
                <UiAuditStatusInfo
                    :model-value="contact"
                    class="bt-1 mt-4 border-t border-gray-300 pt-2"
                />
            </UiPanel>
            <UiPanel>
                <h3 class="mb-4 text-base font-semibold text-gray-800">
                    {{ $t('contact.view.section.emails.title') }}
                </h3>
                <div class="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
                    <UiPanel
                        v-for="email in contact?.emails"
                        :key="email.id"
                        class="flex items-center bg-white"
                    >
                        <MailIcon
                            class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                        />
                        {{ email.email }}
                    </UiPanel>
                </div>
            </UiPanel>
            <UiPanel>
                <h3 class="mb-4 text-base font-semibold text-gray-800">
                    {{ $t('contact.view.section.phones.title') }}
                </h3>
                <div class="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
                    <UiPanel
                        v-for="phone in contact?.phones"
                        :key="phone.id"
                        class="flex items-center bg-white"
                    >
                        <PhoneIcon
                            class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                        />
                        {{ applyPhoneMask(phone.number) }}
                    </UiPanel>
                </div>
            </UiPanel>
        </div>
    </div>
</template>
