<script lang="ts" setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { OfficeBuildingIcon } from '@heroicons/vue/solid';

import store from '@/store';
import { useFilterStore, useUiStore } from '@/stores';
import LevelService from '@/services/api/LevelService';
import { toast, toastServiceError } from '@/services/core/notification';

import {
    FilterItem,
    SortMenuItem,
    LevelModel,
    LevelFilter,
    LevelServiceGetAllParams,
    Permission,
    LevelActions,
    LevelGetters,
} from '@/types';

import { LevelListItem, LevelListSort, LevelListFilters } from '@/views/pages/level/components';

const route = useRoute();
const router = useRouter();
const uiStore = useUiStore();
const filterStore = useFilterStore();

const levels = ref<LevelModel[]>([]);
const count = ref(0);
const perPage = 10;

const search = computed({
    get: () => store.getters[LevelGetters.SEARCH],
    set: (value: string) => store.dispatch(LevelActions.SET_SEARCH, value),
});

const sortMenuSelected = computed<SortMenuItem[]>({
    get: () => store.getters[LevelGetters.SORT],
    set: (value: SortMenuItem[]) => store.dispatch(LevelActions.SET_SORT, value),
});

const levelFilter = computed(() => filterStore.level);

const currentPage = computed({
    get: () => store.getters[LevelGetters.PAGE],
    set: (value: number) => store.dispatch(LevelActions.SET_PAGE, value),
});

const filter: LevelFilter = store.getters[LevelGetters.FILTER];
const activeFilters = computed<FilterItem[]>(() => store.getters[LevelGetters.ACTIVE_FILTERS]);

const orderBy = computed(() => sortMenuSelected.value.map((s) => s.direction + s.key).join(','));

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

if (route.query.clearStateOnSetup) {
    search.value = '';
    sortMenuSelected.value = [];
    currentPage.value = 1;
    router.replace({ query: {} });
}

const getParams = (): LevelServiceGetAllParams => ({
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    is_active: filter.isActive !== null ? (filter.isActive.value as boolean) : undefined,
    search: search.value,
});

const fetchLevels = async () => {
    await setLoading(true);
    try {
        const { data, headers } = await LevelService.getAll<LevelModel[]>({
            params: getParams(),
        });
        levels.value = data;
        count.value = parseInt(headers['x-pagination-count']);
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const setIsActive = async (item: LevelModel, isActive: boolean) => {
    await setLoading(true);
    try {
        const { data } = await (
            isActive
                ? (id: number) => LevelService.activate(id)
                : (id: number) => LevelService.inactivate(id)
        )(item.id);
        toast.success(data.message);
        await fetchLevels();
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const handleRemoveSortClick = (item: SortMenuItem) => {
    const index = sortMenuSelected.value.indexOf(item);
    if (index > -1) sortMenuSelected.value.splice(index, 1);
};

const handleRemoveFilterClick = (item: FilterItem) => {
    store.dispatch(LevelActions.CLEAR_FILTER, item);
};

const handleActivateClick = (item: LevelModel) => setIsActive(item, true);
const handleInactivateClick = (item: LevelModel) => setIsActive(item, false);

watch([currentPage], () => {
    fetchLevels();
});

watch([levelFilter, search, filter, orderBy], () => {
    if (currentPage.value === 1) fetchLevels();
    else currentPage.value = 1;
});

onMounted(fetchLevels);
</script>

<template>
    <div class="space-y-4 p-4 sm:p-6 lg:p-8">
        <UiPageHeader>
            <template #info>
                <h1 class="flex items-center text-xl font-semibold text-gray-800">
                    <OfficeBuildingIcon class="mr-1 h-6 w-6 text-gray-500" aria-hidden="true" />
                    {{ $t('core.entity.Levels') }}
                </h1>
            </template>
            <template #actions>
                <router-link v-if="$can(Permission.CREATE_LEVEL)" :to="{ name: 'CreateLevel' }">
                    <UiButton variant="primary" text-variant="white">
                        {{ $t('core.actions.Add') }}
                    </UiButton>
                </router-link>
            </template>
        </UiPageHeader>

        <div class="space-y-2">
            <UiPanel class="flex px-4 py-2" no-padding>
                <UiListSearch
                    v-model="search"
                    :placeholder="$t('level.filters.search')"
                    class="flex-1"
                />
                <div class="flex items-center space-x-4">
                    <LevelListSort v-model="sortMenuSelected" />
                    <LevelListFilters />
                </div>
            </UiPanel>

            <UiPanel v-if="sortMenuSelected.length || activeFilters.length" class="space-x-2">
                <UiSortMenuBadge
                    v-for="selected in sortMenuSelected"
                    :key="selected.key"
                    :item="selected"
                    @remove="handleRemoveSortClick(selected)"
                />
                <UiBadge
                    v-for="item in activeFilters"
                    :key="`activeFilter${item.field}`"
                    removable
                    @remove="handleRemoveFilterClick(item)"
                >
                    {{ $t(`level.filters.${item.field}.label`) }}: {{ item.label }}
                </UiBadge>
            </UiPanel>

            <div v-if="levels.length" class="space-y-2">
                <LevelListItem
                    v-for="level in levels"
                    :key="level.id"
                    :model-value="level"
                    @activate="handleActivateClick"
                    @inactivate="handleInactivateClick"
                />
            </div>
            <UiPanel v-else class="flex items-center justify-center">
                {{ $t('core.messages.noRecordsFound') }}
            </UiPanel>

            <UiPagination
                v-if="levels.length"
                v-model="currentPage"
                :count="count"
                :perPage="perPage"
            />
        </div>
    </div>
</template>
