import { RouteRecordRaw } from 'vue-router';
import { validateIdAsPositiveIntegerGuard } from '@/router/routeGuards';
import { FormAction } from '@/types';
import RoleDetailPage from '@/views/pages/role/RoleDetailPage.vue';
import RoleFormPage from '@/views/pages/role/RoleFormPage.vue';

const roleRouter: RouteRecordRaw = {
    name: 'Role',
    path: 'role',
    children: [
        {
            name: 'ViewRoleById',
            path: ':id',
            component: RoleDetailPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            meta: { isLevelLocked: true },
        },
        {
            name: 'EditRoleById',
            path: ':id/edit',
            component: RoleFormPage,
            beforeEnter: [validateIdAsPositiveIntegerGuard],
            props: { action: FormAction.EDIT },
            meta: { isLevelLocked: true },
        },
        {
            name: 'CreateRole',
            path: 'create',
            component: RoleFormPage,
            props: { action: FormAction.CREATE },
            meta: { isLevelLocked: true },
        },
    ],
};

export default roleRouter;
