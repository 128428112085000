<script lang="ts" setup>
import { computed, reactive, ref, watch, onMounted } from 'vue';
import { useVuelidate, ValidationArgs } from '@vuelidate/core';
import { VaultService } from '@/services';
import { toastServiceError } from '@/services/core/notification';
import { getAudioFormat } from '@/utils/audio';
import { TrashIcon } from '@heroicons/vue/solid';

import { required, requiredIf, maxLength } from '@/utils/validators';
import { ConnectFlowSurveyQuestionFormState, MultiSelectOption } from '@/types';

const props = defineProps({
    modelValue: {
        type: Object as () => ConnectFlowSurveyQuestionFormState,
        required: true,
    },
    index: {
        type: Number,
        required: true,
    },
    showRemove: {
        type: Boolean,
        required: true,
    },
});

const emit = defineEmits(['update:modelValue', 'remove']);

const audioPreview = ref<string | null>(null);

const scoreOptions = [
    { label: '0-5', value: '0-5' },
    { label: '0-9', value: '0-9' },
    { label: '0-10', value: '0-10' },
    { label: '1-2', value: '1-2' },
    { label: '1-5', value: '1-5' },
    { label: '1-9', value: '1-9' },
    { label: '1-10', value: '1-10' },
] as MultiSelectOption[];

const formState = reactive<ConnectFlowSurveyQuestionFormState>(props.modelValue);

const rules = computed<ValidationArgs<ConnectFlowSurveyQuestionFormState>>(() => ({
    label: { required, maxLength: maxLength(500) },
    rangeAnswer: { required },
    audioFile: {},
    textToAudio: {
        required: requiredIf(() => !formState.audioFilePath),
        maxLength: maxLength(500),
    },
    audioFilePath: {
        required: requiredIf(() => formState.textToAudio?.length === 0),
    },
}));

const v$ = useVuelidate(rules, formState);

defineExpose({
    validate: () => v$.value.$validate(),
});

// Load audio preview for edit mode
const loadAudio = async () => {
    if (formState.audioFilePath) {
        try {
            const { data } = await VaultService.getAudioAsset(formState.audioFilePath);
            const blob = new Blob([data], { type: getAudioFormat(formState.audioFilePath) });
            formState.audioFile = new File([blob], formState.audioFilePath, {
                type: blob.type,
                lastModified: Date.now(),
            });
        } catch (e) {
            toastServiceError(e);
        }
    }
};

// Handle file selection
watch(
    () => formState.audioFile,
    () => {
        if (formState.audioFile) {
            formState.audioFilePath = formState.audioFile.name;
            audioPreview.value = URL.createObjectURL(formState.audioFile);
        } else {
            formState.audioFilePath = '';
            audioPreview.value = null;
        }
    },
);

watch(
    formState,
    () => {
        emit('update:modelValue', formState);
    },
    {
        deep: true,
    },
);

onMounted(loadAudio);
</script>

<template>
    <div class="mt-2 space-y-2 border-t border-gray-300 py-2">
        <div class="flex">
            <h4 class="flex-1 font-semibold">
                {{ `${$t('connectFlowSurvey.form.labels.question')} ${index + 1}` }}
            </h4>
            <UiActionButton
                v-if="showRemove"
                variant="danger"
                v-tippy="$t('connectFlowSurvey.form.actions.removeQuestion')"
                @click="emit('remove', index)"
            >
                <TrashIcon class="h-5 w-5" />
            </UiActionButton>
        </div>
        <div class="gap-4 space-y-4 md:grid md:grid-cols-3 md:space-y-0">
            <UiTextInput
                v-model="v$.label.$model"
                name="label"
                type="text"
                :label="$t('connectFlowSurvey.form.labels.label')"
                class="col-span-2"
                :errors="v$.label.$errors"
                @blur="v$.label.$touch"
            />

            <div>
                <label for="rangeAnswer" class="mb-2 block text-sm font-medium text-gray-800">
                    {{ $t('connectFlowSurvey.form.labels.scoreRange') }}
                </label>
                <UiMultiselect
                    name="rangeAnswer"
                    v-model="formState.rangeAnswer"
                    :primitive="true"
                    :options="scoreOptions"
                    :errors="v$.rangeAnswer.$errors"
                />
            </div>

            <UiTextArea
                id="textToAudio"
                name="textToAudio"
                v-model="formState.textToAudio"
                :label="$t('connectFlowSurvey.form.labels.textToAudio')"
                :placeholder="$t('connectFlowSurvey.form.labels.textToAudioPlaceholder')"
                :rows="4"
                :disabled="formState.audioFile != undefined"
                :errors="v$.textToAudio.$errors"
                class="col-span-2"
            />

            <div class="flex flex-col">
                <label class="mb-2 block text-sm font-medium text-gray-800">
                    {{ $t('connectFlowSurvey.form.labels.audioUpload') }}
                </label>
                <UiAudioUpload
                    v-model:file="formState.audioFile"
                    :text-rule="$t('connectFlowSurvey.form.labels.uploadRules')"
                    :errors="v$.audioFilePath.$errors"
                />
                <div
                    class="text-danger bg-danger-100 p-2 text-sm shadow"
                    v-if="v$.audioFilePath.$errors && v$.audioFilePath.$errors.length"
                >
                    <p v-for="error of v$.audioFilePath.$errors" :key="error.$uid">
                        {{ error.$message }}
                    </p>
                </div>
                <audio v-if="audioPreview" controls class="mt-2 w-full">
                    <source :src="audioPreview" />
                    Your browser does not support the audio element.
                </audio>
            </div>
        </div>
    </div>
</template>
