import { defineComponent as _defineComponent } from 'vue'
import { PropType } from 'vue';
import router from '@/router';

import {
    InformationCircleIcon,
    LocationMarkerIcon,
    TagIcon,
    UsersIcon,
} from '@heroicons/vue/solid';
import ConnectFlowIcon from '@/components/svg/ConnectFlowIcon';
import { PencilAltIcon } from '@heroicons/vue/outline';
import { NumberModel, Permission } from '@/types';
import { applyPhoneMask } from '@/utils/number';


export default /*@__PURE__*/_defineComponent({
  __name: 'NumberListItem',
  props: {
    modelValue: {
        type: Object as PropType<NumberModel>,
        required: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();



const handleEditClick = () => {
    router.push({
        name: 'EditNumberById',
        params: {
            id: __props.modelValue.id,
        },
    });
};

const __returned__ = { handleEditClick, get InformationCircleIcon() { return InformationCircleIcon }, get LocationMarkerIcon() { return LocationMarkerIcon }, get TagIcon() { return TagIcon }, get UsersIcon() { return UsersIcon }, get ConnectFlowIcon() { return ConnectFlowIcon }, get PencilAltIcon() { return PencilAltIcon }, get Permission() { return Permission }, get applyPhoneMask() { return applyPhoneMask } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})