import { UserLabelModel, UserModel } from '@/types';

export interface AgentProductivityPerDayReportFilter {
    user?: UserModel | null;
    userLabel?: UserLabelModel | null;
}

export interface AgentProductivityPerDayReportState {
    filter: AgentProductivityPerDayReportFilter;
}

export enum AgentProductivityPerDayReportMutations {
    SET_FILTER = 'agentProductivityPerDayReport_setFilter',
    CLEAR_FILTER = 'agentProductivityPerDayReport_clearFilter',
}

export enum AgentProductivityPerDayReportActions {
    SET_FILTER = 'agentProductivityPerDayReport_setFilter',
    CLEAR_FILTER = 'agentProductivityPerDayReport_clearFilter',
}

export enum AgentProductivityPerDayReportGetters {
    DEFAULT_FILTER = 'agentProductivityPerDayReport_defaultFilter',
    FILTER = 'agentProductivityPerDayReport_filter',
    ACTIVE_FILTERS = 'agentProductivityPerDayReport_activeFilters',
}
