<script lang="ts" setup>
import { ref, computed, onMounted, onUnmounted, PropType } from 'vue';
import store from '@/store';
import { PlayIcon, PauseIcon, ExclamationCircleIcon, SearchIcon } from '@heroicons/vue/solid';

import { CallPlayerBullets } from '@/views/pages/call/components';
import { VaultService } from '@/services';
import { getAudioFormat } from '@/utils/audio';
import { formatSecToTime } from '@/utils/datetime';
import {
    CallPlayerMutations,
    CallType,
    CallBulletType,
    CallParticipantType,
    CallModel,
} from '@/types';
import debounce from 'debounce';
import WaveSurfer from 'wavesurfer.js';
import MultiCanvas from 'wavesurfer.js/src/drawer.multicanvas';
import AnalyticsService from '@/services/analytics/AnalyticsService';

const { call } = defineProps({
    call: {
        type: Object as PropType<CallModel>,
        required: true,
    },
});

const waveInstance = ref<WaveSurfer | null>(null);
const audioData = ref<string | null>(null);
const audioElement = ref<HTMLElement | null>(null);
const playerReady = ref(false);
const playerError = ref(null);
const callDuration = ref(0);
const secondsCurrent = ref(0);
const loadingPercentage = ref(0);
const searchWord = ref('');

const isPlaying = computed(() => (playerReady.value ? waveInstance.value?.isPlaying() : false));
const playbackRate = computed(() => waveInstance.value?.getPlaybackRate());

// Fetch Audio File
const getAudioFile = async () => {
    if (!call?.audioFilePath) return;

    try {
        const { data } = await VaultService.getCallAudio(call.id);
        audioData.value = URL.createObjectURL(
            new Blob([data], { type: getAudioFormat(call.audioFilePath) }),
        );
        initAudioPlayer();
    } catch (error) {
        console.error('Error fetching audio file:', error);
    }
};

MultiCanvas.prototype.drawBars = function (peaks, channelIndex, start, end) {
    return this.prepareDraw(
        peaks,
        channelIndex,
        start,
        end,
        ({ absmax, hasMinVals, height, offsetY, peaks }) => {
            if (start === undefined) return;

            const barWidth = this.params.barWidth || 3;
            const barGap = this.params.barGap || 3;
            const pixelRatio = this.params.pixelRatio || 1;
            const bar = barWidth * pixelRatio;
            const peakIndexScale = hasMinVals ? 2 : 1;
            const length = peaks.length / peakIndexScale;
            const gap =
                this.params.barGap === null
                    ? Math.max(pixelRatio, ~~(bar / 2))
                    : Math.max(pixelRatio, barGap * pixelRatio);
            const step = bar + gap;
            const gapBetween = 2;
            const scale = length / this.width;

            for (let i = start; i < end; i += step) {
                const peakIndex = Math.floor(i * scale * peakIndexScale);
                const peak = peaks[peakIndex] || 0;
                const rectWidth = bar + this.halfPixel;
                let rectHeight = Math.abs(Math.round((peak / absmax) * (height - gapBetween)));
                if (rectHeight && this.params.barMinHeight && rectHeight < this.params.barMinHeight)
                    rectHeight = this.params.barMinHeight;
                const x = i + this.halfPixel;
                const y = offsetY
                    ? call.type === CallType.INBOUND
                        ? height - rectHeight - gapBetween
                        : height + gapBetween
                    : call.type === CallType.INBOUND
                      ? height + gapBetween
                      : height - rectHeight - gapBetween;
                this.fillRect(x, y, rectWidth, rectHeight, this.barRadius, channelIndex);
            }
        },
        0,
        0,
    );
};

const initAudioPlayer = () => {
    if (!audioElement.value || !audioData.value) return;

    let waveColor: CanvasGradient | string = '#3b82f6';
    if (call?.type !== CallType.INTERNAL) {
        const ctx = document.createElement('canvas').getContext('2d');
        if (ctx) {
            const gradient = ctx.createLinearGradient(0, 0, 0, 150);
            gradient.addColorStop(0, 'rgb(59, 130, 246)');
            gradient.addColorStop(1, 'rgb(59, 130, 246)');
            gradient.addColorStop(1, 'rgb(168, 85, 247)');
            waveColor = gradient;
        }
    }

    waveInstance.value = WaveSurfer.create({
        container: audioElement.value,
        waveColor,
        progressColor: '#adb5bd',
        cursorColor: '#adb5bd',
        barWidth: 3,
        barGap: 3,
        barRadius: 1,
        barMinHeight: 3,
        height: 75,
        normalize: true,
        hideScrollbar: true,
        splitChannels: true,
        skipLength: 10,
        renderer: MultiCanvas,
    });

    waveInstance.value.load(audioData.value);

    waveInstance.value.on('ready', () => {
        playerReady.value = true;
        callDuration.value = waveInstance.value?.getDuration() ?? 0;
    });

    waveInstance.value.on('audioprocess', (audioProcess) => {
        secondsCurrent.value = parseFloat(audioProcess.toFixed(2));
    });

    waveInstance.value.on('loading', (percentage) => {
        loadingPercentage.value = parseFloat(percentage.toFixed(2));
    });

    waveInstance.value.on('error', (error) => {
        loadingPercentage.value = 0;
        playerError.value = error;
    });

    waveInstance.value.on('seek', (seek) => {
        secondsCurrent.value = (waveInstance.value?.getDuration() ?? 0) * seek;
    });
};

// Play / Pause
const playPause = () => {
    if (!waveInstance.value) {
        return;
    }

    const playStatusTracking = waveInstance.value.isPlaying() ? 'PauseCallAudio' : 'PlayCallAudio';

    waveInstance.value.playPause();
    void AnalyticsService.trackingAction(playStatusTracking);
};

// Change Speed
const changeSpeed = () => {
    const speeds = [1, 1.1, 1.2];
    const currentRate = waveInstance.value?.getPlaybackRate() ?? 1;
    waveInstance.value?.setPlaybackRate(speeds[(speeds.indexOf(currentRate) + 1) % speeds.length]);
};

const handlePlaySnippet = (start: number, end: number) => {
    if (waveInstance.value) waveInstance.value.play(start, end);
};

// Lifecycle Hooks
onMounted(async () => {
    await getAudioFile();
});
onUnmounted(() => {
    if (waveInstance.value) {
        waveInstance.value.destroy();
        waveInstance.value = null;
    }
});
</script>

<template>
    <UiPanel>
        <div v-if="!playerError" class="space-y-4">
            <div class="flex rounded bg-gray-100 text-gray-400 focus-within:text-gray-600">
                <SearchIcon class="my-2 ml-4 h-6 w-6" aria-hidden="true" />
                <input
                    v-model="searchWord"
                    type="search"
                    autocomplete="off"
                    :placeholder="$t('core.actions.Search')"
                    class="grow border-transparent bg-transparent px-4 py-2 text-base text-gray-800 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0"
                    @input="
                        debounce(
                            () => store.commit(CallPlayerMutations.SET_SEARCH_QUERY, searchWord),
                            1000,
                        )()
                    "
                />
            </div>

            <div class="flex">
                <div class="flex flex-col items-center justify-center space-y-4 pr-2">
                    <UiButton
                        v-tippy="$t('call.view.player.PlayPause')"
                        variant="primary"
                        text-variant="white"
                        no-padding
                        no-rounded
                        class="h-10 w-10 rounded-full"
                        @click="playPause"
                    >
                        <PlayIcon v-if="!isPlaying" class="text-lg text-white" />
                        <PauseIcon v-else class="text-lg text-white" />
                    </UiButton>
                    <UiButton
                        v-tippy="$t('call.view.player.ChangeSpeed')"
                        variant="primary"
                        text-variant="white"
                        no-padding
                        class="h-6 w-6 text-sm"
                        @click="changeSpeed"
                    >
                        <span>{{ playbackRate }}x</span>
                    </UiButton>
                </div>

                <div class="flex flex-1 flex-col space-y-1">
                    <CallPlayerBullets
                        :call="call"
                        :callDuration="callDuration"
                        :bulletType="CallBulletType.registered"
                        :participantType="
                            call.type == CallType.INTERNAL
                                ? CallParticipantType.AgentA
                                : CallParticipantType.Agent
                        "
                        @playSnippet="handlePlaySnippet"
                    />
                    <CallPlayerBullets
                        :call="call"
                        :callDuration="callDuration"
                        :bulletType="CallBulletType.matched"
                        :participantType="
                            call.type == CallType.INTERNAL
                                ? CallParticipantType.AgentA
                                : CallParticipantType.Agent
                        "
                        @playSnippet="handlePlaySnippet"
                    />

                    <div ref="audioElement"></div>

                    <CallPlayerBullets
                        :call="call"
                        :callDuration="callDuration"
                        :bulletType="CallBulletType.matched"
                        :participantType="
                            call.type == CallType.INTERNAL
                                ? CallParticipantType.AgentB
                                : CallParticipantType.Client
                        "
                        @playSnippet="handlePlaySnippet"
                    />
                    <CallPlayerBullets
                        :call="call"
                        :callDuration="callDuration"
                        :bulletType="CallBulletType.registered"
                        :participantType="
                            call.type == CallType.INTERNAL
                                ? CallParticipantType.AgentB
                                : CallParticipantType.Client
                        "
                        @playSnippet="handlePlaySnippet"
                    />
                </div>

                <div class="flex flex-shrink flex-col sm:w-auto">
                    <div class="flex grow items-center">
                        <div class="w-full rotate-90 transform text-center text-gray-500">
                            {{
                                $t(
                                    'call.view.participant.' +
                                        (call.type === CallType.INTERNAL
                                            ? CallParticipantType.AgentA.toLowerCase()
                                            : CallParticipantType.Agent.toLowerCase()),
                                )
                            }}
                        </div>
                    </div>
                    <div class="flex grow items-center">
                        <div class="w-full rotate-90 transform text-center text-gray-500">
                            {{
                                $t(
                                    'call.view.participant.' +
                                        (call.type === CallType.INTERNAL
                                            ? CallParticipantType.AgentB.toLowerCase()
                                            : CallParticipantType.Client.toLowerCase()),
                                )
                            }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex px-0 md:px-14">
                <div class="flex space-x-4">
                    <div class="flex items-center text-xs text-blue-500">
                        <div class="group mr-1 h-4 w-4 rounded-full bg-blue-500/50 p-0" />
                        <div>{{ $t('call.audioPlayer.label.words') }}</div>
                    </div>
                    <div class="flex items-center text-xs text-orange-500">
                        <div class="group mr-1 h-4 w-4 rounded-full bg-orange-500/50 p-0" />
                        <div>{{ $t('call.audioPlayer.label.matchedWords') }}</div>
                    </div>
                </div>

                <div class="ml-auto text-sm text-gray-500">
                    {{ formatSecToTime(secondsCurrent) }} /
                    {{ formatSecToTime(callDuration) }}
                </div>
            </div>
        </div>
        <div v-else class="text-danger-700">
            <ExclamationCircleIcon class="inline h-6 w-6" /> {{ playerError }}
        </div>
    </UiPanel>
</template>
