import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, ComputedRef, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AxiosError } from 'axios';

import store from '@/store';
import { useUiStore } from '@/stores';
import CallService from '@/services/api/CallService';
import { toastServiceError } from '@/services/core/notification';

import {
    AuthGetters,
    CallModel,
    CallType,
    CallParticipantType,
    DefaultServiceResult,
    UserIntegrationModel,
} from '@/types';

import {
    CallDetails,
    CallPlayer,
    CallWords,
    CallSurvey,
    CallChatBot,
} from '@/views/pages/call/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'CallDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const uiStore = useUiStore();

const call: Ref<CallModel | null> = ref(null);

const isLoading = computed(() => uiStore.isLoading);

const chatBotIntegration: ComputedRef<UserIntegrationModel> = computed<UserIntegrationModel>(
    () => store.getters[AuthGetters.CHAT_BOT_INTEGRATION],
);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getCall = async (id: number) => {
    await setLoading(true);
    try {
        const response = await CallService.get<CallModel>(id);
        call.value = response.data;
    } catch (err) {
        toastServiceError(err as Error | AxiosError<DefaultServiceResult>);
    } finally {
        await setLoading(false);
    }
};

onMounted(async () => {
    await getCall(Number(route.params.id));
});

const __returned__ = { route, uiStore, call, isLoading, chatBotIntegration, setLoading, getCall, get CallType() { return CallType }, get CallParticipantType() { return CallParticipantType }, get CallDetails() { return CallDetails }, get CallPlayer() { return CallPlayer }, get CallWords() { return CallWords }, get CallSurvey() { return CallSurvey }, get CallChatBot() { return CallChatBot } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})