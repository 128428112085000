import { CallModel, MultiSelectOption } from '@/types';
import BaseService from '@/services/api/BaseService';
import { AxiosRequestConfig } from 'axios';

class CallService<T> extends BaseService<CallModel> {
    getTimeline<ResponseT = T[]>(id: number | string, config?: AxiosRequestConfig) {
        return this.http.get<ResponseT>(`${this.route}/${id}/timeline`, config);
    }
    getWords<ResponseT = T[]>(id: number | string, config?: AxiosRequestConfig) {
        return this.http.get<ResponseT>(`${this.route}/${id}/words`, config);
    }
    getMedias<ResponseT = T[]>(config?: AxiosRequestConfig) {
        return this.http.get<ResponseT>(`${this.route}/options/medias/`, config);
    }
    getClientNumbers<ResponseT = MultiSelectOption[]>(config?: AxiosRequestConfig) {
        return this.http.get<ResponseT>(`${this.route}/number/client`, config);
    }
    getAgentNumbers<ResponseT = T[]>(config?: AxiosRequestConfig) {
        return this.http.get<ResponseT>(`${this.route}/number/agent`, config);
    }
    getCsvReport<ResponseT = T[]>(config?: AxiosRequestConfig) {
        return this.http.get<ResponseT>(`${this.route}/report/csv`, config);
    }
}

export default new CallService('calls');
