/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    IntegrationLogState,
    IntegrationLogFilter,
    IntegrationLogMutations,
    IntegrationLogActions,
    IntegrationLogGetters,
    SortMenuItem,
    FilterItem,
} from '@/types';

const getDefaultState = () => {
    return {
        search: null,
        sort: [],
        page: 1,
        filter: {
            status: null,
        },
    } as IntegrationLogState;
};

const state: IntegrationLogState = getDefaultState();

const mutations: MutationTree<IntegrationLogState> = {
    [IntegrationLogMutations.SET_SEARCH]: (state, search: string) => (state.search = search),
    [IntegrationLogMutations.SET_SORT]: (state, sort: SortMenuItem[]) => (state.sort = sort),
    [IntegrationLogMutations.SET_PAGE]: (state, page: number) => (state.page = page),
    [IntegrationLogMutations.SET_FILTER](state, payload: IntegrationLogFilter) {
        state.filter.status = payload.status;
    },
    [IntegrationLogMutations.CLEAR_FILTER](state, field?: string) {
        switch (field) {
            case 'status':
                state.filter.status = getDefaultState().filter.status;
                break;
            default:
                state.filter.status = getDefaultState().filter.status;
        }
    },
};

const actions: ActionTree<IntegrationLogState, any> = {
    [IntegrationLogActions.SET_SEARCH]: ({ commit }, search: string) =>
        commit(IntegrationLogMutations.SET_SEARCH, search),
    [IntegrationLogActions.SET_SORT]: ({ commit }, sort: SortMenuItem[]) =>
        commit(IntegrationLogMutations.SET_SORT, sort),
    [IntegrationLogActions.SET_PAGE]: ({ commit }, page: number) =>
        commit(IntegrationLogMutations.SET_PAGE, page),
    [IntegrationLogActions.SET_FILTER]({ commit }, payload: IntegrationLogFilter) {
        commit(IntegrationLogMutations.SET_FILTER, payload);
    },
    [IntegrationLogActions.CLEAR_FILTER]({ commit }, field?: string) {
        commit(IntegrationLogMutations.CLEAR_FILTER, field);
    },
};

const getters: GetterTree<IntegrationLogState, any> = {
    [IntegrationLogGetters.SEARCH]: (state): string | null => state.search,
    [IntegrationLogGetters.SORT]: (state): SortMenuItem[] => state.sort,
    [IntegrationLogGetters.PAGE]: (state): number => state.page,
    [IntegrationLogGetters.DEFAULT_FILTER]: (): IntegrationLogFilter => getDefaultState().filter,
    [IntegrationLogGetters.FILTER]: (state): IntegrationLogFilter => state.filter,
    [IntegrationLogGetters.ACTIVE_FILTERS](state): FilterItem[] {
        const filters: Array<FilterItem> = [];

        if (state.filter.status)
            filters.push({
                field: 'status',
                value: state.filter.status.value,
                label: state.filter.status.label,
            });

        return filters;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
