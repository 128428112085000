import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-span-3" }
const _hoisted_2 = { class: "block text-sm font-medium text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiTextInput = _resolveComponent("UiTextInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t('user.labels.extension.number')), 1 /* TEXT */),
    _createVNode(_component_UiTextInput, {
      label: false,
      modelValue: $setup.formState.number,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formState.number) = $event)),
      modelModifiers: { lazy: true, number: true },
      name: "extension",
      type: "number",
      errors: $setup.v$.number.$errors,
      silentErrors: $setup.v$.number.$silentErrors,
      class: "col-span-3",
      disabled: $setup.noDevice || $props.modelValue.levelId === null
    }, null, 8 /* PROPS */, ["modelValue", "errors", "silentErrors", "disabled"])
  ]))
}