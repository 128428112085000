import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { AxiosResponse } from 'axios';

import { useUiStore } from '@/stores';
import ConnectFlowService from '@/services/api/ConnectFlowService';
import { toastServiceError } from '@/services/core/notification';

import { ConnectFlowModel, ConnectFlowFlowModel } from '@/types';

import { ConnectFlowHistoryItem } from '@/views/pages/connect-flow/components';
import ConnectFlowIcon from '@/components/svg/ConnectFlowIcon';

const perPage = 10;


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowHistoryPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const uiStore = useUiStore();

const connectFlow = ref<ConnectFlowModel | undefined>(undefined);
const currentPage = ref(1);
const flows = computed<ConnectFlowFlowModel[]>(() => {
    return (connectFlow.value?.flows || []).slice().sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
});

const count = computed<number>(() => flows.value.length);

const paginatedFlows = computed<ConnectFlowFlowModel[]>(() => {
    const start = (currentPage.value - 1) * perPage;
    return flows.value.slice(start, start + perPage);
});

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchConnectFlow = async (id: number) => {
    await setLoading(true);
    try {
        const response: AxiosResponse<ConnectFlowModel> =
            await ConnectFlowService.get<ConnectFlowModel>(id);
        connectFlow.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

watch(flows, () => {
    currentPage.value = 1;
});

onMounted(() => {
    const id = Number(route.params.id);
    fetchConnectFlow(id);
});

const __returned__ = { route, uiStore, connectFlow, currentPage, perPage, flows, count, paginatedFlows, setLoading, fetchConnectFlow, get ConnectFlowHistoryItem() { return ConnectFlowHistoryItem }, get ConnectFlowIcon() { return ConnectFlowIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})