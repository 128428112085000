import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "space-y-4 p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-800" }
const _hoisted_3 = { class: "flex space-x-4" }
const _hoisted_4 = {
  key: 0,
  class: "space-y-4"
}
const _hoisted_5 = { class: "grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3" }
const _hoisted_6 = { class: "mb-1 font-semibold" }
const _hoisted_7 = { class: "mb-4 text-lg font-semibold text-gray-800" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "mb-1 font-semibold" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "mb-1 font-semibold" }
const _hoisted_12 = { controls: "" }
const _hoisted_13 = ["src", "type"]
const _hoisted_14 = { class: "mb-2 text-lg font-semibold text-gray-800" }
const _hoisted_15 = { class: "font-semibold" }
const _hoisted_16 = {
  key: 0,
  class: "mb-2 text-sm text-gray-500"
}
const _hoisted_17 = { key: 1 }
const _hoisted_18 = {
  key: 2,
  controls: ""
}
const _hoisted_19 = ["src", "type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiBackButton = _resolveComponent("UiBackButton")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!
  const _component_UiAuditStatusInfo = _resolveComponent("UiAuditStatusInfo")!
  const _component_UiPanel = _resolveComponent("UiPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('connectFlowSurvey.view.title')), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_UiBackButton, { fallbackTo: { name: 'ListConnectFlowSurvey' } }),
          (_ctx.$can($setup.Permission.EDIT_CONNECT_FLOW_SURVEY))
            ? (_openBlock(), _createBlock($setup["RouterLink"], {
                key: 0,
                to: {
                            name: 'EditConnectFlowSurveyById',
                            params: {
                                id: $setup.route.params.id,
                            },
                        },
                class: "block hover:bg-gray-50"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_UiButton, {
                    variant: "primary",
                    "text-variant": "white"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Edit')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"]))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }),
    ($setup.survey)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_UiPanel, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", null, [
                  _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('connectFlowSurvey.labels.name')), 1 /* TEXT */),
                  _createElementVNode("p", null, _toDisplayString($setup.survey.name), 1 /* TEXT */)
                ])
              ]),
              _createVNode(_component_UiAuditStatusInfo, {
                "model-value": $setup.survey,
                class: "bt-1 mt-4 border-t border-gray-300 pt-2"
              }, null, 8 /* PROPS */, ["model-value"])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_UiPanel, null, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.$t('connectFlowSurvey.view.section.initialMessage.title')), 1 /* TEXT */),
              ($setup.survey.textToAudio)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t('connectFlowSurvey.labels.textToAudio')), 1 /* TEXT */),
                    _createElementVNode("p", null, _toDisplayString($setup.survey.textToAudio), 1 /* TEXT */)
                  ]))
                : _createCommentVNode("v-if", true),
              ($setup.audioFile)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("h3", _hoisted_11, _toDisplayString(_ctx.$t('connectFlowSurvey.labels.audio')), 1 /* TEXT */),
                    _createElementVNode("audio", _hoisted_12, [
                      _createElementVNode("source", {
                        src: $setup.audioFile,
                        type: $setup.getAudioFormat($setup.survey.audioFilePath || '')
                      }, null, 8 /* PROPS */, _hoisted_13)
                    ])
                  ]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_UiPanel, null, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_14, _toDisplayString(_ctx.$t('connectFlowSurvey.view.section.questions.title')), 1 /* TEXT */),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.questions, (question, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "bt-1 border-t py-2"
                }, [
                  _createElementVNode("h4", _hoisted_15, _toDisplayString(question.label), 1 /* TEXT */),
                  (question.rangeAnswer)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(_ctx.$t('connectFlowSurvey.view.section.questions.labels.scoreRange')) + ": " + _toDisplayString(question.rangeAnswer), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true),
                  (question.textToAudio)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(question.textToAudio), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true),
                  (question.audioFile)
                    ? (_openBlock(), _createElementBlock("audio", _hoisted_18, [
                        _createElementVNode("source", {
                          src: question.audioFile,
                          type: $setup.getAudioFormat(question.audioFilePath || '')
                        }, null, 8 /* PROPS */, _hoisted_19),
                        _cache[0] || (_cache[0] = _createTextVNode(" Seu navegador não suporta o elemento de áudio. "))
                      ]))
                    : _createCommentVNode("v-if", true)
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          })
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}