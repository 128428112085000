import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, onMounted, type Ref } from 'vue';
import { useRoute } from 'vue-router';
import { useVuelidate, type Validation, type ValidationArgs } from '@vuelidate/core';
import { required, maxLength, isValidPhone } from '@/utils/validators';
import { goBack } from '@/composables/useNavigation';
import { useUiStore } from '@/stores';
import { SpamNumberService } from '@/services';
import { toast, toastServiceError } from '@/services/core/notification';
import { Permission, FormAction, type SpamNumberFormState, type SpamNumberModel } from '@/types';
import type { AxiosResponse } from 'axios';


export default /*@__PURE__*/_defineComponent({
  __name: 'SpamNumberFormPage',
  props: {
    action: { type: String, default: FormAction.CREATE },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const route = useRoute();

const uiStore = useUiStore();

const formState = reactive<SpamNumberFormState>({
    id: null,
    number: null,
});

const rules = computed<ValidationArgs<SpamNumberFormState>>(() => ({
    number: {
        required,
        maxLength: maxLength(15),
        isValidPhone,
    },
}));

const v$: Ref<Validation<ValidationArgs<SpamNumberFormState>>> = useVuelidate(rules, formState);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const fetchSpamNumber = async (id: number) => {
    try {
        setLoading(true);
        const { data } = await SpamNumberService.get<SpamNumberModel>(id);
        formState.id = data.id;
        formState.number = data.number;
    } catch (e) {
        toastServiceError(e);
    } finally {
        setLoading(false);
    }
};

const onSubmit = async () => {
    const isFormValid = await v$.value.$validate();
    if (!isFormValid) return;

    setLoading(true);

    const handleServiceSuccess = (message: string, id: number) => {
        toast.success(message);
        v$.value.$reset();
        goBack({ name: 'ViewSpamNumberById', params: { id } });
    };

    try {
        if (props.action === FormAction.EDIT) {
            const id = Number(route.params.id);
            const { data }: AxiosResponse = await SpamNumberService.update(id, formState);
            handleServiceSuccess(data.message, id);
        } else {
            const { data }: AxiosResponse = await SpamNumberService.create(formState);
            handleServiceSuccess(data.message, data.result.id);
        }
    } catch (e) {
        toastServiceError(e);
    } finally {
        setLoading(false);
    }
};

onMounted(async () => {
    if (props.action === FormAction.EDIT) {
        await fetchSpamNumber(Number(route.params.id));
    }
});

const __returned__ = { props, route, uiStore, formState, rules, v$, setLoading, fetchSpamNumber, onSubmit, get Permission() { return Permission }, get FormAction() { return FormAction } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})