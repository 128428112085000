import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, ComputedRef } from 'vue';

import {
    CallModel,
    CallWordModel,
    CallParticipantType,
    CallPlayerGetters,
    CallPlayerMutations,
} from '@/types';

import store from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'CallWords',
  props: {
    call: {
        type: Object as () => CallModel,
        required: true,
    },
    participantType: {
        type: String as () => CallParticipantType,
        required: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();



const showHiddenWords = ref(false);
const transcripts = __props.call?.transcripts || [];

const callPlayerSearchQuery: ComputedRef<string> = computed(
    () => store.getters[CallPlayerGetters.SEARCH_QUERY],
);
const callPlayerSelectedWords: ComputedRef<string[]> = computed(() => {
    const selectedWordsMap = store.getters[CallPlayerGetters.SELECTED_WORDS];
    return Array.from(selectedWordsMap.get(__props.participantType) ?? []);
});

const words: ComputedRef<CallWordModel[]> = computed(() => {
    const wordMap: Map<string, CallWordModel> = new Map();

    transcripts
        .filter(({ participant }) => participant === __props.participantType)
        .forEach(({ words, registeredWords }) => {
            words.forEach((word) => {
                const existing = wordMap.get(word);
                if (existing) existing.counter += 1;
                else
                    wordMap.set(word, {
                        text: word,
                        counter: 1,
                        registered: registeredWords.includes(word),
                        selected: callPlayerSelectedWords.value.includes(word),
                        matched: word === callPlayerSearchQuery.value,
                    });
            });
        });

    return Array.from(wordMap.values()).sort((a, b) => {
        // Sort by registered
        if (a.registered !== b.registered) return a.registered ? -1 : 1;
        // Sort by counter
        if (a.counter !== b.counter) return b.counter - a.counter;
        // Sort by text
        return a.text.localeCompare(b.text);
    });
});

const filteredWords: ComputedRef<CallWordModel[]> = computed(() =>
    showHiddenWords.value ? words.value : words.value.slice(0, 10),
);

const __returned__ = { showHiddenWords, transcripts, callPlayerSearchQuery, callPlayerSelectedWords, words, filteredWords, get CallParticipantType() { return CallParticipantType }, get CallPlayerMutations() { return CallPlayerMutations }, get store() { return store } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})