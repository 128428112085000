import { defineComponent as _defineComponent } from 'vue'
import { PropType, computed, WritableComputedRef, ref, Ref, onMounted } from 'vue';
import {
    ConnectFlowModelingActions,
    ConnectFlowModelingGetters,
    ConnectFlowModelingMutations,
    VueFlowNodeModel,
    MultiSelectOption,
    ScheduleModel,
} from '@/types';
import store from '@/store';
import { ClockIcon } from '@heroicons/vue/outline';

import { useVuelidate, Validation, ValidationArgs } from '@vuelidate/core';
import { required } from '@/utils/validators';

interface ITimeFormState {
    schedule: null | ScheduleModel;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TimeValidation',
  props: {
    node: {
        type: Object as PropType<VueFlowNodeModel>,
        required: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const showForm = ref(false);

const nodeWritable: WritableComputedRef<VueFlowNodeModel> = computed({
    get: () => store.getters['flow/' + ConnectFlowModelingGetters.ELEMENT_BY_ID](props.node.id),
    set: (value: VueFlowNodeModel) => {
        store.commit('flow/' + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
            idx: null,
            value,
        });
    },
});

const scheduleOptions = computed(
    () => store.getters['flow/' + ConnectFlowModelingGetters.SCHEDULE_OPTIONS],
);
const formState: Ref<ITimeFormState> = ref({
    schedule: null,
});
const rules = computed<ValidationArgs<ITimeFormState>>(() => {
    const r: ValidationArgs<ITimeFormState> = {
        schedule: {
            required,
        },
    };
    return r;
});
const v$: Ref<Validation<ValidationArgs<ITimeFormState>>> = useVuelidate(rules, formState);

const isFormCorrect = ref(true);

function removeNode() {
    store.dispatch('flow/' + ConnectFlowModelingActions.DELETE_NODE, {
        nodeId: nodeWritable.value.id,
        parentId: nodeWritable.value.parentId,
    });
}
async function onSubmit() {
    isFormCorrect.value = await v$.value.$validate();
    if (isFormCorrect.value) {
        nodeWritable.value.data.related = {
            value: formState.value.schedule?.id,
            label: formState.value.schedule?.name,
        } as MultiSelectOption;
        showForm.value = false;
    }
    nodeWritable.value.data.hasErrors = !isFormCorrect.value;
    v$.value.$reset();
}

onMounted(() => {
    if (nodeWritable.value.data.related !== null) {
        formState.value.schedule = scheduleOptions.value.find(
            (schedule: ScheduleModel) => schedule.id == nodeWritable.value.data.related?.value,
        );
    }

    v$.value.$validate().then((result) => {
        isFormCorrect.value = result;
        nodeWritable.value.data.hasErrors = !isFormCorrect.value;
    });
});

const __returned__ = { props, showForm, nodeWritable, scheduleOptions, formState, rules, v$, isFormCorrect, removeNode, onSubmit, get ClockIcon() { return ClockIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})