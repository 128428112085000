/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
    NavigationState,
    NavigationMutations,
    NavigationActions,
    NavigationGetters,
    NavigationItem,
} from '@/types';
import { sideNavigationItems, settingSideNavigationItems } from '@/configs';

const state: NavigationState = {
    sideNavigationOpen: false,
    sideNavigationItems: sideNavigationItems,
    settingsSideNavigationItems: settingSideNavigationItems,
};

const mutations: MutationTree<NavigationState> = {
    [NavigationMutations.SET_SIDE_NAVIGATION_OPEN](state, sideNavigationOpen: boolean) {
        state.sideNavigationOpen = sideNavigationOpen;
    },
};

const actions: ActionTree<NavigationState, any> = {
    [NavigationActions.TOGGLE_SIDE_NAVIGATION]({ commit }) {
        commit(NavigationMutations.SET_SIDE_NAVIGATION_OPEN, !state.sideNavigationOpen);
    },
};

const getters: GetterTree<NavigationState, any> = {
    [NavigationGetters.SIDE_NAVIGATION_OPEN](state): boolean {
        return state.sideNavigationOpen;
    },
    [NavigationGetters.SIDE_NAVIGATION_ITEMS](state): NavigationItem[] {
        return state.sideNavigationItems;
    },
    [NavigationGetters.SETTINGS_SIDE_NAVIGATION_ITEMS](state): NavigationItem[] {
        return state.settingsSideNavigationItems;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
