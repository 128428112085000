import { ListFilters, MultiSelectOption } from '@/types';

export interface NumberFilter {
    hasConnectFlow: MultiSelectOption | null;
    state: MultiSelectOption | null;
    city: MultiSelectOption | null;
}

export interface NumberState extends ListFilters {
    filter: NumberFilter;
}

export enum NumberMutations {
    SET_SEARCH = 'number_setSearch',
    SET_SORT = 'number_setSort',
    SET_PAGE = 'number_setPage',
    SET_FILTER = 'number_filter_setFilter',
    CLEAR_FILTER = 'number_filter_clearFilter',
}

export enum NumberActions {
    SET_SEARCH = 'number_setSearch',
    SET_SORT = 'number_setSort',
    SET_PAGE = 'number_setPage',
    SET_FILTER = 'number_filter_SetFilter',
    CLEAR_FILTER = 'number_filter_ClearFilter',
}

export enum NumberGetters {
    SEARCH = 'number_search',
    SORT = 'number_sort',
    PAGE = 'number_page',
    DEFAULT_FILTER = 'number_defaultFilter',
    FILTER = 'numberFilter',
    ACTIVE_FILTERS = 'numberActiveFilters',
}
