import { ListFilters } from '@/types';

export type ContactState = ListFilters;

export enum ContactMutations {
    SET_SEARCH = 'contact_setSearch',
    SET_SORT = 'contact_setSort',
    SET_PAGE = 'contact_setPage',
}

export enum ContactActions {
    SET_SEARCH = 'contact_setSearch',
    SET_SORT = 'contact_setSort',
    SET_PAGE = 'contact_setPage',
}

export enum ContactGetters {
    SEARCH = 'contact_search',
    SORT = 'contact_sort',
    PAGE = 'contact_page',
}
