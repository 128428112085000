import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "text-xl font-semibold text-gray-800" }
const _hoisted_2 = { class: "flex items-center space-x-4" }
const _hoisted_3 = { class: "grid grid-cols-1 gap-4 space-y-4 md:grid-cols-3 md:space-y-0" }
const _hoisted_4 = { class: "mb-2 text-lg font-semibold text-gray-800" }
const _hoisted_5 = { class: "gap-4 space-y-4 md:grid md:grid-cols-3 md:space-y-0" }
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "mb-2 block text-sm font-medium text-gray-800" }
const _hoisted_8 = {
  key: 0,
  class: "text-danger bg-danger-100 p-2 text-sm shadow"
}
const _hoisted_9 = {
  key: 1,
  controls: "",
  class: "mt-2 w-full"
}
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "mb-2 text-lg font-semibold text-gray-800" }
const _hoisted_12 = { class: "mt-2 flex justify-end border-t border-gray-300 pt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiBackButton = _resolveComponent("UiBackButton")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_UiPageHeader = _resolveComponent("UiPageHeader")!
  const _component_UiTextInput = _resolveComponent("UiTextInput")!
  const _component_UiCheckboxInput = _resolveComponent("UiCheckboxInput")!
  const _component_UiPanel = _resolveComponent("UiPanel")!
  const _component_UiTextArea = _resolveComponent("UiTextArea")!
  const _component_UiAudioUpload = _resolveComponent("UiAudioUpload")!
  const _component_UiCheckDirtyBeforeRouteLeave = _resolveComponent("UiCheckDirtyBeforeRouteLeave")!

  return (_openBlock(), _createElementBlock("form", {
    class: "space-y-4 p-4 sm:p-6 lg:p-8",
    autocomplete: "off",
    onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
  }, [
    _createVNode(_component_UiPageHeader, null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('connectFlowSurvey.' + $setup.props.action + '.title')), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_UiBackButton, { fallbackTo: { name: 'ListConnectFlowSurvey' } }),
          (
                            ($setup.props.action == $setup.FormAction.CREATE &&
                                _ctx.$can($setup.Permission.CREATE_CONNECT_FLOW_SURVEY)) ||
                            ($setup.props.action == $setup.FormAction.EDIT &&
                                _ctx.$can($setup.Permission.EDIT_CONNECT_FLOW_SURVEY))
                        )
            ? (_openBlock(), _createBlock(_component_UiButton, {
                key: 0,
                type: "submit",
                variant: "primary",
                "text-variant": "white"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('core.actions.Save')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_UiPanel, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_UiTextInput, {
            modelValue: $setup.v$.name.$model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.v$.name.$model) = $event)),
            name: "name",
            type: "text",
            label: _ctx.$t('connectFlowSurvey.form.labels.name'),
            errors: $setup.v$.name.$errors,
            onBlur: $setup.v$.name.$touch
          }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"]),
          _createVNode(_component_UiCheckboxInput, {
            modelValue: $setup.v$.isActive.$model,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.v$.isActive.$model) = $event)),
            name: "isActive",
            label: _ctx.$t('connectFlowSurvey.form.labels.isActive'),
            class: "md:pt-6"
          }, null, 8 /* PROPS */, ["modelValue", "label"])
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_UiPanel, null, {
      default: _withCtx(() => [
        _createElementVNode("h2", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.$t('connectFlowSurvey.form.section.initialMessage.title')) + " ", 1 /* TEXT */),
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-xs" }, "*", -1 /* HOISTED */))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_UiTextArea, {
            id: "textToAudio",
            name: "textToAudio",
            modelValue: $setup.formState.textToAudio,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formState.textToAudio) = $event)),
            label: _ctx.$t('connectFlowSurvey.form.labels.textToAudio'),
            placeholder: _ctx.$t('connectFlowSurvey.form.labels.textToAudioPlaceholder'),
            rows: 4,
            disabled: $setup.file != undefined,
            errors: $setup.v$.textToAudio.$errors,
            class: "col-span-2"
          }, null, 8 /* PROPS */, ["modelValue", "label", "placeholder", "disabled", "errors"]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('connectFlowSurvey.form.labels.audioUpload')), 1 /* TEXT */),
            _createVNode(_component_UiAudioUpload, {
              file: $setup.file,
              "onUpdate:file": _cache[3] || (_cache[3] = ($event: any) => (($setup.file) = $event)),
              "text-rule": _ctx.$t('connectFlowSurvey.form.labels.uploadRules'),
              errors: $setup.v$.audioFilePath.$errors
            }, null, 8 /* PROPS */, ["file", "text-rule", "errors"]),
            ($setup.v$.audioFilePath.$errors && $setup.v$.audioFilePath.$errors.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.v$.audioFilePath.$errors, (error) => {
                    return (_openBlock(), _createElementBlock("p", {
                      key: error.$uid
                    }, _toDisplayString(error.$message), 1 /* TEXT */))
                  }), 128 /* KEYED_FRAGMENT */))
                ]))
              : _createCommentVNode("v-if", true),
            ($setup.audioPreview)
              ? (_openBlock(), _createElementBlock("audio", _hoisted_9, [
                  _createElementVNode("source", { src: $setup.audioPreview }, null, 8 /* PROPS */, _hoisted_10),
                  _cache[5] || (_cache[5] = _createTextVNode(" Your browser does not support the audio element. "))
                ]))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_UiPanel, null, {
      default: _withCtx(() => [
        _createElementVNode("h2", _hoisted_11, [
          _createTextVNode(_toDisplayString(_ctx.$t('connectFlowSurvey.form.section.questions.title')) + " ", 1 /* TEXT */),
          _cache[6] || (_cache[6] = _createElementVNode("span", { class: "text-xs" }, "*", -1 /* HOISTED */))
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.formState.questions, (item, index) => {
          return (_openBlock(), _createBlock($setup["ConnectFlowSurveyQuestionForm"], {
            key: index,
            index: index,
            modelValue: $setup.formState.questions[index],
            "onUpdate:modelValue": ($event: any) => (($setup.formState.questions[index]) = $event),
            "show-remove": $setup.formState.questions.length > 1,
            ref_for: true,
            ref: (el) => $setup.setQuestionRef(el, index),
            onRemove: $setup.removeQuestion
          }, null, 8 /* PROPS */, ["index", "modelValue", "onUpdate:modelValue", "show-remove"]))
        }), 128 /* KEYED_FRAGMENT */)),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_UiButton, {
            variant: "primary",
            "text-variant": "white",
            onClick: $setup.addQuestion
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('connectFlowSurvey.form.actions.addQuestion')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_UiCheckDirtyBeforeRouteLeave, {
      dirty: $setup.v$.$anyDirty
    }, null, 8 /* PROPS */, ["dirty"])
  ], 32 /* NEED_HYDRATION */))
}