<script lang="ts" setup>
import md5 from 'md5';
import { computed, ref } from 'vue';

const props = defineProps({
    email: {
        type: String,
        required: true,
    },
    name: {
        type: String,
        required: true,
    },
});

const gravatarUrl = computed<string>(() => {
    const hash = md5(props.email.trim().toLowerCase());
    return `https://www.gravatar.com/avatar/${hash}?d=404`;
});

const initials = computed<string>(() => getInitials(props.name));

const showImage = ref(true);

function getInitials(name: string) {
    const names = name.split(' ');
    if (names.length >= 2) {
        const firstInitial = names[0].charAt(0).toUpperCase();
        const lastInitial = names[names.length - 1].charAt(0).toUpperCase();
        return `${firstInitial}${lastInitial}`;
    } else if (names.length === 1) {
        return names[0].charAt(0).toUpperCase();
    }
    return '';
}

function handleImageError() {
    showImage.value = false;
}
</script>

<template>
    <img :src="gravatarUrl" @error="handleImageError" v-if="showImage" />
    <div
        class="flex items-center justify-center bg-cyan-100 text-lg font-semibold text-cyan-700"
        v-else
    >
        {{ initials }}
    </div>
</template>
