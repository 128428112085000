import { DefaultServiceResult } from '@/types';
import http from '@/services/core/http';
import { AxiosInstance, AxiosRequestConfig } from 'axios';

export default class BaseService<T> {
    route: string;
    http: AxiosInstance;

    constructor(route: string) {
        this.route = route;
        this.http = http;
    }

    getAll<ResponseT = T[]>(config?: AxiosRequestConfig) {
        return this.http.get<ResponseT>(this.route, config);
        // .then((response: AxiosResponse) => response.data);
    }

    get<ResponseT = T>(id: number | string, config?: AxiosRequestConfig) {
        return this.http.get<ResponseT>(`${this.route}/${id}`, config);
        // .then((response: AxiosResponse) => response.data);
    }

    create<ResponseT = DefaultServiceResult, DataT = T>(data: DataT, config?: AxiosRequestConfig) {
        return this.http.post<ResponseT>(this.route, data, config);
        // .then((response: AxiosResponse) => response.data);
    }

    update<ResponseT = DefaultServiceResult, DataT = T>(
        id: number | string,
        data: DataT,
        config?: AxiosRequestConfig,
    ) {
        return this.http.put<ResponseT>(`${this.route}/${id}`, data, config);
        // .then((response: AxiosResponse) => response.data);
    }

    activate<ResponseT = DefaultServiceResult, DataT = T>(
        id: number | string,
        data?: DataT,
        config?: AxiosRequestConfig,
    ) {
        return this.http.put<ResponseT>(`${this.route}/${id}/activate`, data, config);
        // .then((response: AxiosResponse) => response.data);
    }

    inactivate<ResponseT = DefaultServiceResult, DataT = T>(
        id: number | string,
        data?: DataT,
        config?: AxiosRequestConfig,
    ) {
        return this.http.put<ResponseT>(`${this.route}/${id}/inactivate`, data, config);
        // .then((response: AxiosResponse) => response.data);
    }

    delete<ResponseT = DefaultServiceResult>(id: number | string, config?: AxiosRequestConfig) {
        return this.http.delete<ResponseT>(`${this.route}/${id}`, config);
        // .then((response: AxiosResponse) => response.data);
    }
}
