import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, onMounted, onUnmounted, PropType } from 'vue';
import store from '@/store';
import { PlayIcon, PauseIcon, ExclamationCircleIcon, SearchIcon } from '@heroicons/vue/solid';

import { CallPlayerBullets } from '@/views/pages/call/components';
import { VaultService } from '@/services';
import { getAudioFormat } from '@/utils/audio';
import { formatSecToTime } from '@/utils/datetime';
import {
    CallPlayerMutations,
    CallType,
    CallBulletType,
    CallParticipantType,
    CallModel,
} from '@/types';
import debounce from 'debounce';
import WaveSurfer from 'wavesurfer.js';
import MultiCanvas from 'wavesurfer.js/src/drawer.multicanvas';
import AnalyticsService from '@/services/analytics/AnalyticsService';


export default /*@__PURE__*/_defineComponent({
  __name: 'CallPlayer',
  props: {
    call: {
        type: Object as PropType<CallModel>,
        required: true,
    },
},
  setup(__props, { expose: __expose }) {
  __expose();



const waveInstance = ref<WaveSurfer | null>(null);
const audioData = ref<string | null>(null);
const audioElement = ref<HTMLElement | null>(null);
const playerReady = ref(false);
const playerError = ref(null);
const callDuration = ref(0);
const secondsCurrent = ref(0);
const loadingPercentage = ref(0);
const searchWord = ref('');

const isPlaying = computed(() => (playerReady.value ? waveInstance.value?.isPlaying() : false));
const playbackRate = computed(() => waveInstance.value?.getPlaybackRate());

// Fetch Audio File
const getAudioFile = async () => {
    if (!__props.call?.audioFilePath) return;

    try {
        const { data } = await VaultService.getCallAudio(__props.call.id);
        audioData.value = URL.createObjectURL(
            new Blob([data], { type: getAudioFormat(__props.call.audioFilePath) }),
        );
        initAudioPlayer();
    } catch (error) {
        console.error('Error fetching audio file:', error);
    }
};

MultiCanvas.prototype.drawBars = function (peaks, channelIndex, start, end) {
    return this.prepareDraw(
        peaks,
        channelIndex,
        start,
        end,
        ({ absmax, hasMinVals, height, offsetY, peaks }) => {
            if (start === undefined) return;

            const barWidth = this.params.barWidth || 3;
            const barGap = this.params.barGap || 3;
            const pixelRatio = this.params.pixelRatio || 1;
            const bar = barWidth * pixelRatio;
            const peakIndexScale = hasMinVals ? 2 : 1;
            const length = peaks.length / peakIndexScale;
            const gap =
                this.params.barGap === null
                    ? Math.max(pixelRatio, ~~(bar / 2))
                    : Math.max(pixelRatio, barGap * pixelRatio);
            const step = bar + gap;
            const gapBetween = 2;
            const scale = length / this.width;

            for (let i = start; i < end; i += step) {
                const peakIndex = Math.floor(i * scale * peakIndexScale);
                const peak = peaks[peakIndex] || 0;
                const rectWidth = bar + this.halfPixel;
                let rectHeight = Math.abs(Math.round((peak / absmax) * (height - gapBetween)));
                if (rectHeight && this.params.barMinHeight && rectHeight < this.params.barMinHeight)
                    rectHeight = this.params.barMinHeight;
                const x = i + this.halfPixel;
                const y = offsetY
                    ? __props.call.type === CallType.INBOUND
                        ? height - rectHeight - gapBetween
                        : height + gapBetween
                    : __props.call.type === CallType.INBOUND
                      ? height + gapBetween
                      : height - rectHeight - gapBetween;
                this.fillRect(x, y, rectWidth, rectHeight, this.barRadius, channelIndex);
            }
        },
        0,
        0,
    );
};

const initAudioPlayer = () => {
    if (!audioElement.value || !audioData.value) return;

    let waveColor: CanvasGradient | string = '#3b82f6';
    if (__props.call?.type !== CallType.INTERNAL) {
        const ctx = document.createElement('canvas').getContext('2d');
        if (ctx) {
            const gradient = ctx.createLinearGradient(0, 0, 0, 150);
            gradient.addColorStop(0, 'rgb(59, 130, 246)');
            gradient.addColorStop(1, 'rgb(59, 130, 246)');
            gradient.addColorStop(1, 'rgb(168, 85, 247)');
            waveColor = gradient;
        }
    }

    waveInstance.value = WaveSurfer.create({
        container: audioElement.value,
        waveColor,
        progressColor: '#adb5bd',
        cursorColor: '#adb5bd',
        barWidth: 3,
        barGap: 3,
        barRadius: 1,
        barMinHeight: 3,
        height: 75,
        normalize: true,
        hideScrollbar: true,
        splitChannels: true,
        skipLength: 10,
        renderer: MultiCanvas,
    });

    waveInstance.value.load(audioData.value);

    waveInstance.value.on('ready', () => {
        playerReady.value = true;
        callDuration.value = waveInstance.value?.getDuration() ?? 0;
    });

    waveInstance.value.on('audioprocess', (audioProcess) => {
        secondsCurrent.value = parseFloat(audioProcess.toFixed(2));
    });

    waveInstance.value.on('loading', (percentage) => {
        loadingPercentage.value = parseFloat(percentage.toFixed(2));
    });

    waveInstance.value.on('error', (error) => {
        loadingPercentage.value = 0;
        playerError.value = error;
    });

    waveInstance.value.on('seek', (seek) => {
        secondsCurrent.value = (waveInstance.value?.getDuration() ?? 0) * seek;
    });
};

// Play / Pause
const playPause = () => {
    if (!waveInstance.value) {
        return;
    }

    const playStatusTracking = waveInstance.value.isPlaying() ? 'PauseCallAudio' : 'PlayCallAudio';

    waveInstance.value.playPause();
    void AnalyticsService.trackingAction(playStatusTracking);
};

// Change Speed
const changeSpeed = () => {
    const speeds = [1, 1.1, 1.2];
    const currentRate = waveInstance.value?.getPlaybackRate() ?? 1;
    waveInstance.value?.setPlaybackRate(speeds[(speeds.indexOf(currentRate) + 1) % speeds.length]);
};

const handlePlaySnippet = (start: number, end: number) => {
    if (waveInstance.value) waveInstance.value.play(start, end);
};

// Lifecycle Hooks
onMounted(async () => {
    await getAudioFile();
});
onUnmounted(() => {
    if (waveInstance.value) {
        waveInstance.value.destroy();
        waveInstance.value = null;
    }
});

const __returned__ = { waveInstance, audioData, audioElement, playerReady, playerError, callDuration, secondsCurrent, loadingPercentage, searchWord, isPlaying, playbackRate, getAudioFile, initAudioPlayer, playPause, changeSpeed, handlePlaySnippet, get store() { return store }, get PlayIcon() { return PlayIcon }, get PauseIcon() { return PauseIcon }, get ExclamationCircleIcon() { return ExclamationCircleIcon }, get SearchIcon() { return SearchIcon }, get CallPlayerBullets() { return CallPlayerBullets }, get formatSecToTime() { return formatSecToTime }, get CallPlayerMutations() { return CallPlayerMutations }, get CallType() { return CallType }, get CallBulletType() { return CallBulletType }, get CallParticipantType() { return CallParticipantType }, get debounce() { return debounce } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})