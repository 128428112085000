import { BillingModel } from '@/types';
import BaseService from '@/services/api/BaseService';
import { AxiosRequestConfig } from 'axios';

class BillingReportService extends BaseService<BillingModel> {
    getInfo(config?: AxiosRequestConfig) {
        return this.http.get<BillingModel>(`${this.route}`, config);
    }
}

export default new BillingReportService('reports/billing');
