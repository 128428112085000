import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

import { useFilterStore, useUiStore } from '@/stores';
import { IntegrationService } from '@/services';
import { toastServiceError } from '@/services/core/notification';

import { IntegrationWidget } from './components';
import { LevelModel, IntegrationModel, IntegrationType } from '@/types';

import { LinkIcon } from '@heroicons/vue/solid';


export default /*@__PURE__*/_defineComponent({
  __name: 'IntegrationPage',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();
const { t } = useI18n();
const filterStore = useFilterStore();
const uiStore = useUiStore();

const integrations: Ref<IntegrationModel[]> = ref([]);

const integrationInfo = {
    [IntegrationType.Pipedrive]: {
        logoPath: '/img/integration/pipedrive.png',
        description: t('integration.integrations.pipedrive.description'),
    },
    [IntegrationType.OpenAi]: {
        logoPath: '/img/integration/openai.png',
        description: t('integration.integrations.openai.description'),
    },
};

const configuredIntegrations = computed<IntegrationModel[]>(() =>
    integrations.value.filter((integration) => integrationInfo[integration.type]),
);

const levelFilter = computed<LevelModel | null>(() => filterStore.level);

const setLoading = (loading: boolean) => uiStore.setIsLoading(loading);

const getIntegrations = async () => {
    try {
        await setLoading(true);
        const response = await IntegrationService.getAll<Array<IntegrationModel>>();
        integrations.value = response.data;
    } catch (error) {
        toastServiceError(error);
    } finally {
        await setLoading(false);
    }
};

const handleConfigClick = (integration: IntegrationModel) => {
    router.push({
        name: 'EditIntegrationById',
        params: {
            type: integration.type,
            id: integration.id,
        },
    });
};

const handleViewLogsClick = (integration: IntegrationModel) => {
    router.push({
        name: 'ViewIntegrationLogs',
        params: {
            type: integration.type,
            id: integration.id,
        },
    });
};

const handleActivateClick = (integration: IntegrationModel) => {
    router.push({
        name: 'CreateIntegration',
        params: {
            type: integration.type,
        },
    });
};

watch([levelFilter], getIntegrations, { deep: true });

onMounted(getIntegrations);

const __returned__ = { router, t, filterStore, uiStore, integrations, integrationInfo, configuredIntegrations, levelFilter, setLoading, getIntegrations, handleConfigClick, handleViewLogsClick, handleActivateClick, get IntegrationWidget() { return IntegrationWidget }, get LinkIcon() { return LinkIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})